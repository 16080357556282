import { ChartConfig, ChartType, MeasurementSetConfig, TimeScaleType } from "data/types/measurementSetTypes";
import AppSyncClientFactory from "data/backend/AppSyncClientFactory";
import { Service } from "data/backend/AppSyncClientProvider";
import { Data, DataUtil } from "data/data/Data";
import { Aggregate, DevicesMeasurementsListTs2Document, MeasurenmentInfo } from "generated/gqlData";
import { Maybe, Nullable } from "types/aliases";
import { isDefined } from "utils/types";

interface ChartConfigData {
  indexOfChartConfigInSet: number;
  data: Data[];
}

export default class MeasurementDataSet {
  private readonly measurementSet: MeasurementSetConfig;
  private readonly startTimestamp: number;
  private readonly endTimestamp: number;
  protected data: ChartConfigData[] = [];

  public constructor(startTimestamp: number, endTimestamp: number, set: MeasurementSetConfig) {
    this.measurementSet = set;
    this.startTimestamp = startTimestamp;
    this.endTimestamp = endTimestamp;
  }

  public async fetchData(): Promise<void> {
    await Promise.all(this.measurementSet.config.chartConfig.map((config, index) => this.fetchChartConfigData(config, index)));
  }

  public async fetchChartConfigData(chartConfig: ChartConfig, index: number): Promise<void> {
    const measurements = this.buildMeasurements(chartConfig);

    if (measurements.length > 0) {

      let interval: number;

      if (chartConfig.chartType === ChartType.STEPPED_AREA_CHART) {
        const timespanInSeconds = this.endTimestamp / 1000 - this.startTimestamp / 1000;
        const oneHourAsSeconds = 3600;
        interval = timespanInSeconds / oneHourAsSeconds;
      } else {
        interval = Math.floor((this.endTimestamp / 1000 - this.startTimestamp / 1000) / 300);
      }

      let nextToken: Nullable<string> = null;
      let data: Data[] = [];
      const startTime = Date.now();

      try {
        do {
          const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DATA);
          const dataResponse = await appSyncClient.query(
            DevicesMeasurementsListTs2Document,
            {
              startTimestamp: this.startTimestamp.toString(),
              endTimestamp: this.endTimestamp.toString(),
              interval,
              measurements,
              nextToken,
            },
          );
          // cast is required or response encounters a cyclic type inference
          nextToken = (dataResponse.data.devicesMeasurementsListTS2?.nextToken ?? null) as Nullable<string>;
          const dataItems: Data[] = (dataResponse.data.devicesMeasurementsListTS2?.measurementItems ?? [])
            .map((item) => DataUtil.parseDataFragment<Data>(item))
            .filter(isDefined);
          data = data.concat(dataItems);
        } while (nextToken);
        this.setData({ indexOfChartConfigInSet: index, data });

        const duration = (Date.now() - startTime) / 1000;
        console.log(`MeasurementDataSet: Fetched ${data.length} items in ${duration} seconds`);
      } catch (error) {
        console.error("Error", error);
      }
    }
  }

  private buildMeasurements(chartConfig: ChartConfig): MeasurenmentInfo[] {
    return chartConfig.dataConfig.reduce<MeasurenmentInfo[]>((acc, curr) => {

      if (chartConfig.timeScaleType === TimeScaleType.HISTORY) {
        acc.push(
          {
            aggregate: Aggregate.Avg,
            sensorName: curr.sensorName,
            deviceId: curr.deviceId,
          },
        );
      } else if (chartConfig.timeScaleType === TimeScaleType.AGGREGATE) {
        acc.push(
          {
            aggregate: Aggregate.Max,
            sensorName: curr.sensorName,
            deviceId: curr.deviceId,
          },
        );
      }
      return acc;
    }, []);
  }

  public setData(data: ChartConfigData): void {
    this.data.push(data);
  }

  public getData(chartConfigIndex: number): Maybe<Data[]> {
    const dataItem = this.data.find(({ indexOfChartConfigInSet }) => indexOfChartConfigInSet === chartConfigIndex);

    if (dataItem) {
      return dataItem.data;
    }
  }
}

//TODO: remove if observer functionality is not needed
// public async addObserver(observer: DataObserver): Promise<void> {
//   if (!this.data) {
//     await this.fetch();
//   }
//   super.addObserver(observer);

//   if (this.observerCount === 1) {
//     DataSubscriptionManager.getInstance().subscribeData(this);
//   }
// }

// public removeObserver(observer: DataObserver): void {
//   super.removeObserver(observer);

//   if (this.observerCount === 0) {
//     DataSubscriptionManager.getInstance().removeSubscription(this);
//   }
// }

