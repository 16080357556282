import React, { Component, Fragment, ReactNode } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Theme, withTheme } from "@material-ui/core/styles";
import { Chart } from "react-google-charts";
import { ChartArea, ChartExplorer } from "types/chartprops";
import { GoogleChartControlProp } from "react-google-charts/dist/types";
import { Maybe, Nullable } from "types/aliases";
import { getDateTimeFormat, DateTimeFormatTarget } from "data/utils/Utils";
import Localization from "data/localization-sensoan/Localization";
import { ChartSize, DataConfig, SensorColorType } from "data/types/measurementSetTypes";
import { ChartData } from "./chartDataTypes";
import SText from "components/styled-components/SText";
import ChartTitle from "./ChartTitle";
import LoaderSensoan from "components/layout/LoaderSensoan";

interface Props {
  colors: SensorColorType[];
  data: ChartData[];
  dataConfig: DataConfig[];
  theme: Theme;
  chartIndex?: number;
  deleteChart?: (index: Maybe<number>) => void;
  editChart?: (index: Maybe<number>) => void;
  scale?: { min: number; max: number };
  size?: ChartSize;
  title?: string;
}

interface State {
  fakeControls: GoogleChartControlProp[];
  fullWidth: boolean;
}

const CHART_AREA: ChartArea = {
  bottom: 60,
  top: 50,
  height: "70%",
  width: "80%",
};

const EXPLORER: ChartExplorer = {
  actions: ["dragToZoom", "rightClickToReset"],
  axis: "horizontal",
  keepInBounds: true,
  maxZoomIn: 100.0,
};

class SteppedAreaChart2 extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      fullWidth: false,
      fakeControls: [],
    };
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (this.state.fullWidth !== prevState.fullWidth) {
      this.setState({ fakeControls: [] });
    }
  }

  public render(): JSX.Element {
    let sm: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 12;
    let md: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 8;
    let lg: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 6;
    let xl: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 4;
    let boxHeight = "400px";

    if (this.props.size === ChartSize.FULL_WIDTH || this.state.fullWidth) {
      sm = 12;
      md = 12;
      lg = 12;
      xl = 12;
      boxHeight = "600px";
    } else if (this.props.size === ChartSize.PREVIEW_WIDTH) {
      sm = 12;
      md = 10;
      lg = 10;
      xl = 10;
      boxHeight = "400px";
    }
    return (
      <Grid item sm={sm} md={md} lg={lg} xl={xl} >
        <Box
          bgcolor={this.props.theme.palette.background.default}
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.35)"
          display="flex"
          flexDirection="column"
          flexWrap="nowrap"
          height={boxHeight}
          onClick={this.props.size !== ChartSize.FULL_WIDTH ? (): void => this.setState({ fullWidth: !this.state.fullWidth }) : undefined}
          overflow="hidden"
        >
          <ChartTitle
            chartIndex={this.props.chartIndex}
            deleteChart={this.props.deleteChart}
            editChart={this.props.editChart}
            title={this.props.title}
          />
          {this.props.data && this.props.data.length > 1
            ?
            <Fragment>
              {this.renderSensorDataMissingMsg()}
              <Box height="80%">
                <Chart
                  chartLanguage={Localization.getInstance().getCurrentLocaleInclCountry()}
                  controls={this.state.fakeControls}
                  chartType="SteppedAreaChart"
                  loader={<LoaderSensoan />}
                  data={this.props.data}
                  options={{
                    chartArea: CHART_AREA,
                    colors: this.props.colors,
                    explorer: EXPLORER,
                    fontName: this.props.theme.typography.fontFamily,
                    legend: {
                      display: true,
                      position: "bottom",
                      textStyle: {
                        color: this.props.theme.palette.text.primary,
                      },
                    },
                    // for some reason animation produces loads of errors like '<rect> attribute y: Expected length, "undefined"'
                    // these errors don't prevent any functionalities but look ugly in the console
                    // animation: ANIMATION,
                    enableInteractivity: true,
                    backgroundColor: this.props.theme.palette.background.default,
                    vAxis: {
                      minValue: this.props.scale?.min,
                      maxValue: this.props.scale?.max,
                      textStyle: {
                        color: this.props.theme.palette.text.secondary,
                      },
                      gridlines: {
                        count: -1,
                        color: this.props.theme.palette.text.secondary,
                      },
                    },
                    hAxis: {
                      textStyle: {
                        color: this.props.theme.palette.text.secondary,
                      },
                      titleTextStyle: {
                        color: this.props.theme.palette.text.secondary,
                      },
                      gridlines: {
                        count: -1,
                        color: this.props.theme.palette.background.default,
                      },
                    },
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  formatters={[
                    {
                      type: "DateFormat",
                      column: 0,
                      options: {
                        pattern: getDateTimeFormat(DateTimeFormatTarget.ChartTooltip),
                      },
                    },
                  ]}
                />
              </Box>
            </Fragment>
            :
            <Box display="flex" justifyContent="center" alignItems="center" height="65%">
              <Typography variant="h6" color="textSecondary">{Localization.getInstance().getDisplayText("LineChart", "nodata")}</Typography>
            </Box>
          }
        </Box>
      </Grid>
    );
  }

  private renderSensorDataMissingMsg(): ReactNode {
    const configs = this.getConfigsWithoutSensorData();

    if (configs !== null) {
      const sensorDisplayNameList = configs.map(sensor => sensor.sensorDisplayName).join(",");
      return (
        <Box display="flex" justifyContent="center" mx={4}>
          <SText color="primary" text={`${Localization.getInstance().getDisplayText("LineChart", "noDataFoundMsg")} ${sensorDisplayNameList}`} textAlign="center"/>
        </Box>
      );
    } else {
      return null;
    }
  }

  private getConfigsWithoutSensorData(): Nullable<DataConfig[]> {
    const configs = this.props.dataConfig.filter(config => !this.props.data[0].includes(config.sensorDisplayName)); // this.props.data[0] === array with timestamp and sensorDisplayNames
    return configs.length > 0 ? configs : null;
  }
}

export default withTheme(SteppedAreaChart2);
