import React, { Component, ReactNode } from "react";
import { Button, Dialog, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import Localization from "data/localization-sensoan/Localization";

interface Props {
  errorMsg?: string;
  onClose: () => void;
}

export default class ErrorDialog extends Component<Props> {
  private text = Localization.getInstance().getDisplayText;

  protected handleOk = (): void => {
    this.props.onClose();
  };

  public render(): ReactNode {
    if (!this.props.errorMsg) {
      return null;
    }
    return (
      <Dialog
        onClose={this.props.onClose}
        open={true}
      >
        <div className="popup-inner">
          <ErrorIcon color="error" style={{ verticalAlign: "sub" }}/>
          <Typography variant="h6" style={{ display: "inline-block" }}>
            {this.text("Common", "error")}
          </Typography>
          <div className="popup-body">
            <p>{this.props.errorMsg}</p>
            <div className="buttons-container">
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleOk}
              >
                {this.text("Common", "ok")}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}
