import React, { Component } from "react";
import { Box, Theme, Typography, withTheme } from "@material-ui/core";
import Localization from "data/localization-sensoan/Localization";
import { CustomSpacingProps } from "types/sensoanUiTypes";

interface Props {
  customMessage?: string;
  msgTextAlign?: "inherit" | "left" | "center" | "right" | "justify";
  theme: Theme;
  m?: CustomSpacingProps;
  mt?: CustomSpacingProps;
  mr?: CustomSpacingProps;
  mb?: CustomSpacingProps;
  ml?: CustomSpacingProps;
  p?: CustomSpacingProps;
  pt?: CustomSpacingProps;
  pr?: CustomSpacingProps;
  pb?: CustomSpacingProps;
  pl?: CustomSpacingProps;
}

interface State {
}

class ErrorMessage extends Component<Props, State> {

  public render(): JSX.Element {
    const defaultMessage = Localization.getInstance().getDisplayText("ErrorMessage", "fieldIsRequired");
    return (
      <Box m={this.props.m} mt={this.props.mt} mr={this.props.mr} mb={this.props.mb} ml={this.props.ml}
        p={this.props.p} pt={this.props.pt} pr={this.props.pr} pb={this.props.pb} pl={this.props.pl}>
        <Typography style={{ color: this.props.theme.palette.primary.main, whiteSpace: "pre" }} align={this.props.msgTextAlign} >
          {this.props.customMessage ?? defaultMessage}
        </Typography>
      </Box>
    );
  }


}

export default withTheme(ErrorMessage);
