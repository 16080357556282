import { MapMarkerData, IconStatus, IconTypes, MapLocation } from "data/map/MapLink";
import Event from "data/clientSpecific/Event";
import { ConditionProperties, EventConfig, EventTriggerDbEntry, TriggerOperator } from "data/types/eventTypes";
import { Nullable } from "types/aliases";
import { v4 as uuid } from "uuid";
import EventsRepository from "data/data-storage/EventsRepository";

export function filterSensorSpecificEventTriggerDbEntry(eventTriggers: EventTriggerDbEntry[], deviceId: string, sensor: string): Nullable<EventTriggerDbEntry> {
  const eventTriggersForDevice = eventTriggers.filter((trigger) => trigger.deviceId === deviceId);
  const eventTrigger = eventTriggersForDevice.filter((trigger) => trigger.rules[0].event.params?.["sensorName"] === sensor);

  if (eventTrigger.length > 1) {
    console.error(`Device ${deviceId} has more than one sensor specific event trigger configuration for sensor ${sensor}`);
    return eventTrigger[0];
  } else if (eventTrigger.length === 1) {
    return eventTrigger[0];
  } else {
    return null;
  }
}

export function buildEmptySensorSpecificEventTriggerConfig(deviceId: string, sensor: string): EventConfig {

  const eventId = uuid();
  const trigger: EventTriggerDbEntry = {
    triggerId: uuid(),
    deviceId,
    rules: [{
      conditions: {
        any: [],
      },
      event: {
        params: {
          eventId,
          sensorName: sensor,
          severity: "HIGH",
        },
        type: "SENSOR",
      },
    }],
  };

  return {
    trigger,
    metadata:
    {
      eventId,
    },
  };
}

export function buildEventTriggerCondition(sensor: string, value: number, operator: TriggerOperator): ConditionProperties {
  return {
    fact: sensor,
    operator,
    value,
  };
}

export function buildEventMetadataDescription(input: ConditionProperties[]): string {
  const greaterThanOperator = ">";
  const lessThanOperator = "<";

  const stringArray = input.reduce<string[]>((acc, { operator, fact, value }) => {
    if (operator === TriggerOperator.MIN) {
      acc.push(`${fact} ${lessThanOperator} ${value}`);
    } else if (operator === TriggerOperator.MAX) {
      acc.push(`${fact} ${greaterThanOperator} ${value}`);
    } else {
      console.error(`Error: skipping a condition property with an operator '${operator}' because the type of the operator is not yet supported`);
    }
    return acc;
  }, []);
  return stringArray.length === 1 ? stringArray.toString() : `${stringArray[0]} | ${stringArray[1]}`;
}

export function parseEventTriggerValue(dbEntry: EventTriggerDbEntry, operator: TriggerOperator): Nullable<number> {
  const conditions: ConditionProperties[] = dbEntry.rules[0]?.conditions.any ?? [];
  const condition = conditions.filter((c) => c.operator === operator);

  if (condition.length > 0) {
    return condition[0].value as number;
  } else {
    return null;
  }
}

export function buildUniqueUIStringForEvent({ deviceId, eventId, timestamp }: Event): string {
  // eventIds are not unique and also are voidable -> build unique id from event properties for map marker data handling and event listing
  // if a single event has to extracted from a map marker at some point, the use of uuid() here might cause an issue
  return `${deviceId}_${eventId ?? uuid()}_${timestamp}`;
}

export function getMapMarkerDataForEvent(event: Event, selected: () => IconStatus): Nullable<MapMarkerData> {
  const { latitude, longitude } = event;

  if (latitude && longitude) {
    const location: MapLocation = { lat: latitude, lng: longitude };
    return {
      id: buildUniqueUIStringForEvent(event),
      type: IconTypes.event,
      status: selected,
      location: (): MapLocation => location,
    };
  } else {
    return null;
  }
}

/**
 * @param filter An optional array of Events for which to get mapMarkerData.
 */
export function getMapMarkerDataForAllEvents(filter?: Event[]): MapMarkerData[] {
  const events = filter !== undefined ? filter : EventsRepository.instance.getAllEvents();
  return events.reduce<MapMarkerData[]>((acc, curr) => {
    const mapDataItem = getMapMarkerDataForEvent(curr, () => IconStatus.selected);
    if (mapDataItem) acc.push(mapDataItem);
    return acc;
  }, []);
}
