import React, { Component, ReactNode } from "react";
import { Box } from "@material-ui/core";


interface Props {
  disableOverflowY?: boolean;
}

/* This pads children with same padding and allows overflowing in same way as AppLayout before.
This had to be changed because the new DevicesWrapper has its custom overflow functionality.
*/
class WrapperContainer extends Component<Props> {
  public render(): ReactNode {
    const { children, disableOverflowY } = this.props;
    return (
      <Box height="100%" width="100%" p={1} pl={6} pr={6} style={{ overflowY: disableOverflowY ? undefined : "auto" }}>
        {children}
      </Box>
    );
  }
}

export default WrapperContainer;

