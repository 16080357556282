import React, { ChangeEvent, Component, Fragment } from "react";
import { Button, TextField, withStyles } from "@material-ui/core";
import AuthWrapper, { AuthenticatedUser } from "data/auth/AuthWrapper";
import Localization from "data/localization-sensoan/Localization";
import { ErrorWithCode, isErrorWithCode } from "data/utils/ErrorUtils";
import { Maybe } from "types/aliases";
import { isValidEmail } from "utils/validation";
import PasswordField from "components/ui/password-field";
import ConfirmationDialog from "components/ui/confirmation-dialog";
import Loader from "components/ui/loader";

interface Props {
  onPasswordResetRequest: (user: string) => void;
  onSignUpConfirmRequest: (user: AuthenticatedUser) => void;
}

interface State {
  isLoaderVisible: boolean;
  isPasswordVisible: boolean;
  username: string;
  password: string;
  generalError?: string;
  emailError?: string;
  passwordError?: string;
  dialog?: Dialog;
}

interface Dialog {
  title: string;
  message: string;
  onAccept: () => void;
  onDecline?: () => void;
}

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
})(TextField);

export default class LoginForm extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      isLoaderVisible: false,
      isPasswordVisible: false,
      username: "",
      password: "",
    };
  }

  private renderLoader = (): Maybe<JSX.Element> => {
    if (this.state.isLoaderVisible) {
      return <Loader/>;
    }
  };

  private handleErrors(reason: string, message?: string, continueOnIgnore?: () => void): void {
    switch (reason) {
      case "UsernameEmptyException":
        this.setState({ emailError: this.text("Login", "usernameCannotBeEmpty") });
        break;
      case "PasswordEmptyException":
        this.setState({ passwordError: this.text("Login", "passwordCannotBeEmpty") });
        break;
      case "EmailFormatNotValidException":
        this.setState({ emailError: this.text("Login", "emailFormatNotValid") });
        break;
      case "LimitExceededException":
        this.setState({ emailError: this.text("Common", "tooManyAttempts") });
        break;
      case "NetworkError":
        this.setState({ generalError: this.text("Common", "networkError") });
        break;
      case "NotAuthorizedException":
        if (message?.includes("User password cannot be reset in the current state")) {
          this.setState({ passwordError: this.text("Login", "passwordCannotBeResetCurrently") });
        } else {
          this.setState({ passwordError: this.text("Common", "incorrectCredentials") });
        }
        break;
      case "UserNotFoundException":
        if (continueOnIgnore) { continueOnIgnore(); }
        else { this.setState({ passwordError: this.text("Common", "incorrectCredentials") }); }
        break;
      case "ResetPasswordNotAuthorizedException":
        this.setState({ passwordError: this.text("Login", "passwordCannotBeResetCurrently") });
        break;
      case "InvalidParameterException":
        this.setState({ emailError: this.text("Login", "emailNotVerified") });
        break;
      case "CodeMismatchException":
      case "ExpiredCodeException":
      default:
        this.setState({ generalError: this.text("Common", "unableToPerformAction") });
        break;
    }
  }

  private logIn = async (): Promise<void> => {
    try {
      this.resetErrors();
      this.isEmailValid();
      this.isPasswordValid();
      this.setState({ isLoaderVisible: true });
      const user = await AuthWrapper.logIn(this.state.username, this.state.password);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.props.onSignUpConfirmRequest(user);
      }
    } catch (error) {
      this.setState({ isLoaderVisible: false });
      console.error("logIn", error);
      if (isErrorWithCode(error)) this.handleErrors(error.code);
    }
  };

  private triggerForgotPasswordConfirmation = (): void => {
    try {
      this.isEmailValid();
      this.setState({
        dialog: {
          title: this.text("Login", "passwordReset"),
          message: this.text("Login", "verificationCodeSentToEmail"),
          onAccept: () => {
            this.closeConfirmationDialog();
            this.handlePasswordResetCodeRequest();
          },
          onDecline: () => this.closeConfirmationDialog(),
        },
      });
    } catch (error) {
      console.error("triggerForgotPasswordWithConfirmation", error);
      if (isErrorWithCode(error)) this.handleErrors(error.code);
    }
  };

  private isEmailValid(email?: string): void | never {
    const testedEmail = email ?? this.state.username;

    if (testedEmail === "") {
      throw new ErrorWithCode("UsernameEmptyException");
    }

    if (!isValidEmail(testedEmail)) {
      throw new ErrorWithCode("EmailFormatNotValidException");
    }
  }

  private isPasswordValid(password?: string): void | never {
    const testedPassword = password ?? this.state.password;

    if (testedPassword === "") {
      throw new ErrorWithCode("PasswordEmptyException");
    }
  }

  private resetErrors(): void {
    this.setState({ generalError: undefined, emailError: undefined, passwordError: undefined });
  }

  private handlePasswordResetRequest = (): void => {
    try {
      this.resetErrors();
      this.isEmailValid();
      this.props.onPasswordResetRequest(this.state.username);
    } catch (error) {
      console.error("redirectToForgotPassword", error);
      if (isErrorWithCode(error)) this.handleErrors(error.code);
    }
  };

  private handlePasswordResetCodeRequest = async (): Promise<void> => {
    try {
      this.resetErrors();
      this.isEmailValid();
      this.setState({ isLoaderVisible: true });
      await AuthWrapper.forgotPassword(this.state.username);
      this.props.onPasswordResetRequest(this.state.username);
    } catch (error) {
      this.setState({ isLoaderVisible: false });
      console.error("forgotPassword", error);
      if (isErrorWithCode(error)) this.handleErrors(error.code, error.message, () => this.props.onPasswordResetRequest(this.state.username));
    }
  };

  private closeConfirmationDialog(): void {
    this.setState({ dialog: undefined });
  }

  private renderInputs(): JSX.Element {
    return (
      <Fragment>
        <div className="login-fields">
          <ValidationTextField
            label={this.text("Login", "email")}
            type="email"
            name="email"
            inputProps={{ "data-testid": "email-field" }}
            autoComplete="email"
            margin="normal"
            variant="outlined"
            autoFocus={true}
            required={true}
            value={this.state.username}
            helperText={this.state.emailError}
            error={this.state.emailError != null}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
              this.resetErrors();
              this.setState({ username: event.currentTarget.value });
            }}
            fullWidth={true}
            onKeyPress={this.handleKeyPress}
          />
          <PasswordField
            label={this.text("Login", "pw")}
            autoComplete="current-password"
            fullWidth={true}
            margin="normal"
            inputProps={{ "data-testid": "password-field" }}
            helperText={this.state.passwordError}
            error={this.state.passwordError != null}
            onChange={(password: string): void => {
              this.resetErrors();
              this.setState({ password });
            }}
            onKeyPress={this.handleKeyPress}
          />
        </div>
        <div className="login-buttons">
          <Button
            disabled={!this.state.username.length || !this.state.password.length}
            variant="contained"
            data-testid="login-button"
            color="primary"
            onClick={this.logIn}
          >
            {this.text("Login", "login")}
          </Button>
        </div>
        <div className="login-links">
          <span
            data-testid="forgot-password"
            onClick={this.triggerForgotPasswordConfirmation}
          >
            {this.text("Login", "forgotPassword")}
          </span>
        </div>
        <div className="login-links small">
          <span
            data-testid="already-have-code"
            onClick={this.handlePasswordResetRequest}
          >
            {this.text("Login", "verificationCode")}
          </span>
        </div>
      </Fragment>
    );
  }

  private renderErrorMessage(): Maybe<JSX.Element> {
    if (this.state.generalError) {
      return (
        <div className="login-errortext" data-testid="forgot-pass-err">
          {`${this.text("Common", "errorOccurred")} ${this.state.generalError}`}
        </div>
      );
    }
  }

  private handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      this.logIn();
    }
  };

  private renderDialog(): Maybe<JSX.Element> {
    if (this.state.dialog) {
      return (
        <ConfirmationDialog
          title={this.state.dialog.title}
          message={this.state.dialog.message}
          onConfirm={this.state.dialog.onAccept}
          onCancel={this.state.dialog.onDecline ?? undefined}
        />
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.renderLoader()}
        {this.renderInputs()}
        {this.renderErrorMessage()}
        {this.renderDialog()}
      </Fragment>
    );
  }
}
