
import React, { ReactNode } from "react";
import { Button, Grid, List } from "@material-ui/core";
import OtaManager from "data/ota/OtaManager";
import { OtaState, OtaUpdate, OtaUpdateState } from "data/ota/OtaTypes";
import { OtaUpdateListener } from "data/ota/OtaUpdateListener";
import { ReferenceHWState } from "client/devices/ReferenceHW/ReferenceHWState";
import { ReferenceHWStateProperties } from "client/devices/ReferenceHW/ReferenceHWStateProperties";
import OtaProgressInformation from "./ota-progress-information";
import SettingsListItem from "../settings-list-item";
import OtaPackageInformationItem from "./ota-package-information-item";
import { OtaPageProps } from "./ota-page-props";
import Localization from "data/localization-sensoan/Localization";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const semverCompare = require("semver/functions/compare");

interface Props extends OtaPageProps {
  otaManager: OtaManager;
}

interface State {
  availableUpdates: OtaUpdate[];
  selectedOtaPackage?: string;
  currentOtaUpdate?: OtaUpdateState;
}

export default class SettingsPageOtaInjectable extends React.Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      availableUpdates: [],
      selectedOtaPackage: props.deviceState.otaId ?? undefined,
    };
  }

  public componentDidMount(): void {
    this.props.otaManager.addListener(this.otaListener);
    this.updateAvailableOTAs(true).then();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.deviceState !== prevProps.deviceState) {
      this.setState({
        selectedOtaPackage: this.props.deviceState.otaId ?? undefined,
      });
      this.updateAvailableOTAs(true).then();
    }
  }

  public componentWillUnmount(): void {
    this.props.otaManager.removeListener(this.otaListener);
  }

  private isOtaOngoing = (): boolean => {
    return !!this.state.currentOtaUpdate && this.state.currentOtaUpdate.updateState === OtaState.InProgress;
  };

  private updateAvailableOTAs = async (force?: boolean): Promise<void> => {
    const updates = await this.props.otaManager.getOtaUpdates(force);
    console.log("selected ota package is " + this.state.selectedOtaPackage);
    this.setState({
      availableUpdates: updates
        .filter((update: OtaUpdate) => update.enabled)
        .sort((a, b) => semverCompare(b.firmwareVersion, a.firmwareVersion)),
    });
  };

  private handleSelectOta = (id: string): void => {
    this.setState({ selectedOtaPackage: id });
  };

  private startInstall = (): void => {
    const ota = this.state.selectedOtaPackage;

    if (!ota) {
      return;
    }

    if (!this.isOtaOngoing()) {
      this.props.otaManager.triggerDeviceOtaUpdate(this.props.deviceState.deviceId, ota).then();
    }
  };

  private cancelInstall = (): void => {
    if (this.state.currentOtaUpdate) {
      this.props.otaManager.cancelDeviceOtaUpdate(this.state.currentOtaUpdate.deviceId).then();
    }
  };

  private otaListener: OtaUpdateListener = {
    onOtaUpdateState: (state: OtaUpdateState): void => {
      if (state.deviceId === this.props.deviceState.deviceId) {
        this.setState({ currentOtaUpdate: state });
      }
    },
  };

  private getInstallButton(): JSX.Element {
    return (
      <Grid item>
        <Button
          disabled={!this.state.selectedOtaPackage}
          variant="contained"
          color="primary"
          onClick={this.startInstall}
        >
          {this.text("DeviceSettings", "installOta")}
        </Button>
      </Grid>
    );
  }

  private getCancelButton(): ReactNode {
    return (
      <Grid item>
        <Button
          variant="contained"
          onClick={this.cancelInstall}
        >
          {this.text("DeviceSettings", "cancelOta")}
        </Button>
      </Grid>
    );
  }

  private getCloseButton(): ReactNode {
    return (
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={this.props.onCloseSettings}
        >
          {this.text("Actions", "cancel")}
        </Button>
      </Grid>
    );
  }

  private renderSectionHeader(text: string): ReactNode {
    return (<h4>{text}</h4>);
  }

  private renderOtaDeviceInformation(): ReactNode {
    const firmwareVersion = (this.props.deviceState as ReferenceHWState<ReferenceHWStateProperties>).firmwareVersion ?? "N/A";
    return (
      <List>
        <SettingsListItem label={this.text("DeviceSettings", "firmwareVersion")}>
          {firmwareVersion}
        </SettingsListItem>
        <SettingsListItem label={this.text("DeviceSettings", "otaId")}>
          {this.props.deviceState.otaId || this.text("Common", "notAvailable")}
        </SettingsListItem>
      </List>
    );
  }

  private renderOtaProgressInformation(): ReactNode {
    return this.state.currentOtaUpdate
      ? (<OtaProgressInformation update={this.state.currentOtaUpdate} />)
      : (<p>{this.text("DeviceSettings", "noInstallationInProgress")}</p>);
  }

  private renderUpdatePackageListing(): ReactNode {
    if (!this.state.availableUpdates || this.state.availableUpdates.length === 0) {
      return <p>{this.text("DeviceSettings", "noUpdateAvailable")}</p>;
    }
    // force contents to be scrollable - otherwise the whole popup will be scrollable.
    return <div style={{ height: "300px", overflowY: "auto" }}>
      {this.state.availableUpdates.map((update: OtaUpdate): JSX.Element => {
        return <OtaPackageInformationItem
          key={update.otaId}
          item={update}
          selected={this.state.selectedOtaPackage === update.otaId}
          onClick={(): void => this.handleSelectOta(update.otaId)}
        />;
      })}
    </div>;
  }

  public render(): ReactNode {
    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          {this.renderSectionHeader(this.text("DeviceSettings", "deviceFirmwareInformation"))}
          {this.renderOtaDeviceInformation()}
          {this.renderSectionHeader(this.text("DeviceSettings", "otaUpdateProcess"))}
          {this.renderOtaProgressInformation()}
        </Grid>
        <Grid item={true} xs={6}>
          {this.renderSectionHeader(this.text("DeviceSettings", "availableOtaPackages"))}
          {this.renderUpdatePackageListing()}
        </Grid>
        <Grid item={true} container={true} justifyContent="center" spacing={4}>
          {
            !this.isOtaOngoing() ? this.getInstallButton() : this.getCancelButton()
          }
          {this.getCloseButton()}
        </Grid>
      </Grid>
    );
  }
}
