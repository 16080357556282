import { Nullable } from "types/aliases";
import BaseObservable from "data/observer/BaseObservable";
import { MapButtonActions, MapLocationEventResult } from "types/sensoanUiTypes";
import MapUi, { InfoBubbleState } from "./MapUi";
import MapBase, { DEFAULT_CENTER, ZOOM_DEFAULT } from "./MapBase";
import MapGeocoding, { HereMapsPlace } from "./MapGeocoding";


export enum IconStatus {
  normal = "normal",
  selected = "selected",
}

export enum IconTypes {
  alarm = "alarm",
  device = "device",
  event = "event",
  measurementJob = "measurementJob",
  measurementSet = "measurementSet",
  sensor = "sensor",
}

export interface MapLocation {
  lat: number;
  lng: number;
}

export interface MapMarkerData {
  id: string;
  type: IconTypes;
  location: () => MapLocation;
  status: () => IconStatus;
}

export enum MapState {
  ACTIVE = "ACTIVE",
  DEFAULT = "DEFAULT",
}

export interface ComponentToMapLink {
  addObserver(observer: MapEventObserver): void;
  isLocationOnMap (id: string): boolean;
  areLocationsOnMap (ids: string[]): boolean;
  setLocation(mapData: MapMarkerData[]): void;
  removeLocation(removeData: MapMarkerData[]): void;
  removeAllLocations(): void;
  removeObserver(observer: MapEventObserver): void;
  replaceData(newData: MapMarkerData[]): void;
  refresh(): void;
  getMapState(): MapState;
  setMapState(mapState: MapState): void;
  disableMouseInteraction(): void;
  enableMouseInteraction(): void;
  toggle(markerData: MapMarkerData): void;
  centerMap(newCenter: MapLocation[], zoomToNewCenter?: boolean): void;
  resetZoomAndCenter(): void;
  isDefaultZoomAndCenter(): boolean;
  getCurrentCenter(): MapLocation;
  isInCurrentView(locationOrArea: MapLocation | MapLocation[]): boolean;
  search(location: string | MapLocation): Promise<Nullable<HereMapsPlace[]>>;
}

export interface MapDataStorage {
  connectMapWrapper(map: React.Component): void;
  getMarkerData(): MapMarkerData[];
  // TODO: remove and change MapMarkerData.location() to return HereMapsPlaceItem ?
  getPlaceData(): Nullable<HereMapsPlace>; 
  setPlaceData(newPlaceData: HereMapsPlace): void;
  clearPlaceData(): void;
}

export interface MapToComponentsLink {
  postLocation (location: Nullable<MapLocationEventResult>): void;
}

export interface MapEventObserver {
  onMapLocationEvent?(eventResult: Nullable<MapLocationEventResult>): void;
}

export default class MapLink extends BaseObservable<MapEventObserver> implements ComponentToMapLink, MapDataStorage, MapToComponentsLink {
  private static instance: MapLink = new MapLink();
  private map: Nullable<React.Component> = null;
  private mapState: MapState = MapState.DEFAULT;
  private markerData: MapMarkerData[] = [];
  private placeData: Nullable<HereMapsPlace> = null;

  public addObserver(observer: MapEventObserver): void {
    super.addObserver(observer);
  }

  public static getLinkToMap(): ComponentToMapLink {
    return this.instance;
  }

  public static getDataStorage(): MapDataStorage {
    return this.instance;
  }

  public static getLinkToComponents(): MapToComponentsLink {
    return this.instance;
  }

  public connectMapWrapper(map: React.Component): void {
    this.map = map;
  }

  public disableMouseInteraction(): void {
    if (this.map === null) {
      console.error("Map not connected to MapLink");
    } else {
      const _map = this.map;
      _map.setState({ mouseInteractionEnabled: false });
    }
  }

  public enableMouseInteraction(): void {
    if (this.map === null) {
      console.error("Map not connected to MapLink");
    } else {
      const _map = this.map;
      _map.setState({ mouseInteractionEnabled: true });
    }
  }

  public getMapState (): MapState {
    return this.mapState;
  }

  public getMarkerData (): MapMarkerData[] {
    return this.markerData;
  }

  public isLocationOnMap (id: string): boolean {
    let flag = false;
    this.markerData.map((data: MapMarkerData) => {
      if (id === data.id) {
        flag = true;
      }
      return;
    });
    return flag;
  }

  public areLocationsOnMap (ids: string[]): boolean {
    if (this.markerData.length > 0) {
      return ids.every(id => this.isLocationOnMap(id));
    } else {
      return false;
    }
  }

  // a new mapData item with an id already on the map replaces the old marker
  public setLocation (mapData: MapMarkerData[]): void {
    const markerData = [...this.markerData];

    for (const newData of mapData) {
      const index = markerData.findIndex((oldData) => oldData.id === newData.id);

      if (index < 0) {
        markerData.push(newData);
      } else {
        markerData[index] = newData;
      }
    }

    this.markerData = markerData;
    this.updateMap();
  }

  public removeLocation (removeData: MapMarkerData[]): void {
    this.markerData = this.markerData.filter(
      (data: MapMarkerData) => {
        let flag = true;
        removeData.map((rdata: MapMarkerData) => {
          if (rdata.id === data.id) {
            flag = false;
            if (MapUi.getInstance().getInfoBubbleState() === InfoBubbleState.open) this.closeMapInfoBubble();
          }
          return;
        });
        return flag;
      },
    );
    this.updateMap();
  }

  public removeAllLocations (): void {
    this.markerData = [];
    if (MapUi.getInstance().getInfoBubbleState() === InfoBubbleState.open) this.closeMapInfoBubble();
    this.updateMap();
  }

  public removeObserver(observer: MapEventObserver): void {
    super.removeObserver(observer);
  }

  public replaceData(newData: MapMarkerData[]): void {
    this.markerData = newData;
    this.updateMap();
  }

  public refresh(): void {
    this.updateMap();
  }

  public setMapState(mapState: MapState): void {
    this.mapState = mapState;
    this.updateMap();
  }

  public toggle(markerData: MapMarkerData): void {
    if (this.isLocationOnMap(markerData.id)) {
      this.removeLocation([markerData]);
    } else {
      this.setLocation([markerData]);
    }
  }

  public postLocation(location: Nullable<MapLocationEventResult>): void {
    this.notifyAction(observer => observer.onMapLocationEvent?.(location));
  }

  public getPlaceData(): Nullable<HereMapsPlace> {
    return this.placeData;
  }

  public setPlaceData(newPlaceData: HereMapsPlace): void {
    this.placeData = newPlaceData;
  }

  public clearPlaceData(): void {
    this.placeData = null;
  }

  public closeMapInfoBubble(): void {
    if (this.map === null) {
      console.error("Map not connected to MapLink");
    } else {
      const _map = this.map;
      _map.setState({ actionRequest: MapButtonActions.closeInfoBubble, infoBubbleData: null });
    }
  }

  public centerMap(newCenter: MapLocation[], zoomToNewCenter?: boolean): void {
    if (zoomToNewCenter) {
      MapBase.getInstance().setLookAtData(newCenter);
    } else {
      MapBase.getInstance().setCenter(newCenter);
    }
  }

  public resetZoomAndCenter(): void {
    MapBase.getInstance().setCenter(DEFAULT_CENTER);
    MapBase.getInstance().setZoom(ZOOM_DEFAULT);
  }

  public isDefaultZoomAndCenter(): boolean {
    return this.isDefaultCenter() && this.isDefaultZoom();
  }

  public getCurrentCenter(): MapLocation {
    const { lat, lng } = MapBase.getInstance().getCenter();
    return { lat, lng };
  }

  public isInCurrentView(locationOrArea: MapLocation | MapLocation[]): boolean {
    return MapBase.getInstance().isInCurrentView(locationOrArea);
  }

  public async search(location: string | MapLocation): Promise<Nullable<HereMapsPlace[]>> {
    if (typeof location === "string") {
      return await MapGeocoding.getInstance().searchWithAddress(location);
    } else {
      return await MapGeocoding.getInstance().searchWithMapLocation(location);
    }
  }

  private updateMap(): void {
    if (this.map === null) {
      console.error("Map not connected to MapLink");
    } else {
      const _map = this.map;
      _map.forceUpdate();
    }
  }

  private isDefaultCenter(): boolean {
    const { lat, lng } = MapBase.getInstance().getCenter();
    const { lat: defaultLat, lng: defaultLng } = DEFAULT_CENTER;
    // MapBase.getInstance().getCenter() might return a very precise number (initially with DEFAULT_CENTER lng will be 26.8000004, for example)
    // using toPrecision(4) should be enough to check if current center is in practice the same as DEFAULT_CENTER
    return lat.toPrecision(4) === defaultLat.toPrecision(4) && lng.toPrecision(4) === defaultLng.toPrecision(4);
  }

  private isDefaultZoom(): boolean {
    return MapBase.getInstance().getZoom() === ZOOM_DEFAULT;
  }
}
