/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */


export function policyListContainsPermission(policies: string[], permission: string): boolean {
  const lowercaseAction = permission.toLowerCase();
  return policies
    .map((policy) => policy.toLowerCase())
    .some((policy) =>
      policy.endsWith("*")
        ? lowercaseAction.startsWith(policy.replace("*", ""))
        : lowercaseAction === policy);
}

/**
 * Strips users-service entity id to a non-prefixed version.
 * TODO: update API so that prefixes are no longer leaked to the clients.
 * @param id
 */
export function prefixlessId(id: string): string {
  const parts = id.split("/");
  const strippedId = parts[1] ?? parts[0];

  if (!strippedId) {
    throw new Error(`Not a valid entity id: '${id}'`);
  }
  return strippedId;
}
