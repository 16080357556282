import React, { Fragment } from "react";
import { ColorResult } from "react-color";
import CompactPicker from "react-color/lib/components/compact/Compact";
import { Grid, List, Switch, TextField } from "@material-ui/core";
import { getDeviceStateAsHyper, getDeviceStateAsRuuviGW, getDeviceStateAsSereneHW, getDeviceStateAsSuper, HyperLikeState, stateIsHyperLike, stateIsSerene, stateIsSuper } from "data/utils/Utils";
import { Maybe, Nullable } from "types/aliases";
import DeviceState from "data/device/DeviceState";
import { DeviceStateProperties } from "data/device/DeviceStateProperties";
import { HyperHWState, HyperIntervalTypes } from "client/devices/HyperHW/HyperHWState";
import { SuperHW } from "client/devices/SuperHW/SuperHW";
import { HyperHW } from "client/devices/HyperHW/HyperHW";
import { SensorHW } from "client/devices/SensorHW/SensorHW";
import { RuuviGWHW } from "client/devices/RuuviGWHW/RuuviGWHW";
import Loader from "components/ui/loader";
import SettingsListItem from "../settings-list-item";
import SettingsControls from "./components/settings-controls";
import RuuviGWStateSettings from "./components/RuuviGWStateSettings";
import Localization from "data/localization-sensoan/Localization";
import { RuuviTagHW } from "client/devices/RuuviTagHW/RuuviTagHW";
import { SereneHW } from "client/devices/SereneHW/SereneHW";
import { PiikkioHW } from "client/devices/PiikkioHW/PiikkioHW";

interface Props {
  deviceType: string;
  deviceState: DeviceState<DeviceStateProperties>;
  closeSettings: () => void;
}

interface State {
  changesMade: boolean;
  displayColorPicker: boolean;
  ledEnabled: boolean;
  ledColor: string;
  displayName: string;
  noModemSleep: boolean;
  logs: boolean;
  updateInterval: number;
  measurementInterval: number;
  firmwareVersion: string;
  resetCount: number;
}

type Color = { r: number; g: number; b: number; a: number };

export default class SettingsPageGeneral extends React.Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    const { deviceState } = this.props;
    const superState = getDeviceStateAsSuper(deviceState); 
    const hyperState = getDeviceStateAsHyper(deviceState);
    const sereneState = getDeviceStateAsSereneHW(deviceState);
    this.state = {
      changesMade: false,
      displayColorPicker: false,
      ledEnabled: superState?.ledEnabled || false,
      ledColor: superState?.ledColor || "#000000",
      displayName: this.props.deviceState.displayName || "",
      noModemSleep: hyperState.noModemSleep || false,
      logs: sereneState.logs || false,
      updateInterval: HyperHWState.toValidInterval(hyperState.updateInterval, HyperIntervalTypes.UPDATE),
      measurementInterval: HyperHWState.toValidInterval(hyperState.measurementInterval, HyperIntervalTypes.MEASURE),
      /* TODO: fix confusing device state getter: firmwareVersion is implemented in ReferenceHWState*/
      firmwareVersion: superState?.firmwareVersion || "N/A",
      /* TODO: fix confusing device state getter: resetCount is implemented in ReferenceHWState */
      resetCount: superState?.resetCount || 0,
    };
  }

  private handleCancel = (): void => {
    this.props.deviceState.revert();
    this.props.closeSettings();
  };

  private saveDeviceSettings = async (): Promise<void> => {
    this.setState({ changesMade: false });
    await this.props.deviceState.store();
  };

  private toggleColorPicker = (): void => {
    this.setState((prevState: State) => ({ displayColorPicker: !prevState.displayColorPicker }));
  };

  private ledStateChanged = (): void => {
    if (stateIsSuper(this.props.deviceState) && this.props.deviceState.ledEnabled !== null) {
      this.props.deviceState.ledEnabled = !this.props.deviceState.ledEnabled;
      this.setState({ ledEnabled: this.props.deviceState.ledEnabled });
    }
  };

  private ledColorChanged = (color: ColorResult): void => {
    if (stateIsSuper(this.props.deviceState) && this.props.deviceState.ledColor !== null) {
      this.props.deviceState.ledColor = color.hex;
      this.setState({ ledColor: color.hex });
    }
  };

  private displayNameChanged = (event: React.FormEvent<HTMLInputElement>): void => {
    const newName = event.currentTarget.value;
    this.props.deviceState.displayName = newName === "" ? null : newName;
    this.setState({ displayName: newName, changesMade: true });
  };

  private noModemSleepChanged = (): void => {
    const { deviceState } = this.props;

    if (stateIsHyperLike(deviceState) && (deviceState as HyperLikeState).noModemSleep != null) {
      const enabled = !(deviceState as HyperLikeState).noModemSleep;
      (deviceState as HyperLikeState).noModemSleep = enabled;
      this.setState({ noModemSleep: enabled, changesMade: true });
    }
  };

  private logsChanged = (): void => {
    const { deviceState } = this.props;
    const sereneState = getDeviceStateAsSereneHW(deviceState);

    if (stateIsSerene(deviceState) && sereneState.logs != null) {
      const enabled = !sereneState.logs;
      sereneState.logs = enabled;
      this.setState({ logs: enabled, changesMade: true });
    }
  };

  private updateIntervalChanged = (event: React.FormEvent<HTMLInputElement>): void => {
    const newValue = Number(event.currentTarget.value);
    const { deviceState } = this.props;

    // Cast is needed to fix a type error which only occurs at compilation time
    if (stateIsHyperLike(deviceState) && (deviceState as HyperLikeState).updateInterval !== null) {
      (deviceState as HyperLikeState).updateInterval = newValue;
      this.setState({ updateInterval: newValue, changesMade: true });
    }
  };

  private measurementIntervalChanged = (event: React.FormEvent<HTMLInputElement>): void => {
    const newValue = Number(event.currentTarget.value);
    const { deviceState } = this.props;

    // Cast is needed to fix a type error which only occurs at compilation time
    if (stateIsHyperLike(deviceState) && (deviceState as HyperLikeState).measurementInterval !== null) {
      (deviceState as HyperLikeState).measurementInterval = newValue;
      this.setState({ measurementInterval: newValue, changesMade: true });
    }
  };

  private getRgbaLedColor(): Color {
    let color: Color = {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    };

    if (this.state.ledColor !== null) {
      color = {
        r: parseInt(this.state.ledColor.slice(1, 3), 16),
        g: parseInt(this.state.ledColor.slice(3, 5), 16),
        b: parseInt(this.state.ledColor.slice(5, 7), 16),
        a: 1,
      };
    }
    return color;
  }

  private renderColorPicker(): Maybe<JSX.Element> {
    if (this.state.displayColorPicker) {
      return (
        <div className="popover" onClick={this.toggleColorPicker}>
          <CompactPicker
            color={this.state.ledColor}
            onChange={this.ledColorChanged}
          />
        </div>
      );
    }
  }

  private getLoader(key: string): Maybe<JSX.Element> {
    // TODO: better state handling in settings
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (this.props.deviceState.beingApplied(key as any)) {
      return (
        <Loader
          size="small"
          topBottomPadding="0"
          leftRightPadding="0.2rem"
        />
      );
    }
  }

  public render(): Nullable<JSX.Element> {
    if (!this.props.deviceState) {
      console.error("Settings general page does not have device state");
      return null;
    }

    let deviceTypeSpecificContent: Nullable<JSX.Element> = null;

    switch (this.props.deviceType) {
      case SuperHW.type:
        deviceTypeSpecificContent = this.renderSuperHWContent();
        break;
      case HyperHW.type:
        deviceTypeSpecificContent = this.renderHyperLikeHWContent();
        break;
      case SereneHW.type:
        deviceTypeSpecificContent = this.renderSereneHWContent();
        break;
      case PiikkioHW.type:
        deviceTypeSpecificContent = this.renderHyperLikeHWContent();
        break;
      case RuuviGWHW.type:
        deviceTypeSpecificContent = this.renderRuuviGWHWContent();
        break;
      case SensorHW.type:
        /* no device type specific content exists for this type */
        break;
      case RuuviTagHW.type:
        /* no device type specific content exists for this type */
        break;
      default:
        console.error("Unknown device type");
        break;
    }
    return (
      <Grid container={true} direction="column" spacing={2}>
        <Grid item={true}>
          <List>
            <SettingsListItem label="Display name">
              <TextField
                value={this.state.displayName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                  this.displayNameChanged(event)}
              />
              {this.getLoader("displayName")}
            </SettingsListItem>
            <SettingsListItem label="Device type">{this.props.deviceType}</SettingsListItem>
            {deviceTypeSpecificContent}
            <Grid item={true}>
              <SettingsControls
                submitButtonDisabled={!this.state.changesMade}
                submitButtonLabel={this.text("Actions", "apply")}
                cancelButtonLabel={this.text("Actions", "cancel")}
                onSave={this.saveDeviceSettings}
                onCancel={this.handleCancel}
              />
            </Grid>
          </List>
        </Grid>
      </Grid>
    );
  }

  private renderSuperHWContent(): JSX.Element {
    const { r, g, b, a } = this.getRgbaLedColor();

    return (
      <Fragment>
        <SettingsListItem label={this.text("DeviceSettings", "toggleLed")}>
          <Switch
            edge="end"
            onChange={this.ledStateChanged}
            checked={this.state.ledEnabled}
            color="primary"
          />
          {this.getLoader("ledEnabled")}
        </SettingsListItem>
        <SettingsListItem label={this.text("DeviceSettings", "ledColor")}>
          <div className="swatch" onClick={this.toggleColorPicker}>
            <div
              className="color"
              style={{
                background: `rgba(${r}, ${g}, ${b}, ${a})`,
              }}
            />
          </div>
          {this.getLoader("ledColor")}
        </SettingsListItem>
        {this.renderColorPicker()}
        <SettingsListItem label={this.text("DeviceSettings", "firmwareVersion")}>
          {this.state.firmwareVersion}
        </SettingsListItem>
        <SettingsListItem label={this.text("DeviceSettings", "resetCount")}>{this.state.resetCount}</SettingsListItem>
      </Fragment>
    );
  }

  private renderHyperLikeHWContent(): JSX.Element {
    return (
      <Fragment>
        <SettingsListItem label="Firmware version">
          {this.state.firmwareVersion}
        </SettingsListItem>
        <SettingsListItem label={this.text("DeviceSettings", "disableModemSleep")}>
          <Switch
            edge="end"
            onChange={this.noModemSleepChanged}
            checked={this.state.noModemSleep}
            color="primary"
          />
          {this.getLoader("noModemSleep")}
        </SettingsListItem>
        <SettingsListItem label={this.text("DeviceSettings", "updateInterval")}>
          <TextField
            value={this.state.updateInterval}
            inputMode="numeric"
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.updateIntervalChanged(event)}
          />
          {this.getLoader("updateInterval")}
        </SettingsListItem>
        <SettingsListItem label={this.text("DeviceSettings", "measurementInterval")}>
          <TextField
            value={this.state.measurementInterval}
            inputMode="numeric"
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.measurementIntervalChanged(event)}
          />
          {this.getLoader("measurementInterval")}
        </SettingsListItem>
        <SettingsListItem label={this.text("DeviceSettings", "firmwareVersion")}>
          {this.state.firmwareVersion}
        </SettingsListItem>
        <SettingsListItem label={this.text("DeviceSettings", "resetCount")}>{this.state.resetCount}</SettingsListItem>
      </Fragment>
    );
  }
  
  private renderSereneHWContent(): JSX.Element {
    return (
      <Fragment>
        <SettingsListItem label="Firmware version">
          {this.state.firmwareVersion}
        </SettingsListItem>
        <SettingsListItem label={this.text("DeviceSettings", "disableModemSleep")}>
          <Switch
            edge="end"
            onChange={this.noModemSleepChanged}
            checked={this.state.noModemSleep}
            color="primary"
          />
          {this.getLoader("noModemSleep")}
        </SettingsListItem>
        <SettingsListItem label={this.text("DeviceSettings", "logs")}>
          <Switch
            edge="end"
            onChange={this.logsChanged}
            checked={this.state.logs}
            color="primary"
          />
          {this.getLoader("logs")}
        </SettingsListItem>
        <SettingsListItem label={this.text("DeviceSettings", "updateInterval")}>
          <TextField
            value={this.state.updateInterval}
            inputMode="numeric"
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.updateIntervalChanged(event)}
          />
          {this.getLoader("updateInterval")}
        </SettingsListItem>
        <SettingsListItem label={this.text("DeviceSettings", "measurementInterval")}>
          <TextField
            value={this.state.measurementInterval}
            inputMode="numeric"
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.measurementIntervalChanged(event)}
          />
          {this.getLoader("measurementInterval")}
        </SettingsListItem>
        <SettingsListItem label={this.text("DeviceSettings", "firmwareVersion")}>
          {this.state.firmwareVersion}
        </SettingsListItem>
        <SettingsListItem label={this.text("DeviceSettings", "resetCount")}>{this.state.resetCount}</SettingsListItem>
      </Fragment>
    );
  }

  private renderRuuviGWHWContent(): JSX.Element {
    const state = getDeviceStateAsRuuviGW(this.props.deviceState);
    return <RuuviGWStateSettings deviceState={state} setChangesMade={(): void => this.setState({ changesMade: true })}/>;
  }
}
