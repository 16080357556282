import React, { Component, RefObject } from "react";
import { fade, Theme, withStyles, withTheme } from "@material-ui/core/styles";
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import { Maybe, Nullable } from "types/aliases";

let waitId: NodeJS.Timeout | null = null;
let waitingList: STreeItem[] = [];

function doIt (): void {
  waitId = null;
  waitingList.map((item): void => item.setState({ rootLIElementClientHeight: item.rootLIElement.current?.clientHeight ?? 0 }));
  waitingList = [];
}

function waitForIt (): void {
  if (waitId !== null) clearTimeout(waitId);
  waitId = setTimeout(doIt, 1);
}

interface Props {
  collapseIcon?: React.ReactNode;
  dense?: boolean;
  endIcon?: React.ReactNode;
  endLevelItem?: boolean;
  expanded?: boolean;
  expandIcon?: React.ReactNode;
  hideExtraBorder?: boolean;
  allowRecursiveChildren?: boolean;
  hideIconContainer?: boolean;
  icon?: React.ReactNode;
  inlineStyle?: React.CSSProperties;
  label?: React.ReactNode;
  nodeId: string;
  noTogglerIcon?: boolean;
  onIconClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  onLabelClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  rootItem?: boolean;
  theme: Theme;
}
interface State {
  clientHeightChangePending: boolean;
  rootLIElementClientHeight: Nullable<number>;
  StyledTreeItem: React.ComponentType<TreeItemProps>;
}

class STreeItem extends Component<Props, State> {
  public rootLIElement: RefObject<HTMLLIElement>;

  public constructor(props: Props) {
    super(props);
    this.state = {
      clientHeightChangePending: false,
      rootLIElementClientHeight: null,
      StyledTreeItem: this.getStyledTreeItem(),
    };
    this.rootLIElement = React.createRef();
  }

  public componentDidMount(): void {
    if (this.props.hideExtraBorder && this.rootLIElement.current) {
      if (this.props.allowRecursiveChildren) {
        waitingList.push(this);
        waitForIt();
      } else {
        this.setState({ rootLIElementClientHeight: this.rootLIElement.current.clientHeight });
      }
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (this.props.theme !== prevProps.theme) {
      this.setState({ 
        StyledTreeItem: this.getStyledTreeItem(),
      });
    } 
    
    if (this.props.hideExtraBorder) {
      if (this.props.expanded !== prevProps.expanded) {
        // reading this.rootLIElement.current.clientHeight here gives the previous height -> trigger additional update in state to have correct current height
        if (this.props.expanded !== undefined) {
          this.setState({ 
            clientHeightChangePending: true, 
            StyledTreeItem: this.getStyledTreeItem(),
          });
        }
      } 
    
      if (this.state.clientHeightChangePending && !prevState.clientHeightChangePending) {
        // reading this.rootLIElement.current.clientHeight gives correct current value here
        if (this.rootLIElement.current) {
          if (this.props.allowRecursiveChildren) {
            waitingList.push(this);
            waitForIt();
          } else {
            this.setState({ rootLIElementClientHeight: this.rootLIElement.current.clientHeight });
          }
        }
      } 
    
      if (this.state.rootLIElementClientHeight !== prevState.rootLIElementClientHeight) {
        this.setState({ 
          StyledTreeItem: this.getStyledTreeItem(),
          clientHeightChangePending: false,
        });
      }
    }
  }
  
  //TODO: fix styling for selected item and for item that can't be selected
  private getStyledTreeItem(): React.ComponentType<TreeItemProps> {
    const { 
      hideIconContainer, 
      endLevelItem,
      dense,
      hideExtraBorder,
      theme,
      rootItem,
    } = this.props;

    return (
      withStyles({
        iconContainer: {
          display: hideIconContainer ? "none" : "flex",
          width: endLevelItem ? "0rem" : "1.5rem", 
          marginRight: "0.5rem",
          position: "relative", 
          "& svg": {
            fontSize: "initial",
          },
          "& .close": {
            opacity: 0.3,
          },
          "&::before": {
            // eslint-disable-next-line quotes
            content: '""',
            position: "absolute",
            display: "inline-block",
            height: "1px", 
            width: dense ? "1rem" : "2rem",
            backgroundColor: `${fade("#454545", 0.4)}`, // from props
            top: "50%",
            right: endLevelItem ? "0rem" : "1.5rem",
          },
        },
        root: {
          marginBottom: hideIconContainer ? "0.25rem" : 0,
          position: "relative",
          padding: "0.25rem 0",
          "&::after": hideExtraBorder ? this.getBorderHidingPseudoElement() : undefined,
        },
        content: {
          "&::after": rootItem ? this.getVerticalLineForRootItem() : undefined,
        },
        group: {
          marginLeft: dense ? "0.5rem" : "0.6875rem", // half of the size of toggler icon -> vertical line is aligned to the middle of icon
          paddingLeft: dense ? "1rem" : "2rem",
          borderLeft: !rootItem ? `1px solid ${fade("#454545", 0.4)}` : undefined, // TODO: read from props
        },
        label: {
          display: "flex", // set label text and delete button next to each other
          alignItems: "center",
          padding: hideIconContainer ? "0" : "inherit", 
          right: "auto",
          backgroundColor: "inherit !important", // remove default background-color
          "& p": { // set space between label text and delete button
            marginRight: dense ? undefined : "1rem",
          },
          "& p:hover": { 
            backgroundColor: theme.palette.type === "dark"
              ? "rgba(255, 255, 255, 0.08)" 
              : "rgba(0, 0, 0, 0.04)",
          },
          "& svg": { // set space between icon and text
            marginRight: "0.5rem",
          },
        },
        selected: {
          backgroundColor: undefined,
        },
      })(TreeItem)
    );
  }

  public render(): JSX.Element {  
    return (
      <this.state.StyledTreeItem
        collapseIcon={this.props.collapseIcon}
        endIcon={this.props.endIcon}
        expandIcon={this.props.expandIcon}
        icon={this.props.icon}
        key={this.props.nodeId}
        label={this.props.label}
        nodeId={this.props.nodeId}
        onIconClick={this.props.onIconClick}
        onLabelClick={this.props.onLabelClick}
        ref={this.rootLIElement}
        style={this.props.inlineStyle}
      > 
        {this.props.children}
      </this.state.StyledTreeItem>
    );
  }

  private getVerticalLineForRootItem(): {[property: string]: Maybe<string>} {
    return {
      // eslint-disable-next-line quotes
      content: '""',
      height: "1.1rem",
      width: "1px",
      display: "block",
      backgroundColor: `${fade("#454545", 0.4)}`,
      position: "absolute",
      top: "2rem",
      left: "0.6875rem",
    };
  }

  private getBorderHidingPseudoElement(): {[property: string]: Maybe<string>} {
    return {
      // eslint-disable-next-line quotes
      content: '""',
      height: this.getHeightOfBorderHidingPseudoElement(),
      width: "1rem",
      display: "block",
      backgroundColor: this.props.theme.palette.background.default,
      position: "absolute",
      bottom: "0rem",
      borderTop: "1px solid transparent",
      left: this.props.dense ? "-2rem" : "-3rem",
    };
  }

  private getHeightOfBorderHidingPseudoElement(): Maybe<string> {
    if (this.state?.rootLIElementClientHeight) {
      // clientHeight === current height of the element 
      // 1.2rem === amount that needs to removed from height to hide border from bottom up to horizontal line
      const hideLength = this.props.dense ? "1rem" : "1.2rem";
      return `calc(${this.state.rootLIElementClientHeight}px - ${hideLength})`;
    } else {
      return undefined;
    }
  }
}

export default withTheme(STreeItem);

