import { Divider, Menu } from "@material-ui/core";
import React, { Fragment } from "react";
import { Nullable } from "types/aliases";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SButton from "./SButton";
import { CustomSpacingProps } from "types/sensoanUiTypes";
import { SSvgIconColorProps } from "./SSvgIcon";

interface State {
  menuAnchorEl: Nullable<HTMLButtonElement>;
}

interface Props {
  buttonText: string;
  buttonWidthInRems?: number; 
  m?: CustomSpacingProps;
  mt?: CustomSpacingProps;
  mr?: CustomSpacingProps;
  mb?: CustomSpacingProps;
  ml?: CustomSpacingProps;
}

class SSelect extends React.Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      menuAnchorEl: null,
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.buttonText !== this.props.buttonText) {
      this.setState({ menuAnchorEl: null });
    }
  }

  public render(): JSX.Element {
    const elements: React.ReactElement[] = this.props.children ?
      React.Children.map(this.props.children, element => {
        if (React.isValidElement(element)) {
          return element;
        }
      }) ?? []
      : [];
    const menuItemsArray: React.ReactElement[] = [];
    elements.map((element, index): void => {
      menuItemsArray.push(element);

      if (index < elements.length - 1) {
        menuItemsArray.push(<Divider key={index} />);
      }
    });
    return (
      <Fragment>
        <SButton
          m={this.props.m}
          mt={this.props.mt}
          mb={this.props.mb}
          ml={this.props.ml}
          mr={this.props.mr}
          ariaControls="sselect" 
          ariaHaspopup="true"        
          color="secondary"
          endIcon={ExpandMoreIcon}
          iconColor={SSvgIconColorProps.textPrimary}
          labelText={this.props.buttonText}
          onClick={(event: React.MouseEvent<HTMLButtonElement>): void => this.setState({ menuAnchorEl: event.currentTarget })}
          widthInRems={this.props.buttonWidthInRems}
        />
        <Menu
          anchorEl={this.state.menuAnchorEl}
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }} 
          keepMounted
          open={this.state.menuAnchorEl !== null}
          onClose={(): void => this.setState({ menuAnchorEl: null })}
        >
          {menuItemsArray.map((item): JSX.Element => item)}
        </Menu>
      </Fragment>
    );
  }
}

export default SSelect;
