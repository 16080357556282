import React, { Component, Fragment, ReactNode } from "react";
import { Box, Divider } from "@material-ui/core";
import { Maybe, Nullable } from "types/aliases";
import * as Utils from "data/utils/Utils";
import { ChartConfig, MeasurementSetConfig, MeasurementSetType } from "data/types/measurementSetTypes";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import MeasurementSetDataContainer from "data/measurement-data/MeasurementSetDataContainer";
import MeasurementSetCharts from "components/charts/MeasurementSetCharts";
import { PREVIEW_SET_DISPLAY_NAME, PREVIEW_SET_ID, PREVIEW_TIMESPAN } from "../chart-configs/data-visualization-preview/DataVisualizationPreview";
import LoaderSensoan from "components/layout/LoaderSensoan";

interface Props {
  chartConfig: ChartConfig[];
  deleteChart?: (index: Maybe<number>) => void;
  editChart?: (index: Maybe<number>) => void;
  historyLength?: number;
}

interface State {
  dataInitialized: boolean;
  measurementSetData: Nullable<MeasurementSetDataContainer>;
  measurementSetForPreview: Nullable<MeasurementSetConfig>;
}

class SetsChartList extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      dataInitialized: false,
      measurementSetData: null,
      measurementSetForPreview: null,
    };
    this.onMeasurementSetDataReady = this.onMeasurementSetDataReady.bind(this);
  }

  public componentDidMount(): void {
    this.setState({
      dataInitialized: false,
      measurementSetData: this.buildMeasurementSetDataContainer(),
    });
  }

  public componentDidUpdate(prevProps: Props): void {

    if (this.props.historyLength !== prevProps.historyLength) {

      this.setState({
        dataInitialized: false,
        measurementSetData: this.buildMeasurementSetDataContainer(),
      });
    }

    if (this.props.chartConfig.length !== prevProps.chartConfig.length) {
      this.setState({
        dataInitialized: false,
        measurementSetData: this.buildMeasurementSetDataContainer(),
      });
    }
  }

  public onMeasurementSetDataReady(): void {
    this.setState({ dataInitialized: true, measurementSetForPreview: this.buildMeasurementSet() });
  }

  public render(): ReactNode {
    return (
      this.state.dataInitialized
        ?
        <Fragment>
          <Box marginY="2rem">
            <MeasurementSetCharts
              deleteChart={this.props.deleteChart}
              editChart={this.props.editChart}
              measurementSetData={this.state.measurementSetData}
              selectedMeasurementSet={this.state.measurementSetForPreview}
            />
          </Box>
          <Divider/>
        </Fragment>
        :
        <LoaderSensoan/>
    );
  }

  private buildMeasurementSetDataContainer(): MeasurementSetDataContainer {
    return new MeasurementSetDataContainer(this.buildMeasurementSet(), Date.now(), Utils.convertHoursToTimestamp(this.props.historyLength ?? PREVIEW_TIMESPAN), this.onMeasurementSetDataReady);
  }

  private buildMeasurementSet(): MeasurementSetConfig {
    return ({
      setId: PREVIEW_SET_ID,
      parentIds: [MeasurementSetRepository.getInstance().getTree()[0].setId],
      type: MeasurementSetType.CONFIG,
      displayName: PREVIEW_SET_DISPLAY_NAME,
      config: {
        chartConfig: this.props.chartConfig,
      },
    });
  }
}

export default SetsChartList;
