import React, { Component } from "react";
import { IconButton, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import GroupWork from "@material-ui/icons/GroupWork";
import RemoveIcon from "@material-ui/icons/Remove";
import ClientProperties from "data/clientSpecific/ClientProperties";
import DeviceGroup from "data/device/DeviceGroup";
import { Maybe } from "types/aliases";
import BackendFactory from "data/BackendFactory";
import Localization from "data/localization-sensoan/Localization";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";

interface Props {
  group: DeviceGroup;
  onRemoveGroup: (group: DeviceGroup) => void;
  onAddGroup: (parent: DeviceGroup) => void;
  editMode?: boolean;
}

export default class TreeFolder extends Component<Props> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
  }

  private handleAddGroupClick = (event: React.MouseEvent<unknown>): void => {
    event.stopPropagation();
    this.props.onAddGroup(this.props.group);
  };

  private handleRemoveGroupClick = (event: React.MouseEvent<unknown>): void => {
    event.stopPropagation();
    this.props.onRemoveGroup(this.props.group);
  };

  private handleDragStart = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
  };

  private handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
    if (this.props.editMode) {
      event.preventDefault();
      event.stopPropagation();
      // TODO: Define proper color
      event.currentTarget.style.backgroundColor = "#AAAAAA";
    }
  };

  private handleDragLeave = (event: React.DragEvent<HTMLDivElement>): void => {
    if (this.props.editMode) {
      event.preventDefault();
      event.stopPropagation();
      // TODO: Define proper color
      event.currentTarget.style.backgroundColor = "#FFFFFF";
    }
  };

  private handleDrop = async (event: React.DragEvent<HTMLDivElement>): Promise<void> => {
    if (this.props.editMode) {
      event.preventDefault();
      event.stopPropagation();
      // TODO: Define proper color
      event.currentTarget.style.backgroundColor = "#FFFFFF";

      const deviceId = event.dataTransfer.getData("device");
      const sourceGroupId = event.dataTransfer.getData("group");

      if (this.props.group.getId() === sourceGroupId) {
        return;
      }

      if (deviceId) {
        const device = await BackendFactory.getBackend().getDevice(deviceId);

        if (device) {
          if (sourceGroupId) {
            const sourceGroup = await BackendFactory.getBackend().getDeviceGroup(sourceGroupId);
            await sourceGroup?.removeDevice(device);
          }
          await this.props.group.addDevice(device);
        }
      }
    }
  };

  private canAddChildGroup(): boolean {
    return !!this.props.editMode && this.props.group.childGroupCanBeAdded();
  }

  private groupCanBeRemoved(): boolean {
    return !!this.props.editMode && this.props.group.canBeDeleted();
  }

  private renderAddSubGroupIcon(): Maybe<JSX.Element> {
    if (this.canAddChildGroup()) {
      return (
        <IconButton
          title={this.text("AdminView", "addSubGroup")}
          onClick={this.handleAddGroupClick}
          style={{ backgroundColor: "#e6e6e6" }}
          size="small"
        >
          <AddIcon />
        </IconButton>
      );
    }
  }

  private renderRemoveGroupIcon(): Maybe<JSX.Element> {
    if (this.groupCanBeRemoved()) {
      return (
        <IconButton
          title={this.text("AdminView", "removeGroup")}
          onClick={this.handleRemoveGroupClick}
          style={{ backgroundColor: "#e6e6e6" }}
          size="small"
        >
          <RemoveIcon />
        </IconButton>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <div
        className="tree-folder"
        onDragStart={this.handleDragStart}
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDrop}
      >
        <div className="iot-item-link col-sm-8 col-xsm-8">
          <div
            className="iot-icon col-sm-1 col-xsm-1"
            data-tip={ClientProperties.getAlarmTooltipFromEntities([])}
          >
            <SSvgIcon color={SSvgIconColorProps.primary} iconComponent={GroupWork} />
          </div>
          <div className="iot-name col-sm-10 col-xsm-10">
            <Typography color="textPrimary">{this.props.group.getLabel()}</Typography>
            {
              this.props.editMode &&
              <Typography variant="body2" color="textSecondary">{this.props.group.getId()}</Typography>
            }
          </div>
        </div>
        <div className="iot-status-container col-sm-4 col-xsm-4">
          {/* Uncommenting these lines will re-enable device group adding and removing */}
          {/* {this.renderAddSubGroupIcon()}
          {this.renderRemoveGroupIcon()} */}
        </div>
      </div>
    );
  }
}
