import React, { Component, Fragment, ReactNode } from "react";
import { v4 as uuid } from "uuid";
import { Box, Divider, Popover } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Maybe, Nullable } from "types/aliases";
import Localization from "data/localization-sensoan/Localization";
import { ChartConfig, DataConfig, MeasurementSetConfig, MeasurementSetTreeItem, SetConfig } from "data/types/measurementSetTypes";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import MeasurementSetSelector from "data/measurement-set-selector/MeasurementSetSelector";
import { ChartConfigValidityStatus, LocationInfo, MapLocationEventResult, MeasSetEditTarget, MeasSetLocationOption, MeasSetsButtonActions } from "types/sensoanUiTypes";
import MapLink, { ComponentToMapLink, IconStatus, IconTypes, MapEventObserver, MapLocation, MapMarkerData, MapState } from "data/map/MapLink";
import Section from "components/layout/Section";
import ErrorMessage from "components/layout/ErrorMessage";
import SetsDetailsConfiguration from "components/measurement-sets/create-new-view/sets-details-configuration/SetsDetailsConfiguration";
import ChartConfigCreateView from "components/measurement-sets/create-new-view/chart-configs/ChartConfigsCreateView";
import SetsChartList from "./sets-chart-list/SetsChartList";
import SButton from "components/styled-components/SButton";
import withDataJanitor from "components/hocs/DataJanitor";
import * as Utils from "data/utils/Utils";
import { DataRepositories } from "data/data-storage/DataRepositoryFactory";
import { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import DeviceRepository from "data/data-storage/DeviceRepository";
import DeviceNavigationCache from "utils/DeviceNavigationCache";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { parseDeviceId } from "utils/NavigationUtils";
import Device from "data/device/Device";

interface Props extends RouteComponentProps {
  actionRequest: Nullable<MeasSetsButtonActions>;
  chartConfigDisplayName: string;
  chartConfigValidityStatus: ChartConfigValidityStatus;
  clearActionRequest: () => void;
  clearSelectedGroup: () => void;
  closeEditChartConfigMode: () => void;
  editChartConfigMode: boolean;
  onNameChange: (text: string, target: MeasSetEditTarget) => void;
  onSelectMeasGroup: (event: React.MouseEvent<Element, MouseEvent>, measurementSetGroup: MeasurementSetTreeItem) => void;
  saveChartConfigAfterScaleMsg: () => void;
  selectedMeasGroupId: string;
  setChartConfigValidityStatus: (status: ChartConfigValidityStatus) => void;
  setDisplayName: string;
  startCreateNew: (target: MeasSetEditTarget) => void;
  startEditExisting: (target: MeasSetEditTarget, targetName: string) => void;
}

interface State {
  aggregateLength: Nullable<number | string>; // union type to show empty inputs before user decides to set values
  chartConfig: Nullable<ChartConfig[]>;
  // REFACTOR: remove (not used anywhere)
  deviceLocationLoadingTextNeeded: boolean;
  errorPopUpOpen: boolean;
  gpsFunction: Nullable<{
    deviceId: string;
  }>;
  // TODO: remove and use only value in placeLocation
  gpsLocation: Nullable<{
    latitude: number;
    longitude: number;
  }>;
  placeLocation: Nullable<LocationInfo>; // stores the result of map search and is used in SetsLocationSelector
  historyLength: Maybe<number>;
  metadata: string;
  locationOption: Nullable<MeasSetLocationOption>;
  selectedChartConfigIndex: Nullable<number>;
}

class SetsCreateView extends Component<Props, State> implements MapEventObserver {

  private map: Nullable<ComponentToMapLink> = null;
  private measurementSetRepo = MeasurementSetRepository.getInstance();
  private selectedSet: Nullable<MeasurementSetConfig> = null;

  public constructor(props: Props) {
    super(props);
    this.selectedSet = MeasurementSetSelector.getInstance().getSelectedMeasurementSet();
    this.state = this.selectedSet ? this.getStateValuesFromSelectedSet() : this.getEmptyState();
    this.isDeviceLocationMsgNeeded = this.isDeviceLocationMsgNeeded.bind(this);
    this.saveChartConfig = this.saveChartConfig.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    await DeviceNavigationCache.getInstance().setCurrentDevice(undefined);

    if (this.selectedSet) {
      this.props.onNameChange(this.selectedSet.displayName, "measurementSet");

      if (this.selectedSet.config.gpsFunction || this.selectedSet.config.gpsLocation) {
        await this.setLocationFromSelectedSet(this.selectedSet);
      }
      // TODO: remove unneeded else block?
    } else {
      this.props.onNameChange("", "measurementSet");
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (this.props.actionRequest !== null && prevProps.actionRequest === null) {
      if (this.props.actionRequest === MeasSetsButtonActions.saveSet) {
        this.saveMeasurementSet();
        return this.props.clearActionRequest();
      }
    }

    //Clear selectedChartConfigIndex if the user selects an existing set for editing and presses returns to this view
    if (prevProps.editChartConfigMode === true && this.props.editChartConfigMode === false && this.state.selectedChartConfigIndex !== null) {
      this.setState({ selectedChartConfigIndex: null });
    }

    // disable taps on map and set MapState to default when user edits chart config
    if (prevProps.editChartConfigMode === false && this.props.editChartConfigMode === true && this.state.locationOption === MeasSetLocationOption.gpsLocation) {
      this.map?.disableMouseInteraction();
      this.map?.setMapState(MapState.DEFAULT);
    }

    // re-enable taps on map and set MapState back to active when user returns from ChartConfigEditView to this view
    if (prevProps.editChartConfigMode === true && this.props.editChartConfigMode === false && this.state.locationOption === MeasSetLocationOption.gpsLocation) {
      this.map?.enableMouseInteraction();
      this.map?.setMapState(MapState.ACTIVE);
    }

    if (this.state.locationOption === MeasSetLocationOption.gpsLocation && this.map === null) {
      this.setupMapConnection();
    }

    if (this.state.locationOption !== MeasSetLocationOption.gpsLocation && this.map !== null) {
      this.endMapConnection();
    }

    // clear deviceLocationLoadingTextNeeded after the first change in this.state.locationOption
    if (this.state.deviceLocationLoadingTextNeeded && this.state.locationOption !== prevState.locationOption) {
      this.setState({ deviceLocationLoadingTextNeeded: false });
    }

    // detect changes in device selection which is indicated by a search string in URL taking the following form: pathname?deviceId=exampleDeviceId
    const deviceSelectionChanged = parseDeviceId(this.props) !== parseDeviceId(prevProps);

    if (deviceSelectionChanged) {
      const deviceId = parseDeviceId(this.props);
      // deviceId search string (and device selection) was cleared if deviceId === null
      this.handleDeviceChange(deviceId ? DeviceRepository.getInstance().getDevice(deviceId) : undefined);
    }
  }

  public async componentWillUnmount(): Promise<void> {
    this.map?.enableMouseInteraction();
    this.endMapConnection();
    await DeviceNavigationCache.getInstance().navigateToDevice(this.props, undefined);
  }

  public onMapLocationEvent?(eventResult: Nullable<MapLocationEventResult>): void {
    if (!eventResult) {
      this.setState({ placeLocation: null });
      // TODO: fix so that handleClearButtonClick in MapSearchBar doesn't cause this:
      // this.setState({ placeLocation: (): string => Localization.getInstance().getDisplayText("MeasurementSetEditView", "locationError") });
    } else if (eventResult === "noPlaceFound") {
      this.setState({
        placeLocation: (): string => Localization.getInstance().getDisplayText("MeasurementSetEditView", "locationNotFound"),
      });
      this.map?.removeAllLocations();
    } else {
      const mapData: MapMarkerData = {
        id: uuid(),
        type: IconTypes.measurementSet,
        location: (): MapLocation => { return { lat: eventResult.position.lat, lng: eventResult.position.lng }; },
        status: () => IconStatus.selected,
      };
      this.map?.replaceData([mapData]);

      if (!this.map?.isInCurrentView(mapData.location())) {
        this.map?.centerMap([mapData.location(), this.map!.getCurrentCenter()], true);
      }
      const gpsLocation = { latitude: eventResult.position.lat, longitude: eventResult.position.lng };
      this.setState({ gpsLocation, placeLocation: eventResult });
    }
  }

  private renderChartConfigEditView(): ReactNode {
    return (
      <ChartConfigCreateView
        actionRequest={this.props.actionRequest}
        aggregateLength={this.state.aggregateLength}
        chartConfig={this.state.selectedChartConfigIndex !== null ? (this.state.chartConfig as ChartConfig[])[this.state.selectedChartConfigIndex as number] : null}
        chartConfigValidityStatus={this.props.chartConfigValidityStatus}
        clearActionRequest={this.props.clearActionRequest}
        displayName={this.props.chartConfigDisplayName}
        index={this.state.selectedChartConfigIndex}
        onAggregateLengthChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.setState({ aggregateLength: event.target.value as unknown as number })}
        onNameChange={this.props.onNameChange}
        saveChartConfig={this.saveChartConfig}
        saveChartConfigAfterScaleMsg={this.props.saveChartConfigAfterScaleMsg}
        setChartConfigValidityStatus={this.props.setChartConfigValidityStatus}
        toggleAggregateLength={(value: Nullable<string>): void => this.setState({ aggregateLength: value })}
      />
    );
  }

  private renderChartButtonAndList(): ReactNode {
    return (
      <Fragment>
        <Box display="flex" justifyContent="flex-end" alignItems="center" height="4rem" pb={4}>
          <Box>
            <SButton
              endIcon={AddIcon}
              fontWeight="medium"
              iconColor={SSvgIconColorProps.white}
              labelText={Localization.getInstance().getDisplayText("MeasurementSetEditView", "addNewChartConfig")}
              onClick={(): void => this.props.startCreateNew("chartConfig")}
            />
          </Box>
        </Box>
        <Divider/>
        {this.state.chartConfig !== null
        &&
        <SetsChartList
          chartConfig={this.state.chartConfig}
          deleteChart={(index: Maybe<number>): void => this.deleteChartConfig(index)}
          editChart={(index: Maybe<number>): void => this.startEditChartConfig(index)}
          historyLength={this.state.historyLength}
        />
        }
      </Fragment>
    );
  }

  private renderSetConfigSelectorAndChartList(): ReactNode {
    return (
      <Fragment>
        {this.state.errorPopUpOpen &&
        this.renderErrorPopup()
        }
        <Section
          title={Localization.getInstance().getDisplayText("Common", "generalInfo")}
          titleTextStyle="h6"
          variant="foldable">
          <SetsDetailsConfiguration
            clearSelectedGroup={this.props.clearSelectedGroup}
            locationDeviceName={this.getLocationDeviceName()}
            deviceLocationMsgNeeded={this.isDeviceLocationMsgNeeded()}
            disableParentGroupEditing={this.selectedSet !== null}
            displayName={this.props.setDisplayName}
            metadata={this.state.metadata}
            onSelectMeasGroup={this.props.onSelectMeasGroup}
            onNameChange={this.props.onNameChange}
            onHistoryLengthChange={(option: number): void => this.setState({ historyLength: option === this.state.historyLength ? undefined : option })}
            onLocationOptionChange={(option): Promise<void> => this.onLocationOptionChange(option)}
            onMetadataChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.setState({ metadata: event.target.value as string })}
            placeLocation={this.state.placeLocation}
            selectedMeasGroupId={this.props.selectedMeasGroupId}
            selectedLocationOption={this.state.locationOption}
            historyLength={this.state.historyLength}
          />
        </Section>
        <Section
          title={Localization.getInstance().getDisplayText("MeasurementSetEditView", "sensorViews")}
          titleTextStyle="h6"
          variant="foldable">
          {this.renderChartButtonAndList()}
        </Section>
      </Fragment>
    );
  }

  private getLocationDeviceName(): Nullable<string> {
    if (this.state.gpsFunction?.deviceId) {
      const device = DeviceRepository.getInstance().getDevice(this.state.gpsFunction.deviceId);
      return Utils.getDisplayName(device);
    } else {
      return null;
    }
  }

  private renderErrorPopup(): ReactNode {
    const id = this.state.errorPopUpOpen ? "save-chart-config-popup" : undefined;
    return (
      <Popover
        anchorReference="anchorPosition"
        anchorPosition={{ top: 200, left: 800 }}
        id={id}
        PaperProps={{ style: { height: "4rem", display: "flex", alignItems: "center", border: "1px solid #0069FF" } }}
        open={this.state.errorPopUpOpen}
        onClose={(): void => this.setState({ errorPopUpOpen: false })}
      >
        <ErrorMessage customMessage={Localization.getInstance().getDisplayText("MeasurementSetEditView", "validationError")}/>
      </Popover>
    );
  }

  public render(): ReactNode {
    return (
      this.props.editChartConfigMode
        ?
        this.renderChartConfigEditView()
        :
        this.renderSetConfigSelectorAndChartList()
    );
  }

  public handleDeviceChange(device?: Nullable<Device>): void {
    if (device) {
      this.setState({ gpsFunction: { deviceId: device.getId() } });
    } else {
      this.setState({ gpsFunction: null });
    }
  }

  private async onLocationOptionChange(option: MeasSetLocationOption): Promise<void> {
    if (option === this.state.locationOption) {
      this.setState({ locationOption: null });
    } else {
      this.setState({ locationOption: option });
    }

    if (MapLink.getDataStorage().getMarkerData().length > 0) {
      MapLink.getLinkToMap().removeAllLocations();
    }

    if (this.state.placeLocation) {
      this.setState({ placeLocation: null });
    }

    if (this.state.gpsLocation !== null) {
      this.setState({ gpsLocation: null });
    } else if (this.state.gpsFunction !== null) {
      // sets current device to undefined which will set this.state.gpsFunction to null:
      await DeviceNavigationCache.getInstance().navigateToDevice(this.props, undefined);
    }
  }

  private setupMapConnection(): void {
    this.map = MapLink.getLinkToMap();
    this.map.addObserver(this);
    this.map.setMapState(MapState.ACTIVE);
  }

  private endMapConnection(): void {
    if (this.map !== null) {
      this.map.removeAllLocations();
      this.map.removeObserver(this);
      this.map.setMapState(MapState.DEFAULT);
      this.map = null;
    }
  }

  private saveMeasurementSet(): void {

    if (this.validateMeasurementSet()) {
      const setConfigWithRequiredFields: SetConfig = {
        chartConfig: this.state.chartConfig as ChartConfig[],
      };
      const setConfigWithOptionalFields: SetConfig = {
        ...setConfigWithRequiredFields,
        ...(this.state.historyLength && { historyLength: this.state.historyLength }),
        ...(this.state.aggregateLength && { aggregateLength: this.state.aggregateLength as number }),
        ...(this.state.gpsLocation && { gpsLocation: this.state.gpsLocation }),
        ...(this.state.gpsFunction && { gpsFunction: this.state.gpsFunction }),
      };

      // adding new triggers selectedMeasSet change
      // changing selectedMeasSet closes editSetMode in MeasurementSetsWrapper
      if (this.selectedSet) {
        this.measurementSetRepo.edit(
          this.selectedSet.setId,
          this.props.setDisplayName,
          this.state.metadata,
          setConfigWithOptionalFields,
        );
      } else {
        this.state.metadata === ""
          ?
          this.measurementSetRepo.addConfig(
            this.props.setDisplayName,
            [this.props.selectedMeasGroupId],
            setConfigWithOptionalFields,
          )
          :
          this.measurementSetRepo.addConfig(
            this.props.setDisplayName,
            [this.props.selectedMeasGroupId],
            setConfigWithOptionalFields,
            this.state.metadata,
          );
      }
    } else {
      this.setState({ errorPopUpOpen: true });
    }
  }

  private validateMeasurementSet(): boolean {
    let flag = true;

    if (this.props.setDisplayName === "") {
      flag = false;
    }

    if (this.state.chartConfig === null) {
      flag = false;
    }

    if (this.props.selectedMeasGroupId === "") {
      flag = false;
    }
    return flag;
  }

  private saveChartConfig(config: ChartConfig, index: Nullable<number>, newConfig: boolean): void {
    let chartConfigArray: ChartConfig[] = [];

    if (newConfig) {
      if (this.state.chartConfig === null) {
        chartConfigArray = [config];
      } else {
        chartConfigArray = [...this.state.chartConfig];
        chartConfigArray.push(config);
      }
    } else {
      chartConfigArray = [...this.state.chartConfig as ChartConfig[]];
      chartConfigArray[index as number] = config;
    }
    this.setState({ chartConfig: chartConfigArray });
    // set editChartConfigMode to false and reset newChartConfigName in wrapper
    this.props.closeEditChartConfigMode();
  }

  private startEditChartConfig(index: Maybe<number>): void {
    if (this.state.chartConfig !== null && index !== undefined) {
      this.props.startEditExisting("chartConfig", this.state.chartConfig[index].displayName);
      this.setState({ selectedChartConfigIndex: index });
    } else {
      console.error("Error in MeasurementSetEditView: tried to edit a chart config with undefined index or when there are no chart configs to edit");
    }
  }

  private deleteChartConfig(indexToDelete: Maybe<number>): void {
    if (this.state.chartConfig && indexToDelete !== undefined) {
      const updatedChartConfig = this.state.chartConfig.filter((_config, index) => index !== indexToDelete);
      this.setState({ chartConfig: updatedChartConfig.length === 0 ? null : updatedChartConfig });
    } else {
      console.error("Error in MeasurementSetEditView: tried to delete a chart config with undefined index or when there are no chart configs to delete");
    }
  }

  private isDeviceLocationMsgNeeded(): boolean {
    if (this.state.chartConfig !== null && this.state.gpsFunction !== null) {
      // REFACTOR: return .some directly and remove duplicate check for this.state.gpsFunction && this.state.chartConfig
      const isLocationDeviceUsedForData = this.state.chartConfig?.some((c: ChartConfig) => {
        return c.dataConfig.some((d: DataConfig) => d.deviceId === this.state.gpsFunction?.deviceId);
      });

      if (!isLocationDeviceUsedForData && this.state.gpsFunction !== null && this.state.chartConfig !== null) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private async setLocationFromSelectedSet({ config }: MeasurementSetConfig): Promise<void> {
    const { gpsFunction, gpsLocation } = config;

    if (gpsFunction) {
      this.setState({ deviceLocationLoadingTextNeeded: true });
      await DeviceNavigationCache.getInstance().navigateToDeviceWithQueryString(this.props, gpsFunction.deviceId);
    } else if (gpsLocation) {
      const { latitude: lat, longitude: lng } = gpsLocation;
      this.setupMapConnection();
      const mapData: MapMarkerData = {
        id: uuid(),
        type: IconTypes.measurementSet,
        location: (): MapLocation => { return { lat, lng }; },
        status: () => IconStatus.selected,
      };
      await this.setPlaceLocation(mapData);
      // ASSERTION: setupMapConnection assigns this.map to a ComponentToMapLink instance
      (this.map as ComponentToMapLink).setLocation([mapData]);
    }
  }

  private async setPlaceLocation(mapData: MapMarkerData): Promise<void> {
    const locations = await MapLink.getLinkToMap().search(mapData.location());
    let placeLocation: Nullable<LocationInfo> = null;

    if (locations && locations.length > 0) {
      placeLocation = locations[0];
    } else if (locations) {
      placeLocation = (): string => Localization.getInstance().getDisplayText("MeasurementSetEditView", "locationNotFound");
    } else {
      placeLocation = (): string => Localization.getInstance().getDisplayText("MeasurementSetEditView", "locationError");
    }
    this.setState({ placeLocation });
  }

  // sets created before map geocoding functionality will have more precise value in gpsLocation than
  // in placeLocation.position. New sets will have identical values.
  private getStateValuesFromSelectedSet(): State {
    return {
      aggregateLength: this.selectedSet?.config.aggregateLength ?? null,
      chartConfig: this.selectedSet?.config.chartConfig ?? null,
      deviceLocationLoadingTextNeeded: false,
      errorPopUpOpen: false,
      gpsFunction: this.selectedSet?.config.gpsFunction ?? null,
      gpsLocation: this.selectedSet?.config.gpsLocation ?? null,
      historyLength: this.selectedSet?.config.historyLength ?? undefined,
      locationOption:
        this.selectedSet?.config.gpsFunction ?
          MeasSetLocationOption.gpsFunction :
          this.selectedSet?.config.gpsLocation ?
            MeasSetLocationOption.gpsLocation :
            null,
      metadata: this.selectedSet?.metadata ?? "",
      selectedChartConfigIndex: null,
      // placeLocation is set asynchronously in this.setLocationFromSelectedSet
      placeLocation: null,
    };
  }

  private getEmptyState(): State {
    return {
      aggregateLength: null,
      chartConfig: null,
      deviceLocationLoadingTextNeeded: false,
      errorPopUpOpen: false,
      gpsFunction: null,
      gpsLocation: null,
      historyLength: undefined,
      locationOption: null,
      metadata: "",
      selectedChartConfigIndex: null,
      placeLocation: null,
    };
  }
}

export default withRouter(withDataJanitor(SetsCreateView, [
  DataRepositories.Device,
]));
