/* These are exported here because they can't be read from Theme in components */
const SCROLLBAR_WIDTH = "8px";
const SCROLLBAR_HEIGHT = "8px";
const DIVIDER_HEIGHT = "2px";

export {
  SCROLLBAR_WIDTH,
  SCROLLBAR_HEIGHT,
  DIVIDER_HEIGHT,
};
