import React, { Component } from "react";
import TreeView from "@material-ui/lab/TreeView";
import { Box, Link, Theme, Typography, withTheme } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import { Maybe } from "types/aliases";
import Localization from "data/localization-sensoan/Localization";
import STreeItem from "components/styled-components/STreeItem";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import { LocationGroupingResult } from "./MapWrapper";
import { IconTypes, MapMarkerData } from "data/map/MapLink";
import { ReactComponent as DevicesIcon } from "assets/app-views/icons/DevicesIcon.svg";
import { ReactComponent as EventsIcon } from "assets/app-views/icons/EventsIcon.svg";
import { ReactComponent as MeasurementsetsIcon } from "assets/app-views/icons/MeasurementsetsIcon.svg";
import { ReactComponent as MeasurementjobsIcon } from "assets/app-views/icons/MeasurementjobsIcon.svg";
import MeasurementJobSelector from "data/measurement-job-selector/MeasurementJobSelector";
import DeviceRepository from "data/data-storage/DeviceRepository";
import MeasurementJobRepository from "data/data-storage/MeasurementJobRepository";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import MeasurementSetSelector from "data/measurement-set-selector/MeasurementSetSelector";
import { getDisplayName } from "data/utils/Utils";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DevicePathRouterProps } from "types/routerprops";
import { AppLayoutMode } from "types/sensoanUiTypes";
import SIconButton from "components/styled-components/SIconButton";
import DeviceNavigationCache from "utils/DeviceNavigationCache";
import PathsSensoan from "data/paths/PathsSensoan";

// TODO: split into 2-3 components
interface Props extends RouteComponentProps<DevicePathRouterProps> {
  appLayoutMode: AppLayoutMode;
  closeMapInfoBubble: () => void;
  onAppLayoutModeChange: () => void;
  size: "large" | "small";
  theme: Theme;
  treeItems: LocationGroupingResult[];
}

interface State {
  expandedItems: string[];
}

class LocationTree extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      expandedItems: [this.props.treeItems && this.getRootItemText()],
    };
  }

  private renderItemIcon({ type }: MapMarkerData): JSX.Element {
    let icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }>;

    switch (type) {
      case IconTypes.measurementSet:
        icon = MeasurementsetsIcon;
        break;
      case IconTypes.measurementJob:
        icon = MeasurementjobsIcon;
        break;
      case IconTypes.device:
        icon = DevicesIcon;
        break;
      case IconTypes.event:
        icon = EventsIcon;
        break;
      default:
        icon = MeasurementsetsIcon;
        console.error("Unknown property 'type' in LocationTree.renderItemIcon");
    }
    return (
      <SSvgIcon
        iconComponent={icon}
        color={SSvgIconColorProps.textPrimary}
        viewBox="0 0 30 30"
        size="1.25rem"
      />
    );
  }

  private renderTogglerIcon(type: "expand" | "collapse"): JSX.Element {
    return (
      <SSvgIcon
        shadow
        color={SSvgIconColorProps.primary}
        iconComponent={type === "expand" ? ChevronRightIcon : ExpandMoreIcon}
        size="1rem"
      />
    );
  }

  private renderLabelContent(treeItem: LocationGroupingResult | MapMarkerData | string): JSX.Element {
    const variant = this.props.size === "small" ? "body2" : "body1";

    if (typeof treeItem === "string") {
      return (
        <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
          <Typography color="textSecondary" variant={variant}>
            {treeItem}
          </Typography>
          <SIconButton
            onClick={(): void => this.props.closeMapInfoBubble()}
            hoverBgSize={this.props.size === "small" ? "6px" : undefined}
          >
            <SSvgIcon
              color={SSvgIconColorProps.secondary}
              iconComponent={CloseIcon}
              size={this.props.size === "small" ? "1.25rem" : undefined}
              inlineStyle={{ margin: 0 }}
            />
          </SIconButton>
        </Box>
      );
    } else if (this.isTreeItemLocationGroupingResult(treeItem)) {
      return (
        <Typography color="textSecondary" variant={variant}>
          {typeof treeItem.location === "object" ? treeItem.location.title : treeItem.location} ({treeItem.items.length})
        </Typography>
      );
    } else {
      return (
        // TODO: change component type because this does not function as an actual link anymore
        <Link
          color="textPrimary"
          component="button"
          onClick={(): void => this.handleNameItemClick(treeItem)}
        >
          <Typography color="textPrimary" variant={variant}>
            {this.getMapMarkerDataItemName(treeItem)}
          </Typography>
        </Link>
      );
    }
  }

  private renderMapMarkerDataItems(items: MapMarkerData[]): Maybe<JSX.Element[]> {
    if (this.areMapMarkerDataItemsOfSameType(items)) {
      // filter duplicate entries for events
      const _items = items[0].type !== IconTypes.event
        ? items
        : this.filterDuplicateEventItems(items);

      if (_items.length > 0) {
        return (
          _items.map((item, index, { length }) => {
            return (
              <STreeItem
                dense={this.props.size === "small"}
                key={item.id}
                expanded={this.state.expandedItems.includes(item.id)}
                icon={this.renderItemIcon(item)} // use icon prop always instead of expandIcon or collapseIcon prop because TreeView component automatically removes expand and collapse icon from a TreeItem without children
                label={this.renderLabelContent(item)}
                nodeId={item.id}
                hideExtraBorder={index === length - 1 ? true : undefined}
                allowRecursiveChildren
              />
            );
          })
        );
      }
    } else {
      console.error("Not all items of parameter 'items' are of the same type");
    }
  }

  private renderLocationItems(treeItem: LocationGroupingResult, index: number, length: number): JSX.Element {
    const title = typeof treeItem.location === "object" ? treeItem.location.title : treeItem.location;
    return (
      <STreeItem
        dense={this.props.size === "small"}
        key={title}
        expanded={this.state.expandedItems.includes(title)}
        icon={this.renderTogglerIcon(this.state.expandedItems.includes(title) ? "collapse" : "expand")}
        label={this.renderLabelContent(treeItem)}
        nodeId={title}
        onIconClick={(event: React.MouseEvent<Element, MouseEvent>): void => this.onTogglerIconClick(event, title)}
        hideExtraBorder={index === length - 1 ? true : undefined}
        allowRecursiveChildren
      >
        {this.renderMapMarkerDataItems(treeItem.items)}
      </STreeItem>
    );
  }

  public render(): JSX.Element {
    return (
      <TreeView
        expanded={this.state.expandedItems}
      >
        <STreeItem
          dense={this.props.size === "small"}
          label={this.renderLabelContent(this.getRootItemText())}
          nodeId={this.getRootItemText()}
          hideIconContainer
          allowRecursiveChildren
        >
          {this.props.treeItems.map((item, index, { length }) => this.renderLocationItems(item, index, length))}
        </STreeItem>
      </TreeView>
    );
  }

  private async handleNameItemSelect({ id, type }: MapMarkerData): Promise<void> {
    if (type === IconTypes.measurementSet) {
      this.handleMeasurementSetItemSelect(id);
    } else if (type === IconTypes.measurementJob) {
      this.handleMeasurementJobItemSelect(id);
    } else if (type === IconTypes.device) {
      await this.handleDeviceItemSelect(id);
    } else if (type === IconTypes.event) {
      await this.handleEventItemSelect(id);
    } else {
      console.error(`Unknown value '${type}' for parameter 'type'`);
    }
  }

  private async handleDeviceItemSelect(id: string): Promise<void> {
    if (id !== DeviceNavigationCache.getInstance().getSelectedDevice()?.getId()) {
      if (!this.props.location.pathname.includes(PathsSensoan.DEVICES)) {
        await DeviceNavigationCache.getInstance().setCurrentDeviceAndNavigateToPath(this.props, PathsSensoan.DEVICES, id);
      } else {
        await DeviceNavigationCache.getInstance().navigateToDevice(this.props, id);
      }
    } else {
      return;
    }
  }

  private async handleEventItemSelect(id: string): Promise<void> {
    const deviceId = this.extractDeviceIdFromEventMarkerId(id);

    if (deviceId !== DeviceNavigationCache.getInstance().getSelectedDevice()?.getId()) {
      if (!this.props.location.pathname.includes(PathsSensoan.EVENTS)) {
        await DeviceNavigationCache.getInstance().setCurrentDeviceAndNavigateToPath(this.props, PathsSensoan.EVENTS, deviceId);
      } else {
        await DeviceNavigationCache.getInstance().navigateToDevice(this.props, id);
      }
    } else {
      return;
    }
  }

  private handleMeasurementSetItemSelect(id: string): void {
    const set = MeasurementSetRepository.getInstance().getMeasurementSet(id);

    if (set && set.setId !== MeasurementSetSelector.getInstance().getSelectedMeasurementSet()?.setId) {
      if (!this.props.location.pathname.includes(PathsSensoan.MEASUREMENTSETS)) {
        this.props.history.push(PathsSensoan.MEASUREMENTSETS, this.props.location.state);
      }
      MeasurementSetSelector.getInstance().setSelectedMeasurementSet(set);
    } else {
      return;
    }
  }

  private handleMeasurementJobItemSelect(id: string): void {
    const job = MeasurementJobRepository.getInstance().getMeasurementJob(id);

    if (job && job.jobId !== MeasurementJobSelector.getInstance().getSelectedMeasurementJob()?.jobId) {
      if (!this.props.location.pathname.includes(PathsSensoan.MEASUREMENTJOBS)) {
        this.props.history.push(PathsSensoan.MEASUREMENTJOBS, this.props.location.state);
      }
      MeasurementJobSelector.getInstance().setSelectedMeasurementJob(job);
    } else {
      return;
    }
  }

  private handleNameItemClick(item: MapMarkerData): void {
    this.handleNameItemSelect(item);
    this.props.closeMapInfoBubble();

    if (this.props.appLayoutMode === AppLayoutMode.mapWithDrawer) {
      this.props.onAppLayoutModeChange();
    }
  }

  private onTogglerIconClick(event: React.MouseEvent<Element, MouseEvent>, itemTitle: string): void {
    event.preventDefault();
    event.stopPropagation();
    this.state.expandedItems.includes(itemTitle)
      ?
      this.setState({ expandedItems: this.state.expandedItems.filter(item => item !== itemTitle) })
      :
      this.setState({ expandedItems: this.state.expandedItems.concat(itemTitle) });
  }

  private isTreeItemLocationGroupingResult(treeItem: LocationGroupingResult | MapMarkerData): treeItem is LocationGroupingResult {
    return (treeItem as LocationGroupingResult).items !== undefined;
  }

  private getEventCountForDevice(deviceId: string): number {
    const parentGroup = this.props.treeItems.find(item => item.items.some(({ id }) => this.extractDeviceIdFromEventMarkerId(id) === deviceId));
    const count = parentGroup && parentGroup.items.filter(({ id }) => this.extractDeviceIdFromEventMarkerId(id) === deviceId).length;
    return count || 0;
  }

  private getRootItemText(): string {
    const { treeItems } = this.props;
    const isSingleListElement = treeItems.length === 1;
    const isSingleEventListElement = isSingleListElement && treeItems[0].items.length === 1;

    if (this.areMapMarkerDataItemsOfSameType(treeItems[0].items)) {
      const { type } = treeItems[0].items[0];

      if (type === IconTypes.measurementSet) {
        return this.text("Common", isSingleListElement ? "measurementSet" : "measurementSets");
      } else if (type === IconTypes.measurementJob) {
        return this.text("Common", isSingleListElement ? "measurementJob" : "measurementJobs");
      } else if (type === IconTypes.device) {
        return this.text("Common", isSingleListElement ? "device" : "devices");
      } else if (type === IconTypes.event) {
        return this.text("Common", isSingleEventListElement ? "event" : "events");
      } else {
        console.error(`Unknown value '${type}' for property 'type'`);
        return "/???";
      }
    } else {
      console.error("Not all items of array 'treeItems' are of the same type");
      return "???";
    }
  }

  private getMapMarkerDataItemName({ type, id }: MapMarkerData): string {
    if (type === IconTypes.measurementSet) {
      return MeasurementSetRepository.getInstance().getMeasurementSet(id)?.displayName ?? "";
    } else if (type === IconTypes.measurementJob) {
      return MeasurementJobRepository.getInstance().getMeasurementJob(id)?.displayName ?? "";
    } else if (type === IconTypes.device) {
      return getDisplayName(DeviceRepository.getInstance().getDevice(id));
    } else if (type === IconTypes.event) {
      const count = this.getEventCountForDevice(this.extractDeviceIdFromEventMarkerId(id));
      const name = getDisplayName(DeviceRepository.getInstance().getDevice(this.extractDeviceIdFromEventMarkerId(id)));
      return count > 1
        ? `${name} (${count})`
        : name;
    } else {
      console.error(`Unknown value '${type}' for parameter 'type'`);
      return "???";
    }
  }

  private areMapMarkerDataItemsOfSameType(items: MapMarkerData[]): boolean {
    return items.reduce<IconTypes[]>((acc, { type }) => {
      if (!acc.includes(type)) {
        acc.push(type);
      }
      return acc;
    }, []).length === 1;
  }

  private extractDeviceIdFromEventMarkerId(id: string): string {
    return id.split("_", 1).toString();
  }

  private filterDuplicateEventItems(items: MapMarkerData[]): MapMarkerData[] {
    return items.reduce<MapMarkerData[]>((acc, curr) => {
      if (!acc.find(({ id }) => this.extractDeviceIdFromEventMarkerId(id) === this.extractDeviceIdFromEventMarkerId(curr.id))) {
        acc.push(curr);
      }
      return acc;
    }, []);
  }
}

export default withRouter(withTheme(LocationTree));
