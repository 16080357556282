import React, { Component, Fragment } from "react";
import { Button } from "@material-ui/core";
import Device from "data/device/Device";
import Localization from "data/localization-sensoan/Localization";
import { Maybe } from "types/aliases";
import SettingsIcon from "assets/device/icons/baseline-settings-20px.svg";
import DeviceSettingsPopup from "./device-settings-popup";

interface Props {
  device?: Device;
  isIcon: boolean;
}

interface State {
  showSettings: boolean;
}

export default class DeviceSettingsButton extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      showSettings: false,
    };
  }

  private toggleDeviceSettings = (): void => {
    this.setState((prevState: State) => ({ showSettings: !prevState.showSettings }));
  };

  private renderSettingsPopupIcon(): Maybe<JSX.Element> {
    if (!this.props.device) {
      return;
    }

    if (this.props.isIcon) {
      return (
        <img
          className="status-icon action"
          alt="Settings"
          src={SettingsIcon}
          onClick={(event): void => {
            event.stopPropagation();
            this.toggleDeviceSettings();
          }}
        />
      );
    } else {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={this.toggleDeviceSettings}
        >
          {Localization.getInstance().getDisplayText("Common", "settings")}
        </Button>
      );
    }
  }

  private renderSettingsPopup(): Maybe<JSX.Element> {
    if (this.state.showSettings) {
      return (
        <DeviceSettingsPopup
          selectedDevice={this.props.device!}
          closeSettings={this.toggleDeviceSettings}
        />
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.renderSettingsPopupIcon()}
        {this.renderSettingsPopup()}
      </Fragment>
    );
  }
}
