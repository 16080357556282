import React, { Component, ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link } from "@material-ui/core";
import Localization from "data/localization-sensoan/Localization";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import LocationOffIcon from "@material-ui/icons/LocationOff";
import { MeasurementSetConfig } from "data/types/measurementSetTypes";
import IoTDataRow from "components/layout/iot-data-row/IoTDataRow";
import { MeasurementJobStatus, MeasurementJob } from "data/types/measurementJobTypes";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import { Nullable } from "types/aliases";
import { IconStatus, MapMarkerData } from "data/map/MapLink";
import MeasurementDataTools from "data/measurement-data/MeasurementDataTools";
import MeasurementJobSelector from "data/measurement-job-selector/MeasurementJobSelector";
import SButton from "components/styled-components/SButton";
import SText from "components/styled-components/SText";
import MeasurementSetSelector from "data/measurement-set-selector/MeasurementSetSelector";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import { isDefined } from "utils/types";
import withDataJanitor from "components/hocs/DataJanitor";
import DataRowLocationIndicator from "components/layout/iot-data-row/DataRowLocationIndicator";
import { DataRepositories } from "data/data-storage/DataRepositoryFactory";
import PathsSensoan from "data/paths/PathsSensoan";

const DataRowLocationIndicatorWithDeviceRepo = withDataJanitor(DataRowLocationIndicator, [
  DataRepositories.Device,
]);

interface Props {
  dataItem: MeasurementJob;
  mapMarkerData: Nullable<MapMarkerData[]>;
  toggleMarker: (marker: Nullable<MapMarkerData>) => void;
}

interface State {
  locationLoading: boolean;
  measSet: Nullable<MeasurementSetConfig>;
}

class JobsListDataRow extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      locationLoading: false,
      measSet: MeasurementSetRepository.getInstance().getMeasurementSet(props.dataItem.setId),
    };
  }

  private renderLocationIndicator(job: MeasurementJob): ReactNode {
    const { mapMarkerData, toggleMarker } = this.props;
    const { config } = MeasurementSetRepository.getInstance().getMeasurementSet(job.setId) as MeasurementSetConfig;
    const color = SSvgIconColorProps.greenGradient;
    const markerItemGetter = (): Nullable<MapMarkerData> => this.getMapMarkerDataItem(job);

    if (config.gpsFunction?.deviceId) {
      return (
        <DataRowLocationIndicatorWithDeviceRepo
          deviceId={config.gpsFunction?.deviceId}
          buttonActiveColor={color}
          mapMarkerData={mapMarkerData}
          getMapMarkerDataItem={markerItemGetter}
          toggleMarker={toggleMarker}
          inlineLoaderWithoutWaitingText
          loaderProps={{
            size: 1.75,
            leftRightPadding: "12px",
          }}
        />
      );
    } else if ((config.gpsLocation?.latitude !== undefined && config.gpsLocation?.longitude !== undefined) || job.endLocation) {
      return (
        <DataRowLocationIndicator
          buttonActiveColor={color}
          mapMarkerData={mapMarkerData}
          getMapMarkerDataItem={markerItemGetter}
          toggleMarker={toggleMarker}
        />
      );
    } else {
      return (
        <Box p={3}>
          <SSvgIcon
            color={SSvgIconColorProps.primary}
            iconComponent={LocationOffIcon}
          />
        </Box>
      );
    }
  }

  // MapIcon & Name
  // Start
  // Stop
  // Measset button
  // Comment
  // Status
  // Details button
  public render(): ReactNode {
    if (this.state.measSet === null) {
      return null;
    } else {
      const { dataItem } = this.props;
      return (
        <IoTDataRow dividerFadeEffect>
          {this.renderLocationIndicator(dataItem)}
          <Box width="14%">
            <Link
              color="textPrimary"
              component="button"
              onClick={(): void => MeasurementJobSelector.getInstance().setSelectedMeasurementJob(dataItem)}
              style={{ cursor: "pointer", width: "100%", textAlign: "start" }} // width and textAlign needed to SText to function properly here
            >
              <SText text={dataItem.displayName} color="textPrimary"/>
            </Link>
          </Box>
          <Box width="14%">
            <SText text={Localization.getInstance().getDateAndTimeString(dataItem.startTs)} color="textSecondary"/>
          </Box>
          <Box width="14%">
            <SText text={Localization.getInstance().getDateAndTimeString(dataItem.endTs)} color="textSecondary"/>
          </Box>
          <Box width="14%">
            <SButton
              color="secondary"
              buttonComponent={RouterLink}
              endIcon={ArrowRightAltIcon}
              fontWeight="regular"
              iconColor={SSvgIconColorProps.textPrimary}
              labelText={MeasurementSetRepository.getInstance().getMeasurementSet(dataItem.setId)?.displayName
                ??
                Localization.getInstance().getDisplayText("MeasJobsListView", "setNotFound")}
              linkTo={PathsSensoan.MEASUREMENTSETS}
              onClick={(): void => MeasurementSetSelector.getInstance().setSelectedMeasurementSet(this.state.measSet)}
              widthInRems={10}
            />
          </Box>
          <Box width="30%">
            <SText text={`${dataItem.responsible} ${isDefined(dataItem.metadata) ? " / " + dataItem.metadata : ""}`} color="textSecondary"/>
          </Box>
          <Box width="10%">
            <SText text={this.getStatusText(dataItem)} color={dataItem.status === MeasurementJobStatus.Running ? "textPrimary" : "textSecondary"}/>
          </Box>
        </IoTDataRow>
      );
    }
  }

  private getMapMarkerDataItem(job: MeasurementJob): Nullable<MapMarkerData> {
    const measSet = MeasurementSetRepository.getInstance().getMeasurementSet(job.setId) as MeasurementSetConfig;
    return MeasurementDataTools.getMapMarkerDataForMeasJob(measSet, job, () => IconStatus.selected);
  }

  // TODO: add status icon
  private getStatusText(job: MeasurementJob): string {
    let status: MeasurementJobStatus = MeasurementJobStatus.NotStarted;

    if (job.status === undefined) {
      status = MeasurementJobStatus.NotStarted;
    } else {
      status = job.status as MeasurementJobStatus;
    }
    return (
      Localization.getInstance().getDisplayText("MeasJobsListView", status)
    );
  }

}

export default JobsListDataRow;
