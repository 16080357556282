/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, ReactNode } from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import Organization, { OrganizationObserver } from "data/organization/Organization";
import Localization from "data/localization-sensoan/Localization";
import { Maybe } from "types/aliases";
import DropdownSelection from "components/ui/dropdown-selection";

interface Props {
  rootOrganization: Organization;
  organizationSelected: (organization: Organization) => void;
}

interface State {
  breadCrumbs: Organization[];
  nextChildren?: Organization[];
}

function tail<T>(array: T[]): Maybe<T> {
  return array.length === 0 ? undefined : array[array.length - 1];
}

export default class OrganizationSelector extends Component<Props, State> implements OrganizationObserver {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      breadCrumbs: [],
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.updateCrumbs([this.props.rootOrganization]);
  }

  public componentWillUnmount(): void {
    this.state.breadCrumbs.forEach(organization => organization.removeObserver(this));
    this.state.nextChildren?.forEach(organization => organization.removeObserver(this));
  }

  public onChildrenChange(children: Organization[], organization: Organization): void {
    // check if the children of the last organization in the path changed
    // deletions _on_ the path will be handled by onDelete callback
    if (organization === tail(this.state.breadCrumbs)) {
      this.swapChildren(children);
    }
  }

  public async onDeleted(organization: Organization): Promise<void> {
    const deleteIndex = this.state.breadCrumbs.findIndex(crumb => crumb.getId() === organization.getId());

    // TODO what should happen if deleteIndex === 0
    if (deleteIndex > 0) {
      const nextPath = this.state.breadCrumbs.slice(0, deleteIndex);
      await this.updateCrumbs(nextPath);
    }
  }

  public onNameChange(organization: Organization): void {
    console.log(`OrganizationSelector: organization ${organization.getName()} updated its name!`);

    if (this.state.breadCrumbs.includes(organization)) {
      console.log("OrganizationSelector updating path");
      this.setState({
        breadCrumbs: [...this.state.breadCrumbs],
      });
    } else if (this.state.nextChildren?.includes(organization)) {
      console.log("OrganizationSelector updating children");
      this.setState({
        nextChildren: [...this.state.nextChildren!],
      });
    }
  }

  private async handleParentSelection(index: number): Promise<void> {
    const newCrumbs = this.state.breadCrumbs.slice(0, index + 1);
    return this.updateCrumbs(newCrumbs);
  }

  private handleChildSelection = async (index?: number): Promise<void> => {
    if (index == null) {
      return;
    }
    const child = this.state.nextChildren?.[index];

    if (!child) {
      console.error("Selected organization from a set of none");
      return;
    }

    const newCrumbs = this.state.breadCrumbs.concat(child);
    return this.updateCrumbs(newCrumbs);
  };

  private async updateCrumbs(organizations: Organization[]): Promise<void> {
    this.state.breadCrumbs.forEach(organization => organization.removeObserver(this));
    organizations.forEach(organization => organization.addObserver(this));
    this.setState({
      breadCrumbs: organizations,
    });
    const nextChildren = await OrganizationSelector.getNewChildren(organizations);
    this.swapChildren(nextChildren);
    this.notifyCurrentOrganizationSelection();
  }

  private swapChildren(nextChildren: Organization[]): void {
    this.state.nextChildren?.forEach(organization => {
      if (!this.state.breadCrumbs.includes(organization)) {
        organization.removeObserver(this);
      }
    });
    nextChildren.forEach(organization => organization.addObserver(this));
    this.setState({ nextChildren });
  }

  private notifyCurrentOrganizationSelection(): void {
    this.props.organizationSelected(this.state.breadCrumbs[this.state.breadCrumbs.length - 1]);
  }

  private renderOrganizationLinks(): ReactNode {
    if (!this.state.breadCrumbs) {
      return;
    }

    const breadCrumbsCopy = [...this.state.breadCrumbs];
    const lastItem = breadCrumbsCopy.pop();

    const ret: ReactNode[] = [];

    for (let i = 0; i < breadCrumbsCopy.length; i++) {
      const organization = breadCrumbsCopy[i];
      ret.push((
        <Link key={organization.getId()} color="inherit" href="#" onClick={(): void => void this.handleParentSelection(i)} data-testid={`organization-link-${i}`}>
          {organization.getName()}
        </Link>
      ));
    }

    if (lastItem) {
      ret.push((
        <Typography key={lastItem.getId()} color="secondary" data-testid="current-org">{lastItem.getName()}</Typography>
      ));
    }
    return ret;
  }

  private renderChildSelector(): ReactNode {
    if (!this.state.nextChildren) {
      return;
    }
    return (
      <DropdownSelection
        onSelect={this.handleChildSelection}
        selectionList={this.state.nextChildren.map((child) => ({ key: child.getId(), label: child.getName() }))}
        emptySelectionItem={this.text("OrganisationSelector", "selectChild")}
        disabled={this.state.nextChildren.length === 0}
      />
    );

  }

  public render(): ReactNode {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography color="textPrimary">{this.text("OrganisationSelector", "organisations")}</Typography>
        {this.renderOrganizationLinks()}
        {this.renderChildSelector()}
      </Breadcrumbs>
    );
  }

  private static async getNewChildren(organizations: Organization[]): Promise<Organization[]> {
    const last = tail(organizations);
    return last ? await last.getChildOrganizations() : [];
  }
}
