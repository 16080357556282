import React, { Component } from "react";
import { Box, Button, Dialog, DialogProps, Grid, Typography } from "@material-ui/core";
import Localization from "data/localization-sensoan/Localization";

interface Props {
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
  title?: string;
  disableAccept?: false | boolean;
  acceptButtonText?: string;
  cancelButtonText?: string;
  maxWidth?: DialogProps["maxWidth"];
}

/**
 * Simple "form" style dialog, where
 * <FormDialog>
 *   <ChildComponent1 />
 *   <ChildComponent2 />
 * </FormDialog>
 *
 * are placed within a <List>
 */
export default class FormDialog extends Component<Props> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    const ACCEPT_TEXT = this.text("Actions", "accept");
    const CANCEL_TEXT = this.text("Actions", "cancel");
    return (
      <Dialog
        onClose={this.props.onCancel}
        open={this.props.isOpen}
        maxWidth={this.props.maxWidth}
      >
        <Box padding={3} style={{ overflow: "hidden" }}>
          <Grid container={true} direction={"column"} spacing={3}>
            {
              this.props.title &&
              <Grid item={true} container={true} xs={12} justifyContent="center">
                <Typography variant="h6">{this.props.title}</Typography>
              </Grid>
            }
            <Grid item={true} container={true} xs={12} justifyContent="center">
              {this.props.children}
            </Grid>
            <Grid item={true} container={true} justifyContent="center" spacing={6}>
              <Grid item={true}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.props.onAccept}
                  disabled={this.props.disableAccept ?? false}
                >
                  {this.props.acceptButtonText ?? ACCEPT_TEXT}
                </Button>
              </Grid>
              <Grid item={true}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.props.onCancel}
                >
                  {this.props.cancelButtonText ?? CANCEL_TEXT}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    );
  }
}
