import React, { ReactNode } from "react";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import { Box, FilledInput, FilledInputProps, FormControl, InputAdornment,
  InputLabel, InputLabelProps, Paper, PaperProps, Theme, withStyles, withTheme } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { HereMapsPlace } from "data/map/MapGeocoding";
import { Maybe, Nullable } from "types/aliases";
import MapLink, { MapEventObserver } from "data/map/MapLink";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import { AppLayoutMode, MapLocationEventResult } from "types/sensoanUiTypes";
import SIconButton from "components/styled-components/SIconButton";
import Localization from "data/localization-sensoan/Localization";

interface Props {
  appLayoutMode: AppLayoutMode;
  theme: Theme;
}

interface State {
  loading: boolean;
  optionsListOpen: boolean;
  options: HereMapsPlace[];
  searchText: string;
  selectedOption: Nullable<HereMapsPlace>;
  StyledFilledInput: React.ComponentType<FilledInputProps>;
  StyledInputLabel: React.ComponentType<InputLabelProps>;
}

class MapSearchBar extends React.Component<Props, State> implements MapEventObserver {

  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      optionsListOpen: false,
      options: [],
      searchText: "",
      selectedOption: null,
      StyledFilledInput: this.getStyledFilledInput(),
      StyledInputLabel: this.getStyledInputLabel(),
    };
  }

  public componentDidMount(): void {
    const data = MapLink.getDataStorage().getPlaceData();

    if (data) {
      this.setState({ selectedOption: data });
    }
    this.setupMapConnection();
  }

  public async componentDidUpdate(prevProps: Props, prevState: State): Promise<void> {
    if (this.state.optionsListOpen && this.state.options.length === 0 && this.state.loading) {
      this.setState({ loading: false });
    }

    if (!this.state.optionsListOpen && prevState.optionsListOpen) {
      this.setState({ options: [] });
    }

    if (this.props.appLayoutMode !== prevProps.appLayoutMode) {
      this.setState({
        StyledFilledInput: this.getStyledFilledInput(),
        StyledInputLabel: this.getStyledInputLabel(),
      });
    }

    if (this.props.theme !== prevProps.theme) {
      this.setState({
        StyledFilledInput: this.getStyledFilledInput(),
        StyledInputLabel: this.getStyledInputLabel(),
      });
    }
  }

  public componentWillUnmount(): void {
    const data = MapLink.getDataStorage().getPlaceData();

    if (data && this.state.selectedOption === null) {
      MapLink.getDataStorage().clearPlaceData();
    }
    MapLink.getLinkToMap().removeObserver(this);
  }

  public onMapLocationEvent?(eventResult: Nullable<MapLocationEventResult>): void {
    if (eventResult === "noPlaceFound") {
      this.setState({ selectedOption: null, options: [] });
      // check to prevent unneeded setState when postLocation is called in this.handleSelectOption
    } else if (eventResult && (eventResult.title !== this.state.selectedOption?.title)) {
      this.setState({ selectedOption: eventResult });
    }
  }

  private renderInput(params: AutocompleteRenderInputParams, id: string): JSX.Element {
    const { StyledFilledInput } = this.state;

    return (
      <div ref={params.InputProps.ref}>
        <FormControl variant="filled" fullWidth>
          {this.renderInputLabel(id)}
          <StyledFilledInput
            {...params.inputProps}
            inputProps={
              { style:
                { textAlign: "start" },
              }
            }
            endAdornment={this.renderInputEndAdornment()}
          />
        </FormControl>
      </div>
    );
  }

  private renderInputEndAdornment(): React.ReactNode {
    if (this.state.searchText === "") {
      return (
        <InputAdornment position="end">
          <SSvgIcon color={SSvgIconColorProps.textPrimary} iconComponent={SearchIcon} size="1.5rem" />
        </InputAdornment>
      );
    } else {
      return (
        <InputAdornment position="end">
          <SIconButton onClick={(): void => this.handleClearButtonClick()} size="1.5rem">
            <SSvgIcon color={SSvgIconColorProps.textPrimary} iconComponent={CloseIcon} size="1.5rem"/>
          </SIconButton>
        </InputAdornment>
      );
    }
  }

  private handleClearButtonClick(): void {
    // tyhjennä oma state
    this.setState({ searchText: "", optionsListOpen: false, options: [], selectedOption: null });
    // postaa null lokaatioina -> teksti poistuu setin muokkausnäkymästä koska se poistuu CreateSetView:n statesta
    MapLink.getLinkToComponents().postLocation(null);
    // tyhjennä mapDataStorage -> marker poistuu
    MapLink.getLinkToMap().removeAllLocations();
    // tyhjennä placeData -> kartan uuden mountin yhteydessä hakukenttä pysyy tyhjänä
    MapLink.getDataStorage().clearPlaceData();
  }

  private renderInputLabel(id: string): Maybe<JSX.Element> {
    const { StyledInputLabel } = this.state;

    if (!this.state.selectedOption) {
      return (
        <StyledInputLabel htmlFor={id}>
          {Localization.getInstance().getDisplayText("Actions", "search")}
        </StyledInputLabel>
      );
    }
  }

  public render(): JSX.Element {

    const id = "map-location-search";
    return (
      <Box position="absolute"
        top="16px"
        left="16px"
        zIndex={1}
        width={this.props.appLayoutMode === AppLayoutMode.dataWithDrawer ? "200px" : "350px"}
      >
        <Autocomplete
          clearOnBlur
          clearOnEscape
          getOptionLabel={(option): string => option.title}
          getOptionSelected={(option, value): boolean => option.title === value.title}
          id={id}
          inputValue={this.state.searchText}
          loading={this.state.loading}
          noOptionsText={Localization.getInstance().getDisplayText("MeasurementSetsDataTable", "noSearchResults")}
          onClose={(): void => this.setState({ optionsListOpen: false })}
          onInputChange={(_event, newInputValue): Promise<void> => this.onSearchTextChange(newInputValue)}
          value={this.state.selectedOption as HereMapsPlace}
          onOpen={(): void => this.setState({ optionsListOpen: true })}
          open={this.state.optionsListOpen}
          options={this.state.options}
          onChange={(_event, option: Nullable<HereMapsPlace>): void => this.handleSelectOption(option)}
          PaperComponent={this.getStyledPaper()}
          renderInput={(params): ReactNode => this.renderInput(params, id)}
        />
      </Box>
    );
  }

  private async onSearchTextChange(value: string): Promise<void> {
    this.setState({ searchText: value });

    if (value !== "") {
      this.setState({ loading: true });
      const options = await MapLink.getLinkToMap().search(value);
      this.setState({ loading: false });

      if (options !== null) {
        this.setState({ options });
      }
    }
  }

  private handleSelectOption(selectedOption: Nullable<HereMapsPlace>): void {
    if (selectedOption) {
      this.setState({ selectedOption });
      MapLink.getLinkToComponents().postLocation(selectedOption);
      MapLink.getDataStorage().setPlaceData(selectedOption);
    }
  }

  private getStyledFilledInput(): React.ComponentType<FilledInputProps> {
    const { theme } = this.props;

    return withStyles({
      root: {
        backgroundColor: theme.palette.type === "dark" ? "#292929" : "#f3f3f3",
        boxShadow: theme.shadows["4"],
        borderRadius: "36px",
        color: theme.palette.text.primary, // sets the color of the insertion caret
        fontSize: this.getFontSize(),
        fontWeight: theme.typography.fontWeightBold as "bold", // quick fix for ts error
        height: "2.5rem",
        lineHeight: "1.75",
        width: "100%",
        "&:hover": {
          backgroundColor: theme.palette.type === "dark" ? "#2e2e2e" : "#ececec",
        },
      },
      error: {
        border: `1px solid ${theme.palette.error.main}`,
      },
      focused: {
        backgroundColor: theme.palette.type === "dark" ? "#292929 !important" : "#f3f3f3 !important",

      },
      input: {
        height: "auto",
        padding: "6px 16px",
        textAlign: "center",
      },
      underline: {
        "&:after": {
          borderBottom: "none",
        },
        "&:before": {
          borderBottom: "none",
        },
        "&:hover:before": {
          borderBottom: "none",
        },
      },
    })(FilledInput);
  }

  private getStyledInputLabel(): React.ComponentType<InputLabelProps> {
    return withStyles({
      root: {
        transform: "translate(12px, 11px) scale(1)",
        color: this.props.theme.palette.text.primary,
        fontSize: this.getFontSize(),
      },
      focused: {
        display: "none",
      },
    })(InputLabel);
  }

  private getStyledPaper(): React.ComponentType<PaperProps> {
    return withStyles({
      root: {
        margin: 0,
        backgroundColor: this.props.theme.palette.type === "dark" ? "#292929" : "#f3f3f3",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px", //same as in MuiPopover-paper
        fontSize: this.getFontSize(),
      },
    })(Paper);
  }

  private getFontSize(): Maybe<string | number> {
    return this.props.appLayoutMode === AppLayoutMode.dataWithDrawer ? this.props.theme.typography.body2.fontSize : this.props.theme.typography.body1.fontSize;
  }

  private setupMapConnection(): void {
    const map = MapLink.getLinkToMap();
    map.addObserver(this);
  }
}

export default withTheme(MapSearchBar);
