/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import ClientProperties from "../clientSpecific/ClientProperties";
import Event, { EventState, IEventMetadata } from "../clientSpecific/Event";
import { EventRepositoryListener } from "./EventRepositoryListener";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Maybe, Nullable, Voidable } from "../../types/aliases";
import { EventsFeedDocument, EventsListDocument, EventsMetadataListDocument, EventsDeactivateDocument, EventsFeedSubscription, EventsMetadataAddDocument, EventsMetadataDeleteDocument } from "../../generated/gqlEvents";
import AsyncCache from "data/utils/AsynCache";
import AbstractSetSubscriptionManager from "data/utils/subscriptions/AbstractSetSubscriptionManager";

export default class EventsRepository extends AbstractSetSubscriptionManager<EventRepositoryListener, EventsFeedSubscription> {
  public static readonly EVENT_AGE_DAYS = 365;
  private static __instance: EventsRepository = new EventsRepository();

  private readonly initQueue = new AsyncCache();

  private initialized = false;
  private events: Event[] = [];
  private eventMetadata: Map<string, IEventMetadata> = new Map<string, IEventMetadata>();

  private constructor() {
    super(Service.EVENTS, EventsFeedDocument);
  }

  public static get instance(): EventsRepository {
    return EventsRepository.__instance;
  }

  public getName(): string {
    return "events";
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  public async init(): Promise<void> {
    const initRepo = async (): Promise<void> => {
      this.eventMetadata = new Map();
      await this.fetchEventMetadata();
      const range = ClientProperties.getDefaultEventTimestampRange(EventsRepository.EVENT_AGE_DAYS);
      await this.fetchAllEvents(`${range.start}`, `${range.end}`);
      this.subscribeOnce();
      this.initialized = true;
    };
    return this.initQueue.get("initEventsRepo", initRepo);
  }

  public uninit(): void {
    this.unsubscribe();

    if (this.eventMetadata) {
      this.eventMetadata.clear();
    }
  }

  public getAllActiveEvents(): Event[] {
    return this.events.filter((e: Event) => {
      return e.eventState === EventState.Active;
    });
  }

  public getEventDescription(eventId: string): Nullable<string> {
    return this.eventMetadata.get(eventId)?.description ?? null;
  }

  protected subscriptionHandler(result: Maybe<EventsFeedSubscription>): void {
    if (!result?.eventsFeed) return;
    const event = result.eventsFeed.item;
    const isNew = this.handleEvent(event);
    this.forEachListener((listener: EventRepositoryListener) => {
      if (isNew) {
        listener.onEvent(event);
      } else {
        listener.onEventStateChanged(event);
      }
    });
  }

  private async fetchEventMetadata(): Promise<void> {
    let nextToken: Nullable<string> = null;

    try {
      do {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const metadataResponse = await client.query(
          EventsMetadataListDocument,
          {
            nextToken,
          },
          {
            fetchPolicy: "network-only",
          },
        );
        // cast is required or response's type inference becomes cyclic
        nextToken = (metadataResponse.data.eventsMetadataList?.nextToken ?? null) as Nullable<string>;
        metadataResponse.data.eventsMetadataList?.eventMetadataItems.forEach((metadata) => {
          this.eventMetadata.set(metadata.eventId, metadata);
        });
      } while (nextToken);
    } catch (error) {
      console.error("Error", error);
    }
  }

  private handleEvent(event: Event): boolean {
    const index = this.events.findIndex(EventsRepository.getIsSameEventComparator(event));
    const isNew = index === -1;

    if (isNew) {
      this.events.push(event);
    } else {
      this.events[index] = event;
    }
    return isNew;
  }

  public async fetchAllEvents(startTimestamp: string, endTimestamp?: string): Promise<Event[]> {
    let nextToken: Nullable<string> = null;
    let events: Event[] = [];

    try {
      do {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const eventsResponse = await client.query(
          EventsListDocument,
          {
            startTimestamp,
            endTimestamp: endTimestamp != null ? endTimestamp : `${Date.now()}`,
            nextToken,
          },
        );
        // cast is required or response's type inference becomes cyclic
        nextToken = this.parseNextToken(eventsResponse.data.eventsList?.nextToken);
        events = events.concat(eventsResponse.data.eventsList?.events ?? []);
      } while (nextToken);
      this.events = events;
      return events;
    } catch (error) {
      console.error("Error", error);
      return [];
    }
  }

  private parseNextToken(token: Voidable<string>): Nullable<string> {
    // backend returns "e30=" (base 64 representation of empty JSON object {}) when no more items are available
    if (!token || token === "e30=") {
      return null;
    } else {
      return token;
    }
  }

  public static getIsSameEventComparator(event: Event): (e: Event) => boolean {
    return (e: Event): boolean => event.deviceId === e.deviceId
      && event.eventId === e.eventId
      && event.timestamp === e.timestamp;
  }

  // - - - - - - - - - - -
  // Sensoan changes

  public clear(): void {
    this.uninit();
    this.events = [];
    this.initQueue.clear();
    this.initialized = false;
  }

  public async deactivateEvent(event: Event): Promise<void> {
    const eventPayload = {
      deviceId: event.deviceId,
      timestamp: event.timestamp,
    };

    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
      await client.mutate(
        EventsDeactivateDocument,
        {
          payload: eventPayload,
        },
      );
    } catch (error) {
      console.log(error);
    }
  }

  public getAllEvents(): Event[] {
    return this.events;
  }

  public async addEventMetadata(metadata: IEventMetadata): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
      const { data } = await client.mutate(
        EventsMetadataAddDocument,
        {
          ...metadata,
        },
      );

      if (data?.eventsMetadataAdd) {
        const { eventsMetadataAdd } = data;
        this.eventMetadata.set(eventsMetadataAdd.eventId, eventsMetadataAdd);
        return eventsMetadataAdd.eventId;
      } else {
        console.error("Unexpected type of response data: ", data);
        return "error";
      }
    } catch (error) {
      console.log(error);
      return "error";
    }
  }

  public async deleteEventMetadata(eventId: string): Promise<Maybe<string>> {
    const hasExistingEvents = this.getAllEvents().some(event => event.eventId === eventId);

    if (!hasExistingEvents) {
      try {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        await client.mutate(
          EventsMetadataDeleteDocument,
          {
            eventId,
          },
        );
        this.eventMetadata.delete(eventId);
        return "success";
      } catch (error) {
        console.log(error);
        return "error";
      }
    }
  }
}
