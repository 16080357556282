import React, { Component, ElementType } from "react";
import { Nullable } from "types/aliases";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SpeedIcon from "@material-ui/icons/Speed";
import { MeasurementSetTreeItem, MeasurementSetType, MeasurementSetConfig, MeasurementSet } from "data/types/measurementSetTypes";
import MeasurementSetSelector from "data/measurement-set-selector/MeasurementSetSelector";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";

interface Props {
  configLevelClickIcon?: JSX.Element;
  editSelectedConfig?: (event: React.ChangeEvent<unknown>, measurementSet: MeasurementSetTreeItem) => void;
  filter?: (item: MeasurementSet) => boolean;
  groupLevelClickIcon?: JSX.Element;
  isLabelIconDisabled?: (setId: string) => boolean;
  onSelectGroup?: (event: React.ChangeEvent<unknown>, measurementSet: MeasurementSetTreeItem) => void;
  treeItems: MeasurementSetTreeItem[];
  labelItem: ElementType;
}

interface State {
  expandedItems: string[];
}

class MeasurementSetTree extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      expandedItems: [],
    };
  }

  private renderTree(treeItem: MeasurementSetTreeItem): Nullable<JSX.Element> {
    const Label: ElementType = this.props.labelItem;

    if (!this.props.filter || this.props.filter(treeItem)) {
      if (treeItem.type === MeasurementSetType.GROUP) {
        return (
          <TreeItem
            key={treeItem.setId}
            nodeId={treeItem.setId}
            label={
              <Label
                isLabelIconDisabled={this.props.isLabelIconDisabled}
                item={treeItem}
                icon={this.props.groupLevelClickIcon}
                onLabelClick={(event: React.ChangeEvent<unknown>): void => this.props.onSelectGroup ? this.props.onSelectGroup(event, treeItem) : undefined}
              />
            }
            collapseIcon={<ExpandMoreIcon />}
            expandIcon={<ChevronRightIcon />}
            classes={{ }}
          >
            {treeItem.childs?.map((node: MeasurementSetTreeItem) => this.renderTree(node))}
          </TreeItem>
        );
      } else {
        return (
          <TreeItem
            key={treeItem.setId}
            nodeId={treeItem.setId}
            label={
              <Label
                item={treeItem}
                icon={this.props.configLevelClickIcon}
                onLabelClick={this.props.editSelectedConfig}
              />
            }
            icon={<SpeedIcon />}
            classes={{ }}
          />
        );
      }
    } else {
      return null;
    }
  }

  public render(): JSX.Element {
    return (
      <TreeView
        expanded={this.state.expandedItems}
        defaultEndIcon={<ChevronRightIcon style={{ opacity: 0.4 }}/>}
        onNodeToggle={(event: React.ChangeEvent<unknown>, nodeIds: string[]): void => this.onNodeToggle(nodeIds)}
        onNodeSelect={(event: React.ChangeEvent<unknown>, nodeId: string): void => this.onNodeSelect(nodeId)}
      >
        {
          this.props.treeItems.map((item: MeasurementSetTreeItem) => this.renderTree(item))
        }
      </TreeView>
    );
  }

  private onNodeSelect(selectedNodeId: string): void {
    const searchResult: MeasurementSetTreeItem[] = [];
    MeasurementSetRepository.getInstance().findItemFromTree(selectedNodeId, this.props.treeItems, searchResult);
    const selectedNode: MeasurementSetTreeItem = searchResult[0];

    if (selectedNode.type === MeasurementSetType.CONFIG) {
      MeasurementSetSelector.getInstance().setSelectedMeasurementSet(selectedNode as MeasurementSetConfig);
    }
  }

  private onNodeToggle(expandedNodeIds: string[]): void {
    this.setState({ expandedItems: expandedNodeIds });
  }

}

export default MeasurementSetTree;
