import { Box, Popover, Theme, Typography, withTheme } from "@material-ui/core";
import MeasurementSetTree from "components/measurement-sets/tree-components/measurement-set-tree/MeasurementSetTree";
import TreeTextLabel from "components/measurement-sets/tree-components/measurement-set-tree/TreeTextLabel";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import Localization from "data/localization-sensoan/Localization";
import MeasurementSetSelector from "data/measurement-set-selector/MeasurementSetSelector";
import React, { Component } from "react";
import { Nullable } from "types/aliases";

interface Props {
  anchorEl: Nullable<HTMLButtonElement>;
  close: () => void;
  theme: Theme;
}

interface State {
}

class MeasSetSelect extends Component<Props, State> {

  public render(): JSX.Element {
    const open = this.props.anchorEl !== null ? true : false;
    return (
      <Popover
        onClose={(): void => this.props.close()}
        anchorEl={this.props.anchorEl}
        open={open}
      >
        <Box p={4} display="flex" flexWrap="wrap" justifyContent="center" width="20rem" bgcolor={this.props.theme.palette.background.default}>
          <Box pl={2} width="100%" onClick={(_event: React.MouseEvent<HTMLButtonElement>): void => MeasurementSetSelector.getInstance().setSelectedMeasurementSet(null)}>
            <Typography>
              {Localization.getInstance().getDisplayText("MeasSetSelect", "clear")}
            </Typography>
          </Box>
          <MeasurementSetTree
            treeItems={MeasurementSetRepository.getInstance().getTree()}
            labelItem={TreeTextLabel}
          />
        </Box>
      </Popover>
    );
  }
}

export default withTheme(MeasSetSelect);
