import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSJSON: string;
}

export interface Query {
  __typename?: 'Query';
  getLocalizationText?: Maybe<Scalars['AWSJSON']>;
  getLoginLocalizationText?: Maybe<Scalars['AWSJSON']>;
  getMeasurementSetTree?: Maybe<Scalars['String']>;
  measSetsList: MeasSets;
  measurementJobsList?: Maybe<MeasurementJobs>;
  measurementServiceVersion?: Maybe<Scalars['String']>;
}


export interface QueryGetLocalizationTextArgs {
  localeId: Scalars['String'];
}


export interface QueryGetLoginLocalizationTextArgs {
  localeId: Scalars['String'];
}


export interface QueryMeasSetsListArgs {
  groupId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
}


export interface QueryMeasurementJobsListArgs {
  nextToken?: InputMaybe<Scalars['String']>;
  setId: Scalars['String'];
}

export interface MeasSets {
  __typename?: 'MeasSets';
  items: Array<Maybe<MeasSet>>;
  nextToken?: Maybe<Scalars['String']>;
}

export interface MeasSet {
  __typename?: 'MeasSet';
  config: Scalars['AWSJSON'];
  displayName: Scalars['String'];
  groupId: Scalars['String'];
  metadata: Scalars['String'];
  setId: Scalars['String'];
}

export interface MeasurementJobs {
  __typename?: 'MeasurementJobs';
  measurementJobs?: Maybe<Array<Maybe<MeasurementJob>>>;
  nextToken?: Maybe<Scalars['String']>;
}

export interface MeasurementJob {
  __typename?: 'MeasurementJob';
  displayName: Scalars['String'];
  endTriggerConf?: Maybe<Scalars['AWSJSON']>;
  endTriggerId?: Maybe<Scalars['String']>;
  endTs?: Maybe<Scalars['Float']>;
  jobId: Scalars['String'];
  metadata?: Maybe<Scalars['String']>;
  responsible: Scalars['String'];
  setId: Scalars['String'];
  startTriggerConf?: Maybe<Scalars['AWSJSON']>;
  startTriggerId?: Maybe<Scalars['String']>;
  startTs?: Maybe<Scalars['Float']>;
  status: MeasurementJobStatus;
}

export enum MeasurementJobStatus {
  NotStarted = 'not_started',
  Ready = 'ready',
  Running = 'running'
}

export interface Mutation {
  __typename?: 'Mutation';
  addMeasurementSetConfig?: Maybe<Scalars['String']>;
  addMeasurementSetGroup?: Maybe<Scalars['String']>;
  addParentToMeasurementSetItem?: Maybe<Scalars['String']>;
  bugReporting?: Maybe<Scalars['String']>;
  deleteMeasurementSetItem?: Maybe<Scalars['String']>;
  measSetsAdd?: Maybe<MeasSet>;
  measSetsDelete?: Maybe<MeasSet>;
  measurementJobsAdd?: Maybe<MeasurementJob>;
  measurementJobsAdd2?: Maybe<MeasurementJob>;
  measurementJobsDelete?: Maybe<MeasurementJob>;
  measurementJobsDetailsEdit?: Maybe<MeasurementJob>;
  measurementJobsStart?: Maybe<MeasurementJob>;
  measurementJobsStop?: Maybe<MeasurementJob>;
  measurementJobsStop2?: Maybe<MeasurementJob>;
  measurementJobsUpdateNotify?: Maybe<MeasurementJobUpdate>;
  measurementSetsConfigDetailsEdit: MeasurementSet;
  measurementSetsConfigEdit: MeasurementSet;
  measurementSetsItemDisplayNameEdit: MeasurementSet;
}


export interface MutationAddMeasurementSetConfigArgs {
  config: Scalars['AWSJSON'];
  configDisplayName: Scalars['String'];
  metadata?: InputMaybe<Scalars['String']>;
  parentIds: Array<InputMaybe<Scalars['String']>>;
}


export interface MutationAddMeasurementSetGroupArgs {
  groupDisplayName: Scalars['String'];
  parentIds: Array<InputMaybe<Scalars['String']>>;
}


export interface MutationAddParentToMeasurementSetItemArgs {
  childId: Scalars['String'];
  parentId: Scalars['String'];
}


export interface MutationBugReportingArgs {
  msgBody: Scalars['String'];
  subject: Scalars['String'];
}


export interface MutationDeleteMeasurementSetItemArgs {
  setId: Scalars['String'];
}


export interface MutationMeasSetsAddArgs {
  measSet: MeasSetInput;
}


export interface MutationMeasSetsDeleteArgs {
  groupId: Scalars['String'];
  setId: Scalars['String'];
}


export interface MutationMeasurementJobsAddArgs {
  measurementJob: MeasurementJobInput;
}


export interface MutationMeasurementJobsAdd2Args {
  measurementJob: MeasurementJobInput;
}


export interface MutationMeasurementJobsDeleteArgs {
  jobId: Scalars['String'];
  setId: Scalars['String'];
}


export interface MutationMeasurementJobsDetailsEditArgs {
  jobId: Scalars['String'];
  metadata: Scalars['String'];
  setId: Scalars['String'];
}


export interface MutationMeasurementJobsStartArgs {
  jobId: Scalars['String'];
  setId: Scalars['String'];
}


export interface MutationMeasurementJobsStopArgs {
  jobId: Scalars['String'];
  setId: Scalars['String'];
}


export interface MutationMeasurementJobsStop2Args {
  jobId: Scalars['String'];
  setId: Scalars['String'];
}


export interface MutationMeasurementJobsUpdateNotifyArgs {
  payload: Scalars['AWSJSON'];
}


export interface MutationMeasurementSetsConfigDetailsEditArgs {
  metadata: Scalars['String'];
  setId: Scalars['String'];
}


export interface MutationMeasurementSetsConfigEditArgs {
  config: Scalars['AWSJSON'];
  configDisplayName: Scalars['String'];
  metadata: Scalars['String'];
  setId: Scalars['String'];
}


export interface MutationMeasurementSetsItemDisplayNameEditArgs {
  displayName: Scalars['String'];
  setId: Scalars['String'];
}

export interface MeasSetInput {
  config: Scalars['AWSJSON'];
  displayName: Scalars['String'];
  groupId: Scalars['String'];
  metadata: Scalars['String'];
  setId: Scalars['String'];
}

export interface MeasurementJobInput {
  displayName: Scalars['String'];
  endTriggerConf?: InputMaybe<Scalars['AWSJSON']>;
  endTriggerId?: InputMaybe<Scalars['String']>;
  endTs?: InputMaybe<Scalars['Float']>;
  jobId: Scalars['String'];
  metadata?: InputMaybe<Scalars['String']>;
  responsible: Scalars['String'];
  setId: Scalars['String'];
  startTriggerConf?: InputMaybe<Scalars['AWSJSON']>;
  startTriggerId?: InputMaybe<Scalars['String']>;
  startTs?: InputMaybe<Scalars['Float']>;
  status: MeasurementJobStatus;
}

export interface MeasurementJobUpdate {
  __typename?: 'MeasurementJobUpdate';
  newImage?: Maybe<MeasJobImage>;
  oldImage?: Maybe<MeasJobImage>;
}

export interface MeasJobImage {
  __typename?: 'MeasJobImage';
  displayName?: Maybe<Scalars['String']>;
  endTriggerConf?: Maybe<Scalars['AWSJSON']>;
  endTriggerId?: Maybe<Scalars['String']>;
  endTs?: Maybe<Scalars['Float']>;
  jobId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  responsible?: Maybe<Scalars['String']>;
  setId?: Maybe<Scalars['String']>;
  startTriggerConf?: Maybe<Scalars['AWSJSON']>;
  startTriggerId?: Maybe<Scalars['String']>;
  startTs?: Maybe<Scalars['Float']>;
  status?: Maybe<MeasurementJobStatus>;
}

export interface MeasurementSet {
  __typename?: 'MeasurementSet';
  config: Scalars['AWSJSON'];
  displayName: Scalars['String'];
  metadata: Scalars['String'];
  parentIds: Array<Maybe<Scalars['String']>>;
  setId: Scalars['String'];
  type: MeasurementSetType;
}

export enum MeasurementSetType {
  Config = 'config',
  Group = 'group'
}

export interface Subscription {
  __typename?: 'Subscription';
  measurementJobsUpdateFeed?: Maybe<MeasurementJobUpdate>;
}

export interface Schema {
  __typename?: 'Schema';
  mutation?: Maybe<Mutation>;
  query?: Maybe<Query>;
}

export type MeasurementJobFieldsFragment = { __typename?: 'MeasurementJob', setId: string, jobId: string, displayName: string, responsible: string, startTs?: number | null | undefined, startTriggerConf?: string | null | undefined, startTriggerId?: string | null | undefined, endTs?: number | null | undefined, endTriggerConf?: string | null | undefined, endTriggerId?: string | null | undefined, metadata?: string | null | undefined, status: MeasurementJobStatus };

export type MeasurementSetFieldsFragment = { __typename?: 'MeasurementSet', setId: string, displayName: string, parentIds: Array<string | null | undefined>, metadata: string, config: string, type: MeasurementSetType };

export type GetLocalizationTextQueryVariables = Exact<{
  localeId: Scalars['String'];
}>;


export type GetLocalizationTextQuery = { __typename?: 'Query', getLocalizationText?: string | null | undefined };

export type GetLoginLocalizationTextQueryVariables = Exact<{
  localeId: Scalars['String'];
}>;


export type GetLoginLocalizationTextQuery = { __typename?: 'Query', getLoginLocalizationText?: string | null | undefined };

export type GetMeasurementSetTreeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeasurementSetTreeQuery = { __typename?: 'Query', getMeasurementSetTree?: string | null | undefined };

export type MeasurementJobsListQueryVariables = Exact<{
  setId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type MeasurementJobsListQuery = { __typename?: 'Query', measurementJobsList?: { __typename?: 'MeasurementJobs', nextToken?: string | null | undefined, measurementJobs?: Array<{ __typename?: 'MeasurementJob', setId: string, jobId: string, displayName: string, responsible: string, startTs?: number | null | undefined, startTriggerConf?: string | null | undefined, startTriggerId?: string | null | undefined, endTs?: number | null | undefined, endTriggerConf?: string | null | undefined, endTriggerId?: string | null | undefined, metadata?: string | null | undefined, status: MeasurementJobStatus } | null | undefined> | null | undefined } | null | undefined };

export type MeasurementServiceVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type MeasurementServiceVersionQuery = { __typename?: 'Query', measurementServiceVersion?: string | null | undefined };

export type BugReportingMutationVariables = Exact<{
  subject: Scalars['String'];
  msgBody: Scalars['String'];
}>;


export type BugReportingMutation = { __typename?: 'Mutation', bugReporting?: string | null | undefined };

export type MeasurementSetsConfigEditMutationVariables = Exact<{
  setId: Scalars['String'];
  configDisplayName: Scalars['String'];
  metadata: Scalars['String'];
  config: Scalars['AWSJSON'];
}>;


export type MeasurementSetsConfigEditMutation = { __typename?: 'Mutation', measurementSetsConfigEdit: { __typename?: 'MeasurementSet', setId: string, displayName: string, parentIds: Array<string | null | undefined>, metadata: string, config: string, type: MeasurementSetType } };

export type MeasurementSetsConfigDetailsEditMutationVariables = Exact<{
  setId: Scalars['String'];
  metadata: Scalars['String'];
}>;


export type MeasurementSetsConfigDetailsEditMutation = { __typename?: 'Mutation', measurementSetsConfigDetailsEdit: { __typename?: 'MeasurementSet', setId: string, displayName: string, parentIds: Array<string | null | undefined>, metadata: string, config: string, type: MeasurementSetType } };

export type MeasurementSetsItemDisplayNameEditMutationVariables = Exact<{
  setId: Scalars['String'];
  displayName: Scalars['String'];
}>;


export type MeasurementSetsItemDisplayNameEditMutation = { __typename?: 'Mutation', measurementSetsItemDisplayNameEdit: { __typename?: 'MeasurementSet', setId: string, displayName: string, parentIds: Array<string | null | undefined>, metadata: string, config: string, type: MeasurementSetType } };

export type AddMeasurementSetConfigMutationVariables = Exact<{
  configDisplayName: Scalars['String'];
  parentIds: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  config: Scalars['AWSJSON'];
}>;


export type AddMeasurementSetConfigMutation = { __typename?: 'Mutation', addMeasurementSetConfig?: string | null | undefined };

export type AddMeasurementSetGroupMutationVariables = Exact<{
  groupDisplayName: Scalars['String'];
  parentIds: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type AddMeasurementSetGroupMutation = { __typename?: 'Mutation', addMeasurementSetGroup?: string | null | undefined };

export type DeleteMeasurementSetItemMutationVariables = Exact<{
  setId: Scalars['String'];
}>;


export type DeleteMeasurementSetItemMutation = { __typename?: 'Mutation', deleteMeasurementSetItem?: string | null | undefined };

export type MeasurementJobsAddMutationVariables = Exact<{
  measurementJob: MeasurementJobInput;
}>;


export type MeasurementJobsAddMutation = { __typename?: 'Mutation', measurementJobsAdd?: { __typename?: 'MeasurementJob', setId: string, jobId: string, displayName: string, responsible: string, startTs?: number | null | undefined, startTriggerConf?: string | null | undefined, startTriggerId?: string | null | undefined, endTs?: number | null | undefined, endTriggerConf?: string | null | undefined, endTriggerId?: string | null | undefined, metadata?: string | null | undefined, status: MeasurementJobStatus } | null | undefined };

export type MeasurementJobsAdd2MutationVariables = Exact<{
  measurementJob: MeasurementJobInput;
}>;


export type MeasurementJobsAdd2Mutation = { __typename?: 'Mutation', measurementJobsAdd2?: { __typename?: 'MeasurementJob', setId: string, jobId: string, displayName: string, responsible: string, startTs?: number | null | undefined, startTriggerConf?: string | null | undefined, startTriggerId?: string | null | undefined, endTs?: number | null | undefined, endTriggerConf?: string | null | undefined, endTriggerId?: string | null | undefined, metadata?: string | null | undefined, status: MeasurementJobStatus } | null | undefined };

export type MeasurementJobsDeleteMutationVariables = Exact<{
  setId: Scalars['String'];
  jobId: Scalars['String'];
}>;


export type MeasurementJobsDeleteMutation = { __typename?: 'Mutation', measurementJobsDelete?: { __typename?: 'MeasurementJob', setId: string, jobId: string, displayName: string, responsible: string, startTs?: number | null | undefined, startTriggerConf?: string | null | undefined, startTriggerId?: string | null | undefined, endTs?: number | null | undefined, endTriggerConf?: string | null | undefined, endTriggerId?: string | null | undefined, metadata?: string | null | undefined, status: MeasurementJobStatus } | null | undefined };

export type MeasurementJobsDetailsEditMutationVariables = Exact<{
  setId: Scalars['String'];
  jobId: Scalars['String'];
  metadata: Scalars['String'];
}>;


export type MeasurementJobsDetailsEditMutation = { __typename?: 'Mutation', measurementJobsDetailsEdit?: { __typename?: 'MeasurementJob', setId: string, jobId: string, displayName: string, responsible: string, startTs?: number | null | undefined, startTriggerConf?: string | null | undefined, startTriggerId?: string | null | undefined, endTs?: number | null | undefined, endTriggerConf?: string | null | undefined, endTriggerId?: string | null | undefined, metadata?: string | null | undefined, status: MeasurementJobStatus } | null | undefined };

export type MeasurementJobsStartMutationVariables = Exact<{
  setId: Scalars['String'];
  jobId: Scalars['String'];
}>;


export type MeasurementJobsStartMutation = { __typename?: 'Mutation', measurementJobsStart?: { __typename?: 'MeasurementJob', setId: string, jobId: string, displayName: string, responsible: string, startTs?: number | null | undefined, startTriggerConf?: string | null | undefined, startTriggerId?: string | null | undefined, endTs?: number | null | undefined, endTriggerConf?: string | null | undefined, endTriggerId?: string | null | undefined, metadata?: string | null | undefined, status: MeasurementJobStatus } | null | undefined };

export type MeasurementJobsStopMutationVariables = Exact<{
  setId: Scalars['String'];
  jobId: Scalars['String'];
}>;


export type MeasurementJobsStopMutation = { __typename?: 'Mutation', measurementJobsStop?: { __typename?: 'MeasurementJob', setId: string, jobId: string, displayName: string, responsible: string, startTs?: number | null | undefined, startTriggerConf?: string | null | undefined, startTriggerId?: string | null | undefined, endTs?: number | null | undefined, endTriggerConf?: string | null | undefined, endTriggerId?: string | null | undefined, metadata?: string | null | undefined, status: MeasurementJobStatus } | null | undefined };

export type MeasurementJobsStop2MutationVariables = Exact<{
  setId: Scalars['String'];
  jobId: Scalars['String'];
}>;


export type MeasurementJobsStop2Mutation = { __typename?: 'Mutation', measurementJobsStop2?: { __typename?: 'MeasurementJob', setId: string, jobId: string, displayName: string, responsible: string, startTs?: number | null | undefined, startTriggerConf?: string | null | undefined, startTriggerId?: string | null | undefined, endTs?: number | null | undefined, endTriggerConf?: string | null | undefined, endTriggerId?: string | null | undefined, metadata?: string | null | undefined, status: MeasurementJobStatus } | null | undefined };

export type MeasurementJobsUpdateFeedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type MeasurementJobsUpdateFeedSubscription = { __typename?: 'Subscription', measurementJobsUpdateFeed?: { __typename?: 'MeasurementJobUpdate', oldImage?: { __typename?: 'MeasJobImage', setId?: string | null | undefined, jobId?: string | null | undefined } | null | undefined, newImage?: { __typename?: 'MeasJobImage', setId?: string | null | undefined, jobId?: string | null | undefined, displayName?: string | null | undefined, responsible?: string | null | undefined, startTs?: number | null | undefined, startTriggerConf?: string | null | undefined, startTriggerId?: string | null | undefined, endTs?: number | null | undefined, endTriggerConf?: string | null | undefined, endTriggerId?: string | null | undefined, metadata?: string | null | undefined, status?: MeasurementJobStatus | null | undefined } | null | undefined } | null | undefined };

export const MeasurementJobFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"measurementJobFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MeasurementJob"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setId"}},{"kind":"Field","name":{"kind":"Name","value":"jobId"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"responsible"}},{"kind":"Field","name":{"kind":"Name","value":"startTs"}},{"kind":"Field","name":{"kind":"Name","value":"startTriggerConf"}},{"kind":"Field","name":{"kind":"Name","value":"startTriggerId"}},{"kind":"Field","name":{"kind":"Name","value":"endTs"}},{"kind":"Field","name":{"kind":"Name","value":"endTriggerConf"}},{"kind":"Field","name":{"kind":"Name","value":"endTriggerId"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]} as unknown as DocumentNode<MeasurementJobFieldsFragment, unknown>;
export const MeasurementSetFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"measurementSetFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MeasurementSet"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setId"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"parentIds"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"config"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]} as unknown as DocumentNode<MeasurementSetFieldsFragment, unknown>;
export const GetLocalizationTextDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getLocalizationText"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"localeId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getLocalizationText"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"localeId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"localeId"}}}]}]}}]} as unknown as DocumentNode<GetLocalizationTextQuery, GetLocalizationTextQueryVariables>;
export const GetLoginLocalizationTextDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getLoginLocalizationText"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"localeId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getLoginLocalizationText"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"localeId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"localeId"}}}]}]}}]} as unknown as DocumentNode<GetLoginLocalizationTextQuery, GetLoginLocalizationTextQueryVariables>;
export const GetMeasurementSetTreeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getMeasurementSetTree"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getMeasurementSetTree"}}]}}]} as unknown as DocumentNode<GetMeasurementSetTreeQuery, GetMeasurementSetTreeQueryVariables>;
export const MeasurementJobsListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"measurementJobsList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"setId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measurementJobsList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"setId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"setId"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measurementJobs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"measurementJobFields"}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextToken"}}]}}]}},...MeasurementJobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<MeasurementJobsListQuery, MeasurementJobsListQueryVariables>;
export const MeasurementServiceVersionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"measurementServiceVersion"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measurementServiceVersion"}}]}}]} as unknown as DocumentNode<MeasurementServiceVersionQuery, MeasurementServiceVersionQueryVariables>;
export const BugReportingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"bugReporting"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"subject"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"msgBody"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bugReporting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"subject"},"value":{"kind":"Variable","name":{"kind":"Name","value":"subject"}}},{"kind":"Argument","name":{"kind":"Name","value":"msgBody"},"value":{"kind":"Variable","name":{"kind":"Name","value":"msgBody"}}}]}]}}]} as unknown as DocumentNode<BugReportingMutation, BugReportingMutationVariables>;
export const MeasurementSetsConfigEditDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"measurementSetsConfigEdit"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"setId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"configDisplayName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"metadata"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"config"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AWSJSON"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measurementSetsConfigEdit"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"setId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"setId"}}},{"kind":"Argument","name":{"kind":"Name","value":"configDisplayName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"configDisplayName"}}},{"kind":"Argument","name":{"kind":"Name","value":"metadata"},"value":{"kind":"Variable","name":{"kind":"Name","value":"metadata"}}},{"kind":"Argument","name":{"kind":"Name","value":"config"},"value":{"kind":"Variable","name":{"kind":"Name","value":"config"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"measurementSetFields"}}]}}]}},...MeasurementSetFieldsFragmentDoc.definitions]} as unknown as DocumentNode<MeasurementSetsConfigEditMutation, MeasurementSetsConfigEditMutationVariables>;
export const MeasurementSetsConfigDetailsEditDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"measurementSetsConfigDetailsEdit"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"setId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"metadata"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measurementSetsConfigDetailsEdit"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"setId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"setId"}}},{"kind":"Argument","name":{"kind":"Name","value":"metadata"},"value":{"kind":"Variable","name":{"kind":"Name","value":"metadata"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"measurementSetFields"}}]}}]}},...MeasurementSetFieldsFragmentDoc.definitions]} as unknown as DocumentNode<MeasurementSetsConfigDetailsEditMutation, MeasurementSetsConfigDetailsEditMutationVariables>;
export const MeasurementSetsItemDisplayNameEditDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"measurementSetsItemDisplayNameEdit"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"setId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"displayName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measurementSetsItemDisplayNameEdit"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"setId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"setId"}}},{"kind":"Argument","name":{"kind":"Name","value":"displayName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"displayName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"measurementSetFields"}}]}}]}},...MeasurementSetFieldsFragmentDoc.definitions]} as unknown as DocumentNode<MeasurementSetsItemDisplayNameEditMutation, MeasurementSetsItemDisplayNameEditMutationVariables>;
export const AddMeasurementSetConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"addMeasurementSetConfig"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"configDisplayName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"parentIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"metadata"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"config"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AWSJSON"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addMeasurementSetConfig"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"configDisplayName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"configDisplayName"}}},{"kind":"Argument","name":{"kind":"Name","value":"parentIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"parentIds"}}},{"kind":"Argument","name":{"kind":"Name","value":"metadata"},"value":{"kind":"Variable","name":{"kind":"Name","value":"metadata"}}},{"kind":"Argument","name":{"kind":"Name","value":"config"},"value":{"kind":"Variable","name":{"kind":"Name","value":"config"}}}]}]}}]} as unknown as DocumentNode<AddMeasurementSetConfigMutation, AddMeasurementSetConfigMutationVariables>;
export const AddMeasurementSetGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"addMeasurementSetGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"groupDisplayName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"parentIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addMeasurementSetGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"groupDisplayName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"groupDisplayName"}}},{"kind":"Argument","name":{"kind":"Name","value":"parentIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"parentIds"}}}]}]}}]} as unknown as DocumentNode<AddMeasurementSetGroupMutation, AddMeasurementSetGroupMutationVariables>;
export const DeleteMeasurementSetItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"deleteMeasurementSetItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"setId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteMeasurementSetItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"setId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"setId"}}}]}]}}]} as unknown as DocumentNode<DeleteMeasurementSetItemMutation, DeleteMeasurementSetItemMutationVariables>;
export const MeasurementJobsAddDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"measurementJobsAdd"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"measurementJob"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MeasurementJobInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measurementJobsAdd"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"measurementJob"},"value":{"kind":"Variable","name":{"kind":"Name","value":"measurementJob"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"measurementJobFields"}}]}}]}},...MeasurementJobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<MeasurementJobsAddMutation, MeasurementJobsAddMutationVariables>;
export const MeasurementJobsAdd2Document = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"measurementJobsAdd2"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"measurementJob"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MeasurementJobInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measurementJobsAdd2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"measurementJob"},"value":{"kind":"Variable","name":{"kind":"Name","value":"measurementJob"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"measurementJobFields"}}]}}]}},...MeasurementJobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<MeasurementJobsAdd2Mutation, MeasurementJobsAdd2MutationVariables>;
export const MeasurementJobsDeleteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"measurementJobsDelete"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"setId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measurementJobsDelete"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"setId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"setId"}}},{"kind":"Argument","name":{"kind":"Name","value":"jobId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"measurementJobFields"}}]}}]}},...MeasurementJobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<MeasurementJobsDeleteMutation, MeasurementJobsDeleteMutationVariables>;
export const MeasurementJobsDetailsEditDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"measurementJobsDetailsEdit"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"setId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"metadata"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measurementJobsDetailsEdit"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"setId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"setId"}}},{"kind":"Argument","name":{"kind":"Name","value":"jobId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}}},{"kind":"Argument","name":{"kind":"Name","value":"metadata"},"value":{"kind":"Variable","name":{"kind":"Name","value":"metadata"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"measurementJobFields"}}]}}]}},...MeasurementJobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<MeasurementJobsDetailsEditMutation, MeasurementJobsDetailsEditMutationVariables>;
export const MeasurementJobsStartDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"measurementJobsStart"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"setId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measurementJobsStart"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"setId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"setId"}}},{"kind":"Argument","name":{"kind":"Name","value":"jobId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"measurementJobFields"}}]}}]}},...MeasurementJobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<MeasurementJobsStartMutation, MeasurementJobsStartMutationVariables>;
export const MeasurementJobsStopDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"measurementJobsStop"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"setId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measurementJobsStop"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"setId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"setId"}}},{"kind":"Argument","name":{"kind":"Name","value":"jobId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"measurementJobFields"}}]}}]}},...MeasurementJobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<MeasurementJobsStopMutation, MeasurementJobsStopMutationVariables>;
export const MeasurementJobsStop2Document = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"measurementJobsStop2"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"setId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measurementJobsStop2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"setId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"setId"}}},{"kind":"Argument","name":{"kind":"Name","value":"jobId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"measurementJobFields"}}]}}]}},...MeasurementJobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<MeasurementJobsStop2Mutation, MeasurementJobsStop2MutationVariables>;
export const MeasurementJobsUpdateFeedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"measurementJobsUpdateFeed"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"measurementJobsUpdateFeed"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"oldImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setId"}},{"kind":"Field","name":{"kind":"Name","value":"jobId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"newImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setId"}},{"kind":"Field","name":{"kind":"Name","value":"jobId"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"responsible"}},{"kind":"Field","name":{"kind":"Name","value":"startTs"}},{"kind":"Field","name":{"kind":"Name","value":"startTriggerConf"}},{"kind":"Field","name":{"kind":"Name","value":"startTriggerId"}},{"kind":"Field","name":{"kind":"Name","value":"endTs"}},{"kind":"Field","name":{"kind":"Name","value":"endTriggerConf"}},{"kind":"Field","name":{"kind":"Name","value":"endTriggerId"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode<MeasurementJobsUpdateFeedSubscription, MeasurementJobsUpdateFeedSubscriptionVariables>;