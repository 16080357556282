import React, { Component, Fragment } from "react";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { Box } from "@material-ui/core";
import AdminView from "components/admin-view";
import UserView from "components/user-view";
// import AuthWrapper from "../data/auth/authWrapper";
import Device, { DeviceObserver } from "data/device/Device";
// import EventsRepository from "../data/events/EventsRepository";
import DeviceGroup from "data/device/DeviceGroup";
// import OtaManager from "../data/ota/otaManager";
import AppViews, { AppView } from "client/AppViews";
import { Maybe } from "types/aliases";
import AuthWrapper from "data/auth/AuthWrapper";
import OtaManager from "data/ota/OtaManager";
import DataRepositoryFactory from "data/data-storage/DataRepositoryFactory";
import { AppLayoutMode } from "types/sensoanUiTypes";
import DeviceNavigationCache from "utils/DeviceNavigationCache";
import PathsSensoan from "data/paths/PathsSensoan";
// import UrlParams from "../components-sensoan/inputs/UrlParams";


interface Props extends RouteComponentProps {
  appLayoutMode: AppLayoutMode;
}

interface State {
  groups?: DeviceGroup[];
  devices?: Device[];
  userLoggedIn: boolean;
  visible: boolean;
}

class ContentController extends Component<Props, State> implements DeviceObserver {

  private deviceSelector = DeviceNavigationCache.getInstance();

  public constructor(props: Props) {
    super(props);
    this.state = {
      userLoggedIn: false,
      visible: true,
    };
  }

  public async componentDidMount(): Promise<void> {
    if (await AuthWrapper.isCurrentUserAuthenticated()) {
      this.setState({ userLoggedIn: true });
    }

    if (await AuthWrapper.isCurrentUserAuthenticated()) {
    //   DeviceSelector.getInstance().addObserver(this);
    //   await this.getGroups();
    //   await this.getDevices();
      OtaManager.getInstance().init();
      DataRepositoryFactory.initRepositories();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.appLayoutMode === AppLayoutMode.mapWithDrawer && prevProps.appLayoutMode === AppLayoutMode.dataWithDrawer) {
      this.setState({ visible: false });
    }

    if (this.props.appLayoutMode === AppLayoutMode.dataWithDrawer && prevProps.appLayoutMode === AppLayoutMode.mapWithDrawer) {
      setTimeout(() => { // without setTimeout list view is shown for a little time when selecting a job or set from full screen map
        this.setState({ visible: true });
      }, 10);
    }
  }

  public componentWillUnmount(): void {
    // this.cleanupDeviceStateObserver();
    // DeviceSelector.getInstance().removeObserver(this);
    // EventsRepository.getInstance().uninit();
    OtaManager.getInstance().uninit();
    // TODO: should be moved to DataRepositoryFactory?
    DataRepositoryFactory.clearRepositories();
  }

  public async onSelectedGroupChanged(group?: DeviceGroup): Promise<void> {
    console.log(`onSelectedGroupChanged(${group?.getId()})`);

    if (group && !this.state.groups?.includes(group)) {
      const groupDevices = await group.getDevices();
      this.registerDeviceStateObservers(groupDevices);
      const deviceSet = new Set(this.state.devices ?? []);
      groupDevices.forEach(device => deviceSet.add(device));

      this.setState({ devices: [...deviceSet] });
    }
  }

  private registerDeviceStateObservers(devices?: Device[]): void {
    const devs = devices ?? this.state.devices ?? [];
    devs.forEach(device => device.addObserver(this));
  }

  private removeDeviceStateObservers(): void {
    this.state.devices?.forEach((device: Device) => {
      device.removeObserver(this);
    });
  }

  public onDeviceStateUpdated(device: Device): void {
    console.log(`onDeviceStateUpdate ${device.getId()}`);
    let devices: Maybe<Device[]>;

    if (this.state.devices) {
      devices = this.state.devices.slice();
      const deviceIndex = devices.findIndex(dev => dev.getId() === device.getId());

      // should device devices update, if the state of a single device updates?
      if (deviceIndex > -1) {
        devices[deviceIndex] = device;
      }
    } else {
      devices = [device];
    }
    this.setState({ devices });
  }

  private triggerGroupsUpdate = (): void => {
    console.log("Body doesn't trigger groups update");
  };

  // TODO: AdminWrapper should update groups using DeviceRepository
  private renderAdminView = (): JSX.Element => (
    // TODO: Admin näkymän DataRequirements ei toimi koska adminControlled
    <AdminView />
  );

  private renderView(item: AppView): JSX.Element {
    return (
      <Fragment>
        {/* <UrlParams /> */}
        <item.View />
      </Fragment>
    );
  }

  public render(): JSX.Element {
    return (
      // TODO: remove section tag?
      <Box display={!this.state.visible ? "none" : undefined} height="100%">
        <Switch>
          {AppViews.map((item: AppView, index: number): JSX.Element =>
            <Route key={index} exact={true} path={`${item.path}/:id?`}>
              {this.props.location.pathname.startsWith(`${item.path}`)
                ? this.renderView(item)
                : null
              }
            </Route>,
          )}
          <Route
            exact={true}
            path={PathsSensoan.USER}
          >
            <UserView />
          </Route>
          <Route
            exact={true}
            path={PathsSensoan.ADMIN}
            render={this.renderAdminView}
          />
          <Route
            exact={false}
            path={PathsSensoan.ROOT}
          >
            <Redirect to={PathsSensoan.STATUS} />
          </Route>
        </Switch>
      </Box>
    );
  }
}

export default withRouter(ContentController);
