import React, { Component } from "react";
import { Box, Theme, Fab, withTheme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Localization from "data/localization-sensoan/Localization";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";

interface Props {
  onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  theme: Theme;
}

class ListViewFab extends Component<Props> {

  public render(): JSX.Element {
    return (
      <Box display="flex" justifyContent="center">
        <Fab
          size="medium"
          color="primary"
          aria-label="loadMore"
          variant="extended"
          style={{ bottom: "5vh", zIndex: 1300, textTransform: "none", marginRight: "10px" }}
          onClick={this.props.onClick}
        >
          {Localization.getInstance().getDisplayText("ListViewFab", "loadMore")}
          <SSvgIcon color={SSvgIconColorProps.white} iconComponent={AddIcon}/>
        </Fab>
      </Box>
    );
  }
}

export default withTheme(ListViewFab);
