import AWSThing from "data/device/AWSThing";
import { RuuviGWHWState } from "./RuuviGWHWState";
import { RuuviGWHWStateProperties } from "./RuuviGWHWStateProperties";
import AWSBackend from "data/backend/AWSBackend";
import HyperIcon from "assets/device/icons/router-24px.svg";
import { DeviceParameters } from "data/device/Device";


export class RuuviGWHW extends AWSThing<RuuviGWHWState> {
  public static type = "RuuviGW";

  public constructor(backend: AWSBackend, params: DeviceParameters) {
    super(RuuviGWHW.type, backend, params);
  }

  public getIcon(): string {
    return HyperIcon;
  }

  public createState(timestamp?: number, reported?: Partial<RuuviGWHWStateProperties>, desired?: Partial<RuuviGWHWStateProperties>):
  RuuviGWHWState {
    return new RuuviGWHWState(this.getId(), new RuuviGWHWStateProperties(reported ?? {}), new RuuviGWHWStateProperties(desired ?? {}), timestamp);
  }
}
