import React, { Component } from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import Localization from "data/localization-sensoan/Localization";

interface Props {
}

interface State {
}

class PreviewPlaceholderBox extends Component<Props, State> {
  public render(): JSX.Element {
    const boxHeight = "400px";
    const sm: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 12;
    const md: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 10;
    const lg: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 10;
    const xl: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 10;
    const shadow = "0px 4px 4px rgba(0, 0, 0, 0.35)";

    return (
      <Grid container justifyContent="center" spacing={2}>
        <Grid item sm={sm} md={md} lg={lg} xl={xl}>
          <Paper square elevation={0}>
            <Box display="flex" flexWrap="wrap" height={boxHeight} justifyContent="center" alignItems="center" style={{ boxShadow: shadow }}>
              <Box>
                <Typography color="textSecondary" align="center" style={{ margin: "0 1rem" }}>
                  {Localization.getInstance().getDisplayText("PreviewPlaceholderBox", "placeholderInfoText")}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default PreviewPlaceholderBox;


