import React, { Component, ReactNode } from "react";
import { Box } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocationOffIcon from "@material-ui/icons/LocationOff";
import { Nullable } from "types/aliases";
import { MapMarkerData } from "data/map/MapLink";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import SIconButton from "components/styled-components/SIconButton";
import Data from "data/data/Data";
import LatestDeviceDataRepository, { LatestDeviceDataRepositoryListener } from "data/data-storage/LatestDeviceDataRepository";
import { getLocation } from "data/utils/deviceUtils";
import DeviceRepository from "data/data-storage/DeviceRepository";

interface Props {
  buttonActiveColor:
  SSvgIconColorProps.blueGradient |
  SSvgIconColorProps.greenGradient |
  SSvgIconColorProps.orangeGradient |
  SSvgIconColorProps.yellowGradient;
  mapMarkerData: Nullable<MapMarkerData[]>;
  getMapMarkerDataItem: () => Nullable<MapMarkerData>;
  toggleMarker: (marker: Nullable<MapMarkerData>) => void;
  deviceId?: string;
}

interface State {
  locationFoundWithDeviceId: boolean;
}

class DataRowLocationIndicator extends Component<Props, State> implements LatestDeviceDataRepositoryListener {

  public constructor (props: Props) {
    super(props);
    this.state = {
      locationFoundWithDeviceId: false,
    };
  }

  public componentDidMount(): void {
    const { deviceId } = this.props;

    if (deviceId) {
      const device = DeviceRepository.getInstance().getDevice(deviceId);

      if (device && getLocation(device.getLatestDataSync())) {
        this.setState({ locationFoundWithDeviceId: true });
      }
      LatestDeviceDataRepository.getInstance().addListener(this, deviceId);
    }
  }

  public componentDidUpdate(_prevProps: Props, prevState: State): void {
    const { deviceId, mapMarkerData, toggleMarker, getMapMarkerDataItem } = this.props;
    const mapMarkerDataItem = getMapMarkerDataItem();

    if (deviceId) {
      if (prevState.locationFoundWithDeviceId && !this.state.locationFoundWithDeviceId) {
        // update map marker data in MapLink and in list view state if marker was on map but location was not found when data updated
        if (mapMarkerDataItem && mapMarkerData && mapMarkerData.some(mData => mData.id === mapMarkerDataItem.id)) {
          toggleMarker(mapMarkerDataItem);
        }
      }
    }
  }

  public componentWillUnmount(): void {
    if (this.props.deviceId) {
      LatestDeviceDataRepository.getInstance().removeListener(this, this.props.deviceId);
    }
  }

  public onDataUpdated(data: Data): void {
    let locationFound;

    if (data === null || data["latitude"] === undefined || data["longitude"] === undefined) {
      locationFound = false;
    } else {
      if (typeof(data["latitude"]) === "number" && typeof (data["longitude"]) === "number") {
        locationFound = true;
      } else {
        console.error(`DataRowLocationIndicator.onDataUpdated() / Unexpected type for "latitude" and/or "longitude" in ${data}`);
        locationFound = false;
      }
    }
    this.setState({ locationFoundWithDeviceId: locationFound });
  }

  public render(): ReactNode {
    const { deviceId, toggleMarker, mapMarkerData, getMapMarkerDataItem, buttonActiveColor } = this.props;
    const mapMarkerDataItem = getMapMarkerDataItem();

    if (deviceId && !this.state.locationFoundWithDeviceId) {
      return (
        <Box p={3}>
          <SSvgIcon
            color={SSvgIconColorProps.primary}
            iconComponent={LocationOffIcon}
          />
        </Box>
      );
    } else {
      return (
        <Box>
          <SIconButton onClick={(): void => toggleMarker(mapMarkerDataItem)} >
            <SSvgIcon
              color={this.isActive(mapMarkerDataItem, mapMarkerData) ? buttonActiveColor : SSvgIconColorProps.primary}
              iconComponent={LocationOnIcon}
            />
          </SIconButton>
        </Box>
      );
    }
  }

  private isActive(mapMarkerDataItem: Nullable<MapMarkerData>, mapMarkerData: Nullable<MapMarkerData[]>): boolean {
    if (mapMarkerDataItem && mapMarkerData) {
      return mapMarkerData.some(mData => mData.id === mapMarkerDataItem.id);
    } else {
      return false;
    }
  }
}


export default DataRowLocationIndicator;
