import { Theme } from "@material-ui/core";

function getClusterIconWithLinearGradient({ palette, typography }: Theme, iconGradient: { colorStop1: string; colorStop2: string }, textContent: number, isLargeMap: boolean): string {

  return (
    `<svg id="testSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="29px" height="29px" viewBox="0 0 29 29" version="1.1">
      <defs>
        <linearGradient id="linear0" gradientUnits="userSpaceOnUse" x1="37" y1="0" x2="37" y2="72" >
          <stop offset="0" style="stop-color:${iconGradient.colorStop1};stop-opacity:1;"/>
          <stop offset="1" style="stop-color:${iconGradient.colorStop2};stop-opacity:1;"/>
        </linearGradient>
      </defs>
      <g id="surface1">
        <path style="fill-rule:nonzero;fill:${palette.background.default};fill-opacity:1;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke:url(#linear0);stroke-miterlimit:4;" d="M 71.996498 36 C 71.996498 54.727371 56.377155 70.002155 37 70.002155 C 17.622845 70.002155 2.003502 54.727371 2.003502 36 C 2.003502 17.272629 17.622845 1.997845 37 1.997845 C 56.377155 1.997845 71.996498 17.272629 71.996498 36 Z M 71.996498 36 " transform="matrix(0.391892,0,0,0.402778,0,0)"/>
      </g>
      <text 
      style="font-family: inherit; 
      font-size: ${isLargeMap ? typography.body1.fontSize : typography.body2.fontSize}; 
      fill: red" 
      text-anchor="middle" x="50%" y="70%">
        ${textContent}
      </text>
    </svg>`    
  );

}
 
function getClusterDomIconWithLinearGradient({ palette, typography }: Theme, iconGradient: { colorStop1: string; colorStop2: string }, textContent: number, isLargeMap: boolean): string {

  return (
    `<svg 
      xmlns="http://www.w3.org/2000/svg" 
      xmlns:xlink="http://www.w3.org/1999/xlink" 
      width="29px" 
      height="29px" 
      style="transform:translate(-14px, -27px); cursor: pointer"; 
      viewBox="0 0 29 29" 
      version="1.1">
      <defs>
        <linearGradient id="linear0" gradientUnits="userSpaceOnUse" x1="37" y1="0" x2="37" y2="72" >
          <stop offset="0" style="stop-color:${iconGradient.colorStop1};stop-opacity:1;"/>
          <stop offset="1" style="stop-color:${iconGradient.colorStop2};stop-opacity:1;"/>
        </linearGradient>
      </defs>
      <g id="surface1">
        <path style="fill:${palette.background.default};
        stroke-width:4;
        stroke-linecap:butt;
        stroke-linejoin:miter;
        stroke:url(#linear0);" 
        d="M 71.996498 36 C 71.996498 54.727371 56.377155 70.002155 37 70.002155 C 17.622845 70.002155 2.003502 54.727371 2.003502 36 C 2.003502 17.272629 17.622845 1.997845 37 1.997845 C 56.377155 1.997845 71.996498 17.272629 71.996498 36 Z M 71.996498 36 " transform="matrix(0.391892,0,0,0.402778,0,0)"/>
      </g>
      <text 
        style="font-size: ${isLargeMap ? typography.body1.fontSize : typography.body2.fontSize}; 
        fill: ${palette.text.primary};" 
        text-anchor="middle" 
        x="50%" 
        y="70%">
        ${textContent}
      </text>
    </svg>`    
  );
} 


export { getClusterIconWithLinearGradient, getClusterDomIconWithLinearGradient };
