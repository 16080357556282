import React, { Component, Fragment } from "react";
import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import { RuuviGWNodListValue } from "client/devices/RuuviGWHW/RuuviGWHWStateProperties";
import { ruuviGWNodDictionary } from "constants/constants";

interface Props {
  nodGnss: boolean;
  nodNcell: boolean;
  nodMcellLoc: boolean;
  handleChange: (key: RuuviGWNodListValue, value: boolean) => void;
}

interface State {}

class RuuviGWNodSelector extends Component<Props, State> {
  public render(): JSX.Element {
    const { nodGnss, nodMcellLoc, nodNcell, handleChange } = this.props;
    const { gnss, ncell, mcell_loc } = ruuviGWNodDictionary;
    const labelStyle: React.CSSProperties = { margin: 0, justifyContent: "flex-end" };
    return (
      <Fragment>
        <FormControl component="fieldset">
          <FormLabel component="legend"></FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={nodGnss} onChange={({ target }): void => handleChange(gnss.name, target.checked)} name={gnss.name} />}
              label={gnss.displayName()}
              style={labelStyle}
            />
            <FormControlLabel
              control={<Checkbox checked={nodNcell} onChange={({ target }): void => handleChange(ncell.name, target.checked)} name={ncell.name} />}
              label={ncell.displayName()}
              style={labelStyle}
            />
            <FormControlLabel
              control={<Checkbox checked={nodMcellLoc} onChange={({ target }): void => handleChange(mcell_loc.name, target.checked)} name={mcell_loc.name} />}
              label={mcell_loc.displayName()}
              style={labelStyle}
            />
          </FormGroup>
        </FormControl>
      </Fragment>
    );
  }
}

export default RuuviGWNodSelector;
