/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import HyperIcon from "../../../assets/device/icons/router-24px.svg";
import { ReferenceHW } from "../ReferenceHW/ReferenceHW";
import { HyperHWState } from "./HyperHWState";
import { HyperHWStateProperties } from "./HyperHWStateProperties";
import AWSBackend from "../../../data/backend/AWSBackend";
import { DeviceParameters } from "../../../data/device/Device";

export class HyperHW extends ReferenceHW<HyperHWState> {
  public static type = "Hyper";

  public constructor(backend: AWSBackend, params: DeviceParameters) {
    super(HyperHW.type, backend, params);
  }

  public getIcon(): string {
    return HyperIcon;
  }

  public createState(timestamp?: number, reported?: Partial<HyperHWStateProperties>, desired?: Partial<HyperHWStateProperties>):
  HyperHWState {
    return new HyperHWState(this.getId(), new HyperHWStateProperties(reported ?? {}), new HyperHWStateProperties(desired ?? {}), timestamp);
  }

}
