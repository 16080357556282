import React, { Component, Fragment } from "react";
import { IconButton, Typography } from "@material-ui/core";
import { Nullable } from "types/aliases";
import { MeasurementSetTreeItem } from "data/types/measurementSetTypes";

interface Props {
  isLabelIconDisabled?: (setId?: string) => boolean;
  onLabelClick?: (event?: React.ChangeEvent<unknown>, measurementSet?: MeasurementSetTreeItem) => void;
  icon?: JSX.Element;
  item: MeasurementSetTreeItem;
}

interface State {}

export default class TreeTextLabel extends Component<Props, State> {

  private getDisabledProp(setId?: string): boolean {
    if (this.props.isLabelIconDisabled) {
      return this.props.isLabelIconDisabled(setId);
    } else {
      return false;
    }
  }

  private renderIconButton(): Nullable<JSX.Element> {

    return (
      this.props.icon ?
        <IconButton
          disabled={this.getDisabledProp(this.props.item.setId)}
          onClick={(event?: React.ChangeEvent<unknown>): void => this.props.onLabelClick ? this.props.onLabelClick(event, this.props.item) : undefined}
        >
          {this.props.icon}
        </IconButton>
        : null
    );
  }

  public render():JSX.Element {

    return (
      <Fragment>
        {/* // type of component could also be configured with props if needed */}
        <Typography component="span" variant="body1">
          {this.props.item.displayName}
        </Typography>
        {this.renderIconButton()}
      </Fragment>
    );
  }
}
