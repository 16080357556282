import React, { Component, Fragment, ReactNode } from "react";
import TreeView from "@material-ui/lab/TreeView";
import { Theme, Typography, withTheme } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AddIcon from "@material-ui/icons/Add";
import { v4 as uuid } from "uuid";
import { Maybe, Nullable } from "types/aliases";
import { ReactComponent as MeasurementsetsIcon } from "assets/app-views/icons/MeasurementsetsIcon.svg";
import { MeasurementSetTreeItem, MeasurementSetType } from "data/types/measurementSetTypes";
import Localization from "data/localization-sensoan/Localization";
import MeasurementSetSelector from "data/measurement-set-selector/MeasurementSetSelector";
import STreeItem from "components/styled-components/STreeItem";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import SIconButton from "components/styled-components/SIconButton";
import SButton from "components/styled-components/SButton";

interface Props {
  onAddButtonClick: (event: React.MouseEvent<HTMLButtonElement>, parentIds: string[]) => void;
  onDeleteButtonClick: (event: React.MouseEvent<HTMLButtonElement>, group: MeasurementSetTreeItem) => void;
  onTreeItemLabelClick?: (event: React.MouseEvent<Element, MouseEvent>, treeItem: MeasurementSetTreeItem) => void;
  onClearButtonClick?: () => void;
  configSelectMode?: boolean;
  selectedGroupId?: Maybe<string>;
  treeItems: MeasurementSetTreeItem[];
  disableModifications?: boolean;
  theme: Theme;
}

interface State {
  expandedItems: string[];
}

const ROOT_GROUP_ID = process.env.REACT_APP_MEASSET_ROOT_LEVEL_GROUP_ID as string;

class MeasSetGroupTree2 extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      expandedItems: [ROOT_GROUP_ID],
    };
  }

  private renderTree(treeItem: MeasurementSetTreeItem, indexOf: number, length: number): Nullable<JSX.Element> {
    const isConfig = treeItem.type === MeasurementSetType.CONFIG;
    return (
      <Fragment key={treeItem.setId}>
        <STreeItem
          expanded={!isConfig && this.state.expandedItems.includes(treeItem.setId)}
          icon={this.getTreeItemIcon(treeItem)} // use icon prop always instead of expandIcon or collapseIcon prop because TreeView component automatically removes expand and collapse icon from a TreeItem without children
          label={this.renderLabelContent(treeItem)}
          nodeId={treeItem.setId}
          onIconClick={!isConfig ? (event: React.MouseEvent<Element, MouseEvent>): void => this.onIconToggle(event, treeItem) : undefined}
          onLabelClick={(event: React.MouseEvent<Element, MouseEvent>): void => this.getOnLabelClickHandler(event, treeItem)}
          hideExtraBorder={this.props.disableModifications && indexOf === length - 1 ? true : undefined}
        >
          {this.renderTreeChilds(treeItem.childs)}
        </STreeItem>
        {!this.props.disableModifications ? this.renderAddButton(treeItem.setId) : null}
      </Fragment>
    );
  }

  private renderClearButton(): ReactNode {
    if ((MeasurementSetSelector.getInstance().getSelectedMeasurementSet !== null && this.props.configSelectMode) ||
    (this.props.selectedGroupId && !this.props.configSelectMode)) {
      return (
        <SButton
          color="secondary"
          labelText={Localization.getInstance().getDisplayText("MeasSetSelect", "clear")}
          onClick={(): void => this.props.onClearButtonClick?.()}
          widthInRems={7}
          mb={2}
        />
      );
    } else {
      return null;
    }
  }

  private renderLabelContent(treeItem: MeasurementSetTreeItem): ReactNode {
    if (!this.props.disableModifications && treeItem.type === MeasurementSetType.GROUP && treeItem.childs && treeItem.childs.length === 0) {
      return (
        <Fragment>
          {this.renderItemNameElement(treeItem)}
          <SIconButton
            shadow
            disabled={treeItem.setId === this.props.selectedGroupId}
            onClick={(event): void => this.props.onDeleteButtonClick(event, treeItem)}
          >
            {this.getAddAndDeleteIcon("delete")}
          </SIconButton>
        </Fragment>
      );
    } else if (treeItem.type === MeasurementSetType.GROUP){
      return (
        this.renderItemNameElement(treeItem)
      );
    } else {
      return (
        this.renderItemNameElement(treeItem)
      );
    }
  }

  private renderItemNameElement(treeItem: MeasurementSetTreeItem): ReactNode {
    let color: "textSecondary" | "textPrimary";

    if (this.props.configSelectMode) {
      color = treeItem.type === MeasurementSetType.GROUP ? "textSecondary" : "textPrimary";
    } else {
      color = treeItem.type === MeasurementSetType.GROUP ? "textPrimary" : "textSecondary";
    }
    return (
      <Typography variant="body1" color={color}>
        {treeItem.displayName}
      </Typography>
    );
  }

  private renderAddButton(parentItemId: string): ReactNode {
    return (
      this.state.expandedItems.includes(parentItemId)
        &&
        <STreeItem
          inlineStyle={{ bottom: parentItemId === ROOT_GROUP_ID ? "1rem" : "0.7rem" }} // push add button up to vertical line
          hideIconContainer
          nodeId={uuid()} // unique string passed as key prop to treeItem
          label={
            <SIconButton
              shadow
              onClick={(event): void => this.props.onAddButtonClick(event, [parentItemId])}
            >
              {this.getAddAndDeleteIcon("add")}
            </SIconButton>}
        />
    );
  }

  private renderTreeChilds(childs: Maybe<MeasurementSetTreeItem[]>): ReactNode {
    return (
      (childs && childs.length > 0)
        ?
        childs.map((item, index, { length }) => this.renderTree(item, index, length))
        :
        !this.props.disableModifications
          ?
          <STreeItem /* empty item to enable collapse/expand functionality for group without childs when modifications are allowed */
            hideIconContainer
            nodeId="empty"
          />
          :
          null
    );
  }

  public render(): JSX.Element {
    return (
      <TreeView
        expanded={this.state.expandedItems}
        style={{ minWidth: "15rem", padding: "1.5rem", maxHeight: "66vh" }}
        selected={this.props.selectedGroupId}
      >
        {this.renderClearButton()}
        <STreeItem
          label={
            <Typography color="textSecondary">
              {Localization.getInstance().getDisplayText("MeasSetGroupTree", "groups")}
            </Typography>}
          nodeId={ROOT_GROUP_ID}
          onLabelClick={(): void => this.props.onClearButtonClick?.()}
          hideIconContainer
        >
          {this.props.treeItems.map((item, index, { length }) => this.renderTree(item, index, length))}
        </STreeItem>
        {!this.props.disableModifications
          ?
          this.renderAddButton(ROOT_GROUP_ID)
          :
          null
        }
      </TreeView>
    );
  }

  //TODO: collapse groups recursively
  private onIconToggle(event: React.MouseEvent<Element, MouseEvent>, treeItem: MeasurementSetTreeItem): void {
    event.preventDefault();
    event.stopPropagation();
    this.state.expandedItems.includes(treeItem.setId)
      ?
      this.setState({ expandedItems: this.state.expandedItems.filter(item => item !== treeItem.setId) })
      :
      this.setState({ expandedItems: this.state.expandedItems.concat(treeItem.setId) });
  }

  private getAddAndDeleteIcon(type: "add" | "delete"): ReactNode {
    return (
      <SSvgIcon
        color={type === "add" ? SSvgIconColorProps.blueGradient : SSvgIconColorProps.orangeGradient}
        iconComponent={AddIcon}
        size="1.375rem"
        inlineStyle={{ transform: type === "delete" ? "rotate(45deg)" : undefined, marginRight: "0rem" }}
      />
    );
  }

  private getTogglerIcon(type: "expand" | "collapse"): ReactNode {
    return (
      <SSvgIcon
        shadow
        color={SSvgIconColorProps.primary}
        iconComponent={type === "expand" ? ChevronRightIcon : ExpandMoreIcon}
        size="1.375rem"
      />
    );
  }

  private getMeasSetIcon({ setId }: MeasurementSetTreeItem): ReactNode {
    const isActive = MeasurementSetSelector.getInstance().getSelectedMeasurementSet()?.setId === setId;
    return (
      <SSvgIcon
        color={(this.props.configSelectMode && isActive) ? SSvgIconColorProps.blueGradient : SSvgIconColorProps.primary}
        iconComponent={MeasurementsetsIcon}
        viewBox="0 0 30 30"
        size="1.375rem"
      />
    );
  }

  private getOnLabelClickHandler(event: React.MouseEvent<Element, MouseEvent>, treeItem: MeasurementSetTreeItem): void {
    if ((treeItem.type === MeasurementSetType.CONFIG && this.props.configSelectMode) ||
      (treeItem.type === MeasurementSetType.GROUP && !this.props.configSelectMode)) {
      return this.props.onTreeItemLabelClick?.(event, treeItem);
    } else {
      return undefined;
    }
  }

  private getTreeItemIcon(treeItem: MeasurementSetTreeItem): ReactNode {
    if (treeItem.type === MeasurementSetType.CONFIG){
      return this.getMeasSetIcon(treeItem);
    } else {
      return this.getTogglerIcon(this.state.expandedItems.includes(treeItem.setId) ? "collapse" : "expand");
    }
  }
}

export default withTheme(MeasSetGroupTree2);
