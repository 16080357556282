import { ReferenceHWStateProperties } from "../ReferenceHW/ReferenceHWStateProperties";
import { Nullable } from "../../../types/aliases";

export class MLDemoHWStateProperties extends ReferenceHWStateProperties {
  public updateInterval: Nullable<number>;
  public measurementInterval: Nullable<number>;
  public noModemSleep: Nullable<boolean>;

  public constructor(properties: Partial<MLDemoHWStateProperties>) {
    super(properties);
    this.updateInterval = properties.updateInterval ?? null;
    this.measurementInterval = properties.measurementInterval ?? null;
    this.noModemSleep = properties.noModemSleep ?? null;
  }
}
