import { Nullable } from "types/aliases";
import LatestDeviceDataRepository from "data/data-storage/LatestDeviceDataRepository";
import { MeasurementSetConfig } from "data/types/measurementSetTypes";
import { MapLocation } from "data/map/MapLink";

export function getLocation(set: MeasurementSetConfig): Nullable<MapLocation> {
  if (set.config.gpsLocation !== undefined) {
    return { lat: set.config.gpsLocation.latitude, lng: set.config.gpsLocation.longitude };
  } else if (set.config.gpsFunction !== undefined) {
    const data = LatestDeviceDataRepository.getInstance().getData(set.config.gpsFunction.deviceId);

    if (data === null || data["latitude"] === undefined || data["longitude"] === undefined) {
      return null;
    } else {
      if (typeof(data["latitude"]) === "number" && typeof (data["longitude"]) === "number") {
        return { lat: data["latitude"], lng: data["longitude"] };
      } else {
        console.error(`Unexpected type for "latitude" and/or "longitude" in ${data}`);
        return null;
      }
    }
  } else {
    return null;
  }
}
