import React, { Component } from "react";
import { Box, Popover, Theme, withTheme } from "@material-ui/core";
import * as Utils from "data/utils/Utils";
import { Nullable } from "types/aliases";
import MeasurementSetDataContainer from "data/measurement-data/MeasurementSetDataContainer";
import { ChartSize, ChartType, MeasurementSetConfig, MeasurementSetType, SensorColorType, TimeScaleType } from "data/types/measurementSetTypes";
import LineChart from "components/charts/LineChart";
import MeasurementDataTools from "data/measurement-data/MeasurementDataTools";
import LoaderSensoan from "components/layout/LoaderSensoan";

interface Props {
  title: string;
  deviceId: string;
  sensorName: string;
  anchorEl: Nullable<HTMLButtonElement>;
  theme: Theme;
  close: () => void;
}

interface State {
  measurementSetData: Nullable<MeasurementSetDataContainer>;
  measurementSet: MeasurementSetConfig;
  dataReady: boolean;
}

class SensorDataPopup extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    const measurementSet: MeasurementSetConfig = {
      displayName: this.props.deviceId,
      setId: "temp",
      type: MeasurementSetType.CONFIG,
      parentIds: [],
      config: {
        chartConfig: [{
          displayName: this.props.title,
          chartType: ChartType.LINECHART,
          timeScaleType: TimeScaleType.HISTORY,
          dataConfig: [{
            deviceId: this.props.deviceId,
            sensorDisplayName: this.props.sensorName,
            sensorName: this.props.sensorName,
            sensorColor: SensorColorType.BLUE,
          }],
        }],
      },
    };
    this.state = {
      measurementSetData: new MeasurementSetDataContainer(measurementSet, Date.now(), Utils.convertHoursToTimestamp(24), (): void => this.onMeasurementSetDataReady()),
      measurementSet,
      dataReady: false,
    };
  }

  public onMeasurementSetDataReady(): void {
    this.setState({ dataReady: true });
  }

  public render(): JSX.Element {
    return (
      <Popover
        open={true}
        anchorEl={this.props.anchorEl}
        onClose={(): void => this.props.close()}
      >
        <Box position="relative" bgcolor={this.props.theme.palette.background.default} p={2} width="600px" maxWidth="90vw" height="fit-content" maxHeight="90vh">
          {this.state.dataReady
            ?
            <LineChart
              chartIndex={0}
              // TODO: ensure colors are shown correctly with getLineChartDataTs
              colors={MeasurementDataTools.getSortedSensorColorList(this.state.measurementSet.config.chartConfig[0].dataConfig)}
              data={this.state.measurementSetData?.getChartDataTs(this.state.measurementSet.config.chartConfig[0], 0) ?? []}
              dataConfig={this.state.measurementSet.config.chartConfig[0].dataConfig}
              title={this.state.measurementSet.config.chartConfig[0].displayName}
              size={ChartSize.FULL_WIDTH}
            />
            : <LoaderSensoan />
          }
        </Box>
      </Popover>
    );
  }
}

export default withTheme(SensorDataPopup);
