import { RuuviGWNodListValue } from "client/devices/RuuviGWHW/RuuviGWHWStateProperties";
import { HereMapsPlace } from "data/map/MapGeocoding";
import { Maybe } from "types/aliases";

export interface BreadcrumbData {
  name: string | (() => string);
  link: () => void;
}

export interface MeasJobsAction {
  type: MeasJobsButtonActions;
  action: () => void;
}

export enum MeasJobsButtonActions {
  addNew = "addNew",
  delete = "delete",
  save = "save",
  saveAndStart = "saveAndStart",
  clear = "clear",
}

export enum MeasSetsButtonActions {
  deleteSet = "deleteSet",
  saveChartConfig = "saveChartConfig",
  saveSet = "saveSet",
}

export enum MapButtonActions {
  closeInfoBubble = "closeInfoBubble",
  openInfoBubble = "openInfoBubble",
}

export type MeasSetEditTarget = "measurementSet" | "chartConfig";

export enum MeasSetLocationOption {
  gpsFunction = "gpsFunction",
  gpsLocation = "gpsLocation",
}

export type MeasSetLocationTextTarget = "locationOptionSelector" | "locationInfoText";

export type MinMaxInputType = "min" | "max";

export enum ChartConfigValidityStatus {
  invalidWithMissingValues = "invalidWithMissingValue",
  invalidWithSensorLimitReached = "invalidWithSensorLimitReached",
  invalidWithScaleInputError = "invalidWithScaleInputError",
  valid = "valid",
}

export enum ScaleInputErrorStatus {
  invalidValue = "invalidValue",
  otherValueIsMissing = "otherValueIsMissing",
}

export type TextAlignProps = Maybe<"left" | "right" | "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "center" | "end" | "start" | "justify" | "match-parent">;

export type CustomSpacingProps = Maybe<string | number>;

export type AriaHaspopupProps = Maybe<boolean | "false" | "true" | "menu" | "listbox" | "tree" | "grid" | "dialog">;

export enum AppLayoutMode {
  dataWithDrawer = "dataWithDrawer",
  mapWithDrawer = "mapWithDrawer",
}

export type LocationInfo = HereMapsPlace | (() => string);

export type MapLocationEventResult = HereMapsPlace | "noPlaceFound";

export type LocationGroupingKey = HereMapsPlace | "Tuntematon sijainti";

export enum BackendActionStatus {
  ERROR,
  SUCCESS,
}

export enum GroupAction {
  ADD,
  DELETE
}

export enum PiikkioHWActivityTypeLabel {
  HAALAUS = "Haalaus",
  NOSTO = "Nosto",
  PAIKALLA = "Paikalla",
  REKKA = "Rekka",
  SIIRTO = "Siirto",
}

export enum MLDemoHWActivityTypeLabel {
  ON_A_TABLE = "On a table",
  IDLE = "Idle",
  WALKING = "Walking",
  RUNNING = "Running",
  STOP = "Stop",
}

export type Concrete<Type> = {
  [Property in keyof Type]-?: Type[Property];
};

type LocalisationReadyDictionaryEntry<T> = {
  name: T;
  displayName: () => string;
};

export type RuuviGWNodDictionary = Record<RuuviGWNodListValue, LocalisationReadyDictionaryEntry<RuuviGWNodListValue>>;
