/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment, ReactNode } from "react";
import { Redirect } from "react-router-dom";
import AuthListener, { AuthEvent } from "data/auth/AuthListener";
import AuthWrapper from "data/auth/AuthWrapper";
import PathsSensoan from "data/paths/PathsSensoan";

interface Props {
  redirectPath: PathsSensoan;
  redirectIfAuthenticated?: boolean;
}

interface State {
  isAuthenticated?: boolean;
}

export default class Gatekeeper extends Component<Props, State> {

  private authListener?: AuthListener;

  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public async componentDidMount(): Promise<void> {
    this.authListener = new AuthListener(this.listener);

    const isAuthenticated = await AuthWrapper.isCurrentUserAuthenticated(true);
    // await this.changeLanguage();

    this.setState({
      isAuthenticated,
    });
  }

  public async componentWillUnmount(): Promise<void> {
    this.authListener?.removeListener();
    this.authListener = undefined;
  }

  private listener = (event: AuthEvent): void => {
    if (event === "SignedOut") {
      this.setState({ isAuthenticated: false });
    }

    if (event === "SignedIn") {
      this.setState({ isAuthenticated: true });
    }
  };

  private renderContentOrRedirect(): ReactNode {
    const redirectIfAuthenticated = this.props.redirectIfAuthenticated ?? false;

    if (this.state.isAuthenticated == null) return;

    if (this.state.isAuthenticated !== redirectIfAuthenticated) {
      return this.props.children;
    } else {
      return <Redirect to={this.props.redirectPath} />;
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.renderContentOrRedirect()}
      </Fragment>
    );
  }
}
