import React, { Component, Fragment, ReactNode } from "react";
import { Box, Divider, Theme, Typography, withTheme } from "@material-ui/core";
import Device from "data/device/Device";
import Localization from "data/localization-sensoan/Localization";
import { getDisplayName } from "data/utils/Utils";
import { DIVIDER_HEIGHT } from "data/theme/constants";
import Data from "data/data/Data";
import { Maybe, Nullable } from "types/aliases";
import SensorListDataRow, { SensorListItem } from "./SensorListDataRow";
import { DEVICE_INFO_SECTION_ROW_HEIGHT } from "./DeviceInfoSection";
import { SECTION_CONTENT_BOTTOM_PADDING, SECTION_CONTENT_TOP_PADDING,
  SECTION_TITLE_BOTTOM_PADDING, SECTION_TITLE_TOP_PADDING } from "components/layout/Section";

interface Props {
  device: Device;
  deviceLatestData: Nullable<Data>;
  expandedListHeight: boolean;
  theme: Theme;
}

interface State {
  devicePopupOpen: boolean;
}

/* This is essentially a copy of <Section /> but has custom functionality and layout which is needed to
make DeviceWindow function properly */
class SensorListSection extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor (props: Props) {
    super(props);
    this.state = {
      devicePopupOpen: false,
    };
  }

  /* TODO: handle this in parent component? */
  private getSensorList(): SensorListItem[] {
    const { deviceLatestData, device } = this.props;
    const deviceId = device.getId();
    const deviceDisplayName = getDisplayName(device);
    const sensorList: SensorListItem[] = [];
    const unwantedKeys = ["deviceId", "mac", "type", "timestamp", "unprocessed", "organization", "sessionId"];

    if (deviceLatestData !== null) {
      for (const sensor in deviceLatestData) {
        if (!unwantedKeys.includes(sensor)) {
          let reading = deviceLatestData[sensor] ?? "-";

          // TODO: conversion loses precision
          if (typeof reading === "number" && parseInt(reading.toString(), 10) !== reading) {
            reading = parseFloat((reading as number).toFixed(1));
          }
          const item: SensorListItem = {
            sensor,
            reading,
            deviceId,
            deviceDisplayName,
          };
          sensorList.push(item);
        }
      }
    }
    return sensorList;
  }

  private renderTitle(): JSX.Element {
    return (
      <Box
        pt={SECTION_TITLE_TOP_PADDING}
        pb={0}
        display="flex"
        flexWrap="wrap"
      >
        <Typography variant="h6" color="textPrimary">
          {this.text("Common", "measurements")}
        </Typography>
        <Divider/>
      </Box>
    );
  }

  private renderSensorRows(): JSX.Element {
    return (
      <Box height={`calc(100% - (${this.getSensorRowsContainerHeight()}))`} style={{ overflowY: "auto" }}>
        {this.getSensorList().map((sensor, index, list) => <SensorListDataRow key={`${sensor.deviceId}-${sensor.sensor}`} dataItem={sensor} hideIotDataRowDivider={index === list.length - 1}/>)}
      </Box>
    );
  }

  private renderContent(): ReactNode {
    return (
      <Box px={0} height="100%">
        {/* TODO: header row */}
        {this.renderSensorRows()}
      </Box>
    );
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.renderTitle()}
        {this.renderContent()}
      </Fragment>
    );
  }

  // - - - - - - - - - - - - - - - - - - - - - - -
  // Helper methods for building the layout
  private getSensorRowsContainerHeight(): string {
    if (this.props.expandedListHeight) {
      const generalInfoRowCount = 4;
      return `(${this.getSectionTitleDefaultHeight()} + ${this.getSensorSectionTitleHeight()}) + (${generalInfoRowCount} * ${DEVICE_INFO_SECTION_ROW_HEIGHT}) + (${SECTION_CONTENT_BOTTOM_PADDING} + ${SECTION_CONTENT_TOP_PADDING})`;
    } else {
      return `(${this.getSectionTitleDefaultHeight()} + ${this.getSensorSectionTitleHeight()})`;
    }
  }

  private getSectionTitleDefaultHeight(): Maybe<string> {
    const { theme } = this.props;
    const sectionTitleDefaultFontSize = theme.typography.h6.fontSize;

    if (typeof sectionTitleDefaultFontSize === "string") {
      const sectionTitleDefaultLineHeight = theme.typography.h6.lineHeight;

      if (typeof sectionTitleDefaultLineHeight === "number") {
        return `((${sectionTitleDefaultFontSize} * ${sectionTitleDefaultLineHeight}) + ${SECTION_TITLE_BOTTOM_PADDING} + ${SECTION_TITLE_TOP_PADDING} + ${DIVIDER_HEIGHT})`;
      } else {
        console.error("Height of Section title could not be calculated: font size of h6 element in theme is not in expected format.");
      }
    } else {
      console.error("Height of Section title could not be calculated: line height of h6 element in theme is not in expected format.");
    }
  }

  private getSensorSectionTitleHeight(): Maybe<string> {
    const defaultHeight = this.getSectionTitleDefaultHeight();

    if (defaultHeight !== undefined) {
      return `(${defaultHeight} - ${SECTION_TITLE_BOTTOM_PADDING})`;
    }
  }
}

export default withTheme(SensorListSection);
