import { Voidable } from "types/aliases";
import { isDefined } from "utils/types";

export function isDefinedAndSmaller(a: Voidable<number>, b: Voidable<number>): boolean {
  if (!isDefined(a) && !isDefined(b)) {
    return false;
  } else if (isDefined(a) && !isDefined(b)) {
    return false;
  } else if (!isDefined(a) && isDefined(b)) {
    return false;
  } else {
    return (a as number) < (b as number);
  }
}

export function isDefinedAndBigger(a: Voidable<number>, b: Voidable<number>): boolean {
  if (!isDefined(a) && !isDefined(b)) {
    return false;
  } else if (isDefined(a) && !isDefined(b)) {
    return false;
  } else if (!isDefined(a) && isDefined(b)) {
    return false;
  } else {
    return (a as number) > (b as number);
  }
}

export function isDefinedAndSmallerOrEqual(a: Voidable<number>, b: Voidable<number>): boolean {
  if (!isDefined(a) && !isDefined(b)) {
    return false;
  } else if (isDefined(a) && !isDefined(b)) {
    return false;
  } else if (!isDefined(a) && isDefined(b)) {
    return false;
  } else {
    return (a as number) <= (b as number);
  }
}

export function isDefinedAndBiggerOrEqual(a: Voidable<number>, b: Voidable<number>): boolean {
  if (!isDefined(a) && !isDefined(b)) {
    return false;
  } else if (isDefined(a) && !isDefined(b)) {
    return false;
  } else if (!isDefined(a) && isDefined(b)) {
    return false;
  } else {
    return (a as number) >= (b as number);
  }
}

export function sortList<T extends Record<keyof T, unknown>, P extends Partial<T>>(order: "default" | "reverse", sortKey: keyof T, list: P[]): P[] {
  if (list.length === 0) {
    return list;
  } else {
    const sortedList = list.sort((a, b) => {
      const val1 = typeof a[sortKey] === "string" ? (a[sortKey] as string).toUpperCase() : a[sortKey];
      const val2 = typeof b[sortKey] === "string" ? (b[sortKey] as string).toUpperCase() : b[sortKey];

      if ((val1 === null || val1 === undefined) && (val2 === null || val2 === undefined)) {
        return 0;
      } else if ((val1 === null || val1 === undefined) && (val2 !== null && val2 !== undefined)) {
        return order === "default" ? -1 : 1;
      } else if ((val1 !== null && val1 !== undefined) && (val2 === null || val2 === undefined)) {
        return order === "default" ? 1 : -1;
      }
      
      if ((val1 !== null && val1 !== undefined) && (val2 !== null && val2 !== undefined)) {
        if (val1 < val2) {
          return order === "default" ? -1 : 1;
        } else if (val1 > val2) {
          return order === "default" ? 1 : -1;
        } else {
          return 0;
        }
      }

      console.error("Fix sorting!!");
      return 0;

    });
    return sortedList;
  }
}
