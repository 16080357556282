import { createTheme, Theme } from "@material-ui/core";
import BaseObservable from "../observer/BaseObservable";
import Gradients, { GradientCollection } from "./PaletteColors/Gradients";
import IconColors, { IconColorCollection } from "./PaletteColors/IconColors";
import { Themes } from "./themes";
import * as OverrideColors from "./OverrideColors/OverrideColors";
import Input from "./OverrideStyles/Input";
import { SCROLLBAR_WIDTH, SCROLLBAR_HEIGHT, DIVIDER_HEIGHT } from "./constants";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    gradients: GradientCollection;
    iconColors: IconColorCollection;
    iconGradients: GradientCollection;
  }
  interface PaletteOptions {
    gradients: GradientCollection;
    iconColors: IconColorCollection;
    iconGradients: GradientCollection;
  }
}

export interface ThemeSelectorObserver {
  onSelectedThemeChanged?(theme: Theme): void;
}

export default class ThemeSelector extends BaseObservable<ThemeSelectorObserver> {
  private static instance: ThemeSelector = new ThemeSelector();
  private themeName: Themes = Themes.dark;

  public static getInstance(): ThemeSelector {
    return this.instance;
  }

  public addObserver(observer: ThemeSelectorObserver): void {
    super.addObserver(observer);
  }

  public getSelectedTheme(): Theme {
    const muiDefaultTheme = createTheme(
      {
        palette: {
          type: this.themeName.startsWith("dark") ? "dark" : "light",
          gradients: Gradients.getGradientsForThemeSelector(this.themeName),
          iconColors: IconColors.getIconColorsForThemeSelector(this.themeName),
          iconGradients: Gradients.getIconGradientsForThemeSelector(),
        },
      });
    const theme = createTheme(
      {
        palette: {
          action: {
            disabledBackground: OverrideColors.actionDisabledBg(this.themeName),
          },
          primary: {
            main: OverrideColors.primaryMain(this.themeName),
          },
          type: muiDefaultTheme.palette.type,
          gradients: Gradients.getGradientsForThemeSelector(this.themeName),
          iconColors: IconColors.getIconColorsForThemeSelector(this.themeName),
          iconGradients: Gradients.getIconGradientsForThemeSelector(),
          text: {
            primary: OverrideColors.textPrimary(this.themeName),
            secondary: OverrideColors.textSecondary(this.themeName),
          },
          divider: OverrideColors.divider(this.themeName),
          background: {
            paper: OverrideColors.bgPaper(this.themeName),
            default: OverrideColors.bgDefault(this.themeName),
          },
        },
        typography: {
          h1: {
            fontSize: "6.75rem",
            fontWeight: 300,
          },
          h2: {
            fontSize: "4.21875rem",
            fontWeight: 300,
          },
          h3: {
            fontSize: "3.375rem",
            fontWeight: 300,
          },
          h4: {
            fontSize: "2.390625rem",
            fontWeight: 300,
          },
          h5: {
            fontSize: "1.6875rem",
            fontWeight: 300,
          },
          h6: {
            fontSize: "1.40625rem",
            fontWeight: 300,
          },
          body1: {
            fontSize: "1.125rem",
            fontWeight: 300,
          },
          body2: {
            fontSize: "1rem",
            fontWeight: 300,
          },
          button: {
            fontSize: "1rem", // buttons in spec have at least fontSizes 16px, 18px and 20px -> let's simplify things and use same value as body1
            fontWeight: 300, // same as above
          },
          fontFamily: [
            "saira",
            "sans-serif",
          ].join(","),
        },
        spacing: 4,
        mixins: {
          toolbar: { height: "3rem" },
        },
        overrides: {
          MuiBreadcrumbs: {
            root: {
              cursor: "pointer",
            },
            separator: {
              fontSize: muiDefaultTheme.typography.h5.fontSize,
            },
          },
          MuiCssBaseline: {
            "@global": {
              "*::-webkit-scrollbar": {
                width: SCROLLBAR_WIDTH,
                height: SCROLLBAR_HEIGHT,
                borderRight: "1px",
                borderColor: "rgba(0, 0, 0, 0.12)",
              },
              "*::-webkit-scrollbar-thumb": {
                backgroundColor: muiDefaultTheme.palette.text.disabled,
              },
              "*::-webkit-scrollbar-thumb:hover": {
                backgroundColor: OverrideColors.primaryMain(this.themeName),
              },
            },
          },
          MuiDivider: {
            root: {
              height: DIVIDER_HEIGHT,
              width: "100%",
            },
          },
          MuiInput: {
            input: Input.getInputStyle(this.themeName),
            inputMultiline: Input.getInputMultilineStyle(this.themeName),
            multiline: Input.getMultilineStyle(this.themeName),
            underline: {
              "&:before": {
                borderBottom: `1px solid ${OverrideColors.inputUnderline(this.themeName)}`,
              },
              "&:after": {
                borderBottom: `1px solid ${OverrideColors.primaryMain(this.themeName)}`,
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottom: `1px solid ${OverrideColors.primaryMain(this.themeName)}`,
              },
            },
          },
          MuiPopover: {
            paper: {
              backgroundColor: OverrideColors.bgDefault(this.themeName),
              boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px",
            },
          },
          MuiTooltip: {
            tooltip: {
              backgroundColor: OverrideColors.tooltip(this.themeName),
            },
            arrow: {
              color: OverrideColors.tooltip(this.themeName),
              "&::before": {
                backgroundColor: OverrideColors.tooltip(this.themeName),
              },
            },
          },
          MuiDrawer: {
            paper: {
              boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.1)",
            },
            paperAnchorDockedLeft: {
              borderRight: "0px",
            },
          },
          MuiPickersCalendar: {
            week: {
              borderBottom: `1px solid ${OverrideColors.divider(this.themeName)}`,
            },
          },
        },
      },
    );
    return theme;
  }

  public getSelectedThemeType(): Themes {
    return this.themeName;
  }

  public removeObserver(observer: ThemeSelectorObserver): void {
    super.removeObserver(observer);
  }

  public setTheme(themeName: Themes): void {
    this.themeName = themeName;
    this.notifyAction(observer => observer.onSelectedThemeChanged?.(this.getSelectedTheme()));
  }
}
