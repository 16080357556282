import React, { Component } from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import Localization from "data/localization-sensoan/Localization";

interface Props {}

interface State {}

class DeviceWindowPlaceHolder extends Component<Props, State> {
  public render(): JSX.Element {
    const boxShadow = "0px 4px 4px rgba(0, 0, 0, 0.35)";

    return (
      <Paper square elevation={0} style={{ height: "calc(50% - 210px)", minHeight: "15rem", boxShadow }}>
        <Box height="100%">
          <Box display="flex" alignItems="center" justifyContent="center" height="100%" width="100%">
            <Typography color="textSecondary">
              {Localization.getInstance().getDisplayText("DeviceWindowPlaceHolder", "noDeviceSelected")}
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  }
}

export default DeviceWindowPlaceHolder;
