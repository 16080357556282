import React, { Component, Fragment } from "react";
import { Button } from "@material-ui/core";
import AuthWrapper, { AuthenticatedUser } from "data/auth/AuthWrapper";
import Localization from "data/localization-sensoan/Localization";
import { isError } from "data/utils/ErrorUtils";
import { Maybe } from "types/aliases";
import Loader from "components/ui/loader";
import PasswordField from "components/ui/password-field";

interface Props {
  user: AuthenticatedUser;
}

interface State {
  isLoaderVisible: boolean;
  newPassword: string;
  confirmPassword: string;
  newPasswordError?: string;
  confirmPasswordError?: string;
  generalError?: string;
}

export default class CompleteAccountCreationForm extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      isLoaderVisible: false,
      newPassword: "",
      confirmPassword: "",
    };
  }

  private handleErrors(code?: string): void {
    switch (code) {
      case "UserNotLoggedIn":
        this.setState({ generalError: this.text("Login", "userNotLoggedIn") });
        break;
      case "Password does not conform to policy: Password must have numeric characters":
        this.setState({ newPasswordError: this.text("Common", "passwordMustHaveNumbers") });
        break;
      case "Password does not conform to policy: Password must have lowercase characters":
        this.setState({ newPasswordError: this.text("Common", "passwordMustHaveLowercaseCharacters") });
        break;
      case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "Password does not conform to policy: Password not long enough":
        this.setState({ newPasswordError: this.text("Common", "passwordMustBeLongEnough") });
        break;
      case "Invalid session for the user, session is expired.":
        this.setState({ generalError: this.text("Common", "userSessionExpired") });
        break;
      default:
        this.setState({ generalError: this.text("Common", "unableToPerformAction") });
        break;
    }
  }

  private resetErrors(): void {
    this.setState({
      generalError: undefined,
      newPasswordError: undefined,
      confirmPasswordError: undefined,
    });
  }

  private async completeAccountCreation(): Promise<void> {
    this.resetErrors();

    if (!this.passwordsMatch()) {
      this.setState({ confirmPasswordError: this.text("Common", "passwordsNotMatching") });
      return;
    }

    try {
      this.setState({ isLoaderVisible: true });
      await AuthWrapper.completeNewPassword(this.props.user, this.state.newPassword);
    } catch (error) {
      this.setState({ isLoaderVisible: false });
      console.error("completePassword", error);
      if (isError(error)) this.handleErrors(error.message);
    }
  }

  private validatePasswords = (): boolean => {
    return !!this.state.newPassword.length && !!this.state.confirmPassword.length;
  };

  private passwordsMatch(): boolean {
    return this.state.newPassword === this.state.confirmPassword;
  }

  private renderLoader(): Maybe<JSX.Element> {
    if (this.state.isLoaderVisible) {
      return <Loader />;
    }
  }

  private renderInputs(): JSX.Element {
    return (
      <Fragment>
        <div className="login-fields">
          <PasswordField
            label={this.text("Common", "newPassword")}
            autoComplete="new-password"
            required={true}
            error={this.state.newPasswordError != null}
            helperText={this.state.newPasswordError}
            onChange={(newPassword: string): void => {
              this.resetErrors();
              this.setState({ newPassword });
            }}
            fullWidth={true}
            margin="normal"
            inputProps={{ "data-testid": "new-password-field" }}
          />
          <PasswordField
            label={this.text("Common", "confirmNewPassword")}
            autoComplete="new-password"
            error={this.state.confirmPasswordError != null}
            helperText={this.state.confirmPasswordError}
            onChange={(confirmPassword: string): void => {
              this.resetErrors();
              this.setState({ confirmPassword });
            }}
            fullWidth={true}
            required={true}
            margin="normal"
            inputProps={{ "data-testid": "confirm-password-field" }}
          />
        </div>
        <div className="login-buttons">
          <Button
            disabled={!this.validatePasswords()}
            variant="contained"
            color="primary"
            onClick={(): Promise<void> => this.completeAccountCreation()}
            data-testid="new-account-login-button"
          >
            {this.text("Login", "confirmLogIn")}
          </Button>
        </div>
      </Fragment>
    );
  }

  private renderGeneralError(): Maybe<JSX.Element> {
    if (this.state.generalError) {
      return (
        <div className="login-errortext" data-testid="complete-account-err">
          {`${this.text("Common", "errorOccurred")} ${this.state.generalError}`}
        </div>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <div className="login-header">
          {this.text("Login", "newPasswordRequired")}
        </div>
        {this.renderLoader()}
        {this.renderInputs()}
        {this.renderGeneralError()}
      </Fragment >
    );
  }
}
