import React, { Component, ReactNode } from "react";
import { Box, Typography } from "@material-ui/core";
import { IconStatus, MapMarkerData } from "data/map/MapLink";
import { getMapMarkerDataForDevice } from "data/utils/deviceUtils";
import DeviceRepository from "data/data-storage/DeviceRepository";
import { Maybe, Nullable } from "types/aliases";
import Device, { DeviceObserver } from "data/device/Device";
import { getDisplayName } from "data/utils/Utils";
import IoTDataRow from "components/layout/iot-data-row/IoTDataRow";
import { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import DataRowLocationIndicator from "components/layout/iot-data-row/DataRowLocationIndicator";
import SButton from "components/styled-components/SButton";
import { DeviceListItem } from "components/devices-view/DevicesWrapper";

interface Props {
  dataItem: DeviceListItem;
  mapMarkerData: Nullable<MapMarkerData[]>;
  onSelectDataItem: () => Promise<void>;
  toggleMarker: (marker: Nullable<MapMarkerData>) => void;
}

interface State {
  deviceDisplayName: Nullable<string>;
}

class DevicesListDataRow extends Component<Props, State> implements DeviceObserver {
  private deviceRepo = DeviceRepository.getInstance();

  public constructor (props: Props) {
    super(props);
    this.state = {
      deviceDisplayName: null,
    };
  }

  public componentDidMount(): void {
    const device = this.deviceRepo.getDevice(this.props.dataItem.deviceId);

    if (device) {
      device.addObserver(this);
      this.setState({ deviceDisplayName: getDisplayName(device) });
    }
  }

  public componentWillUnmount(): void {
    const device = this.deviceRepo.getDevice(this.props.dataItem.deviceId);

    if (device) {
      device.removeObserver(this);
    }
  }

  public onDeviceStateUpdated(device: Device): void {
    if (device.getId() === this.props.dataItem.deviceId) {
      this.setState({
        deviceDisplayName: getDisplayName(device),
      });
    }
  }

  private renderLocationIndicator(device: Maybe<Device>): Nullable<JSX.Element> {
    if (device) {
      const { mapMarkerData, toggleMarker } = this.props;
      return (
        <DataRowLocationIndicator
          deviceId={device.getId()}
          buttonActiveColor={SSvgIconColorProps.yellowGradient}
          mapMarkerData={mapMarkerData}
          getMapMarkerDataItem={(): Nullable<MapMarkerData> => getMapMarkerDataForDevice(device, () => IconStatus.selected)}
          toggleMarker={toggleMarker}
        />
      );
    } else {
      console.error(`Error in rendering a location indicator: device with id '${this.props.dataItem.deviceId}' was not found in Device Repository`);
      return null;
    }
  }

  // Location indicator
  // DeviceName (sort)
  // Device type (sort)
  public render(): ReactNode {
    const { dataItem } = this.props;
    const device = this.deviceRepo.getDevice(dataItem.deviceId);
    return (
      <IoTDataRow dividerFadeEffect>
        {this.renderLocationIndicator(device)}
        <Box width="50%">
          <SButton
            onClick={this.props.onSelectDataItem}
            labelText={this.state.deviceDisplayName ?? undefined}
            textButton
          />
        </Box>
        <Box width="50%">
          <Typography variant="body1" color="textSecondary">
            {dataItem.deviceType}
          </Typography>
        </Box>
      </IoTDataRow>
    );
  }
}

export default DevicesListDataRow;
