import React, { Component, Fragment, ReactNode } from "react";
import Localization from "data/localization-sensoan/Localization";
import Section from "components/layout/Section";
import { Maybe, Nullable, Voidable } from "types/aliases";
import MeasurementJobSelector from "data/measurement-job-selector/MeasurementJobSelector";
import { MeasJobsButtonActions } from "types/sensoanUiTypes";
import MeasurementSetSelector, { MeasurementSetSelectorObserver } from "data/measurement-set-selector/MeasurementSetSelector";
import { v4 as uuid } from "uuid";
import AuthWrapper from "data/auth/AuthWrapper";
import { Box, MenuItem, TextField, Theme, Typography, withTheme } from "@material-ui/core";
import { MeasurementSetConfig } from "data/types/measurementSetTypes";
import MeasSetSelect from "components/inputs/MeasSetSelect";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import MeasurementJobRepository from "data/data-storage/MeasurementJobRepository";
import { MeasurementJob, MeasurementJobStatus, StartType } from "data/types/measurementJobTypes";
import { isDefined } from "utils/types";
import SSelect from "components/styled-components/SSelect";
import SButton from "components/styled-components/SButton";
import DateTimePicker from "components/inputs/DateTimePicker";

interface Props {
  actionRequest: Nullable<MeasJobsButtonActions>;
  clearActionRequest: () => void;
  close: () => void;
  displayName: string;
  handleNameInput: (text: string) => void;
  theme: Theme;
}

interface State {
  setId: Nullable<string>;
  responsible: string;
  startTs: Voidable<number>;
  startTriggerConf: Voidable<string>; // TODO: define TriggerConf
  startTriggerId: Voidable<string>;
  endTs: Voidable<number>;
  endTriggerConf: Voidable<string>; // TODO: define TriggerConf
  endTriggerId: Voidable<string>;
  metadata: Voidable<string>;
  measSetSelectorAnchorEl: Nullable<HTMLButtonElement>;
  startTypeSelectorAnchorEl: Nullable<HTMLButtonElement>;
  startType: Nullable<StartType>;
  saveErrorPopup: boolean;
  startTsAnchorEl: Nullable<HTMLButtonElement>;
  endTsAnchorEl: Nullable<HTMLButtonElement>;
}

const minute = 60 * 1000;

class JobsCreateView extends Component<Props, State> implements MeasurementSetSelectorObserver {

  private jobRepo = MeasurementJobRepository.getInstance();
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = this.getEmptyState();
  }

  public componentDidMount(): void {
    MeasurementSetSelector.getInstance().addObserver(this);
    const selectedJob = MeasurementJobSelector.getInstance().getSelectedMeasurementJob();
    const selectedSet = MeasurementSetSelector.getInstance().getSelectedMeasurementSet();
    const newState: State = this.getEmptyState();

    if (selectedJob !== null) {
      newState.setId = selectedJob.setId;
      this.props.handleNameInput(selectedJob.displayName + "_" + this.text("MeasJobsCreateView", "copy"));
    } else if (selectedSet !== null) {
      newState.setId = selectedSet.setId;
      this.props.handleNameInput("");
    }
    this.setState(newState);
    this.getUserName();
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (this.props.actionRequest !== null && prevProps.actionRequest === null) {
      console.log("Action request: " + this.props.actionRequest);

      this.handleAction();
    }

    if (this.state.startType !== prevState.startType) {
      if (this.state.startType === StartType.timer) {
        this.setState({ startTs: Date.now() + 60 * minute, endTs: Date.now() + 120 * minute });
      } else {
        this.setState({ startTs: undefined, endTs: undefined });
      }
    }
  }

  public onSelectedMeasurementSetChanged(newSet: Nullable<MeasurementSetConfig>): void {
    this.setState({ setId: newSet?.setId ?? null, measSetSelectorAnchorEl: null });
  }

  public componentWillUnmount(): void {
    MeasurementSetSelector.getInstance().removeObserver(this);
  }

  private renderNameRow(): JSX.Element {
    return (
      <Box mt={2} mb={2} width="100%" display="flex" alignItems="center">
        <Box width="20%" display="flex" alignItems="center">
          <Typography color="textSecondary">
            {this.text("MeasJobsListView", "jobName")}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <TextField
            value={this.props.displayName}
            size="small"
            fullWidth={true}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.props.handleNameInput(event.target.value)}
          />
        </Box>
      </Box>
    );
  }

  private renderMeasSetRow(): JSX.Element {
    return (
      <Box mt={2} mb={2} width="100%" display="flex" alignItems="center">
        <MeasSetSelect anchorEl={this.state.measSetSelectorAnchorEl} close={(): void => this.setState({ measSetSelectorAnchorEl: null })} />
        <Box width="20%" display="flex" alignItems="center">
          <Typography color="textSecondary">
            {this.text("MeasJobsListView", "measSet")}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <SButton
            color="secondary"
            labelText={this.state.setId
              ? MeasurementSetRepository.getInstance().getMeasurementSet(this.state.setId)?.displayName
                ?? Localization.getInstance().getDisplayText("MeasJobsListView", "setNotFound")
              : this.text("MeasJobsListView", "measSet")}
            onClick={(event: React.MouseEvent<HTMLButtonElement>): void => this.setState({ measSetSelectorAnchorEl: event.currentTarget })}
          />
        </Box>
      </Box>
    );
  }

  private renderResponsibleRow(): JSX.Element {
    return (
      <Box mt={2} mb={2} width="100%" display="flex" alignItems="center">
        <Box width="20%" display="flex" alignItems="center">
          <Typography color="textSecondary">
            {this.text("MeasJobsListView", "responsible")}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography>
            {this.state.responsible}
          </Typography>
        </Box>
      </Box>
    );
  }

  private renderCommentRow(): JSX.Element {
    return (
      <Box mt={2} mb={2} width="100%" display="flex" alignItems="center">
        <Box width="20%" display="flex" alignItems="center">
          <Typography color="textSecondary">
            {this.text("MeasJobsListView", "comments")}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <TextField
            value={this.state.metadata ?? ""}
            size="small"
            fullWidth={true}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.setState({ metadata: event.target.value })}
          />
        </Box>
      </Box>
    );
  }

  private renderStartTypeSelectRow(): JSX.Element {
    return (
      <Box mt={2} mb={2} width="100%" display="flex" alignItems="center">
        <Box width="20%" display="flex" alignItems="center">
          <Typography color="textSecondary">
            {this.text("MeasJobsCreateView", "selectStartType")}
          </Typography>
        </Box>
        <SSelect buttonText={
          this.state.startType !== null
            ? this.text("MeasJobsCreateView", this.state.startType)
            : this.text("MeasJobsCreateView", "selectStartType")
        }>
          {Object.values(StartType).map((type, key): JSX.Element => {
            return (
              <MenuItem key={key} onClick={(): void => this.setState({ startType: type }) }>
                {this.text("MeasJobsCreateView", type)}
              </MenuItem>
            );
          })}
        </SSelect>
      </Box>
    );
  }

  private renderStartStopTimeRows(): JSX.Element {
    return (
      <Fragment>
        <Box mt={2} mb={2} width="100%" display="flex" alignItems="center">
          <Box width="20%" display="flex" alignItems="center">
            <Typography color="textSecondary">
              {this.text("MeasJobsCreateView", "startTime")}
            </Typography>
          </Box>
          <DateTimePicker
            disablePast
            dateInputId="start-time-date-input"
            setTimestamp={(timestamp): void => this.setState({ startTs: timestamp })}
            timestamp={this.state.startTs!}
            maxTimestamp={this.state.endTs ?? undefined}
          >
            {this.getThresholdNotification(this.state.startTs!, Date.now() + 5 * minute, "start")}
          </DateTimePicker>
        </Box>
        <Box mt={2} mb={2} width="100%" display="flex" alignItems="center">
          <Box width="20%" display="flex" alignItems="center">
            <Typography color="textSecondary">
              {this.text("MeasJobsCreateView", "endTime")}
            </Typography>
          </Box>
          <DateTimePicker
            disablePast
            dateInputId="end-time-date-input"
            setTimestamp={(timestamp): void => this.setState({ endTs: timestamp })}
            timestamp={this.state.endTs!}
            minTimestamp={this.state.startTs ?? undefined}
          >
            {this.getThresholdNotification(this.state.endTs!, this.state.startTs! + 5 * minute, "end")}
          </DateTimePicker>
        </Box>
      </Fragment>
    );
  }

  private getThresholdNotification(value: number, compareValue: number, thresholdType: "end" | "start"): Maybe<JSX.Element> {
    const threshold = 5;

    if (value < compareValue) {
      return (
        <Typography color="primary">
          {this.text("MeasJobsCreateView", thresholdType === "start" ? "startTreshold" : "endTreshold").replace("#1", threshold.toString())}
        </Typography>
      );
    }
  }

  public render(): ReactNode {
    return (
      <Section
        title={Localization.getInstance().getDisplayText("MeasJobsDataView", "info")}
        titleTextStyle="h6"
      >
        {this.renderNameRow()}
        {this.renderMeasSetRow()}
        {this.renderResponsibleRow()}
        {this.renderCommentRow()}
        {this.renderStartTypeSelectRow()}
        {this.state.startType === StartType.timer ? this.renderStartStopTimeRows() : null}
      </Section>
    );
  }

  // - - - - - - - - - - - - -
  // handle actions

  private handleAction(): void {
    switch (this.props.actionRequest) {
      case MeasJobsButtonActions.clear:
        this.setState(this.getEmptyState());
        this.props.handleNameInput("");
        // TODO: uncomment next line when null is accepted argument
        // MeasurementSetSelector.getInstance().setSelectedMeasurementSet(null);
        return this.props.clearActionRequest();

      case MeasJobsButtonActions.save:
        if (this.validateInputs()) {
          // adding new triggers selectedMeasJob change
          // changing selectedMeasJob closes creatingNew in measJobWrapper
          this.jobRepo.add(this.buildJobFromInputs());
        } else {
          this.setState({ saveErrorPopup: true });
        }
        this.props.clearActionRequest();
        return this.props.close();

      default:
        console.error("Unknow action request: " + this.props.actionRequest);
        return this.props.clearActionRequest();
    }
  }

  // - - - - - - - - - - - - -
  // private helper functions

  private getEmptyState(): State {
    return {
      setId: null,
      responsible: "",
      startTs: undefined,
      startTriggerConf: undefined,
      startTriggerId: undefined,
      endTs: undefined,
      endTriggerConf: undefined,
      endTriggerId: undefined,
      metadata: undefined,
      measSetSelectorAnchorEl: null,
      startTypeSelectorAnchorEl: null,
      startType: null,
      saveErrorPopup: false,
      startTsAnchorEl: null,
      endTsAnchorEl: null,
    };
  }

  private async getUserName(): Promise<void> {
    const userName = await AuthWrapper.getCurrentAuthenticatedUsername();
    const fName = await AuthWrapper.getFamilyName();
    const familyName = (fName ?? "").trim();
    let responsible = await AuthWrapper.getGivenName();
    responsible = (responsible ?? "").trim();

    if (responsible.length > 0 && familyName.length > 0) {
      responsible = responsible + " " + familyName;
    } else if (familyName.length > 0) {
      responsible = familyName;
    }

    if (responsible.length < 2) {
      responsible = userName;
    }
    this.setState({ responsible });
  }

  private validateInputs(): boolean {
    let flag = true;


    if (this.props.displayName.length < 1) {
      flag = false;
    }

    if (this.state.setId === null) {
      flag = false;
    }

    if (this.state.responsible === null) {
      flag = false;
    }

    if (this.state.startType === StartType.timer) {
      if (!isDefined(this.state.startTs) || !isDefined(this.state.endTs)) {
        flag = false;
      } else if (this.state.startTs < Date.now() + 3 * minute || this.state.endTs < this.state.startTs + 4 * minute) {
        flag = false;
      }
    } else if (this.state.startType === StartType.manual) {
      if (isDefined(this.state.startTs) || isDefined(this.state.endTs)) {
        flag = false;
      }
    }

    console.log("MeasJob validation returns: ", flag);

    return flag;
  }

  private buildJobFromInputs(): MeasurementJob {
    return {
      setId: this.state.setId as string,
      jobId: uuid(),
      displayName: this.props.displayName,
      responsible: this.state.responsible,
      startTs: this.state.startTs,
      startTriggerConf: this.state.startTriggerConf,
      startTriggerId: this.state.startTriggerId,
      endTs: this.state.endTs,
      endTriggerConf: this.state.endTriggerConf,
      endTriggerId: this.state.endTriggerId,
      metadata: this.state.metadata,
      status: MeasurementJobStatus.NotStarted,
    };
  }

}
export default withTheme(JobsCreateView);
