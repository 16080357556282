/*
* Sensoan extension to Sade booster asset
*
* Wrapper to handle communication to measset-service
*
*/

import AppSyncClientFactory from "data/backend/AppSyncClientFactory";
import { Service } from "data/backend/AppSyncClientProvider";
import ISensoanBackend from "./ISensoanBackend";
import { SetConfig } from "data/types/measurementSetTypes";
import { LocalizationDictionary } from "data/types/localizationTypes";
import {
  AddMeasurementSetConfigDocument,
  AddMeasurementSetGroupDocument,
  DeleteMeasurementSetItemDocument,
  GetLocalizationTextDocument,
  GetLoginLocalizationTextDocument,
  GetMeasurementSetTreeDocument,
  MeasurementJobsListDocument,
  MeasurementJobsAdd2Document,
  MeasurementJobsDeleteDocument,
  MeasurementJobsDetailsEditDocument,
  MeasurementJobsStartDocument,
  MeasurementJobsStop2Document,
  MeasurementSetsConfigEditDocument,
  MeasurementSetsConfigDetailsEditDocument,
  MeasurementSetsItemDisplayNameEditDocument,
  MeasurementServiceVersionDocument,
  BugReportingDocument,
} from "generated/gqlMeassets";
import { EventsTriggerRulesAddDocument, EventsTriggerRulesDeleteDocument, EventsTriggerRulesListDocument } from "generated/gqlEvents";
import { MeasurementJob } from "data/types/measurementJobTypes";
import { isDefined } from "utils/types";
import { Maybe } from "types/aliases";
import { EventTriggerDbEntry, RuleProperties } from "data/types/eventTypes";
import {
  DevicesActivityTypeLabelSendDocument,
  DevicesActivityTypeLabelSendMutationVariables,
  DevicesWhitelistValidateDocument,
  DevicesWhitelistValidateMutationVariables,
  StringResponse,
} from "generated/gqlDevice";

// Combine this with AWSBackend.ts
export default class SensoanBackend implements ISensoanBackend {

  private static instance: ISensoanBackend = new SensoanBackend();

  public static getInstance(): ISensoanBackend {
    return this.instance;
  }

  public async addMeasurementSetConfig(configDisplayName: string, parentIds: string[], config: SetConfig, metadata?: string): Promise<string> {

    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.mutate(
        AddMeasurementSetConfigDocument,
        {
          configDisplayName,
          parentIds,
          config: JSON.stringify(config),
          metadata,
        },
      );

      return response.data ? response.data.addMeasurementSetConfig as string : "";
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return "";
    }
  }

  public async addMeasurementSetGroup(groupDisplayName: string, parentIds: string[]): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.mutate(
        AddMeasurementSetGroupDocument,
        {
          groupDisplayName,
          parentIds,
        },
      );
      return response.data ? response.data.addMeasurementSetGroup as string : "";
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return "";
    }
  }

  public async deleteMeasurementSet(setId: string): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.mutate(
        DeleteMeasurementSetItemDocument,
        { setId },
      );
      return response.data?.deleteMeasurementSetItem as string ?? undefined;
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return "";
    }
  }

  public async getLocalizationData(localeId: string): Promise<LocalizationDictionary> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      console.log(localeId);
      console.log(client);
      const response = await client.query(
        GetLocalizationTextDocument,
        {
          localeId,
        },
      );

      const data = JSON.parse(response.data.getLocalizationText as string);

      if (data.items.length === 1) {
        return data.items[0].data;
      } else {
        console.error("Error: Localization data not found");
        return {};
      }
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      console.log(error);

      return {};
    }
  }

  public async getBackendSwVersion(): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.query(
        MeasurementServiceVersionDocument,
        {},
      );
      const measurementServiceVersion = response.data.measurementServiceVersion;

      if (isDefined(measurementServiceVersion)) {
        return measurementServiceVersion;
      } else {
        console.error("Error: Backend SW info not found");
        return "";
      }
    } catch (error) {
      console.error("Error: ");
      console.log(error);
      return "";
    }
  }

  public async getLoginLocalizationData(localeId: string): Promise<LocalizationDictionary> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.query(
        GetLoginLocalizationTextDocument,
        {
          localeId,
        },
      );
      const data = JSON.parse(response.data.getLoginLocalizationText as string);

      if (data.items.length === 1) {
        return data.items[0].data;
      } else {
        console.error("Error: Localization data not found");
        return {};
      }
    } catch (error) {
      console.error("Error: ");
      console.log(error);
      return {};
    }
  }

  public async getMeasurementSetTree(): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.query(
        GetMeasurementSetTreeDocument,
        {},
      );
      return response.data.getMeasurementSetTree as string;
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return "";
    }
  }

  public async eventsTriggerRulesAdd(triggerId: string, deviceId: string, rules: RuleProperties[]): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
      const response = await client.mutate(
        EventsTriggerRulesAddDocument,
        { triggerId,
          deviceId,
          rules: JSON.stringify(rules),
        },
      );
      return response.data?.eventsTriggerRulesAdd ? "success" : "error";
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return "error";
    }
  }

  public async eventsTriggerRulesDelete(triggerId: string): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
      const response = await client.mutate(
        EventsTriggerRulesDeleteDocument,
        { triggerId },
      );
      return response.data?.eventsTriggerRulesDelete ? "success" : "error";
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return "error";
    }
  }

  public async eventsTriggerRulesList(deviceId: string): Promise<EventTriggerDbEntry[]> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
      const response = await client.query(
        EventsTriggerRulesListDocument,
        {
          deviceId,
          dummy: Date.now().toString(),
        },
      );
      return response.data?.eventsTriggerRulesList ? JSON.parse(response.data.eventsTriggerRulesList) : [];
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return [];
    }
  }

  public async measurementSetsConfigEdit(setId: string, configDisplayName: string, metadata: string, config: SetConfig): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.mutate(
        MeasurementSetsConfigEditDocument,
        { setId,
          configDisplayName,
          metadata,
          config: JSON.stringify(config),
        },
      );
      return response.data?.measurementSetsConfigEdit ? "success" : "error";
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return "error";
    }
  }

  public async measurementSetsConfigDetailsEdit(setId: string, metadata: string): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.mutate(
        MeasurementSetsConfigDetailsEditDocument,
        { setId,
          metadata,
        },
      );
      return response.data?.measurementSetsConfigDetailsEdit ? "success" : "error";
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return "error";
    }
  }

  public async measurementSetsItemDisplayNameEdit(setId: string, displayName: string): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.mutate(
        MeasurementSetsItemDisplayNameEditDocument,
        { setId,
          displayName,
        },
      );
      return response.data?.measurementSetsItemDisplayNameEdit ? "success" : "error";
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return "error";
    }
  }

  public async measurementJobsAdd(measurementJob: MeasurementJob): Promise<Maybe<MeasurementJob>> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.mutate(
        MeasurementJobsAdd2Document,
        { measurementJob },
      );
      return response.data?.measurementJobsAdd2 ?? undefined;
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return undefined;
    }
  }

  public async measurementJobsDelete(setId: string, jobId: string): Promise<Maybe<MeasurementJob>> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.mutate(
        MeasurementJobsDeleteDocument,
        { setId, jobId },
      );
      return response.data?.measurementJobsDelete ?? undefined;
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return undefined;
    }
  }

  public async measurementJobsDetailsEdit(setId: string, jobId: string, metadata: string): Promise<Maybe<MeasurementJob>> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.mutate(
        MeasurementJobsDetailsEditDocument,
        { setId, jobId, metadata },
      );
      return response.data?.measurementJobsDetailsEdit ?? undefined;
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return undefined;
    }
  }

  // TODO: add pagination support, backend may return nextToken!
  public async measurementJobsList(setId: string): Promise<MeasurementJob[]> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.query(
        MeasurementJobsListDocument,
        { setId },
      );
      const jobs: Maybe<MeasurementJob[]> = response.data.measurementJobsList?.measurementJobs
        ?.filter(isDefined);
      return jobs ?? [];
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return [];
    }
  }

  public async measurementJobsStart(setId: string, jobId: string): Promise<Maybe<MeasurementJob>> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.mutate(
        MeasurementJobsStartDocument,
        { setId, jobId },
      );
      return response.data?.measurementJobsStart ?? undefined;
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return undefined;
    }
  }

  public async measurementJobsStop(setId: string, jobId: string): Promise<Maybe<MeasurementJob>> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.mutate(
        MeasurementJobsStop2Document,
        { setId, jobId },
      );
      return response.data?.measurementJobsStop2 ?? undefined;
    } catch (error) {
      console.error("Error: ", error);
      return undefined;
    }
  }

  public async sendBugReport(subject: string, msgBody: string): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
      const response = await client.mutate(
        BugReportingDocument,
        { subject, msgBody },
      );
      console.log("email sent: ", response.data?.bugReporting);

      return response.data?.bugReporting ?? "error";
    } catch (error) {
      console.error("Error: ", error);
      return "error";
    }
  }

  public async devicesWhitelistValidate(params: DevicesWhitelistValidateMutationVariables): Promise<StringResponse> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
      const response = await client.mutate(
        DevicesWhitelistValidateDocument,
        params,
      );

      return response.data?.devicesWhitelistValidate ?? StringResponse.NotAuthorized;
    } catch (error) {
      console.error("Error: ", error);
      return StringResponse.NotAuthorized;
    }
  }

  public async devicesActivityTypeLabelSend(params: DevicesActivityTypeLabelSendMutationVariables): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
      const response = await client.mutate(
        DevicesActivityTypeLabelSendDocument,
        params,
      );

      console.log("devicesActivityTypeLabelSend / response: ", response);
      // Currently successful response is: { data: {devicesActivityTypeLabelSend: null } }
      return "success";
    } catch (error) {
      console.error("devicesActivityTypeLabelSend / Error: ", error);
      return "error";
    }
  }
}
