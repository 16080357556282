/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment, ReactNode } from "react";
import { FormControl, TextField } from "@material-ui/core";
import DeviceGroup from "data/device/DeviceGroup";
import Localization from "data/localization-sensoan/Localization";
import ErrorDialog from "components/ui/error-dialog";
import FormDialog from "components/ui/form-dialog";
import Loader from "components/ui/loader";

interface Props {
  targetGroup: DeviceGroup;
  open: boolean;
  onClose: (success: boolean) => void;
}

interface State {
  loading: boolean;
  deviceId: string;
  errorMessage?: string;
}

export default class AddDevicePopup extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      deviceId: "",
      loading: false,
    };
  }

  private addDeviceToGroup = async (): Promise<void> => {
    try {
      this.setState({ loading: true });

      if (!this.formIsValid()) {
        throw new Error("Invalid control flow");
      }
      await this.props.targetGroup.addDevice(this.state.deviceId!);
      this.props.onClose(true);
    } catch (error) {
      console.error("addDeviceToGroup", error);
      this.setState({
        errorMessage: this.text("AddDevicePopup", "failedToAddDevice"),
      });
      this.props.onClose(false);
    } finally {
      this.setState({ loading: false });
    }
  };

  private formIsValid(): boolean {
    return !!this.state.deviceId;
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <ErrorDialog
          onClose={(): void => {
            this.setState({ errorMessage: undefined });
            this.props.onClose(false);
          }}
          errorMsg={this.state.errorMessage}
        />
        <FormDialog
          title={this.text("AddDevicePopup", "addNewDevice")}
          acceptButtonText={this.text("Actions", "add")}
          isOpen={this.props.open}
          onAccept={this.addDeviceToGroup}
          onCancel={(): void => this.props.onClose(false)}
          disableAccept={!this.formIsValid()}
          maxWidth={"xs"}
        >
          <FormControl component="fieldset" margin="normal" fullWidth>
            <TextField
              variant="outlined"
              label={this.text("AddDevicePopup", "deviceIdentifier")}
              value={this.state.deviceId}
              onChange={(event): void =>
                this.setState({ deviceId: event.currentTarget.value })}
            />
          </FormControl>
          <Loader show={this.state.loading}/>
        </FormDialog>
      </Fragment>
    );
  }
}

