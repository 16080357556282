import React, { Component, Fragment } from "react";
import { Box, TextField, Theme, Typography, withTheme } from "@material-ui/core";
import { Maybe, Nullable } from "types/aliases";
import Localization from "data/localization-sensoan/Localization";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import { MeasurementSetTreeItem } from "data/types/measurementSetTypes";
import SSelect from "components/styled-components/SSelect";
import MeasSetGroupTree2 from "components/measurement-sets/tree-components/MeasSetGroupTree2";
import SButton from "components/styled-components/SButton";
import ErrorMessage from "components/layout/ErrorMessage";
import MeasurementSetSelector from "data/measurement-set-selector/MeasurementSetSelector";
import { BackendActionStatus, GroupAction } from "types/sensoanUiTypes";
import DialogSensoan from "../../layout/DialogSensoan";

interface Props {
  onSelectTreeItem: (event: React.MouseEvent<Element, MouseEvent>, measurementSetGroup: MeasurementSetTreeItem) => void;
  theme: Theme;
  clearSelectedGroup?: () => void;
  configSelectMode?: boolean;
  disableModifications?: boolean;
  selectedGroupId?: string;
}

//TODO: replace error and success with errorMsg and successMsg
interface State {
  action: Nullable<GroupAction>;
  backendActionStatus: Nullable<BackendActionStatus>;
  dialogOpen: boolean;
  groupId: string;
  groupName: string;
  loading: boolean;
  parentIds: string[];
  tree: MeasurementSetTreeItem[];
}

class MeasSetGroupMenu2 extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      action: null,
      backendActionStatus: null,
      dialogOpen: false,
      groupId: "",
      groupName: "",
      loading: false,
      parentIds: [],
      tree: MeasurementSetRepository.getInstance().getTree(),
    };
    this.handleStartAddNewGroup = this.handleStartAddNewGroup.bind(this);
    this.handleStartDeleteGroup = this.handleStartDeleteGroup.bind(this);
    this.clearSetOrGroupSelection = this.clearSetOrGroupSelection.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  public componentDidUpdate(_prevProps: Props, prevState: State): void {
    if (!prevState.backendActionStatus && this.state.backendActionStatus) {
      this.setState({
        tree: MeasurementSetRepository.getInstance().getTree(),
      });
    }

    if (prevState.dialogOpen && !this.state.dialogOpen) {
      this.setState({
        backendActionStatus: null,
        action: null,
        groupName: "",
        groupId: "",
        parentIds: [],
      });
    }
  }

  private renderActionDialog(): Maybe<JSX.Element> {
    if (this.state.dialogOpen) {
      const isAddDialog = this.state.action === GroupAction.ADD;
      const title = isAddDialog
        ? this.text("MeasSetGroupMenu", "createNewGroup")
        : this.text("MeasSetGroupMenu", "deleteGroup");
      return (
        <DialogSensoan
          dialogActionElements={this.renderActionDialogButtons()}
          onClose={this.handleCloseDialog}
          showLoader={this.state.loading}
          title={title}
        >
          {isAddDialog
            ? this.renderAddDialogContent()
            : this.renderDeleteDialogContent()
          }
        </DialogSensoan>
      );
    }
  }

  private renderActionDialogButtons(): Maybe<JSX.Element> {
    if (this.state.backendActionStatus !== null) {
      return <SButton labelText={this.text("MeasSetGroupMenu", "ok")} onClick={this.handleCloseDialog}/>;
    } else {
      const cancelButton = <SButton color="secondary" labelText={this.text("MeasSetGroupMenu", "cancel")} onClick={this.handleCloseDialog}/>;

      if (this.state.action === GroupAction.ADD) {
        return (
          <Fragment>
            <SButton labelText={this.text("MeasSetGroupMenu", "save")} disabled={this.state.groupName === ""} onClick={(): Promise<void> => this.addGroup()}/>
            {cancelButton}
          </Fragment>
        );
      } else if (this.state.action === GroupAction.DELETE) {
        return (
          <Fragment>
            <SButton labelText={this.text("MeasSetGroupMenu", "ok")} onClick={(): Promise<void> => this.deleteGroup()}/>
            {cancelButton}
          </Fragment>
        );
      }
    }
  }

  private renderAddDialogContent(): JSX.Element {
    let content = null;

    if (this.state.backendActionStatus !== null) {
      content = (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" pb="1.5rem">
          <Typography variant="body1" color="textPrimary">
            {this.state.backendActionStatus === BackendActionStatus.SUCCESS
              ? this.text("MeasSetGroupMenu", "saveSuccessMsg")
              : this.text("MeasSetGroupMenu", "saveErrorMsg")
            }
          </Typography>
        </Box>
      );
    } else {
      content = (
        <Box display="flex" justifyContent="center" alignItems="baseline" height="100%">
          <Typography variant="body1" color="textSecondary" style={{ marginRight: "1rem" }}>
            {Localization.getInstance().getDisplayText("MeasSetGroupMenu", "groupName")}
          </Typography>
          <Box display="flex" flexDirection="column">
            <TextField
              onChange={({ target }): void => this.setState({ groupName: target.value })}
              value={this.state.groupName}
              style={{ width: "85%" }}
            />
            <Box mt="10px" height="1.5rem">
              {this.state.groupName === "" && <ErrorMessage />}
            </Box>
          </Box>
        </Box>
      );
    }
    return content;
  }

  private renderDeleteDialogContent(): JSX.Element {
    let content = null;

    if (this.state.backendActionStatus !== null) {
      content = (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" pb="1.5rem">
          <Typography variant="body1" color="textPrimary">
            {this.state.backendActionStatus === BackendActionStatus.SUCCESS
              ? this.text("MeasSetGroupMenu", "deleteSuccessMsg")
              : this.text("MeasSetGroupMenu", "deleteErrorMsg")
            }
          </Typography>
        </Box>
      );
    } else {
      content = (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" pb="1.5rem">
          <Typography variant="body1" color="textSecondary" style={{ marginRight: "0.5rem" }}>
            {this.text("MeasSetGroupMenu", "confirmGroupDelete")}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {this.state.groupName}
          </Typography>
        </Box>
      );
    }
    return content;
  }

  public render(): JSX.Element {
    return (
      <Box>
        <SSelect buttonText={this.getButtonText()}>
          <MeasSetGroupTree2
            onAddButtonClick={this.handleStartAddNewGroup}
            onClearButtonClick={this.clearSetOrGroupSelection}
            onDeleteButtonClick={this.handleStartDeleteGroup}
            onTreeItemLabelClick={this.props.onSelectTreeItem}
            selectedGroupId={this.props.selectedGroupId}
            treeItems={this.state.tree}
            disableModifications={this.props.disableModifications}
            configSelectMode={this.props.configSelectMode}
          />
        </SSelect>
        {this.renderActionDialog()}
      </Box>
    );
  }

  private handleStartAddNewGroup(event: React.MouseEvent<HTMLButtonElement>, parentIds: string[]): void {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      action: GroupAction.ADD,
      dialogOpen: true,
      parentIds,
    });
  }

  private handleStartDeleteGroup(event: React.MouseEvent<HTMLButtonElement>, group: MeasurementSetTreeItem): void {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      dialogOpen: true,
      action: GroupAction.DELETE,
      groupId: group.setId,
      groupName: group.displayName,
    });
  }

  private clearSetOrGroupSelection(): void {
    if (MeasurementSetSelector.getInstance().getSelectedMeasurementSet !== null && this.props.configSelectMode) {
      MeasurementSetSelector.getInstance().setSelectedMeasurementSet(null);
    } else if (this.props.selectedGroupId && !this.props.configSelectMode){
      this.props.clearSelectedGroup?.();
    }
  }

  private handleCloseDialog(): void {
    this.setState({ dialogOpen: false });
  }

  private getButtonText(): string {
    if (this.props.configSelectMode) {
      const set = MeasurementSetSelector.getInstance().getSelectedMeasurementSet();
      return set !== null ? set.displayName : this.text("MeasJobsListView", "measSet");
    } else {
      return this.props.selectedGroupId
        ? MeasurementSetRepository.getInstance().findParentGroupFromTree([this.props.selectedGroupId])[0].displayName
        : this.text("MeasSetGroupMenu", "group");
    }
  }

  private async addGroup(): Promise<void> {
    this.setState({ loading: true });
    const response = await MeasurementSetRepository.getInstance().addGroup(this.state.groupName, this.state.parentIds);
    this.setState({ loading: false });

    if (response !== -1) {
      this.setState({ backendActionStatus: BackendActionStatus.SUCCESS });
    } else {
      this.setState({ backendActionStatus: BackendActionStatus.ERROR });
      console.error("Error in MeasSetGroupMenu.addGroup(): saving a group failed");
    }
  }

  private async deleteGroup(): Promise<void> {
    this.setState({ loading: true });
    const response = await MeasurementSetRepository.getInstance().deleteGroup(this.state.groupId);
    this.setState({ loading: false });

    if (response !== -1) {
      this.setState({ backendActionStatus: BackendActionStatus.SUCCESS });
    } else {
      this.setState({ backendActionStatus: BackendActionStatus.ERROR });
      console.error("Error in MeasSetGroupMenu.deleteGroup(): deleting a group failed");
    }
  }
}

export default withTheme(MeasSetGroupMenu2);
