import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DevicePathRouterProps } from "types/routerprops";
import { AppLayoutMode } from "types/sensoanUiTypes";
import AppViews from "client/AppViews";
import AppMenuItem from "./AppMenuItem";

interface Props extends RouteComponentProps<DevicePathRouterProps> {
  appLayoutMode: AppLayoutMode;
  onAppLayoutModeChange: () => void;
}

interface State {
}

class AppMenu extends Component<Props, State> {
  // trigger a change in layout when map is in full screen mode and a view is selected from menu -> user is taken to selected view and map is in drawer again
  public componentDidUpdate(prevProps: Props): void {
    if (this.props.location.pathname !== prevProps.location.pathname && this.props.appLayoutMode === AppLayoutMode.mapWithDrawer) {
      this.props.onAppLayoutModeChange();
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {AppViews.map(({ path, icon }) => <AppMenuItem key={path} name={path} active={this.props.location.pathname.startsWith(`${path}`)} icon={icon}/>)}
      </Fragment>
    );
  }
}

export default withRouter(AppMenu);
