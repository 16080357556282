import React, { Component, ReactNode } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box, Theme, Typography, withTheme } from "@material-ui/core";
import { Maybe } from "types/aliases";
import SIconButton from "components/styled-components/SIconButton";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";

interface Props {
  chartIndex?: number;
  deleteChart?: Maybe<(index: Maybe<number>) => void>;
  editChart?: Maybe<(index: Maybe<number>) => void>;
  marginTop?: number;
  theme: Theme;
  title: Maybe<string>;
}

interface State {
}

class ChartTitle extends Component<Props, State> {

  public render(): ReactNode {
    const withButtons = this.props.editChart !== undefined && this.props.deleteChart !== undefined;
    return (
      <Box
        display="flex"
        height="10%"
        justifyContent="center" alignItems="center"
        mt={this.props.marginTop ?? 4}
        width="100%"
      >
        <Typography variant="h6" style={{ margin: "0 1rem" }}>
          {this.props.title}
        </Typography>
        {withButtons && this.renderButtons()}
      </Box>
    );
  }

  private renderButtons(): ReactNode {
    return (
      <Box display="flex">
        <SIconButton onClick={(): void => this.props.editChart?.(this.props.chartIndex)}>
          <SSvgIcon color={SSvgIconColorProps.blueGradient} iconComponent={EditIcon}/>
        </SIconButton>
        <SIconButton onClick={(): void => this.props.deleteChart?.(this.props.chartIndex)}>
          <SSvgIcon color={SSvgIconColorProps.orangeGradient} iconComponent={DeleteIcon}/>
        </SIconButton>
      </Box>
    );
  }
}

export default withTheme(ChartTitle);
