import { MenuItem } from "@material-ui/core";
import SSelect from "components/styled-components/SSelect";
import Localization from "data/localization-sensoan/Localization";
import React from "react";
import { Themes } from "../../data/theme/themes";
import ThemeSelector from "../../data/theme/ThemeSelector";

interface State {
}

interface Props {
  onSelectTheme: () => void;
}

class ThemeInput extends React.Component<Props, State> {

  private handleSelectTheme(themeName: string): void {
    ThemeSelector.getInstance().setTheme(themeName as Themes);
    this.props.onSelectTheme();
  }

  public render(): JSX.Element {
    return (
      <SSelect
        buttonText={`${Localization.getInstance().getDisplayText("ThemeInput", "theme")}: ${ThemeSelector.getInstance().getSelectedThemeType()}`}
      >
        {Object.keys(Themes).map((themeName: string, index: number): JSX.Element => (
          <MenuItem
            key={index}
            onClick={(): void => this.handleSelectTheme(themeName)}
            value={themeName}
          >
            {themeName}
          </MenuItem>
        ))}
      </SSelect>
    );
  }
}

export default ThemeInput;
