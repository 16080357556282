import React, { Component, Fragment, ReactNode } from "react";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import Organization from "data/organization/Organization";
import Localization from "data/localization-sensoan/Localization";
import { Maybe } from "types/aliases";
import accessControlled from "components/access-control/access-controlled";
import ErrorDialog from "components/ui/error-dialog";
import Loader from "components/ui/loader";

interface Props {
  organization: Organization;
}

interface State {
  loading: boolean;
  displayName: string;
  errorMsg?: string;
}

const ACTextField = accessControlled(TextField, ["organizationsUpdate"]);

const ROW_COL_COUNT = 2;
export default class OrganizationDetails extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      displayName: props.organization.getName(),
    };
  }

  public async componentDidMount(): Promise<void> {
    this.setState({
      displayName: this.props.organization.getName(),
    });
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (prevProps.organization.getId() !== this.props.organization.getId()) {
      this.setState({
        displayName: this.props.organization.getName(),
      });
    }
  }

  private displayNameChanged = (newInput: string): void => {
    this.setState({ displayName: newInput });
  };

  private handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === "Enter") {
      return this.submitNameChange();
    }
  };

  private handleSubmit = async (): Promise<void> => {
    return this.submitNameChange();
  };

  private async submitNameChange(): Promise<void> {
    this.setState({ loading: true });

    try {
      await this.props.organization.changeName(this.state.displayName);
      this.setState({
        loading: false,
        errorMsg: undefined,
        displayName: this.props.organization.getName(),
      });
    } catch (error) {
      console.error("submitNameChange", error);
      this.setState({ loading: false });
    }
  }

  private cancelNameChange = (): void => {
    this.setState({ displayName: this.props.organization.getName() });
  };

  private renderNameControllers(): ReactNode {
    const disabled = this.state.displayName === this.props.organization.getName();
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleSubmit}
          style={{ marginRight: "2rem" }}
          disabled={disabled}
        >
          {this.text("Actions", "save")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={this.cancelNameChange}
          disabled={disabled}
        >
          {this.text("Actions", "cancel")}
        </Button>
      </div>
    );
  }

  private renderLoader(): Maybe<JSX.Element> {
    if (this.state.loading) {
      return (
        <TableRow>
          <TableCell colSpan={ROW_COL_COUNT} align="center">
            <Loader size="small" />
          </TableCell>
        </TableRow>
      );
    }
  }

  public render(): ReactNode {

    return (
      <Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={ROW_COL_COUNT}>{this.text("AdminView", "organisationDetails")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <ACTextField
                  contextOrganization={this.props.organization}
                  accessDeniedProps={{ disabled: true }}
                  fullWidth={true}
                  variant="outlined"
                  placeholder={this.text("AdminView", "organisationName")}
                  className="organization-name"
                  value={this.state.displayName}
                  onChange={(event): void => this.displayNameChanged(event.currentTarget.value)}
                  onKeyPress={this.handleKeyPress}
                />
              </TableCell>
              <TableCell align="right" size="small">
                {this.renderNameControllers()}
              </TableCell>
            </TableRow>
            {this.renderLoader()}
          </TableBody>
        </Table>
        <ErrorDialog
          errorMsg={this.state.errorMsg}
          onClose={(): void => this.setState({ errorMsg: undefined })}
        />
      </Fragment>
    );
  }
}
