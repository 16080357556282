import React, { Component } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import Loader from "components/ui/loader";
import { Maybe } from "types/aliases";
import AuthWrapper from "data/auth/AuthWrapper";
import PasswordField from "components/ui/password-field";
import { isErrorWithCode } from "data/utils/ErrorUtils";
import Localization from "data/localization-sensoan/Localization";

interface Props {}

interface State {
  isLoading: boolean;
  username: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  error?: string;
}

export default class PasswordForm extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      username: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  }

  public componentDidMount(): void {
    this.getloggedInUserName();
  }

  private async getloggedInUserName(): Promise<void> {
    const username = await AuthWrapper.getCurrentAuthenticatedUsername();
    this.setState({ username });
  }

  private handlePasswordSubmit = async (): Promise<void> => {
    try {
      this.setState({ isLoading: true });
      await AuthWrapper.submitNewPassword(this.state.oldPassword, this.state.newPassword);
      this.setState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      console.error("handlePasswordSubmit", error);
      if (isErrorWithCode(error)) this.handleErrorCode(error.message);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  private handleErrorCode(code?: string): void {
    switch (code) {
      case "Attempt limit exceeded, please try after some time.":
        this.setErrorMessage(this.text("Common", "tooManyAttempts"));
        break;
      case "Incorrect username or password.":
        this.setErrorMessage(this.text("Common", "incorrectCredentials"));
        break;
      case "Network error":
        this.setErrorMessage(this.text("Common", "networkError"));
        break;
      case "Password did not conform with policy: Password must have numeric characters":
        this.setErrorMessage(this.text("Common", "passwordMustHaveNumbers"));
        break;
      case "Password did not conform with policy: Password must have lowercase characters":
        this.setErrorMessage(this.text("Common", "passwordMustHaveLowercaseCharacters"));
        break;
      case "1 validation error detected: Value at 'previousPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "2 validation errors detected: Value at 'previousPassword' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "1 validation error detected: Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "Password did not conform with policy: Password not long enough":
        this.setErrorMessage(this.text("Common", "passwordMustBeLongEnough"));
        break;
      case "Invalid session for the user, session is expired.":
        this.setErrorMessage(this.text("Common", "userSessionExpired"));
        break;
      default:
        this.setErrorMessage(this.text("Common", "unableToPerformAction"));
        break;
    }
  }

  private setErrorMessage(error?: string): void {
    this.setState({ error });
  }

  private renderPasswordValidationMessage(): Maybe<JSX.Element> {
    if (this.state.newPassword.length > 0 && this.state.confirmPassword.length > 0 && this.state.newPassword !== this.state.confirmPassword) {
      return (
        <Grid item={true} container={true} justifyContent="center">
          <span>{this.text("Common", "passwordsNotMatching")}</span>
        </Grid>
      );
    }
  }

  private renderPasswordSubmitMessage(): Maybe<JSX.Element> {
    if (this.state.error) {
      return (
        <Grid item={true} container={true} justifyContent="center">
          <span>{`${this.text("Common", "errorOccurred")} ${this.state.error}`}</span>
        </Grid>
      );
    }
  }

  private renderLoader(): Maybe<JSX.Element> {
    if (this.state.isLoading) {
      return <Grid item={true} container={true} justifyContent="center"><Loader /></Grid>;
    }
  }

  private renderInputs(): JSX.Element {
    const isEnabled = this.state.newPassword.length > 0 && this.state.confirmPassword.length > 0;
    return (
      <Grid item={true} xs={12} sm={10} md={8}>
        <Grid item={true}>
          <PasswordField
            fullWidth={true}
            label={this.text("UserView", "oldPassword")}
            autoComplete="current-password"
            margin="normal"
            onChange={(password: string): void => {
              this.setErrorMessage(undefined);
              this.setState({ oldPassword: password });
            }}
          />
        </Grid>
        <Grid item={true}>
          <PasswordField
            fullWidth={true}
            label={this.text("Common", "newPassword")}
            autoComplete="current-password"
            margin="normal"
            onChange={(password: string): void => {
              this.setErrorMessage(undefined);
              this.setState({ newPassword: password });
            }}
          />
        </Grid>
        <Grid item={true}>
          <PasswordField
            fullWidth={true}
            label={this.text("Common", "confirmNewPassword")}
            autoComplete="current-password"
            margin="normal"
            onChange={(password: string): void => {
              this.setErrorMessage(undefined);
              this.setState({ confirmPassword: password });
            }}
          />
        </Grid>
        <Grid item={true} container={true} justifyContent="center">
          <Button
            disabled={!isEnabled}
            variant="contained"
            color="primary"
            onClick={this.handlePasswordSubmit}
          >
            {this.text("UserView", "confirmPasswordChange")}
          </Button>
        </Grid>
      </Grid>
    );
  }

  public render(): JSX.Element {
    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} container={true} justifyContent="center">
          <Typography variant="h6" style={{ fontWeight: "bold" }}>{this.text("UserView", "enterOldPasswordAndNewPassword")}</Typography>
        </Grid>
        <Grid item={true} container={true} spacing={2} justifyContent="center">
          {this.renderInputs()}
        </Grid>
        {this.renderPasswordValidationMessage()}
        {this.renderLoader()}
        {this.renderPasswordSubmitMessage()}
      </Grid>
    );
  }
}
