import React, { Component, Fragment, ReactNode } from "react";
import WrapperTitle2 from "components/layout/WrapperTitle2";
import MeasurementJobRepository from "data/data-storage/MeasurementJobRepository";
import MeasurementJobSelector, { MeasurementJobSelectorObserver } from "data/measurement-job-selector/MeasurementJobSelector";
import Localization from "data/localization-sensoan/Localization";
import { BreadcrumbData, MeasJobsButtonActions } from "types/sensoanUiTypes";
import { MeasurementJob } from "data/types/measurementJobTypes";
import { Nullable } from "types/aliases";
import JobsListView from "./list-view/JobsListView";
import JobsDataView from "./data-view/JobsDataView";
import StartStopButton from "./header-items/StartStopButton";
import JobsCreateView from "./create-new-view/JobsCreateView";
import { Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SButton from "components/styled-components/SButton";
import withDataJanitor from "components/hocs/DataJanitor";
import { DataRepositories } from "data/data-storage/DataRepositoryFactory";
import { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import MeasurementSetSelector, { MeasurementSetSelectorObserver } from "data/measurement-set-selector/MeasurementSetSelector";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import { MeasurementSetConfig } from "data/types/measurementSetTypes";
import AdminSButton from "components/styled-components/AdminSButton";
import WrapperContainer from "components/layout/WrapperContainer";

interface Props {
}

interface State {
  creatingNewJob: boolean;
  titleBreadcrumbs: BreadcrumbData[];
  newJobName: string;
  actionRequest: Nullable<MeasJobsButtonActions>;
}

class MeasurementJobsWrapper extends Component<Props, State> implements MeasurementJobSelectorObserver, MeasurementSetSelectorObserver {

  private jobSelector = MeasurementJobSelector.getInstance();
  private setSelector = MeasurementSetSelector.getInstance();
  private jobRepo = MeasurementJobRepository.getInstance();
  private setRepo = MeasurementSetRepository.getInstance();
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      creatingNewJob: false,
      titleBreadcrumbs: [{
        name: (): string => Localization.getInstance().getDisplayText("Common", "measurementJobs"),
        link: (): void => {
          this.jobSelector.setSelectedMeasurementJob(null);
          this.setSelector.setSelectedMeasurementSet(null);
        },
      }],
      newJobName: "",
      actionRequest: null,
    };
    this.clearActionRequest = this.clearActionRequest.bind(this);
    this.handleJobNameInputEvent = this.handleJobNameInputEvent.bind(this);
    this.handleCloseCreateNew = this.handleCloseCreateNew.bind(this);
    this.handleSetNameCrumbClick = this.handleSetNameCrumbClick.bind(this);
  }

  public componentDidMount(): void {
    this.jobSelector.addObserver(this);
    this.setSelector.addObserver(this);

    const crumbs = [this.state.titleBreadcrumbs[0]];
    const selectedJob = this.jobSelector.getSelectedMeasurementJob();
    const selectedSet = this.setSelector.getSelectedMeasurementSet();

    if (selectedJob !== null) {
      const setToSelect = this.setRepo.getMeasurementSet(selectedJob.setId);

      if (setToSelect !== null) {
        crumbs[1] = {
          name: setToSelect.displayName,
          link: (): void => {
            this.jobSelector.setSelectedMeasurementJob(null);
            this.setSelector.setSelectedMeasurementSet(setToSelect);
          },
        };
        crumbs[2] = {
          name: selectedJob.displayName,
          link: (): void => undefined,
        };
      } else {
        console.error("MeasurementJobsWrapper.cDM: meas. set not found for selected meas. job");
        return;
      }
    } else if (selectedSet !== null) {
      crumbs[1] = {
        name: selectedSet.displayName,
        link: (): void => undefined,
      };
    }
    this.setState({
      titleBreadcrumbs: crumbs,
    });
  }

  public componentWillUnmount(): void {
    MeasurementJobSelector.getInstance().removeObserver(this);
  }

  public onSelectedMeasurementJobChanged(job: Nullable<MeasurementJob>): void {
    const crumbs = [this.state.titleBreadcrumbs[0]];

    if (job !== null) {
      const setToSelect = this.setRepo.getMeasurementSet(job.setId);

      if (setToSelect !== null) {
        crumbs[1] = {
          name: setToSelect.displayName,
          link: (): void => {
            this.jobSelector.setSelectedMeasurementJob(null);
            this.setSelector.setSelectedMeasurementSet(setToSelect);
          },
        };
        crumbs[2] = {
          name: job.displayName,
          link: (): void => undefined,
        };
      } else {
        console.error("MeasurementJobsWrapper.onSelectedMeasurementJobChanged: meas. set not found for selected meas. job");
        return;
      }
    } else {
      const selectedSet = this.setSelector.getSelectedMeasurementSet();

      if (selectedSet !== null) {
        crumbs[1] = {
          name: selectedSet.displayName,
          link: (): void => undefined,
        };
      }
    }
    this.setState({ creatingNewJob: false, titleBreadcrumbs: crumbs, newJobName: "" });
  }

  public onSelectedMeasurementSetChanged(set: Nullable<MeasurementSetConfig>): void {
    let crumbs = [...this.state.titleBreadcrumbs];

    if (set !== null) {
      crumbs[1] = {
        name: set.displayName,
        link: (): void => this.handleSetNameCrumbClick(),
      };
    } else {
      if (this.state.creatingNewJob) {
        crumbs[1] = {
          name: this.text("MeasJobsListView", "measSet"),
          link: (): void => this.handleSetNameCrumbClick(),
        };
      } else {
        crumbs = [this.state.titleBreadcrumbs[0]];
      }
    }
    this.setState({
      titleBreadcrumbs: crumbs,
    });
  }

  private renderCreateView(): JSX.Element {
    return (
      <Fragment>
        <WrapperTitle2 breadcrumbList={this.state.titleBreadcrumbs}>
          <Box display="flex">
            <SButton
              fontWeight="regular"
              labelText={this.text("MeasJobsCreateView", "save")}
              mr={4}
              onClick={(): void => this.actionRequest(MeasJobsButtonActions.save)}
            />
            <SButton
              color="secondary"
              fontWeight="regular"
              labelText={this.text("MeasJobsCreateView", "cancel")}
              onClick={(): void => this.handleCloseCreateNew()}
            />
          </Box>
        </WrapperTitle2>
        <JobsCreateView
          actionRequest={this.state.actionRequest}
          clearActionRequest={this.clearActionRequest}
          close={this.handleCloseCreateNew}
          displayName={this.state.newJobName}
          handleNameInput={this.handleJobNameInputEvent}
        />
      </Fragment>
    );
  }

  private renderDataView(): JSX.Element {
    const job = this.jobSelector.getSelectedMeasurementJob() as MeasurementJob;
    return (
      <Fragment>
        <WrapperTitle2 breadcrumbList={this.state.titleBreadcrumbs}>
          <Box display="flex">
            <StartStopButton job={job}/>
            <AdminSButton
              fontWeight="regular"
              color="secondary"
              labelText={this.text("MeasJobsDataView", "delete")}
              onClick={(): void => { this.jobRepo.delete(job.setId, job.jobId); }}
              widthInRems={6}
            />
          </Box>
        </WrapperTitle2>
        <JobsDataView />
      </Fragment>
    );
  }

  private renderListView(): JSX.Element {
    return (
      <Fragment>
        <WrapperTitle2 breadcrumbList={this.state.titleBreadcrumbs} minWidth="1300px">
          <SButton
            endIcon={AddIcon}
            fontWeight="medium"
            iconColor={SSvgIconColorProps.white}
            labelText={this.text("MeasJobs", "addNew")}
            onClick={(): void => this.startCreateNewJob()}
          />
        </WrapperTitle2>
        <JobsListView
        />
      </Fragment>
    );
  }

  public render(): ReactNode {
    let view: ReactNode = null;

    if (this.state.creatingNewJob) {
      view = this.renderCreateView();
    } else if (this.jobSelector.getSelectedMeasurementJob() !== null) {
      view = this.renderDataView();
    } else {
      view = this.renderListView();
    }
    return (
      <WrapperContainer>
        {view}
      </WrapperContainer>
    );
  }

  // - - - - - - - - - - - - -
  // Helper methods for create-view

  public actionRequest(req: MeasJobsButtonActions): void {
    if (this.state.actionRequest === null) {
      this.setState({ actionRequest: req });
    } else {
      console.error("Requested new action before previous was served");
    }
  }

  public clearActionRequest(): void {
    this.setState({ actionRequest: null });
  }

  public handleCloseCreateNew(): void {
    const crumbs = [this.state.titleBreadcrumbs[0]];
    this.setState({ titleBreadcrumbs: crumbs, creatingNewJob: false, newJobName: "" });
  }

  public handleSetNameCrumbClick(): void {
    const crumbs = [this.state.titleBreadcrumbs[0]];
    const selectedSet = this.setSelector.getSelectedMeasurementSet();

    if (selectedSet !== null) {
      crumbs[1] = {
        name: selectedSet.displayName,
        link: (): void => undefined,
      };
    }
    this.setState({ titleBreadcrumbs: crumbs, creatingNewJob: false, newJobName: "" });
  }

  public handleJobNameInputEvent(text: string): void {
    const crumbs = [...this.state.titleBreadcrumbs];
    crumbs[2].name = text.length === 0 ? this.text("MeasJobsCreateView", "newJob") : text;
    this.setState({ newJobName: text, titleBreadcrumbs: crumbs });
  }

  public startCreateNewJob(): void {
    const crumbs = [this.state.titleBreadcrumbs[0]];
    const selectedSet = this.setSelector.getSelectedMeasurementSet();

    crumbs[1] = {
      name: selectedSet?.displayName ?? this.text("MeasJobsListView", "measSet"),
      link: (): void => this.handleSetNameCrumbClick(),
    };
    crumbs[2] = {
      name: this.text("MeasJobsCreateView", "newJob"),
      link: (): void => undefined,
    };
    this.setState({ titleBreadcrumbs: crumbs, creatingNewJob: true });
  }

}

export default withDataJanitor(MeasurementJobsWrapper, [
  DataRepositories.MeasurementJob,
  DataRepositories.MeasurementSet,
]);
