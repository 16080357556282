import React, { Component, Fragment } from "react";
import { Box, Popover, Theme, Typography, withTheme } from "@material-ui/core";
import Localization from "data/localization-sensoan/Localization";
import SButton from "components/styled-components/SButton";
import { Nullable } from "types/aliases";
import { CustomSpacingProps } from "types/sensoanUiTypes";

interface Props {
  title: string;
  anchorOrigin?: {
    vertical: "bottom" | "center" | "top" | number ;
    horizontal: "center"| "left" | "right" | number;
  };
  actionButtons?: JSX.Element[];
  theme: Theme;
  m?: CustomSpacingProps;
  mt?: CustomSpacingProps;
  mr?: CustomSpacingProps;
  mb?: CustomSpacingProps;
  ml?: CustomSpacingProps;
}

interface State {
  anchorEl: Nullable<HTMLButtonElement>;
}

class InfoPopover extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <SButton
          labelText={this.props.title}
          color="secondary"
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => this.setState({ anchorEl: event.currentTarget })}
          m={this.props.m}
          mt={this.props.mt}
          mb={this.props.mb}
          ml={this.props.ml}
          mr={this.props.mr}
        />
        <Popover
          open={this.state.anchorEl !== null}
          anchorEl={this.props.anchorOrigin === undefined ? this.state.anchorEl : undefined}
          anchorOrigin={this.props.anchorOrigin}
          onClose={(): void => this.setState({ anchorEl: null })}
        >
          <Box bgcolor={this.props.theme.palette.background.default} p={2}>
            <Typography variant="h5">
              {this.props.title}
            </Typography>
            {this.props.children}
            <Box display="flex" mt={2} justifyContent="flex-end">
              {this.props.actionButtons}
              <SButton m={2}
                labelText={Localization.getInstance().getDisplayText("Actions", "close")}
                color="secondary"
                onClick={(): void => this.setState({ anchorEl: null })}
              />
            </Box>
          </Box>
        </Popover>
      </Fragment>
    );
  }
}

export default withTheme(InfoPopover);
