import { IconStatus, IconTypes, MapLocation, MapMarkerData } from "data/map/MapLink";
import { Maybe, Nullable } from "types/aliases";
import { ChartData } from "components/charts/chartDataTypes";
import Data from "data/data/Data";
import { ChartConfig, DataConfig, DeviceData, DeviceDataTs, MeasurementSetConfig, SensorColorType } from "data/types/measurementSetTypes"; // TODO: remove DeviceData!!
import { getLocation } from "data/utils/meassetUtils";
import { MeasurementJob } from "data/types/measurementJobTypes";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import MeasurementJobRepository from "data/data-storage/MeasurementJobRepository";

interface SortedSensors {
  [deviceId: string]: {
    [sensorName: string]: DataConfig;
  };
}

interface FillInfo {
  [deviceId: string]: {
    [sensorName: string]: {
      leadingSpaces: number;
      mySpace: number;
      trailingSpaces: number;
    };
  };
}

// The MeasurementDataTools utility class assumes data in the method inputs is sorted oldest first
export default class MeasurementDataTools {

  public static getMapMarkerDataForMeasJob(set: MeasurementSetConfig, { endLocation, jobId }: MeasurementJob, selected: () => IconStatus): Nullable<MapMarkerData> {

    if (endLocation) {
      return {
        id: jobId,
        type: IconTypes.measurementJob,
        status: selected,
        location: (): MapLocation => endLocation, //TODO: does functionality to set endLocation exist yet?
      };
    } else {
      const location = getLocation(set);

      if (location !== null) {
        return {
          id: jobId,
          type: IconTypes.measurementJob,
          status: selected,
          location: (): MapLocation => location,
        };
      } else {
        return null;
      }
    }
  }

  /**
  * @param filter An optional array of MeasurementJobs for which to get mapMarkerData.
  */
  public static getMapMarkerDataForAllMeasJobs(filter?: MeasurementJob[]): MapMarkerData[] {
    const mapData: MapMarkerData[] = [];

    if (filter !== undefined) {
      filter.map(job => {
        const set = MeasurementSetRepository.getInstance().getMeasurementSet(job.setId);

        if (set !== null) {
          const mapDataItem = this.getMapMarkerDataForMeasJob(set, job, () => IconStatus.selected);

          if (mapDataItem !== null) {
            return mapData.push(mapDataItem);
          }
        }
      });
    } else {
      MeasurementSetRepository.getInstance().getMeasurementSets().map(set => {
        return MeasurementJobRepository.getInstance().list(set.setId).map(job => {
          const mapDataItem = this.getMapMarkerDataForMeasJob(set, job, () => IconStatus.selected);

          if (mapDataItem !== null) {
            return mapData.push(mapDataItem);
          }
        });
      });
    }
    return mapData;
  }

  public static getMapMarkerDataForMeasSet(set: MeasurementSetConfig, selected: () => IconStatus): Nullable<MapMarkerData> {
    const location = getLocation(set);

    if (location !== null) {
      return {
        id: set.setId,
        type: IconTypes.measurementSet,
        status: selected,
        location: (): MapLocation => location,
      };
    } else {
      return null;
    }
  }

  /**
  * @param filter An optional array of MeasurementSets for which to get mapMarkerData.
  */
  public static getMapMarkerDataForAllMeasSets(filter?: MeasurementSetConfig[]): MapMarkerData[] {
    const mapData: MapMarkerData[] = [];

    if (filter !== undefined) {
      filter.map(set => {
        const mapDataItem = this.getMapMarkerDataForMeasSet(set, () => IconStatus.selected);

        if (mapDataItem !== null) {
          return mapData.push(mapDataItem);
        }
      });
    } else {
      MeasurementSetRepository.getInstance().getMeasurementSets().map(set => {
        const mapDataItem = this.getMapMarkerDataForMeasSet(set, () => IconStatus.selected);

        if (mapDataItem !== null) {
          return mapData.push(mapDataItem);
        }
      });
    }
    return mapData;
  }

  public static getGaugeData(data: Data[], sensorName: string): Nullable<number> {
    for (let x = data.length - 1; x >= 0; x--) {
      if (data[x][sensorName] !== undefined && data[x][sensorName] !== null) {
        return data[x][sensorName] as number;
      }
    }
    return null;
  }

  public static buildLineChartData(data: DeviceDataTs, config: DataConfig[]): ChartData[] {
    console.log("building lineChartData for config:");
    console.log(config);
    console.log("from:");
    console.log(data);

    const configArray = MeasurementDataTools.configHasItemWithEmptySensorData(config, data)
      ?
      MeasurementDataTools.removeItemsWithEmptySensorData(config, data)
      :
      config;

    const sortedSensors = MeasurementDataTools.groupSensorsForDevices(configArray);
    const fillInfo: FillInfo = MeasurementDataTools.buildFillInfo(sortedSensors);

    const chartData: ChartData[] = [];
    let chartDataRow: ChartData = ["timestamp"];

    for (const deviceId in sortedSensors) {
      for (const dataConfig in sortedSensors[deviceId]) {
        chartDataRow.push(sortedSensors[deviceId][dataConfig].sensorDisplayName);
      }
    }

    chartData.push(chartDataRow);

    chartDataRow = [];

    for (const deviceId in sortedSensors) {
      for (const dataConfig in sortedSensors[deviceId]) {
        for (const dataRow of data[deviceId][sortedSensors[deviceId][dataConfig].sensorName]) {
          chartDataRow.push(new Date(dataRow.timestamp));
          MeasurementDataTools.pushSpaces(chartDataRow, fillInfo[deviceId][sortedSensors[deviceId][dataConfig].sensorName].leadingSpaces);

          for (const sensor in sortedSensors[deviceId]) {
            if (dataRow[sortedSensors[deviceId][sensor].sensorName] !== undefined) {

              chartDataRow.push(
                dataRow[sortedSensors[deviceId][sensor].sensorName] as number,
              );
            }
          }

          MeasurementDataTools.pushSpaces(chartDataRow, fillInfo[deviceId][sortedSensors[deviceId][dataConfig].sensorName].trailingSpaces);
          chartData.push(chartDataRow);

          chartDataRow = [];
        }
      }
    }

    return chartData;
  }

  public static buildSteppedAreaChartData(data: DeviceData, config: DataConfig[], aggregateLength: number): ChartData[] {
    console.log("building steppedAreaChartData for config:");
    console.log(config);
    console.log("from:");
    console.log(data);

    const chartData: ChartData[] = [["interval", config[0].sensorDisplayName],
      ...MeasurementDataTools.getAggregatedData(
        data[config[0].deviceId],
        config[0].sensorName,
        aggregateLength * 60,
        Date.now(),
      ).map((value, index): ChartData => [index.toString(), value]),
    ];

    return chartData;
  }

  public static buildChartDataTs(data: Data[], chartConfig: ChartConfig): ChartData[] {
    const chartData: ChartData[] = [];
    let chartDataRow: ChartData = [];
    // use data[0] to add display names to first chartDataRow (every index in data has the same fields)
    chartData.push(MeasurementDataTools.createHeaderRow(data[0], chartDataRow, chartConfig));
    chartDataRow = [];

    data.forEach(dataItem => {
      chartData.push(this.createDataRow(dataItem, chartDataRow, chartConfig));
      chartDataRow = [];
    });
    return chartData;
  }

  public static getSortedSensorColorList(config: DataConfig[]): SensorColorType[] {
    const sortedSensors = MeasurementDataTools.groupSensorsForDevices(config);
    const sensorColorList: SensorColorType[] = [];
    const defaultColorList = Object.values(SensorColorType);
    let defaultColorListIndex = 0;

    for (const deviceId in sortedSensors) {
      for (const dataConfig in sortedSensors[deviceId]) {
        if (sortedSensors[deviceId][dataConfig].sensorColor) {
          sensorColorList.push(sortedSensors[deviceId][dataConfig].sensorColor);
        } else {
          // use all colors in defaultColorList and then start from the beginning
          sensorColorList.push(defaultColorList[defaultColorListIndex]);
          defaultColorListIndex++;

          if (defaultColorListIndex === defaultColorList.length) {
            defaultColorListIndex = 0;
          }
        }
      }
    }
    return sensorColorList;
  }

  /**
   * @param {IData[]} data Inputdata to use for counting aggregate
   * @param {string} sensorName Data item is counted to aggregate if data[i].sensorname is prensent
   * @param {number} intervalLength Interval length (seconds)
   * @param {number} startTime Timestamp (milliseconds) that is used to start counting intervals backwards in time
   * @returns {number[]} The length of the array is the number of intervals found in data. Each number in the array is the
   * aggregated number of times a sensorName datafield is present in the data during interval period. Most recent value is the last index.
   *  */
  public static getAggregatedData(data: Data[], sensorName: string, intervalLength: number, startTime: number): number[] {
    const result: number[] = [];
    let intervalIndex = 0;
    let counter = 0;

    for (let i = data.length - 1; i >= 0; i--) {

      // ignore datapoints that are more recent than startTime
      if (data[i].timestamp <= startTime) {
        if (data[i].timestamp <= startTime - (intervalLength * 1000) * (intervalIndex + 1)) {
          // store result for this interval
          result.unshift(counter);
          // and move to the next interval
          intervalIndex = intervalIndex + 1;
          counter = 0;
        }

        if (data[i][sensorName] !== null && data[i][sensorName] !== undefined) {
          counter = counter + 1;
        }
      }
    }
    return result;
  }

  // Private helper methods
  private static buildFillInfo(sortedSensors: SortedSensors): FillInfo {
    const fillInfo: FillInfo = {};
    let numberOfDataColumns = 0;
    let numberOfNotUsedDataColumns = 0;

    for (const deviceId in sortedSensors) {

      for (const dataConfig in sortedSensors[deviceId]) {

        if (fillInfo[deviceId] === undefined) {
          fillInfo[deviceId] = {};
        }
        fillInfo[deviceId][sortedSensors[deviceId][dataConfig].sensorName] = {
          leadingSpaces: 0,
          mySpace: 1, // sortedSensors[deviceId].length,
          trailingSpaces: 0,
        };
        numberOfDataColumns = numberOfDataColumns + fillInfo[deviceId][sortedSensors[deviceId][dataConfig].sensorName].mySpace;
      }
    }

    numberOfNotUsedDataColumns = numberOfDataColumns;

    for (const deviceId in sortedSensors) {
      for (const dataConfig in sortedSensors[deviceId]) {
        fillInfo[deviceId][sortedSensors[deviceId][dataConfig].sensorName].leadingSpaces = numberOfDataColumns - numberOfNotUsedDataColumns;
        numberOfNotUsedDataColumns = numberOfNotUsedDataColumns - fillInfo[deviceId][sortedSensors[deviceId][dataConfig].sensorName].mySpace;
        fillInfo[deviceId][sortedSensors[deviceId][dataConfig].sensorName].trailingSpaces = numberOfNotUsedDataColumns;
      }
    }

    if (numberOfNotUsedDataColumns !== 0) {
      console.error("Indexing error in MeasurementDataTools.buildFillInfo()");
    }

    return fillInfo;
  }

  private static groupSensorsForDevices(config: DataConfig[]): SortedSensors {
    const sortedSensors: SortedSensors = {};

    for (const dataConfig of config) {
      if (sortedSensors[dataConfig.deviceId] === undefined) {
        sortedSensors[dataConfig.deviceId] = {};
      }
      sortedSensors[dataConfig.deviceId][dataConfig.sensorName] = {
        deviceId: dataConfig.deviceId,
        sensorColor: dataConfig.sensorColor,
        sensorName: dataConfig.sensorName,
        sensorDisplayName: dataConfig.sensorDisplayName,
      };
    }

    return sortedSensors;
  }

  private static pushSpaces(target: ChartData, amount: number): void {
    for (let i = 0; i < amount; i++) {
      target.push(null);
    }
  }

  private static removeItemsWithEmptySensorData(config: DataConfig[], data: DeviceDataTs): DataConfig[] {
    return config.filter(item => data[item.deviceId][item.sensorName].length !== 0);
  }

  private static configHasItemWithEmptySensorData(config: DataConfig[], data: DeviceDataTs): boolean {
    return config.some(item => data[item.deviceId][item.sensorName].length === 0);
  }

  // TODO: replace for - in loop with something that guarantees iteration order on all browsers
  private static createDataRow(dataItem: Data, chartDataRow: ChartData, chartConfig: ChartConfig): ChartData {
    chartDataRow.push(new Date(dataItem.timestamp));

    for (const key in dataItem) {
      // check that only data from current chartConfig is added to chart
      if (this.getPartialTsDataKeysFromChartConfig(chartConfig).some(partialDataKey => key.includes(partialDataKey))) {
        const dataEntry = dataItem[key];

        if (dataEntry !== undefined) {
          chartDataRow.push(dataEntry as number);
        }
      }
    }
    return chartDataRow;
  }

  // TODO: replace for - in loop with something that guarantees iteration order on all browsers
  private static createHeaderRow(dataItem: Data, chartDataRow: ChartData, chartConfig: ChartConfig): ChartData {
    chartDataRow.push("timestamp");

    for (const key in dataItem) {

      const dataConfig = this.findDataConfigWithTsDataKey(key, chartConfig);

      if (dataConfig) {
        chartDataRow.push(dataConfig.sensorDisplayName);
      }
    }
    return chartDataRow;
  }

  private static getPartialTsDataKeysFromChartConfig(chartConfig: ChartConfig): string[] {
    return chartConfig.dataConfig.map(config => this.transformDataConfigToPartialTsDataKey(config));
  }

  private static transformDataConfigToPartialTsDataKey(dataConfig: DataConfig): string {
    const deviceIdWithUnderscores = dataConfig.deviceId.replace(/:/gi, "_");
    return `${deviceIdWithUnderscores}_${dataConfig.sensorName}`;
  }

  private static findDataConfigWithTsDataKey(tsDataKey: string, chartConfig: ChartConfig): Maybe<DataConfig> {
    return chartConfig.dataConfig.find(config => tsDataKey.includes(this.transformDataConfigToPartialTsDataKey(config)));
  }
}
