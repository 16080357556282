import { Nullable } from "types/aliases";
import BaseObservable from "data/observer/BaseObservable";
import { MeasurementJob } from "data/types/measurementJobTypes";

export interface MeasurementJobSelectorObserver {
  onSelectedMeasurementJobChanged: (measurementSet: Nullable<MeasurementJob>) => void;
}

export class MeasurementJobSelector extends BaseObservable<MeasurementJobSelectorObserver> {
  private static instance: MeasurementJobSelector = new MeasurementJobSelector();
  private selectedMeasJob: Nullable<MeasurementJob> = null;

  public static getInstance(): MeasurementJobSelector {
    return this.instance;
  }

  public addObserver(observer: MeasurementJobSelectorObserver): void {
    super.addObserver(observer);
    observer.onSelectedMeasurementJobChanged?.(this.selectedMeasJob);
  }

  public getSelectedMeasurementJob(): Nullable<MeasurementJob> {
    return this.selectedMeasJob;
  }

  public removeObserver(observer: MeasurementJobSelectorObserver): void {
    super.removeObserver(observer);
  }

  public setSelectedMeasurementJob(measJob: Nullable<MeasurementJob>): void {
    this.selectedMeasJob = measJob;
    this.notifyAction(observer => observer.onSelectedMeasurementJobChanged?.(this.selectedMeasJob));
  }
}

export default MeasurementJobSelector;
