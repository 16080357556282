import React, { FunctionComponent, PropsWithChildren, ReactElement } from "react";
import { Grid, List, Paper, Radio, Typography } from "@material-ui/core";
import { OtaUpdate } from "data/ota/OtaTypes";
import Localization from "data/localization-sensoan/Localization";
import SettingsListItem from "../settings-list-item";

interface Props {
  item: OtaUpdate;
  onClick: () => void;
  selected: boolean;
}

function bytesToKb(bytes: number): number {
  return bytes / 1000;
}


const OtaPackageInformationItem: FunctionComponent<Props> = (props: PropsWithChildren<Props>): ReactElement => {
  const text = Localization.getInstance().getDisplayText;

  return (
    <Paper
      className={"ota-package-information-item"}
      onClick={props.onClick}
    >
      <Grid container={true} alignItems={"center"}>
        <Grid item={true} xs={1}>
          <Radio onClick={props.onClick} checked={props.selected}/>
        </Grid>
        <Grid item={true} xs={11}>
          <List>
            <SettingsListItem label={text("DeviceSettings", "version")}>{props.item.firmwareVersion}</SettingsListItem>
            <SettingsListItem label={text("DeviceSettings", "size")}>{bytesToKb(props.item?.size ?? 0).toFixed(2)}</SettingsListItem>
            <SettingsListItem label={text("DeviceSettings", "description")} secondary={<Typography component="span" variant="body2" display="inline"> {props.item.description} </Typography>}/>
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OtaPackageInformationItem;
