import MeasurementJobSelector from "data/measurement-job-selector/MeasurementJobSelector";
import MeasurementSetSelector from "data/measurement-set-selector/MeasurementSetSelector";
import DeviceNavigationCache from "utils/DeviceNavigationCache";

export async function clearAllSelectorsWithValue(): Promise<void> {
  if (MeasurementSetSelector.getInstance().getSelectedMeasurementSet() !== null) {
    MeasurementSetSelector.getInstance().setSelectedMeasurementSet(null);
  }

  if (MeasurementJobSelector.getInstance().getSelectedMeasurementJob() !== null) {
    MeasurementJobSelector.getInstance().setSelectedMeasurementJob(null);
  }

  if (DeviceNavigationCache.getInstance().getSelectedDevice() !== undefined) {
    await DeviceNavigationCache.getInstance().setCurrentDevice(undefined);
  }

  if (DeviceNavigationCache.getInstance().getSelectedGroup() !== undefined) {
    await DeviceNavigationCache.getInstance().setCurrentGroup(undefined);
  }
}

