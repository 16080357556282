/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component, ReactNode } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CssBaseline, MuiThemeProvider, Theme } from "@material-ui/core";
import ThemeSelector, { ThemeSelectorObserver } from "./data/theme/ThemeSelector";
import IconGradientProvider from "./components/utils/IconGradientProvider";
import Gradients, { IconGradient } from "./data/theme/PaletteColors/Gradients";
import LoginView from "components/login-view";
import AppLayout from "components/app-layout/AppLayout";
import PathsSensoan from "data/paths/PathsSensoan";
import Gatekeeper from "components/access-control/gatekeeper";

interface State {
  theme: Theme;
}

interface Props {}

class App extends Component<Props, State> implements ThemeSelectorObserver {

  public constructor(props: Props) {
    super(props);
    this.state = {
      theme: ThemeSelector.getInstance().getSelectedTheme(),
    };
    ThemeSelector.getInstance().addObserver(this);
  }

  public onSelectedThemeChanged(theme: Theme): void {
    this.setState({ theme });
  }

  private renderIconGradientProviders(): JSX.Element[] {
    return Gradients.getIconGradientsForProviders(this.state.theme).map((gradient: IconGradient) => {
      return <IconGradientProvider
        colorStopFirst={gradient.colorStopFirst}
        colorStopSecond={gradient.colorStopSecond}
        key={gradient.name}
        name={gradient.name}
      />;
    });
  }

  private renderWithGatekeeper(authenticatedComponent: ReactNode, redirectPath: PathsSensoan, redirectIfAuthenticated?: boolean): JSX.Element {
    return (
      <Gatekeeper
        redirectIfAuthenticated={redirectIfAuthenticated}
        redirectPath={redirectPath}
      >
        {authenticatedComponent}
      </Gatekeeper>
    );
  }


  public render(): ReactNode {
    return (
      <BrowserRouter>
        <MuiThemeProvider theme={this.state.theme}>
          <CssBaseline/>
          <Switch>
            <Route
              path={PathsSensoan.LOGIN}
              component={(): JSX.Element => this.renderWithGatekeeper(<LoginView />, PathsSensoan.ROOT, true)}
            />
            <Route
              path={PathsSensoan.ROOT}
              render={(): JSX.Element => this.renderWithGatekeeper(<AppLayout />, PathsSensoan.LOGIN)}
            />
          </Switch>
          {this.renderIconGradientProviders()}
        </MuiThemeProvider>
      </BrowserRouter>
    );
  }
}

export default App;
