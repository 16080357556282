import React, { Component, ReactNode } from "react";
import { Dialog, Tab, Tabs } from "@material-ui/core";
import Device, { DeviceObserver } from "data/device/Device";
import { getDisplayName } from "data/utils/Utils";
import DeviceState from "data/device/DeviceState";
import { DeviceStateProperties } from "data/device/DeviceStateProperties";
import Localization from "data/localization-sensoan/Localization";
import { Nullable } from "types/aliases";
import { RuuviGWHW } from "client/devices/RuuviGWHW/RuuviGWHW";
import { HyperHW } from "client/devices/HyperHW/HyperHW";
import { SuperHW } from "client/devices/SuperHW/SuperHW";
import SettingsPageAWSIoTJobOta from "./components/settings-page-ota/settings-page-aws-iot-job-ota";
import SettingsPageWhitelist from "./components/settings-page-whitelist";
import TabPanel from "components/ui/tab-panel";
import SettingsPageGeneral from "./components/settings-page-general";
import SettingsPageOta from "./components/settings-page-ota";
import SettingsPageAttributes from "./components/settings-page-attributes";
import { SereneHW } from "client/devices/SereneHW/SereneHW";
import { PiikkioHW } from "client/devices/PiikkioHW/PiikkioHW";
import { MLDemoHW } from "client/devices/MLDemoHW/MLDemoHW";

interface Props {
  selectedDevice: Device;
  closeSettings: () => void;
}

interface State {
  deviceState: Nullable<DeviceState<DeviceStateProperties>>;
  settingsPage: number;
}

export default class DeviceSettingsPopup extends Component<Props, State> implements DeviceObserver {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      deviceState: this.props.selectedDevice.getState(),
      settingsPage: 0,
    };
  }

  public componentDidMount(): void {
    this.props.selectedDevice.addObserver(this);
  }

  public componentWillUnmount(): void {
    this.props.selectedDevice.removeObserver(this);
  }

  public onDeviceStateUpdated(device: Device): void {
    const deviceState = device.getState();
    this.setState({
      deviceState: deviceState,
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private handlePageChange = (event: React.ChangeEvent<{}>, value: number): void => {
    this.setState({ settingsPage: value });
  };

  private renderGeneralPage(): ReactNode {
    if (this.state.deviceState) {
      return (
        <SettingsPageGeneral
          deviceState={this.state.deviceState}
          deviceType={this.props.selectedDevice.getType()}
          closeSettings={this.props.closeSettings}
        />
      );
    }
  }

  private renderOtaPage(): ReactNode {
    const { selectedDevice, closeSettings } = this.props;

    if (selectedDevice instanceof HyperHW ||
      selectedDevice instanceof SuperHW) {
      const { deviceState } = this.state;

      if (deviceState) {
        return (
          <SettingsPageOta
            deviceState={deviceState}
            onCloseSettings={closeSettings}
          />
        );
      }
    } else if (selectedDevice instanceof RuuviGWHW ||
      selectedDevice instanceof SereneHW ||
      selectedDevice instanceof PiikkioHW ||
      selectedDevice instanceof MLDemoHW) {
      return (
        <SettingsPageAWSIoTJobOta
          device={selectedDevice}
          closeSettings={closeSettings}
        />
      );
    }
  }

  private renderWhitelistPage(): ReactNode {
    return (
      <>
        { this.props.selectedDevice instanceof RuuviGWHW ?
          <SettingsPageWhitelist
            device={this.props.selectedDevice}
            closeSettings={this.props.closeSettings}
          />
          : null }
      </>
    );
  }

  public render(): JSX.Element {
    const isDeviceWithOta = this.props.selectedDevice instanceof HyperHW ||
    this.props.selectedDevice instanceof SuperHW ||
    this.props.selectedDevice instanceof RuuviGWHW ||
    this.props.selectedDevice instanceof SereneHW ||
    this.props.selectedDevice instanceof PiikkioHW ||
    this.props.selectedDevice instanceof MLDemoHW;
    const isDeviceWithWhitelist = this.props.selectedDevice instanceof RuuviGWHW;

    return (
      <Dialog
        maxWidth={"md"}
        onClose={this.props.closeSettings}
        open={true}
      >
        <div className="popup-inner">
          <div className="popup-header">
            <div className="popup-header-device-name">
              <img className="device-icon" alt="Device type" src={this.props.selectedDevice.getIcon()} />
              {getDisplayName(this.props.selectedDevice)}
            </div>
            <div className="popup-header-device-type">
              {this.props.selectedDevice.getType()}
            </div>
            <div className="popup-header-secondary-info">
              {this.props.selectedDevice.getId()}
            </div>
          </div>

          <div className="popup-body">
            <Tabs
              value={this.state.settingsPage}
              onChange={this.handlePageChange}
              centered={true}
            >
              <Tab label={this.text("DeviceSettings", "general")} />
              <Tab label={this.text("DeviceSettings", "attributes")} />
              {isDeviceWithOta && <Tab label={this.text("DeviceSettings", "otaUpdate")} />}
              {isDeviceWithWhitelist && <Tab label={this.text("DeviceSettings", "whiteList")}/>}
            </Tabs>
            <TabPanel
              value={this.state.settingsPage}
              index={0}
              retainChildrenWhenInvisible={true}
            >
              {this.renderGeneralPage()}
            </TabPanel>
            <TabPanel
              value={this.state.settingsPage}
              index={1}
              retainChildrenWhenInvisible={true}
            >
              <SettingsPageAttributes
                selectedDevice={this.props.selectedDevice}
                onCloseSettings={this.props.closeSettings}
              />
            </TabPanel>
            {isDeviceWithOta && <TabPanel
              value={this.state.settingsPage}
              index={2}
              retainChildrenWhenInvisible={true}
            >
              {this.renderOtaPage()}
            </TabPanel>}
            {isDeviceWithWhitelist && <TabPanel
              value={this.state.settingsPage}
              index={3}
              retainChildrenWhenInvisible={true}
            >
              {this.renderWhitelistPage()}
            </TabPanel>}
          </div>
        </div>
      </Dialog>
    );
  }
}
