import { Nullable } from "types/aliases";
import MeasurementJobSelector from "data/measurement-job-selector/MeasurementJobSelector";
import SensoanBackend from "data/backend/SensoanBackend";
import { MeasurementJob } from "data/types/measurementJobTypes";
import { MeasurementSetConfig } from "data/types/measurementSetTypes";
import DataRepository from "./DataRepository";
import MeasurementSetRepository from "./MeasurementSetRepository";
import AppSyncClientFactory from "data/backend/AppSyncClientFactory";
import { MeasurementJobsUpdateFeedDocument } from "generated/gqlMeassets";
import { Service } from "data/backend/AppSyncClientProvider";
import BaseObservable from "data/observer/BaseObservable";
import AsyncCache from "data/utils/AsynCache";

export interface MeasurementJobObserver {
  onMeasurementJobUpdated?(setId: string, jobId: string): void;
}

// TODO: subscription should be harmonized with other subscriptions so that all subscriptions are handled with
// AbstractSubscriptionManager
export default class MeasurementJobRepository extends BaseObservable<MeasurementJobObserver> implements DataRepository {

  private static instance = new MeasurementJobRepository();
  private readonly initQueue = new AsyncCache();

  private initialized = false;
  private measurementJobs: { [setId: string]: MeasurementJob[] } = {};
  private subscriptions: ZenObservable.Subscription[] = [];

  //-------------------------
  // Public methods
  //

  public async add(measurementJob: MeasurementJob): Promise<number> {
    const match = this.measurementJobs[measurementJob.setId]?.findIndex((job) => job.jobId === measurementJob.jobId);

    if (match === undefined && match > -1) {
      console.error("Trying to add a measurement job with non-unique id");
      return -1;
    }

    const resp = await SensoanBackend.getInstance().measurementJobsAdd(measurementJob);

    if (resp !== undefined) {
      this.measurementJobs[measurementJob.setId].push(measurementJob);
      MeasurementJobSelector.getInstance().setSelectedMeasurementJob(measurementJob);
      return 0;
    }
    else {
      return -1;
    }
  }

  public addObserver(observer: MeasurementJobObserver): void {
    super.addObserver(observer);
  }

  public async delete(setId: string, jobId: string): Promise<number> {
    const match = this.measurementJobs[setId]?.findIndex((job) => job.jobId === jobId);

    if (match === undefined || match < 0) {
      console.error("Trying to delete a non-existing measurement job");
      return -1;
    }

    const resp = await SensoanBackend.getInstance().measurementJobsDelete(setId, jobId);

    if (resp !== undefined) {
      this.measurementJobs[setId] = this.measurementJobs[setId].filter((job) => job.jobId !== jobId);
      MeasurementJobSelector.getInstance().setSelectedMeasurementJob(null);
      return 0;
    }
    else {
      return -1;
    }
  }

  public async detailsEdit(setId: string, jobId: string, metadata: string): Promise<number> {
    const match = this.measurementJobs[setId]?.findIndex((job) => job.jobId === jobId);

    if (match === undefined || match < 0) {
      console.error("Trying to edit a non-existing measurement job");
      return -1;
    }

    const resp = await SensoanBackend.getInstance().measurementJobsDetailsEdit(setId, jobId, metadata);

    if (resp !== undefined) {
      this.measurementJobs[setId][match].metadata = metadata;
      return 0;
    }
    else {
      return -1;
    }
  }

  public list(setId: string): MeasurementJob[] {
    if (this.measurementJobs[setId] !== undefined) {
      return this.measurementJobs[setId];
    } else {
      return [];
    }
  }

  public removeObserver(observer: MeasurementJobObserver): void {
    super.removeObserver(observer);
  }

  public getMeasurementJob(jobId: string): Nullable<MeasurementJob> {
    const allJobs = Object.values(this.measurementJobs).flatMap(jobs => jobs);
    const foundJob = allJobs.find(job => job.jobId === jobId);

    if (foundJob !== undefined) {
      return foundJob;
    } else {
      console.log("MeasurementJob not found");
      return null;
    }
  }

  public getMeasurementJobs(): MeasurementJob[] {
    return Object.values(this.measurementJobs).flatMap(jobs => jobs);
  }

  public async start(setId: string, jobId: string): Promise<number> {
    const match = this.measurementJobs[setId]?.findIndex((job) => job.jobId === jobId);

    if (match === undefined || match < 0) {
      console.error("Trying to start a non-existing measurement job");
      return -1;
    }
    const job = this.measurementJobs[setId][match];

    if (job.startTs !== undefined && job.startTs !== null && job.startTs < Date.now()) {
      console.error("Trying to start a measurement job that has already started");
      return -1;
    }

    const resp = await SensoanBackend.getInstance().measurementJobsStart(setId, jobId);

    if (resp !== undefined) {
      this.measurementJobs[setId][match].startTs = resp.startTs;
      MeasurementJobSelector.getInstance().setSelectedMeasurementJob(this.measurementJobs[setId][match]);
      return 0;
    }
    else {
      return -1;
    }
  }

  public async stop(setId: string, jobId: string): Promise<number> {
    const match = this.measurementJobs[setId]?.findIndex((job) => job.jobId === jobId);
    console.log("setId", setId, "jobId", jobId);
    console.log("jobs in repo", this.measurementJobs);


    if (match === undefined || match < 0) {
      console.error("Trying to stop a non-existing measurement job");
      return -1;
    }
    const job = this.measurementJobs[setId][match];

    if (job.endTs !== undefined && job.endTs !== null && job.endTs < Date.now()) {
      console.error("Trying to stop a measurement job that has already stopped");
      return -1;
    }

    const resp = await SensoanBackend.getInstance().measurementJobsStop(setId, jobId);

    if (resp !== undefined) {
      this.measurementJobs[setId][match].endTs = resp.endTs;
      MeasurementJobSelector.getInstance().setSelectedMeasurementJob(this.measurementJobs[setId][match]);
      return 0;
    }
    else {
      return -1;
    }
  }

  // ------------------------
  // DataRepository interface
  //
  public static getInstance(): MeasurementJobRepository {
    return this.instance;
  }

  public getName(): string {
    return "measurementJobs";
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  public async init(): Promise<void> {
    const initRepo = async (): Promise<void> => {
      const setRepo = MeasurementSetRepository.getInstance();

      if (!setRepo.isInitialized()) {
        await setRepo.init();
      }

      const sets: MeasurementSetConfig[] = setRepo.getMeasurementSets();
      await Promise.all(sets.map((set: MeasurementSetConfig): Promise<void> => this.fetchJobs(set.setId)));

      this.subscriptions.push(this.subscribe());
      this.initialized = true;
    };
    return this.initQueue.get("initMeasJobsRepo", initRepo);
  }

  public clear(): void {
    this.measurementJobs = {};
    this.subscriptions.map((s: ZenObservable.Subscription) => {
      s.unsubscribe();
    });
    this.initQueue.clear();
    this.initialized = false;
  }

  // ----------------------
  // Private methods
  //
  private constructor() {
    super();
  }

  private async fetchJobs(setId: string): Promise<void> {
    const jobs = await SensoanBackend.getInstance().measurementJobsList(setId);
    this.measurementJobs[setId] = jobs;
  }

  private validateAccess(setId: string): boolean {
    if (MeasurementSetRepository.getInstance().getMeasurementSet(setId) !== null) {
      return true;
    } else {
      return false;
    }
  }

  private remove(setId: string, jobId: string): void {
    if (this.measurementJobs[setId]) {
      this.measurementJobs[setId] = this.measurementJobs[setId].filter((job) => job.jobId !== jobId);
      this.notifyAction(observer => observer.onMeasurementJobUpdated?.(setId, jobId));
    }
  }

  private update(updatedJob: MeasurementJob): void {
    if (this.measurementJobs[updatedJob.setId]) {
      const index = this.measurementJobs[updatedJob.setId].findIndex((job) => job.jobId === updatedJob.jobId);

      if (index < 0) {
        this.measurementJobs[updatedJob.setId].push(updatedJob);
      } else {
        this.measurementJobs[updatedJob.setId][index] = updatedJob;
      }
      this.notifyAction(observer => observer.onMeasurementJobUpdated?.(updatedJob.setId, updatedJob.jobId));
    }
  }

  private subscribe(): ZenObservable.Subscription {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASSET);
    return client.subscribe(
      MeasurementJobsUpdateFeedDocument,
      {
      },
    ).subscribe({
      // TODO: Fix any type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: (error: any): void => {
        if (error.errorMessage === "AMQJS0008I Socket closed.") {
          console.log("Reconnecting socket");
          this.subscriptions.push(this.subscribe());
        }
        console.error(error);
      },
      next: (update): void => {
        console.log(update);

        if (update.data?.measurementJobsUpdateFeed) {
          const newImage = update.data.measurementJobsUpdateFeed.newImage;
          const oldImage = update.data.measurementJobsUpdateFeed.oldImage;

          if (newImage && newImage.setId !== null) {
            if (this.validateAccess(newImage.setId as string)) {
              console.log("MeasJobs Access ok");

              this.update(newImage as MeasurementJob);
            } else {
              console.error("MeasJobs Access nok");
            }
          } else if (oldImage && oldImage.setId !== null) {
            if (this.validateAccess(oldImage.setId as string)) {
              console.log("MeasJobs Access ok");
              this.remove(oldImage.setId as string, oldImage.jobId as string);
            } else {
              console.error("MeasJobs Access nok");
            }
          } else {
            console.error("Data error in MeasurementJobsFeed");
          }
        }
      },
    });
  }

}
