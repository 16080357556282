import React, { Component, Fragment, ReactNode } from "react";
import { Box, Divider, Link, Theme, Typography, withTheme } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Device, { DeviceObserver } from "data/device/Device";
import Localization from "data/localization-sensoan/Localization";
import { getDisplayName } from "data/utils/Utils";
import LatestDeviceDataRepository from "data/data-storage/LatestDeviceDataRepository";
import DeviceGroup from "data/device/DeviceGroup";
import { Maybe, Nullable } from "types/aliases";
import { LocationInfo } from "types/sensoanUiTypes";
import DeviceSettingsPopup from "components/device-settings/device-settings-popup";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import { SECTION_CONTENT_BOTTOM_PADDING, SECTION_CONTENT_TOP_PADDING,
  SECTION_TITLE_BOTTOM_PADDING, SECTION_TITLE_TOP_PADDING } from "components/layout/Section";
import AdminSIconButton from "components/styled-components/AdminSIconButton";
import SIconButton from "components/styled-components/SIconButton";
import { assertNever } from "utils/functions";
import ActivityLabelingPopup from "./ActivityLabelingPopup";
import { PiikkioHW } from "client/devices/PiikkioHW/PiikkioHW";
import { MLDemoHW } from "client/devices/MLDemoHW/MLDemoHW";

interface Props {
  device: Device;
  deviceGroups: DeviceGroup[];
  location: Nullable<LocationInfo>;
  open: boolean;
  theme: Theme;
  toggleOpen: () => void;
}

interface State {
  popupState: Nullable<DeviceInfoSectionPopupState>;
  deviceDisplayName: Nullable<string>;
}

export const DEVICE_INFO_SECTION_ROW_HEIGHT = "3rem";

enum DeviceInfoSectionPopupState {
  DEVICE_SETTINGS,
  ACTIVITY_LABELING,
}

/* This is essentially a copy of <Section /> but has custom functionality which is needed to
make DeviceWindow function properly */
class DeviceInfoSection extends Component<Props, State> implements DeviceObserver {
  private text = Localization.getInstance().getDisplayText;

  public constructor (props: Props) {
    super(props);
    this.state = {
      popupState: null,
      deviceDisplayName: null,
    };
  }

  public componentDidMount(): void {
    this.props.device.addObserver(this);
    this.setState({ deviceDisplayName: getDisplayName(this.props.device) });
  }

  public componentWillUnmount(): void {
    this.props.device.removeObserver(this);
  }

  public onDeviceStateUpdated(device: Device): void {
    if (device.getId() === this.props.device.getId()) {
      this.setState({
        deviceDisplayName: getDisplayName(this.props.device),
      });
    }
  }

  private isMeasurementJobDataLoggingDeviceType(device: Device): boolean {
    return device instanceof PiikkioHW || device instanceof MLDemoHW;
  }
  
  private renderPopup(): Maybe<JSX.Element> {
    if (this.state.popupState !== null) {
      switch (this.state.popupState) {
        case DeviceInfoSectionPopupState.DEVICE_SETTINGS:
          return (
            <DeviceSettingsPopup
              selectedDevice={this.props.device}
              closeSettings={(): void => this.setState({ popupState: null })}
            />
          );
      
        case DeviceInfoSectionPopupState.ACTIVITY_LABELING:
          return (
            <ActivityLabelingPopup
              openedTs={Date.now()}
              selectedDevice={this.props.device}
              close={(): void => this.setState({ popupState: null })}
            />
          );
      
        default:
          assertNever(this.state.popupState);
      }
    }
  }

  private getLocationText(locationInfo: LocationInfo): string {
    if (typeof locationInfo === "function") {
      return locationInfo();
    } else {
      return locationInfo.title;
    }
  }

  private renderToggler(): ReactNode {
    const { open, toggleOpen } = this.props;

    return (
      <Link style={{ margin: "auto", marginRight: 0 }} onClick={toggleOpen}>
        <Box pr={2} color="text.primary">
          <SSvgIcon color={SSvgIconColorProps.textPrimary} iconComponent={open ? ExpandLessIcon : ExpandMoreIcon }/>
        </Box>
      </Link>
    );
  }

  private renderTitle(): JSX.Element {
    return (
      <Box
        pt={SECTION_TITLE_TOP_PADDING}
        pb={SECTION_TITLE_BOTTOM_PADDING}
        display="flex"
        flexWrap="wrap"
      >
        <Typography variant="h6" color="textPrimary">
          {this.text("Common", "generalInfo")}
        </Typography>
        {this.renderToggler()}
        <Divider/>
      </Box>
    );
  }

  private renderContent(): ReactNode {
    const { open, location, deviceGroups, device } = this.props;
    const latestDataTimestamp = LatestDeviceDataRepository.getInstance().getLatestData(device.getId())?.getData()?.timestamp;
    return (
      open
      &&
        <Box
          pt={SECTION_CONTENT_TOP_PADDING}
          pb={SECTION_CONTENT_BOTTOM_PADDING}
        >
          <Box display="flex" height={DEVICE_INFO_SECTION_ROW_HEIGHT} alignItems="center">
            <Box width="50%">
              <Typography variant="body1" color="textSecondary">
                {this.text("DeviceWindow", "displayName")}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" color="textPrimary">
                {this.state.deviceDisplayName}
              </Typography>
              <AdminSIconButton
                onClick={(): void => this.setState({ popupState: DeviceInfoSectionPopupState.DEVICE_SETTINGS })}
                tooltipText={this.text("Common", "openDeviceSettings")}
                showAccessError={false}
                showLoader={false}
              >
                <SSvgIcon color={SSvgIconColorProps.blueGradient} iconComponent={SettingsIcon}/>
              </AdminSIconButton>
              {this.isMeasurementJobDataLoggingDeviceType(this.props.device)
              && <SIconButton
                onClick={(): void => this.setState({ popupState: DeviceInfoSectionPopupState.ACTIVITY_LABELING })}
                tooltipText={this.text("DeviceWindow", "openActivityLabeling")}
              >
                <SSvgIcon color={SSvgIconColorProps.greenGradient} iconComponent={BookmarkIcon}/>
              </SIconButton>}
            </Box>
          </Box>
          {/* Latest reading row */}
          <Box display="flex" height={DEVICE_INFO_SECTION_ROW_HEIGHT} alignItems="center">
            <Box width="50%">
              <Typography variant="body1" color="textSecondary">
                {this.text("Common", "latestReading")}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" color="textPrimary">
                {latestDataTimestamp !== undefined ? Localization.getInstance().getDateAndTimeString(latestDataTimestamp) : this.text("Common", "notAvailable")}
              </Typography>
            </Box>
          </Box>
          {/* Groups row */}
          <Box display="flex" height={DEVICE_INFO_SECTION_ROW_HEIGHT} alignItems="center">
            <Box width="50%">
              <Typography variant="body1" color="textSecondary">
                {this.text("DeviceWindow", deviceGroups.length > 1 ? "deviceGroups" : "deviceGroup")}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" color="textPrimary">
                {deviceGroups.map(group => group.getLabel()).join(", ")}
              </Typography>
            </Box>
          </Box>
          {/* Location row */}
          <Box display="flex" height={DEVICE_INFO_SECTION_ROW_HEIGHT} alignItems="center">
            <Box width="50%">
              <Typography variant="body1" color="textSecondary">
                {this.text("Common", "location")}
              </Typography>
            </Box>
            <Box width="50%">
              <Typography variant="body1" color="textPrimary">
                {location !== null && this.getLocationText(location)}
              </Typography>
            </Box>
          </Box>
        </Box>
    );
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.renderPopup()}
        {this.renderTitle()}
        {this.renderContent()}
      </Fragment>
    );
  }
}
export default withTheme(DeviceInfoSection);
