/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import moment from "moment";
import Device from "../device/Device";
import DeviceGroup from "../device/DeviceGroup";
import { Voidable } from "../../types/aliases";
import { GraphQLError } from "graphql/error/GraphQLError";
import { HyperHWState } from "client/devices/HyperHW/HyperHWState";
import { HyperHWStateProperties } from "client/devices/HyperHW/HyperHWStateProperties";
import { SuperHWState } from "client/devices/SuperHW/SuperHWState";
import { DeviceStateProperties } from "data/device/DeviceStateProperties";
import DeviceState from "data/device/DeviceState";
import { RuuviGWHWStateProperties } from "client/devices/RuuviGWHW/RuuviGWHWStateProperties";
import { SereneHWState } from "client/devices/SereneHW/SereneHWState";
import { PiikkioHWState } from "client/devices/PiikkioHW/PiikkioHWState";

const HOUR_AS_MILLISECONDS: number = 60 * 60 * 1000;
const MINUTE_AS_MILLISECONDS: number = 60 * 1000;

type StopMethod = () => void;

export type HyperLikeState = HyperHWState | SereneHWState | PiikkioHWState;

/* Added in Sensoan BF-2020 */
export function convertHoursToTimestamp(hours: number): number {
  return HOUR_AS_MILLISECONDS * hours;
}

// For sorting device names ending with a number (so that e.g. "Device 2" shows up before "Device 10")
function compareDeviceNamesAsNumbers(deviceName1: string, deviceName2: string): number {

  try {
    // Split up the strings to compare into tokens
    const tokens1 = deviceName1.split(" ");
    const tokens2 = deviceName2.split(" ");

    // We only care about strings with equal number of tokens
    if (tokens1.length > 0 && tokens1.length === tokens2.length) {

      // Compare the all the other tokens except the last one...
      let tokenComparisons = 0;
      tokens1.forEach((token1: string, index: number) => {
        if (index < tokens1.length - 1) {
          tokenComparisons += Math.abs(token1.localeCompare(tokens2[index]));
        }
      });

      // If all other tokens were equal, we compare the final tokens
      if (tokenComparisons === 0) {

        // ...and compare them as numbers, if applicable
        const number1 = Number(tokens1[tokens1.length - 1].replace("#", ""));
        const number2 = Number(tokens2[tokens2.length - 1].replace("#", ""));

        // One or both of the tokens are not a number => rely on "normal" comparison
        if (isNaN(number1) || isNaN(number2)) {
          return 0;
        }

        return number1 < number2 ? -1 : 1;
      }
    }
  } catch (error) {
    console.log("Error in compareDeviceNamesAsNumbers", error);
  }
  return 0;
}

export enum DateTimeFormatTarget {
  ChartTimeAxis,
  ChartTooltip,
  ShadowUpdate,
  StatusTable,
  EventsTable,
}

export enum DrawerState {
  Closed,
  Open,
  Full,
}

export function getDisplayName(device: Voidable<Device>): string {
  if (device) {
    const deviceState = device.getState();

    if (deviceState && deviceState.displayName) {
      return deviceState.displayName;
    }
    return device.getId();
  }
  return "N/A";
}

export function getDateTimeFormat(target: DateTimeFormatTarget): string {
  switch (target) {
    case DateTimeFormatTarget.ChartTimeAxis:
      return "d/M HH:mm";
    case DateTimeFormatTarget.ChartTooltip:
      return "d/M/yyyy HH:mm:ss";
    case DateTimeFormatTarget.StatusTable:
    case DateTimeFormatTarget.EventsTable:
      return "DD/MM/YYYY HH:mm:ss";
    case DateTimeFormatTarget.ShadowUpdate:
      return "D/M/Y HH:mm:ss";
  }
}

export function numberToFixed(value: number, decimalCount: number): string {
  if (isNaN(value) || value == null) {
    return "N/A";
  }
  return value.toFixed(decimalCount);
}

export function convertTimestampToString(timestamp: number, formatTarget: DateTimeFormatTarget): string {
  return moment(timestamp).format(getDateTimeFormat(formatTarget));
}

export function convertStringToTimestamp(dateTime: string, inputFormat: DateTimeFormatTarget): number {
  return moment(dateTime, getDateTimeFormat(inputFormat)).unix();
}

export function millisecondsToHoursAndMinutes(milliseconds: number): string {
  const hours: number = Math.floor(milliseconds / HOUR_AS_MILLISECONDS);
  const minutes: number = Math.floor((milliseconds % HOUR_AS_MILLISECONDS) / MINUTE_AS_MILLISECONDS);

  if (hours > 0) {
    return hours + " h " + minutes + " min";
  }
  return minutes + " min";
}

export function compareDevicesForSorting(first: Device, second: Device): number {
  const firstName = getDisplayName(first);
  const secondName = getDisplayName(second);
  const numberComparison = compareDeviceNamesAsNumbers(firstName, secondName);

  if (numberComparison === 0) {
    return firstName.localeCompare(secondName);
  } else {
    return numberComparison;
  }
}

export function compareGroupsForSorting(first: DeviceGroup, second: DeviceGroup): number {
  return first.getId().localeCompare(second.getId());
}

export function timestampToMilliseconds(timestamp: number): number {
  let millisecTimestamp = timestamp;

  if (`${timestamp}`.length < 13) {
    // timestamp seems to be expressed as seconds => convert to millisec
    millisecTimestamp = timestamp * 1000;
  }
  return millisecTimestamp;
}

export function isTimestampWithinTimeoutBounds(timestamp: number, timeoutMs: number): boolean {
  const deltaMs = Date.now() - timestampToMilliseconds(timestamp);
  return deltaMs < timeoutMs;
}

export function startInterval(callback: (stop: StopMethod) => void, interval: number): StopMethod {
  let id = -1;
  const stop = (): void => window.clearInterval(id);
  id = window.setInterval(callback, interval, stop);
  return stop;
}

export function startTimeout(callback: (stop: StopMethod) => void, timeout: number): StopMethod {
  let id = -1;
  const stop = (): void => window.clearTimeout(id);
  id = window.setTimeout(callback, timeout, stop);
  return stop;
}

export function throwGQLError(response: {errors?: Array<GraphQLError> | ReadonlyArray<GraphQLError>}, fallback = "Unexpected error"): never {
  if (!response.errors || response.errors.length === 0) {
    throw new Error(fallback);
  } else {
    const error = response.errors[0]; // for now, ignore all the other errors
    const msg = `${error.name}: ${error.message}${error.originalError ? "( " + error.originalError.message + " )" : ""}`;
    throw new Error(msg);
  }
}

/**
 * Verifies that value is an object.
 * @param {unknown} value Potential object
 * @returns {boolean} result
 */
export function isObject(value: unknown): value is {[k: string]: unknown} {
  return typeof value === "object" && value != null && !Array.isArray(value);
}

export function getDeviceStateAsSuper(state: DeviceState<DeviceStateProperties>): Partial<SuperHWState> {
  return state as Partial<SuperHWState>;
}

/* TODO: should this return Partial<HyperHWState> ? */
export function getDeviceStateAsHyper(state: DeviceState<DeviceStateProperties>): Partial<HyperHWStateProperties> {
  return state as Partial<HyperHWStateProperties>;
}

export function stateIsSuper(state: unknown): state is SuperHWState {
  return state instanceof SuperHWState;
}

export function stateIsHyper(state: unknown): state is HyperHWState {
  return state instanceof HyperHWState;
}

export function stateIsSerene(state: unknown): state is SereneHWState {
  return state instanceof SereneHWState;
}

export function stateIsHyperLike(state: unknown): state is HyperHWState | PiikkioHWState | SereneHWState {
  return stateIsHyper(state) || stateIsSerene(state) || state instanceof PiikkioHWState;
}

/* TODO: should this return Partial<RuuviGWHWState> ?  */
export function getDeviceStateAsRuuviGW(state: DeviceState<DeviceStateProperties>): Partial<RuuviGWHWStateProperties> {
  return state as Partial<RuuviGWHWStateProperties>;
}

export function getDeviceStateAsSereneHW(state: DeviceState<DeviceStateProperties>): Partial<SereneHWState> {
  return state as Partial<SereneHWState>;
}

export function getDeviceStateAsPiikkioHW(state: DeviceState<DeviceStateProperties>): Partial<PiikkioHWState> {
  return state as Partial<PiikkioHWState>;
}
