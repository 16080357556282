import React, { Component, Fragment, ReactNode } from "react";
import { Box, Divider, Theme, withTheme } from "@material-ui/core";

type CommonJustifyContentValues = "space-between" | "space-around" | "space-evenly" | "center" | "start" | "end";
interface Props {
  dividerFadeEffect?: boolean;
  theme: Theme;
  justifyContent?: CommonJustifyContentValues;
  width?: string;
  dividerWidth?: string;
  hideDivider?: boolean;
}

/* This is needed outside of this component */
export const IOT_DATA_ROW_HEIGHT = "4.5rem";

class IoTDataRow extends Component<Props> {
  public render(): ReactNode {
    return (
      <Fragment>
        <Box
          alignItems="center"
          justifyContent={this.props.justifyContent}
          display="flex"
          height={IOT_DATA_ROW_HEIGHT}
          width={this.props.width}
        >
          {this.props.children}
        </Box>
        {!this.props.hideDivider && <Divider style={{ background: this.props.dividerFadeEffect ? this.getDividerFadeEffect() : undefined, width: this.props.dividerWidth }}/>}
      </Fragment>
    );
  }

  private getDividerFadeEffect(): string {
    return `linear-gradient(90deg, transparent 0%, ${this.props.theme.palette.divider} 10%)`;
  }
}

export default withTheme(IoTDataRow);
