import { Maybe } from "types/aliases";
import { Themes } from "../themes";
import * as OverrideColors from "../OverrideColors/OverrideColors";

interface StylePropWithPseudoSelector {
  [property: string]: string | number | {
    [pseudoSelector: string]: string | number; 
  }; 
}

export default class Input {

  public static getInputStyle(themeName: Themes): Maybe<StylePropWithPseudoSelector> {
    if (themeName === Themes.light_V2 || themeName === Themes.dark_V2) {
      return {
        backgroundColor: OverrideColors.bgPaper(themeName),
        borderRadius: "3px",
        // use same transition as in .MuiInput-underline::before
        transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:focus": {
          backgroundColor: "initial",
          borderRadius: "initial",
        },
        "&:hover": {
          backgroundColor: "initial",
          borderRadius: "initial",
        },
      };
    } else {
      return undefined;
    }
  } 

  // properties moved from multiline rule to create more space in multiline inputs
  public static getInputMultilineStyle(themeName: Themes): Maybe<StylePropWithPseudoSelector> {
    if (themeName === Themes.light_V2 || Themes.dark_V2) {
      return {
        padding: "6px 0 7px",
      };
    } else {
      return undefined;
    }
  } 

  // removed and moved to inputMultiline rule to fix gap under input background when background-color is applied to input rule
  public static getMultilineStyle(themeName: Themes): Maybe<StylePropWithPseudoSelector> {
    if (themeName === Themes.light_V2 || Themes.dark_V2) {
      return {
        padding: 0,
      };
    } else {
      return undefined;
    }
  } 

}

