import React, { Component, ReactNode } from "react";
import { Box } from "@material-ui/core";
import LocationOffIcon from "@material-ui/icons/LocationOff";
import Localization from "data/localization-sensoan/Localization";
import IoTDataRow from "components/layout/iot-data-row/IoTDataRow";
import { Nullable } from "types/aliases";
import Event, { EventState } from "data/clientSpecific/Event";
import EventsRepository from "data/data-storage/EventsRepository";
import { IconStatus, MapMarkerData } from "data/map/MapLink";
import { getMapMarkerDataForEvent } from "data/utils/eventUtils";
import SButton from "components/styled-components/SButton";
import SText from "components/styled-components/SText";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import DataRowLocationIndicator from "components/layout/iot-data-row/DataRowLocationIndicator";

interface Props {
  dataItem: Event;
  mapMarkerData: Nullable<MapMarkerData[]>;
  toggleMarker: (marker: Nullable<MapMarkerData>) => void;
}

interface State {
}

class EventsListDataRow extends Component<Props, State> {

  private eventsRepo = EventsRepository.instance;

  private renderLocationIndicator(event: Event): ReactNode {
    const { mapMarkerData, toggleMarker } = this.props;

    if (event.latitude && event.longitude) {
      return (
        <DataRowLocationIndicator
          buttonActiveColor={SSvgIconColorProps.orangeGradient}
          mapMarkerData={mapMarkerData}
          getMapMarkerDataItem={(): Nullable<MapMarkerData> => this.getMapMarkerDataItem(event)}
          toggleMarker={toggleMarker}
        />
      );
    } else {
      return (
        <Box p={3}>
          <SSvgIcon
            color={SSvgIconColorProps.primary}
            iconComponent={LocationOffIcon}
          />
        </Box>
      );
    }
  }

  public render(): ReactNode {
    const { dataItem } = this.props;
    return (
      <IoTDataRow>
        {this.renderLocationIndicator(dataItem)}
        <Box width="15%">
          <SText text={dataItem.deviceId} color="textSecondary"/>
        </Box>
        <Box width="14%">
          <SText text={dataItem.sensorName ?? ""} color="textSecondary"/>
        </Box>
        <Box width="20%">
          <SText text={dataItem.eventId ? this.eventsRepo.getEventDescription(dataItem.eventId) ?? "" : ""} color="textSecondary"/>
        </Box>
        <Box width="11%">
          <SText text={dataItem.sensorValue?.toString() ?? ""} color="textPrimary"/>
        </Box>
        <Box width="14%">
          <SText text={Localization.getInstance().getDateAndTimeString(parseInt(dataItem.timestamp))} color="textSecondary"/>
        </Box>
        <Box width="12%">
          <SText text={dataItem.eventState ?? "N/A"} color={dataItem.eventState === EventState.Active ? "textPrimary" : "textSecondary"}/>
        </Box>
        <Box width="14%">
          {dataItem.eventState === EventState.Active ?
            <SButton
              fontWeight="medium"
              onClick={async (): Promise<void> => await this.eventsRepo.deactivateEvent(dataItem)}
              labelText={Localization.getInstance().getDisplayText("EventsView", "acknowledge")}
              widthInRems={7}
            />
            : null}
        </Box>
      </IoTDataRow>
    );
  }

  private getMapMarkerDataItem(event: Event): Nullable<MapMarkerData> {
    return getMapMarkerDataForEvent(event, () => IconStatus.selected);
  }
}

export default EventsListDataRow;
