import React, { ChangeEvent, Component, Fragment } from "react";
import { Button, TextField } from "@material-ui/core";
import AuthWrapper from "data/auth/AuthWrapper";
import Localization from "data/localization-sensoan/Localization";
import { Maybe } from "types/aliases";
import PasswordField from "components/ui/password-field";
import { isError } from "data/utils/ErrorUtils";
import Loader from "components/ui/loader";

export interface Props {
  username: string;
  onLoginRequest: () => void;
}

interface State {
  newPassword: string;
  confirmPassword: string;
  verificationCode: string;
  codeError?: string;
  newPasswordError?: string;
  confirmPasswordError?: string;
  generalError?: string;
  isLoaderVisible?: boolean;
}

export default class ForgotPasswordForm extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      verificationCode: "",
    };
  }

  private fieldsHaveValues(): boolean {
    const { verificationCode, newPassword, confirmPassword } = this.state;
    return Boolean(verificationCode.length && newPassword.length && confirmPassword.length);
  }

  private passwordsMatch(): boolean {
    return this.state.newPassword === this.state.confirmPassword;
  }

  private async submitNewPassword(): Promise<void> {
    this.resetErrors();

    if (!this.passwordsMatch()) {
      this.setState({ confirmPasswordError: this.text("Common", "passwordsNotMatching") });
      return;
    }

    try {
      this.setState({ isLoaderVisible: true });
      await AuthWrapper.checkCodeAndSubmitNewPassword(this.props.username, this.state.verificationCode, this.state.newPassword);
      await AuthWrapper.logIn(this.props.username, this.state.newPassword);
    } catch (error) {
      console.error("submitNewPassword", error);
      if (isError(error)) this.handleErrors(error.message);
      this.setState({ isLoaderVisible: false });
    }
  }

  private handleErrors(code?: string): void {
    switch (code) {
      case "Attempt limit exceeded, please try after some time.":
        this.setState({ generalError: this.text("Common", "tooManyAttempts") });
        break;
      case "Invalid verification code provided, please try again.":
        this.setState({ codeError: this.text("Login", "invalidCode") });
        break;
      case "Invalid code provided, please request a code again.":
        this.setState({ codeError: this.text("Login", "codeExpired") });
        break;
      case "Network error":
        this.setState({ codeError: this.text("Common", "networkError") });
        break;
      case "Password does not conform to policy: Password must have numeric characters":
        this.setState({ newPasswordError: this.text("Common", "passwordMustHaveNumbers") });
        break;
      case "Password does not conform to policy: Password must have lowercase characters":
        this.setState({ newPasswordError: this.text("Common", "passwordMustHaveLowercaseCharacters") });
        break;
      case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "Password does not conform to policy: Password not long enough":
      case "Password did not conform with policy: Password not long enough":
        this.setState({ newPasswordError: this.text("Common", "passwordMustBeLongEnough") });
        break;
      case "Invalid session for the user, session is expired.":
        this.setState({ generalError: this.text("Common", "userSessionExpired") });
        break;
      default:
        this.setState({ generalError: this.text("Common", "unableToPerformAction") });
        break;
    }
  }

  private resetErrors(): void {
    this.setState({
      generalError: undefined,
      codeError: undefined,
      newPasswordError: undefined,
      confirmPasswordError: undefined,
    });
  }

  private renderLoader(): Maybe<JSX.Element> {
    if (this.state.isLoaderVisible) {
      return <Loader />;
    }
  }

  private renderBackToLogInForm(): JSX.Element {
    return (
      <Fragment>
        <div className="login-links small">
          <span
            onClick={(): void => this.props.onLoginRequest()}
          >
            {this.text("Login", "backToLogIn")}
          </span>
        </div>
      </Fragment>
    );
  }

  private renderGeneralError(): Maybe<JSX.Element> {
    if (this.state.generalError) {
      return (
        <div className="login-errortext">
          {`${this.text("Common", "errorOccurred")} ${this.state.generalError}`}
        </div>
      );
    }
  }

  private renderInputs(): JSX.Element {
    return (
      <Fragment>
        <div className="login-fields">
          <TextField
            label={this.text("Login", "confirmationCode")}
            type="text"
            autoComplete="confirmation-code"
            margin="normal"
            variant="outlined"
            required={true}
            value={this.state.verificationCode}
            error={this.state.codeError != null}
            helperText={this.state.codeError}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
              this.resetErrors();
              this.setState({ verificationCode: event.target.value });
            }}
            fullWidth={true}
          />
          <PasswordField
            label={this.text("Common", "newPassword")}
            autoComplete="new-password"
            fullWidth={true}
            required={true}
            margin="normal"
            error={this.state.newPasswordError != null}
            helperText={this.state.newPasswordError}
            onChange={(newPassword: string): void => {
              this.resetErrors();
              this.setState({ newPassword });
            }}
          />
          <PasswordField
            label={this.text("Common", "confirmNewPassword")}
            autoComplete="confirm-password"
            fullWidth={true}
            required={true}
            margin="normal"
            error={this.state.confirmPasswordError != null}
            helperText={this.state.confirmPasswordError}
            onChange={(confirmPassword: string): void => {
              this.resetErrors();
              this.setState({ confirmPassword });
            }}
          />
        </div>
        <div className="login-buttons">
          <Button
            disabled={!this.fieldsHaveValues()}
            variant="contained"
            color="primary"
            onClick={(): Promise<void> => this.submitNewPassword()}
          >
            {this.text("Login", "confirmLogIn")}
          </Button>
        </div>
      </Fragment>
    );
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <div className="login-header">
          {`${this.text("Login", "enterVerificationCodeAndPassword")} ${this.props.username}`}
        </div>
        {this.renderLoader()}
        {this.renderInputs()}
        {this.renderBackToLogInForm()}
        {this.renderGeneralError()}
      </Fragment >
    );
  }
}
