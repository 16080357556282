/*Copyright*/

enum PathsSensoan {
  ROOT = "/",
  STATUS = "/status",
  DEVICES = "/devices",
  LOGIN = "/login",
  MEASUREMENTSETS = "/measurementsets",
  MEASUREMENTJOBS = "/measurementjobs",
  EVENTS = "/events",
  USER = "/user",
  ADMIN = "/admin",
}

// Commented code might be useful when pathname and search of URL are updated to take a form like this https://tiedonkeruu.sensoan.com/measurementsets/edit?measset_id=xxxx

// interface RouteParamPreservationConfig {
//   pathParams: string[];
// }
// type PreservationConfigurations = Record<Paths, RouteParamPreservationConfig>;


// const preservationConfigs: PreservationConfigurations = {
//   [Paths.ROOT]: {
//     pathParams: [],
//   },
//   [Paths.STATUS]: {
//     pathParams: ["id"],
//   },
//   [Paths.HISTORY]: {
//     pathParams: ["id"],
//   },
//   [Paths.LOGIN]: {
//     pathParams: [],
//   },
//   [Paths.EVENTS]: {
//     pathParams: ["id"],
//   },
//   [Paths.ADMIN]: {
//     pathParams: [],
//   },
//   [Paths.USER]: {
//     pathParams: [],
//   },
//   [Paths.ANALYTICS]: {
//     pathParams: ["id"],
//   },
// };

// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// export function getPathWithRetainedParams(path: Paths, routeProps: RouteComponentProps<any>): string {
//   const retainedPathParams: string[] = [];

//   for (const pathParam of preservationConfigs[path].pathParams) {
//     if (routeProps.match.params[pathParam]) {
//       retainedPathParams.push(routeProps.match.params[pathParam]);
//     } else {
//       break;
//     }
//   }

//   return path + (retainedPathParams.length > 0 ? "/" + retainedPathParams.join("/") : "");
// }


export default PathsSensoan;
