import React, { Component, Fragment, ReactNode } from "react";
import { Grid } from "@material-ui/core";
import MeasurementDataTools from "data/measurement-data/MeasurementDataTools";
import { Maybe, Nullable } from "types/aliases";
import MeasurementSetDataContainer from "data/measurement-data/MeasurementSetDataContainer";
import { ChartConfig, ChartSize, ChartType, MeasurementSetConfig, SensorColorType } from "data/types/measurementSetTypes";
import GaugeCircle from "components/charts/GaugeCircle";
import LineChart from "components/charts/LineChart";
import GaugeChart2 from "components/charts/GaugeChart2";
import SteppedAreaChart2 from "./SteppedAreaChart2";

interface Props {
  deleteChart?: (index: Maybe<number>) => void;
  editChart?: (index: Maybe<number>) => void;
  measurementSetData: Nullable<MeasurementSetDataContainer>;
  previewMode?: boolean;
  selectedMeasurementSet: Nullable<MeasurementSetConfig>;
}

interface State {
}

class MeasurementSetCharts extends Component<Props, State> {

  private renderChart(chartConfig: ChartConfig, chartSize: ChartSize, id: number): Nullable<ReactNode> {

    switch (chartConfig.chartType) {
      case ChartType.STEPPED_AREA_CHART:
        return (
          <SteppedAreaChart2
            chartIndex={id}
            // TODO: ensure colors are shown correctly with getLineChartDataTs
            colors={MeasurementDataTools.getSortedSensorColorList(chartConfig.dataConfig)}
            data={this.props.measurementSetData?.getChartDataTs(chartConfig, id) ?? []}
            dataConfig={chartConfig.dataConfig}
            deleteChart={this.props.deleteChart}
            editChart={this.props.editChart}
            key={id}
            scale={chartConfig.scale}
            size={chartSize}
            title={chartConfig.displayName}
          />
        );
      case ChartType.LINECHART:
        return (
          <LineChart
            chartIndex={id}
            // TODO: ensure colors are shown correctly with getLineChartDataTs
            colors={MeasurementDataTools.getSortedSensorColorList(chartConfig.dataConfig)}
            data={this.props.measurementSetData?.getChartDataTs(chartConfig, id) ?? []}
            dataConfig={chartConfig.dataConfig}
            deleteChart={this.props.deleteChart}
            editChart={this.props.editChart}
            key={id}
            scale={chartConfig.scale}
            size={chartSize}
            title={chartConfig.displayName}
          />
        );

      case ChartType.GAUGE:
        if (chartConfig.scale !== undefined) {
          return (
            <GaugeChart2
              key={id}
              size={chartSize}
              title={`${chartConfig.displayName} / ${chartConfig.dataConfig[0].sensorDisplayName}`}
              editChart={this.props.editChart}
              deleteChart={this.props.deleteChart}
              chartIndex={id}
              scale={chartConfig.scale}
              value={this.props.measurementSetData?.getGaugeData(chartConfig) as number}
              latestDataOf={{ deviceId: chartConfig.dataConfig[0].deviceId, sensor: chartConfig.dataConfig[0].sensorName }}
              color={chartConfig.dataConfig[0].sensorColor ?? SensorColorType.ORANGE}
              gradient={true}
            />
          );
        } else {
          return (
            <GaugeCircle
              key={id}
              size={chartSize}
              title={`${chartConfig.displayName} / ${chartConfig.dataConfig[0].sensorDisplayName}`}
              editChart={this.props.editChart}
              deleteChart={this.props.deleteChart}
              chartIndex={id}
              value={this.props.measurementSetData?.getGaugeData(chartConfig) as number}
              color={chartConfig.dataConfig[0].sensorColor ?? SensorColorType.ORANGE}
            />
          );
        }

      case ChartType.NUMBER_DISPLAY:
        return (
          <GaugeCircle
            size={chartSize}
            key={id}
            value={this.props.measurementSetData?.getGaugeData(chartConfig) as number}
            title={`${chartConfig.displayName} / ${chartConfig.dataConfig[0].sensorDisplayName}`}
            chartIndex={id}
            editChart={this.props.editChart}
            deleteChart={this.props.deleteChart}
            color={chartConfig.dataConfig[0].sensorColor ?? SensorColorType.BLUE}
          />
        );

        /* case ChartType.GAUGECHART:
        return null; */

      default:
        return null;
    }
  }

  public render(): ReactNode {
    return (
      <Fragment>
        {this.props.selectedMeasurementSet
          ?
          <Grid container justifyContent="center" spacing={2}>
            {this.props.selectedMeasurementSet?.config.chartConfig.map((conf: ChartConfig, index: number): Nullable<ReactNode> => {
              return this.props.selectedMeasurementSet
                ? this.renderChart(conf, this.getChartSize(), index)
                : null;
            })}
          </Grid>
          : null
        }
      </Fragment>
    );
  }

  private getChartSize(): ChartSize {
    if (this.props.selectedMeasurementSet?.config.chartConfig.length === 1 && this.props.previewMode) {
      return ChartSize.PREVIEW_WIDTH;
    } else if (this.props.selectedMeasurementSet?.config.chartConfig.length === 1){
      return ChartSize.FULL_WIDTH;
    } else {
      return ChartSize.NORMAL;
    }
  }
}

export default MeasurementSetCharts;
