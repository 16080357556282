import React, { Component, Fragment, ReactNode } from "react";
import { Box, Container, CssBaseline, Theme, withTheme } from "@material-ui/core";
import { AppLayoutMode } from "types/sensoanUiTypes";
import SDrawer, { DRAWER_WIDTH_COLLAPSED, DRAWER_WIDTH_EXPANDED } from "components/styled-components/SDrawer";
import MapWrapper from "components/map/MapWrapper";
import Header from "../header/Header";
import ContentController from "components/content-controller/ContentController";
import { Locales } from "data/localization-sensoan/Locales";
import Localization, { LocalizationObserver } from "data/localization-sensoan/Localization";

const DRAWER_RIGHT_GUTTER = 12;

interface Props {
  theme: Theme;
}

interface State {
  drawerExpanded: boolean;
  layoutMode: AppLayoutMode;
  locale: Locales;
}
class AppLayout extends Component<Props, State> implements LocalizationObserver {

  public constructor(props: Props) {
    super(props);
    this.state = {
      drawerExpanded: true,
      layoutMode: AppLayoutMode.dataWithDrawer,
      locale: Localization.getInstance().getCurrentLocale(),
    };
    this.handleLayoutModeChange = this.handleLayoutModeChange.bind(this);
  }

  public componentDidMount(): void {
    Localization.getInstance().addObserver(this);
  }

  public componentDidUpdate(_prevProps: Props, prevState: State): void {
    if (prevState.layoutMode === AppLayoutMode.dataWithDrawer && this.state.layoutMode === AppLayoutMode.mapWithDrawer) {
      this.setState({ drawerExpanded: false });
    } else if (prevState.layoutMode === AppLayoutMode.mapWithDrawer && this.state.layoutMode === AppLayoutMode.dataWithDrawer){
      this.setState({ drawerExpanded: true });
    }
  }

  public componentWillUnmount(): void {
    Localization.getInstance().removeObserver(this);
  }

  public onSelectedLocaleChanged(locale: Locales): void {
    this.setState({ locale });
  }

  private renderMainLayoutContent(): ReactNode {
    const isMapWithDrawer = this.state.layoutMode === AppLayoutMode.mapWithDrawer;
    return (
      <Fragment>
        <ContentController appLayoutMode={this.state.layoutMode}/>
        {isMapWithDrawer &&
          <MapWrapper
            drawerExpanded={this.state.drawerExpanded}
            onAppLayoutModeChange={this.handleLayoutModeChange}
            appLayoutMode={this.state.layoutMode}
          />}
      </Fragment>
    );
  }

  public render(): JSX.Element {
    const isExpanded = this.state.drawerExpanded;
    return (
      <Container maxWidth={false} disableGutters={true}>
        <CssBaseline />
        <Header />
        <SDrawer
          expanded={isExpanded}
          appLayoutMode={this.state.layoutMode}
          onAppLayoutModeChange={this.handleLayoutModeChange}
          toggleExpanded={(): void => this.setState({ drawerExpanded: !isExpanded })}
        />
        <Box
          height={`calc(100vh - ${this.props.theme.mixins.toolbar.height})`}
          left={`${this.getDrawerArea(isExpanded)}px`}
          /* This allows overflowing in horizontal direction but prevents overflowing in vertical direction - it is handled by WrapperContainer */
          overflow="auto hidden"
          position="fixed"
          top={this.props.theme.mixins.toolbar.height}
          width={`calc(100vw - ${this.getDrawerArea(isExpanded)}px)`}
        >
          {this.renderMainLayoutContent()}
        </Box>
      </Container>
    );
  }

  private handleLayoutModeChange(): void {
    const { layoutMode } = this.state;

    if (layoutMode === AppLayoutMode.dataWithDrawer) {
      this.setState({ layoutMode: AppLayoutMode.mapWithDrawer });
    } else if (layoutMode === AppLayoutMode.mapWithDrawer){
      this.setState({ layoutMode: AppLayoutMode.dataWithDrawer });
    } else {
      console.error("Unknown layoutMode in AppLayout.handleLayoutTypeChange");
    }
  }

  private getDrawerArea(isExpanded: boolean): number {
    let drawerArea;

    if (isExpanded) {
      drawerArea = DRAWER_WIDTH_EXPANDED;
    } else {
      drawerArea = DRAWER_WIDTH_COLLAPSED + DRAWER_RIGHT_GUTTER;
    }
    return drawerArea;
  }

}

export default withTheme(AppLayout);
