/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import Device from "../data/device/Device";
import DeviceGroup from "../data/device/DeviceGroup";
import { Maybe } from "../types/aliases";
import { DeviceAndTime, DeviceChangeType, DeviceRouterProps } from "./NavigationUtils";
import SelectedDeviceCacheImpl from "./SelectedDeviceCacheImpl";
import AuthListener, { AuthEvent } from "../data/auth/AuthListener";
import BackendFactory from "../data/BackendFactory";
import Paths from "../data/paths/Paths";
import PathsSensoan from "data/paths/PathsSensoan";

export interface DeviceChangeHandler {
  handle: (change: DeviceChangeType, props: DeviceRouterProps) => Promise<void>;
}

type Selection<T> = string | T;
export type DeviceSelection = Selection<Device>;
export type GroupSelection = Selection<DeviceGroup>;

export function isIdSelection(selection: Selection<unknown> | undefined): selection is string {
  return typeof selection === "string";
}

/**
 * Caches device and group selections.
 * Handles few relevant operations relating to devices and groups:
 * - helps to build correct URLS for device selections
 * - updates ReceiverManager with correct groups selections
 */
export interface SelectedDeviceCache {
  /**
   * Retrieves the currently selected device. This is set by views that resolve device selections and rely on this
   * component to provide a cached device
   */
  getSelectedDevice(): Maybe<Device>;

  /**
   * Caches the given device or empties the cache.
   * Device's groups are added to known receivers list in order to receive events from the backend for the device groups.
   *
   * When empty device is given, its groups are removed from the known receivers list.
   * @param deviceSelection
   *    device id, device, or empty
   */
  setCurrentDevice(deviceSelection?: DeviceSelection): Promise<Maybe<Device>>;

  /**
   * If the user has selected and cached a group other than the devices groups, returns that group
   */
  getSelectedGroup(): Maybe<DeviceGroup>;

  /**
   * Caches the given group or empties the cache.
   * Group is added to the known receivers list (or removed from it)
   *
   * @param groupSelection
   *    group id, group, or empty
   */
  setCurrentGroup(groupSelection?: GroupSelection): Promise<boolean>;

  hasCachedDevice(): boolean;

  /**
   * Dictates how the value update of {@link DeviceRouterProps} should be handled.
   *
   * @param routerProps
   *    current router props
   * @param previousRouterProps
   *    previous router props. Give these in {@code componentDidUpdate} method.
   */
  predictDeviceChange(routerProps: DeviceRouterProps, previousRouterProps?: DeviceRouterProps): DeviceChangeType;

  resolveDeviceChange(handler: DeviceChangeHandler, routerProps: DeviceRouterProps, previousRouterProps?: DeviceRouterProps): Promise<void>;

  /**
   * If device is set in the URL, keeps that selection and sets it as the currently cached device. If there is no device
   * in the URL, attempts to redirect to a cached device, if there is one.
   *
   * Usually called in the {@code componentDidMount} callback.
   *
   * @param routerProps
   *    current device router props
   * @returns restored
   *    did the call cause an url change
   */
  navigateToCachedIfNoDeviceInPath(routerProps: DeviceRouterProps): Promise<boolean>;

  /**
   * Pushes a new relative path into the routing history, replacing (possible) previous device with the given
   * device (or an empty device, if none given). Can take query parameters that are added to the new target URL
   * @param routerProps
   * @param deviceId
   * @param params
   */
  navigateToDevice(routerProps: DeviceRouterProps, deviceId?: DeviceSelection, params?: Record<string, unknown>): Promise<Maybe<Device>>;

  navigateToDeviceWithQueryString(routerProps: DeviceRouterProps, deviceId: string): Promise<Maybe<Device>>;

  navigateToDeviceAndTime(routerProps: DeviceRouterProps, info?: DeviceAndTime): Promise<Maybe<Device>>;

  navigateToPath(routerProps: DeviceRouterProps, path: Paths): void;

  // Sensoan modification:
  setCurrentDeviceAndNavigateToPath(routerProps: DeviceRouterProps, path: PathsSensoan, deviceId: string): Promise<void>;
}

/**
 * Singleton container for {@link SelectedDeviceCache} implementation
 */
export default class DeviceNavigationCache {
  private static instance?: SelectedDeviceCache;

  private static authEventHandler = async (event: AuthEvent): Promise<void> => {
    if (event === "SignedOut") {
      await Promise.all([
        DeviceNavigationCache.instance?.setCurrentGroup(),
        DeviceNavigationCache.instance?.setCurrentDevice(),
      ]);
    }
  };
  private static readonly authListener = new AuthListener(DeviceNavigationCache.authEventHandler);

  private constructor() { /* empty */ }

  public static getInstance(): SelectedDeviceCache {
    if (DeviceNavigationCache.instance == null) {
      DeviceNavigationCache.instance = new SelectedDeviceCacheImpl(BackendFactory.getBackend());
    }
    return this.instance!;
  }
}
