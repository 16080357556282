import { Nullable } from "types/aliases";
import AWSLatestData from "../data/AWSLatestData";
import Data from "data/data/Data";
import LatestData, { LatestDataObserver } from "../data/LatestData";
import DataRepository from "./DataRepository";

export interface LatestDeviceDataRepositoryListener {
  onDataUpdated(data: Data): void;
}

interface SingleDeviceListeners {
  [deviceId: string]: LatestDeviceDataRepositoryListener[];
}
export default class LatestDeviceDataRepository implements DataRepository, LatestDataObserver {

  private static instance: LatestDeviceDataRepository = new LatestDeviceDataRepository();

  private initialized = false;
  private data: { [key: string]: LatestData } = {};
  private allDataListeners: LatestDeviceDataRepositoryListener[] = [];
  private singleDeviceListeners: SingleDeviceListeners = {};

  public static getInstance(): LatestDeviceDataRepository {
    return this.instance;
  }

  public getName(): string {
    return "latestData";
  }

  public addListener(listener: LatestDeviceDataRepositoryListener, deviceId?: string): void {
    if (deviceId === undefined) {
      const listenerIndex = this.allDataListeners.indexOf(listener);

      if (listenerIndex === -1) {
        this.allDataListeners.push(listener);
      }
    } else {
      if (this.singleDeviceListeners[deviceId] === undefined) {
        this.singleDeviceListeners[deviceId] = [listener];
      } else {
        const listenerIndex = this.singleDeviceListeners[deviceId].indexOf(listener);

        if (listenerIndex === -1) {
          this.singleDeviceListeners[deviceId].push(listener);
        }
      }
    }
  }

  public removeListener(listener: LatestDeviceDataRepositoryListener, deviceId?: string): void {
    if (deviceId === undefined) {
      const listenerIndex = this.allDataListeners.indexOf(listener);

      if (listenerIndex !== -1) {
        this.removeListenerFromIndex(listenerIndex);
      }
    } else {
      if (this.singleDeviceListeners[deviceId] !== undefined) {
        const listenerIndex = this.singleDeviceListeners[deviceId].indexOf(listener);

        if (listenerIndex !== -1) {
          this.removeSingleDeviceListenerFromIndex(listenerIndex, deviceId);
        }
      }
    }
  }

  private removeSingleDeviceListenerFromIndex(index: number, deviceId: string): void {
    this.singleDeviceListeners[deviceId].splice(index, 1);
  }

  private removeListenerFromIndex(index: number): void {
    this.allDataListeners.splice(index, 1);
  }

  public onDataUpdate(data: LatestData): void {
    this.allDataListeners.forEach((listener: LatestDeviceDataRepositoryListener) => {
      listener.onDataUpdated(data.getData() as Data);
    });

    const deviceId: string = data.getData()?.deviceId ?? "undefined"; // shouldn't ever fall to "undefined", but needed for type safety

    if (this.singleDeviceListeners[deviceId] !== undefined) {
      this.singleDeviceListeners[deviceId].forEach((listener: LatestDeviceDataRepositoryListener) => {
        listener.onDataUpdated(data.getData() as Data);
      });
    }
  }

  public getData(deviceId: string): Nullable<Data> {
    return this.data[deviceId]?.getData() ?? null;
  }

  public getLatestData(deviceId: string): Nullable<LatestData> {
    return this.data[deviceId] ?? null;
  }

  public async fetchData(deviceId: string): Promise<Nullable<LatestData>> {
    try {
      if (this.data[deviceId]) {
        console.error(`LatestDeviceDataRepository: Fetching data for ${deviceId}, but the data already exists`);
        return this.data[deviceId];
      } else {
        const newData = new AWSLatestData<Data>(deviceId, "");
        await newData.fetch();
        // TODO: recreate subsription functionality with refactored subsription handlind
        // LatestDataSubscriptionManager.instance(newData);
        newData.addObserver(this);
        this.data[deviceId] = newData;
        return newData;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  public async init(): Promise<void> {
    console.error("LatestDeviceDataRepository init functionality is not implemented");
    // TODO: if we want to implement init(), we need to add backend functionality to scan latestData table and return allowed device data
    this.initialized = true;
  }

  public clear(): void {
    // for (const deviceId in this.data) {
    // TODO: recreate subsription functionality with refactored subsription handlind
    // LatestDataSubscriptionManager.getInstance().removeSubscription(this.data[deviceId]);
    // }
    this.data = {};
    this.initialized = false;
  }

  private constructor() {
    /* empty constructor */
  }

}
