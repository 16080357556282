import React, { AriaAttributes, Component } from "react";
import { Theme, withStyles, withTheme } from "@material-ui/core/styles";
import { Box, IconButton, IconButtonProps, Tooltip, TooltipProps } from "@material-ui/core";
import { AriaHaspopupProps, CustomSpacingProps } from "types/sensoanUiTypes";
import { Maybe } from "types/aliases";

type PartialAriaAttributes = Partial<AriaAttributes>; // AriaAttributes props are lost with withStyles HOC -> add them as optional fields to StyledIconButton type

interface Props {
  theme: Theme;
  ariaControls?: string;
  ariaHaspopup?: AriaHaspopupProps;
  backGroundColor?: "buttonSecondaryBg" | "themeDefaultBg" | string;
  disabled?: boolean;
  disableRipple?: boolean;
  focusVisibleClassName?: string;
  hoverBgColor?: string;
  hoverBgSize?: string;
  inlineStyle?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  shadow?: boolean;
  size?: string;
  tooltipText?: string;
  m?: CustomSpacingProps;
  mt?: CustomSpacingProps;
  mr?: CustomSpacingProps;
  mb?: CustomSpacingProps;
  ml?: CustomSpacingProps;
}

interface State {
  StyledIconButton: React.ComponentType<IconButtonProps & PartialAriaAttributes>;
  StyledTooltip: React.ComponentType<TooltipProps>;
}

class SIconButton extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      StyledIconButton: this.getStyledIconButton(),
      StyledTooltip: this.getStyledTooltip(),
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.theme !== prevProps.theme) {
      this.setState({
        StyledIconButton: this.getStyledIconButton(),
        StyledTooltip: this.getStyledTooltip(),
      });
    }
  }

  public render(): JSX.Element {
    const {
      ariaControls,
      ariaHaspopup,
      disableRipple,
      disabled,
      focusVisibleClassName,
      inlineStyle,
      children,
      onClick,
      m,
      mt,
      mr,
      mb,
      ml,
      tooltipText,
    } = this.props;

    const ButtonElement = (
      <this.state.StyledIconButton
        aria-controls={ariaControls}
        aria-haspopup={ariaHaspopup}
        disabled={disabled}
        disableRipple={disableRipple}
        focusVisibleClassName={focusVisibleClassName}
        onClick={onClick}
        style={inlineStyle}
      >
        {children}
      </this.state.StyledIconButton>
    );

    return (
      <Box m={m} mt={mt} mr={mr} mb={mb} ml={ml}
        // style={this.getWrappingBoxSpacing()} // reading margin from prop does not work for some reason, this is a temporary fix for it
      >
        {tooltipText
          ?
          <this.state.StyledTooltip
            arrow
            id="icon-button-tooltip"
            placement="bottom-end"
            title={tooltipText}>
            {ButtonElement}
          </this.state.StyledTooltip>
          :
          ButtonElement
        }
      </Box>
    );
  }

  private getStyledIconButton(): React.ComponentType<IconButtonProps & PartialAriaAttributes> {
    const { theme, backGroundColor, shadow, hoverBgColor, hoverBgSize, size } = this.props;

    return withStyles({
      root: {
        backgroundColor: backGroundColor ? this.getBgColor() : undefined,
        boxShadow: backGroundColor || shadow ? theme.shadows["2"] : undefined,
        padding: backGroundColor || shadow ? 0 : hoverBgSize ?? "12px", // padding sets the size of background hover effect
        height: size,
        width: size,
        "&:hover": {
          backgroundColor: hoverBgColor,
          boxShadow: backGroundColor ? theme.shadows["4"] : undefined,
        },
      },
    })(IconButton) as typeof IconButton & PartialAriaAttributes;
  }

  private getBgColor(): Maybe<string> {
    const { theme, backGroundColor } = this.props;

    switch (backGroundColor) {
      case "buttonSecondaryBg":
        return theme.palette.gradients.grey;
      case "themeDefaultBg":
        return theme.palette.background.default;
      default:
        return backGroundColor;
    }
  }

  private getStyledTooltip(): React.ComponentType<TooltipProps> {
    const { theme } = this.props;
    return withStyles({
      popper: {
        opacity: 0.9,
      },
      tooltip: {
        display: "flex",
        color: theme.palette.text.secondary,
        alignItems: "center",
        borderRadius: "9px",
        fontSize: theme.typography.body2.fontSize,
        height: "2rem",
        padding: "13px 20px",
      },
    })(Tooltip);
  }
}

export default withTheme(SIconButton);
