/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

export type StatusValue = string | number | boolean | JSX.Element;

export interface StatusEntry {
  title: string;
  value: StatusValue;
}

export type StatusDataRow = StatusValue[];

export interface TableColumnLabels {
  [key: string]: string;
}

export interface TimestampRange {
  start: number;
  end: number;
}

export class ClientProperties {

  public static getAlarmTooltipFromEntities(entities: string[]): string {
    if (entities.length > 0) {
      const result = entities.join(",") + " alarm";
      return result.charAt(0).toUpperCase() + result.slice(1);
    } else {
      return "";
    }
  }

  public static getDefaultEventTimestampRange(days: number): TimestampRange {
    const delta: number = days * 24 * 60 * 60 * 1000;
    const startTimestamp: number = new Date().getTime() - delta;
    const endTimestamp: number = new Date().getTime();
    return {
      start: startTimestamp,
      end: endTimestamp,
    };
  }
}

export default ClientProperties;
