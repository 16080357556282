import { Theme } from "@material-ui/core";
import { Maybe } from "types/aliases";
import { Themes } from "../themes";

export interface IconGradient {
  colorStopFirst: string;
  colorStopSecond: string;
  name: IconGradientNames;
}

export interface GradientCollection {
  [name: string]: React.CSSProperties["color"];
}

export enum IconGradientNames {
  blueGrey = "blue-grey",
  greenGrey = "green-grey",
  orangeGrey = "orange-grey",
  yellowGrey = "yellow-grey",
}

export default class Gradients {

  public static getIconGradientsForProviders(theme: Theme): IconGradient[] {
    let iconGradients: IconGradient[];
    
    if (theme.palette.type === "dark") {
      iconGradients = [
        {
          colorStopFirst: "#0069FF",
          colorStopSecond: "#979FAA",
          name: IconGradientNames.blueGrey,
        },
        {
          colorStopFirst: "#3AE388",
          colorStopSecond: "#979FAA",
          name: IconGradientNames.greenGrey,
        },
        {
          colorStopFirst: "#EF4E23",
          colorStopSecond: "#A9A09A",
          name: IconGradientNames.orangeGrey,
        },
        {
          colorStopFirst: "#FFD559",
          colorStopSecond: "#A9A09A",
          name: IconGradientNames.yellowGrey,
        },
      ];
    } else if (theme.palette.type === "light") {
      //TODO: change values of greenGrey with light theme when they are decided
      iconGradients = [
        {
          colorStopFirst: "#0069FF",
          colorStopSecond: "#8F8F8F",
          name: IconGradientNames.blueGrey,
        },
        {
          colorStopFirst: "#3AE388",
          colorStopSecond: "#979FAA",
          name: IconGradientNames.greenGrey,
        },
        {
          colorStopFirst: "#C6901F",
          colorStopSecond: "#A8A8A8",
          name: IconGradientNames.orangeGrey,
        },
        {
          colorStopFirst: "#FFD559",
          colorStopSecond: "#A9A09A",
          name: IconGradientNames.yellowGrey,
        },
      ];
    } else {
      console.error("Unkown theme in Gradients.ts");
      iconGradients = [
        {
          colorStopFirst: "#0069FF",
          colorStopSecond: "#979FAA",
          name: IconGradientNames.blueGrey,
        },
        {
          colorStopFirst: "#3AE388",
          colorStopSecond: "#979FAA",
          name: IconGradientNames.greenGrey,
        },
        {
          colorStopFirst: "#EF4E23",
          colorStopSecond: "#A9A09A",
          name: IconGradientNames.orangeGrey,
        },
        {
          colorStopFirst: "#FFD559",
          colorStopSecond: "#A9A09A",
          name: IconGradientNames.yellowGrey,
        },
      ];
    }
    return iconGradients;
  }

  public static getIconGradientsForThemeSelector(): GradientCollection {
    const IconGradients: GradientCollection = {
      blueGrey: `url(#${IconGradientNames.blueGrey})`,
      greenGrey: `url(#${IconGradientNames.greenGrey})`,
      orangeGrey: `url(#${IconGradientNames.orangeGrey})`,
      yellowGrey: `url(#${IconGradientNames.yellowGrey})`,
    };
    return IconGradients;
  }

  public static getGradientsForThemeSelector(themeName: Themes): GradientCollection {
    const gradients: GradientCollection = {
      blue: "linear-gradient(180deg, #0069FF 0%, #1564D5 100%)", // dark and light / button blue
    };

    if (themeName === Themes.dark || themeName === Themes.dark_V2) {
      gradients.grey = "linear-gradient(180deg, #424242 0%, #303030 100%)"; // dark / input and button grey 
    } else if (themeName === Themes.light || themeName === Themes.light_V2){
      gradients.grey = "linear-gradient(180deg, #E8E8E8 0%, #DCDCDC 100%)"; // light / input and button grey 
    } else {
      // unknown theme, use values for dark theme
      console.error("Unknown theme in Gradients.ts");
      gradients.grey = "linear-gradient(180deg, #424242 0%, #303030 100%)";
    }
    return gradients;
  }

  public static getIconGradientColorStops(theme: Theme, gradient: IconGradientNames): Maybe<{colorStop1: string; colorStop2: string}> {
    const iconGradient = this.getIconGradientsForProviders(theme).find(g => g.name === gradient);

    if (iconGradient !== undefined) {
      return { colorStop1: iconGradient.colorStopFirst, colorStop2: iconGradient.colorStopSecond };
    }
  }

}

