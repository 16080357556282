import { Box, Popover, TextField, Theme, Typography, withTheme } from "@material-ui/core";
import LoaderSensoan from "components/layout/LoaderSensoan";
import SButton from "components/styled-components/SButton";
import Localization from "data/localization-sensoan/Localization";
import { validateNumber, validatePartialNumber } from "data/utils/inputUtils";
import React, { Component, Fragment } from "react";
import { Nullable } from "types/aliases";

interface Props {
  title: string;
  subTitle?: string;
  min: Nullable<number>;
  max: Nullable<number>;
  latestReading: Nullable<string | number | boolean>;
  anchorEl: Nullable<HTMLButtonElement>;
  loading: boolean;
  saveAction: (min: Nullable<number>, max: Nullable<number>) => Promise<void>;
  cancelAction: () => void;
  theme: Theme;
}

interface State {
  newMin: string;
  newMax: string;
}

class AlarmLimitsEdit extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      newMin: props.min?.toString() ?? "",
      newMax: props.max?.toString() ?? "",
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.anchorEl !== this.props.anchorEl || prevProps.min !== this.props.min || prevProps.max !== this.props.max) {
      this.setState({ newMin: this.props.min?.toString() ?? "", newMax: this.props.max?.toString() ?? "" });
    }
  }

  private async handleSave(): Promise<void> {
    try {
      await this.props.saveAction(validateNumber(this.state.newMin), validateNumber(this.state.newMax));
    } catch (error) {
      // TODO: add error property to state and set a value for it here
      if (error instanceof Error)
        console.error("Error: ", error.message);
    }
  }

  private renderContent(): JSX.Element {
    return (
      <Fragment>
        <Box mt={4} mb={4} width="100%">
          <Typography>{Localization.getInstance().getDisplayText("Common", "latestReading")} : {this.props.latestReading}</Typography>
        </Box>
        <Box mt={2} mb={2} width="100%">
          <Typography>{Localization.getInstance().getDisplayText("AlarmLimitsEdit", "min")}</Typography>
          <TextField
            fullWidth={true}
            variant="outlined"
            error={this.state.newMin !== "" && validateNumber(this.state.newMin) === null}
            label={this.state.newMin === "" ? Localization.getInstance().getDisplayText("AlarmLimitsEdit", "disabled") : undefined}
            value={this.state.newMin?.toString() ?? ""}
            onChange={(event): void => this.setState({ newMin: validatePartialNumber(event.currentTarget.value) ? event.currentTarget.value : this.state.newMin })}
          />
        </Box>
        <Box mt={2} mb={2} width="100%">
          <Typography>{Localization.getInstance().getDisplayText("AlarmLimitsEdit", "max")}</Typography>
          <TextField
            fullWidth={true}
            variant="outlined"
            error={this.state.newMax !== "" && validateNumber(this.state.newMax) === null}
            label={this.state.newMax === "" ? Localization.getInstance().getDisplayText("AlarmLimitsEdit", "disabled") : undefined}
            value={this.state.newMax?.toString() ?? ""}
            onChange={(event): void => this.setState({ newMax: validatePartialNumber(event.currentTarget.value) ? event.currentTarget.value : this.state.newMax })}
          />
        </Box>
        <Box mt={2} width="100%" display="flex">
          <Box ml="auto" mr={1}>
            <SButton
              onClick={(): Promise<void> => this.handleSave()}
              labelText={Localization.getInstance().getDisplayText("CommentAdd", "save")}
            />
          </Box>
          <Box ml={1} mr="auto">
            <SButton
              onClick={(): void => this.props.cancelAction()}
              labelText={Localization.getInstance().getDisplayText("CommentAdd", "cancel")}
              color="secondary"
            />
          </Box>
        </Box>
      </Fragment>
    );
  }

  public render(): JSX.Element {
    const open = this.props.anchorEl !== null ? true : false;
    return (
      <Popover
        onClose={(): void => this.props.cancelAction()}
        anchorEl={this.props.anchorEl}
        open={open}
      >
        <Box p={4} display="flex" flexWrap="wrap" justifyContent="center" width="20rem" bgcolor={this.props.theme.palette.background.default}>
          <Typography variant="h5">{this.props.title}</Typography>
          {this.props.subTitle ? <Typography>{this.props.subTitle}</Typography> : null}
          {this.props.loading ?
            <LoaderSensoan />
            : this.renderContent()
          }
        </Box>
      </Popover>
    );
  }
}

export default withTheme(AlarmLimitsEdit);
