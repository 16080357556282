import React, { Component, Fragment, ReactNode } from "react";
import { Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SpeedIcon from "@material-ui/icons/Speed";
import HelpIcon from "@material-ui/icons/Help";
import { ReactComponent as HyperIcon } from "assets/device/icons/router-24px.svg";
import { ReactComponent as tagFaces } from "assets/device/icons/tagFaces.svg";
import { ReactComponent as RuuviIcon } from "assets/device/icons/iot-item-24px.svg";
import { Maybe, Nullable } from "types/aliases";
import DeviceGroup from "data/device/DeviceGroup";
import Data from "data/data/Data";
import Device from "data/device/Device";
import DeviceRepository from "data/data-storage/DeviceRepository";
import LatestDeviceDataRepository from "data/data-storage/LatestDeviceDataRepository";
import STreeItem from "components/styled-components/STreeItem";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import { getDisplayName } from "data/utils/Utils";
import DeviceNavigationCache from "utils/DeviceNavigationCache";

interface Props {
  expandedItems: string[];
  group: DeviceGroup;
  deviceFilter?: (device: Device) => void;
  onTreeItemLabelClick?: (treeDeviceItem: Device, treeSensorItem?: string) => Promise<void> | void;
  selectedSensorItem?: {deviceId: string; sensorName: string};
  renderSensors?: boolean;
}
interface State {
  devices: Device[];
  childGroups: DeviceGroup[];
}
class SensorTreeGroup2 extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      devices: [],
      childGroups: [],
    };
  }

  public async componentDidMount(): Promise<void> {
    this.setState({
      devices: await this.props.group.getDevices(),
      childGroups: await this.props.group.getGroups(),
    });
  }

  private renderLabelContent(treeItem: DeviceGroup | Device | string, treeParentItem?: Device): ReactNode {
    let text;
    let icon;

    if (treeItem instanceof (DeviceGroup)) {
      text = treeItem.getLabel();
    } else if (treeItem instanceof (Device)) {
      text = getDisplayName(treeItem);
      icon = (
        <SSvgIcon
          color={this.getSelectedStatusForDeviceItem(treeItem) ? SSvgIconColorProps.blueGradient : SSvgIconColorProps.primary}
          iconComponent={this.getDeviceIconComponent(treeItem.getType())}
          size="1.375rem"
        />
      );
    } else {
      text = treeItem;
      icon = (
        <SSvgIcon
          color={this.getSelectedStatusForSensorItem(treeItem, treeParentItem) ? SSvgIconColorProps.blueGradient : SSvgIconColorProps.primary}
          iconComponent={SpeedIcon}
          size="1.375rem"
        />
      );
    }
    return (
      <Fragment>
        {icon}
        <Typography variant="body1" color="textPrimary">
          {text}
        </Typography>
      </Fragment>
    );
  }

  private getSelectedStatusForDeviceItem(device: Device): boolean {
    if (this.props.renderSensors) {
      return device.getId() === this.props.selectedSensorItem?.deviceId;
    } else {
      return device.getId() === DeviceNavigationCache.getInstance().getSelectedDevice()?.getId();
    }
  }

  private getSelectedStatusForSensorItem(sensor: string, device?: Device): boolean {
    return sensor === this.props.selectedSensorItem?.sensorName && device?.getId() === this.props.selectedSensorItem?.deviceId;
  }

  private renderSensors(device: Device): Maybe<ReactNode> {
    const latestDeviceData: Nullable<Data> = LatestDeviceDataRepository.getInstance().getData(device.getId());

    if (latestDeviceData) {
      // keys in device that we don't want the user to see
      const unwantedKeys = ["deviceId", "mac", "type", "timestamp", "unprocessed", "organization", "sessionId"];
      const filteredLatestDeviceData = Object.keys(latestDeviceData).filter(deviceDataKey => !unwantedKeys.includes(deviceDataKey));
      return (
        filteredLatestDeviceData.map((deviceDataKey, indexOfDeviceDataKey, { length }) => {
          return (
            <STreeItem
              key={deviceDataKey}
              nodeId={deviceDataKey}
              label={this.renderLabelContent(deviceDataKey, device)}
              onLabelClick={(event: React.MouseEvent<Element, MouseEvent>): Maybe<Promise<void> | void> => this.handleLabelItemClick(event, device, deviceDataKey)}
              hideExtraBorder={indexOfDeviceDataKey === length - 1 ? true : undefined}
              allowRecursiveChildren
              endLevelItem
            />
          );
        })
      );
    }
  }

  private handleLabelItemClick(event: React.MouseEvent<Element, MouseEvent>, treeDeviceItem: Device, treeSensorItem?: string): Maybe<void | Promise<void>> {
    event.preventDefault();
    this.props.onTreeItemLabelClick?.(treeDeviceItem, treeSensorItem);
  }

  private renderDevice(device: Device, indexOfDevice: number, lengthOfDevices: number): ReactNode {
    if (this.props.deviceFilter === undefined || this.props.deviceFilter(device)) {
      return (
        <STreeItem
          expanded={this.props.expandedItems.includes(device.getId())}
          collapseIcon={this.getTogglerIcon("collapse")}
          expandIcon={this.getTogglerIcon("expand")}
          key={device.getId()}
          nodeId={device.getId()}
          label={this.renderLabelContent(device)}
          hideExtraBorder={indexOfDevice === lengthOfDevices - 1 ? true : undefined}
          allowRecursiveChildren
          onLabelClick={!this.props.renderSensors ? (event: React.MouseEvent<Element, MouseEvent>): Maybe<Promise<void> | void> => this.handleLabelItemClick(event, device) : undefined}
          endLevelItem={!this.props.renderSensors}
        >
          {this.props.renderSensors && this.renderSensors(device)}
        </STreeItem>
      );
    } else {
      return null;
    }

  }

  private renderGroup(group: DeviceGroup, indexOfGroup: number, lengthOfGroups: number): JSX.Element {
    const isLastGroupAndIsWithoutDeviceSiblings = this.state.devices.length === 0 && indexOfGroup === lengthOfGroups - 1 ? true : undefined;
    return (
      <STreeItem
        expanded={this.props.expandedItems.includes(group.getId())}
        collapseIcon={this.getTogglerIcon("collapse")}
        expandIcon={this.getTogglerIcon("expand")}
        key={group.getId()}
        nodeId={group.getId()}
        label={this.renderLabelContent(group)}
        hideExtraBorder={isLastGroupAndIsWithoutDeviceSiblings}
        allowRecursiveChildren
      >
        <SensorTreeGroup2
          expandedItems={this.props.expandedItems}
          group={group}
          onTreeItemLabelClick={this.props.onTreeItemLabelClick}
          selectedSensorItem={this.props.selectedSensorItem}
          renderSensors={this.props.renderSensors}
        />
      </STreeItem>
    );
  }

  public render(): ReactNode {
    const isRootLevelGroup = DeviceRepository.getInstance().getGroups().some(deviceGroup => {
      return deviceGroup.getId() === this.props.group.getId();
    });

    if (isRootLevelGroup) {
      return (
        <STreeItem
          nodeId={this.props.group.getId()}
          label={this.renderLabelContent(this.props.group)}
          collapseIcon={this.getTogglerIcon("collapse")}
          expandIcon={this.getTogglerIcon("expand")}
        >
          {this.state.childGroups.map((group, index, { length }) => this.renderGroup(group, index, length))}
          {this.state.devices.map((device, index, { length }) => this.renderDevice(device, index, length))}
        </STreeItem>
      );
    } else {
      return (
        <Fragment>
          {this.state.childGroups.map((group, index, { length }) => this.renderGroup(group, index, length))}
          {this.state.devices.map((device, index, { length }) => this.renderDevice(device, index, length))}
        </Fragment>
      );
    }
  }

  private getTogglerIcon(type: "expand" | "collapse"): ReactNode {
    return (
      <SSvgIcon
        shadow
        color={SSvgIconColorProps.primary}
        iconComponent={type === "expand" ? ChevronRightIcon : ExpandMoreIcon}
        size="1.375rem"
      />
    );
  }

  private getDeviceIconComponent(type: string): React.FunctionComponent {
    switch (type) {
      case "RuuviTag":
        return RuuviIcon;
      case "RuuviSensoan":
        return tagFaces;
      case "Hyper":
        return HyperIcon;
      case "RuuviGW":
        return HyperIcon;
      case "Sensor":
        return tagFaces;
      default:
        console.error("Error in SensorTreeGroup2.getDeviceIconComponent: unknown parameter 'type'");
        return HelpIcon;
    }
  }
}

export default SensorTreeGroup2;
