import React, { Component, Fragment, ReactNode } from "react";
import { Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Nullable } from "types/aliases";
import { BreadcrumbData, ChartConfigValidityStatus, MeasSetEditTarget, MeasSetsButtonActions } from "types/sensoanUiTypes";
import * as Utils from "data/utils/Utils";
import Localization from "data/localization-sensoan/Localization";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import { MeasurementSetTreeItem, MeasurementSetConfig, MeasurementSetGroup, MeasurementSetType } from "data/types/measurementSetTypes";
import MeasurementSetDataContainer from "data/measurement-data/MeasurementSetDataContainer";
import { MeasurementSetSelector, MeasurementSetSelectorObserver } from "data/measurement-set-selector/MeasurementSetSelector";
import WrapperTitle2 from "components/layout/WrapperTitle2";
import SetsListView from "components/measurement-sets/list-view/SetsListView";
import SetsDataView from "components/measurement-sets/data-view/SetsDataView";
import SetsCreateView from "components/measurement-sets/create-new-view/SetsCreateView";
import SButton from "components/styled-components/SButton";
import withDataJanitor from "components/hocs/DataJanitor";
import { DataRepositories } from "data/data-storage/DataRepositoryFactory";
import { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import AdminSButton from "components/styled-components/AdminSButton";
import WrapperContainer from "components/layout/WrapperContainer";

interface Props {
}

interface State {
  actionRequest: Nullable<MeasSetsButtonActions>;
  chartConfigValidityStatus: ChartConfigValidityStatus;
  dataInitialized: boolean;
  editChartConfigMode: boolean;
  editSetMode: boolean;
  endTimeStamp: number;
  measurementSetData: Nullable<MeasurementSetDataContainer>;
  measurementSets: MeasurementSetConfig[];
  newChartConfigName: string; // TODO: this property is not needed on wrapper level anymore (not used in br.crumbs) -> can be refactored into a child component
  newSetName: string;
  selectedMeasGroupId: string; // TODO: change property type into Nullable<MeasurementSetGroup>
  tableSearchValue: string;
  timeSpanInHours: number;
  titleBreadcrumbs: BreadcrumbData[];
}

const DEFAULT_TIME_SPAN = 6;

class MeasurementSetsWrapper extends Component<Props, State> implements MeasurementSetSelectorObserver {

  private setSelector = MeasurementSetSelector.getInstance();
  private setRepo = MeasurementSetRepository.getInstance();
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      actionRequest: null,
      chartConfigValidityStatus: ChartConfigValidityStatus.invalidWithMissingValues,
      dataInitialized: false,
      editChartConfigMode: false,
      editSetMode: false,
      endTimeStamp: Date.now(),
      measurementSetData: null,
      measurementSets: this.setRepo.getMeasurementSets(),
      newChartConfigName: "",
      newSetName: "",
      selectedMeasGroupId: "",
      tableSearchValue: "",
      timeSpanInHours: DEFAULT_TIME_SPAN,
      titleBreadcrumbs: [{
        name: (): string => this.text("Common", "measurementSets"),
        link: (): void => {
          this.setSelector.setSelectedMeasurementSet(null);
          this.setState({ selectedMeasGroupId: "" });
        },
      }],
    };
    this.clearActionRequest = this.clearActionRequest.bind(this);
    this.onMeasurementSetDataReady = this.onMeasurementSetDataReady.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.startCreateNew = this.startCreateNew.bind(this);
    this.startEditExisting = this.startEditExisting.bind(this);
    this.handleGroupNameCrumbClick = this.handleGroupNameCrumbClick.bind(this);
  }

  public componentDidMount(): void {
    this.setSelector.addObserver(this);
    const measurementSet = this.setSelector.getSelectedMeasurementSet();

    if (measurementSet !== null) {
      this.updateMeasurementSetDataInState();
    }
  }

  public componentDidUpdate(_prevProps: Props, prevState: State): void {
    // update meas.set data when a set is selected and time selection changes and selected time is not in the future
    if ((prevState.timeSpanInHours !== this.state.timeSpanInHours || prevState.endTimeStamp !== this.state.endTimeStamp) && this.state.endTimeStamp <= Date.now()
    && this.setSelector.getSelectedMeasurementSet()) {
      this.updateMeasurementSetDataInState();
    }

    // check for changes in number of sets in repository and update state if there are changes
    // to show updated list of sets to user after returning from display view to list view
    if (prevState.measurementSets.length !== this.setRepo.getMeasurementSets().length) {
      this.setState({ measurementSets: this.setRepo.getMeasurementSets() });
    }

    // check for changes in selected set and update state if there are changes
    // to show updated list of sets to user after returning from editing an existing set
    const selectedSetIndex = this.state.measurementSets.findIndex(set => set.setId === this.setSelector.getSelectedMeasurementSet()?.setId);

    if (prevState.measurementSets[selectedSetIndex] !== this.setRepo.getMeasurementSets()[selectedSetIndex]) {
      this.setState({ measurementSets: this.setRepo.getMeasurementSets() });
    }

    // check for changes in group selection and update titleBreadcrumbs
    if (!this.isSetSelected() && prevState.selectedMeasGroupId !== this.state.selectedMeasGroupId) {
      const crumbs = [this.state.titleBreadcrumbs[0]];

      if (this.state.editSetMode) {
        crumbs[1] = {
          name: this.state.selectedMeasGroupId !== ""
            ? this.getParentGroupName(this.state.selectedMeasGroupId)
            : this.text("MeasSetGroupMenu", "group"),
          link: (): void => this.handleGroupNameCrumbClick(),
        };
        crumbs[2] = this.state.titleBreadcrumbs[2];
      } else if (this.state.selectedMeasGroupId !== "") {
        crumbs[1] = {
          name: this.getParentGroupName(this.state.selectedMeasGroupId),
          link: (): void => undefined,
        };
      }
      this.setState({
        titleBreadcrumbs: crumbs,
      });
    }
  }

  public componentWillUnmount(): void {
    this.setSelector.removeObserver(this);
    this.setState({ dataInitialized: false });
  }

  public onMeasurementSetDataReady(): void {
    this.setState({ dataInitialized: true });
  }

  public onSelectedMeasurementSetChanged(measurementSet: Nullable<MeasurementSetConfig>): void {
    console.log(measurementSet);
    const crumbs = [this.state.titleBreadcrumbs[0]];

    if (measurementSet !== null) {
      crumbs[1] = {
        name: this.getParentGroupName(measurementSet.parentIds[0]),
        link: (): void => {
          this.setSelector.setSelectedMeasurementSet(null);
          // setting measurementSetData null and edit modes to false with parent group name prevents dataview
          // being displayed for a little time when returning from data or create view to filtered list view by clicking group name
          this.setState({
            editChartConfigMode: false,
            editSetMode: false,
            measurementSetData: null,
            selectedMeasGroupId: measurementSet.parentIds[0],
          });
        },
      };
      crumbs[2] = {
        name: measurementSet.displayName,
        link: (): void => undefined,
      };
      this.updateMeasurementSetDataInState();
      this.setState({ selectedMeasGroupId: measurementSet.parentIds[0] });
    } else {

      if (this.state.selectedMeasGroupId !== "") {
        crumbs[1] = {
          name: this.getParentGroupName(this.state.selectedMeasGroupId),
          link: (): void => undefined,
        };
      }

      // measurementSetData is already null if user has returned from data or create view to list view by clicking group name
      if (this.state.measurementSetData !== null) {
        this.setState({
          measurementSetData: null,
        });
      }
    }

    this.setState({
      editChartConfigMode: false,
      editSetMode: false,
      titleBreadcrumbs: crumbs,
      timeSpanInHours: measurementSet?.config.historyLength ?? DEFAULT_TIME_SPAN,
    });
  }

  private renderCreateView(): ReactNode {
    const isCreatingChartConfig = this.state.editSetMode && this.state.editChartConfigMode;

    return (
      <Fragment>
        <WrapperTitle2
          breadcrumbList={this.state.titleBreadcrumbs}
          // disabled temporarily until bug in returning to list view from chartconfig edit mode by clicking the first br.crumb is fixed
          // itemsAfterCollapse={2}
          // itemsBeforeCollapse={1}
          // maxItems={3}
        >
          <Box display="flex">
            <AdminSButton
              fontWeight="regular"
              labelText={isCreatingChartConfig ? this.text("MeasurementSetsWrapper", "continue") : this.text("MeasurementSetsWrapper", "save")}
              mr={4}
              onClick={(): void => this.save(isCreatingChartConfig ? "chartConfig" : "measurementSet")}
              widthInRems={isCreatingChartConfig ? 6 : undefined}
              showLoader={false}
            />
            <SButton
              color="secondary"
              fontWeight="regular"
              labelText={this.text("MeasurementSetsWrapper", "cancel")}
              onClick={(): void => this.cancelEditing(isCreatingChartConfig ? "chartConfig" : "measurementSet")}
            />
          </Box>
        </WrapperTitle2>
        <SetsCreateView
          actionRequest={this.state.actionRequest}
          chartConfigDisplayName={this.state.newChartConfigName}
          chartConfigValidityStatus={this.state.chartConfigValidityStatus}
          clearActionRequest={this.clearActionRequest}
          clearSelectedGroup={(): void => this.setState({ selectedMeasGroupId: "" })}
          closeEditChartConfigMode={(): void => this.setState({ newChartConfigName: "", editChartConfigMode: false })}
          editChartConfigMode={this.state.editChartConfigMode}
          onNameChange={this.onNameChange}
          onSelectMeasGroup={(event, group): void => this.handleSelectMeasGroup(event, group)}
          saveChartConfigAfterScaleMsg={(): void => this.save("chartConfig")}
          selectedMeasGroupId={this.state.selectedMeasGroupId}
          setChartConfigValidityStatus={(status: ChartConfigValidityStatus): void => this.setState({ chartConfigValidityStatus: status })}
          setDisplayName={this.state.newSetName}
          startCreateNew={this.startCreateNew}
          startEditExisting={this.startEditExisting}
        />
      </Fragment>
    );
  }

  private renderDataView(): ReactNode {
    return (
      <Fragment>
        <WrapperTitle2 breadcrumbList={this.state.titleBreadcrumbs}>
          <Box display="flex">
            <AdminSButton
              fontWeight="regular"
              labelText={this.text("MeasurementSetsWrapper", "edit")}
              mr={4}
              onClick={(): void => this.startEditExisting("measurementSet", (this.setSelector.getSelectedMeasurementSet() as MeasurementSetConfig).displayName)} // DataView is rendered only if set is selected
              showLoader={false}
            />
            <AdminSButton
              color="secondary"
              fontWeight="regular"
              labelText={this.text("MeasurementSetsWrapper", "delete")}
              onClick={(): void => this.deleteSet()}
              showLoader={false}
            />
          </Box>
        </WrapperTitle2>
        <SetsDataView
          actionRequest={this.state.actionRequest}
          clearActionRequest={this.clearActionRequest}
          measurementSetData={this.state.measurementSetData}
          measSetDataLoading={!this.state.dataInitialized}
          onTimeSpanChange={(timeSpanInHours): void => this.setState({ timeSpanInHours })}
          timeSpanInHours={this.state.timeSpanInHours}
          endTimeStamp={this.state.endTimeStamp}
          setEndTimeStamp={(endTimeStamp): void => this.setState({ endTimeStamp })}
        />
      </Fragment>
    );
  }

  private renderListView(): ReactNode {
    return (
      <Fragment>
        <WrapperTitle2
          breadcrumbList={this.state.titleBreadcrumbs}
          minWidth="900px"
        >
          <AdminSButton
            endIcon={AddIcon}
            fontWeight="medium"
            iconColor={SSvgIconColorProps.white}
            labelText={this.text("MeasurementSetsWrapper", "addNewSet")}
            onClick={(): void => this.startCreateNew("measurementSet")}
            showLoader={false}
          />
        </WrapperTitle2>
        <SetsListView
          clearSelectedGroup={(): void => this.setState({ selectedMeasGroupId: "" })}
          dataItems={this.state.measurementSets}
          filterText={this.state.tableSearchValue}
          onSelectMeasGroup={(_event, group: MeasurementSetTreeItem): void => this.setState({ selectedMeasGroupId: group.setId === this.state.selectedMeasGroupId ? "" : group.setId })}
          onTableSearchValueChange={(event): void => this.setState({ tableSearchValue: event.target.value })}
          selectedMeasGroupId={this.state.selectedMeasGroupId}
          tableSearchValue={this.state.tableSearchValue}
        />
      </Fragment>
    );
  }

  public render(): ReactNode {
    let view: ReactNode = null;

    if (this.state.editSetMode) {
      view = this.renderCreateView();
    } else if (this.state.measurementSetData !== null) {
      view = this.renderDataView();
    } else {
      view = this.renderListView();
    }
    return (
      <WrapperContainer>
        {view}
      </WrapperContainer>
    );
  }

  public onNameChange(text: string, target: MeasSetEditTarget): void {
    const crumbs = [...this.state.titleBreadcrumbs];
    const noTextFromUser = text.length === 0;
    const isExistingSet = this.isSetSelected();

    if (target === "measurementSet") {
      crumbs[2] = {
        ...this.state.titleBreadcrumbs[2],
        name: noTextFromUser
          ? isExistingSet
            ? this.text("MeasurementSetsWrapper", "editMeasurementSet")
            : this.text("MeasurementSetsWrapper", "newMeasurementSet")
          : text };
      this.setState({ newSetName: text });
    } else if (target === "chartConfig"){
      crumbs[3] = { ...this.state.titleBreadcrumbs[3], name: noTextFromUser ? this.text("MeasurementSetsWrapper", "chartConfig") : text };
      this.setState({ newChartConfigName: text });
    } else {
      console.error("unknown target in MeasurementSetsWrapper.onNameChange");
    }
    this.setState({ titleBreadcrumbs: crumbs });
  }

  private startCreateNew(target: MeasSetEditTarget): void {
    const crumbs = [...this.state.titleBreadcrumbs];

    if (target === "measurementSet") {
      crumbs[1] = {
        name: this.text("MeasSetGroupMenu", "group"),
        link: (): void => this.handleGroupNameCrumbClick(),
      };
      crumbs[2] = {
        name: this.text("MeasurementSetsWrapper", "newMeasurementSet"),
        link: (): void => undefined,
      };
      this.setState({ editSetMode: true, newSetName: "", selectedMeasGroupId: "" });
    } else if (target === "chartConfig"){
      crumbs[2] = { ...this.state.titleBreadcrumbs[2], link: (): void => this.cancelEditing("chartConfig") };
      crumbs[3] = {
        name: this.text("MeasurementSetsWrapper", "chartConfig"),
        link: (): void => undefined,
      };
      this.setState({ editChartConfigMode: true, newChartConfigName: "" });
    } else {
      console.error("unknown target in MeasurementSetsWrapper.startCreateNew");
    }
    this.setState({ titleBreadcrumbs: crumbs });
  }

  private startEditExisting(target: MeasSetEditTarget, targetName: string): void {
    const crumbs = [this.state.titleBreadcrumbs[0], this.state.titleBreadcrumbs[1]];

    if (target === "measurementSet") {
      crumbs[2] = {
        name: targetName,
        link: (): void => undefined,
      };
      this.setState({
        editSetMode: true,
        selectedMeasGroupId: (this.setSelector.getSelectedMeasurementSet() as MeasurementSetConfig).parentIds[0], // this is triggered only in data view and data view is not rendered if a set is not selected
      });
    } else if (target === "chartConfig") {
      crumbs[2] = { ...this.state.titleBreadcrumbs[2], link: (): void => this.cancelEditing("chartConfig") };
      crumbs[3] = {
        name: targetName,
        link: (): void => undefined,
      };
      this.setState({ editChartConfigMode: true, newChartConfigName: targetName });
    } else {
      console.error("unknown target in MeasurementSetsWrapper.startEditExisting");
    }
    this.setState({ titleBreadcrumbs: crumbs });
  }

  private cancelEditing(target: MeasSetEditTarget): void {
    const selectedSet = this.setSelector.getSelectedMeasurementSet();
    const crumbs = [this.state.titleBreadcrumbs[0]];

    if (target === "measurementSet") {
      if (selectedSet !== null) {
        crumbs[1] = this.state.titleBreadcrumbs[1];
        crumbs[2] = {
          name: selectedSet.displayName,
          link: (): void => undefined,
        };
      } else if (this.state.selectedMeasGroupId !== ""){
        crumbs[1] = {
          name: this.getParentGroupName(this.state.selectedMeasGroupId),
          link: (): void => undefined,
        };
      }
      this.setState({ editSetMode: false });
    } else if (target === "chartConfig") {
      crumbs[1] = this.state.titleBreadcrumbs[1];
      crumbs[2] = {
        name: this.state.newSetName === ""
          ? selectedSet !== null
            ? this.text("MeasurementSetsWrapper", "editMeasurementSet")
            : this.text("MeasurementSetsWrapper", "newMeasurementSet")
          : this.state.newSetName,
        link: (): void => undefined,
      };
      this.setState({ editChartConfigMode: false });
    } else {
      console.error("unknown target in MeasurementSetsWrapper.cancelEditing");
    }
    this.setState({ titleBreadcrumbs: crumbs });
  }

  private save(target: MeasSetEditTarget): void {
    const isExistingSet = this.isSetSelected();

    if (target === "measurementSet") {
      this.actionRequest(MeasSetsButtonActions.saveSet);
    } else if (target === "chartConfig") {
      // editChartConfigMode is set to false in saveChartConfig() of MeasurementSetEditView to prevent ChartConfigEditView unmounting before chartConfig is saved
      this.actionRequest(MeasSetsButtonActions.saveChartConfig);

      if (this.state.chartConfigValidityStatus === ChartConfigValidityStatus.valid) {
        const crumbs = [this.state.titleBreadcrumbs[0], this.state.titleBreadcrumbs[1]];
        crumbs[2] = {
          name: this.state.newSetName === ""
            ? isExistingSet
              ? this.text("MeasurementSetsWrapper", "editMeasurementSet")
              : this.text("MeasurementSetsWrapper", "newMeasurementSet")
            : this.state.newSetName,
          link: (): void => undefined,
        };
        this.setState({ titleBreadcrumbs: crumbs });
      }
    } else {
      console.error("unknown target in MeasurementSetsWrapper.save");
    }
  }

  private deleteSet(): void {
    const crumbs = [this.state.titleBreadcrumbs[0], this.state.titleBreadcrumbs[1]];
    this.actionRequest(MeasSetsButtonActions.deleteSet);
    this.setState({ titleBreadcrumbs: crumbs });
  }

  private getParentGroupName(setId: string): string {
    const item = this.setRepo.findParentGroupFromTree([setId])[0];

    if (item.type === MeasurementSetType.GROUP) {
      return (item as MeasurementSetGroup).displayName;
    } else {
      console.error("error in MeasurementSetsWrapper.getParentName / found parent item was not a group");
      return "???";
    }
  }

  private handleGroupNameCrumbClick(): void {
    console.log("handleGroupNameCrumbClick");
    const crumbs = [this.state.titleBreadcrumbs[0]];

    if (this.state.selectedMeasGroupId !== "") {
      crumbs[1] = {
        name: this.getParentGroupName(this.state.selectedMeasGroupId),
        link: (): void => undefined,
      };
    }
    this.setState({ editChartConfigMode: false, editSetMode: false, titleBreadcrumbs: crumbs });

  }

  private handleSelectMeasGroup (event: React.MouseEvent<Element, MouseEvent>, group: MeasurementSetTreeItem): void {
    event.preventDefault();
    event.stopPropagation();
    const { setId } = group;
    this.setState({ selectedMeasGroupId: setId === this.state.selectedMeasGroupId ? "" : setId });
  }

  private updateMeasurementSetDataInState(): void {
    // assertion: method is never called if getSelectedMeasurementSet === null
    const measurementSet = this.setSelector.getSelectedMeasurementSet()!;
    this.setState({
      measurementSetData: new MeasurementSetDataContainer(
        measurementSet,
        this.state.endTimeStamp,
        Utils.convertHoursToTimestamp(this.state.timeSpanInHours ?? DEFAULT_TIME_SPAN),
        this.onMeasurementSetDataReady),
      dataInitialized: false,
    });
  }

  private isSetSelected(): boolean {
    return this.setSelector.getSelectedMeasurementSet() !== null;
  }

  public actionRequest(req: MeasSetsButtonActions): void {

    if (this.state.actionRequest === null) {
      this.setState({ actionRequest: req });
    } else {
      console.error("Requested new action before previous was served");
    }
  }

  public clearActionRequest(): void {
    this.setState({ actionRequest: null });
  }
}

export default withDataJanitor(MeasurementSetsWrapper, [
  DataRepositories.MeasurementSet,
]);
