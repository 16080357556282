import { Themes } from "../themes";

export interface IconColorCollection {
  [name: string]: React.CSSProperties["color"];
}

export default class IconColors {

  public static getIconColorsForThemeSelector(themeName: Themes): IconColorCollection {
    let IconColors: IconColorCollection;

    if (themeName === Themes.dark || themeName === Themes.dark_V2) {
      IconColors = {
        primary: "#4F4F4F",
        secondary: "#787878",
        disabled: "#525252",
      };
    } else if (themeName === Themes.light || themeName === Themes.light_V2) {
      IconColors = {
        primary: "#C4C4C4",
        secondary: "#787878",
        disabled: "#3B3B3B",
      };
    } else {
      // unknown theme, use values for dark theme
      console.error("Unknown theme in IconColors.ts");
      IconColors = {
        primary: "#4F4F4F",
        secondary: "#787878",
        disabled: "#525252",
      };
    }
    return IconColors;
  }

}
