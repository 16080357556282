import EventsRepository from "data/data-storage/EventsRepository";
import DataRepository from "./DataRepository";
import DeviceRepository from "./DeviceRepository";
import LatestDeviceDataRepository from "./LatestDeviceDataRepository";
import MeasurementJobRepository from "./MeasurementJobRepository";
import MeasurementSetRepository from "./MeasurementSetRepository";

export enum DataRepositories {
  Device,
  Events,
  LatestDeviceData,
  MeasurementJob,
  MeasurementSet,
}

export default class DataRepositoryFactory {
  public static getRepository(type: DataRepositories): DataRepository {
    switch (type) {
      case DataRepositories.Device:
        return DeviceRepository.getInstance();

      case DataRepositories.Events:
        return EventsRepository.instance;

      case DataRepositories.LatestDeviceData:
        return LatestDeviceDataRepository.getInstance();

      case DataRepositories.MeasurementJob:
        return MeasurementJobRepository.getInstance();

      case DataRepositories.MeasurementSet:
        return MeasurementSetRepository.getInstance();
      default:
        throw new Error("Unknown DataRepository");
    }
  }

  public static clearRepositories(): void {
    DeviceRepository.getInstance().clear();
    EventsRepository.instance.clear();
    LatestDeviceDataRepository.getInstance().clear();
    MeasurementJobRepository.getInstance().clear();
    MeasurementSetRepository.getInstance().clear();
  }

  public static initRepositories(): void {
    DeviceRepository.getInstance().init();
    EventsRepository.instance.init();
    LatestDeviceDataRepository.getInstance().init();
    MeasurementJobRepository.getInstance().init();
    MeasurementSetRepository.getInstance().init();
  }

}
