import { ReferenceHWState } from "../ReferenceHW/ReferenceHWState";
import { Nullable, Voidable } from "../../../types/aliases";
import { PiikkioHWStateProperties as PiikkioHWStateProperties } from "./PiikkioHWStateProperties";

export enum PiikkioIntervalTypes {
  MEASURE,
  UPDATE
}

export class PiikkioHWState extends ReferenceHWState<PiikkioHWStateProperties> {

  private static readonly DEFAULT_INTERVAL = 15;
  private static readonly MAX_INTERVAL = 3600;
  private static readonly MIN_UPDATE_INTERVAL = 0;
  private static readonly MIN_MEASUREMENT_INTERVAL = 5;

  public static toValidInterval(value: Voidable<number>, type: PiikkioIntervalTypes): number {
    if (value == null) {
      return PiikkioHWState.DEFAULT_INTERVAL;
    }

    if (value > PiikkioHWState.MAX_INTERVAL) {
      return PiikkioHWState.MAX_INTERVAL;
    }
    const min = PiikkioHWState.getMinIntervalValue(type);

    if (value < min) {
      return min;
    }
    return value;
  }

  public static getMinIntervalValue(type: PiikkioIntervalTypes): number {
    switch (type) {
      case PiikkioIntervalTypes.MEASURE:
        return this.MIN_MEASUREMENT_INTERVAL;
      case PiikkioIntervalTypes.UPDATE:
        return this.MIN_UPDATE_INTERVAL;
      default:
        return 0;
    }
  }

  public get noModemSleep(): Nullable<boolean> {
    return this.deltaExists("noModemSleep") ? this.desired.noModemSleep : this.reported.noModemSleep;
  }

  public set noModemSleep(enabled: Nullable<boolean>) {
    this.desired.noModemSleep = enabled;
    this.changedValues.noModemSleep = enabled;
  }

  public get updateInterval(): Nullable<number> {
    return this.deltaExists("updateInterval") ? this.desired.updateInterval : this.reported.updateInterval;
  }

  public set updateInterval(value: Nullable<number>) {
    const validatedValue = PiikkioHWState.toValidInterval(value, PiikkioIntervalTypes.UPDATE);
    this.desired.updateInterval = validatedValue;
    this.changedValues.updateInterval = validatedValue;
  }

  public get measurementInterval(): Nullable<number> {
    return this.deltaExists("measurementInterval") ? this.desired.measurementInterval : this.reported.measurementInterval;
  }

  public set measurementInterval(value: Nullable<number>) {
    const validatedValue = PiikkioHWState.toValidInterval(value, PiikkioIntervalTypes.MEASURE);
    this.desired.measurementInterval = validatedValue;
    this.changedValues.measurementInterval = validatedValue;
  }
}
