import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSJSON: string;
}

export interface Query {
  __typename?: 'Query';
  actionsRulesList?: Maybe<ActionRuleList>;
  devicesEventsList?: Maybe<Events>;
  eventsList?: Maybe<Events>;
  eventsMetadataList?: Maybe<EventMetadataList>;
  eventsTriggerRulesList?: Maybe<Scalars['AWSJSON']>;
  notificationsSubscriptionsList?: Maybe<NotificationSubscriptions>;
}


export interface QueryActionsRulesListArgs {
  eventId?: InputMaybe<Scalars['ID']>;
  nextToken?: InputMaybe<Scalars['ID']>;
}


export interface QueryDevicesEventsListArgs {
  deviceId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['ID']>;
  period?: InputMaybe<TimePeriod>;
}


export interface QueryEventsListArgs {
  endTimestamp: Scalars['String'];
  nextToken?: InputMaybe<Scalars['ID']>;
  startTimestamp: Scalars['String'];
}


export interface QueryEventsMetadataListArgs {
  nextToken?: InputMaybe<Scalars['ID']>;
}


export interface QueryEventsTriggerRulesListArgs {
  deviceId: Scalars['ID'];
  dummy?: InputMaybe<Scalars['String']>;
}


export interface QueryNotificationsSubscriptionsListArgs {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
}

export interface ActionRuleList {
  __typename?: 'ActionRuleList';
  nextToken?: Maybe<Scalars['ID']>;
  rules: Array<ActionRule>;
}

export interface ActionRule {
  __typename?: 'ActionRule';
  eventId: Scalars['ID'];
  name: Scalars['String'];
  rule: Scalars['AWSJSON'];
  ruleId: Scalars['ID'];
  templates?: Maybe<Scalars['AWSJSON']>;
}

export interface TimePeriod {
  endTimestamp: Scalars['String'];
  startTimestamp: Scalars['String'];
}

export interface Events {
  __typename?: 'Events';
  events: Array<Event>;
  nextToken?: Maybe<Scalars['String']>;
}

export interface Event {
  __typename?: 'Event';
  deviceId: Scalars['ID'];
  eventId?: Maybe<Scalars['ID']>;
  eventState?: Maybe<EventState>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  metadata?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['ID']>;
  sensorName?: Maybe<Scalars['String']>;
  sensorValue?: Maybe<Scalars['Float']>;
  severity?: Maybe<EventSeverity>;
  timestamp: Scalars['String'];
  type?: Maybe<EventType>;
  updatedTimestamp?: Maybe<Scalars['String']>;
}

export enum EventState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum EventSeverity {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export enum EventType {
  Generic = 'GENERIC',
  Sensor = 'SENSOR'
}

export interface EventMetadataList {
  __typename?: 'EventMetadataList';
  eventMetadataItems: Array<EventMetadata>;
  nextToken?: Maybe<Scalars['String']>;
}

export interface EventMetadata {
  __typename?: 'EventMetadata';
  description?: Maybe<Scalars['String']>;
  eventId: Scalars['ID'];
}

export interface NotificationSubscriptions {
  __typename?: 'NotificationSubscriptions';
  subscriptions: Array<NotificationSubscription>;
}

export interface NotificationSubscription {
  __typename?: 'NotificationSubscription';
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  ruleIds: Array<Scalars['ID']>;
  type: NotificationType;
  userId: Scalars['ID'];
}

export enum NotificationType {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export interface Mutation {
  __typename?: 'Mutation';
  eventsDeactivate?: Maybe<Event>;
  eventsMetadataAdd?: Maybe<EventMetadata>;
  eventsMetadataDelete?: Maybe<Scalars['AWSJSON']>;
  eventsNotify?: Maybe<EventNotice>;
  eventsTriggerRulesAdd?: Maybe<Scalars['AWSJSON']>;
  eventsTriggerRulesDelete?: Maybe<Scalars['AWSJSON']>;
  notificationsSubscriptionsCreate?: Maybe<NotificationSubscription>;
  notificationsSubscriptionsRemove?: Maybe<UnsubscribeInfo>;
}


export interface MutationEventsDeactivateArgs {
  payload: EventPayload;
}


export interface MutationEventsMetadataAddArgs {
  description?: InputMaybe<Scalars['String']>;
  eventId: Scalars['ID'];
}


export interface MutationEventsMetadataDeleteArgs {
  eventId: Scalars['ID'];
}


export interface MutationEventsNotifyArgs {
  payload: EventPayload;
  receiver: Scalars['ID'];
}


export interface MutationEventsTriggerRulesAddArgs {
  deviceId?: InputMaybe<Scalars['String']>;
  rules: Scalars['AWSJSON'];
  triggerId: Scalars['ID'];
}


export interface MutationEventsTriggerRulesDeleteArgs {
  triggerId: Scalars['ID'];
}


export interface MutationNotificationsSubscriptionsCreateArgs {
  subscriptionDetails: SubscriptionDetails;
}


export interface MutationNotificationsSubscriptionsRemoveArgs {
  subscriptionId: Scalars['ID'];
  userId: Scalars['ID'];
}

export interface EventPayload {
  deviceId: Scalars['ID'];
  eventId?: InputMaybe<Scalars['ID']>;
  eventState?: InputMaybe<EventState>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  metadata?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  sensorName?: InputMaybe<Scalars['String']>;
  sensorValue?: InputMaybe<Scalars['Float']>;
  severity?: InputMaybe<EventSeverity>;
  timestamp: Scalars['String'];
  type?: InputMaybe<EventType>;
  updatedTimestamp?: InputMaybe<Scalars['String']>;
}

export interface EventNotice {
  __typename?: 'EventNotice';
  item: Event;
  receiver: Scalars['ID'];
}

export interface SubscriptionDetails {
  deviceId?: InputMaybe<Scalars['ID']>;
  organizationId: Scalars['ID'];
  ruleId?: InputMaybe<Scalars['ID']>;
  type: NotificationType;
  userId: Scalars['ID'];
}

export interface UnsubscribeInfo {
  __typename?: 'UnsubscribeInfo';
  subscription?: Maybe<NotificationSubscription>;
}

export interface Subscription {
  __typename?: 'Subscription';
  eventsFeed?: Maybe<EventNotice>;
}


export interface SubscriptionEventsFeedArgs {
  receiver: Scalars['ID'];
}

export interface Schema {
  __typename?: 'Schema';
  mutation?: Maybe<Mutation>;
  query?: Maybe<Query>;
  subscription?: Maybe<Subscription>;
}

export type EventFieldsFragment = { __typename?: 'Event', deviceId: string, timestamp: string, eventId?: string | null | undefined, organizationId?: string | null | undefined, updatedTimestamp?: string | null | undefined, type?: EventType | null | undefined, eventState?: EventState | null | undefined, latitude?: number | null | undefined, longitude?: number | null | undefined, severity?: EventSeverity | null | undefined, sensorName?: string | null | undefined, sensorValue?: number | null | undefined, metadata?: string | null | undefined };

export type SubscriptionFieldsFragment = { __typename?: 'NotificationSubscription', id: string, userId: string, ruleIds: Array<string>, organizationId: string, type: NotificationType };

export type RulesFieldsFragment = { __typename?: 'ActionRule', ruleId: string, eventId: string, name: string, rule: string, templates?: string | null | undefined };

export type MetadataFieldsFragment = { __typename?: 'EventMetadata', description?: string | null | undefined, eventId: string };

export type EventsListQueryVariables = Exact<{
  startTimestamp: Scalars['String'];
  endTimestamp: Scalars['String'];
  nextToken?: InputMaybe<Scalars['ID']>;
}>;


export type EventsListQuery = { __typename?: 'Query', eventsList?: { __typename?: 'Events', nextToken?: string | null | undefined, events: Array<{ __typename?: 'Event', deviceId: string, timestamp: string, eventId?: string | null | undefined, organizationId?: string | null | undefined, updatedTimestamp?: string | null | undefined, type?: EventType | null | undefined, eventState?: EventState | null | undefined, latitude?: number | null | undefined, longitude?: number | null | undefined, severity?: EventSeverity | null | undefined, sensorName?: string | null | undefined, sensorValue?: number | null | undefined, metadata?: string | null | undefined }> } | null | undefined };

export type DevicesEventsListQueryVariables = Exact<{
  deviceId: Scalars['ID'];
  period?: InputMaybe<TimePeriod>;
  nextToken?: InputMaybe<Scalars['ID']>;
}>;


export type DevicesEventsListQuery = { __typename?: 'Query', devicesEventsList?: { __typename?: 'Events', nextToken?: string | null | undefined, events: Array<{ __typename?: 'Event', deviceId: string, timestamp: string, eventId?: string | null | undefined, organizationId?: string | null | undefined, updatedTimestamp?: string | null | undefined, type?: EventType | null | undefined, eventState?: EventState | null | undefined, latitude?: number | null | undefined, longitude?: number | null | undefined, severity?: EventSeverity | null | undefined, sensorName?: string | null | undefined, sensorValue?: number | null | undefined, metadata?: string | null | undefined }> } | null | undefined };

export type EventsMetadataListQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['ID']>;
}>;


export type EventsMetadataListQuery = { __typename?: 'Query', eventsMetadataList?: { __typename?: 'EventMetadataList', nextToken?: string | null | undefined, eventMetadataItems: Array<{ __typename?: 'EventMetadata', eventId: string, description?: string | null | undefined }> } | null | undefined };

export type NotificationsSubscriptionsListQueryVariables = Exact<{
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
}>;


export type NotificationsSubscriptionsListQuery = { __typename?: 'Query', notificationsSubscriptionsList?: { __typename?: 'NotificationSubscriptions', subscriptions: Array<{ __typename?: 'NotificationSubscription', id: string, userId: string, ruleIds: Array<string>, organizationId: string, type: NotificationType }> } | null | undefined };

export type ActionsRulesListQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['ID']>;
  nextToken?: InputMaybe<Scalars['ID']>;
}>;


export type ActionsRulesListQuery = { __typename?: 'Query', actionsRulesList?: { __typename?: 'ActionRuleList', nextToken?: string | null | undefined, rules: Array<{ __typename?: 'ActionRule', ruleId: string, eventId: string, name: string, rule: string, templates?: string | null | undefined }> } | null | undefined };

export type EventsDeactivateMutationVariables = Exact<{
  payload: EventPayload;
}>;


export type EventsDeactivateMutation = { __typename?: 'Mutation', eventsDeactivate?: { __typename?: 'Event', deviceId: string, timestamp: string, eventId?: string | null | undefined, organizationId?: string | null | undefined, updatedTimestamp?: string | null | undefined, type?: EventType | null | undefined, eventState?: EventState | null | undefined, latitude?: number | null | undefined, longitude?: number | null | undefined, severity?: EventSeverity | null | undefined, sensorName?: string | null | undefined, sensorValue?: number | null | undefined, metadata?: string | null | undefined } | null | undefined };

export type NotificationsSubscriptionsCreateMutationVariables = Exact<{
  subscriptionDetails: SubscriptionDetails;
}>;


export type NotificationsSubscriptionsCreateMutation = { __typename?: 'Mutation', notificationsSubscriptionsCreate?: { __typename?: 'NotificationSubscription', id: string, userId: string, ruleIds: Array<string>, organizationId: string, type: NotificationType } | null | undefined };

export type NotificationsSubscriptionsRemoveMutationVariables = Exact<{
  userId: Scalars['ID'];
  subscriptionId: Scalars['ID'];
}>;


export type NotificationsSubscriptionsRemoveMutation = { __typename?: 'Mutation', notificationsSubscriptionsRemove?: { __typename?: 'UnsubscribeInfo', subscription?: { __typename?: 'NotificationSubscription', id: string, userId: string, ruleIds: Array<string>, organizationId: string, type: NotificationType } | null | undefined } | null | undefined };

export type EventsFeedSubscriptionVariables = Exact<{
  receiver: Scalars['ID'];
}>;


export type EventsFeedSubscription = { __typename?: 'Subscription', eventsFeed?: { __typename?: 'EventNotice', receiver: string, item: { __typename?: 'Event', deviceId: string, timestamp: string, eventId?: string | null | undefined, organizationId?: string | null | undefined, updatedTimestamp?: string | null | undefined, type?: EventType | null | undefined, eventState?: EventState | null | undefined, latitude?: number | null | undefined, longitude?: number | null | undefined, severity?: EventSeverity | null | undefined, sensorName?: string | null | undefined, sensorValue?: number | null | undefined, metadata?: string | null | undefined } } | null | undefined };

export type EventsTriggerRulesAddMutationVariables = Exact<{
  triggerId: Scalars['ID'];
  deviceId?: InputMaybe<Scalars['String']>;
  rules: Scalars['AWSJSON'];
}>;


export type EventsTriggerRulesAddMutation = { __typename?: 'Mutation', eventsTriggerRulesAdd?: string | null | undefined };

export type EventsTriggerRulesDeleteMutationVariables = Exact<{
  triggerId: Scalars['ID'];
}>;


export type EventsTriggerRulesDeleteMutation = { __typename?: 'Mutation', eventsTriggerRulesDelete?: string | null | undefined };

export type EventsTriggerRulesListQueryVariables = Exact<{
  deviceId: Scalars['ID'];
  dummy?: InputMaybe<Scalars['String']>;
}>;


export type EventsTriggerRulesListQuery = { __typename?: 'Query', eventsTriggerRulesList?: string | null | undefined };

export type EventsMetadataAddMutationVariables = Exact<{
  eventId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
}>;


export type EventsMetadataAddMutation = { __typename?: 'Mutation', eventsMetadataAdd?: { __typename?: 'EventMetadata', description?: string | null | undefined, eventId: string } | null | undefined };

export type EventsMetadataDeleteMutationVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type EventsMetadataDeleteMutation = { __typename?: 'Mutation', eventsMetadataDelete?: string | null | undefined };

export const EventFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"eventFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Event"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deviceId"}},{"kind":"Field","name":{"kind":"Name","value":"timestamp"}},{"kind":"Field","name":{"kind":"Name","value":"eventId"}},{"kind":"Field","name":{"kind":"Name","value":"organizationId"}},{"kind":"Field","name":{"kind":"Name","value":"updatedTimestamp"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"eventState"}},{"kind":"Field","name":{"kind":"Name","value":"latitude"}},{"kind":"Field","name":{"kind":"Name","value":"longitude"}},{"kind":"Field","name":{"kind":"Name","value":"severity"}},{"kind":"Field","name":{"kind":"Name","value":"sensorName"}},{"kind":"Field","name":{"kind":"Name","value":"sensorValue"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}}]}}]} as unknown as DocumentNode<EventFieldsFragment, unknown>;
export const SubscriptionFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"subscriptionFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"NotificationSubscription"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"userId"}},{"kind":"Field","name":{"kind":"Name","value":"ruleIds"}},{"kind":"Field","name":{"kind":"Name","value":"organizationId"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]} as unknown as DocumentNode<SubscriptionFieldsFragment, unknown>;
export const RulesFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"rulesFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ActionRule"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ruleId"}},{"kind":"Field","name":{"kind":"Name","value":"eventId"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"rule"}},{"kind":"Field","name":{"kind":"Name","value":"templates"}}]}}]} as unknown as DocumentNode<RulesFieldsFragment, unknown>;
export const MetadataFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"metadataFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"EventMetadata"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"eventId"}}]}}]} as unknown as DocumentNode<MetadataFieldsFragment, unknown>;
export const EventsListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"eventsList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"startTimestamp"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"endTimestamp"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventsList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"startTimestamp"},"value":{"kind":"Variable","name":{"kind":"Name","value":"startTimestamp"}}},{"kind":"Argument","name":{"kind":"Name","value":"endTimestamp"},"value":{"kind":"Variable","name":{"kind":"Name","value":"endTimestamp"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"events"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"eventFields"}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextToken"}}]}}]}},...EventFieldsFragmentDoc.definitions]} as unknown as DocumentNode<EventsListQuery, EventsListQueryVariables>;
export const DevicesEventsListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"devicesEventsList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"deviceId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"period"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"TimePeriod"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"devicesEventsList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"deviceId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"deviceId"}}},{"kind":"Argument","name":{"kind":"Name","value":"period"},"value":{"kind":"Variable","name":{"kind":"Name","value":"period"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"events"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"eventFields"}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextToken"}}]}}]}},...EventFieldsFragmentDoc.definitions]} as unknown as DocumentNode<DevicesEventsListQuery, DevicesEventsListQueryVariables>;
export const EventsMetadataListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"eventsMetadataList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventsMetadataList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"nextToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventMetadataItems"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventId"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextToken"}}]}}]}}]} as unknown as DocumentNode<EventsMetadataListQuery, EventsMetadataListQueryVariables>;
export const NotificationsSubscriptionsListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"notificationsSubscriptionsList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationsSubscriptionsList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}},{"kind":"Argument","name":{"kind":"Name","value":"organizationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"subscriptions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"subscriptionFields"}}]}}]}}]}},...SubscriptionFieldsFragmentDoc.definitions]} as unknown as DocumentNode<NotificationsSubscriptionsListQuery, NotificationsSubscriptionsListQueryVariables>;
export const ActionsRulesListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"actionsRulesList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"eventId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"actionsRulesList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"eventId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"eventId"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rules"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"rulesFields"}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextToken"}}]}}]}},...RulesFieldsFragmentDoc.definitions]} as unknown as DocumentNode<ActionsRulesListQuery, ActionsRulesListQueryVariables>;
export const EventsDeactivateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"eventsDeactivate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"payload"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EventPayload"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventsDeactivate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"payload"},"value":{"kind":"Variable","name":{"kind":"Name","value":"payload"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"eventFields"}}]}}]}},...EventFieldsFragmentDoc.definitions]} as unknown as DocumentNode<EventsDeactivateMutation, EventsDeactivateMutationVariables>;
export const NotificationsSubscriptionsCreateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"notificationsSubscriptionsCreate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"subscriptionDetails"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SubscriptionDetails"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationsSubscriptionsCreate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"subscriptionDetails"},"value":{"kind":"Variable","name":{"kind":"Name","value":"subscriptionDetails"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"subscriptionFields"}}]}}]}},...SubscriptionFieldsFragmentDoc.definitions]} as unknown as DocumentNode<NotificationsSubscriptionsCreateMutation, NotificationsSubscriptionsCreateMutationVariables>;
export const NotificationsSubscriptionsRemoveDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"notificationsSubscriptionsRemove"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"subscriptionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationsSubscriptionsRemove"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}},{"kind":"Argument","name":{"kind":"Name","value":"subscriptionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"subscriptionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"subscription"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"subscriptionFields"}}]}}]}}]}},...SubscriptionFieldsFragmentDoc.definitions]} as unknown as DocumentNode<NotificationsSubscriptionsRemoveMutation, NotificationsSubscriptionsRemoveMutationVariables>;
export const EventsFeedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"eventsFeed"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"receiver"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventsFeed"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"receiver"},"value":{"kind":"Variable","name":{"kind":"Name","value":"receiver"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"receiver"}},{"kind":"Field","name":{"kind":"Name","value":"item"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"eventFields"}}]}}]}}]}},...EventFieldsFragmentDoc.definitions]} as unknown as DocumentNode<EventsFeedSubscription, EventsFeedSubscriptionVariables>;
export const EventsTriggerRulesAddDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"eventsTriggerRulesAdd"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"triggerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"deviceId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"rules"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AWSJSON"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventsTriggerRulesAdd"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"triggerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"triggerId"}}},{"kind":"Argument","name":{"kind":"Name","value":"deviceId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"deviceId"}}},{"kind":"Argument","name":{"kind":"Name","value":"rules"},"value":{"kind":"Variable","name":{"kind":"Name","value":"rules"}}}]}]}}]} as unknown as DocumentNode<EventsTriggerRulesAddMutation, EventsTriggerRulesAddMutationVariables>;
export const EventsTriggerRulesDeleteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"eventsTriggerRulesDelete"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"triggerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventsTriggerRulesDelete"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"triggerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"triggerId"}}}]}]}}]} as unknown as DocumentNode<EventsTriggerRulesDeleteMutation, EventsTriggerRulesDeleteMutationVariables>;
export const EventsTriggerRulesListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"eventsTriggerRulesList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"deviceId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dummy"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventsTriggerRulesList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"deviceId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"deviceId"}}},{"kind":"Argument","name":{"kind":"Name","value":"dummy"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dummy"}}}]}]}}]} as unknown as DocumentNode<EventsTriggerRulesListQuery, EventsTriggerRulesListQueryVariables>;
export const EventsMetadataAddDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"eventsMetadataAdd"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"eventId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"description"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventsMetadataAdd"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"eventId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"eventId"}}},{"kind":"Argument","name":{"kind":"Name","value":"description"},"value":{"kind":"Variable","name":{"kind":"Name","value":"description"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"metadataFields"}}]}}]}},...MetadataFieldsFragmentDoc.definitions]} as unknown as DocumentNode<EventsMetadataAddMutation, EventsMetadataAddMutationVariables>;
export const EventsMetadataDeleteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"eventsMetadataDelete"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"eventId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventsMetadataDelete"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"eventId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"eventId"}}}]}]}}]} as unknown as DocumentNode<EventsMetadataDeleteMutation, EventsMetadataDeleteMutationVariables>;