import React, { Fragment, RefObject } from "react";
import { Box, PropTypes, Theme, Typography, withTheme } from "@material-ui/core";
import { Maybe } from "types/aliases";

type TextColor = 
  | "initial"
  | "inherit"
  | "primary"
  | "secondary"
  | "textPrimary"
  | "textSecondary"
  | "error";

interface Props {
  text: string;
  theme: Theme;
  color?: TextColor;
  hoverColor?: TextColor;
  hoverBgColor?: string;
  textAlign?: PropTypes.Alignment;
  hoverTextAdd?: string;
}
  
interface State {
  hover: boolean;
}
class SText extends React.Component<Props, State> {

  private myBox: RefObject<HTMLDivElement>;

  public constructor(props: Props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.myBox = React.createRef();
  }

  public render(): JSX.Element {
    const hover = this.state.hover;

    return (
      <Fragment>
        <Box
          // position={hover ? "sticky" : undefined}
          // pr={hover ? 1 : undefined}
          onMouseLeave={(): void => this.setState({ hover: false })}
          onMouseEnter={(): void => this.setState({ hover: true })}
          // bgcolor={hover ? (this.props.hoverBgColor ?? this.props.theme.palette.background.paper) : undefined}
          // width={hover ? "fit-content" : "100%"}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          ref={this.myBox}
          style={{ 
            background: hover ? (this.props.hoverBgColor ?? this.props.theme.palette.background.paper) : undefined, 
            paddingRight: hover ? "4px" : undefined,
            position: hover ? "sticky" : undefined,
            transition: this.getTransition(), 
            width: hover ? "fit-content" : "100%",
          }}>
          <Typography 
            align={this.props.textAlign}
            // noWrap={!hover}
            variant="body1"
            // color={hover ? (this.props.hoverColor ?? this.props.color) : this.props.color}
            style={{ 
              color: this.getHexValuesFromThemeColors(hover ? (this.props.hoverColor ?? this.props.color) : this.props.color),
              overflow: hover ? undefined : "hidden", 
              textOverflow: hover ? undefined : "ellipsis",
              transition: this.getTransition(), 
              whiteSpace: hover ? undefined : "nowrap", 
            }}
          >
            {this.props.text}{this.props.hoverTextAdd !== undefined && hover ? this.props.hoverTextAdd : ""}
          </Typography>
        </Box>
      </Fragment>
    );
  }

  private getHexValuesFromThemeColors(color: Maybe<TextColor>): Maybe<string> {
    if (color === "textPrimary") {
      return this.props.theme.palette.text.primary;
    } else if (color === "textSecondary"){
      return this.props.theme.palette.text.secondary;
    } else if (color === "primary") {
      return this.props.theme.palette.primary.main;
    } else if (color === "secondary"){
      return this.props.theme.palette.secondary.main;
    } else if (color === "error"){
      return this.props.theme.palette.error.main;
    } else {
      return undefined;
    }
  }

  private getTransition(): string {
    const timingFunc = this.props.theme.transitions.easing.sharp;
    const duration = this.props.theme.transitions.duration.leavingScreen; 
    return `all ${duration}ms ${timingFunc}`;
  }
}

export default withTheme(SText);
