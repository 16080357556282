import React, { Component, Fragment, ReactNode } from "react";
import DeviceGroup from "data/device/DeviceGroup";
import Localization from "data/localization-sensoan/Localization";
import ConfirmationDialog from "components/ui/confirmation-dialog";
import ErrorDialog from "components/ui/error-dialog";

interface Props {
  group: DeviceGroup;
  open: boolean;
  onClose: (success: boolean) => void;
}

interface State {
  loading: boolean;
  errorMsg?: string;
}

export class DeleteGroupPopup extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  private deleteGroup = async (): Promise<void> => {
    if (!this.props.group) {
      return;
    }
    this.setState({ loading: true });

    try {
      await this.props.group.delete();
      this.props.onClose(true);
    } catch (error) {
      console.error("Failed to remove device group", error);
      this.setState({ errorMsg: "Group removal failed" });
    } finally {
      this.setState({ loading: false });
    }
  };

  public render(): ReactNode {
    if (!this.props.open) {
      return null;
    }

    const removeText = this.text("Actions", "remove");

    return (
      <Fragment>
        <ErrorDialog
          onClose={(): void => {
            this.setState({ errorMsg: undefined });
            this.props.onClose(false);
          }}
          errorMsg={this.state.errorMsg}
        />
        <ConfirmationDialog
          title={removeText}
          message={`${removeText} ${this.props.group.getLabel()}?`}
          onConfirm={this.deleteGroup}
          onCancel={(): void => this.props.onClose(false)}
          showLoader={this.state.loading}
        />
      </Fragment>
    );
  }
}
