import { Nullable } from "types/aliases";
import { ChartData } from "components/charts/chartDataTypes";
import LatestData from "data/data/LatestData";
import LatestDeviceDataRepository from "data/data-storage/LatestDeviceDataRepository";
import { ChartConfig, MeasurementSetConfig, TimeScaleType } from "data/types/measurementSetTypes";
import MeasurementDataTools from "./MeasurementDataTools";
import MeasurementDataSet from "./MeasurementDataSet";

export default class MeasurementSetDataContainer {
  private readonly measurementSet: MeasurementSetConfig;
  private containerInitialized: boolean;
  private initReadyCallback: () => void;
  private measurementSetDataSet: Nullable<MeasurementDataSet> = null;

  public constructor (measurementSet: MeasurementSetConfig, endTimestamp: number, timeSpan: number, initReadyCallback: () => void) {
    this.measurementSet = measurementSet;
    this.initReadyCallback = initReadyCallback;
    this.containerInitialized = false;

    if (this.measurementSet) {
      this.init(endTimestamp, timeSpan);
    }
  }

  private async init(endTimestamp: number, timeSpan: number): Promise<void> {
    // this.init2(endTimestamp, timeSpan);
    const endTime = endTimestamp;
    const startTime = endTime - timeSpan;

    const loaderTasks: Promise<void | Nullable<LatestData>>[] = [];
    // const historyDataLoading: string[] = [];
    const latestDataLoading: string[] = [];

    // jos on yksikin ch.config johon on valittu muu kuin LATEST
    if (this.measurementSet.config.chartConfig.some(({ timeScaleType }) => timeScaleType === TimeScaleType.HISTORY || timeScaleType === TimeScaleType.AGGREGATE)) {
      // luo uusi measurementSetDataSet aikatiedoilla ja tämän setin tiedoilla
      this.measurementSetDataSet = new MeasurementDataSet(startTime, endTimestamp, this.measurementSet);
      loaderTasks.push(this.measurementSetDataSet.fetchData());
    }

    for (const chartConfig of this.measurementSet.config.chartConfig) {
      if (chartConfig.timeScaleType === TimeScaleType.LATEST) {
        for (const dataConfig of chartConfig.dataConfig) {
          // load data for all deviceIds
          // if (chartConfig.timeScaleType === TimeScaleType.LATEST) {
          if (!latestDataLoading.includes(dataConfig.deviceId)) {
            latestDataLoading.push(dataConfig.deviceId);
            loaderTasks.push(LatestDeviceDataRepository.getInstance().fetchData(dataConfig.deviceId));
          }
        }
      }
    }
    await Promise.all(loaderTasks);

    console.log("MeasurementSetDataContainer initialized");
    this.containerInitialized = true;
    // all done, call UI element
    this.initReadyCallback();
  }

  public getChartDataTs(chartConfig: ChartConfig, chartIndex: number): Nullable<ChartData[]> {
    // TODO: remove if and add assertion
    if (this.measurementSetDataSet) {
      const data = this.measurementSetDataSet.getData(chartIndex);

      if (data && data.length > 0) {
        return MeasurementDataTools.buildChartDataTs(data, chartConfig);
      }
    }
    return null;
  }

  public getGaugeData(chartConfig: ChartConfig): Nullable<number> {
    const config = chartConfig.dataConfig[0];
    const data = LatestDeviceDataRepository.getInstance().getData(config.deviceId);

    if (data) {
      return data[config.sensorName] as unknown as number ?? null;
    }
    return null;
  }
}
