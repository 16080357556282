import { Box, Divider, InputAdornment, MenuItem, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { Component, Fragment, ReactNode } from "react";
import Localization from "data/localization-sensoan/Localization";
import JobsListHeaderRow from "./JobsListHeaderRow";
import JobsListDataRow from "./JobsListDataRow";
import IoTDataRow from "components/layout/iot-data-row/IoTDataRow";
import ListViewFab from "components/layout/ListViewFab";
import { MeasurementJob, MeasurementJobStatus } from "data/types/measurementJobTypes";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import MeasurementDataTools from "data/measurement-data/MeasurementDataTools";
import MeasurementJobRepository, { MeasurementJobObserver } from "data/data-storage/MeasurementJobRepository";
import Section from "components/layout/Section";
import { Nullable } from "types/aliases";
import MeasurementSetSelector, { MeasurementSetSelectorObserver } from "data/measurement-set-selector/MeasurementSetSelector";
import { MeasurementSetConfig, MeasurementSetTreeItem } from "data/types/measurementSetTypes";
import { sortList } from "data/utils/mathUtils";
import SFilledInput from "components/styled-components/SFilledInput";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import MapLink, { MapMarkerData } from "data/map/MapLink";
import MeasSetGroupMenu2 from "components/inputs/meas-set-group-menu/MeasSetGroupMenu";
import SSelect from "components/styled-components/SSelect";

interface Props {
}

interface State {
  measSetSelectorAnchorEl: Nullable<HTMLButtonElement>;
  mapMarkerData: Nullable<MapMarkerData[]>; // handling color changing in location buttons is faster if we keep a copy of marker data in state
  filteredJobList: MeasurementJob[];
  rowCount: number;
  searchText: Nullable<string>;
  sortedJobList: MeasurementJob[];
  sortKey: keyof MeasurementJob;
  sortOrder: "default" | "reverse";
  statusFilter: MeasurementJobStatus | "all";
}

class JobsListView extends Component<Props, State> implements MeasurementSetSelectorObserver, MeasurementJobObserver {
  private text = Localization.getInstance().getDisplayText;

  public constructor (props: Props) {
    super(props);
    this.state = {
      measSetSelectorAnchorEl: null,
      mapMarkerData: null,
      filteredJobList: [],
      rowCount: 30,
      searchText: null,
      sortedJobList: [],
      sortKey: "displayName",
      sortOrder: "default",
      statusFilter: "all",
    };
    this.toggleAllMapMarkers = this.toggleAllMapMarkers.bind(this);

  }

  public componentDidMount(): void {
    MeasurementSetSelector.getInstance().addObserver(this);
    MeasurementJobRepository.getInstance().addObserver(this);
    const sortedJobList = sortList(this.state.sortOrder, this.state.sortKey, this.getRawJobList());
    const filteredJobList = this.filterJobList(sortedJobList, this.state.searchText);
    this.setState({ sortedJobList, filteredJobList });
  }

  public componentDidUpdate(_prevProps: Props, prevState: State): void {
    if (this.state.sortKey !== prevState.sortKey || this.state.sortOrder !== prevState.sortOrder || this.state.statusFilter !== prevState.statusFilter) {
      const sortedJobList = sortList(this.state.sortOrder, this.state.sortKey, this.state.sortedJobList);
      const filteredJobList = this.filterJobList(sortedJobList, this.state.searchText);
      this.setState({ sortedJobList, filteredJobList });
    }

    if (this.didFilteredJobListChange(prevState.filteredJobList, this.state.filteredJobList)) {
      const filteredJobMarkersOnMap = MapLink.getDataStorage().getMarkerData().filter(mData => {
        return this.state.filteredJobList.some(job => {
          return job.jobId === mData.id;
        });
      });
      this.setState({ mapMarkerData: filteredJobMarkersOnMap.length > 0 ? filteredJobMarkersOnMap : null });
      MapLink.getLinkToMap().replaceData(filteredJobMarkersOnMap);
    }
  }

  public componentWillUnmount(): void {
    MeasurementSetSelector.getInstance().removeObserver(this);
    MeasurementJobRepository.getInstance().removeObserver(this);

    if (MapLink.getDataStorage().getMarkerData().length > 0) {
      MapLink.getLinkToMap().removeAllLocations();
    }
  }

  // TODO: Narrow re-rendering based on current filters
  public onMeasurementJobUpdated(_setId: string, _jobId: string): void {
    const sortedJobList = sortList(this.state.sortOrder, this.state.sortKey, this.getRawJobList());
    const filteredJobList = this.filterJobList(sortedJobList, this.state.searchText);
    this.setState({ sortedJobList, filteredJobList });
  }

  public onSelectedMeasurementSetChanged(_newSet: Nullable<MeasurementSetConfig>): void {
    const sortedJobList = sortList(this.state.sortOrder, this.state.sortKey, this.getRawJobList());
    const filteredJobList = this.filterJobList(sortedJobList, this.state.searchText);
    this.setState({ sortedJobList, filteredJobList, measSetSelectorAnchorEl: null });
  }

  public onSearchTextChange(text: Nullable<string>): void {
    const filteredJobList = this.filterJobList(this.state.sortedJobList, text);
    this.setState({ searchText: text, filteredJobList });
  }

  private toggleAllMapMarkers(): void {
    let mapMarkerData: MapMarkerData[];

    if (this.isMapMarkerDataFiltered()) {
      mapMarkerData = this.getMarkerDataForAllFilteredJobs();

      if (MapLink.getLinkToMap().areLocationsOnMap(mapMarkerData.map(({ id }) => id))) {
        this.setState({ mapMarkerData: null });
        MapLink.getLinkToMap().removeAllLocations();
      } else {
        this.setState({ mapMarkerData });
        MapLink.getLinkToMap().replaceData(mapMarkerData);
      }
    } else {
      mapMarkerData = MeasurementDataTools.getMapMarkerDataForAllMeasJobs();

      if (MapLink.getLinkToMap().areLocationsOnMap(mapMarkerData.map(({ id }) => id))) {
        this.setState({ mapMarkerData: null });
        MapLink.getLinkToMap().removeAllLocations();
      } else {
        this.setState({ mapMarkerData });
        MapLink.getLinkToMap().replaceData(mapMarkerData);
      }
    }
  }

  private toggleMapMarker(marker: Nullable<MapMarkerData>): void {

    if (marker) {
      const { mapMarkerData } = this.state;

      // handle updating state
      if (mapMarkerData && mapMarkerData.findIndex(mData => mData.id === marker.id) > -1) {
        this.setState({ mapMarkerData: mapMarkerData.filter(mData => mData.id !== marker.id) });
      } else if (mapMarkerData) {
        this.setState({ mapMarkerData: mapMarkerData.concat(marker) });
      } else {
        this.setState({ mapMarkerData: [marker] });
      }
      // handle updating markerData in MapLink
      MapLink.getLinkToMap().toggle(marker);
    } else {
      console.error("error in JobsListView.toggleMapMarker() / parameter 'marker' was null");
    }
  }

  private renderDataRows(jobs: MeasurementJob[]): JSX.Element {
    if (jobs.length > 0) {
      return (
        <Fragment>
          {jobs.map((job: MeasurementJob, _i: number) =>
            <JobsListDataRow
              mapMarkerData={this.state.mapMarkerData}
              dataItem={job}
              key={job.jobId}
              toggleMarker={(marker: Nullable<MapMarkerData>): void => this.toggleMapMarker(marker)}
            />,
          )}
        </Fragment>
      );
    } else {
      return (
        <IoTDataRow>
          <Box width="100%">
            <Typography variant="body1">
              {this.text("MeasurementSetsDataTable", "noSearchResults")}
            </Typography>
          </Box>
        </IoTDataRow>
      );
    }
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <Section minWidth="1300px">
          <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" height="3.5rem">
            <Box display="flex">
              <MeasSetGroupMenu2
                onSelectTreeItem={(_event, measurementSetConfig: MeasurementSetTreeItem): void => MeasurementSetSelector.getInstance().setSelectedMeasurementSet(measurementSetConfig as MeasurementSetConfig)}
                disableModifications
                configSelectMode
              />
              <SSelect buttonText={Localization.getInstance().getDisplayText("MeasJobsListView", this.state.statusFilter)} ml={12}>
                <MenuItem
                  onClick={(): void => this.setState({ statusFilter: "all" })}
                  value={"all"}
                >
                  {Localization.getInstance().getDisplayText("MeasJobsListView", "all")}
                </MenuItem>
                <MenuItem
                  onClick={(): void => this.setState({ statusFilter: MeasurementJobStatus.NotStarted })}
                  value={MeasurementJobStatus.NotStarted}
                >
                  {Localization.getInstance().getDisplayText("MeasJobsListView", MeasurementJobStatus.NotStarted)}
                </MenuItem>
                <MenuItem
                  onClick={(): void => this.setState({ statusFilter: MeasurementJobStatus.Running })}
                  value={MeasurementJobStatus.Running}
                >
                  {Localization.getInstance().getDisplayText("MeasJobsListView", MeasurementJobStatus.Running)}
                </MenuItem>
                <MenuItem
                  onClick={(): void => this.setState({ statusFilter: MeasurementJobStatus.Ready })}
                  value={MeasurementJobStatus.Ready}
                >
                  {Localization.getInstance().getDisplayText("MeasJobsListView", MeasurementJobStatus.Ready)}
                </MenuItem>
              </SSelect>
            </Box>
            <SFilledInput
              endAdornment={
                <InputAdornment position="end">
                  <SSvgIcon color={SSvgIconColorProps.textPrimary} iconComponent={SearchIcon} size="1.5rem" />
                </InputAdornment>
              }
              id="measurement-jobs-search"
              inputLabelText={Localization.getInstance().getDisplayText("Actions", "search")}
              onChange={(event): void => this.onSearchTextChange(event.target.value)}
              value={this.state.searchText ?? ""}
              width="12.5rem"
            />
          </Box>
          <Box mt={2}>
            <Divider />
          </Box>
        </Section>
        <Section minWidth="1300px">
          <JobsListHeaderRow
            requestSorting={(sortKey: keyof MeasurementJob): void => this.setSortOrder(sortKey)}
            sortKey={this.state.sortKey}
            sortOrder={this.state.sortOrder}
            toggleAllMapMarkers={this.toggleAllMapMarkers}
          />
          {this.renderDataRows(this.state.filteredJobList)}
        </Section>
        {this.state.rowCount <= this.state.filteredJobList.length
        &&
        <ListViewFab
          onClick={(): void => this.setState({ rowCount: this.state.rowCount + 30 })}
        />}
      </Fragment>
    );
  }

  // - - - - - - - - - - - - - - - - - - - - - - -
  // Helper methods for list filtering and sorting

  private filterJobList(jobList: MeasurementJob[], searchText: Nullable<string>): MeasurementJob[] {
    if (searchText === null) {
      if (this.state.statusFilter !== "all") {
        const filteredJobList = jobList.filter((job) => job.status === this.state.statusFilter);
        return filteredJobList;
      } else {
        return jobList;
      }
    } else {
      let filteredJobList: MeasurementJob[];

      if (this.state.statusFilter !== "all") {
        filteredJobList = jobList.filter((job) => job.status === this.state.statusFilter);
      } else {
        filteredJobList = jobList;
      }

      filteredJobList = jobList.filter((job) => job.displayName.toLowerCase().includes(searchText.toLowerCase()));
      return filteredJobList;
    }

  }

  private getMeasSets(): MeasurementSetConfig[] {
    if (MeasurementSetSelector.getInstance().getSelectedMeasurementSet() === null) {
      return MeasurementSetRepository.getInstance().getMeasurementSets();
    } else {
      return [MeasurementSetSelector.getInstance().getSelectedMeasurementSet() as MeasurementSetConfig];
    }
  }

  private getRawJobList(): MeasurementJob[] {
    let jobs: MeasurementJob[] = [];

    const sets = this.getMeasSets();

    for (const set of sets) {
      jobs = jobs.concat(MeasurementJobRepository.getInstance().list(set.setId));
    }

    return jobs;
  }

  private setSortOrder(sortKey: keyof MeasurementJob): void {
    if (this.state.sortKey !== sortKey) {
      this.setState({ sortKey });
    } else {
      this.setState({ sortOrder: this.state.sortOrder === "default" ? "reverse" : "default" });
    }
  }

  // Helper methods for map marker data and location button handling
  // TODO: refactor some of these into general methods that can be used also in SetsListView
  private didFilteredJobListChange(previousList: MeasurementJob[], currentList: MeasurementJob[]): boolean {
    if (previousList.length >= currentList.length) {
      return !previousList.every(prevListItem => {
        return currentList.some(currListItem => {
          return prevListItem.jobId === currListItem.jobId;
        });
      });
    } else if (previousList.length < currentList.length){
      return !currentList.every(currListItem => {
        return previousList.some(prevListItem => {
          return currListItem.jobId === prevListItem.jobId;
        });
      });
    } else {
      return false;
    }
  }

  private isMapMarkerDataFiltered(): boolean {
    return !MeasurementDataTools.getMapMarkerDataForAllMeasJobs().every(mDataItemA => {
      return this.getMarkerDataForAllFilteredJobs().some(mDataItemB => {
        return mDataItemA.id === mDataItemB.id;
      });
    });
  }

  private getMarkerDataForAllFilteredJobs(): MapMarkerData[] {
    return MeasurementDataTools.getMapMarkerDataForAllMeasJobs(this.state.filteredJobList);
  }
}

export default JobsListView;
