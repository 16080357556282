import HyperIcon from "../../../assets/device/icons/router-24px.svg";
import { ReferenceHW } from "../ReferenceHW/ReferenceHW";
import { SereneHWState } from "./SereneHWState";
import { SereneHWStateProperties } from "./SereneHWStateProperties";
import AWSBackend from "../../../data/backend/AWSBackend";
import { DeviceParameters } from "../../../data/device/Device";

export class SereneHW extends ReferenceHW<SereneHWState> {
  public static type = "Serene";

  public constructor(backend: AWSBackend, params: DeviceParameters) {
    super(SereneHW.type, backend, params);
  }

  public getIcon(): string {
    return HyperIcon;
  }

  public createState(timestamp?: number, reported?: Partial<SereneHWStateProperties>, desired?: Partial<SereneHWStateProperties>):
  SereneHWState {
    return new SereneHWState(this.getId(), new SereneHWStateProperties(reported ?? {}), new SereneHWStateProperties(desired ?? {}), timestamp);
  }
}
