import HyperIcon from "../../../assets/device/icons/router-24px.svg";
import { ReferenceHW } from "../ReferenceHW/ReferenceHW";
import { MLDemoHWState } from "./MLDemoHWState";
import AWSBackend from "../../../data/backend/AWSBackend";
import { DeviceParameters } from "../../../data/device/Device";
import { MLDemoHWStateProperties } from "./MLDemoHWStateProperties";

export class MLDemoHW extends ReferenceHW<MLDemoHWState> {
  public static type = "MLDemo";

  public constructor(backend: AWSBackend, params: DeviceParameters) {
    super(MLDemoHW.type, backend, params);
  }

  public getIcon(): string {
    return HyperIcon;
  }

  public createState(timestamp?: number, reported?: Partial<MLDemoHWStateProperties>, desired?: Partial<MLDemoHWStateProperties>):
  MLDemoHWState {
    return new MLDemoHWState(this.getId(), new MLDemoHWStateProperties(reported ?? {}), new MLDemoHWStateProperties(desired ?? {}), timestamp);
  }
}
