import React from "react";
import { Grid, List, TextField } from "@material-ui/core";
import Device from "data/device/Device";
import { Attribute } from "data/device/Attribute";
import Localization from "data/localization-sensoan/Localization";
import SettingsListItem from "../settings-list-item";
import SettingsControls from "../settings-page-general/components/settings-controls";

interface Props {
  selectedDevice: Device;
  onCloseSettings: () => void;
}

interface State {
  attributes: Attribute[];
  changesMade: boolean;
}

const EDITABLE_ATTRIBUTE_PREFIX = "w_";

export default class SettingsPageAttributes extends React.Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      attributes: props.selectedDevice.getAttributes(),
      changesMade: false,
    };
  }

  private handleCancel = (): void => {
    this.props.onCloseSettings();
  };

  private handleSave = (): void => {
    const attributes = this.state.attributes
      .filter(item => item.key.startsWith(EDITABLE_ATTRIBUTE_PREFIX));
    this.props.selectedDevice.updateAttributes(attributes);
    this.setState({ changesMade: false });
  };

  private attributeChanged(attribute: Attribute): void {
    const newAttributes = this.state.attributes.map(item => {
      if (item.key === attribute.key) {
        return attribute;
      }
      return item;
    });
    this.setState({ attributes: newAttributes, changesMade: true });
  }

  public render(): JSX.Element {
    return (
      <Grid container={true} direction="column" spacing={2}>
        <Grid item={true}>
          <List>
            {this.state.attributes ? this.state.attributes.map(item => {
              if (item.key.startsWith(EDITABLE_ATTRIBUTE_PREFIX)) {
                return (
                  <SettingsListItem key={item.key} label={item.key}>
                    <TextField
                      value={item.value}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                        this.attributeChanged({
                          key: item.key,
                          value: event.target.value,
                        })}
                    />
                  </SettingsListItem>
                );
              } else {
                return (
                  <SettingsListItem
                    key={item.key}
                    /* Note: this produces correct localised texts only if this.state.attributes and properties under DeviceSettings of Locales.ts correspond to each other */
                    label={this.text("DeviceSettings", item.key)}
                  >
                    {item.value}
                  </SettingsListItem>
                );
              }
            }) : <p>{this.text("DeviceSettings", "noAttributesDefined")}</p>}
          </List>
        </Grid>
        <Grid item>
          <SettingsControls
            submitButtonDisabled={!this.state.changesMade}
            submitButtonLabel={this.text("Actions", "save")}
            cancelButtonLabel={this.text("Actions", "close")}
            onSave={this.handleSave}
            onCancel={this.handleCancel}/>
        </Grid>
      </Grid>
    );
  }

}
