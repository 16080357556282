import React, { Component, ReactNode } from "react";
import { Box, Typography } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Localization from "data/localization-sensoan/Localization";
import Event from "data/clientSpecific/Event";
import { Maybe } from "types/aliases";
import IoTDataRow from "components/layout/iot-data-row/IoTDataRow";
import HeaderRowLocationButton from "components/layout/iot-data-row/HeaderRowLocationButton";

interface Props {
  requestSorting: (sortKey: keyof Event) => void;
  sortKey: keyof Event;
  sortOrder: "default" | "reverse";
  toggleAllMapMarkers: () => void;
}

interface State {
}


class EventsListHeaderRow extends Component<Props, State> {

  private text = Localization.getInstance().getDisplayText;

  private getSortIcon(label: keyof Event): Maybe<JSX.Element> {
    if (label === this.props.sortKey) {
      return this.props.sortOrder === "default" ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />;
    } else {
      return undefined;
    }
  }

  public render(): ReactNode {
    const cursorPointerStyle: React.CSSProperties = { cursor: "pointer" };
    /* TODO: change boxes into buttons */
    return (
      <IoTDataRow dividerFadeEffect>
        <HeaderRowLocationButton
          toggleAllMapMarkers={this.props.toggleAllMapMarkers}
        />
        <Box width="15%" onClick={(): void => this.props.requestSorting("deviceId")}>
          <Typography variant="body1" style={cursorPointerStyle}>
            {this.text("Common", "device")} {this.getSortIcon("deviceId")}
          </Typography>
        </Box>
        <Box width="14%" onClick={(): void => this.props.requestSorting("sensorName")}>
          <Typography variant="body1" style={cursorPointerStyle}>
            {this.text("DevicesView", "sensor")} {this.getSortIcon("sensorName")}
          </Typography>
        </Box>
        <Box width="20%" onClick={(): void => this.props.requestSorting("metadata")}>
          <Typography variant="body1" style={cursorPointerStyle}>
            {this.text("EventsView", "description")} {this.getSortIcon("metadata")}
          </Typography>
        </Box>
        <Box width="11%" onClick={(): void => this.props.requestSorting("sensorValue")}>
          <Typography variant="body1" style={cursorPointerStyle}>
            {this.text("DevicesView", "reading")} {this.getSortIcon("sensorValue")}
          </Typography>
        </Box>
        <Box width="14%" onClick={(): void => this.props.requestSorting("timestamp")}>
          <Typography variant="body1" style={cursorPointerStyle}>
            {this.text("DevicesView", "time")} {this.getSortIcon("timestamp")}
          </Typography>
        </Box>
        <Box width="12%">
          <Typography variant="body1">
            {this.text("EventsView", "state")}
          </Typography>
        </Box>
        <Box width="14%">
          <Typography variant="body1">
            {this.text("EventsView", "ackSignature")}
          </Typography>
        </Box>
      </IoTDataRow>
    );
  }
}

export default EventsListHeaderRow;
