import React, { Component, ReactNode } from "react";
import { Box, Typography } from "@material-ui/core";
import Localization from "data/localization-sensoan/Localization";
import IoTDataRow from "components/layout/iot-data-row/IoTDataRow";
import HeaderRowLocationButton from "components/layout/iot-data-row/HeaderRowLocationButton";
import withDataJanitor from "components/hocs/DataJanitor";
import { DataRepositories } from "data/data-storage/DataRepositoryFactory";

const HeaderRowLocationButtonWithDeviceRepo = withDataJanitor(HeaderRowLocationButton, [
  DataRepositories.Device,
]);

interface Props {
  toggleAllMapMarkers: () => void;
}

class SetsListHeaderRow extends Component<Props> {

  public render(): ReactNode {
    return (
      <IoTDataRow dividerFadeEffect>
        <HeaderRowLocationButtonWithDeviceRepo
          toggleAllMapMarkers={this.props.toggleAllMapMarkers}
          inlineLoaderWithoutWaitingText
          loaderProps={{
            size: 1.75,
            leftRightPadding: "12px",
          }}
        />
        <Box width="20%">
          <Typography variant="body1">
            {Localization.getInstance().getDisplayText("MeasuremenSetHeaderDataRow", "nameOfSet")}
          </Typography>
        </Box>
        <Box width="20%">
          <Typography variant="body1">
            {Localization.getInstance().getDisplayText("Common", "measurementJobs")}
          </Typography>
        </Box>
        <Box width="25%">
          <Typography variant="body1">
            {Localization.getInstance().getDisplayText("Common", "sensors")}
          </Typography>
        </Box>
        <Box width="30%">
          <Typography variant="body1">
            {Localization.getInstance().getDisplayText("MeasuremenSetHeaderDataRow", "additionalInfo")}
          </Typography>
        </Box>
      </IoTDataRow>
    );
  }

}

export default SetsListHeaderRow;
