import React, { Component, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Divider, Link, Paper, Theme, Typography, withTheme } from "@material-ui/core";
import Localization from "data/localization-sensoan/Localization";
import { clearAllSelectorsWithValue } from "data/utils/selectorUtils";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import PathsSensoan from "data/paths/PathsSensoan";

interface Props {
  active: boolean;
  icon: React.FunctionComponent;
  name: PathsSensoan;
  theme: Theme;
}

interface State {
}


class AppMenuItem extends Component<Props, State> {

  public renderContent(bg: "background.paper" | "background.default"): JSX.Element {
    return (
      <Paper square elevation={0}>
        <Box p={4} bgcolor={bg} display="flex" alignItems="center">
          <Box flexBasis="25%" display="flex" justifyContent="center" >
            <SSvgIcon
              color={this.props.active ? SSvgIconColorProps.blueGradient : SSvgIconColorProps.primary}
              iconComponent={this.props.icon}
              size="2.5rem"
              viewBox="0 0 30 30"
            />
          </Box>
          <Box flexBasis="75%" textAlign="left">
            <Typography color="textPrimary">
              {/* TODO: if PathsSensoan is kept, update localization table in DynamoDB and remove substring(1) */}
              {Localization.getInstance().getDisplayText("MainMenu", this.props.name.substring(1))} {/* name starts always with a "/"*/}
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {!this.props.active ?
          <Link component={RouterLink} to={`${this.props.name}`} onClick={(): Promise<void> => clearAllSelectorsWithValue()}>
            {this.renderContent("background.default")}
          </Link>
          : this.renderContent("background.paper")
        }
        <Divider/>
      </Fragment>
    );
  }
}

export default withTheme(AppMenuItem);
