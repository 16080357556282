import React, { Component, ReactNode } from "react";
import TreeView from "@material-ui/lab/TreeView";
import { Theme, Typography, withTheme } from "@material-ui/core";
import DeviceGroup from "data/device/DeviceGroup";
import DeviceRepository from "data/data-storage/DeviceRepository";
import Localization from "data/localization-sensoan/Localization";
import STreeItem from "components/styled-components/STreeItem";
import SButton from "components/styled-components/SButton";
import SensorTreeGroup2 from "./SensorTreeGroup2";
import Device from "data/device/Device";
import DeviceNavigationCache from "utils/DeviceNavigationCache";

interface Props {
  deviceFilter?: (device: Device) => void;
  onClearButtonClick?: () => void;
  onTreeItemLabelClick?: (treeDeviceItem: Device, treeSensorItem?: string) => Promise<void> | void;
  selectedSensorItem?: {deviceId: string; sensorName: string};
  renderSensors?: boolean;
  theme: Theme;
}

interface State {
  deviceGroups: DeviceGroup[];
  expandedItems: string[];
}

const ROOT_GROUP_ID = process.env.REACT_APP_MEASSET_ROOT_LEVEL_GROUP_ID as string;

class DeviceTree2 extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      deviceGroups: DeviceRepository.getInstance().getGroups(),
      expandedItems: [ROOT_GROUP_ID],
    };
  }

  public render(): JSX.Element {
    return (
      <TreeView
        expanded={this.state.expandedItems}
        style={{ minWidth: "15rem", padding: "1.5rem", maxHeight: "66vh" }}
        onNodeToggle={(event: unknown, nodeIds: string[]): void => this.onNodeToggle(event, nodeIds)}
      >
        {this.renderClearButton()}
        <STreeItem
          rootItem
          hideIconContainer
          onLabelClick={(): void => this.props.onClearButtonClick?.()}
          label={
            <Typography color="textSecondary">
              {Localization.getInstance().getDisplayText("Common", "devices")}
            </Typography>}
          nodeId={ROOT_GROUP_ID}
        >
          {this.state.deviceGroups.map((group: DeviceGroup) =>
            <SensorTreeGroup2
              deviceFilter={this.props.deviceFilter}
              expandedItems={this.state.expandedItems}
              key={group.getId()}
              group={group}
              onTreeItemLabelClick={this.props.onTreeItemLabelClick}
              selectedSensorItem={this.props.selectedSensorItem}
              renderSensors={this.props.renderSensors}
            />,
          )}
        </STreeItem>
      </TreeView>
    );
  }

  //TODO: add clearing functionality also to sensor selecting
  private renderClearButton(): ReactNode {
    if (DeviceNavigationCache.getInstance().getSelectedDevice() && !this.props.renderSensors) {
      return (
        <SButton
          color="secondary"
          labelText={Localization.getInstance().getDisplayText("MeasSetSelect", "clear")}
          onClick={(): void => this.props.onClearButtonClick?.()}
          widthInRems={7}
          mb={2}
        />
      );
    } else {
      return null;
    }
  }

  private onNodeToggle(_event: unknown, expandedNodeIds: string[]): void {
    this.setState({ expandedItems: expandedNodeIds });
  }

}

export default withTheme(DeviceTree2);
