import { DeviceStateProperties } from "data/device/DeviceStateProperties";
import { Nullable } from "types/aliases";
import { RuuviGWHWState } from "./RuuviGWHWState";

// DeviceStateProperties provides 3 things:
// public readonly deviceId: Nullable<string>;
// public displayName: Nullable<string>;
// public otaId: Nullable<string>; - this is not probably needed as it is connected to Hyper OTA functionality

export type RuuviGWNodListValue =
  "gnss" |
  "ncell" |
  "mcell_loc";

export type RuuviGWNodList = RuuviGWNodListValue[];

// AWS shadow property is removed by setting its value to null. Therefore shadow properties from AWS
// never are null but null is included in RuuviGWHWCfg types to allow property removal on UI
export type RuuviGWHWCfg = {
  gpst?: Nullable<number>; // gps timeout, secon
  act?: boolean; // active mode (null not included because false implies that passive mode is on - therefore it never makes sense to remove this property from the shadow)
  mvt?: Nullable<number>; // passive mode data update without movement period, seconds
  actwt?: Nullable<number>; // active mode data update period, seconds
  rscan?: Nullable<number>; // ruuvi scan time, milliseconds
  nod?: RuuviGWNodList; // list of not requested data (null not included because empty list implies that all data is requested)
  mvres?: Nullable<number>; // time between movement triggered data updates
  acct?: Nullable<number>; // accelerometer threshold for triggering movement
  readonly[key: string]: null | undefined | number | boolean | string[];
};

export type RuuviGWHWDeviceInfo = {
  v?: {
    nw?: string; // type of network
    modV?: string; // modem version
    appV?: string; // app version
    brdV?: string; // board version
  };
};

export class RuuviGWHWStateProperties extends DeviceStateProperties {
  public cfg?: RuuviGWHWCfg;
  public readonly dev?: RuuviGWHWDeviceInfo;
  public wl?: RuuviGWHWWhitelist;
  public constructor(deviceProperties: Partial<RuuviGWHWStateProperties>) {
    super(deviceProperties);
    this.cfg = RuuviGWHWState.getSupportedCfgProperties(deviceProperties);
    this.dev = RuuviGWHWState.getSupportedDevProperties(deviceProperties);
    this.wl = deviceProperties.wl;
  }
}

export type RuuviGWHWWhitelist = string[];
