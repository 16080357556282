import React from "react";
import { MenuItem } from "@material-ui/core";
import { Locales } from "data/localization-sensoan/Locales";
import Localization from "data/localization-sensoan/Localization";
import SSelect from "components/styled-components/SSelect";

interface Props {
  fetchAppData?: boolean;
  updateThis?: React.Component;
  onSelectLocale?: () => void;
}

class LocalizationInput extends React.Component<Props> {

  private handleSelectLocale(locale: string): void {
    Localization.getInstance().setCurrentLocale(locale as Locales);
    this.props.onSelectLocale?.();
  }

  public render(): JSX.Element {
    return (
      <SSelect buttonText={`${Localization.getInstance().getDisplayText("Login", "lang")}: ${Localization.getInstance().getCurrentLocale()}`}>
        {Object.keys(Locales).map((locale: string, index: number): JSX.Element => (
          <MenuItem
            key={index}
            onClick={(): void => this.handleSelectLocale(locale)}
            value={locale}
          >
            {locale}
          </MenuItem>
        ))}
      </SSelect>
    );
  }
}

export default LocalizationInput;
