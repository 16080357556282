import React, { Component, Fragment, ReactNode } from "react";
import { Box, Checkbox, MenuItem, TextField, Theme, Typography, withTheme } from "@material-ui/core";
import { Maybe, Nullable } from "types/aliases";
import { MinMaxInputType, ScaleInputErrorStatus } from "types/sensoanUiTypes";
import { ChartType, DataConfig, SensorColorType, TimeScaleType } from "data/types/measurementSetTypes";
import Localization from "data/localization-sensoan/Localization";
import ErrorMessage from "components/layout/ErrorMessage";
import SensorColorSelector from "./sensor-configuration/SensorColorSelector";
import SSelect from "components/styled-components/SSelect";
import SFilledInput from "components/styled-components/SFilledInput";

interface Props {
  aggregateLength: Nullable<number | string>;
  activeScaleInput: Nullable<MinMaxInputType>;
  availableSensorColors: Maybe<SensorColorType[]>;
  chartType: ChartType | string;
  dataConfig: Nullable<DataConfig[]>;
  onAggregateLengthChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChartTypeChange: (type: ChartType) => void;
  onScaleValueChange: (event: React.ChangeEvent<HTMLInputElement>, type: MinMaxInputType) => void;
  onSensorColorSelect: (index: number, sensorColor: string) => void;
  onTimeScaleTypeChange: (type: string) => void;
  scale: {
    min: string;
    max: string;
  };
  scaleError: Maybe<ScaleInputErrorStatus>;
  theme: Theme;
  timeScaleType: TimeScaleType | string;
  toggleAggregateLength: (value: Nullable<string>) => void;
}

interface State {
  aggregateLengthOptionActivated: boolean;
}

class DataVisualization extends Component<Props, State> {

  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      aggregateLengthOptionActivated: this.props.aggregateLength === null ? false : true,
    };
  }

  public componentDidUpdate(_prevProps: Props): void {
    //Clear aggregateLength value in parent component and set local aggregateLength option to false if the user changes chart type to gauge from something else
    // **uncomment when aggregating functionality is implemented**
    /* if (this.props.chartType !== prevProps.chartType && this.props.chartType === ChartType.GAUGE) {
      this.props.toggleAggregateLength(null);
      this.setState({ aggregateLengthOptionActivated: false });
    } */
  }

  private renderChartTypeRow(): ReactNode {
    return (
      <Box display="flex" height="3rem" justifyContent="flex-start" alignItems="center">
        <Box width="35%">
          <Typography variant="body1" color="textSecondary">
            {this.text("DataVisualizationSelection", "selectChart")}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <SSelect buttonText={this.getLocalisedMenuOption(this.props.chartType) ?? this.text("MeasurementSetConfigSelection", "select")}>
            {Object.values(ChartType).map((type: ChartType, index: number) => {
              return (
                <MenuItem key={index} value={type} onClick={(): void => this.props.onChartTypeChange(type)}>
                  {this.getLocalisedMenuOption(type)}
                </MenuItem>
              );
            })}
          </SSelect>
          {!this.props.chartType && <ErrorMessage ml={4}/>}
        </Box>
      </Box>
    );
  }

  private renderGaugeInfoMsgRow(): ReactNode {
    if (this.props.chartType === ChartType.GAUGE && (!this.props.scale.min && !this.props.scale.max)) {
      return (
        <Box display="flex" height="3rem" justifyContent="flex-start" alignItems="center">
          <Box marginLeft="calc(35% + 12px)">
            <Typography>
              {this.text("DataVisualizationSelection", "gaugeWithoutScaleMsg")}
            </Typography>
          </Box>
        </Box>
      );
    } else {
      return null;
    }
  }

  private renderAggregateLengthSelector(): ReactNode {
    return (
      <Fragment>
        {(this.props.chartType && this.props.chartType !== ChartType.GAUGE)
        &&
        <Box display="flex" height="3rem" justifyContent="flex-start">
          <Box width="35%">
            <Typography variant="body1" color="textSecondary">
              {this.text("DataVisualizationSelection", "dataAggregation")}
            </Typography>
          </Box>
          <Box width="55%">
            <Checkbox
              checked={this.state.aggregateLengthOptionActivated}
              color="default"
              inputProps={{ "aria-label": "aggregate length option checkbox" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                this.setState({ aggregateLengthOptionActivated: event.target.checked });
                this.props.toggleAggregateLength(this.state.aggregateLengthOptionActivated ? null : "");
              }}
            />
          </Box>
        </Box>
        }
        {this.state.aggregateLengthOptionActivated
        &&
        <Box display="flex" height="3rem" justifyContent="flex-start">
          <Box width="35%">
            <Typography variant="body1" color="textSecondary">
              {this.text("DataVisualizationSelection", "aggregationValue")}
            </Typography>
          </Box>
          <Box width="55%">
            <TextField size="small" variant="filled" type="number" value={this.props.aggregateLength} onChange={this.props.onAggregateLengthChange} />
          </Box>
        </Box>
        }
      </Fragment>
    );
  }

  private renderTimeScaleRow(): ReactNode {
    return (
      this.props.chartType
        &&
        <Box display="flex" height="3rem" justifyContent="flex-start" alignItems="center">
          <Box width="35%">
            <Typography variant="body1" color="textSecondary">
              {this.text("DataVisualizationSelection", "timeScaleType")}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            {this.renderTimeScaleSelector()}
            {!this.props.timeScaleType && <ErrorMessage ml={4}/>}
          </Box>
        </Box>
    );
  }

  private renderTimeScaleSelector(): ReactNode {
    const options = Object.values(TimeScaleType);
    const timeScaleOptions = this.props.chartType
      ?
      this.filterTimeScaleOptions(options, this.props.chartType as ChartType)
        ??
        options
      :
      options;
    return (
      <SSelect buttonText={this.getLocalisedMenuOption(this.props.timeScaleType) ?? this.text("MeasurementSetConfigSelection", "select")}>
        {timeScaleOptions.map((type: string, index: number) => {
          return (
            <MenuItem key={index} value={type} onClick={(): void => this.props.onTimeScaleTypeChange(type)}>{this.getLocalisedMenuOption(type)}</MenuItem>
          );
        })}
      </SSelect>
    );
  }

  private renderScaleRow(): ReactNode {
    return (
      (this.props.chartType && this.props.chartType !== ChartType.NUMBER_DISPLAY)
        &&
        <Box display="flex" height="3rem" justifyContent="flex-start" alignItems="center">
          <Box width="35%">
            <Typography variant="body1" color="textSecondary">
              {this.text("DataVisualizationSelection", "scaleValues")}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="body1" color="textSecondary">
              {this.text("DataVisualizationSelection", "scaleLowerLimit")}
            </Typography>
            <SFilledInput
              error={this.props.scaleError === ScaleInputErrorStatus.invalidValue && this.props.activeScaleInput === "min"}
              id="scale-min-value"
              ml="1rem"
              mr="1rem"
              value={this.props.scale?.min}
              onChange={(event): void => this.props.onScaleValueChange(event, "min")}
              width="5rem"
            />
            <Typography variant="body1" color="textSecondary">
              {this.text("DataVisualizationSelection", "scaleUpperLimit")}
            </Typography>
            <SFilledInput
              error={this.props.scaleError === ScaleInputErrorStatus.invalidValue && this.props.activeScaleInput === "max"}
              id="scale-max-value"
              ml="1rem"
              mr="1rem"
              value={this.props.scale?.max}
              onChange={(event): void => this.props.onScaleValueChange(event, "max")}
              width="5rem"
            />
          </Box>
        </Box>
    );
  }

  private renderSensorColorSelectorList(): ReactNode {
    return (
      this.props.dataConfig
      &&
      <Box width="auto" display="flex" alignItems="baseline" flexDirection="column">
        {this.props.dataConfig?.map((config: DataConfig, index: number) => {
          // show SensorColorSelector only after other properties of dataConfig have been entered
          return (config.sensorDisplayName !== "" && config.sensorName && config.deviceId)
            ?
            <SensorColorSelector
              availableSensorColors={this.props.availableSensorColors}
              dataConfig={config}
              index={index}
              key={index}
              onSensorColorSelect={this.props.onSensorColorSelect}
            />
            :
            undefined;
        })}
      </Box>
    );
  }

  private renderSensorColorRow(): ReactNode {
    return (
      this.props.chartType
      &&
      <Box display="flex" height="auto" justifyContent="flex-start" alignItems="baseline">
        <Box width="35%">
          <Typography variant="body1" color="textSecondary">
            {this.text("DataVisualizationSelection", "colors")}
          </Typography>
        </Box>
        {this.renderSensorColorSelectorList()}
      </Box>
    );
  }

  private renderScaleErrorMsgRow(): Nullable<ReactNode> {
    if (this.props.scaleError === ScaleInputErrorStatus.otherValueIsMissing) {
      return (
        <Box display="flex" height="2rem" justifyContent="flex-start" alignItems="center">
          <Box display="flex" marginLeft="35%">
            <ErrorMessage
              customMessage={this.text("DataVisualizationSelection", "scaleValueError")}
            />
          </Box>
        </Box>
      );
    } else {
      return null;
    }
  }

  public render(): ReactNode {
    return (
      <Fragment>
        {this.renderChartTypeRow()}
        {this.renderGaugeInfoMsgRow()}
        {/*this.renderTimeScaleRow()*/}
        {this.renderScaleRow()}
        {this.renderScaleErrorMsgRow()}
        {/* this.renderAggregateLengthSelector() **disabled ATM, will be enabled when aggregation functionality is implemented  */}
        {this.renderSensorColorRow()}
      </Fragment>
    );
  }

  private filterTimeScaleOptions(options: string[], filter: ChartType): Maybe<string[]> {
    switch (filter) {
      case ChartType.GAUGE:
        return options.filter(option => option === TimeScaleType.LATEST);
      case ChartType.NUMBER_DISPLAY:
        return options.filter(option => option === TimeScaleType.LATEST);
      case ChartType.LINECHART:
        return options.filter(option => option === TimeScaleType.HISTORY || option === TimeScaleType.AGGREGATE);
      default:
        console.error("error in DataVisualizationSelection: filterSelectorOptions was called with unknown argument");
        return undefined;
    }
  }

  private getLocalisedMenuOption(menuOption: string): Maybe<string> {
    switch (menuOption) {
      case ChartType.GAUGE:
        return this.text("DataVisualizationSelection", "gauge");
      /* case ChartType.GAUGECHART:
        return this.text("DataVisualizationSelection", "gaugeChart"); */
      case ChartType.LINECHART:
        return this.text("DataVisualizationSelection", "lineChart");
      case ChartType.NUMBER_DISPLAY:
        return this.text("DataVisualizationSelection", "numberDisplay");
      case ChartType.STEPPED_AREA_CHART:
        return this.text("DataVisualizationSelection", "steppedAreaChart");
      /* case TimeScaleType.AGGREGATE:
        return this.text("DataVisualizationSelection", "aggregate"); */
      case TimeScaleType.LATEST:
        return this.text("DataVisualizationSelection", "latest");
      case TimeScaleType.HISTORY:
        return this.text("DataVisualizationSelection", "history");
      default:
        return undefined;
    }
  }

}

export default withTheme(DataVisualization);

