import React, { Component, ReactNode, RefObject } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Box, Theme } from "@material-ui/core";
import { Maybe, Nullable } from "types/aliases";
import { AppLayoutMode, MapButtonActions, MapLocationEventResult } from "types/sensoanUiTypes";
import MapLink, { MapMarkerData, MapState } from "data/map/MapLink";
import Localization from "data/localization-sensoan/Localization";
import MapBase from "data/map/MapBase";
import MapUi, { InfoBubbleState } from "data/map/MapUi";
import MapClustering from "data/map/MapClustering";
import { ReactComponent as DrawerSizeIcon } from "assets/map/icons/Map_drawer_size_icon.svg";
import { ReactComponent as FullScreenSizeIcon } from "assets/map/icons/Map_fullscreen_size_icon.svg";
import SIconButton from "components/styled-components/SIconButton";
import SSvgIcon from "components/styled-components/SSvgIcon";
import MapGeocoding, { HereMapsPlace } from "data/map/MapGeocoding";
import MapSearchBar from "components/map/MapSearchBar";

export enum InfoBubblePositioning {
  topRight = "topRight",
  bottomRight = "bottomRight",
  bottomLeft = "bottomLeft",
  topLeft = "topLeft",
}

interface Props extends RouteComponentProps {
  actionRequest: Nullable<MapButtonActions>;
  appLayoutMode: AppLayoutMode;
  clearActionRequest: () => void;
  infoBubbleContentEl: JSX.Element;
  infoBubbleData: Nullable<MapMarkerData[]>;
  mapState: MapState;
  markerData: MapMarkerData[];
  onAppLayoutModeChange: () => void;
  onMarkerGroupClick: (markerData: MapMarkerData[]) => Promise<void>;
  theme: Theme;
  drawerExpanded?: boolean;
}

interface State {
  containerSize: Nullable<{
    width: number;
    height: number;
  }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  group: any;
  infoBubblePositioning: Nullable<InfoBubblePositioning>;
  mapResizePending: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  markers: any[];
}

class HereMap extends Component<Props, State> {
  private mapContainerRef: RefObject<HTMLDivElement>;
  private mapUi = MapUi.getInstance();
  private mapBase = MapBase.getInstance();
  private mapClustering = MapClustering.getInstance();
  private mapGeocoding = MapGeocoding.getInstance();

  public constructor(props: Props) {
    super(props);
    this.state = {
      containerSize: null,
      group: null,
      infoBubblePositioning: null,
      mapResizePending: false,
      markers: [],
    };
    this.mapContainerRef = React.createRef();
  }

  public componentDidMount(): void {
    this.mapUi.setMapLocale(Localization.getInstance().getCurrentLocale().toLowerCase());
    this.mapBase.init();
    this.mapUi.init();
    this.mapGeocoding.init();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.mapBase.addListener("dbltap", (event: any) => this.handleDoubleTap(event));
    this.mapBase.addListener("resize", this.handleResize);

    if (this.props.markerData.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.mapClustering.init(this.props.markerData, this.props.appLayoutMode, (event: any): void => this.handleClusterClick(event));
    }

    const { current } = this.mapContainerRef;

    if (current) {
      this.setState({ containerSize: this.getContainerSize(current) });
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (this.props.actionRequest !== null && prevProps.actionRequest === null) {
      if (this.props.actionRequest === MapButtonActions.openInfoBubble || this.props.actionRequest === MapButtonActions.closeInfoBubble) {
        this.handleAction(this.props.actionRequest);
      }
    }

    if (this.didMarkerDataChange(prevProps.markerData, this.props.markerData)) {
      const { markerData } = this.props;

      if (this.mapClustering.getClusteredDataProvider() === null) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.mapClustering.init(markerData, this.props.appLayoutMode, (event: any): void => this.handleClusterClick(event));
      } else {
        this.mapClustering.updateClusteredDataProvider(markerData, this.props.appLayoutMode);
      }
    }

    const locale = Localization.getInstance().getCurrentLocale().toLowerCase();

    if (this.props.theme !== prevProps.theme || this.mapUi.getMapLocale() !== locale) {
      this.mapUi.setMapLocale(locale);
      this.mapUi.setMapTheme();
    }

    if (this.props.appLayoutMode === AppLayoutMode.mapWithDrawer) {
      if (!this.props.drawerExpanded && prevProps.drawerExpanded) {
      // calling this.map.getViewPort().resize() in this update will not set correct updated values to canvas size -> set local state to trigger another update
        this.setState({ mapResizePending: true });
      }

      if (!prevState.mapResizePending && this.state.mapResizePending) {
      // updated values are correct here
        this.mapBase.resize();
        this.setState({ mapResizePending: false });
      }
    }
  }

  public componentWillUnmount(): void {
    if (this.mapUi.getInfoBubble()) {
      this.mapUi.disposeInfoBubble();
    }
    this.mapBase.disposeMap();
  }

  public render(): ReactNode {
    return (
      <Box height="100%" width="100%" position="relative">
        {this.renderSizeTogglerButton()}
        {this.renderSearchBar()}
        <div id="here-status-map" style={{ height: "100%", width: "100%" }} ref={this.mapContainerRef}/>
        <div id="here-info-bubble" style={{ display: "none" }}>
          {this.mapUi.getInfoBubbleState() === InfoBubbleState.open ? this.props.infoBubbleContentEl : null}
        </div>
      </Box>
    );
  }

  private renderSearchBar(): Maybe<JSX.Element> {
    if (this.props.mapState === MapState.ACTIVE) {
      return <MapSearchBar appLayoutMode={this.props.appLayoutMode}/>;
    }
  }

  private handleSizeTogglerButtonClick = (): void => {
    this.mapUi.closeInfoBubble();
    this.props.onAppLayoutModeChange();
  };

  private renderSizeTogglerButton(): ReactNode {
    const { theme, appLayoutMode } = this.props;
    return (
      <SIconButton
        backGroundColor={theme.palette.type === "dark" ? "#292929" : "#f3f3f3"}
        disableRipple
        hoverBgColor={theme.palette.type === "dark" ? "#2e2e2e" : "#ececec"}
        inlineStyle={{
          borderRadius: "50%",
          position: "absolute",
          top: "16px",
          right: this.getSizeTogglerButtonHorizontalPosition(appLayoutMode),
          zIndex: 1,
        }}
        onClick={this.handleSizeTogglerButtonClick}
        size="2rem"
      >
        <SSvgIcon
          color={theme.palette.type === "dark" ? "#ccc" : "#333"}
          iconComponent={this.getSizeTogglerButtonIcon(appLayoutMode)}
          size="0.9rem"
          viewBox="0 0 18 18"
        />
      </SIconButton>
    );
  }

  private handleResize = (): void => {
    const { current } = this.mapContainerRef;

    if (current) {
      const { width, height } = this.getContainerSize(current);

      if (this.state.containerSize?.width !== width || this.state.containerSize?.height !== height) {
        this.setState({ containerSize: { width, height } });
      }
    }
  };

  private async handleDoubleTap(event: any): Promise<void> {  // eslint-disable-line @typescript-eslint/no-explicit-any
    const geo = this.mapBase.getMap().screenToGeo(event.currentPointer.viewportX, event.currentPointer.viewportY);
    const locations = await this.mapGeocoding.searchWithMapLocation({ lat: geo.lat, lng: geo.lng });

    let eventResult: Nullable<MapLocationEventResult> = null;

    if (locations && locations.length > 0) {
      // index 0 of locations should be the most relevant search result (determined by Here API)
      eventResult = locations[0] as HereMapsPlace;
    } else if (locations) {
      eventResult = "noPlaceFound";
    }
    MapLink.getLinkToComponents().postLocation(eventResult);
  }

  private handleClusterClick = (event: any): void => { // eslint-disable-line @typescript-eslint/no-explicit-any
    let data = event.target.getData();
    this.setState({ infoBubblePositioning: this.getInfoBubblePositioning(event.currentPointer.viewportX, event.currentPointer.viewportY) });

    if (!Array.isArray(data)) {
      data = [data];
    }
    this.props.onMarkerGroupClick(data as MapMarkerData[]);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getSizeTogglerButtonIcon(appLayoutMode: AppLayoutMode): React.ElementType<any> {
    if (appLayoutMode === AppLayoutMode.dataWithDrawer) {
      return DrawerSizeIcon;
    } else if (appLayoutMode === AppLayoutMode.mapWithDrawer){
      return FullScreenSizeIcon;
    } else {
      console.error("Unknown param. 'appLayoutMode' in HereMap.getSizeTogglerButtonIcon");
      return DrawerSizeIcon;
    }
  }

  private getSizeTogglerButtonHorizontalPosition(appLayoutMode: AppLayoutMode): string {
    if (appLayoutMode === AppLayoutMode.dataWithDrawer) {
      return "3%";
    } else if (appLayoutMode === AppLayoutMode.mapWithDrawer){
      return "2%";
    } else {
      console.error("Unknown param. 'appLayoutMode' in HereMap.getSizeTogglerButtonHorizontalPosition");
      return "3%";
    }
  }

  private getContainerSize(element: HTMLDivElement): {width: number; height: number} {
    const { width, height } = element.getBoundingClientRect();
    return { width, height };
  }

  private getInfoBubblePositioning(viewportX: number, viewportY: number): Nullable<InfoBubblePositioning> {
    const { current } = this.mapContainerRef;

    if (current) {
      const { width, height } = this.getContainerSize(current);

      const isToLeftFromCenter = viewportX < width / 2;
      const isToTopFromCenter = viewportY < height / 2;
      let positioning: InfoBubblePositioning;

      if (isToLeftFromCenter && isToTopFromCenter) {
        positioning = InfoBubblePositioning.bottomRight;
      } else if (!isToLeftFromCenter && isToTopFromCenter) {
        positioning = InfoBubblePositioning.bottomLeft;
      } else if (isToLeftFromCenter && !isToTopFromCenter) {
        positioning = InfoBubblePositioning.topRight;
      } else {
        positioning = InfoBubblePositioning.topLeft;
      }
      return positioning;
    } else {
      console.error("error in HereMap.getInfoBubblePositioning() / positioning could not be calculated");
      return null;
    }
  }

  private didMarkerDataChange(previousData: MapMarkerData[], currentData: MapMarkerData[]): boolean {
    if (previousData.length >= currentData.length) {
      return !previousData.every(mDataItemA => {
        return currentData.some(mDataItemB => {
          return mDataItemA.id === mDataItemB.id;
        });
      });
    } else if (previousData.length < currentData.length){
      return !currentData.every(mDataItemA => {
        return previousData.some(mDataItemB => {
          return mDataItemA.id === mDataItemB.id;
        });
      });
    } else {
      return false;
    }
  }

  private areLocationsInDataIdentical(data: any[]): boolean { // eslint-disable-line @typescript-eslint/no-explicit-any
    return data.every(d => d.location().lat === data[0].location().lat && d.location().lng === data[0].location().lng);
  }

  private handleAction(action:MapButtonActions.openInfoBubble | MapButtonActions.closeInfoBubble): void {
    switch (action) {
      case MapButtonActions.closeInfoBubble:{
        this.mapUi.closeInfoBubble();
        return this.props.clearActionRequest();
      }

      case MapButtonActions.openInfoBubble:{
        this.mapUi.openInfoBubble(this.props.infoBubbleData, this.state.infoBubblePositioning);
        return this.props.clearActionRequest();
      }

      default:
        console.error("Unknown action in HereMap.handleAction(): " + action);
        return this.props.clearActionRequest();
    }
  }
}

export default withRouter(HereMap);
