import { Maybe, Nullable } from "types/aliases";
import LatestData from "data/data/LatestData";
import Device from "data/device/Device";
import DeviceGroup from "data/device/DeviceGroup";
import DataRepository from "./DataRepository";
import AsyncCache from "data/utils/AsynCache";
import LatestDeviceDataRepository from "./LatestDeviceDataRepository";
import BackendFactory from "data/BackendFactory";

export default class DeviceRepository implements DataRepository {

  private static instance: DeviceRepository = new DeviceRepository();

  private readonly initQueue = new AsyncCache();

  private initialized = false;
  private devices: Device[] = [];
  private groupsTree: DeviceGroup[] = [];
  private groupsList: DeviceGroup[] = [];

  public static getInstance(): DeviceRepository {
    return this.instance;
  }

  public getName(): string {
    return "devices";
  }

  public getDevice(deviceId: string): Maybe<Device> {
    const index = this.devices.findIndex((d) => d.getId() === deviceId);

    if (index !== -1) {
      return this.devices[index];
    } else {
      console.error(`Device not found in DeviceRepository with deviceId ${deviceId}!`);
    }
  }

  public getDevices(): Device[] {
    return this.devices;
  }

  public getGroups(): DeviceGroup[] {
    return this.groupsTree;
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  public async init(): Promise<void> {
    const initRepo = async (): Promise<void> => {
      await this.fetchDevices();
      await Promise.all(this.devices.map((d: Device): Promise<Nullable<LatestData>> => d.getLatestData()));
      this.initialized = true;
    };
    return this.initQueue.get("initDevicesRepo", initRepo);
  }

  public clear(): void {
    this.devices = [];
    this.groupsTree = [];
    this.groupsList = [];
    LatestDeviceDataRepository.getInstance().clear();
    this.initQueue.clear();
    this.initialized = false;
  }

  private constructor() {
    /* empty constructor */
  }

  private async fetchDevicesForGroup(group: DeviceGroup): Promise<void> {
    const devices = await group.getDevices();
    this.devices = this.devices.concat(devices);
  }

  private async fetchSubGroups(group: DeviceGroup): Promise<void> {
    const subGroups = await group.getGroups();

    if (subGroups && subGroups.length > 0) {
      this.groupsList = this.groupsList.concat(subGroups);
      await Promise.all(subGroups.map(async (g): Promise<void> => this.fetchSubGroups(g)));
    }
  }

  private async fetchDevices(): Promise<void> {
    this.groupsTree = await BackendFactory.getBackend().getRootDeviceGroups();
    this.groupsList = this.groupsTree;
    await Promise.all(this.groupsList.map(async (g): Promise<void> => this.fetchSubGroups(g)));

    if (!this.groupsList || this.groupsList.length === 0) {
      this.devices = [];
    } else {
      await Promise.all(this.groupsList.map(async (g): Promise<void> => this.fetchDevicesForGroup(g)));
    }
  }
}
