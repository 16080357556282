export enum Locales {
  FI = "FI",
  EN = "EN",
}

export enum LocaleInclCountry {
  FI = "fi-FI",
  EN = "en-GB",
}

// property names are wrapped in quotes to make it easier to copy the contents to Database if needed
export const localizationTexts = {
  "FI": {
    "Login": {
      "email": "Sähköposti",
      "lang": "Kieli",
      "login": "Kirjaudu",
      "pw": "Salasana",
      "forgotPassword": "Unohditko salasanasi?",
      "verificationCode": "Minulla on jo vahvistuskoodi",
      "backToLogIn": "Palaa kirjautumissivulle",
      "confirmLogIn": "Vahvista ja kirjaudu sisään",
      "enterVerificationCodeAndPassword": "Syötä uusi salasana ja vahvistuskoodi joka on lähetetty osoitteeseen ",
      "emailFormatNotValid": "Sähköpostiosoitteen rakenne on virheellinen.",
      "emailNotVerified": "Sähköpostiosoitetta ei ole vamistettu. Ota yhteys järjestelmänvalvojaan.",
      "temporaryPasswordExpired": "Väliaikainen salasanasi on vanhentunut. Ota yhteys järjestelmänvalvojaan.",
      "passwordCannotBeEmpty": "Salasana ei voi olla tyhjä.",
      "usernameCannotBeEmpty": "Sähköpostiosoite ei voi olla tyhjä.",
      "passwordReset": "Salasanan nollaus",
      "passwordCannotBeResetCurrently": "Käyttäjän salasanaa ei voida tällä hetkellä nollata. Syötä sähköpostiin lähetetty tilapäinen salasana. Jos ongelma jatkuu ota yhteyttä järjestelmänvalvojaan.",
      "verificationCodeSentToEmail": "Varmistuskoodi salasanasi nollaamiseksi lähetetään sähköpostiisi. Jos varmistuskoodia ei löydy, tarkista myös roskapostikansio.",
      "invalidCode": "Virheellinen vahvistuskoodi, ole hyvä ja tarkista koodi ja yritä uudelleen.",
      "codeExpired": "Vahvistuskoodi on vanhentunut. Ole hyvä ja pyydä uusi vahvistuskoodi.",
      "newPasswordRequired": "Salasanasi tulee uusia",
      "userNotLoggedIn": "Käyttäjä ei ole sisäänkirjautunut",
      "confirmationCode": "Vahvistuskoodi",
    },
    "AdminView": {
      "users": "Käyttäjät",
      "user": "Käyttäjä",
      "organisation": "Organisaatio",
      "policies": "Käyttöoikeudet",
      "notifications": "Ilmoitukset",
      "event": "Tapahtuma",
      "noNotificationsAvailable": "Ilmoituksia ei ole saatavilla",
      "notificationManagement": "Ilmoitustenhallinta",
      "removeSubscription": "Poista ilmoitustilaus",
      "fromUser": "-ilmoitus käyttäjältä",
      "failedToDeleteSubscription": "Tilauksen poistaminen epäonnistui",
      "newOrganisationsName": "Organisaation nimi",
      "failedToDeleteOrganizationHasChildren": "Organisaatiota, jolla on aliorganisaatioita, ei voi poistaa",
      "failedToDeleteOrganization": "Organisaation poistaminen epäonnistui",
      "deleteOrganization": "Poista organisaatio",
      "deleteOrganizationConfirmation": "Oletko varma että haluat poistaa organisaation",
      "organisationsChildren": "Organisaation aliorganisaatiot",
      "organisationDetails": "Organisaation tiedot",
      "organisationName": "Organisaation nimi",
      "organisationsPolicyGroups": "Organisaation käyttöoikeusryhmät",
      "failedToDeleteUser": "Käyttäjän poistaminen epäonnistui",
      "noUsers": "Ei käyttäjiä",
      "policyGroup": "Käyttöoikeusryhmä",
      "deleteUser": "Poista käyttäjä",
      "removeUserFromOrganization": "Poista käyttäjä organisaatiota",
      "invalidEmailAddress": "Virheellinen sähköpostiosoite",
      "policyGroupMustBeSelected": "Käyttäjäoikeusryhmä tulee olla valittuna",
      "failedToCreateUser": "Käyttäjän luominen epäonnistui",
      "userAlreadyExists": "Käyttäjä on jo olemassa",
      "createNewUser": "Luo uusi käyttäjä",
      "failedToRemoveUserFromPolicyGroup": "Käyttäjän poistaminen epäonnistui käyttöoikeusryhmästä",
      "failedToAddUserToPolicyGroup": "Käyttäjän lisääminen epäonnistui käyttöoikeusryhmään",
      "owner": "Omistaja",
      "addSubGroup": "Lisää uusi aliryhmä",
      "removeGroup": "Poista ryhmä",
      "lastUpdate": "Viimeisin päivitys",
      "groupName": "Ryhmän nimi",
      "failedToAddGroup": "Laitteen lisääminen epäonnistui",
    },
    "deviceDrawer": {
      "noDevicesOrGroupsFound": "Laitteita tai ryhmiä ei löytynyt!",
    },
    "OrganisationSelector": {
      "selectChild": "Valitse aliorganisaatio",
      "organisations": "Organisaatiot",
    },
    "AddDevicePopup": {
      "failedToAddDevice": "Laitteen lisääminen epäonnistui",
      "addNewDevice": "Lisää uusi laite",
      "deviceIdentifier": "Laitetunniste",
      "addDevice": "Lisää laite",
      "groups": "Groups",
    },
    "AddNotificationSubscriptionPopup": {
      "failedToAddNotification": "Ilmoituksen lisääminen epäonnistui",
      "newNotification": "Uusi ilmoitustilaus",
      "addNotification": "Lisää ilmoitus",
      "selectEventType": "Valitse tapahtuman tyyppi",
      "selectUser": "Valitse käyttäjä",
      "emailAlert": "Sähköposti-ilmoitus",
      "textMessageAlert": "Tekstiviesti-ilmoitus",
      "eventType": "Tapahtuman tyyppi",
    },
    "SwInfo": {
      "web": "Web käyttöliittymän ohjelmistoversio",
      "backend": "Backend ohjelmistoversio",
    },
    "SensorNameAndEventSelector": {
      "reading": "Lukema",
      "loadingReading": "Lukemaa ladataan...",
      "selectDeviceAndSensor": "Valitse laite ja anturi",
      "delete": "Poista",
      "giveSensorDisplayName": "Nimeä anturi",
    },
    "NavigationMenu": {
      "jobQueue": "Työjono",
      "metrics": "Mittaristo",
      "map": "Kartta",
      "alarms": "Hälytykset",
    },
    "BugReport": {
      "ruuviTag": "RuuviTag",
      "userManagement": "Käyttäjähallinta",
      "other": "Muu",
      "correct": "Millainen toiminnan pitäisi olla?",
      "minor": "Vähäinen",
      "subject": "Otsikko",
      "measurementjobs": "Mittaustyöt",
      "title": "Raportoi virheestä",
      "userView": "Käyttäjä",
      "fatal": "Kriittinen",
      "reproduce": "Ohjeet virheen toistamiseen",
      "measurementsets": "Mittaussetit",
      "current": "Millainen nykyinen toiminta on?",
      "dataError": "Ystävällisesti, täytä kaikki tiedot",
      "events": "Hälytykset",
      "area": "Alue",
      "subfield": "Osa-alue",
      "severity": "Vakavuus",
      "normal": "Vakava",
      "devices": "Laitteet",
      "sent": "Kiitos ilmoituksesta, raportti on lähetetty",
      "webUi": "WebUi",
      "device": "Laite",
      "send": "Raporttia lähetetään",
      "gateway": "Modeemi",
      "status": "Yleiskuva",
    },
    "SensorNameAndEventSelection": {
      "validationError": "Anturinäkymässä tulee olla ainakin yksi anturi",
    },
    "CommentAdd": {
      "cancel": "Peruuta",
      "save": "Tallenna",
    },
    "DataVisualizationSelection": {
      "continueFromScaleHint": "Jatka ilman muutoksia",
      "gauge": "Mittari",
      "scaleValues": "Mittarajat",
      "gaugeChart": "Mittaritaulukko",
      "selectChart": "Muoto",
      "steppedAreaChart": "Step-funktio",
      "dataAggregation": "Datan aggregointi",
      "numberDisplay": "Numeronäyttö",
      "lineChart": "Viivakäyrä",
      "scaleHintText": "Anturinäkymään on liitetty mittari ilman mittarajoja. Mittari ilman mittarajoja näytetään numeronäyttönä.",
      "history": "Historia",
      "colors": "Värit",
      "aggregate": "Aggregoitu",
      "scaleLowerLimit": "Alaraja",
      "timeScaleType": "Datan prosessointityyppi",
      "aggregationValue": "Aggregointiarvo",
      "scaleValueError": "Toinen mittaraja-arvo puuttuu",
      "gaugeWithoutScaleMsg": "Mittari ilman mittarajoja näytetään numeronäyttönä",
      "returnFromScaleHint": "Palaa muokkaamaan anturinäkymää",
      "latest": "Viimeisin",
      "scaleUpperLimit": "Yläraja",
    },
    "MainMenu": {
      "jobQueue": "Työjono",
      "alarms": "Hälytykset",
      "metrics": "Mittaristo",
      "loading": "Ladataan",
      "map": "Kartta",
      "measurementsets": "Mittaussetit",
      "measurementjobs": "Mittaustyöt",
      "events": "Hälytykset",
      "devices": "Laitteet",
      "status": "Yleiskuva",
    },
    "ListViewFab": {
      "loadMore": "Lataa lisää rivejä",
    },
    "MeasJobsCreateView": {
      "cancel": "Peruuta",
      "newJob": "Uusi työ",
      "save": "Tallenna",
      "endTreshold": "Loppuajan tulee olla vähintään #1 minuuttia alkuhetkestä",
      "manual": "Manuaalinen",
      "startTreshold": "Alkuajan tulee olla vähintään #1 minuuttia nykyhetkestä",
      "selectStartType": "Valitse aloitustapa",
      "timer": "Ajastin",
      "startMethod": "Aloitustapa",
      "startTime": "Aloitusaika",
      "copy": "Kopio",
      "endTime": "Loppuaika",
      "saveAndStart": "Aloita ja tallenna",
    },
    "MeasSetSelect": {
      "clear": "Tyhjennä",
    },
    "MeasurementSetConfiguration": {
      "nameOfSet": "Setin nimi",
      "month": "kk",
      "latitude": "leveysastetta",
      "additionalInfo": "Lisätietoa",
      "timeSpan": "Ajanjakso",
      "day": "pv",
      "backwards": "taaksepäin",
      "group": "Ryhmä",
      "longitude": "pituusastetta",
    },
    "MeasurementSetsUtilityBar": {
      "group": "Ryhmä",
    },
    "MeasurementSetsWrapper": {
      "addNewSet": "Lisää uusi setti",
      "cancel": "Peruuta",
      "editMeasurementSet": "Muokkaa mittaussettiä",
      "chartConfig": "Anturinäkymä",
      "addNewChartConfig": "Lisää uusi anturinäkymä",
      "edit": "Muokkaa",
      "continue": "Jatka",
      "newMeasurementSet": "Uusi mittaussetti",
      "save": "Tallenna",
      "delete": "Poista",
      "newChartConfig": "Uusi anturinäkymä",
    },
    "MeasJobsDataView": {
      "endAction": "Lopeta",
      "delete": "Poista",
      "editComment": "Kirjoita kommentti",
      "startAction": "Aloita",
      "info": "Perustiedot",
    },
    "UserMenu": {
      "settings": "Asetukset",
      "userManagement": "Käyttäjähallinta",
      "logOut": "Kirjaudu ulos",
      "userView": "Käyttäjä",
      "info": "Tietoja",
    },
    "UserView": {
      "youAreAboutToLogOut": "Olet kirjautumassa ulos. Haluatko jatkaa?",
      "doYouWantToLogOut": "Haluatko kirjautua ulos?",
      "chooseLanguage": "Valitse kieli",
      "enterFirstnameAndLastname": "Syötä etu- ja sukunimesi",
      "enterPhoneNumber": "Syötä puhelinnumerosi",
      "enterOldPasswordAndNewPassword": "Syötä vanha ja uusi salasanasi",
      "invalidPhoneNumberFormat": "Puhelinnumero täytyy olla kansainvälisessä muodossa. (esim. +358501234567)",
      "invalidName": "Nimi ei voi olla tyhjä. Ole hyvä ja syötä nimi.",
      "logOut": "Uloskirjautuminen",
      "incorrectCredentials": "Vanha salasana on väärin. Tarkista salasana ja yritä uudelleen.",
      "firstname": "Etunimi",
      "lastname": "Sukunimi",
      "phonenumber": "Puhelinnumero",
      "changeLanguage": "Vaihda kieli",
      "confirmNameChange": "Vahvista ja vaihda nimi",
      "updatePhoneNumber": "Päivitä puhelinnumero",
      "confirmPasswordChange": "Vahvista ja vaihda salasana",
      "oldPassword": "Vanha salasana",
    },
    "MeasurementSetDisplayView": {
      "deletionError": "Settiä ei voitu poistaa, koska settiin on liitetty mittaustöitä",
      "sensorViews": "Anturinäkymät",
    },
    "Actions": {
      "useNow": "Nyt",
      "close": "Sulje",
      "send": "Lähetä",
      "add": "Lisää",
      "remove": "Poista",
      "sort": "Lajittele",
      "create": "Luo",
      "save": "Tallenna",
      "cancel": "Peruuta",
      "apply": "Aseta",
      "confirm": "Vahvista",
      "accept": "Hyväksy",
      "search": "Etsi",
      "logOut": "Kirjaudu ulos",
    },
    "DevicesWrapper": {
      "searchForDevice": "Etsi laitetta",
    },
    "ChartConfigEditView": {
      "continueFromScaleHint": "Jatka ilman muutoksia",
      "sensorLimitValidationError": "Virhe lisätessä uutta anturinäkymää: Mittarissa, numeronäytössä ja step-funktiossa voi käyttää vain yhtä sensoria kerrallaan. Poista ylimääräiset sensorit anturinäkymästä",
      "configureSensors": "Määritä anturit",
      "sensorLimitReachedError": "Virhe lisätessä uutta anturinäkymää: mittarissa, numeronäytössä ja step-funktiossa voi käyttää vain yhtä sensoria kerrallaan. Poista ylimääräiset sensorit anturinäkymästä",
      "missingValuesError": "Virhe lisätessä uutta anturinäkymää: Täytä kaikki vaaditut kentät",
      "scaleValueError": "Virhe lisätessä uutta anturinäkymää: virheellinen mittaraja-arvo",
      "selectDataVisualization": "Valitse visualisointinäkymä",
      "returnFromScaleHint": "Palaa muokkaamaan anturinäkymää",
      "scaleHintText": "Anturinäkymään on liitetty mittari ilman mittarajoja. Mittari ilman mittarajoja näytetään numeronäyttönä.",
      "nameOfChartConfig": "Anturinäkymän nimi",
      "invalidScaleValueError": "Virhe lisätessä uutta anturinäkymää: virheellinen mittaraja-arvo",
      "validationError": "Virhe lisätessä uutta anturinäkymää: Täytä kaikki vaaditut kentät",
    },
    "MeasJobsListView": {
      "add": "Lisää uusi työ",
      "all": "Kaikki",
      "jobName": "Työn nimi",
      "comments": "Kommentit",
      "activity": "Aktiivisuus",
      "notDefined": "Ei asetettu",
      "start": "Alku",
      "not_started": "Ei aloitettu",
      "running": "Aktiivinen",
      "measSet": "Mittaussetti",
      "ready": "Valmis",
      "responsible": "Vastaava",
      "end": "Loppu",
      "time": "Ajanjakso",
      "status": "Tila",
    },
    "MeasuremenSetHeaderDataRow": {
      "additionalInfo": "Lisätietoa",
      "nameOfSet": "Setin nimi",
    },
    "MeasurementSetParentSelector": {
      "select": "Valitse",
      "group": "Ryhmä",
    },
    "Overview": {
      "all": "Kaikki",
      "allEvents": "Kaikki hälytykset",
      "allMeasurementSets": "Kaikki mittaussetit",
      "activeJobs": "Aktiiviset mittaustyöt",
      "active": "Aktiiviset",
      "activeEvents": "Aktiiviset hälytykset",
      "allJobs": "Kaikki mittaustyöt",
      "activeDevices": "Aktiiviset laitteet",
      "allDevices": "Kaikki laitteet",
    },
    "MeasurementSetConfigSelection": {
      "additionalInfo": "Lisätietoa",
      "timeSpan": "Ajanjakso",
      "nameOfSet": "Setin nimi",
      "select": "Valitse",
      "backwards": "taaksepäin",
    },
    "MeasurementSetEditView": {
      "addNewChartConfig": "Lisää uusi anturinäkymä",
      "edit": "Muokkaa",
      "locationNotFound": "Sijaintia ei voitu määrittää",
      "locationError": "Sijainnin määrittämisessä tapahtui virhe",
      "sensorViews": "Anturinäkymät",
      "delete": "Poista",
      "validationError": "Virhe tallennettaessa uutta mittaussettiä: setistä puuttuu pakollisia tietoja tai siihen ei ole liitetty yhtään anturinäkymää",
    },
    "MeasJobs": {
      "measSet": "Mittaussetti",
      "addNew": "Lisää uusi työ",
    },
    "MeasurementSets": {
      "addNewSet": "Lisää uusi setti",
      "endTimePicker": "Loppuaika",
      "paramsTitle": "Parametrit",
      "spanTimePicker": "Aikajakso",
      "groups": "Ryhmä",
    },
    "AlarmLimitsEdit": {
      "disabled": "Ei asetettu",
      "min": "Alaraja",
      "max": "Yläraja",
    },
    "EventsView": {
      "acknowledge": "Kuittaa",
      "showAll": "Näytä kaikki",
      "state": "Hälytyksen tila",
      "showActive": "Näytä aktiiviset",
      "ackSignature": "Kuittaus",
      "description": "Kuvaus",
    },
    "PreviewPlaceholderBox": {
      "placeholderInfoText": "Valittuasi anturit ja visualisointinäkymän näet esikatselun tässä.",
    },
    "DeviceWindow": {
      "displayName": "Laitteen nimi",
      "deviceGroup": "Laitteen ryhmä",
      "deviceGroups": "Laitteen ryhmät",
      "openSensorDataPopup": "Avaa sensoridataikkuna",
      "addAlarmTrigger": "Aseta hälytysrajat",
      "editAlarmTrigger": "Konfiguroi hälytysrajoja",
      "openActivityLabeling": "Avaa tapahtuman merkitseminen",
      "label": "Tapahtuman nimi",
      "activityLabelingInfo": "Lisää tapahtumamerkintä",
      "customLabel": "Oma tapahtumamerkintä",
      "activityLabelingError": "Tapahtumamerkinnän lisäämisessä tapahtui virhe.",
    },
    "DeviceWindowPlaceHolder": {
      "noDeviceSelected": "Laitetta ei ole valittu",
    },
    "ThemeInput": {
      "theme": "Teema",
    },
    "MeasurementSetsDataTable": {
      "noSearchResults": "Ei hakutuloksia",
    },
    "MeasSetGroupTree": {
      "groups": "Ryhmät",
    },
    "MeasurementSetLocationSelector": {
      "gpsLocationOption": "Valitse sijainti",
      "locationNotification": "Valittua laitetta käytetään vain sijainnin määrittämiseen",
      "select": "Valitse",
      "gpsFunctionOption": "Käytä laitteen sijaintia",
      "loadingDevice": "Laitetta haetaan...",
      "selectedLocation": "Valittu sijainti",
    },
    "ErrorMessage": {
      "fieldIsRequired": "Pakollinen kenttä",
      "noFutureTime": "Aika ei voi olla tulevaisuudessa",
    },
    "MeasSetGroupMenu": {
      "cancel": "Peruuta",
      "saveSuccessMsg": "Ryhmä on nyt luotu",
      "select": "Valitse",
      "save": "Tallenna",
      "groupName": "Ryhmän nimi",
      "confirmGroupDelete": "Haluatko varmasti poistaa ryhmän: ",
      "saveErrorMsg": "Virhe: ryhmän luominen epäonnistui",
      "deleteSuccessMsg": "Ryhmä on nyt poistettu",
      "createNewGroup": "Luo uusi ryhmä",
      "deleteGroup": "Poista ryhmä",
      "deleteErrorMsg": "Virhe: ryhmän poistaminen epäonnistui",
      "ok": "OK",
      "return": "Palaa",
      "group": "Ryhmä",
    },
    "DevicesView": {
      "limitsEditTitle": "Anturin hälytysrajat",
      "metadata": "Lisätietoa",
      "measSet": "Mittaussetti",
      "reading": "Lukema",
      "sensor": "Anturi",
      "time": "Mittausaika",
      "limits": "Hälytysrajat",
    },
    "LineChart": {
      "noDataFoundMsg": "Dataa ei löytynyt sensoreille:",
      "nodata": "Ei riittävästi dataa esittämiseen",
    },
    "DeviceSettings": {
      "general": "Yleiset",
      "attributes": "Määritteet",
      "otaUpdate": "Ohjelmistopäivitys",
      "installOta": "Asenna päivitys",
      "cancelOta": "Peruuta päivitys",
      "noAttributesDefined": "Ei määritettyjä määritteitä",
      "HWID": "Laitteistotunniste",
      "canSee": "Näkyvyys",
      "organization": "Organisaatio",
      "serialNumber": "Sarjanumero",
      "disableModemSleep": "Poista käytöstä modeemin lepotila",
      "updateInterval": "Raportointi-intervalli",
      "measurementInterval": "Mittausintervalli",
      "firmwareVersion": "Laiteohjelmistoversio",
      "resetCount": "Uudelleenkäynnistykset",
      "size": "Koko (kt)",
      "version": "Versio",
      "description": "Kuvaus",
      "otaId": "Päivityspaketin tunniste",
      "noUpdateAvailable": "Ei päivityspaketteja saatavilla!",
      "noInstallationInProgress": "Ei käynnissä olevaa asennusta. Valitse asennettava päivityspaketti!",
      "availableOtaPackages": "Saatavilla olevat päivityspaketit",
      "otaUpdateProcess": "Päivitysprosessi",
      "deviceFirmwareInformation": "Laitteen ohjelmistotiedot",
      "ledColor": "Ledin väri",
      "toggleLed": "Vaihda ledin tilaa",
      "whiteList": "Hyväksyttyjen lista",
      "notRequestedList": "Pois kytkettyjen mittausten lista",
      "gnss": "GPS",
      "ncell": "Naapurisolujen mittaus",
      "mcell_loc": "Mobiiliverkkopaikannus",
      "logs": "Näytä lokit",
    },
    "Common": {
      "nextPage": "Seuraava sivu",
      "previousPage": "Edellinen sivu",
      "notAvailable": "Ei saatavilla",
      "rowsPerPage": "Rivejä sivulla",
      "email": "Sähköposti",
      "settings": "Asetukset",
      "error": "Virhe",
      "ok": "Ok",
      "username": "Käyttäjänimi",
      "networkError": "Internetyhteydessäsi on ongelmia. Ole hyvä ja tarkista internetyhteytesi ja yritä uudelleen.",
      "unableToPerformAction": "Toimintoa ei voida suorittaa. Yritä uudelleen myöhemmin. Jos ongelma jatkuu, ota yhteys järjestelmänvalvojaan.",
      "errorOccurred": "Tapahtui virhe: ",
      "tooManyAttempts": "Olet yrittänyt liian monta kertaa pienen ajan sisään. Ole hyvä ja yritä myöhemmin uudelleen.",
      "incorrectCredentials": "Kirjautumistiedot ovat virheelliset. Ole hyvä ja tarkista kirjautumistietosi ja yritä uudelleen.",
      "passwordMustHaveNumbers": "Salasana ei noudata asetettuja vaatimuksia. Salasanassa tulee olla numeroita.",
      "passwordMustHaveLowercaseCharacters": "Salasana ei noudata asetettuja vaatimuksia. Salasanassa tulee olla pieniä kirjaimia.",
      "passwordMustBeLongEnough": "Salasana ei noudata asetettuja vaatimuksia. Salasana ei ole riittävän pitkä.",
      "userSessionExpired": "Käyttäjän kirjautuminen on vanhentunut. Ole hyvä ja virkistä selaimesi ja yritä uudelleen.",
      "passwordsNotMatching": "Salasanat eivät täsmää.",
      "newPassword": "Uusi salasana",
      "confirmNewPassword": "Vahvista uusi salasana",
      "latestReading": "Viimeisin mittaus",
      "location": "Sijainti",
      "locationNotSpecified": "Sijaintia ei ole määritetty",
      "sensors": "Anturit",
      "generalInfo": "Perustiedot",
      "type": "Tyyppi",
      "openDeviceSettings": "Avaa laitteen asetukset",
      "measurements": "Mittaukset",
      "device": "Laite",
      "devices": "Laitteet",
      "measurementJob": "Mittaustyö",
      "measurementJobs": "Mittaustyöt",
      "measurementSet": "Mittaussetti",
      "measurementSets": "Mittaussetit",
      "event": "Hälytys",
      "events": "Hälytykset",
      "status": "Yleiskuva",
    },
  },
  "EN": {
    "Login": {
      "email": "Email",
      "lang": "Language",
      "login": "Login",
      "pw": "Password",
      "backToLogIn": "Return to log in",
      "confirmLogIn": "Confirm and log in",
      "forgotPassword": "Forgot password?",
      "verificationCode": "I already have a verification code",
      "enterVerificationCodeAndPassword": "Enter new password and verification code sent to ",
      "emailFormatNotValid": "Email format is not valid.",
      "emailNotVerified": "Email has not been verified, please contact administrator",
      "temporaryPasswordExpired": "Temporary password has expired and must be reset by an administrator.",
      "passwordCannotBeEmpty": "Password cannot be empty.",
      "usernameCannotBeEmpty": "Email cannot be empty.",
      "passwordReset": "Password reset",
      "passwordCannotBeResetCurrently": "User password cannot be reset in the current state. You need to enter your temporary password. If you did not receive an email with your temporary password, please contact your admin.",
      "verificationCodeSentToEmail": "Verification code will be sent to your email. If you don´t find it in your inbox, please check your junk mail folder before requesting a new one.",
      "invalidCode": "Invalid verification code provided, please check and try again.",
      "codeExpired": "Verification code is expired. Please request a new verification code.",
      "newPasswordRequired": "New password required",
      "userNotLoggedIn": "User is not logged in",
      "confirmationCode": "Verification code",
    },
    "AdminView": {
      "users": "Users",
      "user": "User",
      "organisation": "Organisation",
      "policies": "Policies",
      "notifications": "Notifications",
      "event": "Event",
      "noNotificationsAvailable": "No notifications available",
      "notificationManagement": "Notification Management",
      "removeSubscription": "Remove subscription",
      "fromUser": "from user",
      "failedToDeleteSubscription": "Failed to delete subscription",
      "newOrganisationsName": "Name of the new organization",
      "failedToDeleteOrganization": "Failed to delete organization",
      "failedToDeleteOrganizationHasChildren": "Organization cannot be deleted, it has sub-organizations",
      "deleteOrganization": "Delete organization",
      "deleteOrganizationConfirmation": "Are your sure you want to delete the organization",
      "organisationsChildren": "Children of the organization",
      "organisationDetails": "Organisation details",
      "organisationName": "Name of the organization",
      "organisationsPolicyGroups": "Policy groups of the organisation",
      "failedToDeleteUser": "Failed to delete the user",
      "noUsers": "No users",
      "policyGroup": "Policy Group",
      "deleteUser": "Delete user",
      "removeUserFromOrganization": "Remove user from organization",
      "invalidEmailAddress": "Invalid email address",
      "policyGroupMustBeSelected": "Policy group must be selected",
      "failedToCreateUser": "Failed to create user",
      "userAlreadyExists": "User already exists",
      "createNewUser": "Create a new user",
      "failedToRemoveUserFromPolicyGroup": "Failed to remove user from policy group",
      "failedToAddUserToPolicyGroup": "Failed to add user to policy group",
      "owner": "Owner",
      "addSubGroup": "Add a new sub group",
      "removeGroup": "Remove group",
      "lastUpdate": "Last update",
      "groupName": "Name of the group",
      "failedToAddGroup": "Failed to add a group",
    },
    "DeviceDrawer": {
      "noDevicesOrGroupsFound": "No devices or groups found!",
    },
    "OrganisationSelector": {
      "selectChild": "Select child",
      "organisations": "Organisations",
    },
    "AddDevicePopup": {
      "failedToAddDevice": "Failed to add device",
      "addNewDevice": "Add new device",
      "deviceIdentifier": "Device identifier",
      "addDevice": "Failed to add device",
      "groups": "Ryhmät",
    },
    "AddNotificationSubscriptionPopup": {
      "failedToAddNotification": "Failed to add notification",
      "newNotification": "New notification",
      "addNotification": "Add notification",
      "selectEventType": "Select event type",
      "selectUser": "Select user",
      "emailAlert": "Email alert",
      "textMessageAlert": "Text message alert",
      "eventType": "Event type",
    },
    "SwInfo": {
      "web": "Web UI software version",
      "backend": "Backend software version",
    },
    "SensorNameAndEventSelector": {
      "reading": "Reading",
      "loadingReading": "Loading the reading...",
      "selectDeviceAndSensor": "Select device and sensor",
      "delete": "Delete",
      "giveSensorDisplayName": "Give a display name to sensor",
    },
    "NavigationMenu": {
      "jobQueue": "Job Queue",
      "metrics": "Metrics",
      "map": "Map",
      "alarms": "Alarms",
    },
    "BugReport": {
      "ruuviTag": "RuuviTag",
      "userManagement": "User Management",
      "other": "Other",
      "correct": "What is the expected correct behavior?",
      "minor": "Minor",
      "subject": "Title",
      "measurementjobs": "Measurement Jobs",
      "title": "Bug reporting",
      "userView": "User",
      "fatal": "Critical",
      "reproduce": "Steps to reproduce",
      "measurementsets": "Measurement Sets",
      "current": "What is the current bug behavior?",
      "dataError": "Please, fill all inputs",
      "events": "Events",
      "area": "Entity",
      "subfield": "Field",
      "severity": "Severity",
      "normal": "Severe",
      "jobQueue": "Job Queue",
      "devices": "Devices",
      "sent": "Thank you for submitting, the report has been sent",
      "webUi": "WebUi",
      "device": "Device",
      "send": "Sending report",
      "gateway": "Gateway",
      "status": "Status",
    },
    "SensorNameAndEventSelection": {
      "validationError": "A sensor view has to contain at least one sensor",
    },
    "CommentAdd": {
      "cancel": "Cancel",
      "save": "Save",
    },
    "DataVisualizationSelection": {
      "continueFromScaleHint": "Continue without changes",
      "gauge": "Gauge",
      "scaleValues": "Scale values",
      "gaugeChart": "Gauge Chart",
      "selectChart": "Chart type",
      "steppedAreaChart": "Stepped Area Chart",
      "dataAggregation": "Aggregate the data",
      "numberDisplay": "Number Display",
      "lineChart": "Line Chart",
      "scaleHintText": "This sensor view includes a gauge without scale values. A gauge without scale values will be displayed as a number display.",
      "history": "History",
      "colors": "Colors",
      "aggregate": "Aggregate",
      "scaleLowerLimit": "Lower limit",
      "timeScaleType": "Data processing type",
      "aggregationValue": "Aggregation value",
      "scaleValueError": "Second scale value is missing",
      "gaugeWithoutScaleMsg": "A gauge without scale is displayed as a number display",
      "returnFromScaleHint": "Return and edit sensor view",
      "latest": "Latest",
      "scaleUpperLimit": "Upper limit",
    },
    "MainMenu": {
      "jobQueue": "Job Queue",
      "alarms": "Alarms",
      "metrics": "Metrics",
      "loading": "Loading",
      "map": "Map",
      "measurementsets": "Measurement Sets",
      "measurementjobs": "Measurement Jobs",
      "events": "Events",
      "devices": "Devices",
      "status": "Status",
    },
    "ListViewFab": {
      "loadMore": "Load more rows",
    },
    "MeasJobsCreateView": {
      "cancel": "Cancel",
      "newJob": "New job",
      "save": "Save",
      "endTreshold": "End time must be at least #1 minutes from start time",
      "manual": "Manual",
      "startTreshold": "Start time must be at least #1 minutest from now",
      "selectStartType": "Select start method",
      "timer": "Timer",
      "startMethod": "Start method",
      "startTime": "Starting time",
      "copy": "Copy",
      "endTime": "Ending time",
      "saveAndStart": "Save and start",
    },
    "MeasSetSelect": {
      "clear": "Clear",
    },
    "MeasurementSetConfiguration": {
      "nameOfSet": "Name of set",
      "month": "m",
      "latitude": "Latitude",
      "additionalInfo": "Additional Info",
      "timeSpan": "Time span",
      "day": "d",
      "backwards": "backwards",
      "group": "Group",
      "longitude": "Longitude",
    },
    "MeasurementSetsUtilityBar": {
      "group": "Group",
    },
    "MeasurementSetsWrapper": {
      "addNewSet": "Add a new measurement set",
      "cancel": "Cancel",
      "editMeasurementSet": "Edit measurement set",
      "chartConfig": "Chart configuration",
      "addNewChartConfig": "Add a new sensor view",
      "edit": "Edit",
      "continue": "Continue",
      "newMeasurementSet": "New measurement set",
      "save": "Save",
      "delete": "Delete",
      "newChartConfig": "New chart configuration",
    },
    "MeasJobsDataView": {
      "endAction": "Stop",
      "delete": "Delete",
      "editComment": "Write comment",
      "startAction": "Start",
      "info": "Details",
    },
    "UserMenu": {
      "settings": "Settings",
      "userManagement": "User Management",
      "logOut": "Log out",
      "userView": "User",
      "info": "Info",
    },
    "UserView": {
      "chooseLanguage": "Choose language",
      "doYouWantToLogOut": "Do you want to log out?",
      "enterFirstnameAndLastname": "Enter new first name and last name",
      "enterPhoneNumber": "Enter phone number",
      "enterOldPasswordAndNewPassword": "Enter old password and new password",
      "youAreAboutToLogOut": "You are about to log out. Proceed?",
      "invalidPhoneNumberFormat": "Phone number must be in valid international format (eg. +358501234567)",
      "invalidName": "Name must not be empty. Please enter a name.",
      "logOut": "Log out",
      "incorrectCredentials": "Incorrect old password. Please check and try again.",
      "changeLanguage": "Change language",
      "confirmNameChange": "Confirm and change name",
      "updatePhoneNumber": "Update phone number",
      "confirmPasswordChange": "Confirm and change password",
      "firstname": "First name",
      "lastname": "Last name",
      "phonenumber": "Phone number",
      "oldPassword": "Old password",
    },
    "MeasurementSetDisplayView": {
      "deletionError": "Can't delete a set that has measurement jobs",
      "sensorViews": "Sensor Views",
    },
    "Actions": {
      "useNow": "Now",
      "close": "Close",
      "send": "Send",
      "add": "Add",
      "remove": "Remove",
      "sort": "Sort",
      "create": "Create",
      "save": "Save",
      "cancel": "Cancel",
      "apply": "Apply",
      "confirm": "Confirm",
      "accept": "Accept",
      "search": "Etsi",
      "logOut": "Log out",
    },
    "DevicesWrapper": {
      "searchForDevice": "Search for a device",
    },

    "ChartConfigEditView": {
      "continueFromScaleHint": "Continue without changes",
      "sensorLimitValidationError": "Error while adding a new chart configuration: gauge, number display and stepped area chart can use only one sensor at time. Please remove all extra sensors",
      "configureSensors": "Configure sensors",
      "sensorLimitReachedError": "Error while adding a new chart configuration: gauge, number display and stepped area chart can use only one sensor at time. Please remove all extra sensors",
      "missingValuesError": "Error while adding a new chart configuration: please fill all required fields",
      "scaleValueError": "Error while adding a new chart configuration: invalid scale value",
      "selectDataVisualization": "Select data visualization",
      "returnFromScaleHint": "Return and edit sensor view",
      "scaleHintText": "This sensor view includes a gauge without scale values. A gauge without scale values will be displayed as a number display.",
      "nameOfChartConfig": "Name of chart configuration",
      "invalidScaleValueError": "Error while adding a new chart configuration: invalid scale value",
      "validationError": "Error while adding a new chart configuration: please fill all required fields",
    },
    "MeasJobsListView": {
      "add": "Add a new job",
      "all": "All",
      "jobName": "Job name",
      "comments": "Comments",
      "activity": "Activity",
      "notDefined": "Not set",
      "start": "Start time",
      "not_started": "Not started",
      "running": "Active",
      "measSet": "Measurement set",
      "ready": "Ready",
      "responsible": "Responsible",
      "end": "End time",
      "time": "Timespan",
      "status": "Status",
    },
    "MeasuremenSetHeaderDataRow": {
      "additionalInfo": "Additional info",
      "nameOfSet": "Name of set",
    },
    "MeasurementSetParentSelector": {
      "select": "Select",
      "group": "Group",
    },
    "Overview": {
      "all": "All",
      "allEvents": "All alarms",
      "allMeasurementSets": "All measurement sets",
      "activeJobs": "Active measurement jobs",
      "active": "Active",
      "activeEvents": "Active alarms",
      "allJobs": "All measurement jobs",
      "activeDevices": "Active devices",
      "allDevices": "All devices",
    },
    "MeasurementSetConfigSelection": {
      "additionalInfo": "Additional Info",
      "timeSpan": "Time span",
      "nameOfSet": "Name of set",
      "select": "Select",
    },
    "MeasurementSetEditView": {
      "addNewChartConfig": "Add a new chart configuration",
      "edit": "Edit",
      "locationNotFound": "Location could not be determined",
      "locationError": "Error occured while searching for location",
      "sensorViews": "Sensor Views",
      "delete": "Delete",
      "validationError": "Error while adding a new measurement set: required information is missing or set does not contain a sensor view",
    },
    "MeasJobs": {
      "measSet": "Measurement set",
      "addNew": "Add a new job",
    },
    "MeasurementSets": {
      "addNewSet": "Add a new measurement set",
      "endTimePicker": "End time",
      "paramsTitle": "Parameters",
      "spanTimePicker": "Time span",
      "groups": "Group",
    },
    "AlarmLimitsEdit": {
      "disabled": "Disabled",
      "min": "Lower limit",
      "max": "Upper limit",
    },
    "EventsView": {
      "acknowledge": "Acknowledge",
      "showAll": "Show all",
      "state": "Alarm state",
      "showActive": "Show active",
      "ackSignature": "Acknowledgement",
      "description": "Description",
    },
    "PreviewPlaceholderBox": {
      "placeholderInfoText": "After selecting sensors and data visualization a preview is shown here.",
    },
    "DeviceWindow": {
      "displayName": "Name of the device",
      "deviceGroup": "Group of the device",
      "deviceGroups": "Groups of the device",
      "openSensorDataPopup": "Open sensor data popup",
      "addAlarmTrigger": "Create an alarm trigger",
      "editAlarmTrigger": "Configure alarm triggers",
      "openActivityLabeling": "Open activity labeling",
      "label": "Label",
      "activityLabelingInfo": "Add an activity label",
      "customLabel": "Custom label",
      "activityLabelingError": "An error occured while adding an activity label.",
    },
    "DeviceWindowPlaceHolder": {
      "noDeviceSelected": "A device has not been selected",
    },
    "ThemeInput": {
      "theme": "Theme",
    },
    "MeasurementSetsDataTable": {
      "noSearchResults": "No search results",
    },
    "MeasSetGroupTree": {
      "groups": "Groups",
    },
    "MeasurementSetLocationSelector": {
      "gpsLocationOption": "Choose a location",
      "locationNotification": "The selected device is used only for determining the location",
      "select": "Select",
      "gpsFunctionOption": "Use device location",
      "loadingDevice": "Loading the device...",
      "selectedLocation": "Selected location",
    },
    "ErrorMessage": {
      "fieldIsRequired": "Required field",
      "noFutureTime": "Time can't be in the future",
    },
    "MeasSetGroupMenu": {
      "cancel": "Cancel",
      "saveSuccessMsg": "The group has been created",
      "select": "Select",
      "save": "Save",
      "groupName": "Name of the group",
      "confirmGroupDelete": "Do you want to delete group: ",
      "saveErrorMsg": "Error: group could not be saved",
      "deleteSuccessMsg": "The group has been deleted",
      "createNewGroup": "Create a new group",
      "deleteGroup": "Delete a group",
      "deleteErrorMsg": "Error: group could not be deleted",
      "ok": "OK",
      "return": "Return",
      "group": "Group",
    },
    "DevicesView": {
      "limitsEditTitle": "Sensor alarm limits",
      "metadata": "Information",
      "measSet": "Measurement set",
      "reading": "Reading",
      "sensor": "Sensor",
      "time": "Measurement time",
      "limits": "Alarm limits",
    },
    "LineChart": {
      "noDataFoundMsg": "No data was found for sensors:",
      "nodata": "Not enough data for visualization",
    },
    "DeviceSettings": {
      "general": "General",
      "attributes": "Attributes",
      "otaUpdate": "OTA Update",
      "installOta": "Install OTA",
      "cancelOta": "Cancel OTA",
      "noAttributesDefined": "No attributes defined",
      "HWID": "HWID",
      "canSee": "Visibility",
      "organization": "Organization",
      "serialNumber": "Serial number",
      "disableModemSleep": "Disable modem sleep",
      "updateInterval": "Update interval",
      "measurementInterval": "Measurement interval",
      "firmwareVersion": "Firmware version",
      "resetCount": "Reset count",
      "size": "Size (kB)",
      "version": "Version",
      "description": "Description",
      "otaId": "OTA id",
      "noUpdateAvailable": "No update packages available!",
      "noInstallationInProgress": "No installation in progress. Select a package to install!",
      "availableOtaPackages": "Available OTA packages",
      "otaUpdateProcess": "OTA update process",
      "deviceFirmwareInformation": "Device firmware information",
      "ledColor": "Led color",
      "toggleLed": "Toggle led",
      "whiteList": "Whitelist",
      "notRequestedList": "List of disabled measurements",
      "gnss": "GPS",
      "ncell": "Neighboring cell measurement",
      "mcell_loc": "LTE Positioning",
      "logs": "Show logs",
    },
    "Common": {
      "of": "of",
      "nextPage": "Next page",
      "previousPage": "Previous page",
      "notAvailable": "Not available",
      "rowsPerPage": "Rows per page",
      "email": "Email",
      "settings": "Settings",
      "error": "Error",
      "ok": "Ok",
      "username": "Username",
      "networkError": "There was an error with the network. Please check your network connection and try again.",
      "unableToPerformAction": "Unable to perform action, please try again. If this problem persists, contact your admin.",
      "errorOccurred": "An error occurred: ",
      "tooManyAttempts": "You have tried too many times within a short period of time. Please try again later.",
      "incorrectCredentials": "Incorrect username/password combination. Please check and try again.",
      "passwordMustHaveNumbers": "Password does not conform to policy. Password must have numeric characters.",
      "passwordMustHaveLowercaseCharacters": "Password does not conform to policy. Password must have lowercase characters.",
      "passwordMustBeLongEnough": "Password does not conform to policy. Password that you provided is not long enough.",
      "userSessionExpired": "Invalid session for the user, session is expired. Please refresh the browser and try again.",
      "passwordsNotMatching": "Passwords do not match.",
      "newPassword": "New password",
      "confirmNewPassword": "Confirm new password",
      "latestReading": "Latest reading",
      "location": "Location",
      "locationNotSpecified": "Location is not specified",
      "sensors": "Sensors",
      "generalInfo": "General Info",
      "type": "Type",
      "openDeviceSettings": "Open device settings",
      "measurements": "Measurements",
      "device": "Device",
      "devices": "Devices",
      "measurementSet": "Measurement Set",
      "measurementSets": "Measurement Sets",
      "measurementJob": "Measurement Job",
      "measurementJobs": "Measurement Jobs",
      "events": "Events",
      "event": "Event",
      "status": "Status",
    },
  },
};
