import { Nullable } from "types/aliases";
import BaseObservable from "data/observer/BaseObservable";
import { MeasurementSetConfig } from "data/types/measurementSetTypes";

export interface MeasurementSetSelectorObserver {
  onSelectedMeasurementSetChanged: (measurementSet: Nullable<MeasurementSetConfig>) => void;
}

export class MeasurementSetSelector extends BaseObservable<MeasurementSetSelectorObserver> {
  private static instance: MeasurementSetSelector = new MeasurementSetSelector();
  private selectedMeasSet: Nullable<MeasurementSetConfig> = null;

  public static getInstance(): MeasurementSetSelector {
    return this.instance;
  }

  public addObserver(observer: MeasurementSetSelectorObserver): void {
    super.addObserver(observer);
    observer.onSelectedMeasurementSetChanged?.(this.selectedMeasSet);
  }

  public getSelectedMeasurementSet(): Nullable<MeasurementSetConfig> {
    return this.selectedMeasSet;
  }

  public removeObserver(observer: MeasurementSetSelectorObserver): void {
    super.removeObserver(observer);
  }

  public setSelectedMeasurementSet(measSet: Nullable<MeasurementSetConfig>): void {
    this.selectedMeasSet = measSet;
    this.notifyAction(observer => observer.onSelectedMeasurementSetChanged?.(this.selectedMeasSet));
  }
}

export default MeasurementSetSelector;
