import React, { Component, FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from "react";
import { Grid, Tab, Tabs } from "@material-ui/core";
import AuthWrapper from "data/auth/AuthWrapper";
import DeviceGroup from "data/device/DeviceGroup";
import BackendFactory from "data/BackendFactory";
import Organization from "data/organization/Organization";
import DataRepository from "data/data-storage/DataRepository";
import DeviceRepository from "data/data-storage/DeviceRepository";
import Localization from "data/localization-sensoan/Localization";
import ViewAccessMethods from "ViewAccessMethods";
import accessControlled from "../access-control/access-controlled";
import InlineBox from "components/ui/inline-box";
import TabPanel from "components/ui/tab-panel";
import OrganizationSelector from "./components/organization-selector";
// import Permissions from "./components/permissions";
import DeviceManagement from "./components/device-management";
import OrganizationManagement from "./components/organization-management";
import UserList from "./components/user-list";
import WrapperContainer from "components/layout/WrapperContainer";
// import NotificationManagement from "./components/notification-management";

interface Props {}

interface State {
  rootDeviceGroups: DeviceGroup[];
  selectedTab: number;
  homeOrganization?: Organization;
  selectedOrganization?: Organization;
  currentUserId?: string;
  searchFilter?: string;
  errorMsg?: string;
}

type TabPageProps = { currentPage: number; index: number };

const TabPage: FunctionComponent<TabPageProps> = (props: PropsWithChildren<TabPageProps>): ReactElement => {
  return (
    <TabPanel
      value={props.currentPage}
      index={props.index}
    >
      <InlineBox>
        {props.children}
      </InlineBox>
    </TabPanel>
  );
};

export class AdminView extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public static DataRequirements: DataRepository[] = [
    DeviceRepository.getInstance(),
  ];

  private readonly organizationBackend = BackendFactory.getOrganizationBackend();

  public constructor(props: Props) {
    super(props);
    this.state = {
      rootDeviceGroups: [],
      selectedTab: 0,
    };
  }

  public async componentDidMount(): Promise<void> {
    const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();

    if (!claims) {
      throw new Error("Failed to resolve current user!");
    }

    const [homeOrganization, rootDeviceGroups] = await Promise.all([
      this.organizationBackend.getCurrentHomeOrganization(),
      BackendFactory.getBackend().getRootDeviceGroups(), // this is probably not needed since groups are available with DeviceRepository
    ]);

    this.setState({
      rootDeviceGroups,
      homeOrganization,
      currentUserId: claims.userId,
      selectedOrganization: homeOrganization,
    });
  }

  private handleOrganizationSelected = (organization: Organization): void => {
    this.setState({
      selectedOrganization: organization,
    });
  };

  private handleGroupsUpdate = async (): Promise<void> => {
    this.setState({ rootDeviceGroups: await BackendFactory.getBackend().getRootDeviceGroups() });
  };

  private handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    this.setState({ selectedTab: value });
  };

  private renderOrganizationStructure(): ReactNode {
    if (this.state.selectedOrganization) {
      return (
        <OrganizationManagement
          organization={this.state.selectedOrganization}
        />
      );
    }
  }

  // private renderOrganizationPolicies(): ReactNode {
  //   if (this.state.selectedOrganization) {
  //     return (
  //       <Permissions
  //         organization={this.state.selectedOrganization}
  //       />
  //     );
  //   }
  // }

  private renderUsersList(): ReactNode {
    if (this.state.selectedOrganization && this.state.currentUserId) {
      return (
        <UserList
          organization={this.state.selectedOrganization}
          currentUserId={this.state.currentUserId}
        />
      );
    }
  }

  private renderDeviceList(): ReactNode {
    return (
      <DeviceManagement
        groups={this.state.rootDeviceGroups}
        onGroupsUpdate={this.handleGroupsUpdate}
      />
    );
  }

  private renderOrganizationSelector(): ReactNode {
    if (this.state.homeOrganization) {
      return (
        <OrganizationSelector
          rootOrganization={this.state.homeOrganization}
          organizationSelected={this.handleOrganizationSelected}
        />
      );
    }
  }

  // private renderNotificationManager(): ReactNode {
  //   if (this.state.selectedOrganization && this.state.currentUserId) {
  //     return (
  //       <NotificationManagement
  //         organization={this.state.selectedOrganization}
  //         currentUserId={this.state.currentUserId}
  //       />
  //     );
  //   }
  // }

  public render(): ReactNode {
    return (
      <WrapperContainer>
        <Grid container={true} spacing={1} justifyContent={"center"}>
          <Grid item={true} md={12} lg={10} xl={8}>
            {this.renderOrganizationSelector()}
            <Tabs
              value={this.state.selectedTab}
              onChange={this.handlePageChange}
            >
              <Tab label={this.text("AdminView", "users")} />
              <Tab label={this.text("Common", "devices")} />
              <Tab label={this.text("AdminView", "organisation")} />
              {/* <Tab label={this.text("AdminView", "policies")} /> */}
              {/* <Tab label={this.text("AdminView", "notifications")} /> */}
            </Tabs>
            <TabPage currentPage={this.state.selectedTab} index={0}>
              {this.renderUsersList()}
            </TabPage>
            <TabPage currentPage={this.state.selectedTab} index={1}>
              {this.renderDeviceList()}
            </TabPage>
            <TabPage currentPage={this.state.selectedTab} index={2}>
              {this.renderOrganizationStructure()}
            </TabPage>
            {/* <TabPage currentPage={this.state.selectedTab} index={3}>
              {this.renderOrganizationPolicies()}
            </TabPage> */}
            {/* <TabPage currentPage={this.state.selectedTab} index={4}>
              {this.renderNotificationManager()}
            </TabPage> */}
          </Grid>
        </Grid>
      </WrapperContainer>
    );
  }
}

export default accessControlled(AdminView, ViewAccessMethods.hasAdminAccess);
