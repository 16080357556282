import React, { ChangeEvent, Component, Fragment, ReactNode } from "react";
import { Box, Divider, InputAdornment, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Nullable } from "types/aliases";
import Localization from "data/localization-sensoan/Localization";
import { MeasurementSetConfig, MeasurementSetTreeItem } from "data/types/measurementSetTypes";
import MeasurementDataTools from "data/measurement-data/MeasurementDataTools";
import Section from "components/layout/Section";
import IoTDataRow from "components/layout/iot-data-row/IoTDataRow";
import SFilledInput from "components/styled-components/SFilledInput";
import MeasSetGroupMenu2 from "components/inputs/meas-set-group-menu/MeasSetGroupMenu";
import SetsListDataRow from "./SetsListDataRow";
import SetsListHeaderRow from "./SetsListHeaderRow";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import MapLink, { MapMarkerData } from "data/map/MapLink";

interface Props {
  clearSelectedGroup: () => void;
  dataItems: MeasurementSetConfig[];
  filterText: string;
  onSelectMeasGroup: (event: React.MouseEvent<Element, MouseEvent>, measurementSetGroup: MeasurementSetTreeItem) => void;
  onTableSearchValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedMeasGroupId: string;
  tableSearchValue: string;
}

interface State {
  deviceSelectorAnchorEl: Nullable<HTMLButtonElement>;
  filteredSetList: MeasurementSetConfig[];
  mapMarkerData: Nullable<MapMarkerData[]>; // handling color changing in location buttons is faster if we keep a copy of marker data in state
  rowCount: number;
}

class SetsListView extends Component<Props, State> {

  public constructor (props: Props) {
    super(props);
    this.state = {
      deviceSelectorAnchorEl: null,
      filteredSetList: [],
      mapMarkerData: null,
      rowCount: 30,
    };
    this.toggleAllMapMarkers = this.toggleAllMapMarkers.bind(this);
  }

  public componentDidMount(): void {
    const filteredSetList = this.filterSetList(this.props.dataItems, this.props.filterText, this.props.selectedMeasGroupId);
    this.setState({ filteredSetList });
  }

  //TODO: move all filtering functionality to state
  //TODO: add sorting
  public componentDidUpdate(prevProps: Props, prevState: State): void {

    if (this.props.filterText !== prevProps.filterText
      || this.props.selectedMeasGroupId !== prevProps.selectedMeasGroupId
      || this.props.dataItems.length !== prevProps.dataItems.length) {
      const filteredSetList = this.filterSetList(this.props.dataItems, this.props.filterText, this.props.selectedMeasGroupId);
      this.setState({ filteredSetList });
    }

    if (this.didFilteredSetListChange(prevState.filteredSetList, this.state.filteredSetList)) {
      const filteredSetMarkersOnMap = MapLink.getDataStorage().getMarkerData().filter(mData => {
        return this.state.filteredSetList.some(set => {
          return set.setId === mData.id;
        });
      });
      this.setState({ mapMarkerData: filteredSetMarkersOnMap.length > 0 ? filteredSetMarkersOnMap : null });
      MapLink.getLinkToMap().replaceData(filteredSetMarkersOnMap);
    }
  }

  public componentWillUnmount(): void {
    if (MapLink.getDataStorage().getMarkerData().length > 0) {
      MapLink.getLinkToMap().removeAllLocations();
    }
  }

  private renderDataRows(): JSX.Element {
    if (this.state.filteredSetList.length > 0) {
      return (
        <Fragment>
          {this.state.filteredSetList.map((measurementSet: MeasurementSetConfig) =>
            <SetsListDataRow
              mapMarkerData={this.state.mapMarkerData}
              dataItem={measurementSet}
              key={measurementSet.setId}
              toggleMarker={(marker: Nullable<MapMarkerData>): void => this.toggleMapMarker(marker)}
            />,
          )}
        </Fragment>
      );
    } else {
      return (
        <IoTDataRow>
          <Box width="100%">
            <Typography variant="body1">
              {Localization.getInstance().getDisplayText("MeasurementSetsDataTable", "noSearchResults")}
            </Typography>
          </Box>
        </IoTDataRow>
      );
    }
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <Section minWidth="900px">
          <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" height="3.5rem">
            <MeasSetGroupMenu2
              clearSelectedGroup={this.props.clearSelectedGroup}
              onSelectTreeItem={this.props.onSelectMeasGroup}
              selectedGroupId={this.props.selectedMeasGroupId}
              disableModifications
            />
            <SFilledInput
              endAdornment={
                <InputAdornment position="end">
                  <SSvgIcon color={SSvgIconColorProps.textPrimary} iconComponent={SearchIcon} size="1.5rem" />
                </InputAdornment>
              }
              id="measurement-sets-search"
              inputLabelText={Localization.getInstance().getDisplayText("Actions", "search")}
              onChange={this.props.onTableSearchValueChange}
              value={this.props.tableSearchValue}
              width="12.5rem"
            />
          </Box>
          <Box mt={2}>
            <Divider />
          </Box>
        </Section>
        <Section minWidth="900px">
          <SetsListHeaderRow
            toggleAllMapMarkers={this.toggleAllMapMarkers}
          />
          {this.renderDataRows()}
        </Section>
        {/* {this.state.rowCount <= this.state.filteredSetList.length // uncomment when filtering functions in same way as in other list views
        &&
        <ListViewFab
          onClick={(): void => this.setState({ rowCount: this.state.rowCount + 30 })}
        />} */}
      </Fragment>
    );
  }

  private toggleAllMapMarkers(): void {
    let mapMarkerData: MapMarkerData[];

    if (this.isMapMarkerDataFiltered()) {
      mapMarkerData = this.getMarkerDataForAllFilteredSets();

      if (MapLink.getLinkToMap().areLocationsOnMap(mapMarkerData.map(({ id }) => id))) {
        this.setState({ mapMarkerData: null });
        MapLink.getLinkToMap().removeAllLocations();
      } else {
        this.setState({ mapMarkerData });
        MapLink.getLinkToMap().replaceData(mapMarkerData);
      }
    } else {
      mapMarkerData = MeasurementDataTools.getMapMarkerDataForAllMeasSets();

      if (MapLink.getLinkToMap().areLocationsOnMap(mapMarkerData.map(({ id }) => id))) {
        this.setState({ mapMarkerData: null });
        MapLink.getLinkToMap().removeAllLocations();
      } else {
        this.setState({ mapMarkerData });
        MapLink.getLinkToMap().replaceData(mapMarkerData);
      }
    }
  }

  private toggleMapMarker(marker: Nullable<MapMarkerData>): void {

    if (marker) {
      const { mapMarkerData } = this.state;

      // handle updating state
      if (mapMarkerData && mapMarkerData.findIndex(mData => mData.id === marker.id) > -1) {
        this.setState({ mapMarkerData: mapMarkerData.filter(mData => mData.id !== marker.id) });
      } else if (mapMarkerData) {
        this.setState({ mapMarkerData: mapMarkerData.concat(marker) });
      } else {
        this.setState({ mapMarkerData: [marker] });
      }
      // handle updating markerData in MapLink
      MapLink.getLinkToMap().toggle(marker);
    } else {
      console.error("error in SetsListView.toggleMapMarker() / parameter 'marker' was null");
    }
  }

  private filterSetList(setList: MeasurementSetConfig[], searchText: string, groupId: string): MeasurementSetConfig[] {
    let filteredSetList: MeasurementSetConfig[] = setList;

    if (groupId !== "") {
      filteredSetList = filteredSetList.filter(set => set.parentIds[0] === groupId);
    }

    if (searchText !== "") {
      filteredSetList = filteredSetList.filter(set => set.displayName.toLowerCase().includes(searchText.toLowerCase()));
    }
    return filteredSetList;
  }

  // Helper methods for map marker data and location button handling
  private didFilteredSetListChange(previousList: MeasurementSetConfig[], currentList: MeasurementSetConfig[]): boolean {
    if (previousList.length >= currentList.length) {
      return !previousList.every(prevListItem => {
        return currentList.some(currListItem => {
          return prevListItem.setId === currListItem.setId;
        });
      });
    } else if (previousList.length < currentList.length){
      return !currentList.every(currListItem => {
        return previousList.some(prevListItem => {
          return currListItem.setId === prevListItem.setId;
        });
      });
    } else {
      return false;
    }
  }

  private isMapMarkerDataFiltered(): boolean {
    return !MeasurementDataTools.getMapMarkerDataForAllMeasSets().every(mDataItemA => {
      return this.getMarkerDataForAllFilteredSets().some(mDataItemB => {
        return mDataItemA.id === mDataItemB.id;
      });
    });
  }

  private getMarkerDataForAllFilteredSets(): MapMarkerData[] {
    return MeasurementDataTools.getMapMarkerDataForAllMeasSets(this.state.filteredSetList);
  }

}

export default SetsListView;
