import React, { Component, ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import LocationOffIcon from "@material-ui/icons/LocationOff";
import { Nullable } from "types/aliases";
import { ChartConfig, DataConfig, MeasurementSetConfig } from "data/types/measurementSetTypes";
import MeasurementDataTools from "data/measurement-data/MeasurementDataTools";
import MeasurementJobSelector from "data/measurement-job-selector/MeasurementJobSelector";
import MeasurementSetSelector from "data/measurement-set-selector/MeasurementSetSelector";
import Localization from "data/localization-sensoan/Localization";
import IoTDataRow from "components/layout/iot-data-row/IoTDataRow";
import { IconStatus, MapMarkerData } from "data/map/MapLink";
import SButton from "components/styled-components/SButton";
import SText from "components/styled-components/SText";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import DataRowLocationIndicator from "components/layout/iot-data-row/DataRowLocationIndicator";
import withDataJanitor from "components/hocs/DataJanitor";
import { DataRepositories } from "data/data-storage/DataRepositoryFactory";
import PathsSensoan from "data/paths/PathsSensoan";

const DataRowLocationIndicatorWithDeviceRepo = withDataJanitor(DataRowLocationIndicator, [
  DataRepositories.Device,
]);

interface Props {
  dataItem: MeasurementSetConfig;
  mapMarkerData: Nullable<MapMarkerData[]>;
  toggleMarker: (marker: Nullable<MapMarkerData>) => void;
}

interface State {
  locationLoading: boolean;
}

class SetsListDataRow extends Component<Props, State> {

  public constructor (props: Props) {
    super(props);
    this.state = {
      locationLoading: false,
    };
  }

  public render(): ReactNode {
    const { dataItem } = this.props;
    return (
      <IoTDataRow dividerFadeEffect>
        {this.renderLocationIndicator(dataItem)}
        <Box width="20%">
          <Link
            color="textPrimary"
            component="button"
            onClick={(): void => MeasurementSetSelector.getInstance().setSelectedMeasurementSet(dataItem)}
            style={{ cursor: "pointer", width: "100%", textAlign: "start" }} // width and textAlign needed to SText to function properly here
          >
            <SText text={dataItem.displayName} color="textPrimary"/>
          </Link>
        </Box>
        <Box width="20%">
          <SButton
            color="secondary"
            buttonComponent={RouterLink}
            endIcon={ArrowRightAltIcon}
            fontWeight="regular"
            iconColor={SSvgIconColorProps.textPrimary}
            labelText={Localization.getInstance().getDisplayText("Common", "measurementJobs")}
            linkTo={PathsSensoan.MEASUREMENTJOBS}
            onClick={(): void => this.handleSelectMeasJobsForMeasSet(dataItem)}
            widthInRems={10}
          />
        </Box>
        <Box width="25%">
          <SText text={this.getSensorNameList(dataItem)} color="textSecondary"/>
        </Box>
        <Box width="30%">
          <SText text={dataItem.metadata ?? ""} color="textSecondary"/>
        </Box>
      </IoTDataRow>
    );
  }

  private renderLocationIndicator(set: MeasurementSetConfig): ReactNode {
    const { mapMarkerData, toggleMarker } = this.props;
    const color = SSvgIconColorProps.blueGradient;
    const { config } = set;
    const markerItemGetter = (): Nullable<MapMarkerData> => this.getMapMarkerDataItem(set);

    if (config.gpsFunction?.deviceId !== undefined) {
      return (
        <DataRowLocationIndicatorWithDeviceRepo
          deviceId={config.gpsFunction?.deviceId}
          buttonActiveColor={color}
          mapMarkerData={mapMarkerData}
          getMapMarkerDataItem={markerItemGetter}
          toggleMarker={toggleMarker}
          inlineLoaderWithoutWaitingText
          loaderProps={{
            size: 1.75,
            leftRightPadding: "12px",
          }}
        />
      );
    } else if (config.gpsLocation?.latitude !== undefined && config.gpsLocation?.longitude !== undefined) {
      return (
        <DataRowLocationIndicator
          buttonActiveColor={color}
          mapMarkerData={mapMarkerData}
          getMapMarkerDataItem={markerItemGetter}
          toggleMarker={toggleMarker}
        />
      );
    } else {
      return (
        <Box p={3}>
          <SSvgIcon
            color={SSvgIconColorProps.primary}
            iconComponent={LocationOffIcon}
          />
        </Box>
      );
    }
  }

  private getMapMarkerDataItem(set: MeasurementSetConfig): Nullable<MapMarkerData> {
    return MeasurementDataTools.getMapMarkerDataForMeasSet(set, () => IconStatus.selected);
  }

  private getSensorNameList(set: MeasurementSetConfig): string {
    const sensorNameList: string[] = [];
    set.config.chartConfig.forEach((c: ChartConfig) => {
      return c.dataConfig.forEach((d: DataConfig) => {
        return !sensorNameList.includes(d.sensorDisplayName) && sensorNameList.push(d.sensorDisplayName);
      });
    });
    return sensorNameList.join(", ");
  }

  private handleSelectMeasJobsForMeasSet(set: MeasurementSetConfig): void {
    MeasurementSetSelector.getInstance().setSelectedMeasurementSet(set);
    const measurementJob = MeasurementJobSelector.getInstance().getSelectedMeasurementJob();

    // this prevents unexpected behaviour where user selects a job in JobsListView and changes view to SetsListView and then returns to
    // JobsListView by selecting jobs for a certain set (a job should not be selected then)
    if (measurementJob !== null) {
      MeasurementJobSelector.getInstance().setSelectedMeasurementJob(null);
    }
  }
}

export default SetsListDataRow;
