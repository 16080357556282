import React, { Component, ReactNode } from "react";
import { Box, Divider, Link, Typography } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";

interface Props {
  minWidth?: string;
  mountOpen?: boolean;
  title?: string;
  height?: string;
  titleTextStyle?: "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "subtitle1" | "subtitle2" | "body1" | "body2" | "overline" | "srOnly";
  variant?: "foldable";
}

interface State {
  open: boolean;
}

export const SECTION_CONTENT_TOP_PADDING = "8px";
export const SECTION_CONTENT_BOTTOM_PADDING = "8px";
export const SECTION_TITLE_TOP_PADDING = "8px";
export const SECTION_TITLE_BOTTOM_PADDING = "8px";

class Section extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      open: props.mountOpen ?? true,
    };
  }

  private renderToggler(): ReactNode {
    return (
      this.props.variant === "foldable"
        &&
        /* TODO: use button instead of Link */
      <Link style={{ margin: "auto", marginRight: 0 }} onClick={(): void => this.setState({ open: !this.state.open })}>
        <Box pr={2} color="text.primary">
          <SSvgIcon color={SSvgIconColorProps.textPrimary} iconComponent={this.state.open ? ExpandLessIcon : ExpandMoreIcon }/>
        </Box>
      </Link>
    );
  }

  private renderTitle(): ReactNode {
    return (
      this.props.title
        &&
      <Box
        pt={SECTION_TITLE_TOP_PADDING}
        pb={SECTION_TITLE_BOTTOM_PADDING}
        display="flex"
        flexWrap="wrap"
      >
        <Typography variant={this.props.titleTextStyle ?? "h6"} color="textPrimary">
          {this.props.title}
        </Typography>
        {this.renderToggler()}
        <Divider/>
      </Box>
    );
  }

  private renderContent(): ReactNode {
    return (
      this.state.open
      &&
        <Box
          pt={SECTION_CONTENT_TOP_PADDING}
          pb={SECTION_CONTENT_BOTTOM_PADDING}
          height={this.props.height}
        >
          {this.props.children}
        </Box>
    );
  }

  public render(): JSX.Element {
    return (
      <Box
        minWidth={this.props.minWidth}
        height={this.props.height}
      >
        {this.renderTitle()}
        {this.renderContent()}
      </Box>
    );
  }
}

export default Section;
