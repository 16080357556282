import { IEventMetadata } from "data/clientSpecific/Event";

export interface EventInfo {
  params: {
    eventId: string;
    sensorName: string;
    severity: string;
    value?: string; // backend adds the value after executing the rules engine
  };
  type: string;
}

export interface RuleProperties {
  conditions: TopLevelCondition;
  event: Event;
  name?: string;
  priority?: number;
  onSuccess?: EventHandler;
  onFailure?: EventHandler;
}

export interface ConditionProperties {
  fact: string;
  operator: string;
  value: { fact: string } | unknown;
  path?: string;
  priority?: number;
  params?: Record<string, unknown>;
}

export interface Event {
  type: string;
  params?: Record<string, unknown>;
}

export interface RuleResult {
  name: string;
  conditions: TopLevelCondition;
  event?: Event;
  priority?: number;
  result: unknown;
}

export type EventHandler = (
  event: Event,
  almanac: unknown,
  ruleResult: RuleResult
) => void;

// Copy from json-rules-engine
// type NestedCondition = ConditionProperties | TopLevelCondition;
// type AllConditions = { all: NestedCondition[] };
// type AnyConditions = { any: NestedCondition[] };
// export type TopLevelCondition = AllConditions | AnyConditions;
//
// We use simpler type
export type AnyConditions = { any: ConditionProperties[] };
export type TopLevelCondition = AnyConditions;


export interface EventTriggerDbEntry {
  triggerId: string;
  deviceId?: string;
  groupId?: string;
  rules: RuleProperties[];
}

export interface EventConfig {
  trigger: EventTriggerDbEntry;
  metadata: IEventMetadata;
}

export enum TriggerOperator {
  MIN = "lessThan",
  MAX = "greaterThan",
}
