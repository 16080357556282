import { Box, Grid, Theme, Typography, withTheme } from "@material-ui/core";
import LatestDeviceDataRepository, { LatestDeviceDataRepositoryListener } from "data/data-storage/LatestDeviceDataRepository";
import { ChartSize, SensorColorType } from "data/types/measurementSetTypes";
import Data from "data/data/Data";
import React from "react";
import { Maybe, Nullable } from "types/aliases";
import { v4 as uuid } from "uuid";
import ChartTitle from "./ChartTitle";

interface Props {
  decimals?: number;
  deleteChart?: (index: Maybe<number>) => void;
  editChart?: (index: Maybe<number>) => void;
  chartIndex?: number;
  color: SensorColorType;
  gradient?: boolean;
  onClick?: () => void;
  scale: { min: number; max: number };
  size?: ChartSize;
  theme: Theme;
  title: string;
  value: Nullable<number>;
  latestDataOf?: { deviceId: string; sensor: string };
  // normal = MeasurementSetView
  // verySmall = 4x4 grid in MeasurementSetView
  // small = StatusView
}

interface State {
  gradId: string;
}

class GaugeChart2 extends React.Component<Props, State> implements LatestDeviceDataRepositoryListener {

  public constructor(props: Props) {
    super(props);
    this.state = {
      gradId: uuid(),
    };
  }

  public onDataUpdated(_data: Data): void {
    this.forceUpdate();
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.latestDataOf && !this.props.latestDataOf) {
      LatestDeviceDataRepository.getInstance().removeListener(this, prevProps.latestDataOf.deviceId);
    } else if (!prevProps.latestDataOf && this.props.latestDataOf) {
      LatestDeviceDataRepository.getInstance().addListener(this, this.props.latestDataOf.deviceId);
    }
  }

  public componentDidMount(): void {
    if (this.props.latestDataOf) {
      LatestDeviceDataRepository.getInstance().addListener(this, this.props.latestDataOf.deviceId);
    }
  }

  public componentWillUnmount(): void {
    if (this.props.latestDataOf) {
      LatestDeviceDataRepository.getInstance().removeListener(this, this.props.latestDataOf.deviceId);
    }
  }

  public render(): JSX.Element {
    let boxHeight = "400px";
    let svgWidth = 420;
    let svgHeigth = 180;
    let sm: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 12;
    let md: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 8;
    let lg: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 6;
    let xl: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 4;

    if (this.props.size === ChartSize.PREVIEW_WIDTH) {
      sm = 12;
      md = 10;
      lg = 10;
      xl = 10;
    } else if (this.props.size === ChartSize.SMALL) {
      boxHeight = "320px";
      sm = 12;
      md = 4;
      lg = 3;
      xl = 3;
      svgWidth = 280;
      svgHeigth = 120;
    } else if (this.props.size === ChartSize.VERY_SMALL) {
      boxHeight = "196px";
      svgWidth = 224;
      svgHeigth = 96;
    }

    const shadow = "0px 4px 4px rgba(0, 0, 0, 0.35)";
    const min = this.props.scale.min.toFixed(1);
    const quarter = (((this.props.scale.max - this.props.scale.min) / 4) + this.props.scale.min).toFixed(1);
    const half = (((this.props.scale.max - this.props.scale.min) / 2) + this.props.scale.min).toFixed(1);
    const threeQuarters = ((3 * (this.props.scale.max - this.props.scale.min) / 4) + this.props.scale.min).toFixed(1);
    const max = this.props.scale.max.toFixed(1);
    const needleTransform = `translate(140,120) rotate(${this.getNeedleRotation()})`;
    const color = this.getNeedleColor();
    const needleFill = this.props.gradient ? `url(#${this.state.gradId})` : color;

    return (
      <Grid item sm={sm} md={md} lg={lg} xl={xl} onClick={this.props.onClick}>
        <Box position="relative" display="flex" flexWrap="wrap" height={boxHeight} justifyContent="center" alignContent="flex-start" style={{
          boxShadow: shadow,
        }} >
          <ChartTitle
            chartIndex={this.props.chartIndex}
            title={this.props.title}
            editChart={this.props.editChart}
            deleteChart={this.props.deleteChart}
          />
          <Box mt={4}>
            <svg height={svgHeigth} width={svgWidth} viewBox="0 0 280 120" stroke="#787878" fill="#787878" strokeWidth="0.2">
              <defs>
                <linearGradient id={this.state.gradId} x1="0" x2="0" y1="0" y2="1">
                  <stop offset="0%" style={{ stopColor: "#979FAA", stopOpacity: 1 }} />
                  <stop offset="100%" style={{ stopColor: color, stopOpacity: 1 }} />
                </linearGradient>
              </defs>
              <path d="M 60 120 A 80 80 0 0 1 220 120 H 60" stroke="#303030" strokeWidth="2" fill="none" />
              <path d="M 0 0 H 3 L 0 86 L -3 0" stroke="#303030" strokeWidth="0" fill={needleFill} transform={needleTransform} />
              <text textAnchor="end" x="50" y="119" fontSize="smaller">{min}</text>
              <text textAnchor="end" x="76.36" y="56.36" fontSize="smaller">{quarter}</text>
              <text textAnchor="middle" x="140" y="29" fontSize="smaller">{half}</text>
              <text x="203.64" y="56.36" fontSize="smaller">{threeQuarters}</text>
              <text x="230" y="119" fontSize="smaller">{max}</text>
            </svg>
          </Box>
          <Box display="flex" mt={0} mb={"auto"} justifyContent="center" width="100%">
            <Typography variant="h6" >
              {this.props.latestDataOf ? (LatestDeviceDataRepository.getInstance().getData(this.props.latestDataOf.deviceId)?.[this.props.latestDataOf.sensor] as number).toFixed(this.props.decimals ?? 1) : (this.props.value?.toFixed(this.props.decimals ?? 1))}
            </Typography>
          </Box>
        </Box>
      </Grid>
    );
  }

  private getNeedleColor(): string {
    const value = this.props.latestDataOf ? (LatestDeviceDataRepository.getInstance().getData(this.props.latestDataOf.deviceId)?.[this.props.latestDataOf.sensor] as number) : this.props.value;

    if (value === null) {
      return this.props.theme.palette.background.paper;
    } else if (value < this.props.scale.min || value > this.props.scale.max) {
      return "#979FAA";
    } else {
      return this.props.color;
    }
  }

  private getNeedleRotation(): number {
    const value = this.props.latestDataOf ? (LatestDeviceDataRepository.getInstance().getData(this.props.latestDataOf.deviceId)?.[this.props.latestDataOf.sensor] as number) : this.props.value;

    if (value === null) {
      return 90;
    } else if (value < this.props.scale.min) {
      return 90;
    } else if (value > this.props.scale.max) {
      return 270;
    } else {
      return 90 + 180 * (value - this.props.scale.min) / (this.props.scale.max - this.props.scale.min);
    }
  }
}

export default withTheme(GaugeChart2);


