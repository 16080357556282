import BaseObservable from "data/observer/BaseObservable";
import { LocalizationDictionary } from "data/types/localizationTypes";
import { LocaleInclCountry, Locales, localizationTexts } from "./Locales";
import { Voidable } from "types/aliases";

export interface LocalizationObserver {
  onSelectedLocaleChanged?(locale: Locales): void;
}

function getInitialLocale(): Locales {
  const browserLocale =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (browserLocale[0].startsWith("fi")) {
    return Locales.FI;
  } else {
    return Locales.EN;
  }
}

export default class Localization extends BaseObservable<LocalizationObserver> {
  private static instance: Localization = new Localization();
  private currentLocale: Locales = getInitialLocale();
  private data: LocalizationDictionary = Object.assign({}, localizationTexts[getInitialLocale()]);

  public static getInstance(): Localization {
    return this.instance;
  }

  public addObserver(observer: LocalizationObserver): void {
    super.addObserver(observer);
  }

  public removeObserver(observer: LocalizationObserver): void {
    super.removeObserver(observer);
  }

  public setCurrentLocale(locale: Locales): void {
    this.currentLocale = locale;
    this.data = Object.assign({}, localizationTexts[locale]);
    this.notifyAction(observer => observer.onSelectedLocaleChanged?.(locale));
  }

  public getCurrentLocale(): Locales {
    return this.currentLocale;
  }

  public getDateAndTimeString(ts: Voidable<number>): string {
    if (ts !== undefined && ts !== null) {
      const dT = new Date(ts);

      // TODO: remove quick fix for ts error
      type DateTimeFormatOptions = {
        year: "numeric"; month: "numeric"; day: "numeric";
      };

      const dateOpts: DateTimeFormatOptions = { year: "numeric", month: "numeric", day: "numeric" };
      return `${dT.toLocaleDateString(this.getCurrentLocaleInclCountry(), dateOpts)} - ${dT.toLocaleTimeString(this.getCurrentLocaleInclCountry())}`;
    } else {
      return this.getDisplayText("MeasJobsListView", "notDefined");
    }
  }

  public getCurrentLocaleInclCountry(): LocaleInclCountry {
    return LocaleInclCountry[this.currentLocale];
  }

  // TODO: add console.error for easier discovery of missing texts in Locales.ts
  public getDisplayText(componentName: string, id: string): string {
    const notFoundDisplayText = "...";
    const locale = Localization.instance.currentLocale;

    if (Localization.instance.data) {
      if (Localization.instance.data[componentName]) {
        if (Localization.instance.data[componentName][id]) {
          return Localization.instance.data[componentName][id];
        } else if (id === ""){
          console.error(`Failed to find localised display text for locale '${locale}' and componentName '${componentName}'. Provided id was an empty string`);
          return notFoundDisplayText;
        } else {
          console.error(`Failed to find localised display text for locale '${locale}', componentName '${componentName}' and id '${id}'`);
          return notFoundDisplayText;
        }
      } else {
        console.error(`Failed to find localised display text for locale '${locale}' and componentName ${componentName}`);
      }
      return notFoundDisplayText;
    } else {
      console.error("Failed to find localization data");
      return notFoundDisplayText;
    }
  }

  // Use this if localization texts are fetched from backend
  // public async fetchAppData(needsUpdate?: React.Component): Promise<void> {
  //   const data: LocalizationDictionary = await SensoanBackend.getInstance().getLocalizationData(this.currentLocale.toLowerCase());

  //   if (this.data !== null && this.data["Login"] !== undefined) {
  //     data["Login"] = this.data["Login"];
  //     this.data = data;
  //   } else {
  //     this.data = data;
  //   }

  //   if (needsUpdate) {
  //     needsUpdate.forceUpdate();
  //   }
  // }
}
