import React, { Component, Fragment, ReactNode } from "react";
import { Theme, withTheme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Nullable } from "types/aliases";
import { DataConfig } from "data/types/measurementSetTypes";
import Localization from "data/localization-sensoan/Localization";
import ErrorMessage from "components/layout/ErrorMessage";
import SensorNameAndEventSelector from "./SensorNameAndEventSelector";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import SIconButton from "components/styled-components/SIconButton";

interface Props {
  dataConfig: Nullable<DataConfig[]>;
  isOkToSave: boolean;
  isSensorLimitReached: boolean;
  saveDataConfig: () => void;
  onSensorDisplayNameChange: (index: number, sensorDisplayName: string) => void;
  onSensorSelect: (deviceDataKey: string, deviceId: string, index: number) => void;
  removeDataConfig: (index: number) => void;
  theme: Theme;
}

interface State {
}

class SensorNameAndEventSelection extends Component<Props, State> {

  private renderSensorNameAndEventSelectors(): ReactNode {
    return (
      this.props.dataConfig
      &&
      this.props.dataConfig?.map((config: DataConfig, index: number) => {
        return (
          <SensorNameAndEventSelector
            dataConfig={config}
            index={index}
            key={index}
            onSensorDisplayNameChange={(index, sensorDisplayName): void => this.props.onSensorDisplayNameChange(index, sensorDisplayName)}
            onSensorSelect={(deviceDataKey, deviceId, index): void => this.props.onSensorSelect(deviceDataKey, deviceId, index)}
            removeDataConfig={(index: number): void => this.props.removeDataConfig(index)}
          />);
      })
    );
  }

  public render(): ReactNode {
    return (
      <Fragment>
        {this.renderSensorNameAndEventSelectors()}
        <SIconButton
          backGroundColor="buttonSecondaryBg"
          disabled={this.isAddButtonDisabled()}
          onClick={(): void => this.props.saveDataConfig()}
          size="2.5rem"
        >
          <SSvgIcon color={SSvgIconColorProps.textPrimary} iconComponent={AddIcon}/>
        </SIconButton>
        {this.props.dataConfig === null
        && <ErrorMessage customMessage={Localization.getInstance().getDisplayText("SensorNameAndEventSelection", "validationError")} mt={2}/>}
      </Fragment>
    );
  }

  private isAddButtonDisabled(): boolean {
    let check = false;

    if (!this.props.isOkToSave) {
      check = true;
    } else if (this.props.isSensorLimitReached) {
      check = true;
    }
    return check;
  }
}

export default withTheme(SensorNameAndEventSelection);
