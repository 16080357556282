import React, { Component, Fragment, ReactNode } from "react";
import { Box, Theme, Typography, withTheme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Localization from "data/localization-sensoan/Localization";
import { MapMarkerData } from "data/map/MapLink";
import { DIVIDER_HEIGHT, SCROLLBAR_WIDTH } from "data/theme/constants";
import { Nullable } from "types/aliases";
import SButton from "components/styled-components/SButton";
import IoTDataRow, { IOT_DATA_ROW_HEIGHT } from "components/layout/iot-data-row/IoTDataRow";
import { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import DevicesListDataRow from "./components/DevicesListDataRow";
import DevicesListHeaderRow from "./components/DevicesListHeaderRow";
import { DeviceListItem, DeviceListItemSortKey } from "../../DevicesWrapper";

interface Props {
  listItems: DeviceListItem[];
  mapMarkerData: Nullable<MapMarkerData[]>;
  onSelectListItem: (listItem: DeviceListItem) => Promise<void>;
  setSortOrder: (sortKey: DeviceListItemSortKey) => void;
  sortKey: DeviceListItemSortKey;
  sortOrder: "default" | "reverse";
  theme: Theme;
  toggleAllMapMarkers: () => void;
  toggleMapMarker: (marker: Nullable<MapMarkerData>) => void;
}

interface State {
  rowCount: number;
}

class DeviceList extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor (props: Props) {
    super(props);
    this.state = {
      rowCount: 30,
    };
  }

  private renderDataRows(listItems: DeviceListItem[]): JSX.Element {
    const { theme, mapMarkerData } = this.props;

    if (listItems.length > 0) {
      return (
        <Box height="auto" width="100%" borderRight={`1px solid ${theme.palette.divider}`}>
          {listItems.map((listItem, index) =>
            index <= this.state.rowCount
            &&
            <DevicesListDataRow
              key={listItem.deviceId}
              dataItem={listItem}
              onSelectDataItem={(): Promise<void> => this.props.onSelectListItem(listItem)}
              mapMarkerData={mapMarkerData}
              toggleMarker={(marker): void => this.props.toggleMapMarker(marker)}
            />,
          )}
        </Box>
      );
    } else {
      return (
        <IoTDataRow>
          <Box width="100%">
            <Typography variant="body1">
              {this.text("MeasurementSetsDataTable", "noSearchResults")}
            </Typography>
          </Box>
        </IoTDataRow>
      );
    }
  }

  private renderLoadMoreRowsButton(deviceList: DeviceListItem[]): Nullable<JSX.Element> {
    if (this.state.rowCount <= deviceList.length) {
      return (
        <IoTDataRow justifyContent="center" hideDivider>
          <SButton
            labelText={this.text("ListViewFab", "loadMore")}
            onClick={(): void => this.setState({ rowCount: this.state.rowCount + 30 })}
            endIcon={AddIcon}
            iconColor={SSvgIconColorProps.textPrimary}
          />
        </IoTDataRow>
      );
    } else {
      return null;
    }
  }

  public render(): ReactNode {
    const { sortKey, sortOrder, listItems: deviceList, setSortOrder, toggleAllMapMarkers } = this.props;
    return (
      <Fragment>
        <DevicesListHeaderRow
          requestSorting={(sortKey): void => setSortOrder(sortKey)}
          toggleAllMapMarkers={toggleAllMapMarkers}
          sortKey={sortKey}
          sortOrder={sortOrder}
          iotDataRowDividerWidth={`calc(100% - ${SCROLLBAR_WIDTH})`}
        />
        {/* Data rows - overflowing happens here*/}
        <Box height={this.getDataRowsContainerHeight()} style={{ overflowY: "auto" }}>
          {this.renderDataRows(deviceList)}
          {this.renderLoadMoreRowsButton(deviceList)}
        </Box>
      </Fragment>
    );
  }

  // - - - - - - - - - - - - - - - - - - - - - - -
  // Helper method for building the layout
  private getDataRowsContainerHeight(): string {
    return `calc(100% - ${IOT_DATA_ROW_HEIGHT} - ${DIVIDER_HEIGHT})`;
  }
}

export default withTheme(DeviceList);
