import React, { Component, ReactNode } from "react";
import { Theme, withStyles, withTheme } from "@material-ui/core/styles";
import { Box, FilledInput, FilledInputProps, FormControl, InputBaseComponentProps, InputLabel, InputLabelProps } from "@material-ui/core";
import { CustomSpacingProps } from "types/sensoanUiTypes";

interface Props {
  value: string;
  id: string;
  theme: Theme;
  buttonAppearance?: boolean;
  endAdornment?: ReactNode;
  error?: boolean;
  inputLabelText?: string;
  inputProps?: InputBaseComponentProps;
  inputRef?: React.Ref<any> | undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>; // needed for opening in DateTimePicker
  width?: string;
  mt?: CustomSpacingProps;
  mr?: CustomSpacingProps;
  mb?: CustomSpacingProps;
  ml?: CustomSpacingProps;
}

interface State {
  StyledFilledInput: React.ComponentType<FilledInputProps>;
  StyledInputLabel: React.ComponentType<InputLabelProps>;
}

class SFilledInput extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      StyledFilledInput: this.getStyledFilledInput(),
      StyledInputLabel: this.getStyledInputLabel(),
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.theme !== prevProps.theme) {
      this.setState({
        StyledFilledInput: this.getStyledFilledInput(),
        StyledInputLabel: this.getStyledInputLabel(),
      });
    }
  }

  public render(): JSX.Element {
    const { StyledFilledInput, StyledInputLabel } = this.state;
    const {
      buttonAppearance,
      endAdornment,
      error,
      id,
      inputLabelText,
      inputProps,
      inputRef,
      onChange,
      onClick,
      value,
      mt,
      mr,
      mb,
      ml,
    } = this.props;
    return (
      <Box mt={mt} mr={mr} mb={mb} ml={ml} width={this.props.width}
      >
        {/* FormControl is only needed to apply .Mui-focused correctly to StyledFilledInputLabel */}
        <FormControl variant="filled">
          {(inputLabelText && !value)
          &&
          <StyledInputLabel htmlFor={id}>
            {inputLabelText}
          </StyledInputLabel>}
          <StyledFilledInput
            error={error}
            endAdornment={endAdornment}
            id={id}
            inputProps={inputProps}
            inputRef={inputRef}
            value={value}
            onChange={onChange}
            readOnly={buttonAppearance}
            onClick={onClick}
          />
        </FormControl>
      </Box>
    );
  }

  private getStyledFilledInput(): React.ComponentType<FilledInputProps> {
    const {
      theme,
      buttonAppearance,
    } = this.props;
    return withStyles({
      root: {
        borderRadius: "36px",
        height: "2.5rem",
        lineHeight: "1.75",
        width: "100%",
        background: theme.palette.gradients.grey,
        color: theme.palette.text.primary, // sets the color of the insertion caret
        boxShadow: buttonAppearance ? theme.shadows[2] : undefined,
        fontSize: buttonAppearance ? theme.typography.button.fontSize : theme.typography.body1.fontSize,
        fontWeight: buttonAppearance ? theme.typography.button.fontWeight as 300 : theme.typography.fontWeightRegular as "normal", // quick fix for ts error
        justifyContent: buttonAppearance ? "space-between" : undefined,
        padding: buttonAppearance ? "9px 18px" : undefined,
        cursor: buttonAppearance ? "pointer" : undefined,
        "&:hover": {
          boxShadow: buttonAppearance ? theme.shadows[4] : undefined,
        },
      },
      error: {
        border: `1px solid ${theme.palette.error.main}`,
      },
      input: {
        height: "auto",
        textAlign: "start",
        padding: buttonAppearance ? "0px" : "6px 16px",
        width: buttonAppearance ? "63%" : undefined,
      },
      underline: {
        "&:after": {
          borderBottom: "none",
        },
        "&:before": {
          borderBottom: "none",
        },
        "&:hover:before": {
          borderBottom: "none",
        },
      },
    })(FilledInput);
  }

  private getStyledInputLabel(): React.ComponentType<InputLabelProps> {
    return withStyles({
      root: {
        transform: "translate(12px, 11px) scale(1)",
        color: this.props.theme.palette.text.primary,
      },
      focused: {
        display: "none",
      },
    })(InputLabel);
  }
}


export default withTheme(SFilledInput);

