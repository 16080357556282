import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Box, Theme, withTheme } from "@material-ui/core";
import { DevicePathRouterProps } from "types/routerprops";
import { Nullable } from "types/aliases";
import { AppLayoutMode, LocationGroupingKey, MapButtonActions } from "types/sensoanUiTypes";
import MapLink, { MapMarkerData, MapState } from "data/map/MapLink";
import HereMap from "./HereMap";
import { HereMapsPlace } from "data/map/MapGeocoding";
import LocationTree from "./LocationTree";
import LoaderSensoan from "components/layout/LoaderSensoan";

export interface LocationGroupingResult {
  location: LocationGroupingKey; //TODO: change into LocationInfo and use localization
  items: MapMarkerData[];
}

interface Props extends RouteComponentProps<DevicePathRouterProps>{
  appLayoutMode: AppLayoutMode;
  onAppLayoutModeChange: () => void;
  theme: Theme;
  drawerExpanded?: boolean;
}

interface State {
  actionRequest: Nullable<MapButtonActions>;
  infoBubbleData: Nullable<MapMarkerData[]>;
  loading: boolean;
  locationGroups: Nullable<LocationGroupingResult[]>;
  mouseInteractionEnabled: boolean;
}

class MapWrapper extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      actionRequest: null,
      infoBubbleData: null,
      loading: false,
      locationGroups: null,
      mouseInteractionEnabled: true,
    };
    this.clearActionRequest = this.clearActionRequest.bind(this);
    MapLink.getDataStorage().connectMapWrapper(this);
  }

  public componentDidMount(): void {
    // when drawer is reopened check for existing markers and set them on map
    const mapData = MapLink.getDataStorage().getMarkerData();

    if (mapData.length > 0) {
      MapLink.getLinkToMap().setLocation(mapData);
    }
  }

  public setMapState(): void {
    this.forceUpdate();
  }

  public refresh(): void {
    this.forceUpdate();
  }

  public enableMouseInteraction(): void {
    this.setState({ mouseInteractionEnabled: true });
  }

  public disableMouseInteraction(): void {
    this.setState({ mouseInteractionEnabled: false });
  }

  public closeMapInfoBubble(): void {
    this.setState({ actionRequest: MapButtonActions.closeInfoBubble, infoBubbleData: null });
  }

  // TODO: separate component for IB content
  private renderIBContentTree(): JSX.Element {
    const isLargeMap = this.props.appLayoutMode === AppLayoutMode.mapWithDrawer;
    return (
      <Box
        bgcolor={this.props.theme.palette.background.default}
        borderRadius={this.props.theme.shape.borderRadius}
        width={isLargeMap ? "23.75rem" : "13.75rem"}
        maxHeight={isLargeMap ? "16.25rem" : "8.75rem"}
        px={2}
        overflow="auto"
      >
        {this.state.loading
          ?
          <LoaderSensoan size={2}/>
          :
          <LocationTree
            appLayoutMode={this.props.appLayoutMode}
            closeMapInfoBubble={(): void => this.closeMapInfoBubble()}
            onAppLayoutModeChange={this.props.onAppLayoutModeChange}
            size={isLargeMap ? "large" : "small"}
            treeItems={this.state.locationGroups!}
          />
        }
      </Box>
    );
  }

  public render(): JSX.Element {
    const mapState = MapLink.getLinkToMap().getMapState();
    const size = mapState === MapState.DEFAULT ? "100%" : "calc(100% - 2px)";
    const style: React.CSSProperties = this.state.mouseInteractionEnabled
      ?
      { background: "linear-gradient(180deg, #0069FF 0%, #979FAA 100%)", pointerEvents: "auto" }
      :
      { background: "linear-gradient(180deg, #0069FF 0%, #979FAA 100%)", pointerEvents: "none" };
    return (
      <Box display="flex" justifyContent="center" alignItems="center" m={0} p={0} border={0} width={"100%"} height={"100%"}
        style={style}>
        <Box bgcolor={this.props.theme.palette.background.default} m={0} p={0} border={0} zIndex={2} display="flex" justifyContent="center" alignItems="center" width={size} height={size}>
          <HereMap
            actionRequest={this.state.actionRequest}
            appLayoutMode={this.props.appLayoutMode}
            clearActionRequest={this.clearActionRequest}
            infoBubbleContentEl={this.renderIBContentTree()}
            infoBubbleData={this.state.infoBubbleData}
            mapState={mapState}
            markerData={MapLink.getDataStorage().getMarkerData()}
            onAppLayoutModeChange={this.props.onAppLayoutModeChange}
            onMarkerGroupClick={(markerData: MapMarkerData[]): Promise<void> => this.handleMarkerGroupClick(markerData)}
            theme={this.props.theme}
            drawerExpanded={this.props.drawerExpanded}
          />
        </Box>
      </Box>
    );
  }

  private async handleMarkerGroupClick(markerData: MapMarkerData[]): Promise<void> {
    this.setState({ loading: true, infoBubbleData: markerData, actionRequest: MapButtonActions.openInfoBubble });
    const locationGroups = await this.groupIBDataByLocation(markerData);
    this.setState({ locationGroups, loading: false });
  }

  private async groupIBDataByLocation(IBData: MapMarkerData[]): Promise<LocationGroupingResult[]> {
    const groupingResult: LocationGroupingResult[] = [];
    await Promise.all(IBData.map(async (data) => {
      const locations = await MapLink.getLinkToMap().search(data.location());

      if (locations && locations.length > 0) {
        this.pushIntoLocation(groupingResult, locations[0], data);
      } else {
        this.pushIntoUnknownLocation(groupingResult, data);
      }
    },
    ));
    return groupingResult;
  }

  private pushIntoLocation(result: LocationGroupingResult[], location: HereMapsPlace, data: MapMarkerData): void {
    const existingResultItem = result.find(item => typeof item.location === "object" && item.location.title === location.title);

    if (!existingResultItem) {
      result.push({
        location,
        items: [data],
      });
    } else {
      existingResultItem.items.push(data);
    }
  }

  // creates a separate entry for locations which do not return a place from Here API
  private pushIntoUnknownLocation(result: LocationGroupingResult[], data: MapMarkerData): void {
    const existingResultItem = result.find(aItem => aItem.location === "Tuntematon sijainti");

    if (!existingResultItem) {
      result.push({
        location: "Tuntematon sijainti",
        items: [data],
      });
    } else {
      existingResultItem.items.push(data);
    }
  }

  public clearActionRequest(): void {
    this.setState({ actionRequest: null });
  }
}

export default withRouter(withTheme(MapWrapper));
