import { MapLocation } from "data/map/MapLink";
import { Voidable } from "types/aliases";

export interface MeasurementJob {
  setId: string;
  jobId: string;
  displayName: string;
  responsible: string;
  startTs?: Voidable<number>;
  startTriggerConf?: Voidable<string>; // TODO: define TriggerConf
  startTriggerId?: Voidable<string>;
  endTs?: Voidable<number>;
  endTriggerConf?: Voidable<string>; // TODO: define TriggerConf
  endTriggerId?: Voidable<string>;
  metadata?: Voidable<string>;
  endLocation?: Voidable<MapLocation>;
  status: MeasurementJobStatus;
}

export enum MeasurementJobStatus {
  NotStarted = "not_started",
  Ready = "ready",
  Running = "running",
}

export enum StartType {
  manual = "manual",
  timer = "timer",
  // Sensor not yet supported
  // sensor = "sensor",
}
