import React, { Component, Fragment } from "react";
import Localization from "data/localization-sensoan/Localization";
import EventsRepository from "data/data-storage/EventsRepository";
import { EventRepositoryListener } from "data/data-storage/EventRepositoryListener";
import Event, { EventState } from "data/clientSpecific/Event";
import { Box, Divider, InputAdornment, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Maybe, Nullable } from "types/aliases";
import Section from "components/layout/Section";
import WrapperTitle2 from "components/layout/WrapperTitle2";
import { BreadcrumbData } from "types/sensoanUiTypes";
import { getDisplayName } from "data/utils/Utils";
import IoTDataRow from "components/layout/iot-data-row/IoTDataRow";
import { sortList } from "data/utils/mathUtils";
import Device from "data/device/Device";
import MapLink, { MapMarkerData } from "data/map/MapLink";
import EventsListHeaderRow from "./EventsListHeaderRow";
import EventsListDataRow from "./EventsListDataRow";
import SFilledInput from "components/styled-components/SFilledInput";
import SButton from "components/styled-components/SButton";
import AddIcon from "@material-ui/icons/Add";
import withDataJanitor from "components/hocs/DataJanitor";
import { DataRepositories } from "data/data-storage/DataRepositoryFactory";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import DeviceSelect2 from "components/inputs/DeviceSelect2";
import { buildUniqueUIStringForEvent, getMapMarkerDataForAllEvents } from "data/utils/eventUtils";
import { getLocationsFromMapMarkers } from "data/utils/mapUtils";
import DeviceNavigationCache from "utils/DeviceNavigationCache";
import { RouteComponentProps, withRouter } from "react-router";
import { DevicePathRouterProps } from "types/routerprops";
import { DeviceChangeType, idFromProps } from "utils/NavigationUtils";
import DeviceRepository from "data/data-storage/DeviceRepository";
import WrapperContainer from "components/layout/WrapperContainer";

interface Props extends RouteComponentProps<DevicePathRouterProps> {}

interface State {
  deviceSelectorAnchorEl: Nullable<HTMLButtonElement>;
  mapMarkerData: Nullable<MapMarkerData[]>; // handling color changing in location buttons is faster if we keep a copy of marker data in state
  rowCount: number;
  titleBreadcrumbs: BreadcrumbData[];
  searchText: Nullable<string>;
  sortKey: keyof Event;
  sortOrder: "default" | "reverse";
  filteredEventsList: Event[];
  sortedEventsList: Event[];
  showOnlyActive: boolean;
}

class EventsWrapper extends Component<Props, State> implements EventRepositoryListener {

  private text = Localization.getInstance().getDisplayText;

  public constructor (props: Props) {
    super(props);
    this.state = {
      deviceSelectorAnchorEl: null,
      mapMarkerData: null,
      rowCount: 30,
      titleBreadcrumbs: [{
        name: (): string => Localization.getInstance().getDisplayText("Common", "events"),
        link: async (): Promise<Maybe<Device>> => await DeviceNavigationCache.getInstance().navigateToDevice(this.props, undefined),
      }],
      searchText: null,
      sortKey: "timestamp",
      sortOrder: "reverse",
      filteredEventsList: [],
      sortedEventsList: [],
      showOnlyActive: true,
    };
    this.toggleAllMapMarkers = this.toggleAllMapMarkers.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    const deviceResolveResult = DeviceNavigationCache.getInstance().predictDeviceChange(this.props);

    if (deviceResolveResult === DeviceChangeType.ChangedToNew) {
      const deviceId = idFromProps(this.props);
      await this.handleDeviceChange(deviceId ? DeviceRepository.getInstance().getDevice(deviceId) : undefined);
      // StayedNone is one of the two expected cases - no need to do anything in that case
    } else if (deviceResolveResult !== DeviceChangeType.StayedNone) {
      console.error(`Unexpected value ${deviceResolveResult} for deviceResolveResult`);
    }

    EventsRepository.instance.addListener(this);
    const sortedEventsList = sortList(this.state.sortOrder, this.state.sortKey, this.getRawEventsList());
    const filteredEventsList = this.filterEventsList(sortedEventsList, this.state.searchText);
    this.setState({ sortedEventsList, filteredEventsList });
  }

  public async componentDidUpdate(prevProps: Props, prevState: State): Promise<void> {
    const pathnameChanged = this.props.location.pathname !== prevProps.location.pathname;

    if (pathnameChanged) {
      const deviceId = idFromProps(this.props);
      // deviceId (and device selection) was cleared if deviceId === undefined
      await this.handleDeviceChange(deviceId ? DeviceRepository.getInstance().getDevice(deviceId) : undefined);
    }

    if (this.state.sortKey !== prevState.sortKey || this.state.sortOrder !== prevState.sortOrder || this.state.showOnlyActive !== prevState.showOnlyActive) {
      const sortedEventsList = sortList(this.state.sortOrder, this.state.sortKey, this.state.sortedEventsList);
      const filteredEventsList = this.filterEventsList(this.state.sortedEventsList, this.state.searchText);
      this.setState({ sortedEventsList, filteredEventsList });
    }

    if (this.didFilteredEventsListChange(prevState.filteredEventsList, this.state.filteredEventsList)) {
      const filteredEventMarkersOnMap = MapLink.getDataStorage().getMarkerData().filter(mData => {
        return this.state.filteredEventsList.some(event => {
          // TODO: fix check (buildUniqueUIStringForEvent uses uuid -> values are never equal)
          return buildUniqueUIStringForEvent(event) === mData.id;
        });
      });
      this.setState({ mapMarkerData: filteredEventMarkersOnMap.length > 0 ? filteredEventMarkersOnMap : null });
      MapLink.getLinkToMap().replaceData(filteredEventMarkersOnMap);
    }

    const device = DeviceNavigationCache.getInstance().getSelectedDevice();

    if ((prevState.showOnlyActive !== this.state.showOnlyActive) && device) {
      const mapMarkerData = getMapMarkerDataForAllEvents(
        this.state.showOnlyActive
          ? EventsRepository.instance.getAllActiveEvents().filter(event => device.getId() === event.deviceId)
          : EventsRepository.instance.getAllEvents().filter(event => device.getId() === event.deviceId),
      );

      if (mapMarkerData.length > 0) {
        MapLink.getLinkToMap().replaceData(mapMarkerData);
        MapLink.getLinkToMap().centerMap(getLocationsFromMapMarkers(mapMarkerData), true);
      }
    }
  }

  public componentWillUnmount(): void {
    EventsRepository.instance.removeListener(this);

    if (MapLink.getDataStorage().getMarkerData().length > 0) {
      MapLink.getLinkToMap().removeAllLocations();
      MapLink.getLinkToMap().resetZoomAndCenter();
    }
  }

  public onEvent(_event: Event): void {
    const sortedEventsList = sortList(this.state.sortOrder, this.state.sortKey, this.getRawEventsList());
    const filteredEventsList = this.filterEventsList(sortedEventsList, this.state.searchText);
    this.setState({ sortedEventsList, filteredEventsList });
  }

  public onEventStateChanged(_event: Event): void {
    const sortedEventsList = sortList(this.state.sortOrder, this.state.sortKey, this.getRawEventsList());
    const filteredEventsList = this.filterEventsList(sortedEventsList, this.state.searchText);
    this.setState({ sortedEventsList, filteredEventsList });
  }

  public onSearchTextChange(text: Nullable<string>): void {
    const filteredEventsList = this.filterEventsList(this.state.sortedEventsList, text);
    this.setState({ searchText: text, filteredEventsList });
  }

  public onDeviceSetChanged(devices: Device[]): void {
    console.log("onDeviceSetChanged " + devices.length);
  }

  private async handleDeviceChange(device: Maybe<Device>): Promise<void> {
    await DeviceNavigationCache.getInstance().setCurrentDevice(device);
    const sortedEventsList = sortList(this.state.sortOrder, this.state.sortKey, this.getRawEventsList());
    const filteredEventsList = this.filterEventsList(sortedEventsList, this.state.searchText);
    const crumbs = [this.state.titleBreadcrumbs[0]];

    if (device !== undefined) {
      crumbs[1] = {
        name: this.getCurrentDeviceDisplayName(),
        link: (): void => undefined,
      };
      const mapMarkerData = getMapMarkerDataForAllEvents(
        this.state.showOnlyActive
          ? EventsRepository.instance.getAllActiveEvents().filter(event => device?.getId() === event.deviceId)
          : EventsRepository.instance.getAllEvents().filter(event => device?.getId() === event.deviceId),
      );

      MapLink.getLinkToMap().replaceData(mapMarkerData);

      if (mapMarkerData.length > 0) {
        MapLink.getLinkToMap().centerMap(getLocationsFromMapMarkers(mapMarkerData), true);
      }

    } else {

      if (MapLink.getDataStorage().getMarkerData().length > 0) {
        MapLink.getLinkToMap().removeAllLocations();
      }

      if (!MapLink.getLinkToMap().isDefaultZoomAndCenter()) {
        MapLink.getLinkToMap().resetZoomAndCenter();
      }
    }
    this.setState({ sortedEventsList, filteredEventsList, deviceSelectorAnchorEl: null, titleBreadcrumbs: crumbs });
  }

  private renderEventRows(events: Event[]): JSX.Element {
    if (events.length > 0) {
      return (
        <Fragment>
          {events.map((event: Event, i: number) =>
            i <= this.state.rowCount
            &&
            <EventsListDataRow
              key={buildUniqueUIStringForEvent(event)}
              dataItem={event}
              mapMarkerData={this.state.mapMarkerData}
              toggleMarker={(marker: Nullable<MapMarkerData>): void => this.toggleMapMarker(marker)}
            />,
          )}
        </Fragment>
      );
    } else {
      return (
        <IoTDataRow>
          <Box width="100%">
            <Typography variant="body1">
              {this.text("MeasurementSetsDataTable", "noSearchResults")}
            </Typography>
          </Box>
        </IoTDataRow>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <WrapperContainer>
        <WrapperTitle2 breadcrumbList={this.state.titleBreadcrumbs} minWidth="1100px"/>
        <Section minWidth="1100px">
          <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" height="3.5rem">
            <Box display="flex">
              {/* TODO: replace DeviceSelect2 and SButton with SSelect */}
              {this.state.deviceSelectorAnchorEl !== null
              &&
              <DeviceSelect2 anchorEl={this.state.deviceSelectorAnchorEl} close={(): void => this.setState({ deviceSelectorAnchorEl: null })} />}
              <SButton
                color="secondary"
                labelText={this.getCurrentDeviceDisplayName()}
                onClick={(event: React.MouseEvent<HTMLButtonElement>): void => this.setState({ deviceSelectorAnchorEl: event.currentTarget })}
                mr={12}
              />
              <SButton
                color="secondary"
                labelText={Localization.getInstance().getDisplayText("EventsView", this.state.showOnlyActive ? "showAll" : "showActive")}
                onClick={(): void => this.setState({ showOnlyActive: !this.state.showOnlyActive })}
              />
            </Box>
            <SFilledInput
              endAdornment={
                <InputAdornment position="end">
                  <SSvgIcon color={SSvgIconColorProps.textPrimary} iconComponent={SearchIcon} size="1.5rem" />
                </InputAdornment>
              }
              id="events-search"
              inputLabelText={Localization.getInstance().getDisplayText("Actions", "search")}
              onChange={(event): void => this.onSearchTextChange(event.target.value)}
              value={this.state.searchText ?? ""}
              width="12.5rem"
            />
          </Box>
          <Box mt={2}>
            <Divider />
          </Box>
        </Section>
        <Section minWidth="1100px">
          <EventsListHeaderRow
            requestSorting={(sortKey: keyof Event): void => this.setSortOrder(sortKey)}
            sortKey={this.state.sortKey}
            sortOrder={this.state.sortOrder}
            toggleAllMapMarkers={(): void => this.toggleAllMapMarkers()}
          />
          {this.renderEventRows(this.state.filteredEventsList)}
        </Section>
        {this.state.rowCount <= this.state.filteredEventsList.length
        &&
        <Box display="flex" justifyContent="center">
          <Box width="fit-content">
            <SButton
              labelText={Localization.getInstance().getDisplayText("ListViewFab", "loadMore")}
              onClick={(): void => this.setState({ rowCount: this.state.rowCount + 30 })}
              endIcon={AddIcon}
              iconColor={SSvgIconColorProps.textPrimary}
            />
          </Box>
        </Box>
        }
      </WrapperContainer>
    );
  }

  private toggleAllMapMarkers(): void {
    let mapMarkerData: MapMarkerData[];

    if (this.isMapMarkerDataFiltered()) {
      mapMarkerData = this.getMarkerDataForAllFilteredEvents();

      if (MapLink.getLinkToMap().areLocationsOnMap(mapMarkerData.map(({ id }) => id))) {
        this.setState({ mapMarkerData: null });
        MapLink.getLinkToMap().removeAllLocations();
      } else {
        this.setState({ mapMarkerData });
        MapLink.getLinkToMap().replaceData(mapMarkerData);
      }
    } else {
      mapMarkerData = getMapMarkerDataForAllEvents();

      if (MapLink.getLinkToMap().areLocationsOnMap(mapMarkerData.map(({ id }) => id))) {
        this.setState({ mapMarkerData: null });
        MapLink.getLinkToMap().removeAllLocations();
      } else {
        this.setState({ mapMarkerData });
        MapLink.getLinkToMap().replaceData(mapMarkerData);
      }
    }
  }

  private toggleMapMarker(marker: Nullable<MapMarkerData>): void {
    const { mapMarkerData } = this.state;

    if (marker) {
    // handle updating state
      if (mapMarkerData && mapMarkerData.findIndex(mData => mData.id === marker.id) > -1) {
        this.setState({ mapMarkerData: mapMarkerData.filter(mData => mData.id !== marker.id) });
      } else if (mapMarkerData) {
        this.setState({ mapMarkerData: mapMarkerData.concat(marker) });
      } else {
        this.setState({ mapMarkerData: [marker] });
      }
      // handle updating markerData in MapLink
      MapLink.getLinkToMap().toggle(marker);
    }
  }

  // - - - - - - - - - - - - - - - -
  // Private helper methods

  private filterEventsList(eventsList: Event[], searchText: Nullable<string>): Event[] {
    if (searchText === null || searchText === "") {
      const filteredEventsList = eventsList.filter((event) =>
        (this.state.showOnlyActive ? event.eventState === EventState.Active : true));
      return filteredEventsList;
    } else {
      const filteredEventsList = eventsList.filter((event) =>
        (this.state.showOnlyActive ? event.eventState === EventState.Active : true) &&
        (event.sensorName?.toLowerCase().includes(searchText.toLowerCase()) ||
        event.deviceId?.toLowerCase().includes(searchText.toLowerCase()) ||
        event.eventState?.toLowerCase().includes(searchText.toLowerCase()) ||
        event.metadata?.toLowerCase().includes(searchText.toLowerCase())));
      return filteredEventsList;
    }
  }

  private getCurrentDeviceDisplayName(): string {
    const device = DeviceNavigationCache.getInstance().getSelectedDevice();

    if (!device) {
      return this.text("Common", "device");
    } else {
      return (getDisplayName(device));
    }
  }

  private getRawEventsList(): Event[] {
    const device = DeviceNavigationCache.getInstance().getSelectedDevice();

    if (!device) {
      return EventsRepository.instance.getAllEvents();
    } else {
      return EventsRepository.instance.getAllEvents().filter((event) => device?.getId() === event.deviceId);
    }
  }

  private setSortOrder(sortKey: keyof Event): void {
    if (this.state.sortKey !== sortKey) {
      this.setState({ sortKey });
    } else {
      this.setState({ sortOrder: this.state.sortOrder === "default" ? "reverse" : "default" });
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - -
  // Helper methods for map marker data and location button handling
  private didFilteredEventsListChange(previousList: Event[], currentList: Event[]): boolean {
    if (previousList.length >= currentList.length) {
      return !previousList.every(prevListItem => {
        return currentList.some(currListItem => {
          return (prevListItem.deviceId === currListItem.deviceId &&
            prevListItem.eventId === currListItem.eventId &&
            prevListItem.timestamp === currListItem.timestamp);
        });
      });
    } else if (previousList.length < currentList.length){
      return !currentList.every(currListItem => {
        return previousList.some(prevListItem => {
          return (currListItem.deviceId === prevListItem.deviceId &&
            currListItem.eventId === prevListItem.eventId &&
            currListItem.timestamp === prevListItem.timestamp);
        });
      });
    } else {
      return false;
    }
  }

  private isMapMarkerDataFiltered(): boolean {
    return !getMapMarkerDataForAllEvents().every(mDataItemA => {
      return this.getMarkerDataForAllFilteredEvents().some(mDataItemB => {
        return mDataItemA.id === mDataItemB.id;
      });
    });
  }

  private getMarkerDataForAllFilteredEvents(): MapMarkerData[] {
    return getMapMarkerDataForAllEvents(this.state.filteredEventsList);
  }
}

export default withRouter(withDataJanitor(EventsWrapper, [
  DataRepositories.Device,
  DataRepositories.Events,
]));


