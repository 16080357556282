/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { DialogActions, DialogTitle, Box, Dialog, DialogContent, Theme, withTheme } from "@material-ui/core";


import LoaderSensoan from "components/layout/LoaderSensoan";
import React, { Component } from "react";

interface Props {
  onClose: () => void;
  title: string;
  dialogActionElements?: JSX.Element;
  showLoader?: boolean;
  theme: Theme;
}

class DialogSensoan extends Component<Props> {

  public render(): JSX.Element {
    return (
      <Dialog
        onClose={this.props.onClose}
        open={true}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { backgroundColor: this.props.theme.palette.background.default, height: "14rem" } }}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          {this.props.title}
        </DialogTitle>
        <DialogContent>
          {this.props.showLoader
            ?
            <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" pb="1.5rem">
              <LoaderSensoan topBottomPadding="0px"/>
            </Box>
            :
            this.props.children}
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          {this.props.dialogActionElements}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTheme(DialogSensoan);
