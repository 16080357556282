import Gradients, { IconGradientNames } from "data/theme/PaletteColors/Gradients";
import ThemeSelector from "data/theme/ThemeSelector";
import { AppLayoutMode } from "types/sensoanUiTypes";
import LocationIcon from "assets/map/icons/LocationIcon.svg";
import ClusterIcon from "assets/map/icons/ClusterIcon.svg";
import { getClusterDomIconWithLinearGradient } from "components/map/map-icons/ClusterIcon";
import { getLocationIconWithLinearGradient } from "components/map/map-icons/LocationIcon";
import MapBase from "./MapBase";
import { IconTypes, MapMarkerData } from "./MapLink";

export interface ClusteringProvider {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  init(data: MapMarkerData[], appLayoutMode: AppLayoutMode, uiCallback: (event: any) => void): void;
  getClusteredDataProvider(): any;  // eslint-disable-line @typescript-eslint/no-explicit-any
  updateClusteredDataProvider(markers: MapMarkerData[], appLayoutMode: AppLayoutMode): void;
}

export default class MapClustering implements ClusteringProvider {
  private static instance: MapClustering;

  private clustering: any = null; // eslint-disable-line @typescript-eslint/no-explicit-any
  private clusteredDataProvider: any = null; // eslint-disable-line @typescript-eslint/no-explicit-any

  public static getInstance(): MapClustering {
    if (this.instance == null) {
      this.instance = new MapClustering();
    }
    return this.instance;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public init(data: MapMarkerData[], appLayoutMode: AppLayoutMode, uiCallback: (event: any) => void): void {
    this.clustering = MapBase.getInstance().getClustering();

    // First we need to create an array  of DataPoint objects for the ClusterProvider
    const dataPoints = data.map(item => {
      return new this.clustering.DataPoint(item.location().lat, item.location().lng, null, item);
    });

    if (dataPoints.length > 0) {
      this.clusteredDataProvider = new this.clustering.Provider(dataPoints, {
        clusteringOptions: {
          strategy: this.clustering.Provider.Strategy.GRID,
          // Maximum radius of the neighbourhood
          eps: 30,
          // minimum weight of points required to form a cluster
          minWeight: 2,
        },
        theme: this.getCustomClusteringTheme(dataPoints, appLayoutMode),
      });
      // Note that we attach the event listener to the cluster provider, and not to
      // the individual markers
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.clusteredDataProvider.addEventListener("tap", (event: any) => uiCallback(event));
      MapBase.getInstance().addLayer(this.clusteredDataProvider);
    }
  }

  public getClusteredDataProvider(): any { // eslint-disable-line @typescript-eslint/no-explicit-any
    return this.clusteredDataProvider;
  }

  public updateClusteredDataProvider(markers: MapMarkerData[], appLayoutMode: AppLayoutMode): void {
    const dataPoints = markers.map(marker => {
      return new this.clustering.DataPoint(marker.location().lat, marker.location().lng, null, marker);
    });
    // there is no API to remove dataPoints from clusteredDataProvider when dataPoints.length === 0
    // but clusteredDataProvider handles it internally
    this.clusteredDataProvider.setDataPoints(dataPoints);

    if (dataPoints.length > 0) {
      this.clusteredDataProvider.setTheme(this.getCustomClusteringTheme(dataPoints, appLayoutMode)); // calling only setDataPoints will use default theme for clustering
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getCustomClusteringTheme(dataPoints: any[], appLayoutMode: AppLayoutMode): any {
    const markerType = this.getMapMarkerDataType(dataPoints);
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getClusterPresentation: (cluster: any): any => {
        const clusterMarker = MapBase.getInstance().getMarker("domMarker", cluster.getPosition(), {
          icon: this.getClusterDomIcon(markerType, this.getDataPointCount(cluster), appLayoutMode),
          // Set min/max zoom with values from the cluster,
          // otherwise clusters will be shown at all zoom levels:
          min: cluster.getMinZoom(),
          max: cluster.getMaxZoom(),
        });
        clusterMarker.setData(this.getClusterMarkerData(cluster));
        return clusterMarker;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getNoisePresentation: (noisePoint: any): any => {
        // Get a reference to data object our noise points
        const data = noisePoint.getData();
        // Create a marker for the noisePoint
        const noiseMarker = MapBase.getInstance().getMarker("marker", noisePoint.getPosition(), {
          // Use min zoom from a noise point
          // to show it correctly at certain zoom levels:
          min: noisePoint.getMinZoom(),
          icon: this.getMarkerIcon(markerType),
        });
        noiseMarker.setData(data);
        return noiseMarker;
      },
    };
  }

  private getClusterMarkerData(cluster: any): any[] { // eslint-disable-line @typescript-eslint/no-explicit-any
    const clusterMarkerData: any[] = []; // eslint-disable-line @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cluster.forEachDataPoint((datapoint: any) => clusterMarkerData.push(datapoint.getData()));
    return clusterMarkerData;
  }

  private getDataPointCount(cluster: any): number { // eslint-disable-line @typescript-eslint/no-explicit-any
    let dataPointCount = 0;
    cluster.forEachDataPoint(() => dataPointCount++);
    return dataPointCount;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getClusterDomIcon(IconType: IconTypes, textContent: number, appLayoutMode: AppLayoutMode): any {
    let iconGradientName: IconGradientNames;
    const theme = ThemeSelector.getInstance().getSelectedTheme();

    switch (IconType) {
      case IconTypes.measurementSet:
        iconGradientName = IconGradientNames.blueGrey;
        break;
      case IconTypes.measurementJob:
        iconGradientName = IconGradientNames.greenGrey;
        break;
      case IconTypes.device:
        iconGradientName = IconGradientNames.yellowGrey;
        break;
      case IconTypes.event:
        iconGradientName = IconGradientNames.orangeGrey;
        break;
      default:
        iconGradientName = IconGradientNames.blueGrey;
        console.error("Unknown parameter 'IconType' in MapClustering.getMarkerIcon");
    }

    const colorStops = Gradients.getIconGradientColorStops(theme, iconGradientName);

    // can't use SSvgIcon component here as H.map.Icon only accepts raw SVG format
    if (colorStops !== undefined) {
      const containerDiv = document.createElement("div");
      containerDiv.insertAdjacentHTML("beforeend", getClusterDomIconWithLinearGradient(theme, colorStops, textContent, appLayoutMode === AppLayoutMode.mapWithDrawer));
      return MapBase.getInstance().getIcon("domIcon", containerDiv);
    } else {
      console.error(`No color stops found for iconGradientName ${iconGradientName} in MapClustering.getClusterIcon`);
      return MapBase.getInstance().getIcon("icon", ClusterIcon);
    }
  }

  private getMapMarkerDataType(dataPoints: any[]): IconTypes { // eslint-disable-line @typescript-eslint/no-explicit-any
    const iconTypes = dataPoints.reduce<IconTypes[]>((acc, curr) => {
      if (!acc.includes(curr.data.type)) {
        acc.push(curr.data.type);
      }
      return acc;
    }, []);

    // return an IconType in both cases because getCustomClusteringTheme
    // will throw an error if nothing is set as icon property of getClusterPresentation
    if (iconTypes.length !== 1) {
      console.error("Error in MapClustering.getMapMarkerDataType: markerData contains different types of markers");
      return IconTypes.measurementSet;
    } else {
      return iconTypes[0];
    }
  }

  private getMarkerIcon(IconType: IconTypes): any { // eslint-disable-line @typescript-eslint/no-explicit-any
    const theme = ThemeSelector.getInstance().getSelectedTheme();
    let iconGradientName: IconGradientNames;

    switch (IconType) {
      case IconTypes.measurementSet:
        iconGradientName = IconGradientNames.blueGrey;
        break;
      case IconTypes.measurementJob:
        iconGradientName = IconGradientNames.greenGrey;
        break;
      case IconTypes.device:
        iconGradientName = IconGradientNames.yellowGrey;
        break;
      case IconTypes.event:
        iconGradientName = IconGradientNames.orangeGrey;
        break;
      default:
        iconGradientName = IconGradientNames.blueGrey;
        console.error("Unknown parameter 'IconType' in MapClustering.getMarkerIcon");
    }

    const colorStops = Gradients.getIconGradientColorStops(theme, iconGradientName);

    // can't use SSvgIcon component here as H.map.Icon only accepts raw SVG format
    if (colorStops !== undefined) {
      return MapBase.getInstance().getIcon("icon", getLocationIconWithLinearGradient(colorStops));
    } else {
      console.error(`No color stops found for iconGradientName ${iconGradientName} in MapClustering.getMarkerIcon`);
      return MapBase.getInstance().getIcon("icon", LocationIcon);
    }
  }
}
