import React, { Component, ReactNode } from "react";
import { Maybe } from "types/aliases";
import { Box, Typography } from "@material-ui/core";
import { DataConfig, SensorColorType } from "data/types/measurementSetTypes";
import SensorColorPicker from "./SensorColorPicker";

interface Props {
  availableSensorColors: Maybe<SensorColorType[]>;
  dataConfig: DataConfig;
  index: number;
  onSensorColorSelect: (index: number, color: string) => void;
}

interface State {
}

class SensorColorSelector extends Component<Props, State> {

  public render(): ReactNode {
    return (
      <Box display="flex" alignItems="baseline">
        <Typography variant="body1" color="textSecondary">
          {this.props.dataConfig.sensorDisplayName}
        </Typography>
        <SensorColorPicker
          availableColors={this.props.availableSensorColors}
          selectedColor={this.props.dataConfig.sensorColor}
          index={this.props.index}
          onColorSelect={this.props.onSensorColorSelect}
        />
      </Box>
    );
  }
}

export default SensorColorSelector;
