import React, { Component, ReactNode } from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { Nullable } from "types/aliases";
import Device, { DeviceObserver } from "data/device/Device";
import ClientProperties from "data/clientSpecific/ClientProperties";
import DeviceGroup from "data/device/DeviceGroup";
import DeviceState from "data/device/DeviceState";
import { DeviceStateProperties } from "data/device/DeviceStateProperties";
import Localization from "data/localization-sensoan/Localization";
import { DateTimeFormatTarget, getDateTimeFormat, getDisplayName } from "data/utils/Utils";
import { ReferenceHWState } from "client/devices/ReferenceHW/ReferenceHWState";
import { ReferenceHWStateProperties } from "client/devices/ReferenceHW/ReferenceHWStateProperties";
import DeviceSettingsButton from "components/device-settings/device-settings-button";
import LocationIcon from "components/ui/location-icon";
import { getPowerSupplyStateIcon, getSignalStrengthIcon } from "../helpers/icon-factory";

export const TOOLTIP_DELAY_MS = 400;

export interface Props {
  device: Device;
  editMode?: boolean;
  parentGroup?: DeviceGroup;
  selected?: boolean;
  onDeviceSelect?: (device?: Device) => void;
}

interface State {
  deviceState: Nullable<DeviceState<DeviceStateProperties>>;
  selected: boolean;
}

export default class DraggableDeviceItem extends Component<Props, State> implements DeviceObserver {

  public constructor(props: Props) {
    super(props);
    this.state = {
      deviceState: props.device.getState(),
      selected: false,
    };
  }

  public componentDidMount(): void {
    this.props.device.addObserver(this);
  }


  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.selected !== prevProps.selected) {
      // since props are our primary source of selection "truth", we will always invalidate our internal selection
      // state when props selection state changes.
      this.setState({ selected: false });
    }
  }

  public componentWillUnmount(): void {
    this.props.device.removeObserver(this);
  }

  private handleClick = (): void => {
    if (!this.props.selected) {
      this.props.onDeviceSelect?.(this.props.device);
      this.setState({ selected: true });
    }
  };

  public onDeviceStateUpdated(device: Device): void {
    this.setState({ deviceState: device.getState() });
  }

  private handleDragStart = (event: React.DragEvent): void => {
    ReactTooltip.hide();

    if (this.props.parentGroup) {
      event.dataTransfer.setData("group", this.props.parentGroup.getId());
    }
    event.dataTransfer.setData("device", this.props.device.getId());
  };

  private getTooltip = (): string => {
    let timestampAsString = "N/A";

    if (this.state.deviceState && this.state.deviceState.getStateUpdatedTimestampMillis()) {
      const timestamp = this.state.deviceState.getStateUpdatedTimestampMillis();
      timestampAsString = moment(timestamp).format(getDateTimeFormat(DateTimeFormatTarget.ShadowUpdate));
    }
    return `${Localization.getInstance().getDisplayText("AdminView", "lastUpdate")}: ${timestampAsString}`;
  };

  private renderSignalStatusElement(): ReactNode {
    const icon = getSignalStrengthIcon(this.state.deviceState);

    if (icon) {
      return (
        <img
          className="status-icon"
          data-tip={this.getTooltip()}
          src={icon}
          alt="Signal"
        />
      );
    }
  }

  private renderPowerSupplyStatusElement(): ReactNode {
    const icon = getPowerSupplyStateIcon(this.state.deviceState);

    if (icon) {
      return (
        <img
          className="status-icon"
          data-tip={this.getTooltip()}
          src={icon}
          alt="Battery"
        />
      );
    }
  }

  private renderDeviceIcon(): ReactNode {
    return <img src={this.props.device.getIcon()} alt="device logo" />;
  }

  private renderDeviceName(): ReactNode {
    return (
      <div
        className="iot-item-link col-sm-8 col-xsm-8"
        onClick={this.handleClick}
      >
        <div
          className="iot-icon col-sm-1 col-xsm-1"
          data-tip={ClientProperties.getAlarmTooltipFromEntities([])}
        >
          {this.renderDeviceIcon()}
        </div>
        <div className="iot-name col-sm-10 col-xsm-10" data-tip={this.getTooltip()}>
          {getDisplayName(this.props.device)}
        </div>
      </div>
    );
  }

  private renderStatusContainer(): ReactNode {
    if (this.props.editMode) return;

    const deviceState = this.state.deviceState as Nullable<ReferenceHWState<ReferenceHWStateProperties>>;

    return (
      <div className="iot-status-container col-sm-4 col-xsm-4">
        <DeviceSettingsButton
          device={this.props.device}
          isIcon={true}
        />
        {this.renderSignalStatusElement()}
        <LocationIcon
          locationStatus={deviceState?.gpsFix ?? undefined}
          updateMilliseconds={deviceState?.getStateUpdatedTimestampMillis()}
        />
        {this.renderPowerSupplyStatusElement()}
      </div>
    );
  }

  public render(): JSX.Element {
    // TODO:  remove ClientProperties.getAlarmTooltipFromEntities([]) or add alarms back
    //        currently it provides some custom tool-tipping even if alerts are not enabled
    const selected = this.props.selected || this.state.selected;
    const divClass = selected ? "tree-node-selected" : "tree-node-not-selected";
    return (
      <div
        className={divClass}
        draggable={this.props.editMode}
        onDragStart={(event: React.DragEvent): void => this.handleDragStart(event)}
      >
        <ReactTooltip delayShow={TOOLTIP_DELAY_MS}/>
        {this.renderDeviceName()}
        {this.renderStatusContainer()}
      </div >
    );
  }
}

