import React, { Component, Fragment, ReactNode } from "react";
import { Box, MenuItem, Typography } from "@material-ui/core";
import moment from "moment";
import { Maybe, Nullable } from "types/aliases";
import { LocationInfo } from "types/sensoanUiTypes";
import Localization from "data/localization-sensoan/Localization";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import SSelect from "components/styled-components/SSelect";
import DateTimePicker from "components/inputs/DateTimePicker";
import SButton from "components/styled-components/SButton";

interface Props {
  endTimeStamp: number;
  location: Nullable<LocationInfo>;
  metadata: Maybe<string>;
  onTimeSpanChange: (timeSpanInHours: number) => void;
  parentGroupIds: Maybe<string[]>;
  setEndTimeStamp: (timestamp: number) => void;
  timeSpanInHours: number;
  displayName?: string;
}

class SetsDetails extends Component<Props> {
  private text = Localization.getInstance().getDisplayText;

  private renderTimeSpanSelector(): ReactNode {
    const valueInfo = this.text("MeasurementSetConfiguration", "backwards");
    const localizedDay = this.text("MeasurementSetConfiguration", "day");
    const localizedMonth = this.text("MeasurementSetConfiguration", "month");

    return (
      <SSelect
        buttonWidthInRems={12}
        buttonText={this.props.timeSpanInHours !== undefined
          ? `${this.parseTimeSpanAndUnit(this.props.timeSpanInHours)} ${valueInfo}`
          : this.text("MeasurementSetConfigSelection", "select")}
        mr="1.5rem"
      >
        <MenuItem key="1h" onClick={(): void => this.handleTimeSpanChange(1, "hour")}>{`1h ${valueInfo}`}</MenuItem>
        <MenuItem key="6h" onClick={(): void => this.handleTimeSpanChange(6, "hour")}>{`6h ${valueInfo}`}</MenuItem>
        <MenuItem key="1d" onClick={(): void => this.handleTimeSpanChange(24, "hour")}>{`1${localizedDay} ${valueInfo}`}</MenuItem>
        <MenuItem key="2d" onClick={(): void => this.handleTimeSpanChange(2 * 24, "hour")}>{`2${localizedDay} ${valueInfo}`}</MenuItem>
        <MenuItem key="7d" onClick={(): void => this.handleTimeSpanChange(1 * 7 * 24, "hour")}>{`7${localizedDay} ${valueInfo}`}</MenuItem>
        <MenuItem key="1m" onClick={(): void => this.handleTimeSpanChange(1, "month")}>{`1${localizedMonth} ${valueInfo}`}</MenuItem>
        <MenuItem key="3m" onClick={(): void => this.handleTimeSpanChange(3, "month")}>{`3${localizedMonth} ${valueInfo}`}</MenuItem>
        <MenuItem key="6m" onClick={(): void => this.handleTimeSpanChange(6, "month")}>{`6${localizedMonth} ${valueInfo}`}</MenuItem>
      </SSelect>
    );
  }

  private renderParentGroupNames(): ReactNode {
    return (
      MeasurementSetRepository.getInstance().findParentGroupFromTree(this.props.parentGroupIds)?.map(({ setId, displayName }) => {
        return <Typography key={setId} variant="body1" color="textPrimary"> {displayName} </Typography>;
      })
    );
  }

  private renderThresholdNotification(value: number, compareValue: number): Maybe<JSX.Element> {
    if (value >= compareValue) {
      return (
        <Typography color="primary" style={{ marginLeft: "0.5rem" }}>
          {this.text("ErrorMessage", "noFutureTime")}
        </Typography>
      );
    }
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <Box display="flex" height="3rem" justifyContent="flex-start">
          <Box width="20%">
            <Typography variant="body1" color="textSecondary">
              {this.text("MeasurementSetConfiguration", "nameOfSet")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" color="textPrimary">
              {this.props.displayName}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" height="3rem" justifyContent="flex-start">
          <Box width="20%">
            <Typography variant="body1" color="textSecondary">
              {this.text("MeasurementSetConfiguration", "additionalInfo")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" color="textPrimary">
              {this.props.metadata}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" height="3rem" justifyContent="flex-start">
          <Box width="20%">
            <Typography variant="body1" color="textSecondary">
              {this.text("MeasJobsCreateView", "endTime")}
            </Typography>
          </Box>
          <DateTimePicker
            disableFuture
            dateInputId="end-time-date-input"
            dateInputWidth="12rem"
            setTimestamp={(timestamp): void => this.props.setEndTimeStamp(timestamp)}
            timestamp={this.props.endTimeStamp}
          >
            <SButton
              labelText={this.text("Actions", "useNow")}
              onClick={(): void => this.props.setEndTimeStamp(Date.now())}
              widthInRems={8}
            />
          </DateTimePicker>
        </Box>
        <Box display="flex" height="3rem" justifyContent="flex-start">
          <Box width="20%">
            <Typography variant="body1" color="textSecondary">
              {this.text("MeasurementSetConfiguration", "timeSpan")}
            </Typography>
          </Box>
          {this.renderTimeSpanSelector()}
          {this.renderThresholdNotification(this.props.endTimeStamp, Date.now())}
        </Box>
        <Box display="flex" height="3rem" justifyContent="flex-start">
          <Box width="20%">
            <Typography variant="body1" color="textSecondary">
              {this.text("Common", "location")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" color="textPrimary">
              {this.props.location && this.getLocationText(this.props.location)}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" height="3rem" justifyContent="flex-start">
          <Box width="20%">
            <Typography variant="body1" color="textSecondary">
              {this.text("MeasurementSetConfiguration", "group")}
            </Typography>
          </Box>
          <Box>
            {this.renderParentGroupNames()}
          </Box>
        </Box>
      </Fragment>
    );
  }

  private getLocationText(locationInfo: LocationInfo): string {
    if (typeof locationInfo === "function") {
      return locationInfo();
    } else {
      return locationInfo.title;
    }
  }

  private handleTimeSpanChange(num: number, unit: "hour" | "month"): void {
    if (unit === "month") {
      const subtractedDate = moment(this.props.endTimeStamp).subtract(num, "M");
      const durationAsHours = moment.duration(moment(this.props.endTimeStamp).diff(subtractedDate)).asHours();
      this.props.onTimeSpanChange(durationAsHours);
    } else {
      this.props.onTimeSpanChange(num);
    }
  }

  private parseTimeSpanAndUnit(timeSpanInHours: number): string {
    if (timeSpanInHours < 24) {
      return `${timeSpanInHours}h`;
    } else if (timeSpanInHours >= 24 && timeSpanInHours <= 168) {
      return `${timeSpanInHours / 24}${this.text("MeasurementSetConfiguration", "day")}`;
    } else {
      return `${(timeSpanInHours / 24 / 30).toFixed()}${this.text("MeasurementSetConfiguration", "month")}`;
    }
  }

}

export default SetsDetails;
