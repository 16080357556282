import React, { Component, Fragment, ReactNode } from "react";
import { MeasurementJob } from "data/types/measurementJobTypes";
import Localization from "data/localization-sensoan/Localization";
import MeasurementJobRepository from "data/data-storage/MeasurementJobRepository";
import SButton from "components/styled-components/SButton";

interface Props {
  job: MeasurementJob;
}

interface State {
}

class StartStopButton extends Component<Props, State> {

  private jobRepo = MeasurementJobRepository.getInstance();

  private renderStartButton(): ReactNode {
    return (
      <SButton
        fontWeight="regular"
        labelText={Localization.getInstance().getDisplayText("MeasJobsDataView", "startAction")}
        mr={4}
        onClick={(): void => { this.jobRepo.start(this.props.job.setId, this.props.job.jobId); }}
        widthInRems={6}
      />
    );
  }

  private renderStopButton(): ReactNode {
    return (
      <SButton
        fontWeight="regular"
        labelText={Localization.getInstance().getDisplayText("MeasJobsDataView", "endAction")}
        mr={4}
        onClick={(): void => { this.jobRepo.stop(this.props.job.setId, this.props.job.jobId); }}
        widthInRems={6}
      />
    );
  }

  public render(): ReactNode {
    const startTs = this.props.job.startTs;
    const endTs = this.props.job.endTs;

    if (endTs !== undefined && endTs !== null) {
      // endTs is set --> start/stop not needed
      return <Fragment />;
    } else if (startTs !== undefined && startTs !== null) {
      // startTs is set, endTs is not set --> stop button needed
      return this.renderStopButton();
    } else {
      // neither ts is set --> start button needed
      return this.renderStartButton();
    }
  }
}

export default StartStopButton;
