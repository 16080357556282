import tagFaces from "assets/device/icons/tagFaces.svg";
import { RuuviSensoanHWState } from "./RuuviSensoanHWState";
import { RuuviSensoanHWStateProperties } from "./RuuviSensoanHWStateProperties";
import AWSThing from "data/device/AWSThing";
import AWSBackend from "data/backend/AWSBackend";
import { DeviceParameters } from "data/device/Device";

export class RuuviSensoanHW extends AWSThing<RuuviSensoanHWState> {
  public static type = "RuuviSensoan";

  public constructor(backend: AWSBackend, params: DeviceParameters) {
    super(RuuviSensoanHW.type, backend, params);
  }

  public getIcon(): string {
    return tagFaces;
  }

  public createState(timestamp?: number, reported?: Partial<RuuviSensoanHWStateProperties>, desired?: Partial<RuuviSensoanHWStateProperties>):
  RuuviSensoanHWState {
    return new RuuviSensoanHWState(this.getId(), new RuuviSensoanHWStateProperties(reported ?? {}), new RuuviSensoanHWStateProperties(desired ?? {}), timestamp);
  }

}
