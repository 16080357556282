import React, { ReactNode } from "react";
import { Box, List, TextField, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Loader from "components/ui/loader";
import { RuuviGWHW } from "client/devices/RuuviGWHW/RuuviGWHW";
import { RuuviGWHWWhitelist } from "client/devices/RuuviGWHW/RuuviGWHWStateProperties";
import SIconButton from "components/styled-components/SIconButton";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import SensoanBackend from "data/backend/SensoanBackend";
import SettingsListItem from "../settings-list-item";
import SettingsControls from "../settings-page-general/components/settings-controls";
import { StringResponse } from "generated/gqlDevice";

interface Props {
  device: RuuviGWHW;
  closeSettings: () => void;
}

interface State {
  whitelist: RuuviGWHWWhitelist;
  newDevice: string;
  error?: boolean;
  loading?: boolean;
}

class SettingsPageWhitelist extends React.Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      whitelist: props.device.getState()?.whitelist ?? [],
      newDevice: "",
    };
  }

  private handleCancel = (): void => {
    this.props.device.getState()?.revert();
    this.props.closeSettings();
  };

  private handleAddToWhitelist = async (): Promise<void> => {
    const deviceId = this.props.device.getId();
    const ruuviId = this.state.newDevice;
    const validateResp = await SensoanBackend.getInstance().devicesWhitelistValidate({ deviceId, ruuviId });

    console.log("WhitelistValidate response: " + validateResp);

    if (validateResp !== StringResponse.NotAuthorized) {
      const whitelist: RuuviGWHWWhitelist = [...(this.state.whitelist)];
      const deviceState = this.props.device.getState();

      if (deviceState !== null) {
        whitelist.push(this.state.newDevice);
        deviceState.whitelist = [...whitelist];
        await this.props.device.getState()?.store();
        this.setState({ whitelist, newDevice: "" });
      }
    } else {
      console.error("unauthorized to add ruuvi to whitelist");
      this.setState({ newDevice: "" });
    }
  };

  private handleRemoveFromWhitelist = async (index: number): Promise<void> => {
    const whitelist: RuuviGWHWWhitelist = [...(this.state.whitelist)];
    whitelist.splice(index, 1);
    const deviceState = this.props.device.getState();

    if (deviceState !== null) {
      deviceState.whitelist = [...whitelist];
    }

    await this.props.device.getState()?.store();
    this.setState({ whitelist });
  };

  private validateAddToList = (): boolean => {
    // MAC format is xx:xx:xx:xx:xx:xx
    if (this.state.newDevice.length === (6 * 2 + 5)) {
      const found = this.state.whitelist.find((item) => item === this.state.newDevice);

      if (found === undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  private renderWhitelistDialog(): ReactNode {
    return (
      <List>
        <SettingsListItem label="Whitelisted">
          <div>
            {this.state.whitelist.map((deviceId, index) => {
              return (
                <Box key={deviceId} display="flex" justifyContent="center" alignItems="center">
                  <Typography>{deviceId}</Typography>
                  <SIconButton onClick={async (): Promise<void> => this.handleRemoveFromWhitelist(index)}>
                    <SSvgIcon color={SSvgIconColorProps.orangeGradient} iconComponent={DeleteIcon}/>
                  </SIconButton>
                </Box>
              );
            })}
          </div>
        </SettingsListItem>
        <SettingsListItem label="Add to list">
          <TextField
            value={this.state.newDevice}
            onChange={({ target }): void => this.setState({ newDevice: target.value })}
          />
        </SettingsListItem>
        <SettingsControls
          submitButtonLabel="Add"
          onSave={async (): Promise<void> => this.handleAddToWhitelist()}
          submitButtonDisabled={!this.validateAddToList()}
          onCancel={this.handleCancel}
          cancelButtonLabel="Cancel"
        />
      </List>
    );
  }

  private renderErrorDialog(): ReactNode {
    return (
      <Box my="2rem">
        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" fontSize="0.8rem">
          Creating upgrade job failed
        </Box>
        <SettingsControls submitButtonLabel="Ok" onSave={(): void => this.setState({ error: false })} onCancel={this.handleCancel} cancelButtonLabel="Cancel"/>
      </Box>
    );
  }

  private renderLoader(): ReactNode {
    return (
      <Box my="2rem" width="100%" display="flex" justifyContent="center" alignItems="center">
        <Loader/>
      </Box>
    );
  }

  public render(): ReactNode {
    if (this.state.loading) {
      return this.renderLoader();
    } else if (this.state.error) {
      return this.renderErrorDialog();
    } else {
      return this.renderWhitelistDialog();
    }
  }
}

export default SettingsPageWhitelist;
