import React, { Component, ReactNode } from "react";
import { Box, Typography } from "@material-ui/core";
import AddAlarmIcon from "@material-ui/icons/AddAlarm";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import Localization from "data/localization-sensoan/Localization";
import { ConditionProperties, EventTriggerDbEntry, TriggerOperator } from "data/types/eventTypes";
import { buildEmptySensorSpecificEventTriggerConfig, buildEventMetadataDescription, buildEventTriggerCondition,
  filterSensorSpecificEventTriggerDbEntry, parseEventTriggerValue } from "data/utils/eventUtils";
import SensoanBackend from "data/backend/SensoanBackend";
import { IEventMetadata } from "data/clientSpecific/Event";
import EventsRepository from "data/data-storage/EventsRepository";
import { Nullable } from "types/aliases";
import { hasKey } from "utils/functions";
import IoTDataRow from "components/layout/iot-data-row/IoTDataRow";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import SIconButton from "components/styled-components/SIconButton";
import AlarmLimitsEdit from "./AlarmLimitsEdit";
import SensorDataPopup from "./SensorDataPopup";

interface Props {
  dataItem: SensorListItem;
  hideIotDataRowDivider?: boolean;
}

interface State {
  alarmTrigger: Nullable<EventTriggerDbEntry>;
  alarmTriggerEditAnchorEl: Nullable<HTMLButtonElement>;
  loading: boolean;
  sensorDataPopupAnchorEl: Nullable<HTMLButtonElement>;
}

export interface SensorListItem {
  deviceDisplayName: string;
  deviceId: string;
  reading: number | string | boolean;
  sensor: string;
  metadata?: string;
}

class SensorListDataRow extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      alarmTrigger: null,
      alarmTriggerEditAnchorEl: null,
      loading: false,
      sensorDataPopupAnchorEl: null,
    };
  }

  public closeEditPopover(): void {
    this.setState({ alarmTriggerEditAnchorEl: null });
  }

  public async updateAlarmTrigger(min: Nullable<number>, max: Nullable<number>, sensorListItem: SensorListItem): Promise<void> {
    // assertion: method is called only if this.state.alarmTrigger !== null
    const { rules, triggerId } = this.state.alarmTrigger as EventTriggerDbEntry;

    if (hasKey(rules[0].event.params, "eventId")) {
      rules[0].conditions.any = this.getTriggerConditions(min, max);
      const eventMetadata: IEventMetadata = { eventId: rules[0].event.params.eventId, description: buildEventMetadataDescription(rules[0].conditions.any) };
      const triggerResponse = await SensoanBackend.getInstance().eventsTriggerRulesAdd(triggerId, sensorListItem.deviceId, rules);
      if (triggerResponse === "error") throw new Error("Updating existing alarm trigger failed: saving the trigger failed");
      const metadataResponse = await EventsRepository.instance.addEventMetadata(eventMetadata);
      if (metadataResponse === "error") throw new Error("Updating existing alarm trigger failed: saving event metadata failed");
    } else {
      throw new Error("Updating existing alarm trigger failed: eventId was not found for the trigger");
    }
  }

  private async openAlarmTriggerEditPopover(event: React.MouseEvent<HTMLButtonElement>, sensorListItem: SensorListItem): Promise<void> {
    this.setState({ alarmTriggerEditAnchorEl: event.currentTarget, loading: true });
    const dbData = await SensoanBackend.getInstance().eventsTriggerRulesList(sensorListItem.deviceId);
    const alarmTrigger = filterSensorSpecificEventTriggerDbEntry(dbData, sensorListItem.deviceId, sensorListItem.sensor);
    this.setState({ alarmTrigger, loading: false });
  }

  public async createAlarmTrigger(min: Nullable<number>, max: Nullable<number>, sensorListItem: SensorListItem): Promise<void> {
    const { trigger, metadata } = buildEmptySensorSpecificEventTriggerConfig(sensorListItem.deviceId, sensorListItem.sensor);
    trigger.rules[0].conditions.any = this.getTriggerConditions(min, max);
    const eventMetadata: IEventMetadata = { eventId: metadata.eventId, description: buildEventMetadataDescription(trigger.rules[0].conditions.any) };
    const triggerResponse = await SensoanBackend.getInstance().eventsTriggerRulesAdd(trigger.triggerId, sensorListItem.deviceId, trigger.rules);
    if (triggerResponse === "error") throw new Error("Creating alarm trigger failed: saving the trigger failed");
    const metadataResponse = await EventsRepository.instance.addEventMetadata(eventMetadata);
    if (metadataResponse === "error") throw new Error("Updating existing alarm trigger failed: saving event metadata failed");
  }

  public async deleteAlarmTrigger(): Promise<void> {
    // assertion: method is called only if this.state.alarmTrigger !== null
    const { rules, triggerId } = this.state.alarmTrigger as EventTriggerDbEntry;

    if (hasKey(rules[0].event.params, "eventId")) {
      const triggerResponse = await SensoanBackend.getInstance().eventsTriggerRulesDelete(triggerId);
      if (triggerResponse === "error") throw new Error("Deleting alarm trigger failed");
      const metadataResponse = await EventsRepository.instance.deleteEventMetadata(rules[0].event.params.eventId);
      if (metadataResponse === "error") throw new Error("Deleting alarm trigger failed: deleting event metadata failed");
    } else {
      throw new Error("Deleting alarm trigger failed: eventId was not found for the trigger");
    }
  }

  public async saveAlarmTrigger(min: Nullable<number>, max: Nullable<number>, sensorListItem: SensorListItem): Promise<void> {
    this.closeEditPopover();

    if (this.state.alarmTrigger !== null && (min !== null || max !== null)) {
      await this.updateAlarmTrigger(min, max, sensorListItem);
    } else if (this.state.alarmTrigger === null && (min !== null || max !== null)) {
      await this.createAlarmTrigger(min, max, sensorListItem);
    } else if (this.state.alarmTrigger !== null && (min === null && max === null)) {
      await this.deleteAlarmTrigger();
    }
    this.setState({
      alarmTrigger: null,
      loading: false,
    });
  }

  private getTriggerConditions(min: Nullable<number>, max: Nullable<number>): ConditionProperties[] {
    const conditions: ConditionProperties[] = [];

    if (min !== null) {
      conditions.push(buildEventTriggerCondition(this.props.dataItem.sensor, min, TriggerOperator.MIN));
    }

    if (max !== null) {
      conditions.push(buildEventTriggerCondition(this.props.dataItem.sensor, max, TriggerOperator.MAX));
    }

    return conditions;
  }

  /* TODO: check if trigger exists and render text conditionally */
  private getAlarmTriggerButtonTooltipText(): string {
    return this.text("DeviceWindow", "editAlarmTrigger");
  }

  // Sensor
  // Reading
  // icon button for data popup
  // icon button for alarm limits
  public render(): ReactNode {
    const { dataItem } = this.props;
    return (
      <IoTDataRow dividerFadeEffect hideDivider={this.props.hideIotDataRowDivider} justifyContent="space-between">
        {this.state.sensorDataPopupAnchorEl !== null
          ? <SensorDataPopup
            anchorEl={this.state.sensorDataPopupAnchorEl}
            title={dataItem.deviceDisplayName}
            sensorName={dataItem.sensor}
            deviceId={dataItem.deviceId}
            close={(): void => this.setState({ sensorDataPopupAnchorEl: null })}
          />
          : null
        }
        <Box width="25%">
          <Typography variant="body1" color="textPrimary">
            {dataItem.sensor}
          </Typography>
        </Box>
        <Box width="25%">
          <Typography variant="body1" color="textSecondary">
            {dataItem.reading.toString()}
          </Typography>
        </Box>
        <Box width="25%" display="flex" justifyContent="space-between">
          <Box width="50%">
            <SIconButton
              onClick={({ currentTarget } : React.MouseEvent<HTMLButtonElement, MouseEvent>): void => this.setState({ sensorDataPopupAnchorEl: currentTarget })}
              tooltipText={this.text("DeviceWindow", "openSensorDataPopup")}
            >
              <SSvgIcon color={SSvgIconColorProps.blueGradient} iconComponent={ShowChartIcon}/>
            </SIconButton>
          </Box>
          <Box width="50%">
            <SIconButton
              onClick={(event: React.MouseEvent<HTMLButtonElement>): Promise<void> => this.openAlarmTriggerEditPopover(event, dataItem)}
              tooltipText={this.getAlarmTriggerButtonTooltipText()}
            >
              <SSvgIcon color={SSvgIconColorProps.orangeGradient} iconComponent={AddAlarmIcon}/>
            </SIconButton>
          </Box>
        </Box>
        {this.state.alarmTriggerEditAnchorEl !== null
          ? <AlarmLimitsEdit
            title={this.text("DevicesView", "limitsEditTitle")}
            subTitle={`${dataItem.deviceDisplayName} / ${dataItem.sensor}`}
            min={this.state.alarmTrigger ? parseEventTriggerValue(this.state.alarmTrigger, TriggerOperator.MIN) : null}
            max={this.state.alarmTrigger ? parseEventTriggerValue(this.state.alarmTrigger, TriggerOperator.MAX) : null}
            latestReading={dataItem.reading}
            anchorEl={this.state.alarmTriggerEditAnchorEl}
            loading={this.state.loading}
            saveAction={(min: Nullable<number>, max: Nullable<number>): Promise<void> => this.saveAlarmTrigger(min, max, dataItem)}
            cancelAction={(): void => this.closeEditPopover()}
          />
          : null
        }
      </IoTDataRow>
    );
  }
}

export default SensorListDataRow;
