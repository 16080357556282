import React, { Component } from "react";
import { Theme, withStyles, withTheme } from "@material-ui/core/styles";
import { Box, Button, ButtonProps, Tooltip, TooltipProps, Typography } from "@material-ui/core";
import { Maybe } from "types/aliases";
import { AriaHaspopupProps, CustomSpacingProps } from "types/sensoanUiTypes";
import SSvgIcon, { SSvgIconColorProps } from "./SSvgIcon";
import PathsSensoan from "data/paths/PathsSensoan";

interface Props {
  ariaControls?: string;
  ariaHaspopup?: AriaHaspopupProps;
  ariaId?: string;
  buttonComponent?: React.ElementType;
  /* Use this to render a button without background */
  textButton?: boolean;
  color?: "secondary";
  disabled?: boolean;
  endIcon?: React.ElementType;
  fontWeight?: "light" | "regular" | "medium";
  iconColor?: SSvgIconColorProps | React.CSSProperties["fill"];
  labelText?: string;
  linkTo?: PathsSensoan;
  smallSize?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  startIcon?: React.ElementType;
  theme: Theme;
  tooltipId?: string;
  widthInRems?: number;
  m?: CustomSpacingProps;
  mt?: CustomSpacingProps;
  mr?: CustomSpacingProps;
  mb?: CustomSpacingProps;
  ml?: CustomSpacingProps;
  p?: CustomSpacingProps;
  pt?: CustomSpacingProps;
  pr?: CustomSpacingProps;
  pb?: CustomSpacingProps;
  pl?: CustomSpacingProps;
}
interface State {
  buttonLabelClientWidth: Maybe<number>;
  buttonLabelScrollWidth: Maybe<number>;
  StyledButton: React.ComponentType<ButtonProps>;
  StyledTooltip: React.ComponentType<TooltipProps>;
}

class SButton extends Component<Props, State> {

  private buttonLabelTextRef = React.createRef<HTMLSpanElement>();

  public constructor(props: Props) {
    super(props);
    this.state = {
      buttonLabelClientWidth: undefined,
      buttonLabelScrollWidth: undefined,
      StyledButton: this.getStyledButton(),
      StyledTooltip: this.getStyledTooltip(),
    };
  }

  public componentDidMount(): void {
    this.setState({
      buttonLabelClientWidth: this.buttonLabelTextRef.current?.clientWidth,
      buttonLabelScrollWidth: this.buttonLabelTextRef.current?.scrollWidth,
    });
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.theme !== prevProps.theme) {
      this.setState({
        StyledTooltip: this.getStyledTooltip(),
        StyledButton: this.getStyledButton(),
      });
    }
  }

  public render(): JSX.Element {
    // TODO: create a StyledSvgIcon component and move all styling options there
    // const svgIconStyle = { fontSize: "1.25rem" };
    const StartIconElement = this.props.startIcon && <SSvgIcon color={this.props.iconColor ?? SSvgIconColorProps.primary} iconComponent={this.props.startIcon} />;
    const EndIconElement = this.props.endIcon && <SSvgIcon color={this.props.iconColor ?? SSvgIconColorProps.primary} iconComponent={this.props.endIcon} />;
    const ButtonElement = (
      <this.state.StyledButton
        aria-describedby={this.props.ariaId}
        aria-controls={this.props.ariaControls}
        aria-haspopup={this.props.ariaHaspopup}
        color={this.props.color ?? "primary"}
        // component prop is lost with withStyles HOC regardless of the cast as typeof Button -> ts has to be ignored
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        component={this.props.buttonComponent}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        to={this.props.linkTo}
        variant={!this.props.textButton ? "contained" : undefined}>
        <Box
          alignItems="center"
          component="span"
          display="flex"
          justifyContent={this.props.startIcon || this.props.endIcon ? "space-between" : "center"}>
          {StartIconElement}
          <Typography
            display="block"
            innerRef={this.buttonLabelTextRef}
            style={this.getButtonLabelStyle()}
            variant={this.props.smallSize ? "caption" : "button"}>
            {this.props.labelText}
          </Typography>
          {EndIconElement}
        </Box>
      </this.state.StyledButton>
    );

    return (
      <Box m={this.props.m} mt={this.props.mt} mr={this.props.mr} mb={this.props.mb} ml={this.props.ml}
        p={this.props.p} pt={this.props.pt} pr={this.props.pr} pb={this.props.pb} pl={this.props.pl}
        width={this.props.widthInRems && `${this.props.widthInRems}rem`}
        style={this.getWrappingBoxSpacing()} // reading margin from prop does not work for some reason, this is a temporary fix for it
      >
        {this.isTooltipNeeded()
          ?
          <this.state.StyledTooltip
            arrow
            id={`${this.props.labelText}-button-tooltip`}
            placement="bottom-end"
            title={this.props.labelText ?? ""}>
            {ButtonElement}
          </this.state.StyledTooltip>
          :
          ButtonElement}
      </Box>
    );
  }

  private getLabelTextColor(): string {
    const { theme, color, textButton } = this.props;
    const isContainedPrimaryColorButton = !color && !textButton;

    if (isContainedPrimaryColorButton) {
      return theme.palette.common.white;
    } else {
      return theme.palette.text.primary;
    }
  }

  private getStyledButton(): React.ComponentType<ButtonProps> {
    return withStyles({
      root: {
        borderRadius: "36px",
        border: "0px",
        height: this.props.smallSize ? "1.875rem" : "2.5rem",
        padding: this.props.smallSize ? "7px 14px" : "9px 18px",
        minWidth: this.props.smallSize ? "3.75rem" : "5rem",
        width: this.props.textButton ? "auto" : "100%",
      },
      label: {
        display: "initial",
        color: this.getLabelTextColor(),
      },
      containedPrimary: {
        background: this.props.theme.palette.gradients.blue,
      },
      containedSecondary: {
        background: this.props.theme.palette.gradients.grey,
      },
      textPrimary: {
        /* use same ripple color as in icon button */
        color: this.props.theme.palette.type === "dark" ? "#fff" : "#0000008a",
        /* use same background color as in icon button  */
        "&:hover": {
          backgroundColor: this.props.theme.palette.type === "dark" ? "#ffffff14" : "#0000000a",
        },
      },
      disabled: {
        background: this.props.theme.palette.action.disabled,
      },
    })(Button) as typeof Button;
  }

  private getStyledTooltip(): React.ComponentType<TooltipProps> {
    return withStyles({
      popper: {
        opacity: 0.9,
      },
      tooltip: {
        display: "flex",
        color: this.props.theme.palette.text.secondary,
        alignItems: "center",
        borderRadius: "9px",
        fontSize: this.props.theme.typography.body2.fontSize,
        height: "2rem",
        padding: "13px 20px",
      },
    })(Tooltip);
  }

  private getButtonLabelStyle(): React.CSSProperties {
    return {
      // fontSize: this.props.smallSize ? "0.75rem" : undefined,
      fontWeight: this.props.fontWeight ? this.getButtonLabelFontWeight() : undefined,
      overflow: "hidden",
      paddingLeft: this.props.startIcon ? "10px" : undefined,
      paddingRight: this.props.endIcon ? "10px" : undefined,
      textOverflow: "ellipsis",
      textTransform: "none",
      whiteSpace: "nowrap",
    };
  }

  private getButtonLabelFontWeight(): Maybe<number> {
    switch (this.props.fontWeight) {
      case "light":
        return this.props.theme.typography.fontWeightLight as number;

      case "regular":
        return this.props.theme.typography.fontWeightRegular as number;

      case "medium":
        return this.props.theme.typography.fontWeightMedium as number;
    }
  }

  private getWrappingBoxSpacing(): React.CSSProperties {
    const spacing = 4;
    return {
      margin: `${this.props.m as number * spacing}px`,
      marginBottom: `${this.props.mb as number * spacing}px`,
      marginLeft: `${this.props.ml as number * spacing}px`,
      marginRight: `${this.props.mr as number * spacing}px`,
      marginTop: `${this.props.mt as number * spacing}px`,
      padding: `${this.props.p as number * spacing}px`,
      paddingBottom: `${this.props.pb as number * spacing}px`,
      paddingLeft: `${this.props.pl as number * spacing}px`,
      paddingRight: `${this.props.pr as number * spacing}px`,
      paddingTop: `${this.props.pt as number * spacing}px`,
    };
  }

  private isTooltipNeeded(): Maybe<boolean> {
    if (this.state.buttonLabelClientWidth && this.state.buttonLabelScrollWidth) {
      const isButtonLabelTextOverFlowing = this.state.buttonLabelClientWidth < this.state.buttonLabelScrollWidth;
      return isButtonLabelTextOverFlowing;
    } else {
      return undefined;
    }
  }
}

export default withTheme(SButton);
