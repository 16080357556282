import { Box, Popover, TextField, Theme, Typography, withTheme } from "@material-ui/core";
import SButton from "components/styled-components/SButton";
import Localization from "data/localization-sensoan/Localization";
import React, { Component } from "react";
import { Nullable } from "types/aliases";

interface Props {
  title: string;
  commentText: string;
  anchorEl: Nullable<HTMLButtonElement>;
  saveAction: (text: string) => void;
  cancelAction: () => void;
  theme: Theme;
}

interface State {
  newComment: string;
}

class CommentEdit extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      newComment: props.commentText,
    };
  }

  public render(): JSX.Element {
    const open = this.props.anchorEl !== null ? true : false;
    return (
      <Popover
        onClose={(): void => this.props.cancelAction()}
        anchorEl={this.props.anchorEl}
        open={open}
      >
        <Box p={4} display="flex" flexWrap="wrap" justifyContent="center" width="20rem" bgcolor={this.props.theme.palette.background.default}>
          <Typography variant="h6">{this.props.title}</Typography>
          <Box mt={4} mb={4} width="100%">
            <TextField
              fullWidth={true}
              variant="outlined"
              value={this.state.newComment}
              onChange={(event): void => this.setState({ newComment: event.currentTarget.value })}
            />
          </Box>
          <Box width="100%" display="flex">
            <Box ml="auto" mr={1}>
              <SButton
                labelText={Localization.getInstance().getDisplayText("CommentAdd", "save")}
                onClick={(): void => this.props.saveAction(this.state.newComment)}
              />
            </Box>
            <Box ml={1} mr="auto">
              <SButton
                color="secondary"
                labelText= {Localization.getInstance().getDisplayText("CommentAdd", "cancel")}
                onClick={(): void => this.props.cancelAction()}
              />
            </Box>
          </Box>
        </Box>
      </Popover>
    );
  }
}

export default withTheme(CommentEdit);
