import React, { Component } from "react";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import Organization from "data/organization/Organization";
import { isError } from "data/utils/ErrorUtils";
import PolicyGroup from "data/organization/PolicyGroup";
import { isValidEmail } from "utils/validation";
import Loader from "components/ui/loader";
import DropdownSelection from "components/ui/dropdown-selection";
import ErrorDialog from "components/ui/error-dialog";
import accessControlled from "components/access-control/access-controlled";
import Localization from "data/localization-sensoan/Localization";

interface Props {
  organization: Organization;
  availablePolicyGroups: PolicyGroup[];
}

interface State {
  loading: boolean;
  selectedPolicy?: number;
  username?: string;
  errorMsg?: string;
}

export class NewUserForm extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      selectedPolicy: 0,
    };
  }

  private isValidUsername(): boolean {
    return !!this.state.username && isValidEmail(this.state.username);
  }

  private isValidPolicyGroupSelection(): boolean {
    return this.state.selectedPolicy != null;
  }

  private handleClickNewUser = async (): Promise<void> => {
    if (!this.isValidUsername()) {
      this.setState({ errorMsg: this.text("AdminView", "invalidEmailAddress") });
      return;
    }

    if (!this.isValidPolicyGroupSelection()) {
      this.setState({ errorMsg: this.text("AdminView", "policyGroupMustBeSelected") });
      return;
    }

    const policyGroup = this.props.availablePolicyGroups[this.state.selectedPolicy!];

    this.setState({ loading: true });

    try {
      const result = await this.props.organization.createUser({
        username: this.state.username!,
        resendInvitation: false,
      });

      await policyGroup.addUser(result);
    } catch (error) {
      let reason = this.text("AdminView", "failedToCreateUser");

      if (isError(error) && error.message.search("account already exists") >= 0) {
        reason = this.text("AdminView", "userAlreadyExists");
      }
      this.setState({
        errorMsg: reason,
      });
    } finally {
      this.setState({
        username: undefined,
        loading: false,
        selectedPolicy: 0,
      });
    }
  };

  public render(): JSX.Element {
    return (
      <div className="new-user-form">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{this.text("AdminView", "createNewUser")}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <TextField
                  fullWidth={true}
                  label={this.text("Common", "email")}
                  variant="outlined"
                  type="email"
                  value={this.state.username ?? ""}
                  onChange={(event): void =>
                    this.setState({ username: event.currentTarget.value })}
                />
              </TableCell>
              <TableCell align="center">
                <DropdownSelection
                  label={this.text("AdminView", "policyGroup")}
                  onSelect={(index): void => this.setState({ selectedPolicy: index })}
                  selectionList={this.props.availablePolicyGroups.map((pg) => ({ key: pg.getId(), label: pg.getName() }))}
                  currentSelection={this.state.selectedPolicy}
                />
              </TableCell>
              <TableCell align="right">
                {
                  this.state.loading
                    ? <Loader/>
                    : <Button
                      onClick={this.handleClickNewUser}
                      variant="contained"
                      color="primary"
                      disabled={!this.isValidPolicyGroupSelection() || ! this.isValidUsername()}
                    >
                      {this.text("Actions", "create")}
                    </Button>
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ErrorDialog
          errorMsg={this.state.errorMsg}
          onClose={(): void => this.setState({ errorMsg: undefined })}
        />
      </div>
    );
  }
}

export default accessControlled(NewUserForm, ["usersCreate", "policyGroupsUsersAdd"]);
