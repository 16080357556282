import { IconStatus, IconTypes, MapLocation, MapMarkerData } from "data/map/MapLink";
import DeviceRepository from "data/data-storage/DeviceRepository";
import Data from "data/data/Data";
import Device from "data/device/Device";
import { Nullable } from "types/aliases";

export function checkDeviceActivity(device: Device): boolean {
  const latestData = device.getLatestDataSync();
  let value;

  // TODO: Should we have device specific keepalive thresholds?
  const keepalive = process.env.REACT_APP_DEVICE_KEEPALIVE;

  if (!keepalive) {
    console.error("Device keepalive time not specified, using 60min");
  }

  if (latestData && latestData.timestamp > Date.now() - parseInt(keepalive ?? "60", 10) * 60 * 1000) {
    value = true;
  } else {
    value = false;
  }
  return value ?? false;
}

export function getLocation(data: Nullable<Data>): Nullable<MapLocation> {
  if (data === null || data["latitude"] === undefined || data["longitude"] === undefined) {
    return null;
  } else {
    if (typeof(data["latitude"]) === "number" && typeof (data["longitude"]) === "number") {
      return { lat: data["latitude"], lng: data["longitude"] };
    } else {
      console.error(`Unexpected type for "latitude" and/or "longitude" in ${data}`);
      return null;
    }
  }
}

export function getMapMarkerDataForDevice(device: Device, selected: () => IconStatus): Nullable<MapMarkerData> {
  const location = getLocation(device.getLatestDataSync());

  if (location !== null) {
    return {
      id: device.getId(),
      type: IconTypes.device,
      status: selected,
      location: (): MapLocation => location,
    };
  } else {
    return null;
  }
}

/**
 * @param filter An optional array of Devices for which to get mapMarkerData.
 */
export function getMapMarkerDataForAllDevices(filter?: Device[]): MapMarkerData[] {
  const devices = filter !== undefined ? filter : DeviceRepository.getInstance().getDevices();
  return devices.reduce<MapMarkerData[]>((acc, curr) => {
    const mapDataItem = getMapMarkerDataForDevice(curr, () => IconStatus.selected);
    if (mapDataItem) acc.push(mapDataItem);
    return acc;
  }, []);
}
