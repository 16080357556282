import React, { Component, Fragment, ReactNode } from "react";
import { Box, Drawer, DrawerProps } from "@material-ui/core";
import { Theme, withStyles, withTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AppViews from "client/AppViews";
import { AppLayoutMode } from "types/sensoanUiTypes";
import AppMenu from "components/main-menu/AppMenu";
import MapWrapper from "components/map/MapWrapper";
import SSvgIcon, { SSvgIconColorProps } from "./SSvgIcon";
import SIconButton from "./SIconButton";

export const DRAWER_WIDTH_EXPANDED = 400;
export const DRAWER_WIDTH_COLLAPSED = 40;

interface Props {
  appLayoutMode: AppLayoutMode;
  expanded: boolean;
  onAppLayoutModeChange: () => void;
  theme: Theme;
  toggleExpanded: () => void;
}

interface State {
  StyledDrawer: React.ComponentType<DrawerProps>;
}

class SDrawer extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      StyledDrawer: this.getStyledDrawer(),
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.theme !== prevProps.theme || this.props.expanded !== prevProps.expanded) {
      this.setState({ StyledDrawer: this.getStyledDrawer() });
    }
  }

  private renderTogglerButton(isExpanded: boolean): ReactNode {
    return (
      <SIconButton
        backGroundColor="themeDefaultBg"
        inlineStyle={{ minHeight: "40px", minWidth: "40px" }}
        onClick={this.props.toggleExpanded}
        shadow
      >
        <SSvgIcon color={SSvgIconColorProps.primary} iconComponent={isExpanded ? ChevronLeftIcon : ChevronRightIcon} size="3rem"/>
      </SIconButton>
    );
  }

  private renderDrawerContent(): ReactNode {
    const isExpanded = this.props.expanded;
    return (
      <Fragment>
        <Box height={this.getAppMenuHeight()}>
          {isExpanded
              &&
            <AppMenu appLayoutMode={this.props.appLayoutMode} onAppLayoutModeChange={this.props.onAppLayoutModeChange} />
          }
        </Box>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="flex-end"
          mr={this.props.expanded ? "2rem" : "1.5rem"}
          position="relative"
          py="16px"
          style={this.getIconButtonContainerTransition()}
        >
          {this.renderTogglerButton(isExpanded)}
        </Box>
        <Box height="400px">
          {(isExpanded && this.props.appLayoutMode === AppLayoutMode.dataWithDrawer)
            &&
            <MapWrapper
              onAppLayoutModeChange={this.props.onAppLayoutModeChange}
              appLayoutMode={this.props.appLayoutMode}
            />
          }
        </Box>
      </Fragment>
    );
  }

  private getAppMenuHeight(): string {
    const appMenuItemHeight = "2.5rem";
    const appMenuItemVerticalPadding = "32px";
    const dividerHeight = "2px";
    return `calc(${AppViews.length} * (${appMenuItemHeight} + ${appMenuItemVerticalPadding} + ${dividerHeight}))`;
  }

  public render(): JSX.Element {
    return (
      <this.state.StyledDrawer variant="permanent">
        {this.renderDrawerContent()}
      </this.state.StyledDrawer>
    );
  }

  private getStyledDrawer(): React.ComponentType<DrawerProps> {
    return withStyles({
      paper: {
        backgroundColor: this.props.theme.palette.background.default,
        justifyContent: "space-between",
        paddingTop: this.props.theme.mixins.toolbar.height,
        overflow: this.props.expanded ? "auto" : "initial",
        maxWidth: this.props.expanded ? `${DRAWER_WIDTH_EXPANDED}px` : `${DRAWER_WIDTH_COLLAPSED}px`,
        width: "100%",
        transition: this.getPaperTransition(),
      },
    })(Drawer) as (typeof Drawer);
  }

  private getPaperTransition(): string {
    return this.props.theme.transitions.create("width", {
      easing: this.props.theme.transitions.easing.sharp,
      duration: this.props.expanded
        ?
        this.props.theme.transitions.duration.leavingScreen
        :
        this.props.theme.transitions.duration.enteringScreen,
    });
  }

  private getIconButtonContainerTransition(): React.CSSProperties {
    const timingFunc = this.props.theme.transitions.easing.sharp;
    const duration = (
      this.props.expanded
        ?
        this.props.theme.transitions.duration.leavingScreen
        :
        this.props.theme.transitions.duration.enteringScreen
    );
    return {
      transition: `translate ${duration}ms ${timingFunc}`,
      transform: !this.props.expanded ? "translate(3rem)" : undefined,
    };
  }

}

export default withTheme(SDrawer);
