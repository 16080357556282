import { Themes } from "../themes";

export function divider(theme: Themes): string {
  if (theme === Themes.dark || theme === Themes.dark_V2) {
    return "#323232";
  } else if (theme === Themes.light || theme === Themes.light_V2){
    return "#D0D0D0";
  } else {
    return "#00FF00";
  }
}

export function bgPaper(theme: Themes): string {
  if (theme === Themes.dark || theme === Themes.dark_V2) {
    return "#282828";
  } else if (theme === Themes.light || theme === Themes.light_V2){
    return "#EFEEEE";
  } else {
    return "#001F1F";
  }
}

export function bgDefault(theme: Themes): string {
  if (theme === Themes.dark || theme === Themes.dark_V2) {
    return "#242424";
  } else if (theme === Themes.light || theme === Themes.light_V2){
    return "#F3F3F3";
  } else {
    return "#001F1F";
  }
}

export function textPrimary(theme: Themes): string {
  if (theme === Themes.dark || theme === Themes.dark_V2) {
    return "#FFFFFF";
  } else if (theme === Themes.light || theme === Themes.light_V2){
    return "#000000";
  } else {
    return "#00FF00";
  }
}

export function textSecondary(theme: Themes): string {
  if (theme === Themes.dark || theme === Themes.dark_V2 || theme === Themes.light || theme === Themes.light_V2) {
    return "#787878";
  } else {
    return "#00FF00";
  }
}

export function primaryMain(_theme: Themes): string {
  return "#0069FF";
}

export function actionDisabledBg(theme: Themes): string {
  if (theme === Themes.dark || theme === Themes.dark_V2) {
    return "#ffffff54";
  } else if (theme === Themes.light || theme === Themes.light_V2){
    return "#00000054";
  } else {
    return "#00FF00";
  }
}

export function inputUnderline(theme: Themes): string {
  switch (theme) {
    case Themes.dark:
      return "#323232";
    case Themes.dark_V2:
      return "#424242";
    case Themes.light:
      return "#D0D0D0";
    case Themes.light_V2:
      return "#C0C0C0";
    default:
      return "#00FF00";
  }
}

export function tooltip(theme: Themes): string {
  if (theme === Themes.dark || theme === Themes.dark_V2) {
    return "#202020";
  } else if (theme === Themes.light || theme === Themes.light_V2){
    return "#E2E2E2";        
  } else {
    return "#00FF00";
  }
}
