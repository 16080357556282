import React, { Component, Fragment, ReactNode } from "react";
import { Popover } from "@material-ui/core";
import { Nullable } from "types/aliases";
import { LocationInfo, MeasSetsButtonActions } from "types/sensoanUiTypes";
import { MeasurementSetConfig } from "data/types/measurementSetTypes";
import MeasurementSetDataContainer from "data/measurement-data/MeasurementSetDataContainer";
import MeasurementDataTools from "data/measurement-data/MeasurementDataTools";
import Localization from "data/localization-sensoan/Localization";
import MeasurementJobRepository from "data/data-storage/MeasurementJobRepository";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import MeasurementSetSelector from "data/measurement-set-selector/MeasurementSetSelector";
import { getLocationsFromMapMarkers } from "data/utils/mapUtils";
import Section from "components/layout/Section";
import ErrorMessage from "components/layout/ErrorMessage";
import MapLink, { IconStatus, MapMarkerData } from "data/map/MapLink";
import MeasurementSetCharts from "components/charts/MeasurementSetCharts";
import SetsDetails from "./SetsDetails";
import LoaderSensoan from "components/layout/LoaderSensoan";

interface Props {
  actionRequest: Nullable<MeasSetsButtonActions>;
  clearActionRequest: () => void;
  endTimeStamp: number;
  measurementSetData: Nullable<MeasurementSetDataContainer>;
  measSetDataLoading: boolean;
  onTimeSpanChange: (value: number) => void;
  setEndTimeStamp: (timestamp: number) => void;
  timeSpanInHours: number;
}

interface State {
  errorPopUpOpen: boolean;
  location: Nullable<LocationInfo>;
  mapData: Nullable<MapMarkerData>;
}

class SetsDataView extends Component<Props, State> {

  private measurementSetRepo = MeasurementSetRepository.getInstance();
  private measurementJobRepo = MeasurementJobRepository.getInstance();
  private selectedSet: Nullable<MeasurementSetConfig> = null;

  public constructor(props: Props) {
    super(props);
    this.state = {
      errorPopUpOpen: false,
      location: null,
      mapData: null,
    };
    this.selectedSet = MeasurementSetSelector.getInstance().getSelectedMeasurementSet();
  }

  // TODO: refactor location setting
  public async componentDidMount(): Promise<void> {
    if (this.selectedSet?.config.gpsFunction !== undefined || this.selectedSet?.config.gpsLocation !== undefined) {
      const mapData = MeasurementDataTools.getMapMarkerDataForMeasSet(this.selectedSet, () => IconStatus.normal);

      if (mapData !== null) {
        const locations = await MapLink.getLinkToMap().search(mapData.location());
        let location: Nullable<LocationInfo> = null;

        if (locations && locations.length > 0) {
          location = locations[0]; // location found ok
        } else if (locations) {
          location = (): string => Localization.getInstance().getDisplayText("MeasurementSetEditView", "locationNotFound"); // location search ok but location not found
        } else {
          location = (): string => Localization.getInstance().getDisplayText("MeasurementSetEditView", "locationError"); // location search error
        }
        this.setState({ mapData, location });
        MapLink.getLinkToMap().replaceData([mapData]);
        MapLink.getLinkToMap().centerMap(getLocationsFromMapMarkers([mapData]), true);
      } else {
        this.setState({ location: (): string => Localization.getInstance().getDisplayText("MeasurementSetEditView", "locationNotFound") }); // location could not be read from device
      }
    } else {
      this.setState({ location: (): string => Localization.getInstance().getDisplayText("Common", "locationNotSpecified") }); // location was never set
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.actionRequest !== null && prevProps.actionRequest === null) {
      if (this.props.actionRequest === MeasSetsButtonActions.deleteSet) {
        this.deleteMeasurementSet();
        this.props.clearActionRequest();
      }
    }
  }

  public componentWillUnmount(): void {
    if (this.state.mapData !== null) {
      MapLink.getLinkToMap().removeLocation([this.state.mapData]);
    }

    if (!MapLink.getLinkToMap().isDefaultZoomAndCenter()) {
      MapLink.getLinkToMap().resetZoomAndCenter();
    }
  }

  public render(): ReactNode {
    return (
      <Fragment>
        {this.state.errorPopUpOpen &&
        this.renderErrorPopup()}
        <Section
          title={Localization.getInstance().getDisplayText("Common", "generalInfo")}
          titleTextStyle="h6"
          variant="foldable"
          mountOpen>
          <SetsDetails
            displayName={this.selectedSet?.displayName}
            location={this.state.location}
            metadata={this.selectedSet?.metadata}
            onTimeSpanChange={this.props.onTimeSpanChange}
            parentGroupIds={this.selectedSet?.parentIds}
            timeSpanInHours={this.props.timeSpanInHours}
            endTimeStamp={this.props.endTimeStamp}
            setEndTimeStamp={this.props.setEndTimeStamp}
          />
        </Section>
        <Section
          title={Localization.getInstance().getDisplayText("MeasurementSetDisplayView", "sensorViews")}
          titleTextStyle="h6">
          {this.props.measSetDataLoading
            ?
            <LoaderSensoan/>
            :
            <MeasurementSetCharts
              selectedMeasurementSet={this.selectedSet}
              measurementSetData={this.props.measurementSetData}
            />}
        </Section>
      </Fragment>
    );
  }

  private renderErrorPopup(): ReactNode {
    const id = this.state.errorPopUpOpen ? "delete-measSet-popup" : undefined;
    return (
      <Popover
        anchorReference="anchorPosition"
        anchorPosition={{ top: 200, left: 800 }}
        id={id}
        PaperProps={{ style: { height: "4rem", display: "flex", alignItems: "center", border: "1px solid #0069FF" } }}
        open={this.state.errorPopUpOpen}
        onClose={(): void => this.setState({ errorPopUpOpen: false })}
      >
        <ErrorMessage customMessage={Localization.getInstance().getDisplayText("MeasurementSetDisplayView", "deletionError")}/>
      </Popover>
    );
  }

  private deleteMeasurementSet(): void {
    if (this.selectedSet !== null) {

      if (this.setCanBeDeleted()) {
        this.measurementSetRepo.delete(this.selectedSet.setId);
      } else {
        this.setState({ errorPopUpOpen: true });
      }
    } else {
      console.error("Error in MeasurementSetDisplayView: called deleteMeasurementSet without selected set");
    }
  }

  private setCanBeDeleted(): boolean {
    const jobList = this.measurementJobRepo.list(this.selectedSet?.setId as string);

    if (jobList && jobList.length > 0) {
      return false;
    } else {
      return true;
    }
  }
}

export default SetsDataView;
