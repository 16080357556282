import { Box, Grid, Theme, Typography, withTheme } from "@material-ui/core";
import { ChartSize, SensorColorType } from "data/types/measurementSetTypes";
import React, { Component } from "react";
import { Maybe, Nullable } from "types/aliases";
import ChartTitle from "./ChartTitle";

interface Props {
  chartIndex?: number;
  color?: SensorColorType | {colorStop1: string; colorStop2: string };
  decimals?: number;
  deleteChart?: (index: Maybe<number>) => void;
  editChart?: (index: Maybe<number>) => void;
  onClick?: () => void;
  pressed?: boolean;
  size?: ChartSize;
  theme: Theme;
  title: string;
  value: Nullable<number>;
  // normal = MeasurementSetView
  // verySmall = 4x4 grid in MeasurementSetView
  // small = StatusView
}

interface State {
}

class GaugeCircle extends Component<Props, State> {
  // borderImage: "linear-gradient(180deg, #0069FF 0%, #979FAA 100%) 1 / 2px / 0 round",
  public render(): JSX.Element {
    let boxHeight = "400px";
    let borderSize = "250px";
    let circleSize = "246px";
    let sm: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 12;
    let md: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 8;
    let lg: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 6;
    let xl: boolean | 6 | "auto" | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12 = 4;

    if (this.props.size === ChartSize.PREVIEW_WIDTH) {
      sm = 12;
      md = 10;
      lg = 10;
      xl = 10;
    } else if (this.props.size === ChartSize.SMALL) {
      boxHeight = "320px";
      borderSize = "200px";
      circleSize = "196px";
      sm = 12;
      md = 4;
      lg = 3;
      xl = 3;
    } else if (this.props.size === ChartSize.VERY_SMALL) {
      boxHeight = "196px";
      borderSize = "125px";
      circleSize = "121px";
    }

    const shadow = this.props.pressed ? "inset -4px -4px 4px rgba(51, 51, 51, 0.35), inset 4px 4px 4px rgba(0, 0, 0, 0.35)" : "0px 4px 4px rgba(0, 0, 0, 0.35)";

    return (
      <Grid item sm={sm} md={md} lg={lg} xl={xl} onClick={this.props.onClick}>
        <Box display="flex" flexWrap="wrap" height={boxHeight} justifyContent="center" alignItems="center" style={{
          boxShadow: shadow,
        }} >
          <ChartTitle
            chartIndex={this.props.chartIndex}
            title={this.props.title}
            editChart={this.props.editChart}
            deleteChart={this.props.deleteChart}
          />
          <Box mb={6} bgcolor={this.props.theme.palette.background.default} display="flex" borderRadius="50%"
            justifyContent="center" alignItems="center" m={1} width={borderSize} height={borderSize}
            style={{ background: this.getCircleColor(), marginLeft: "-2px", marginRight: "-2px" }}>
            <Box bgcolor={this.props.theme.palette.background.default} m={0} p={0} borderRadius="50%" zIndex={2} display="flex" justifyContent="center" alignItems="center" width={circleSize} height={circleSize}>
              <Typography variant="h5">
                {this.props.value !== null ? this.props.value.toFixed(this.props.decimals ?? 1) : "??"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  }
  private getCircleColor(): string {
    const { color } = this.props;

    let colorStop1;
    let colorStop2 = "#979FAA";

    if (color) {
      if (typeof(color) === "string") { // type of enum is string
        colorStop1 = color;
      } else {
        colorStop1 = color.colorStop1;
        colorStop2 = color.colorStop2;
      }
    } else {
      colorStop1 = SensorColorType.BLUE;
    }
    return `linear-gradient(180deg, ${colorStop1} 0%, ${colorStop2} 100%)`;
  }
}

export default withTheme(GaugeCircle);


