import HyperIcon from "../../../assets/device/icons/router-24px.svg";
import { ReferenceHW } from "../ReferenceHW/ReferenceHW";
import { PiikkioHWState } from "./PiikkioHWState";
import AWSBackend from "../../../data/backend/AWSBackend";
import { DeviceParameters } from "../../../data/device/Device";
import { PiikkioHWStateProperties } from "./PiikkioHWStateProperties";

export class PiikkioHW extends ReferenceHW<PiikkioHWState> {
  public static type = "Piikkio";

  public constructor(backend: AWSBackend, params: DeviceParameters) {
    super(PiikkioHW.type, backend, params);
  }

  public getIcon(): string {
    return HyperIcon;
  }

  public createState(timestamp?: number, reported?: Partial<PiikkioHWStateProperties>, desired?: Partial<PiikkioHWStateProperties>):
  PiikkioHWState {
    return new PiikkioHWState(this.getId(), new PiikkioHWStateProperties(reported ?? {}), new PiikkioHWStateProperties(desired ?? {}), timestamp);
  }
}
