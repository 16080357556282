import React, { Component, Fragment } from "react";
import { Box, Button, Dialog, Divider, Grid, TextField, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Device from "data/device/Device";
import Localization from "data/localization-sensoan/Localization";
import { getDisplayName } from "data/utils/Utils";
import SensoanBackend from "data/backend/SensoanBackend";
import { MLDemoHWActivityTypeLabel, PiikkioHWActivityTypeLabel } from "types/sensoanUiTypes";
import { PiikkioHW } from "client/devices/PiikkioHW/PiikkioHW";
import { MLDemoHW } from "client/devices/MLDemoHW/MLDemoHW";
import { Nullable } from "types/aliases";
import SIconButton from "components/styled-components/SIconButton";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";

interface Props {
  selectedDevice: Device;
  openedTs: number; // Timestamp used in calculating the delay between opening and sending the label
  close: () => void;
}

interface State {
  error: boolean;
  label: string;
}

export default class ActivityLabelingPopup extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
      label: "",
    };
  }

  private sendLabel = async (label: string): Promise<void> => {
    const labelAge = Math.round((Date.now() - this.props.openedTs) / 1000);
    
    console.log("sending label with params: ", {
      deviceId: this.props.selectedDevice.getId(),
      label,
      labelAge,
    });

    const response = await SensoanBackend.getInstance().devicesActivityTypeLabelSend({
      deviceId: this.props.selectedDevice.getId(),
      label,
      labelAge,
    });

    if (response === "error") {
      this.setState({ error: true });
    }
  };

  private renderActivityLabelButtons(): Nullable<JSX.Element> {
    let activityTypeLabels: PiikkioHWActivityTypeLabel[] | MLDemoHWActivityTypeLabel[] = [];
    const { selectedDevice } = this.props;

    if (selectedDevice instanceof PiikkioHW) {
      activityTypeLabels = Object.values(PiikkioHWActivityTypeLabel);
    } else if (selectedDevice instanceof MLDemoHW) {
      activityTypeLabels = Object.values(MLDemoHWActivityTypeLabel);
    } else {
      console.error(`activity type labeling is not supported for device of type ${selectedDevice.getType()}`);
      return null;
    }

    return (
      <Box display='flex' flexDirection='column' alignItems='center' mb="1rem">
        {activityTypeLabels.map((label, index, array) => 
          <Button
            key={index}
            variant="contained"
            color="primary"
            onClick={(): Promise<void> => this.sendLabel(label)}
            style={{ minWidth: "155px", marginBottom: index !== array.length - 1 ? "1rem" : undefined }}
          >
            {label}
          </Button>,
        )}
      </Box>
    );
  }

  private renderContent(): JSX.Element {
    if (this.state.error) {
      return (
        <Fragment>
          <Box mb={4}>
            <Typography color='textPrimary' align="center">
              {this.text("DeviceWindow", "activityLabelingError")}
            </Typography>
          </Box>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={this.props.close}
              >
                {this.text("Common", "ok")}
              </Button>
            </Grid>
          </Grid>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Box mb={4}>
            <Typography color='textPrimary' align="center">
              {this.text("DeviceWindow", "activityLabelingInfo")}
            </Typography>
          </Box>
          {this.renderActivityLabelButtons()}
          {/* background color is same as in tab headers in DeviceSettingsPopup*/}
          <Divider style={{ backgroundColor: "#f50057" }}/>
          <Box mt={4} display="flex" flexDirection="column">
            <Typography color='textPrimary' align="center">
              {this.text("DeviceWindow", "customLabel")}
            </Typography>
            <TextField
              value={this.state.label}
              onChange={({ target }): void => this.setState({ label: target.value })}
            />
            <Box mt={4} display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={(): Promise<void> => this.sendLabel(this.state.label)}
              >
                {this.text("Actions", "send")}
              </Button>
            </Box>
          </Box>
        </Fragment>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Dialog
        maxWidth={"md"}
        onClose={this.props.close}
        open={true}
      >
        <Box display="flex" flexDirection="column" alignItems="flex-end" mt={2} mr={2}>
          <SIconButton
            onClick={this.props.close}
          >
            <SSvgIcon
              color={SSvgIconColorProps.secondary}
              iconComponent={CloseIcon}
            />
          </SIconButton>
        </Box>
        <Box px="1.5rem" pb="1.5rem">
          <Typography variant='h5' color='textPrimary' style={{ marginBottom: "1rem", textAlign: "center" }}>
            {getDisplayName(this.props.selectedDevice)}
          </Typography>
          {this.renderContent()}
        </Box>
      </Dialog>
    );
  }
}
