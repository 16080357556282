import React, { Component } from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { Theme, withStyles, withTheme } from "@material-ui/core/styles";
import { Maybe } from "types/aliases";

export enum SSvgIconColorProps { 
  primary, 
  secondary,
  textPrimary,
  disabled,
  white,
  blueGradient,
  greenGradient,
  orangeGradient,
  yellowGradient,
}

const DEFAULT_SIZE = "1.75rem";

interface Props {
  color: SSvgIconColorProps | string;
  iconComponent: React.ElementType;
  theme: Theme;
  inlineStyle?: React.CSSProperties;
  shadow?: boolean;
  size?: string;
  viewBox?: string;
}

interface State {
  StyledSvgIcon: React.ComponentType<SvgIconProps>;
}

class SSvgIcon extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      StyledSvgIcon: this.getStyledSvgIcon(),
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.theme !== prevProps.theme) {
      this.setState({ StyledSvgIcon: this.getStyledSvgIcon() });
    }

    if (this.props.color !== prevProps.color) {
      this.setState({ StyledSvgIcon: this.getStyledSvgIcon() });      
    }
  }
  
  public render(): JSX.Element {    
    return (
      <this.state.StyledSvgIcon
        // component prop is lost with withStyles HOC regardless of the cast as typeof SvgIcon -> ts has to be ignored 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        component={this.props.iconComponent}
        style={this.props.inlineStyle}
        viewBox={this.props.viewBox}
      />
    );
  }

  private getStyledSvgIcon(): React.ComponentType<SvgIconProps> {
    return withStyles({
      root: {
        fill: this.getFillColor(),
        ...this.getShadow(),
        ...this.getSize(),
      },
    })(SvgIcon) as (typeof SvgIcon);
  }

  private getShadow(): Maybe<{[property: string]: string}> {
    if (this.props.shadow) {
      return {
        borderRadius: "36px", boxShadow: this.props.theme.shadows[2], 
      };
    } else {
      return undefined;
    }
  }

  private getSize(): {[property: string]: string} {
    const { size } = this.props;

    if (size) {
      return {
        height: size, width: size, 
      };
    } else {
      return {
        height: DEFAULT_SIZE, width: DEFAULT_SIZE, 
      };
    }
  }


  private getFillColor(): Maybe<string> {
    switch (this.props.color) {
      case SSvgIconColorProps.primary:
        return this.props.theme.palette.iconColors.primary;
      case SSvgIconColorProps.secondary:
        return this.props.theme.palette.iconColors.secondary;
      case SSvgIconColorProps.textPrimary:
        return this.props.theme.palette.text.primary;
      case SSvgIconColorProps.disabled:
        return this.props.theme.palette.iconColors.disabled;
      case SSvgIconColorProps.white:
        return this.props.theme.palette.common.white;
      case SSvgIconColorProps.blueGradient:
        return this.props.theme.palette.iconGradients.blueGrey;
      case SSvgIconColorProps.greenGradient:
        return this.props.theme.palette.iconGradients.greenGrey;
      case SSvgIconColorProps.orangeGradient:
        return this.props.theme.palette.iconGradients.orangeGrey;
      case SSvgIconColorProps.yellowGradient:
        return this.props.theme.palette.iconGradients.yellowGrey;
      default:
        return this.props.color;
    }
  }
}

export default withTheme(SSvgIcon);
