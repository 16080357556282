import { Component } from "react";
import EventsWrapper from "../components/events/EventsWrapper";
import MeasurementJobsWrapper from "../components/measurement-jobs/MeasurementJobsWrapper";
import MeasurementSetsWrapper from "../components/measurement-sets/MeasurementSetsWrapper";
import OverviewWrapper from "../components/overview/OverviewWrapper";
import { ReactComponent as DevicesIcon } from "../assets/app-views/icons/DevicesIcon.svg";
import { ReactComponent as EventsIcon } from "../assets/app-views/icons/EventsIcon.svg";
import { ReactComponent as MeasurementsetsIcon } from "../assets/app-views/icons/MeasurementsetsIcon.svg";
import { ReactComponent as MeasurementjobsIcon } from "../assets/app-views/icons/MeasurementjobsIcon.svg";
import { ReactComponent as OverviewIcon } from "../assets/app-views/icons/OverviewIcon.svg";
import PathsSensoan from "data/paths/PathsSensoan";
import DevicesWrapper2 from "components/devices-view/DevicesWrapper";

export interface AppView {
  icon: React.FunctionComponent;
  path: PathsSensoan;
  View: typeof Component;
}

// PathsSensoan.STATUS is used in redirecting unmached paths
const AppViews: AppView[] = [
  {
    icon: OverviewIcon,
    path: PathsSensoan.STATUS,
    View: OverviewWrapper,
  },
  {
    icon: DevicesIcon,
    path: PathsSensoan.DEVICES,
    View: DevicesWrapper2,
  },
  {
    icon: MeasurementsetsIcon,
    path: PathsSensoan.MEASUREMENTSETS,
    View: MeasurementSetsWrapper,
  },
  {
    icon: MeasurementjobsIcon,
    path: PathsSensoan.MEASUREMENTJOBS,
    View: MeasurementJobsWrapper,
  },
  {
    icon: EventsIcon,
    path: PathsSensoan.EVENTS,
    View: EventsWrapper,
  },
];

export default AppViews;
