
import React, { Component, Fragment, ReactNode } from "react";
import DataRepositoryFactory, { DataRepositories } from "data/data-storage/DataRepositoryFactory";
import DataRepository from "data/data-storage/DataRepository";
import { Maybe, Nullable } from "types/aliases";
import AuthWrapper from "data/auth/AuthWrapper";
import { Typography } from "@material-ui/core";
import Localization from "data/localization-sensoan/Localization";
import LoaderSensoan, { Props as LoaderProps } from "components/layout/LoaderSensoan";
import WrapperContainer from "components/layout/WrapperContainer";

interface State {
  dataReady: boolean;
}

interface Props<TWrappedProps> {
  inlineLoaderWithoutWaitingText?: boolean;
  loaderProps?: Partial<LoaderProps>;
  fakeProps?: Partial<TWrappedProps>; // remove and fix ts error concerning TWrappedProps
}

// list of data repositories the component requires
type DataRequirements = DataRepositories[];

/**
 * HOC (https://reactjs.org/docs/higher-order-components.html) for loading async data before rendering a component
 * @param WrappedComponent
 *    component which requires initialized data repositories
 * @param dataRequirements
 *    list of required DataRepository types
 */
export default function withDataJanitor<TProps>(WrappedComponent: React.ComponentType<TProps>, dataRequirements: DataRequirements): React.ComponentClass<Props<TProps> & TProps> {

  if (dataRequirements.length === 0) {
    throw new Error("Don't use DataJanitor if you don't require any data");
  }

  return class ContentWithDataJanitor extends Component<Props<TProps> & TProps, State> {

    public constructor(props: Props<TProps> & TProps) {
      super(props);
      this.state = {
        dataReady: false,
      };
    }

    public async componentDidMount(): Promise<void> {
      if (await AuthWrapper.isCurrentUserAuthenticated()) {
        const repositories = dataRequirements.map((dataRepositoryType) => DataRepositoryFactory.getRepository(dataRepositoryType));
        let status = true;
        repositories.map((repo) => !repo.isInitialized() ? status = false : null);

        if (!status || !this.state.dataReady) {
          await Promise.all(repositories.map(async (repo: DataRepository): Promise<Nullable<void>> => {
            if (!repo.isInitialized()) {
              await repo.init();
              this.forceUpdate();
              return;
            } else {
              return;
            }
          }));
          this.setState({ dataReady: true });
        }
      } else {
        console.log("DataJanitor: user not logged in");
      }
    }

    private renderWaitingTexts(): JSX.Element {
      const repositories = dataRequirements.map((dataRepositoryType) => DataRepositoryFactory.getRepository(dataRepositoryType));
      return (
        <Fragment>
          {repositories.map((repo: DataRepository): Maybe<JSX.Element> => {
            if (!repo.isInitialized()) {
              return (
                <Typography key={repo.getName()}>
                  {Localization.getInstance().getDisplayText("MainMenu", "loading")} {Localization.getInstance().getDisplayText("Common", repo.getName())}
                </Typography>
              );
            } else {
              return;
            }
          })}
        </Fragment>
      );
    }

    private renderLoadingContent(): JSX.Element {
      const { loaderProps, inlineLoaderWithoutWaitingText } = this.props;
      const loader = <LoaderSensoan {...loaderProps} />;

      if (inlineLoaderWithoutWaitingText) {
        return loader;
      } else {
        return (
        /* Use same padding for waiting texts as for wrappers */
          <WrapperContainer>
            {this.renderWaitingTexts()}
            {loader}
          </WrapperContainer>
        );
      }
    }

    public render(): ReactNode {
      if (this.state.dataReady === true) {
        return (
          <WrappedComponent {...this.props} />
        );
      } else {
        return (
          this.renderLoadingContent()
        );
      }
    }
  };
}

