/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment } from "react";
import { IconButton, Table, TableCell, TableHead, TableRow } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Device from "data/device/Device";
import DeviceGroup from "data/device/DeviceGroup";
import AddDevicePopup from "./add-device-popup";
import DeviceNavigationCache from "utils/DeviceNavigationCache";
import DeviceRepository from "data/data-storage/DeviceRepository";
import Localization from "data/localization-sensoan/Localization";
import { Maybe } from "types/aliases";
import DeviceTree from "components/device-browsing/device-tree";
import { getCaseInsensitiveSearchFilter } from "components/device-browsing/helpers/search-filter";
import SearchBar from "components/ui/search-bar";
import ErrorDialog from "components/ui/error-dialog";
import Loader from "components/ui/loader";

interface Props {
  groups: DeviceGroup[];
  onGroupsUpdate?: () => void;
}

interface State {
  searchFilter: string;
  tabValue: number;
  showAddDevicePopup: boolean;
  loading: boolean;
  selectedDevice?: Device;
  parentGroup?: DeviceGroup;
  errorMsg?: string;
}

export default class DeviceManagement extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      searchFilter: "",
      tabValue: 0,
      showAddDevicePopup: false,
      loading: false,
    };
  }

  public async componentDidUpdate(): Promise<void> {
    const selectedDevice = DeviceNavigationCache.getInstance().getSelectedDevice();

    if (selectedDevice?.getId() !== this.state.selectedDevice?.getId()) {
      this.setState({ selectedDevice });
    }
  }

  private onSearchTriggered(searchString: string): void {
    console.log("onSearchTriggered: " + searchString);
    this.setState({ searchFilter: searchString });
  }

  private handleClosePopup = (): void => {
    // TODO: If popups needed in sequence, then split this
    this.setState({
      showAddDevicePopup: false,
      parentGroup: undefined,
      errorMsg: undefined,
    });
  };

  private handleAddDeviceIconClick = (): void => {
    this.setState({
      parentGroup: DeviceRepository.getInstance().getGroups()[0],
      showAddDevicePopup: true,
    });
  };

  private renderAddDevicePopup(): Maybe<JSX.Element> {
    if (this.state.parentGroup) {
      return (
        <AddDevicePopup
          open={this.state.showAddDevicePopup}
          targetGroup={this.state.parentGroup}
          onClose={(success): void => {
            this.handleClosePopup();

            if (success) {
              this.props.onGroupsUpdate?.();
            }
          }}
        />
      );
    }
  }

  private renderPopups(): Maybe<JSX.Element> {
    if (this.state.errorMsg) {
      return (
        <ErrorDialog
          errorMsg={this.state.errorMsg}
          onClose={this.handleClosePopup}
        />
      );
    } else {
      return this.renderAddDevicePopup();
    }
  }

  private renderTreeBrowserMode(): JSX.Element {
    return (
      <Fragment>
        <SearchBar
          searchString={this.state.searchFilter}
          onSearchTriggered={(searchString: string): void => this.onSearchTriggered(searchString)}
          className="admin-search-bar-container"
        />
        <DeviceTree
          groups={DeviceRepository.getInstance().getGroups()}
          searchFilter={getCaseInsensitiveSearchFilter(this.state.searchFilter)}
          editMode={true}
        />
      </Fragment>
    );
  }

  private renderDeviceBrowserMode(): JSX.Element {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return this.renderTreeBrowserMode();
    }
  }

  private renderAddDeviceButton(): Maybe<JSX.Element> {
    if (DeviceRepository.getInstance().getGroups() && !this.state.loading) {
      return (
        <IconButton
          title={this.text("AddDevicePopup", "addDevice")}
          onClick={this.handleAddDeviceIconClick}
          style={{ backgroundColor: "#e6e6e6", margin: "1rem 0 1rem 3rem" }}
          size="small"
        >
          <AddIcon />
        </IconButton>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <Table className="org-groups-table">
          <TableHead>
            <TableRow>
              <TableCell>{this.text("AddDevicePopup", "groups")} / {this.text("Common", "devices")}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {this.renderDeviceBrowserMode()}
        {this.renderAddDeviceButton()}
        {this.renderPopups()}
      </Fragment>
    );
  }
}

