import React, { Component, Fragment } from "react";
import { Box, Paper, Theme, withTheme } from "@material-ui/core";
import Device from "data/device/Device";
import Localization from "data/localization-sensoan/Localization";
import DeviceGroup from "data/device/DeviceGroup";
import MapLink, { MapLocation } from "data/map/MapLink";
import { getLocation } from "data/utils/deviceUtils";
import LatestDeviceDataRepository, { LatestDeviceDataRepositoryListener } from "data/data-storage/LatestDeviceDataRepository";
import Data from "data/data/Data";
import { Nullable } from "types/aliases";
import { LocationInfo } from "types/sensoanUiTypes";
import LoaderSensoan from "components/layout/LoaderSensoan";
import DeviceInfoSection from "./components/DeviceInfoSection";
import SensorListSection from "./components/SensorListSection";

interface Props {
  device: Device;
  theme: Theme;
}

interface State {
  deviceGroups: DeviceGroup[];
  loading: boolean;
  locationInfo: Nullable<LocationInfo>;
  deviceInfoSectionOpen: boolean;
  deviceLatestData: Nullable<Data>;
}

/* TODO: fix latest data reading to have a single source of truth for it and to prevent stale data  */
class DeviceWindow extends Component<Props, State> implements LatestDeviceDataRepositoryListener {
  private text = Localization.getInstance().getDisplayText;

  public constructor (props: Props) {
    super(props);
    this.state = {
      deviceGroups: [],
      loading: false,
      locationInfo: null,
      deviceInfoSectionOpen: true,
      deviceLatestData: null,
    };
  }

  public async componentDidMount(): Promise<void> {
    this.setState({ loading: true });
    const [deviceGroups, location] = await this.getGroupsAndLocation();
    /* TODO: check if latest data is available */
    this.setState({ deviceGroups, locationInfo: location, loading: false, deviceLatestData: this.props.device.getLatestDataSync() });
    LatestDeviceDataRepository.getInstance().addListener(this, this.props.device.getId());
  }

  public async componentDidUpdate(prevProps: Props, _prevState: State): Promise<void> {
    const newDeviceWasSelected = this.props.device.getId() !== prevProps.device.getId();

    if (newDeviceWasSelected) {
      this.setState({ loading: true });
      const [deviceGroups, location] = await this.getGroupsAndLocation();
      this.setState({ deviceGroups, locationInfo: location, loading: false, deviceLatestData: this.props.device.getLatestDataSync() });
    }
  }

  public componentWillUnmount(): void {
    LatestDeviceDataRepository.getInstance().removeListener(this);
  }

  public async onDataUpdated(data: Data): Promise<void> {
    this.setState({ deviceLatestData: data });
    const latestLocation = getLocation(data);

    if (this.didLocationChange(latestLocation)) {
      const location = await this.getLocationInfo(data);
      this.setState({ locationInfo: location });
    }
  }

  private didLocationChange(latestLocation: Nullable<MapLocation>): boolean {
    const previouslocation = getLocation(this.state.deviceLatestData);

    let check = false;

    if (latestLocation && previouslocation) {
      const { lat, lng } = previouslocation;
      const { lat: newLat, lng: newLng } = latestLocation;
      /* Device might report location with more than 5 decimals and it is not meaningful to compare
      so accurate gps positions (for example Google Maps displays 5 decimals for a location when map is right-clicked)
      */
      const wantedDecimalCount = 5;
      const locationChanged = newLat.toFixed(wantedDecimalCount) !== lat.toFixed(wantedDecimalCount) ||
      newLng.toFixed(wantedDecimalCount) !== lng.toFixed(wantedDecimalCount);

      if (locationChanged) {
        check = true;
      }
    } else if (!latestLocation && previouslocation || latestLocation && !previouslocation){
      check = true;
    }
    return check;
  }

  private async getGroupsAndLocation(): Promise<[DeviceGroup[], Nullable<LocationInfo>]> {
    const deviceGroups = await this.props.device.getGroups();
    const location = await this.getLocationInfo(this.props.device.getLatestDataSync());
    return [deviceGroups, location];
  }

  private async getLocationInfo(latestData: Nullable<Data>): Promise<Nullable<LocationInfo>> {
    const gpsLocation = getLocation(latestData);
    let addressLocation: Nullable<LocationInfo> = null;

    if (gpsLocation) {
      const locations = await MapLink.getLinkToMap().search(gpsLocation);

      if (locations && locations.length > 0) {
        addressLocation = locations[0]; // location found ok
      } else if (locations) {
        addressLocation = (): string => this.text("MeasurementSetEditView", "locationNotFound"); // location search ok but location not found
      } else {
        addressLocation = (): string => this.text("MeasurementSetEditView", "locationError"); // location search error
      }

    } else {
      addressLocation = (): string => this.text("MeasurementSetEditView", "locationNotFound"); // location could not be read from device
    }
    return addressLocation;
  }

  public renderContent(): JSX.Element {
    const { locationInfo: location, deviceGroups, deviceInfoSectionOpen, deviceLatestData } = this.state;
    const { device } = this.props;
    return (
      <Fragment>
        <DeviceInfoSection
          deviceGroups={deviceGroups}
          device={device}
          location={location}
          toggleOpen={(): void => this.setState({ deviceInfoSectionOpen: !deviceInfoSectionOpen })}
          open={deviceInfoSectionOpen}
        />
        <SensorListSection
          device={device}
          deviceLatestData={deviceLatestData}
          expandedListHeight={deviceInfoSectionOpen}
        />
      </Fragment>
    );
  }

  public renderLoader(): JSX.Element {
    return (
      <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
        <LoaderSensoan/>
      </Box>
    );
  }

  public render(): JSX.Element {
    const boxShadow = "0px 4px 4px rgba(0, 0, 0, 0.35)";

    return (
      <Paper square elevation={0} style={{ height: "100%", boxShadow, padding: "0 12px 12px" }}>
        {this.state.loading ? this.renderLoader() : this.renderContent()}
      </Paper>
    );
  }
}

export default withTheme(DeviceWindow);

