import React, { Component, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Grid, InputAdornment, Theme, withTheme } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Localization from "data/localization-sensoan/Localization";
import DeviceRepository from "data/data-storage/DeviceRepository";
import Device from "data/device/Device";
import { getDisplayName } from "data/utils/Utils";
import { DataRepositories } from "data/data-storage/DataRepositoryFactory";
import { getMapMarkerDataForAllDevices, getMapMarkerDataForDevice } from "data/utils/deviceUtils";
import { DIVIDER_HEIGHT } from "data/theme/constants";
import { sortList } from "data/utils/mathUtils";
import MapLink, { IconStatus, MapMarkerData } from "data/map/MapLink";
import { getLocationsFromMapMarkers } from "data/utils/mapUtils";
import DeviceNavigationCache from "utils/DeviceNavigationCache";
import { DeviceChangeType, idFromProps } from "utils/NavigationUtils";
import { DevicePathRouterProps } from "types/routerprops";
import { Maybe, Nullable } from "types/aliases";
import { BreadcrumbData } from "types/sensoanUiTypes";
import WrapperTitle2, { WRAPPER_TITLE_BOTTOM_PADDING, WRAPPER_TITLE_TOP_PADDING } from "components/layout/WrapperTitle2";
import SFilledInput from "components/styled-components/SFilledInput";
import IoTDataRow, { IOT_DATA_ROW_HEIGHT } from "components/layout/iot-data-row/IoTDataRow";
import Section, { SECTION_CONTENT_BOTTOM_PADDING, SECTION_CONTENT_TOP_PADDING } from "components/layout/Section";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import withDataJanitor from "components/hocs/DataJanitor";
import DeviceWindow from "./components/device-window/DeviceWindow";
import DeviceWindowPlaceHolder from "./components/DeviceWindowPlaceHolder";
import DeviceList from "./components/device-list/DeviceList";
import WrapperContainer from "components/layout/WrapperContainer";

interface Props extends RouteComponentProps<DevicePathRouterProps> {
  theme: Theme;
}

interface State {
  deviceSelectorAnchorEl: Nullable<HTMLButtonElement>;
  titleBreadcrumbs: BreadcrumbData[];
  filteredDeviceList: DeviceListItem[];
  mapMarkerData: Nullable<MapMarkerData[]>; // handling color changing in location buttons is faster if we keep a copy of marker data in state
  searchText: Nullable<string>;
  sortedDeviceList: DeviceListItem[];
  sortKey: DeviceListItemSortKey;
  sortOrder: "default" | "reverse";
}

export interface DeviceListItem {
  readonly deviceId: string;
  deviceDisplayName: string;
  deviceType: string;
}

export type DeviceListItemSortKey = keyof Omit<DeviceListItem, "deviceId">;

export const SEARCH_FIELD_ROW_HEIGHT = "3.5rem";
export const SEARCH_FIELD_ROW_DIVIDER_MARGIN_TOP = "16px";

/* TODO: move sorting & map marker handling to DeviceList - this component should only provide data with DataJanitor
and searchText from state property */
class DevicesWrapper extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;
  private deviceRepo = DeviceRepository.getInstance();

  public constructor(props: Props) {
    super(props);
    this.state = {
      deviceSelectorAnchorEl: null,
      titleBreadcrumbs: [{
        name: (): string => Localization.getInstance().getDisplayText("Common", "devices"),
        link: async (): Promise<Maybe<Device>> => await DeviceNavigationCache.getInstance().navigateToDevice(this.props, undefined),
      }],
      filteredDeviceList: [],
      mapMarkerData: null,
      searchText: null,
      sortedDeviceList: [],
      sortKey: "deviceDisplayName",
      sortOrder: "default",
    };
    this.toggleAllMapMarkers = this.toggleAllMapMarkers.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    const deviceResolveResult = DeviceNavigationCache.getInstance().predictDeviceChange(this.props);

    if (deviceResolveResult === DeviceChangeType.ChangedToNew) {
      const deviceId = idFromProps(this.props);
      await this.handleDeviceChange(deviceId ? this.deviceRepo.getDevice(deviceId) : undefined);
      // StayedNone is one of the two expected cases - no need to do anything in that case
    } else if (deviceResolveResult !== DeviceChangeType.StayedNone) {
      console.error(`Unexpected value '${deviceResolveResult}' for deviceResolveResult`);
    }
    const sortedDeviceList = sortList(this.state.sortOrder, this.state.sortKey, this.getRawDeviceList());
    const filteredDeviceList = this.filterDeviceList(sortedDeviceList, this.state.searchText);
    this.setState({ filteredDeviceList, sortedDeviceList });
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const deviceId = idFromProps(this.props);
      // deviceId (and device selection) was cleared if deviceId === undefined
      this.handleDeviceChange(deviceId ? this.deviceRepo.getDevice(deviceId) : undefined);
    }

    if (this.state.sortKey !== prevState.sortKey || this.state.sortOrder !== prevState.sortOrder) {
      const sortedDeviceList = sortList(this.state.sortOrder, this.state.sortKey, this.state.sortedDeviceList);
      const filteredDeviceList = this.filterDeviceList(this.state.sortedDeviceList, this.state.searchText);
      this.setState({ filteredDeviceList, sortedDeviceList });
    }

    if (this.didFilteredDeviceListChange(prevState.filteredDeviceList, this.state.filteredDeviceList)) {
      const filteredDeviceMarkersOnMap = MapLink.getDataStorage().getMarkerData().filter(mData => {
        return this.state.filteredDeviceList.some(device => {
          return device.deviceId === mData.id;
        });
      });
      this.setState({ mapMarkerData: filteredDeviceMarkersOnMap.length > 0 ? filteredDeviceMarkersOnMap : null });
      MapLink.getLinkToMap().replaceData(filteredDeviceMarkersOnMap);
    }
  }

  public onSearchTextChange(text: Nullable<string>): void {
    const filteredDeviceList = this.filterDeviceList(this.state.sortedDeviceList, text);
    this.setState({ searchText: text, filteredDeviceList });
  }

  private async selectDeviceListItem({ deviceId }: DeviceListItem): Promise<void> {
    await DeviceNavigationCache.getInstance().navigateToDevice(this.props, deviceId);
  }

  private async handleDeviceChange(device: Maybe<Device>): Promise<void> {
    const crumbs = [this.state.titleBreadcrumbs[0]];
    await DeviceNavigationCache.getInstance().setCurrentDevice(device);

    if (device !== undefined) {
      /* A device was selected */
      crumbs[1] = {
        name: getDisplayName(device),
        link: (): void => undefined,
      };
      const mapMarkerData = getMapMarkerDataForDevice(device, () => IconStatus.selected);

      if (mapMarkerData) {
        /* a location was found for the selected device */
        MapLink.getLinkToMap().replaceData([mapMarkerData]);
        MapLink.getLinkToMap().centerMap(getLocationsFromMapMarkers([mapMarkerData]), true);
        this.setState({ mapMarkerData: [mapMarkerData] });
      } else {
        /* a location was not found for the selected device */
        this.clearMapMarkersAndResetMap();
      }

    } else {
      /* device selection was cleared */
      this.clearMapMarkersAndResetMap();
    }
    this.setState({ deviceSelectorAnchorEl: null, titleBreadcrumbs: crumbs });
  }

  public componentWillUnmount(): void {
    this.clearMapMarkersAndResetMap(true);
  }

  private renderListFilteringRow(): JSX.Element {
    return (
      <IoTDataRow width="50%" justifyContent="space-between">
        <SFilledInput
          endAdornment={
            <InputAdornment position="end">
              <SSvgIcon color={SSvgIconColorProps.textPrimary} iconComponent={SearchIcon} size="1.5rem" />
            </InputAdornment>
          }
          id="devices-search"
          inputLabelText={this.text("DevicesWrapper", "searchForDevice")}
          onChange={(event): void => this.onSearchTextChange(event.target.value)}
          value={this.state.searchText ?? ""}
          width="12.5rem"
        />
        {/* this.renderDeviceGroupSelector() */}
      </IoTDataRow>
    );
  }

  private renderDeviceList(): JSX.Element {
    const { sortKey, sortOrder, filteredDeviceList, mapMarkerData } = this.state;
    return (
      <Grid item xs={6} style={{ height: "100%" }}>
        <DeviceList
          listItems={filteredDeviceList}
          mapMarkerData={mapMarkerData}
          onSelectListItem={(listItem): Promise<void> => this.selectDeviceListItem(listItem)}
          setSortOrder={(sortKey): void => this.setSortOrder(sortKey)}
          sortKey={sortKey}
          sortOrder={sortOrder}
          toggleAllMapMarkers={this.toggleAllMapMarkers}
          toggleMapMarker={(marker): void => this.toggleMapMarker(marker)}
        />
      </Grid>
    );
  }

  private renderDeviceWindowArea(): JSX.Element {
    const device = DeviceNavigationCache.getInstance().getSelectedDevice();
    return (
      <Grid
        item
        xs={6}
        style={{
          height: `calc(100% - ${IOT_DATA_ROW_HEIGHT})`,
          position: "relative",
          top: IOT_DATA_ROW_HEIGHT,
          padding: "0 12px",
        }}
      >
        {device ? <DeviceWindow device={device}/> : <DeviceWindowPlaceHolder/>}
      </Grid>
    );
  }

  private renderDeviceListAndWindow(): JSX.Element {
    return (
      <Section minWidth="1100px" height="100%">
        <Grid container style={{ height: this.getDeviceListAndWindowContainerHeight() }}>
          {this.renderDeviceList()}
          {this.renderDeviceWindowArea()}
        </Grid>
      </Section>
    );
  }

  public render(): ReactNode {
    return (
      <WrapperContainer disableOverflowY>
        <WrapperTitle2 breadcrumbList={this.state.titleBreadcrumbs} minWidth="1100px"/>
        {this.renderListFilteringRow()}
        {this.renderDeviceListAndWindow()}
      </WrapperContainer>
    );
  }

  /* TODO: uncomment this and implement group selection functionality */
  // private renderDeviceGroupSelector(): Maybe<JSX.Element> {
  //   if (this.state.deviceSelectorAnchorEl !== null) {
  //     return (
  //       <DeviceSelect3
  //         anchorEl={this.state.deviceSelectorAnchorEl}
  //         close={(): void => this.setState({ deviceSelectorAnchorEl: null })}
  //         selectionMode={DeviceTreeSelectionMode.Group}
  //       />
  //     );
  //   }
  // }

  // - - - - - - - - - - - - - - - - - - - - - - -
  // Methods for interacting with map markers
  private toggleAllMapMarkers(): void {
    const mapMarkerData = getMapMarkerDataForAllDevices();

    if (MapLink.getLinkToMap().areLocationsOnMap(mapMarkerData.map(({ id }) => id))) {
      this.setState({ mapMarkerData: null });
      MapLink.getLinkToMap().removeAllLocations();
    } else {
      this.setState({ mapMarkerData });
      MapLink.getLinkToMap().replaceData(mapMarkerData);
    }
  }

  private toggleMapMarker(marker: Nullable<MapMarkerData>): void {
    if (marker) {
      const { mapMarkerData } = this.state;

      // handle updating state
      if (mapMarkerData && mapMarkerData.findIndex(mData => mData.id === marker.id) > -1) {
        this.setState({ mapMarkerData: mapMarkerData.filter(mData => mData.id !== marker.id) });
      } else if (mapMarkerData) {
        this.setState({ mapMarkerData: mapMarkerData.concat(marker) });
      } else {
        this.setState({ mapMarkerData: [marker] });
      }
      // handle updating markerData in MapLink
      MapLink.getLinkToMap().toggle(marker);
    } else {
      console.error("error in DevicesWrapper.toggleMapMarker() / parameter 'marker' was null");
    }
  }

  private clearMapMarkersAndResetMap(skipSetState?: boolean): void {
    if (MapLink.getDataStorage().getMarkerData().length > 0) {
      if (!skipSetState) {
        this.setState({ mapMarkerData: null });
      }
      MapLink.getLinkToMap().removeAllLocations();
    }

    if (!MapLink.getLinkToMap().isDefaultZoomAndCenter()) {
      MapLink.getLinkToMap().resetZoomAndCenter();
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - -
  // Helper methods for list filtering and sorting
  private filterDeviceList(deviceList: DeviceListItem[], searchText: Nullable<string>): DeviceListItem[] {
    if (searchText === null) {
      return deviceList;
    } else {
      const filtereDeviceList = deviceList.filter(({ deviceDisplayName, deviceType }) =>
        deviceDisplayName.toLowerCase().includes(searchText.toLowerCase()) ||
      deviceType.toLowerCase().includes(searchText.toLowerCase()),
      );
      return filtereDeviceList;
    }
  }

  private getRawDeviceList(): DeviceListItem[] {
    return this.deviceRepo.getDevices().map(device => ({ deviceId: device.getId(), deviceDisplayName: getDisplayName(device), deviceType: device.getType() }));
  }

  private setSortOrder(sortKey: DeviceListItemSortKey): void {
    if (this.state.sortKey !== sortKey) {
      this.setState({ sortKey });
    } else {
      this.setState({ sortOrder: this.state.sortOrder === "default" ? "reverse" : "default" });
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - -
  // Helper method for map marker data and location button handling
  private didFilteredDeviceListChange(previousList: DeviceListItem[], currentList: DeviceListItem[]): boolean {
    if (previousList.length >= currentList.length) {
      return !previousList.every(prevListItem => {
        return currentList.some(currListItem => {
          return prevListItem.deviceId === currListItem.deviceId;
        });
      });
    } else if (previousList.length < currentList.length){
      return !currentList.every(currListItem => {
        return previousList.some(prevListItem => {
          return currListItem.deviceId === prevListItem.deviceId;
        });
      });
    } else {
      return false;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - -
  // Helper methods for building the layout
  private getSearchFieldRowHeight(): string {
    return `${SEARCH_FIELD_ROW_HEIGHT} + ${SECTION_CONTENT_BOTTOM_PADDING} + ${SECTION_CONTENT_TOP_PADDING} + ${DIVIDER_HEIGHT} + ${SEARCH_FIELD_ROW_DIVIDER_MARGIN_TOP}}`;
  }

  private getWrapperTitleHeight(): Maybe<string> {
    const { theme } = this.props;
    const wrapperTitleFontSize = theme.typography.h4.fontSize;

    if (typeof wrapperTitleFontSize === "string") {
      const wrapperTitleLineHeight = theme.typography.h4.lineHeight;

      if (typeof wrapperTitleLineHeight === "number") {
        return `${wrapperTitleFontSize} * ${wrapperTitleLineHeight} + ${WRAPPER_TITLE_BOTTOM_PADDING} + ${WRAPPER_TITLE_TOP_PADDING}`;
      } else {
        console.error("Height of Wrapper Title could not be calculated: font size of h4 element in theme is not in expected format.");
      }
    } else {
      console.error("Height of Wrapper Title could not be calculated: line height of h4 element in theme is not in expected format.");
    }
  }

  private getDeviceListAndWindowContainerHeight(): string {
    return `calc(100% - (${this.getWrapperTitleHeight()} + ${this.getSearchFieldRowHeight()}))`;
  }
}

export default withRouter(withTheme(withDataJanitor(DevicesWrapper, [
  DataRepositories.Device,
])));
