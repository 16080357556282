import React, { Component, Fragment, ReactNode } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeviceRepository from "data/data-storage/DeviceRepository";
import { DataConfig } from "data/types/measurementSetTypes";
import Localization from "data/localization-sensoan/Localization";
import ErrorMessage from "components/layout/ErrorMessage";
import LatestDeviceDataRepository from "data/data-storage/LatestDeviceDataRepository";
import SButton from "components/styled-components/SButton";
import { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import Device from "data/device/Device";
import DeviceSelect2 from "components/inputs/DeviceSelect2";
import { Nullable } from "types/aliases";

interface Props {
  dataConfig: DataConfig;
  index: number;
  onSensorDisplayNameChange:(index: number, sensorDisplayName: string) => void;
  onSensorSelect: (deviceDataKey: string, deviceId: string, index: number) => void;
  removeDataConfig: (index: number) => void;
}

interface State {
  deviceSelectorAnchorEl: Nullable<HTMLButtonElement>;
}

export type SensorValue = number | string | boolean | undefined;

class SensorNameAndEventSelector extends Component<Props, State> {

  public constructor (props: Props) {
    super(props);
    this.state = {
      deviceSelectorAnchorEl: null,
    };
  }

  private text = Localization.getInstance().getDisplayText;
  private deviceRepo = DeviceRepository.getInstance();

  //TODO: setting event values for sensor is not supported yet
  /*   private renderEventValueSelectors(): ReactNode {
    return (
      <FormControl margin="dense" size="small" variant="filled">
        <Select
          disableUnderline={true}
          style={{ height: "2rem", width: "8rem" }} //TODO: add global styling to inputs
          IconComponent={ExpandMoreIcon} //TODO : IconComponent color should be read from theme
          value={this.state.eventValueLow}
          type="number"
          onChange={(event: React.ChangeEvent<{value: unknown}>): void => this.setState({ eventValueLow: event.target.value as string })}
        >
          <MenuItem value={"???"}>{"???"}</MenuItem>
        </Select>
        <Select
          disableUnderline={true}
          style={{ height: "2rem", width: "8rem" }} //TODO: add global styling to inputs
          IconComponent={ExpandMoreIcon} //TODO : IconComponent color should be read from theme
          value={this.state.eventValueHigh}
          onChange={(event: React.ChangeEvent<{value: unknown}>): void => this.setState({ eventValueHigh: event.target.value as string })}
        >
          <MenuItem value={"???"}>{"???"}</MenuItem>
        </Select>
      </FormControl>
    );
  } */

  private renderSensorReading(): ReactNode {
    const latestData = LatestDeviceDataRepository.getInstance().getLatestData(this.props.dataConfig.deviceId);
    let text;

    if (latestData) {
      const data = latestData.getData();
      const reading = data?.[this.props.dataConfig.sensorName];
      text = `${this.text("SensorNameAndEventSelector", "reading")}: ${this.getFormattedReading(reading ?? "-")}`;
    } else {
      text = this.text("SensorNameAndEventSelector", "loadingReading");
    }
    return <Typography variant="body1" color="textSecondary">{text}</Typography>;
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <Box display="flex" height="3rem" justifyContent="flex-start" >
          <Box width="15%">
            <Typography variant="body1" color="textSecondary">
              {this.text("SensorNameAndEventSelector", "selectDeviceAndSensor")}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" width="50%" >
            <Box width="20%">
              {/* TODO: replace DeviceSelect2 and SButton with SSelect */}
              {this.state.deviceSelectorAnchorEl !== null
              &&
              <DeviceSelect2
                anchorEl={this.state.deviceSelectorAnchorEl}
                close={(): void => this.setState({ deviceSelectorAnchorEl: null })}
                onSelectSensorItem={(treeDeviceItem: Device, treeSensorItem?: string): void => this.props.onSensorSelect(treeSensorItem as string, treeDeviceItem.getId(), this.props.index)}
                selectedSensorItem={{ deviceId: this.props.dataConfig.deviceId, sensorName: this.props.dataConfig.sensorName }}
                sensorSelectingMode
              />}
              <SButton
                color="secondary"
                labelText={this.props.dataConfig.sensorName !== ""
                  ? this.props.dataConfig.sensorName
                  : Localization.getInstance().getDisplayText("MeasurementSetConfigSelection", "select")}
                onClick={(event: React.MouseEvent<HTMLButtonElement>): void => this.setState({ deviceSelectorAnchorEl: event.currentTarget })}
                ariaControls="select-sensor-menu"
                ariaHaspopup="true"
                endIcon={ExpandMoreIcon}
                iconColor={SSvgIconColorProps.textPrimary}
              />
              {(!this.props.dataConfig.sensorName && !this.props.dataConfig.deviceId) && <ErrorMessage mt={2}/>}
            </Box>
            <Box width="20%">
              {this.props.dataConfig.deviceId
              &&
              this.renderSensorReading()}
            </Box>
            <Box display="flex" width="35%">
              <Box marginRight="1rem">
                <Typography variant="body1" color="textSecondary">
                  {this.text("SensorNameAndEventSelector", "giveSensorDisplayName")}
                </Typography>
              </Box>
              <Box>
                <TextField
                  value={this.props.dataConfig.sensorDisplayName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.props.onSensorDisplayNameChange(this.props.index, event.target.value as string)} />
                {!this.props.dataConfig.sensorDisplayName && <ErrorMessage mt={2}/>}
              </Box>
            </Box>
            <Box>
              <SButton
                color="secondary"
                iconColor={SSvgIconColorProps.textPrimary}
                labelText={this.text("SensorNameAndEventSelector", "delete")}
                onClick={(): void => this.props.removeDataConfig(this.props.index)}
                startIcon={RemoveIcon}
              />
            </Box>
          </Box>
        </Box>
      </Fragment>
    );
  }

  private getFormattedReading(value: SensorValue): SensorValue {
    if (typeof value === "number" && parseInt(value.toString(), 10) !== value) {
      return parseFloat((value as number).toFixed(1));
    } else {
      return value;
    }
  }
}

export default SensorNameAndEventSelector;
