/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

const awsconfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_CONFIG_AUTH_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_CONFIG_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AWS_CONFIG_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_CONFIG_AUTH_USER_POOL_WEB_CLIENT_ID,
  },
  Analytics: {
    disabled: true,
  },
  aws_appsync_graphqlEndpoint_users: process.env.REACT_APP_AWS_CONFIG_USERS_GRAPHQL_ENDPOINT,
  aws_appsync_graphqlEndpoint_data: process.env.REACT_APP_AWS_CONFIG_DATA_GRAPHQL_ENDPOINT,
  aws_appsync_graphqlEndpoint_device: process.env.REACT_APP_AWS_CONFIG_DEVICE_GRAPHQL_ENDPOINT,
  aws_appsync_graphqlEndpoint_events: process.env.REACT_APP_AWS_CONFIG_EVENTS_GRAPHQL_ENDPOINT,
  aws_appsync_graphqlEndpoint_stats: process.env.REACT_APP_AWS_CONFIG_STATS_GRAPHQL_ENDPOINT,
  aws_appsync_graphqlEndpoint_measset: process.env.REACT_APP_AWS_CONFIG_MEASSET_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_CONFIG_APPSYNC_REGION,
  aws_appsync_authenticationType: process.env.REACT_APP_AWS_CONFIG_APPSYNC_AUTH_TYPE,
  aws_s3_nrf_fota_bucket: process.env.REACT_APP_AWS_CONFIG_NRF_FOTA_BUCKET,
};

export default awsconfig;
