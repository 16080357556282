import React, { Component } from "react";

interface Props {
  colorStopFirst: string;
  colorStopSecond: string;
  name: string;
}

class IconGradientProvider extends Component<Props> {

  public render(): JSX.Element {
    return (
      <svg height="0" width="0">
        <defs>
          <linearGradient id={`${this.props.name}`} gradientTransform="rotate(90)">
            <stop offset="0%" stopColor={this.props.colorStopFirst}>
            </stop>
            <stop offset="100%" stopColor={this.props.colorStopSecond}>
            </stop>
          </linearGradient>
        </defs>
      </svg>
    );
  }
}

export default IconGradientProvider;
