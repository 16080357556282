import React, { Component } from "react";
import { RouteComponentProps, Link as RouterLink, withRouter } from "react-router-dom";
import { Box, Menu, Typography } from "@material-ui/core";
import { DevicePathRouterProps } from "types/routerprops";
import { Nullable } from "types/aliases";
import AuthWrapper from "data/auth/AuthWrapper";
import Localization from "data/localization-sensoan/Localization";
import SensoanBackend from "data/backend/SensoanBackend";
import InfoPopover from "components/layout/InfoPopover";
import ThemeInput from "components/inputs/ThemeInput";
import BugReport from "components/inputs/BugReport";
import SButton from "components/styled-components/SButton";
import LocalizationInput from "components/inputs/LocalizationInput";
import AdminSButton from "components/styled-components/AdminSButton";
import PathsSensoan from "data/paths/PathsSensoan";

interface State {
  backendVersion: Nullable<string>;
  user: Nullable<string>;
}

interface Props extends RouteComponentProps<DevicePathRouterProps> {
  anchorEl: null | HTMLElement;
  handleCloseMenu: () => void;
}

class UserMenu extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      backendVersion: null,
      user: null,
    };
  }

  public async componentDidUpdate(): Promise<void> {
    if (this.state.backendVersion === null && await AuthWrapper.isCurrentUserAuthenticated()) {
      this.setState({ backendVersion: "loading" });
      const cognitoUser = await AuthWrapper.getCurrentAuthenticatedUser(false);
      let user = "";

      if (cognitoUser !== undefined && cognitoUser.getUsername()) {
        user = cognitoUser.getUsername();
      }
      console.log("username: ", user);
      this.setState({ backendVersion: await SensoanBackend.getInstance().getBackendSwVersion(), user });
    }
  }

  private getLocalizedDisplayName(name: string): string {
    return Localization.getInstance().getDisplayText("UserMenu", name);
  }

  private getTrailingRoute = (): string => {
    if (this.props.match.params.id) {
      return "/" + this.props.match.params.id;
    }
    return "";
  };

  public render(): JSX.Element {
    return (
      <Menu
        anchorEl={this.props.anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        getContentAnchorEl={null}
        keepMounted
        onClose={this.props.handleCloseMenu}
        open={Boolean(this.props.anchorEl)}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Box component="li" mx={4} mt={2} mb={4}>
          <ThemeInput onSelectTheme={this.props.handleCloseMenu}/>
        </Box>
        <Box component="li" m={4}>
          <LocalizationInput fetchAppData onSelectLocale={this.props.handleCloseMenu}/>
        </Box>
        <Box component="li" m={4}>
          <SButton
            buttonComponent={RouterLink}
            color="secondary"
            labelText={this.getLocalizedDisplayName("userView")}
            linkTo={PathsSensoan.USER}
            onClick={(): void => this.props.handleCloseMenu()}
          />
        </Box>
        <Box component="li" m={4}>
          <AdminSButton
            buttonComponent={RouterLink}
            color="secondary"
            labelText={this.getLocalizedDisplayName("userManagement")}
            linkTo={PathsSensoan.ADMIN}
            showAccessError={false}
            onClick={(): void => this.props.handleCloseMenu()}
            showLoader={false}
          />
        </Box>
        <Box component="li" m={4}>
          <InfoPopover
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            title={Localization.getInstance().getDisplayText("UserMenu", "info")}>
            <Box mt={2}>
              <Typography color="textSecondary">
                {`${Localization.getInstance().getDisplayText("SwInfo", "web")}: `}
              </Typography>
              <Typography>
                {process.env.REACT_APP_VERSION_STRING}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography color="textSecondary">
                {`${Localization.getInstance().getDisplayText("SwInfo", "backend")}: `}
              </Typography>
              <Typography>
                {this.state.backendVersion}
              </Typography>
            </Box>
          </InfoPopover>
        </Box>
        <Box component="li" m={4}>
          <BugReport backend_version={this.state.backendVersion ?? ""} reporter={this.state.user ?? ""}/>
        </Box>
        <Box component="li" mx={4} mt={4} mb={2}>
          <SButton
            color="secondary"
            labelText={this.getLocalizedDisplayName("logOut")}
            onClick={async (): Promise<void> => {
              await AuthWrapper.logOut();
              console.log("user logged out");
            }}
          />
        </Box>
      </Menu>
    );
  }
}

export default withRouter(UserMenu);
