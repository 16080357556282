import Data from "data/data/Data";

export enum MeasurementSetType {
  CONFIG = "config",
  GROUP = "group",
}

export enum TimeScaleType {
  AGGREGATE = "aggregate",
  LATEST = "latest",
  HISTORY = "history",
}

export enum SensorType {
  HUMIDITY = "humidity",
  TEMPERATURE = "temperature",
  PRESSURE = "pressure",
}

export enum GpsType {
  GPSFUNCTION = "gpsFunction",
  GPSLOCATION = "gpsLocation",
}

export enum ChartType {
  GAUGE = "Gauge",
  // GAUGECHART = "GaugeNumber", ** disabled ATM, will be implemented later
  LINECHART = "LineChart",
  STEPPED_AREA_CHART = "SteppedAreaChart",
  NUMBER_DISPLAY = "NumberDisplay",
}

export enum ChartSize {
  FULL_WIDTH = "fullWidth",
  PREVIEW_WIDTH = "previewWidth",
  NORMAL = "normal",
  SMALL = "small",
  VERY_SMALL = "verySmall",
}

export enum SensorColorType {
  BLUE = "#0069FF",
  ORANGE = "#EF4E23",
  GREEN = "#00FFA3",
  GREY = "#787878",
  PINK = "#FF38A4",
  TURQUOISE = "#00D1FF",
  YELLOW = "#FCFF73",
  VIOLET = "#A54BFF"
}

export interface ChartConfig {
  chartType: ChartType;
  dataConfig: DataConfig[];
  displayName: string;
  timeScaleType: TimeScaleType;
  scale?: {
    max: number;
    min: number;
  };
}

export interface DataConfig {
  deviceId: string;
  sensorColor: SensorColorType;
  sensorName: string;
  sensorDisplayName: string;
}

export interface DeviceData {
  [deviceId: string]: Data[];
}

export interface DeviceDataTs {
  [deviceId: string]: {
    [sensorName: string]: Data[];
  };
}

export interface MeasurementSet {
  setId: string;
  parentIds: string[];
  type: MeasurementSetType;
  displayName: string;
}

export interface MeasurementSetData {
  [chart: string]: {
    [deviceId: string]: Data[];
  };
}

export interface MeasurementSetConfig extends MeasurementSet {
  config: SetConfig;
  metadata?: string;
}

export interface MeasurementSetGroup extends MeasurementSet {
  childIds: string[];
}

export interface MeasurementSetTreeItem extends MeasurementSet {
  childs?: MeasurementSetTreeItem[];
}

export interface SetConfig {
  chartConfig: ChartConfig[];
  historyLength?: number;
  aggregateLength?: number;
  gpsLocation?: {
    latitude: number;
    longitude: number;
  };
  gpsFunction?: {
    deviceId: string;
  };
}
