import React, { Component, ReactNode } from "react";
import { Popover, Theme, withTheme } from "@material-ui/core";
import { Nullable } from "types/aliases";
import Device from "data/device/Device";
import DeviceTree2 from "components/measurement-sets/tree-components/DeviceTree2";
import DeviceNavigationCache from "utils/DeviceNavigationCache";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DevicePathRouterProps } from "types/routerprops";

interface Props extends RouteComponentProps<DevicePathRouterProps> {
  anchorEl: Nullable<HTMLButtonElement>;
  close: () => void;
  deviceFilter?: (device: Device) => boolean;
  onSelectSensorItem?: (treeDeviceItem: Device, treeSensorItem?: string) => Promise<void> | void;
  selectedSensorItem?: {deviceId: string; sensorName: string};
  sensorSelectingMode?: boolean;
  theme: Theme;
}

interface State {}

class DeviceSelect2 extends Component<Props, State> {

  public render(): ReactNode {
    const open = this.props.anchorEl !== null ? true : false;
    return (
      <Popover
        onClose={(): void => this.props.close()}
        anchorEl={this.props.anchorEl}
        open={open}
      >
        <DeviceTree2
          deviceFilter={this.props.deviceFilter}
          onTreeItemLabelClick={(treeDeviceItem: Device, treeSensorItem?: string): Promise<void> => this.handleTreeItemLabelClick(treeDeviceItem, treeSensorItem)}
          onClearButtonClick={(): Promise<void> => this.handleClearButtonClick()}
          renderSensors={this.props.sensorSelectingMode}
          selectedSensorItem={this.props.selectedSensorItem}
        />
      </Popover>
    );
  }

  private async handleClearButtonClick(): Promise<void> {
    await DeviceNavigationCache.getInstance().navigateToDevice(this.props, undefined);
    this.props.close();
  }

  private async handleTreeItemLabelClick(treeDeviceItem: Device, treeSensorItem?: string): Promise<void> {
    if (!this.props.sensorSelectingMode && treeDeviceItem instanceof (Device)) {
      if (this.props.location.pathname.startsWith("/measurementsets")) {
        await DeviceNavigationCache.getInstance().navigateToDeviceWithQueryString(this.props, treeDeviceItem.getId());
      } else {
        await DeviceNavigationCache.getInstance().navigateToDevice(this.props, treeDeviceItem.getId());
      }
    } else {
      this.props.onSelectSensorItem?.(treeDeviceItem, treeSensorItem);
    }
    this.props.close();
  }
}

export default withRouter(withTheme(DeviceSelect2));
