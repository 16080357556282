import React, { Component } from "react";
import { Grid, Theme, withTheme } from "@material-ui/core";
import Section from "components/layout/Section";
import Localization from "data/localization-sensoan/Localization";
import GaugeCircle from "components/charts/GaugeCircle";
import EventsRepository from "data/data-storage/EventsRepository";
import { EventRepositoryListener } from "data/data-storage/EventRepositoryListener";
import Event from "data/clientSpecific/Event";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import Device from "data/device/Device";
import DeviceRepository from "data/data-storage/DeviceRepository";
import { checkDeviceActivity, getMapMarkerDataForAllDevices } from "data/utils/deviceUtils";
import MeasurementJobRepository from "data/data-storage/MeasurementJobRepository";
import MapLink, { MapMarkerData } from "data/map/MapLink";
import { MeasurementJobStatus, MeasurementJob } from "data/types/measurementJobTypes";
import MeasurementDataTools from "data/measurement-data/MeasurementDataTools";
import { ChartSize, SensorColorType } from "data/types/measurementSetTypes";
import withDataJanitor from "components/hocs/DataJanitor";
import { DataRepositories } from "data/data-storage/DataRepositoryFactory";
import WrapperTitle2 from "components/layout/WrapperTitle2";
import Gradients, { IconGradientNames } from "data/theme/PaletteColors/Gradients";
import { getMapMarkerDataForAllEvents } from "data/utils/eventUtils";
import { getLocationsFromMapMarkers } from "data/utils/mapUtils";
import { Nullable } from "types/aliases";
import WrapperContainer from "components/layout/WrapperContainer";

interface Props {
  theme: Theme;
}

interface State {
  activeBox: null | "activeEvents" | "activeJobs" | "activeDevices" | "allEvents" | "allJobs" | "measurementSets" | "allDevices";
}

class OverviewWrapper extends Component<Props, State> implements EventRepositoryListener {

  private mapLink = MapLink.getLinkToMap();

  public constructor(props: Props) {
    super(props);
    this.state = { activeBox: null };
  }

  public componentDidMount(): void {
    EventsRepository.instance.addListener(this);
  }

  public componentDidUpdate(_prevProps: Props, prevState: State): void {
    if (this.state.activeBox !== prevState.activeBox) {
      let data: Nullable<MapMarkerData[]> = null;

      switch (this.state.activeBox) {
        case null:
          this.mapLink.removeAllLocations();
          return;
        case "activeEvents":
          data = getMapMarkerDataForAllEvents(EventsRepository.instance.getAllActiveEvents());
          break;
        case "activeJobs":
          data = MeasurementDataTools.getMapMarkerDataForAllMeasJobs(this.getActiveMeasJobs());
          break;
        case "activeDevices":
          data = getMapMarkerDataForAllDevices(
            DeviceRepository.getInstance().getDevices().filter(device => {
              return checkDeviceActivity(device);
            }));
          break;
        case "allEvents":
          data = getMapMarkerDataForAllEvents(EventsRepository.instance.getAllEvents());
          break;
        case "allJobs":
          data = MeasurementDataTools.getMapMarkerDataForAllMeasJobs();
          break;
        case "measurementSets":
          data = MeasurementDataTools.getMapMarkerDataForAllMeasSets();
          break;
        case "allDevices":
          data = getMapMarkerDataForAllDevices();
          break;
        default:
          console.error("Unknown activeBox");
          break;
      }

      if (data) {
        this.updateMapWithData(data);
      }
    }
  }

  private updateMapWithData(data: MapMarkerData[]): void {
    const area = getLocationsFromMapMarkers(data);

    if (!MapLink.getLinkToMap().isInCurrentView(area)) {
      MapLink.getLinkToMap().centerMap(area);
    }
    this.mapLink.replaceData(data);
  }

  public componentWillUnmount(): void {
    EventsRepository.instance.removeListener(this);
  }

  public onEvent(_event: Event): void {
    this.forceUpdate();
  }

  public onEventStateChanged(_event: Event): void {
    this.forceUpdate();
  }

  public render(): JSX.Element {

    return (
      <WrapperContainer>
        <WrapperTitle2
          breadcrumbList={
            [{
              name: Localization.getInstance().getDisplayText("Common", "status"),
              link: (): void => undefined,
            }]
          }
          minWidth="1100px"
        />
        <Section title={Localization.getInstance().getDisplayText("Overview", "active")} variant="foldable">
          <Grid container justifyContent="center" spacing={2}>
            <GaugeCircle
              color={this.getCircleColor(IconGradientNames.orangeGrey)}
              size={ChartSize.SMALL}
              decimals={0}
              title={Localization.getInstance().getDisplayText("Overview", "activeEvents")}
              value={EventsRepository.instance.getAllActiveEvents().length}
              onClick={(): void => this.setState({ activeBox: this.state.activeBox === "activeEvents" ? null : "activeEvents" })}
              pressed={this.state.activeBox === "activeEvents"}
            />
            <GaugeCircle
              color={this.getCircleColor(IconGradientNames.greenGrey)}
              size={ChartSize.SMALL}
              decimals={0}
              title={Localization.getInstance().getDisplayText("Overview", "activeJobs")}
              value={this.calculateActiveMeasJobs()}
              onClick={(): void => this.setState({ activeBox: this.state.activeBox === "activeJobs" ? null : "activeJobs" })}
              pressed={this.state.activeBox === "activeJobs"}
            />
            <GaugeCircle
              color={this.getCircleColor(IconGradientNames.yellowGrey)}
              size={ChartSize.SMALL}
              decimals={0}
              title={Localization.getInstance().getDisplayText("Overview", "activeDevices")}
              value={DeviceRepository.getInstance().getDevices().filter((d: Device): boolean => checkDeviceActivity(d)).length}
              onClick={(): void => this.setState({ activeBox: this.state.activeBox === "activeDevices" ? null : "activeDevices" })}
              pressed={this.state.activeBox === "activeDevices"}
            />
          </Grid>
        </Section>
        <Section title={Localization.getInstance().getDisplayText("Overview", "all")}>
          <Grid container justifyContent="center" spacing={2}>
            <GaugeCircle
              color={this.getCircleColor(IconGradientNames.orangeGrey)}
              size={ChartSize.SMALL}
              decimals={0}
              title={Localization.getInstance().getDisplayText("Overview", "allEvents")}
              value={EventsRepository.instance.getAllEvents().length}
              onClick={(): void => this.setState({ activeBox: this.state.activeBox === "allEvents" ? null : "allEvents" })}
              pressed={this.state.activeBox === "allEvents"}
            />
            <GaugeCircle
              color={this.getCircleColor(IconGradientNames.greenGrey)}
              size={ChartSize.SMALL}
              decimals={0}
              title={Localization.getInstance().getDisplayText("Overview", "allJobs")}
              value={this.calculateMeasJobs()}
              onClick={(): void => this.setState({ activeBox: this.state.activeBox === "allJobs" ? null : "allJobs" })}
              pressed={this.state.activeBox === "allJobs"}
            />
            <GaugeCircle
              color={this.getCircleColor(IconGradientNames.blueGrey)}
              size={ChartSize.SMALL}
              decimals={0}
              title={Localization.getInstance().getDisplayText("Overview", "allMeasurementSets")}
              value={MeasurementSetRepository.getInstance().getMeasurementSets().length}
              onClick={(): void => this.setState({ activeBox: this.state.activeBox === "measurementSets" ? null : "measurementSets" })}
              pressed={this.state.activeBox === "measurementSets"}
            />
            <GaugeCircle
              color={this.getCircleColor(IconGradientNames.yellowGrey)}
              size={ChartSize.SMALL}
              decimals={0}
              title={Localization.getInstance().getDisplayText("Overview", "allDevices")}
              value={DeviceRepository.getInstance().getDevices().length}
              onClick={(): void => this.setState({ activeBox: this.state.activeBox === "allDevices" ? null : "allDevices" })}
              pressed={this.state.activeBox === "allDevices"}
            />
          </Grid>
        </Section>
      </WrapperContainer>
    );
  }

  // - - - - - - - - - - - -
  // Private helper methods

  private getActiveMeasJobs(): MeasurementJob[] {
    const activeJobs: MeasurementJob[] = [];
    const allJobs = MeasurementJobRepository.getInstance().getMeasurementJobs();

    for (const job of allJobs) {
      if (job.status === MeasurementJobStatus.Running) {
        activeJobs.push(job);
      }
    }

    return activeJobs;
  }

  private calculateActiveMeasJobs(): number {
    return this.getActiveMeasJobs().length;
  }

  private calculateMeasJobs(): number {
    return MeasurementJobRepository.getInstance().getMeasurementJobs().length;
  }

  private getCircleColor(gradient: IconGradientNames): SensorColorType | {colorStop1: string; colorStop2: string } {
    const colorStops = Gradients.getIconGradientColorStops(this.props.theme, gradient);

    if (colorStops !== undefined) {
      return colorStops;
    } else {
      console.error(`No color stops found for iconGradientName ${gradient} in OverViewWrapper.getCircleColor`);
      return SensorColorType.GREY;
    }
  }

}

export default withDataJanitor(withTheme(OverviewWrapper), [
  DataRepositories.Device,
  DataRepositories.Events,
  DataRepositories.MeasurementJob,
  DataRepositories.MeasurementSet,
]);
