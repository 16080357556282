import { Nullable, Voidable } from "types/aliases";
import { isDefined } from "utils/types";

// Returns:
//   number, if the input is an int or float
//   null, if the input is empty string or invalid number
export function validateNumber(text: Voidable<string>): Nullable<number> {
  const reg = /^[+-]?[0-9]+(\.[0-9]+)?$/;
  
  if (!isDefined(text) || text.length === 0) {
    return null;
  } else if (!reg.test(text)) {
    return null;
  } else {
    return parseFloat(text);
  }
}

// Returns true, if the string is the begininging of a valid float number
export function validatePartialNumber(text: Voidable<string>): boolean {
  const reg1 = /^[+-]?[0-9]*$/;
  const reg2 = /^[+-]?[0-9]+\.[0-9]*$/;
  
  if (!isDefined(text)) {
    return false;
  } else if (text.length === 0) {
    return true;
  } else if (reg1.test(text) || reg2.test(text)) {
    return true;
  } else {
    return false;
  }
}
