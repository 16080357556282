import React, { Component, ReactNode } from "react";
import { Box, Typography } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Localization from "data/localization-sensoan/Localization";
import { SCROLLBAR_WIDTH } from "data/theme/constants";
import { Maybe } from "types/aliases";
import HeaderRowLocationButton from "components/layout/iot-data-row/HeaderRowLocationButton";
import IoTDataRow from "components/layout/iot-data-row/IoTDataRow";
import { DeviceListItemSortKey } from "components/devices-view/DevicesWrapper";

interface Props {
  requestSorting: (sortKey: DeviceListItemSortKey) => void;
  sortKey: DeviceListItemSortKey;
  sortOrder: "default" | "reverse";
  toggleAllMapMarkers: () => void;
  iotDataRowDividerWidth?: string;
}

interface State {
}

class DevicesListHeaderRow extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  private getSortIcon(label: DeviceListItemSortKey): Maybe<JSX.Element> {
    if (label === this.props.sortKey) {
      return this.props.sortOrder === "default" ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />;
    } else {
      return undefined;
    }
  }

  // Location indicator
  // DeviceName (sort)
  // device type (sort)
  public render(): ReactNode {
    const cursorPointerStyle: React.CSSProperties = { cursor: "pointer" };
    /* TODO: change boxes into buttons */
    return (
      <IoTDataRow dividerFadeEffect dividerWidth={this.props.iotDataRowDividerWidth}>
        <HeaderRowLocationButton
          toggleAllMapMarkers={this.props.toggleAllMapMarkers}
        />
        {/* pl="18px" is to align this with data row  content */}
        <Box width="50%" pl="18px" display="flex" alignItems="center" onClick={(): void => this.props.requestSorting("deviceDisplayName")}>
          <Typography variant="body1" style={cursorPointerStyle}>
            {this.text("Common", "device")} {this.getSortIcon("deviceDisplayName")}
          </Typography>
        </Box>
        {/* position="relative" right={SCROLLBAR_WIDTH} are to align this with data row content */}
        <Box width="50%" position="relative" right={SCROLLBAR_WIDTH} onClick={(): void => this.props.requestSorting("deviceType")}>
          <Typography variant="body1" style={cursorPointerStyle}>
            {this.text("Common", "type")} {this.getSortIcon("deviceType")}
          </Typography>
        </Box>
      </IoTDataRow>
    );
  }
}

export default DevicesListHeaderRow;
