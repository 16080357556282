import { Box, IconButton, Popover, Theme, Typography, withTheme } from "@material-ui/core";
import React, { Component } from "react";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Nullable } from "types/aliases";

interface Props {
  anchorEl: Nullable<HTMLButtonElement>;
  close: () => void;
  setTimestamp: (newTs: number) => void;
  theme: Theme;
  timeStamp: number;
}

class TimePicker extends Component<Props> {

  public render(): JSX.Element {
    const open = this.props.anchorEl !== null ? true : false;
    return (
      <Popover
        onClose={this.props.close}
        anchorEl={this.props.anchorEl}
        open={open}
      >
        <Box width="120px" display="flex" alignItems="center" justifyContent="space-evenly" bgcolor={this.props.theme.palette.background.default}>
          <Box pl={1} display="flex" flexWrap="wrap" justifyContent="center">
            <IconButton onClick={(): void => this.handleTimeChange("hour", "add")}>
              <ExpandLessIcon />
            </IconButton>
            <Box width="100%" display="flex" justifyContent="center">
              <Typography>
                {(new Date(this.props.timeStamp)).getHours()}
              </Typography>
            </Box>
            <IconButton onClick={(): void => this.handleTimeChange("hour", "subtract")}>
              <ExpandMoreIcon />
            </IconButton>
          </Box>
          <Typography>
              :
          </Typography>
          <Box pr={1} display="flex" flexWrap="wrap" justifyContent="center">
            <IconButton onClick={(): void => this.handleTimeChange("minute", "add")}>
              <ExpandLessIcon />
            </IconButton>
            <Box width="100%" display="flex" justifyContent="center">
              <Typography>
                {(new Date(this.props.timeStamp)).getMinutes().toLocaleString("en-us", { minimumIntegerDigits: 2 })}
              </Typography>
            </Box>
            <IconButton onClick={(): void => this.handleTimeChange("minute", "subtract")}>
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>
      </Popover>
    );
  }

  
  private handleTimeChange(unit: "minute" | "hour", change: "add" | "subtract"): void {
    const num = change === "add" ? 1 : - 1;
    this.props.setTimestamp(unit === "minute" ? this.addMinutes(this.props.timeStamp, num) : this.addHours(this.props.timeStamp, num));
  }
  
  private addHours(ts: number, nmbrOfHours: number): number {
    const d = new Date(ts);
    const hours = d.getHours();
    d.setHours(hours + nmbrOfHours);
    return d.getTime();
  }

  private addMinutes(ts: number, nmbrOfMinutes: number): number {
    const d = new Date(ts);
    const hours = d.getMinutes();
    d.setMinutes(hours + nmbrOfMinutes);
    return d.getTime();
  }

}

export default withTheme(TimePicker);
