import { Nullable } from "types/aliases";
import MapBase, { DEFAULT_CENTER } from "./MapBase";
import { MapLocation } from "./MapLink";


export interface HereMapsSearchAPIResult {
  [key: string]: any; //eslint-disable-line @typescript-eslint/no-explicit-any
  items?: HereMapsPlace[]; //eslint-disable-line @typescript-eslint/no-explicit-any
}

// HereMapsPlace describes relevant fields in objects returned as items in HereAutoSuggestAPIResult
export interface HereMapsPlace {
  [key: string]: any;//eslint-disable-line @typescript-eslint/no-explicit-any
  title: string;
  mapView: MapView;
  position: MapLocation;
}

export interface MapView {
  east: number;
  north: number;
  south: number;
  west: number;
}

export interface GeocodingProvider {
  init(): void; // eslint-disable-line @typescript-eslint/no-explicit-any
  searchWithAddress(address: string): Promise<Nullable<HereMapsSearchAPIResult>>; 
}
  
export default class MapGeocoding implements GeocodingProvider {
  private static instance: MapGeocoding;
  
  private searchService: any = null; // eslint-disable-line @typescript-eslint/no-explicit-any
      
  public static getInstance(): MapGeocoding {
    if (this.instance == null) {
      this.instance = new MapGeocoding();
    }
    return this.instance;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public init(): void {
    this.searchService = MapBase.getInstance().getSearchService(); 
  }

  public async searchWithAddress(address: string): Promise<Nullable<HereMapsPlace[]>> {
    try {
      const result = await this.promisifiedAutoSuggest(address);

      if (result.items) {
        return result.items.reduce<HereMapsPlace[]>((acc, { title, mapView, position }) => {
          acc.push(
            {
              title,
              mapView,
              position,
            },
          );
          return acc;
        }, []);
      } else {
        console.error("Error in MapGeocoding.ts: address search failed");
        return null;
      }
    } catch (error) {
      console.error("Error in MapGeocoding.ts: address search failed");
      return null;
    }
  }

  private async promisifiedAutoSuggest(address: string): Promise<HereMapsSearchAPIResult> {
    return new Promise((resolve, reject) => {
      return this.searchService.autosuggest({
        q: address,
        at: this.parseMapLocationToQueryParam(DEFAULT_CENTER),
      }, (result: any) => { // eslint-disable-line
        return resolve(result);
      }, (error: any) => {  // eslint-disable-line
        return reject(error);
      }); 
    });
  }

  // TODO: handle error case in components
  public async searchWithMapLocation(location: MapLocation): Promise<Nullable<HereMapsPlace[]>> { // eslint-disable-line @typescript-eslint/no-explicit-any
    try {
      const result = await this.promisifiedReverseGeocode(location);

      if (result.items) {
        return result.items.reduce<HereMapsPlace[]>((acc, { title, mapView, position }) => {
          acc.push(
            {
              title,
              mapView,
              position,
            },
          );
          return acc;
        }, []);
      } else {
        console.error("Error in MapGeocoding.ts: location search failed");
        return null;
      }
    } catch (error) {
      console.error("Error in MapGeocoding.ts: location search failed");
      return null;
    }
  }

  private async promisifiedReverseGeocode(location: MapLocation): Promise<HereMapsSearchAPIResult> {
    return new Promise((resolve, reject) => {
      return this.searchService.reverseGeocode({
        at: this.parseMapLocationToQueryParam(location),
      }, (result: any) => { // eslint-disable-line
        return resolve(result);
      }, (error: any) => {  // eslint-disable-line
        return reject(error);
      }); 
    });
  }

  private parseMapLocationToQueryParam(location: MapLocation): string {
    return `${location.lat.toString()},${location.lng.toString()}`;
  }
}
