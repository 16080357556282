import React, { Component } from "react";
import { AppBar, AppBarProps, Box, Theme, withStyles, withTheme } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import SIconButton from "components/styled-components/SIconButton";
import UserMenu from "components/user-menu/UserMenu";
import HeaderLogoSensoanDarkTheme from "assets/header/images/sensoan_logo_white.png";
import HeaderLogoSensoanLightTheme from "assets/header/images/sensoan_logo_blue.png";

interface State {
  adminUser: boolean;
  navigationMenuAnchorEl: null | HTMLElement;
  StyledAppBar: React.ComponentType<AppBarProps>;
  userMenuAnchorEl: null | HTMLElement;
}

interface Props {
  theme: Theme;
}

class Header extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      adminUser: false,
      navigationMenuAnchorEl: null,
      StyledAppBar: withStyles({
        root: {
          height: this.props.theme.mixins.toolbar.height,
          backgroundColor: this.props.theme.palette.background.default,
          zIndex: this.props.theme.zIndex.drawer + 1,
          margin: "0 auto",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          position: "fixed",
        },
      })(AppBar),
      userMenuAnchorEl: null,
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.theme.palette.type !== prevProps.theme.palette.type) {
      this.setState({
        StyledAppBar: withStyles({
          root: {
            height: this.props.theme.mixins.toolbar.height,
            backgroundColor: this.props.theme.palette.background.default,
            zIndex: this.props.theme.zIndex.drawer + 1,
            margin: "0 auto",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            position: "fixed",
          },
        })(AppBar),
        userMenuAnchorEl: null,
      });
    }
  }

  public render(): JSX.Element {
    return (
      <this.state.StyledAppBar>
        <Box pl={11} pt={4} pb={4} height="100%">
          <img style={{ height: "100%", maxWidth: "90%", minHeight: "16px", minWidth: "105px" }} src={this.getHeaderLogo()} alt="header-logo" />
        </Box>
        <SIconButton
          aria-controls="user-menu-button"
          aria-haspopup="true"
          disableRipple
          focusVisibleClassName="focus-visible"
          onClick={this.handleOpenUserMenu}
        >
          <SSvgIcon
            color={this.state.userMenuAnchorEl !== null ? SSvgIconColorProps.blueGradient : SSvgIconColorProps.secondary}
            iconComponent={MoreVertIcon}
            size="2.5rem"
          />
        </SIconButton>
        <UserMenu
          anchorEl={this.state.userMenuAnchorEl}
          handleCloseMenu={this.handleCloseUserMenu}
        />
      </this.state.StyledAppBar>
    );
  }

  private getHeaderLogo(): string {
    return this.props.theme.palette.type === "dark" ? HeaderLogoSensoanDarkTheme : HeaderLogoSensoanLightTheme;
  }

  private handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    this.setState({ userMenuAnchorEl: event.currentTarget });
  };

  private handleCloseUserMenu = (): void => {
    this.setState({ userMenuAnchorEl: null });
  };
}

export default withTheme(Header);
