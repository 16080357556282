import React, { ChangeEvent, Component, ReactNode } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import AuthWrapper from "data/auth/AuthWrapper";
import { Maybe } from "types/aliases";
import { isError } from "data/utils/ErrorUtils";
import Loader from "components/ui/loader";
import Localization from "data/localization-sensoan/Localization";

interface Props {}

interface State {
  isLoading: boolean;
  firstname?: string;
  lastname?: string;
  phoneNumber?: string;
  error?: string;
}

export default class AttributeForm extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  public componentDidMount(): void {
    this.getUserDetails();
  }

  private async getUserDetails(): Promise<void> {
    this.setState({ isLoading: true });

    Promise.allSettled([
      AuthWrapper.getGivenName(),
      AuthWrapper.getFamilyName(),
      AuthWrapper.getPhoneNumber(),
    ]).then(results => {
      const firstname = AttributeForm.getPromiseResult(results[0]);
      const lastname = AttributeForm.getPromiseResult(results[1]);
      const phoneNumber = AttributeForm.getPromiseResult(results[2]);
      this.setState({
        firstname,
        lastname,
        phoneNumber,
      });
    });
    this.setState({ isLoading: false });
  }

  private static getPromiseResult<TResult = string>(result: PromiseSettledResult<Maybe<TResult>>, fallbackResult?: TResult): Maybe<TResult> {
    if (result.status === "rejected") {
      console.error("Failed to settle", result.reason);
    } else {
      return result.value ?? fallbackResult;
    }
  }

  private handleNameSubmit = async (): Promise<void> => {
    try {
      this.setState({ isLoading: true });
      await AuthWrapper.setName(this.state.firstname ?? "", this.state.lastname ?? "");
      this.setState({
        firstname: this.state.firstname,
        lastname: this.state.lastname,
      });
    } catch (error) {
      console.error("handleNameSubmit", error);
      if (isError(error)) this.handleErrorCode(error.message);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  private handlePhoneNumberSubmit = async (): Promise<void> => {
    try {
      this.setState({ isLoading: true });
      await AuthWrapper.setPhoneNumber(this.state.phoneNumber ?? "");
    } catch (error) {
      console.error("handlePhoneNumberSubmit", error);
      if (isError(error)) this.handleErrorCode(error.message);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  private handleErrorCode(code?: string): void {
    switch (code) {
      case "Attribute value for given_name must not be null":
      case "Attribute value for family_name must not be null":
        this.setErrorMessage(this.text("UserView", "invalidName"));
        break;
      case "Invalid phone number format.":
        this.setErrorMessage(this.text("UserView", "invalidPhoneNumberFormat"));
        break;
      case "Network error":
        this.setErrorMessage(this.text("Common", "networkError"));
        break;
      default:
        this.setErrorMessage(this.text("Common", "unableToPerformAction"));
        break;
    }
  }

  private setErrorMessage(error?: string): void {
    this.setState({ error });
  }

  private renderUserAttributeSubmitMessage(): ReactNode {
    if (this.state.error) {
      return (
        <Grid item={true} container={true} justifyContent="center">
          <span>{`${this.text("Common", "errorOccurred")} ${this.state.error}`}</span>
        </Grid>
      );
    }
  }

  private renderNameInputs(): JSX.Element {
    const isEnabled = !!this.state.firstname || !!this.state.lastname;
    return (
      <Grid item={true} xs={12} sm={10} md={8}>
        <Grid item={true}>
          <TextField
            fullWidth={true}
            label={this.text("UserView", "firstname")}
            type="text"
            margin="normal"
            variant="outlined"
            value={this.state.firstname ?? ""}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              this.setState({ firstname: event.target.value, error: undefined });
            }}
          />
        </Grid>
        <Grid item={true}>
          <TextField
            fullWidth={true}
            label={this.text("UserView", "lastname")}
            type="text"
            margin="normal"
            variant="outlined"
            value={this.state.lastname ?? ""}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              this.setState({ lastname: event.target.value, error: undefined });
            }}
          />
        </Grid>
        <Grid item={true} container={true} justifyContent="center">
          <Button
            disabled={!isEnabled}
            variant="contained"
            color="primary"
            onClick={this.handleNameSubmit}
          >
            {this.text("UserView", "confirmNameChange")}
          </Button>
        </Grid>
      </Grid>
    );
  }

  private renderPhoneNumberInputs(): JSX.Element {
    return (
      <Grid item={true} xs={12} sm={10} md={8}>
        <Grid item={true}>
          <TextField
            fullWidth={true}
            label={this.text("UserView", "phonenumber")}
            type="text"
            margin="normal"
            variant="outlined"
            value={this.state.phoneNumber ?? ""}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              this.setState({ phoneNumber: event.target.value, error: undefined });
            }}
          />
        </Grid>
        <Grid item={true} container={true} justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={this.handlePhoneNumberSubmit}
          >
            {this.text("UserView", "updatePhoneNumber")}
          </Button>
        </Grid>
      </Grid>
    );
  }

  private renderLoader(): Maybe<JSX.Element> {
    if (this.state.isLoading) {
      return <Grid item={true} container={true} justifyContent="center"><Loader /></Grid>;
    }
  }

  public render(): JSX.Element {
    return (
      <Grid container={true} spacing={4}>
        <Grid item={true} container={true}>
          <Grid item={true} container={true} justifyContent="center">
            <Typography variant="h6" style={{ fontWeight: "bold" }}>{this.text("UserView", "enterFirstnameAndLastname")}</Typography>
          </Grid>
          <Grid item={true} container={true} spacing={2} justifyContent="center">
            {this.renderNameInputs()}
          </Grid>
        </Grid>
        <Grid item={true} container={true}>
          <Grid item={true} container={true} justifyContent="center">
            <Typography variant="h6" style={{ fontWeight: "bold" }}>{this.text("UserView", "enterPhoneNumber")}</Typography>
          </Grid>
          <Grid item={true} container={true} spacing={2} justifyContent="center">
            {this.renderPhoneNumberInputs()}
          </Grid>
        </Grid>
        {this.renderLoader()}
        {this.renderUserAttributeSubmitMessage()}
      </Grid>
    );
  }
}
