import React, { Component, Fragment, ReactNode } from "react";
import { Box, Grid, Popover, TextField, Theme, Typography, withTheme } from "@material-ui/core";
import { Maybe, Nullable } from "types/aliases";
import { ChartConfigValidityStatus, MeasSetEditTarget, MeasSetsButtonActions, MinMaxInputType, ScaleInputErrorStatus } from "types/sensoanUiTypes";
import Localization from "data/localization-sensoan/Localization";
import { ChartConfig, ChartType, DataConfig, SensorColorType, TimeScaleType } from "data/types/measurementSetTypes";
import { validateNumber, validatePartialNumber } from "data/utils/inputUtils";
import Section from "components/layout/Section";
import ErrorMessage from "components/layout/ErrorMessage";
import DataVisualization from "./data-visualization-editing/DataVisualizationEditing";
import SensorNameAndEventSelection from "./data-visualization-editing/sensor-configuration/SensorNameAndEventSelection";
import { isDefinedAndBiggerOrEqual, isDefinedAndSmallerOrEqual } from "data/utils/mathUtils";
import DataVisualizationPreview from "./data-visualization-preview/DataVisualizationPreview";
import withDataJanitor from "components/hocs/DataJanitor";
import { DataRepositories } from "data/data-storage/DataRepositoryFactory";

interface Props {
  actionRequest: Nullable<MeasSetsButtonActions>;
  aggregateLength: Nullable<number | string>;
  chartConfig: Nullable<ChartConfig>;
  chartConfigValidityStatus: ChartConfigValidityStatus;
  clearActionRequest: () => void;
  displayName: string;
  index: Nullable<number>;
  onAggregateLengthChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onNameChange: (text: string, target: MeasSetEditTarget) => void;
  saveChartConfig: (config: ChartConfig, index: Nullable<number>, newConfig: boolean) => void;
  saveChartConfigAfterScaleMsg: () => void;
  setChartConfigValidityStatus: (status: ChartConfigValidityStatus) => void;
  theme: Theme;
  toggleAggregateLength: (value: Nullable<string>) => void;
}

interface State {
  activeScaleInput: Nullable<MinMaxInputType>;
  aggregateLengthOptionActivated: boolean;
  chartType: ChartType | ""; // union type to fix error muiInput ("`value` prop on `input` should not be null")
  dataConfig: Nullable<DataConfig[]>;
  timeScaleType: TimeScaleType | string; // union type to fix error muiInput ("`value` prop on `input` should not be null")
  errorPopUpOpen: boolean;
  scale: {
    min: string;
    max: string;
  };
}

class ChartConfigCreateView extends Component<Props, State> {

  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = this.props.chartConfig ? this.getStateValuesFromProps() : this.getEmptyState();
    this.removeDataConfig = this.removeDataConfig.bind(this);
    this.saveChartConfig = this.saveChartConfig.bind(this);
    this.saveDataConfig = this.saveDataConfig.bind(this);
    this.onSensorColorSelect = this.onSensorColorSelect.bind(this);
    this.onSensorDisplayNameChange = this.onSensorDisplayNameChange.bind(this);
    this.onSensorSelect = this.onSensorSelect.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    this.props.setChartConfigValidityStatus(this.validateChartConfig());
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {

    if (this.props.actionRequest !== null && prevProps.actionRequest === null) {
      if (this.props.actionRequest === MeasSetsButtonActions.saveChartConfig) {
        this.saveChartConfig();
        this.props.clearActionRequest();
      }
    }

    if (this.state.chartType && this.state.chartType !== prevState.chartType) {
      // show empty timescale input when user changes chartType
      // TODO: This is ignored, as for now timeScaleType is set according to chartType
      // this.setState({ timeScaleType: "" });

      if (this.state.scale.min || this.state.scale.max) {
        // show empty scale inputs when user changes chartType and had set scale values before
        this.setState({ scale: { min: "", max: "" } });
      }
    }
    const validationResult = this.validateChartConfig();

    // if validationResult !== this.props.chartConfigValidityStatus is not checked, validityStatus is not set to invalid when
    // there is invalid value in a scale input and chartType is changed to number display -> Needs refactoring to decrease re-renders?
    if (validationResult !== prevProps.chartConfigValidityStatus || validationResult !== this.props.chartConfigValidityStatus) {
      this.props.setChartConfigValidityStatus(validationResult);
    }

    //Clear aggregateLength value in parent component and set aggregateLength option to false if the user changes chart type to gauge from something else
    // **uncomment when aggregating functionality is implemented**
    /* if (this.state.chartType !== prevState.chartType && this.state.chartType === ChartType.GAUGE) {
      this.props.toggleAggregateLength(null);
      this.setState({ aggregateLengthOptionActivated: false });
    } */
  }

  private renderErrorPopup(): ReactNode {
    const id = this.state.errorPopUpOpen ? "chart-config-error-popup" : undefined;
    return (
      <Popover
        anchorReference="anchorPosition"
        anchorPosition={{ top: 200, left: 800 }}
        id={id}
        PaperProps={{ style: { height: "4rem", display: "flex", alignItems: "center", padding: "1rem", border: "1px solid #0069FF" } }}
        open={this.state.errorPopUpOpen}
        onClose={(): void => this.setState({ errorPopUpOpen: false })}
      >
        {this.renderErrorPopupContent()}
      </Popover>
    );
  }

  private renderErrorPopupContent(): ReactNode {
    switch (this.props.chartConfigValidityStatus) {
      case ChartConfigValidityStatus.invalidWithMissingValues:
        return (
          <ErrorMessage
            customMessage={this.text("ChartConfigEditView", "missingValuesError")}
          />
        );
      case ChartConfigValidityStatus.invalidWithScaleInputError:
        return (
          <ErrorMessage
            customMessage={this.text("ChartConfigEditView", "scaleValueError")}
          />
        );
      case ChartConfigValidityStatus.invalidWithSensorLimitReached:
        return (
          <ErrorMessage
            customMessage={this.text("ChartConfigEditView", "sensorLimitReachedError")}
          />
        );
      default:
        return null;
    }
  }

  public render(): ReactNode {
    return (
      <Fragment>
        {this.state.errorPopUpOpen &&
        this.renderErrorPopup()}
        <Box display="flex" height="3rem" justifyContent="flex-start">
          <Box width="20%">
            <Typography variant="body1" color="textSecondary">
              {this.text("ChartConfigEditView", "nameOfChartConfig")}
            </Typography>
          </Box>
          <Box>
            <TextField
              value={this.props.displayName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.props.onNameChange(event.target.value, "chartConfig")}
            />
          </Box>
          {!this.props.displayName && <ErrorMessage ml={4}/>}
        </Box>
        <Section
          title={this.text("ChartConfigEditView", "configureSensors")}
          titleTextStyle="h6" >
          <SensorNameAndEventSelection
            dataConfig={this.state.dataConfig}
            // let user save first config without validation and after validate the current dataConfig before another one can be saved
            isOkToSave={this.state.dataConfig !== null ? this.validateDataConfig(this.state.dataConfig.length - 1) : true}
            isSensorLimitReached={this.isSensorLimitReached()}
            saveDataConfig={this.saveDataConfig}
            onSensorDisplayNameChange={this.onSensorDisplayNameChange}
            onSensorSelect={this.onSensorSelect}
            removeDataConfig={this.removeDataConfig}
          />
        </Section>
        <Section
          title={this.text("ChartConfigEditView", "selectDataVisualization")}
          titleTextStyle="h6" >
          <Grid container>
            <Grid item xs={12} lg={6}>
              <DataVisualization
                aggregateLength={this.props.aggregateLength}
                activeScaleInput={this.state.activeScaleInput}
                availableSensorColors={this.getAvailableSensorColors()}
                chartType={this.state.chartType}
                dataConfig={this.state.dataConfig}
                toggleAggregateLength={this.props.toggleAggregateLength}
                onAggregateLengthChange={this.props.onAggregateLengthChange}
                onChartTypeChange={(chartType: ChartType): void => this.onChartTypeChange(chartType)}
                onScaleValueChange={(event: React.ChangeEvent<HTMLInputElement>, inputType): void => this.onScaleValueChange(validatePartialNumber(event.target.value) ? event.target.value : null, inputType)}
                onSensorColorSelect={(index, sensorColor): void => this.onSensorColorSelect(index, sensorColor as SensorColorType)}
                onTimeScaleTypeChange={(type: string): void => this.setState({ timeScaleType: type as TimeScaleType })}
                scale={this.state.scale}
                scaleError={this.getScaleErrorStatus()}
                timeScaleType={this.state.timeScaleType}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <DataVisualizationPreview
                chartType={this.state.chartType}
                dataConfig={this.state.dataConfig}
                displayName={this.props.displayName}
                scale={this.state.scale}
                scaleError={this.getScaleErrorStatus()}
                timeScaleType={this.state.timeScaleType}
              />
            </Grid>
          </Grid>
        </Section>
      </Fragment>
    );
  }

  private onSensorSelect(deviceDataKey: string, deviceId: string, index: number): void {
    const currentDataConfigArray = [...this.state.dataConfig as DataConfig[]];
    currentDataConfigArray[index] = {
      ...currentDataConfigArray[index],
      deviceId,
      sensorName: deviceDataKey,
    };
    this.setState({ dataConfig: currentDataConfigArray });
  }

  private onSensorDisplayNameChange(index: number, sensorDisplayName: string): void {
    const currentDataConfigArray = [...this.state.dataConfig as DataConfig[]];
    currentDataConfigArray[index] = {
      ...currentDataConfigArray[index],
      sensorDisplayName,
    };
    this.setState({ dataConfig: currentDataConfigArray });
  }

  private onSensorColorSelect(index: number, sensorColor: SensorColorType): void {
    const currentDataConfigArray = [...this.state.dataConfig as DataConfig[]];
    currentDataConfigArray[index] = {
      ...currentDataConfigArray[index],
      sensorColor,
    };
    this.setState({ dataConfig: currentDataConfigArray });
  }

  private onChartTypeChange(chartType: ChartType): void {
    if (chartType === ChartType.LINECHART) {
      this.setState({
        chartType,
        timeScaleType: TimeScaleType.HISTORY,
      });
    } else if (chartType === ChartType.STEPPED_AREA_CHART){
      this.setState({
        chartType,
        timeScaleType: TimeScaleType.AGGREGATE,
      });
    } else {
      this.setState({
        chartType,
        timeScaleType: TimeScaleType.LATEST,
      });
    }
  }

  private onScaleValueChange(value: Nullable<string>, inputType: MinMaxInputType): void {
    if (value !== null) {
      const { min, max } = { ...this.state.scale };

      switch (inputType) {
        case "min":
          this.setState({
            scale: {
              min: value,
              max,
            },
          });
          break;
        case "max":
          this.setState({
            scale: {
              min,
              max: value,
            },
          });
          break;
      }
      this.state.activeScaleInput !== inputType
      &&
      this.setState({ activeScaleInput: inputType });
    } else {
      return;
    }
  }

  private isScaleInputInvalid(inputType: Nullable<MinMaxInputType>): Maybe<boolean> {

    if (inputType !== null) {

      let isScaleValueNumber: boolean;
      let isActiveScaleValueOk: boolean;

      switch (inputType) {
        case "min":
          isScaleValueNumber = this.state.scale?.min !== "" && validateNumber(this.state.scale?.min) === null;
          isActiveScaleValueOk = isDefinedAndBiggerOrEqual(parseFloat(this.state.scale?.min), parseFloat(this.state.scale?.max)) && this.state.activeScaleInput === "min";
          return isScaleValueNumber || isActiveScaleValueOk;
        case "max":
          isScaleValueNumber = this.state.scale?.max !== "" && validateNumber(this.state.scale?.max) === null;
          isActiveScaleValueOk = isDefinedAndSmallerOrEqual(parseFloat(this.state.scale?.max), parseFloat(this.state.scale?.min)) && this.state.activeScaleInput === "max";
          return isScaleValueNumber || isActiveScaleValueOk;
        default:
          console.error("error in DataVisualizationSelection: isScaleInputInvalid was called with unknown argument");
          return undefined;
      }
    } else {
      return undefined;
    }

  }

  private getScaleErrorStatus(): Maybe<ScaleInputErrorStatus> {
    if ((this.state.scale.max && !this.state.scale.min) || (!this.state.scale.max && this.state.scale.min)) {
      return ScaleInputErrorStatus.otherValueIsMissing;
    } else if (this.isScaleInputInvalid(this.state.activeScaleInput)) {
      return ScaleInputErrorStatus.invalidValue;
    } else {
      return undefined;
    }
  }

  public saveChartConfig(): void {
    if (this.props.chartConfigValidityStatus === ChartConfigValidityStatus.valid) {
      const isNew = this.props.index === null ? true : false;
      const configWithRequiredFields: ChartConfig = {
        chartType: this.state.chartType as ChartType,
        dataConfig: this.state.dataConfig as DataConfig[],
        displayName: this.props.displayName as string,
        timeScaleType: this.state.timeScaleType as TimeScaleType,
      };
      const configWithOptionalFields: ChartConfig = {
        ...configWithRequiredFields,
        ...((this.state.scale?.min && this.state.scale?.max)
        &&
        { scale: { min: parseFloat(this.state.scale.min), max: parseFloat(this.state.scale.max) } }),
      };
      this.props.saveChartConfig(configWithOptionalFields, this.props.index, isNew);
    } else {
      this.setState({ errorPopUpOpen: true });
    }
  }

  public validateChartConfig(): ChartConfigValidityStatus {
    let flag = ChartConfigValidityStatus.valid;

    const { chartType, dataConfig } = this.state;
    const isChartTypeWithOneAllowedSensor = chartType === ChartType.GAUGE || chartType === ChartType.NUMBER_DISPLAY || chartType === ChartType.STEPPED_AREA_CHART;
    const isMoreThanOneDataConfigCreated = dataConfig !== null && dataConfig.length > 1;

    if (isChartTypeWithOneAllowedSensor && isMoreThanOneDataConfigCreated) {
      flag = ChartConfigValidityStatus.invalidWithSensorLimitReached;
    }

    const isChartTypeWithScaleProperty = chartType === ChartType.LINECHART || chartType === ChartType.NUMBER_DISPLAY || chartType === ChartType.STEPPED_AREA_CHART;

    if (isChartTypeWithScaleProperty) {
      if (this.getScaleErrorStatus() === ScaleInputErrorStatus.invalidValue) {
        flag = ChartConfigValidityStatus.invalidWithScaleInputError;
      } else if (this.getScaleErrorStatus() === ScaleInputErrorStatus.otherValueIsMissing) {
        flag = ChartConfigValidityStatus.invalidWithMissingValues;
      }
    }

    if (this.state.chartType === "") {
      flag = ChartConfigValidityStatus.invalidWithMissingValues;
    }

    if (this.props.displayName === "") {
      flag = ChartConfigValidityStatus.invalidWithMissingValues;
    }

    if (this.state.timeScaleType === "") {
      flag = ChartConfigValidityStatus.invalidWithMissingValues;
    }

    if (this.state.dataConfig === null) {
      flag = ChartConfigValidityStatus.invalidWithMissingValues;
    }

    if (this.state.dataConfig !== null) {
      // check if the newest dataConfig is valid. If there are more than one the rest have been checked when adding new dataConfig
      if (this.validateDataConfig(this.state.dataConfig.length - 1) === false) {
        flag = ChartConfigValidityStatus.invalidWithMissingValues;
      }
    }
    return flag;
  }

  private saveDataConfig(): void {
    const isFirstDataConfig = this.state.dataConfig === null;
    const configToSave: DataConfig = {
      deviceId: "",
      sensorColor: this.getAvailableSensorColors() ? (this.getAvailableSensorColors() as SensorColorType [])[0] : SensorColorType.BLUE,
      sensorDisplayName: "",
      sensorName: "",
    };

    if (isFirstDataConfig) {
      this.setState({ dataConfig: [configToSave] });
    } else {
      const newDataConfigArray = [...this.state.dataConfig as DataConfig[]];
      newDataConfigArray.push(configToSave);
      this.setState({ dataConfig: newDataConfigArray });
    }
  }

  private validateDataConfig(index: number): boolean {
    return Object.values((this.state.dataConfig as DataConfig[])[index]).every((value: string) => value !== "");
  }

  private isSensorLimitReached(): boolean {
    const { chartType, dataConfig } = this.state;
    const isChartTypeWithOneAllowedSensor = chartType === ChartType.GAUGE || chartType === ChartType.NUMBER_DISPLAY || chartType === ChartType.STEPPED_AREA_CHART;
    const isOneDataConfigAlreadyCreated = dataConfig !== null && dataConfig.length > 0;
    return isChartTypeWithOneAllowedSensor && isOneDataConfigAlreadyCreated;
  }

  private removeDataConfig(indexToRemove: number): void {
    const currentDataConfigArray = [...this.state.dataConfig as DataConfig[]];
    let newDataConfigArray: Nullable<DataConfig[]> = currentDataConfigArray.filter((config: DataConfig, index: number) => {
      return index !== indexToRemove;
    });

    if (newDataConfigArray.length === 0) {
      newDataConfigArray = null;
    }

    this.setState({ dataConfig: newDataConfigArray });
  }

  public getAvailableSensorColors(): Maybe<SensorColorType[]> {
    if (this.state.dataConfig !== null) {
      const config = this.state.dataConfig as DataConfig[];
      const availableSensorColors: SensorColorType[] = [];
      config.forEach((c: DataConfig) => {
        availableSensorColors.push(c.sensorColor);
      });
      const unAvailableSensorColors = Object.values(SensorColorType).filter((color: string) => {
        return !availableSensorColors.includes(color as SensorColorType);
      });
      return unAvailableSensorColors;
    } else {
      return undefined;
    }
  }

  private getStateValuesFromProps(): State {
    return {
      activeScaleInput: null,
      aggregateLengthOptionActivated: this.props.aggregateLength === null ? false : true,
      chartType: this.props.chartConfig?.chartType ?? "",
      dataConfig: this.props.chartConfig?.dataConfig ?? null,
      timeScaleType: this.props.chartConfig?.timeScaleType ?? "",
      errorPopUpOpen: false,
      scale: this.props.chartConfig?.scale
        ?
        {
          min: this.props.chartConfig?.scale.min.toString(),
          max: this.props.chartConfig?.scale.max.toString(),
        }
        :
        {
          min: "",
          max: "",
        },
    };
  }

  private getEmptyState(): State {
    return {
      activeScaleInput: null,
      aggregateLengthOptionActivated: this.props.aggregateLength === null ? false : true,
      chartType: "",
      dataConfig: null,
      timeScaleType: "",
      errorPopUpOpen: false,
      scale: {
        min: "",
        max: "",
      },
    };
  }
}

export default withTheme(withDataJanitor(ChartConfigCreateView, [
  DataRepositories.Device,
]));
