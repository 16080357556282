/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import DeviceState from "../../../data/device/DeviceState";
import { Nullable } from "../../../types/aliases";
import { SensorHWStateProperties } from "./SensorHWStateProperties";

export class SensorHWState extends DeviceState<SensorHWStateProperties> {
  
  public get displayName(): Nullable<string> {
    return this.desired.displayName;
  }

  public set displayName(name: Nullable<string>) {
    this.changedValues.displayName = name;
  }

  public deltaExists(): boolean {
    return false;
  }

  public revert(): void {
    this.changedValues = {};
  }
}
