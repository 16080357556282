import React, { Component, ReactNode } from "react";
import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import { Maybe, Nullable } from "types/aliases";
import { SensorColorType } from "data/types/measurementSetTypes";
import { ReactComponent as EllipseIcon } from "assets/sensor-color-picker/icons/EllipseIcon.svg";
import SIconButton from "components/styled-components/SIconButton";
import SSvgIcon from "components/styled-components/SSvgIcon";

interface Props {
  availableColors: Maybe<SensorColorType[]>;
  selectedColor: SensorColorType;
  index: number;
  onColorSelect: (index: number, color: string) => void;
}

interface State {
  colorPickerAnchorEl: Nullable<HTMLButtonElement>;
}

class SensorColorPicker extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      colorPickerAnchorEl: null,
    };
  }

  public render(): ReactNode {
    return (
      <Box width="15%">
        <SIconButton
          onClick={(event: React.MouseEvent<HTMLButtonElement>): void => this.setState({ colorPickerAnchorEl: event.currentTarget })}
        >
          <SSvgIcon
            color={this.props.selectedColor}
            iconComponent={EllipseIcon}
            size="1.25rem"
          />
        </SIconButton>
        <Menu
          anchorEl={this.state.colorPickerAnchorEl}
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          keepMounted
          open={Boolean(this.state.colorPickerAnchorEl)}
          onClose={(): void => this.setState({ colorPickerAnchorEl: null })}
        >
          {this.props.availableColors?.map((color: string, index: number) => {
            return (
              // display set to "inline-block" to form a horizontal menu
              <MenuItem key={index} style={{ display: "inline-block" }}>
                <IconButton
                  onClick={(): void => {
                    this.props.onColorSelect(this.props.index, color);
                    this.setState({ colorPickerAnchorEl: null });
                  }}>
                  <SSvgIcon
                    color={color}
                    iconComponent={EllipseIcon}
                    size="1.25rem"
                  />
                </IconButton>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    );
  }
}

export default SensorColorPicker;
