import React, { Component, ReactNode } from "react";
import { Box, Breadcrumbs, Divider, Link, Typography } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { BreadcrumbData } from "types/sensoanUiTypes";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";

interface Props {
  breadcrumbList: BreadcrumbData[];
  itemsAfterCollapse?: number;
  itemsBeforeCollapse?: number;
  maxItems?: number;
  minWidth?: string;
}

interface State {
  // Collapsing is not used atm
  // Collapsing is not removed because it might be needed later if longer crumbs are used
  collapsed: boolean;
}

export const WRAPPER_TITLE_TOP_PADDING = "8px";
export const WRAPPER_TITLE_BOTTOM_PADDING = "8px";

class WrapperTitle2 extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.breadcrumbList !== this.props.breadcrumbList && this.props.maxItems) {
      if (this.props.breadcrumbList.length > this.props.maxItems) {
        this.setState({ collapsed: true });
      } else {
        this.setState({ collapsed: false });
      }
    }
  }

  private renderBreadCrumbLinks(): ReactNode[] {
    const links: ReactNode[] = [];

    if (this.state.collapsed) {

      const collapsedElement: BreadcrumbData = {
        name: "",
        link: (): void => this.setState({ collapsed: false }),
      };

      const collapsedBreadcrumbList = [
        ...this.props.breadcrumbList.slice(0, this.props.itemsBeforeCollapse), // get elements from start until first collapse point
        collapsedElement, // add collapsed element as first element from first collapse point
        ...this.props.breadcrumbList.slice(this.props.breadcrumbList.length - (this.props.itemsAfterCollapse as number)), // get elementa from second collapse point until end
      ];

      // create a breadcrumb link for all other items except the last one
      for (let i = 0; i <= collapsedBreadcrumbList.length - 2; i++) {
        links.push(
          i === this.props.itemsBeforeCollapse // if item is at first collapse point, make it an icon
            ?
            <Link key={i} underline="none" onClick={collapsedBreadcrumbList[i].link}>
              <SSvgIcon color={SSvgIconColorProps.primary} iconComponent={MoreHorizIcon} />
            </Link>
            :
            <Link key={i} underline="none" onClick={collapsedBreadcrumbList[i].link}>
              <Typography variant="h4" color="textSecondary">
                {this.getBreadcrumbName(this.props.breadcrumbList[i].name)}
              </Typography>
            </Link>,
        );
      }
    } else {
      // create a breadcrumb link for all other items except the last one
      for (let i = 0; i <= this.props.breadcrumbList.length - 2; i++) {
        links.push(
          <Link key={i} underline="none" onClick={this.props.breadcrumbList[i].link}>
            <Typography variant="h4" color="textSecondary">
              {this.getBreadcrumbName(this.props.breadcrumbList[i].name)}
            </Typography>
          </Link>,
        );
      }
    }
    return links;
  }

  private getBreadcrumbName(breadcrumbName: string | (() => string)): string {
    if (typeof breadcrumbName === "function") {
      return breadcrumbName();
    } else {
      return breadcrumbName;
    }
  }

  private renderBreadCrumbs(): ReactNode {
    const titleItem = this.props.breadcrumbList[this.props.breadcrumbList.length - 1];
    return (
      <Breadcrumbs aria-label="breadcrumb">
        {this.renderBreadCrumbLinks()}
        <Typography
          // adjust key so that it is not the same as the last element of array returned by renderBreadCrumbLinks()
          key={this.state.collapsed ? this.props.breadcrumbList.length - 2 : this.props.breadcrumbList.length - 1}
          variant="h4"
          color="textPrimary"
          style={{ cursor: "default" }}
        >
          {this.getBreadcrumbName(titleItem.name)}
        </Typography>
      </Breadcrumbs>
    );
  }

  public render(): ReactNode {
    return (
      <Box minWidth={this.props.minWidth}>
        <Box m={0} pt={WRAPPER_TITLE_TOP_PADDING} pb={WRAPPER_TITLE_BOTTOM_PADDING} display="flex" flexWrap="wrap" justifyContent="space-between">
          {this.renderBreadCrumbs()}
          {this.props.children}
        </Box>
        <Divider />
      </Box>
    );
  }
}

export default WrapperTitle2;
