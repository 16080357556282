import React, { Component, ReactNode, Fragment } from "react";
import { ListItemText, TextField } from "@material-ui/core";
import BackendFactory from "data/BackendFactory";
import Localization from "data/localization-sensoan/Localization";
import DeviceGroup from "data/device/DeviceGroup";
import FormDialog from "components/ui/form-dialog";
import ErrorDialog from "components/ui/error-dialog";
import Loader from "components/ui/loader";

interface Props {
  parentGroup: DeviceGroup;
  open: boolean;
  // success: device group was created and added
  onClose: (success: boolean) => void;
}

interface State {
  loading: boolean;
  name: string;
  errorMessage?: string;
}

export default class AddGroupPopup extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      loading: false,
    };
  }

  private createNewGroup = async (): Promise<void> => {
    if (!this.formIsValid()) {
      throw new Error("Invalid control flow");
    }

    this.setState({ loading: true });

    try {
      await BackendFactory.getBackend().createDeviceGroup({
        displayName: this.state.name!,
        parentGroup: this.props.parentGroup,
      });
      this.props.onClose(true);
    } catch (error) {
      console.error("Failed to create group", error);
      this.setState({
        errorMessage: this.text("AdminView", "failedToAddGroup"),
      });
      this.props.onClose(false);
    } finally {
      this.setState({ loading: false });
    }
  };

  private formIsValid(): boolean {
    return !!this.state.name && this.state.name.length > 3;
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <ErrorDialog
          onClose={(): void => {
            this.setState({ errorMessage: undefined });
            this.props.onClose(false);
          }}
          errorMsg={this.state.errorMessage}
        />
        <FormDialog
          title={this.text("AdminView", "addSubGroup")}
          acceptButtonText={this.text("Actions", "add")}
          isOpen={this.props.open}
          onAccept={this.createNewGroup}
          onCancel={(): void => this.props.onClose(false)}
          disableAccept={!this.formIsValid()}
        >
          <ListItemText id="list-label-new-popup-name" primary={this.text("AdminView", "groupName")} />
          <TextField
            value={this.state.name}
            onChange={(event): void =>
              this.setState({ name: event.currentTarget.value })}
          />
          <Loader show={this.state.loading}/>
        </FormDialog>
      </Fragment>
    );
  }
}
