import React, { Component, Fragment, ReactNode } from "react";
import { Box, MenuItem, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Maybe, Nullable } from "types/aliases";
import Localization from "data/localization-sensoan/Localization";
import { LocationInfo, MeasSetLocationOption, MeasSetLocationTextTarget } from "types/sensoanUiTypes";
import LatestDeviceDataRepository from "data/data-storage/LatestDeviceDataRepository";
import Data from "data/data/Data";
import Device from "data/device/Device";
import SSelect from "components/styled-components/SSelect";
import SButton from "components/styled-components/SButton";
import withDataJanitor from "components/hocs/DataJanitor";
import { DataRepositories } from "data/data-storage/DataRepositoryFactory";
import { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import DeviceSelect2 from "components/inputs/DeviceSelect2";
import DeviceNavigationCache from "utils/DeviceNavigationCache";

interface Props {
  notificationNeeded: boolean;
  locationDeviceName: Nullable<string>;
  onLocationOptionChange: (option: MeasSetLocationOption) => Promise<void>;
  placeLocation: Nullable<LocationInfo>;
  selectedLocationOption: Nullable<MeasSetLocationOption>;
}

interface State {
  deviceSelectorAnchorEl: Nullable<HTMLButtonElement>;
}

class SetsLocationSelector extends Component<Props, State> {

  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      deviceSelectorAnchorEl: null,
    };
  }

  private renderLocationOptionSelector(): ReactNode {
    const options = Object.keys(MeasSetLocationOption);
    return (
      <SSelect buttonText={this.props.selectedLocationOption !== null ? this.getLocalisedLocationText(this.props.selectedLocationOption, "locationOptionSelector") : this.text("MeasurementSetLocationSelector", "select")}>
        {options.map((option: string, index: number) => {
          return (
            <MenuItem
              selected={option === this.props.selectedLocationOption}
              key={index}
              onClick={(): Promise<void> => this.props.onLocationOptionChange(option as MeasSetLocationOption)}>
              {this.getLocalisedLocationText(option as MeasSetLocationOption, "locationOptionSelector")}
            </MenuItem>
          );
        })}
      </SSelect>
    );
  }

  private renderPlaceLocationDisplay(): JSX.Element {
    return (
      <Box display="flex">
        {this.props.placeLocation
          &&
          <Typography variant="body1" color="textPrimary">
            {this.getLocationText(this.props.placeLocation)}
          </Typography>
        }
      </Box>
    );
  }

  private getLocationText(locationInfo: LocationInfo): string {
    if (typeof locationInfo === "function") {
      return locationInfo();
    } else {
      return locationInfo.title;
    }
  }

  private renderLocationNotification(): ReactNode {
    return (
      this.props.notificationNeeded
      &&
      <Typography component="div" variant="body2" color="textPrimary" style={{ marginLeft: "1rem" }}>
        {this.text("MeasurementSetLocationSelector", "locationNotification")}
      </Typography>
    );
  }

  private renderGpsFunctionDisplay(): Maybe<string | ReactNode> {
    return (
      <Fragment>
        {/* TODO: replace DeviceSelect2 and SButton with SSelect */}
        {this.state.deviceSelectorAnchorEl !== null
        &&
        <DeviceSelect2
          anchorEl={this.state.deviceSelectorAnchorEl}
          close={(): void => this.setState({ deviceSelectorAnchorEl: null })}
          deviceFilter={this.passDeviceItemWithLocationData}
        />}
        <SButton
          color="secondary"
          ariaControls="location-from-device-menu"
          ariaHaspopup="true"
          endIcon={ExpandMoreIcon}
          iconColor={SSvgIconColorProps.textPrimary}
          labelText={this.getButtonText()}
          onClick={(event: React.MouseEvent<HTMLButtonElement>): void => this.setState({ deviceSelectorAnchorEl: event.currentTarget })}
        />
      </Fragment>
    );
  }

  private getButtonText(): string {
    if (!this.props.locationDeviceName) {
      return this.text("Common", "device");
    } else {
      return this.props.locationDeviceName;
    }
  }

  private renderLocationValueSelector(): ReactNode {
    // REFACTOR: replace with Object.values(MeasSetLocationOption).includes(this.props.selectedLocationOption)
    if (this.props.selectedLocationOption === MeasSetLocationOption.gpsLocation ||
      this.props.selectedLocationOption === MeasSetLocationOption.gpsFunction) {
      return (
        // REFACTOR: remove unneeded Fragment
        <Fragment>
          <Box display="flex" height="3rem" justifyContent="flex-start">
            <Box width="20%">
              <Typography variant="body1" color="textSecondary">
                {this.getLocalisedLocationText(this.props.selectedLocationOption, "locationInfoText")}
              </Typography>
            </Box>
            <Box>
              {this.props.selectedLocationOption === MeasSetLocationOption.gpsLocation
                ?
                this.renderPlaceLocationDisplay()
                :
                this.renderGpsFunctionDisplay()}
            </Box>
            {!DeviceNavigationCache.getInstance().getSelectedDevice() && this.renderLocationNotification()}
          </Box>
        </Fragment>

      );
      // REFACTOR: remove unneeded else
    } else {
      return null;
    }
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <Box display="flex" height="3rem" justifyContent="flex-start">
          <Box width="20%">
            <Typography variant="body1" color="textSecondary">
              {this.text("Common", "location")}
            </Typography>
          </Box>
          <Box>
            {this.renderLocationOptionSelector()}
          </Box>
        </Box>
        {this.renderLocationValueSelector()}
      </Fragment>
    );
  }

  private getLocalisedLocationText(option: MeasSetLocationOption, target: MeasSetLocationTextTarget): string {
    let text;

    if (option === MeasSetLocationOption.gpsFunction) {
      if (target === "locationOptionSelector") {
        text = this.text("MeasurementSetLocationSelector", "gpsFunctionOption");
      } else if (target === "locationInfoText"){
        text = this.text("Common", "device");
      } else {
        console.error("Unknown target in MeasurementSetEditSetView.tsx / getLocalisedLocationOption()");
        text = "???";
      }
    } else if (option === MeasSetLocationOption.gpsLocation){
      if (target === "locationOptionSelector") {
        text = this.text("MeasurementSetLocationSelector", "gpsLocationOption");
      } else if (target === "locationInfoText"){
        text = this.text("MeasurementSetLocationSelector", "selectedLocation");
      } else {
        console.error("Unknown target in MeasurementSetEditSetView.tsx / getLocalisedLocationOption()");
        text = "???";
      }
    } else {
      console.error("Unknown option in MeasurementSetEditSetView.tsx / getLocalisedLocationOption()");
      text = "???";
    }
    return text;
  }

  private passDeviceItemWithLocationData(device: Device): boolean {
    let check = false;

    const latestDeviceData: Nullable<Data> = LatestDeviceDataRepository.getInstance().getData(device.getId());

    if (latestDeviceData) {
      const wantedKeys = ["latitude", "longitude"];
      check = wantedKeys.every((key: string)=> Object.keys(latestDeviceData).includes(key));
    }
    return check;
  }
}

export default withDataJanitor(SetsLocationSelector, [
  DataRepositories.Device,
]);
