import React, { Component } from "react";
import { Box, MenuItem, Popover, TextField, Theme, Typography, withTheme } from "@material-ui/core";
import SButton from "components/styled-components/SButton";
import InfoPopover from "components/layout/InfoPopover";
import SensoanBackend from "data/backend/SensoanBackend";
import SSelect from "components/styled-components/SSelect";
import Localization from "data/localization-sensoan/Localization";
import AppViews from "client/AppViews";
import { CustomSpacingProps } from "types/sensoanUiTypes";

const bugSeverities = [
  "minor",
  "normal",
  "fatal",
];

const deviceAreas = [
  "gateway",
  "ruuviTag",
  "other",
];

//TODO: if PathsSensoan is kept, update localization table in DynamoDB and remove substring(1)
const viewAreas = [...AppViews.map(view => view.path.substring(1)), "userManagement"]; // paths start always with a "/"

interface Props {
  reporter: string;
  backend_version: string;
  theme: Theme;
  m?: CustomSpacingProps;
  mt?: CustomSpacingProps;
  mr?: CustomSpacingProps;
  mb?: CustomSpacingProps;
  ml?: CustomSpacingProps;
}

interface State {
  areaMajor: string;
  areaMinor: string;
  subject: string;
  severity: string;
  reproduce: string;
  current: string;
  expected: string;
  statusText: string;
}

class BugReport extends Component<Props, State> {

  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      areaMajor: "",
      areaMinor: "",
      subject: "",
      severity: "",
      reproduce: "",
      current: "",
      expected: "",
      statusText: "",
    };
  }

  public render(): JSX.Element {
    return (
      <InfoPopover
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        title={this.text("BugReport", "title")}
        actionButtons={[this.getSendButton()]}
        m={this.props.m}
        mt={this.props.mt}
        mb={this.props.mb}
        ml={this.props.ml}
        mr={this.props.mr}
      >
        <Box m={4}>
          <Typography variant="h6" color="textSecondary">
            {this.text("BugReport", "subject")}
          </Typography>
          <TextField
            value={this.state.subject}
            multiline={true}
            size="small"
            fullWidth={true}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.setState({ subject: event.target.value })}
          />
          <SSelect mt={2} mb={2} buttonText={this.state.areaMajor === "" ? this.text("BugReport", "area") : this.text("BugReport", this.state.areaMajor) + " - " + this.state.areaMinor === "" ? this.text("BugReport", "subfield") : this.text("BugReport", this.state.areaMinor)}>
            {viewAreas.map((type, key): JSX.Element => {
              return (
                <MenuItem key={key} onClick={(): void => this.setState({ areaMajor: "webUi", areaMinor: type }) }>
                  {this.text("BugReport", "webUi") + " - " + this.text("BugReport", type)}
                </MenuItem>
              );
            })}
            {Object.values(deviceAreas).map((type, key): JSX.Element => {
              return (
                <MenuItem key={key} onClick={(): void => this.setState({ areaMajor: "device", areaMinor: type }) }>
                  {this.text("Common", "device") + " - " + this.text("BugReport", type)}
                </MenuItem>
              );
            })}
          </SSelect>
          <Typography color="textSecondary">
            {this.text("BugReport", "reproduce")}
          </Typography>
          <TextField
            value={this.state.reproduce}
            multiline={true}
            size="small"
            fullWidth={true}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.setState({ reproduce: event.target.value })}
          />
          <Typography color="textSecondary">
            {this.text("BugReport", "current")}
          </Typography>
          <TextField
            value={this.state.current}
            multiline={true}
            size="small"
            fullWidth={true}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.setState({ current: event.target.value })}
          />
          <Typography color="textSecondary">
            {this.text("BugReport", "correct")}
          </Typography>
          <TextField
            value={this.state.expected}
            multiline={true}
            size="small"
            fullWidth={true}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.setState({ expected: event.target.value })}
          />
          <SSelect mt={2} buttonText={this.state.severity === "" ? this.text("BugReport", "severity") : this.text("BugReport", this.state.severity)}>
            {Object.values(bugSeverities).map((type, key): JSX.Element => {
              return (
                <MenuItem key={key} onClick={(): void => this.setState({ severity: type }) }>
                  {this.text("BugReport", type)}
                </MenuItem>
              );
            })}
          </SSelect>
        </Box>
        <Popover
          open={this.state.statusText !== ""}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={(): void => this.setState({ statusText: "" })}
        >
          <Box bgcolor={this.props.theme.palette.background.default} p={2}>
            <Typography variant="h5">
              {this.state.statusText !== "" && this.text("BugReport", this.state.statusText)}
            </Typography>
            <Box display="flex" mt={2} justifyContent="flex-end">
              <SButton m={2}
                labelText={Localization.getInstance().getDisplayText("Actions", "close")}
                color={undefined}
                onClick={(): void => this.setState({ statusText: "" })}
              />
            </Box>
          </Box>
        </Popover>
      </InfoPopover>
    );
  }

  private validateData(): string {
    if (
      this.state.areaMajor !== "" &&
      this.state.areaMinor !== "" &&
      this.state.subject !== "" &&
      this.state.severity !== "" &&
      this.state.reproduce !== "" &&
      this.state.current !== "" &&
      this.state.expected !== ""
    ) {
      return "send";
    } else {
      return "dataError";
    }
  }

  private async send(): Promise<void> {
    const status = this.validateData();

    if (status === "send") {
      this.setState({ statusText: status });
      const subject = this.buildSubject();
      const msgBody = this.buildMsgBody();
      await SensoanBackend.getInstance().sendBugReport(subject, msgBody);
      this.setState({
        areaMajor: "",
        areaMinor: "",
        subject: "",
        severity: "",
        reproduce: "",
        current: "",
        expected: "",
        statusText: "sent",
      });
    } else {
      this.setState({ statusText: "dataError" });
    }
  }

  private getSendButton(): JSX.Element {
    return (
      <SButton
        key="SendBugReportButton"
        m={2}
        labelText={this.text("Actions", "send")}
        onClick={async (): Promise<void> => this.send()}
      />
    );
  }

  private buildSubject(): string {
    return `${this.state.areaMajor}:${this.state.areaMinor} - ${this.state.severity === "" ? this.text("BugReport", "minor") : this.state.severity} - ${this.state.subject}`;
  }

  private buildMsgBody(): string {
    return `
## Reporter

${this.props.reporter}

## SW version

### Web UI SW

${process.env.REACT_APP_VERSION_STRING}

### Backend SW

${this.props.backend_version}

## Steps to reproduce

${this.state.reproduce}

## What is the current bug behavior?

${this.state.current}

## What is the expected correct behavior?

${this.state.expected}
`;
  }
}

export default withTheme(BugReport);
