import React, { Component, ReactNode } from "react";
import { Maybe, Nullable } from "types/aliases";
import { ScaleInputErrorStatus } from "types/sensoanUiTypes";
import * as Utils from "data/utils/Utils";
import { ChartConfig, ChartType, DataConfig, MeasurementSetConfig, MeasurementSetType, TimeScaleType } from "data/types/measurementSetTypes";
import MeasurementSetDataContainer from "data/measurement-data/MeasurementSetDataContainer";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import MeasurementSetCharts from "components/charts/MeasurementSetCharts";
import PreviewPlaceholderBox from "./PreviewPlaceholderBox";
import LoaderSensoan from "components/layout/LoaderSensoan";

interface Props {
  chartType: ChartType | string;
  dataConfig: Nullable<DataConfig[]>;
  displayName: string;
  scale: {
    min: string;
    max: string;
  };
  scaleError: Maybe<ScaleInputErrorStatus>;
  timeScaleType: TimeScaleType | string;
}

interface State {
  dataInitialized: boolean;
  measurementSetData: Nullable<MeasurementSetDataContainer>;
  measurementSetForPreview: Nullable<MeasurementSetConfig>;
}

export const PREVIEW_TIMESPAN = 1;
export const PREVIEW_SET_ID = "previewSetId";
export const PREVIEW_SET_DISPLAY_NAME = "previewSet";

class DataVisualizationPreview extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      dataInitialized: false,
      measurementSetData: null,
      measurementSetForPreview: null,
    };
    this.onMeasurementSetDataReady = this.onMeasurementSetDataReady.bind(this);
  }

  public componentDidMount(): void {
    this.setState({
      dataInitialized: false,
      measurementSetData: this.buildMeasurementSetDataContainer(),
    });
  }

  public componentDidUpdate(prevProps: Props) : void {

    if (this.props.dataConfig === null && prevProps.dataConfig !== null) {
      this.setState({
        dataInitialized: false,
        measurementSetData: null,
      });
    }

    if (this.props.scaleError !== undefined && this.state.measurementSetForPreview !== null) {
      this.setState({
        measurementSetForPreview: null,
      });
    }

    if (this.isNewDataContainerNeeded(this.props, prevProps)) {
      this.setState({
        dataInitialized: false,
        measurementSetData: this.buildMeasurementSetDataContainer(),
      });
    }

    if (this.isNewMeasurementSetNeeded(this.props, prevProps)) {
      this.setState({ measurementSetForPreview: this.buildMeasurementSet() });
    }
  }

  public onMeasurementSetDataReady(): void {
    this.setState({ dataInitialized: true, measurementSetForPreview: this.buildMeasurementSet() });
  }

  public render(): ReactNode {
    if (this.state.dataInitialized && this.state.measurementSetForPreview) {
      return (
        <MeasurementSetCharts
          measurementSetData={this.state.measurementSetData}
          previewMode
          selectedMeasurementSet={this.state.measurementSetForPreview}
        />
      );
    } else if (!this.state.dataInitialized && this.state.measurementSetForPreview) {
      return (
        <LoaderSensoan/>
      );
    } else {
      return (
        <PreviewPlaceholderBox/>
      );
    }
  }

  private buildMeasurementSetDataContainer(): Nullable<MeasurementSetDataContainer>{
    const measurementSet = this.buildMeasurementSet();

    if (measurementSet) {
      return new MeasurementSetDataContainer(measurementSet, Date.now(), Utils.convertHoursToTimestamp(PREVIEW_TIMESPAN), this.onMeasurementSetDataReady);
    } else {
      return null;
    }
  }

  private buildMeasurementSet(): Nullable<MeasurementSetConfig> {
    const chartConfig = this.buildChartConfig();

    if (chartConfig) {
      return ({
        setId: PREVIEW_SET_ID,
        parentIds: [MeasurementSetRepository.getInstance().getTree()[0].setId],
        type: MeasurementSetType.CONFIG,
        displayName: PREVIEW_SET_DISPLAY_NAME,
        config: {
          chartConfig: [chartConfig],
        },
      });
    } else {
      return null;
    }
  }

  private buildChartConfig(): Nullable<ChartConfig> {
    if (this.props.dataConfig && this.isSensorAndDeviceSelectedInEveryDataConfig() && this.props.chartType && this.props.timeScaleType) {
      const chartType = this.props.chartType as ChartType;
      return ({
        chartType,
        dataConfig: chartType === ChartType.STEPPED_AREA_CHART ? [this.props.dataConfig[0]] as DataConfig[] : this.props.dataConfig as DataConfig[],
        displayName: this.props.displayName,
        timeScaleType: this.props.timeScaleType as TimeScaleType,
        ...((this.props.scale?.min && this.props.scale?.max)
        &&
        { scale: { min: parseFloat(this.props.scale.min), max: parseFloat(this.props.scale.max) } }),
      });
    } else {
      return null;
    }
  }

  private isNewDataContainerNeeded(currentProps: Props, prevProps: Props): boolean {
    const didChartTypeChange = currentProps.chartType !== prevProps.chartType;
    const didTimeScaleTypeChange = currentProps.timeScaleType !== prevProps.timeScaleType;
    const currentConfig = currentProps.dataConfig;
    const prevConfig = prevProps.dataConfig;
    let wasNewConfigAdded = false;
    let didSensorNameOrDeviceChange = false;

    if (currentConfig !== null && prevConfig !== null) {
      if (currentConfig.length > prevConfig.length) {
        wasNewConfigAdded = true;
      } else if (currentConfig.length === prevConfig.length) {
        didSensorNameOrDeviceChange = currentConfig.some((config, index) => config.deviceId !== prevConfig[index].deviceId || config.sensorName !== prevConfig[index].sensorName);
      }
    }

    if (didChartTypeChange || didTimeScaleTypeChange || wasNewConfigAdded || didSensorNameOrDeviceChange) {
      return true;
    } else {
      return false;
    }
  }

  private isNewMeasurementSetNeeded(currentProps: Props, prevProps: Props): boolean {
    const didScaleChange = this.props.scale.min !== prevProps.scale.min || this.props.scale.max !== prevProps.scale.max;
    const didDisplayNameChange = this.props.displayName !== prevProps.displayName;
    const currentConfig = currentProps.dataConfig;
    const prevConfig = prevProps.dataConfig;
    let wasConfigRemoved = false;
    let didSensorDisplayNameOrColorChange = false;

    if (currentConfig !== null && prevConfig !== null) {
      if (currentConfig.length < prevConfig.length) {
        wasConfigRemoved = true;
      } else if (currentConfig.length === prevConfig.length) {
        didSensorDisplayNameOrColorChange = currentConfig.some((config, index) => config.sensorDisplayName !== prevConfig[index].sensorDisplayName || config.sensorColor !== prevConfig[index].sensorColor);
      }
    }

    if ((didScaleChange || didDisplayNameChange || wasConfigRemoved || didSensorDisplayNameOrColorChange)) {
      return true;
    } else {
      return false;
    }
  }

  private isSensorAndDeviceSelectedInEveryDataConfig(): boolean | undefined {
    return this.props.dataConfig?.every(config => config.sensorName !== "" && config.deviceId !== "");
  }
}

export default DataVisualizationPreview;
