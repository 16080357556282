import tagFaces from "assets/device/icons/tagFaces.svg";
import { SensorHWState } from "./SensorHWState";
import { SensorHWStateProperties } from "./SensorHWStateProperties";
import AWSThing from "data/device/AWSThing";
import AWSBackend from "data/backend/AWSBackend";
import { DeviceParameters } from "data/device/Device";

export class SensorHW extends AWSThing<SensorHWState> {
  public static type = "Sensor";

  public constructor(backend: AWSBackend, params: DeviceParameters) {
    super(SensorHW.type, backend, params);
  }

  public getIcon(): string {
    return tagFaces;
  }

  public createState(timestamp?: number, reported?: Partial<SensorHWStateProperties>, desired?: Partial<SensorHWStateProperties>):
  SensorHWState {
    return new SensorHWState(this.getId(), new SensorHWStateProperties(reported ?? {}), new SensorHWStateProperties(desired ?? {}), timestamp);
  }

}
