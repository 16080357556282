/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { ICredentials } from "@aws-amplify/core";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import awsconfig from "aws-config";
import { BackendActionStatus } from "types/sensoanUiTypes";

export default class S3Tool {
  protected client: S3Client;
  private bucketName: string;

  public constructor(credentials: ICredentials, bucketName: string) {
    this.client = new S3Client({ credentials, region: awsconfig.aws_appsync_region as string });
    this.bucketName = bucketName;
  }

  public async uploadFile(file: File, s3Key: string): Promise<BackendActionStatus> {
    try {
      await this.client.send(
        new PutObjectCommand({
          Bucket: this.bucketName,
          Key: s3Key,
          Body: file,
        }),
      );
      return BackendActionStatus.SUCCESS;
    } catch (error) {
      console.error("error: ", error);
      return BackendActionStatus.ERROR;
    }
  }
}
