/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { ReferenceHWStateProperties } from "../ReferenceHW/ReferenceHWStateProperties";
import { Nullable } from "../../../types/aliases";

export class HyperHWStateProperties extends ReferenceHWStateProperties {
  public updateInterval: Nullable<number>;
  public measurementInterval: Nullable<number>;
  public noModemSleep: Nullable<boolean>;

  public constructor(properties: Partial<HyperHWStateProperties>) {
    super(properties);
    this.updateInterval = properties.updateInterval ?? null;
    this.measurementInterval = properties.measurementInterval ?? null;
    this.noModemSleep = properties.noModemSleep ?? null;
  }
}
