import React, { Component, Fragment, ReactNode } from "react";
import Localization from "data/localization-sensoan/Localization";
import { Box, Theme, Typography, withTheme } from "@material-ui/core";
import { Nullable } from "types/aliases";
import { MeasurementJob } from "data/types/measurementJobTypes";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import CreateIcon from "@material-ui/icons/Create";
import MeasurementJobRepository from "data/data-storage/MeasurementJobRepository";
import CommentEdit from "components/inputs/CommentEdit";
import SIconButton from "components/styled-components/SIconButton";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";

interface Props {
  job: MeasurementJob;
  theme: Theme;
}

interface State {
  editCommentAnchorEl: Nullable<HTMLButtonElement>;
  comment: string;
}

class JobDetails extends Component<Props, State> {

  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      editCommentAnchorEl: null,
      comment: "",
    };
    this.saveComment = this.saveComment.bind(this);
    this.closeEditPopover = this.closeEditPopover.bind(this);
  }

  public componentDidMount(): void {
    this.setState({ comment: this.props.job.metadata ?? "" });
  }

  public saveComment(text: string): void {
    MeasurementJobRepository.getInstance().detailsEdit(this.props.job.setId, this.props.job.jobId, text);
    this.closeEditPopover(text);
  }

  public closeEditPopover(text?: string): void {
    const comment = text ?? this.state.comment;
    this.setState({ editCommentAnchorEl: null, comment });
  }

  private renderRow(title: string, data: string, editIcon?: boolean): JSX.Element {
    const fontSize = this.props.theme.typography.fontSize;
    return (
      <Box width="100%" display="flex" alignItems="center">
        <Box width="20%" display="flex" alignItems="center">
          <Typography color="textSecondary">
            {title}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography>
            {data}
          </Typography>
        </Box>
        {editIcon ?
          <Box ml={2} display="flex" alignItems="center" height={fontSize} width={fontSize}>
            <SIconButton onClick={(event: React.MouseEvent<HTMLButtonElement>): void => this.setState({ editCommentAnchorEl: event.currentTarget })}>
              <SSvgIcon color={SSvgIconColorProps.blueGradient} iconComponent={CreateIcon}/>
            </SIconButton>
          </Box>
          : null
        }
      </Box>
    );
  }

  public render(): ReactNode {
    const setName = MeasurementSetRepository.getInstance().getMeasurementSet(this.props.job.setId)?.displayName ?? "";
    return (
      <Fragment>
        <CommentEdit
          title={this.text("MeasJobsDataView", "editComment")}
          commentText={this.props.job.metadata ?? ""}
          anchorEl={this.state.editCommentAnchorEl}
          saveAction={this.saveComment}
          cancelAction={this.closeEditPopover}
        />
        {this.renderRow(this.text("MeasJobsListView", "jobName"), this.props.job.displayName)}
        {this.renderRow(this.text("MeasJobsListView", "measSet"), setName)}
        {this.renderRow(this.text("MeasJobsListView", "start"), Localization.getInstance().getDateAndTimeString(this.props.job.startTs))}
        {this.renderRow(this.text("MeasJobsListView", "end"), Localization.getInstance().getDateAndTimeString(this.props.job.endTs))}
        {this.renderRow(this.text("MeasJobsListView", "responsible"), this.props.job.responsible)}
        {this.renderRow(this.text("MeasJobsListView", "comments"), this.state.comment, true)}
      </Fragment>
    );
  }
}

export default withTheme(JobDetails);
