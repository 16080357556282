import React, { Component } from "react";
import { Box, InputAdornment, TextFieldProps, Theme, withTheme } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment, { Moment } from "moment";
import MomentUtils from "@date-io/moment";
import { Maybe, Nullable } from "types/aliases";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SButton from "components/styled-components/SButton";
import TimePicker from "./TimePicker";
import "moment/locale/fi";
import { Locales } from "data/localization-sensoan/Locales";
import Localization, { LocalizationObserver } from "data/localization-sensoan/Localization";
import SFilledInput from "components/styled-components/SFilledInput";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import { CustomSpacingProps } from "types/sensoanUiTypes";

interface Props {
  setTimestamp: (timestamp: number) => void;
  theme: Theme;
  timestamp: number;
  dateInputId: string;
  dateInputWidth?: string;
  datePickerLabel?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  maxTimestamp?: number;
  minTimestamp?: number;
  m?: CustomSpacingProps;
  mb?: CustomSpacingProps;
  ml?: CustomSpacingProps;
  mr?: CustomSpacingProps;
  mt?: CustomSpacingProps;
}

interface State {
  locale: Locales; // Moment API uses lowercase locale identifiers but uppercase id works also
  timePickerAnchorEl: Nullable<HTMLButtonElement>;
}

class DateTimePicker extends Component<Props, State> implements LocalizationObserver {

  public constructor(props: Props) {
    super(props);
    this.state = {
      locale: Localization.getInstance().getCurrentLocale(),
      timePickerAnchorEl: null,
    };
  }

  public componentDidMount(): void {
    Localization.getInstance().addObserver(this);
  }

  public componentWillUnmount(): void {
    Localization.getInstance().removeObserver(this);
  }

  public onSelectedLocaleChanged(locale: Locales): void {
    this.setState({ locale });
  }

  private renderDateInput = (props: TextFieldProps): any => { // eslint-disable-line @typescript-eslint/no-explicit-any
    return (
      <SFilledInput
        id={this.props.dateInputId}
        endAdornment={
          <InputAdornment position="end" style={{ cursor: "pointer" }}>
            <SSvgIcon color={SSvgIconColorProps.textPrimary} iconComponent={ExpandMoreIcon} size="1.75rem" />
          </InputAdornment>
        }
        value={props.value as string}
        onChange={props.onChange}
        inputRef={props.inputRef}
        inputProps={{ style:
          { cursor: "pointer", textAlign: "center", paddingRight: 0 },
        }}
        onClick={props.onClick}
        width={this.props.dateInputWidth ?? "11rem"}
        buttonAppearance
      />
    );
  };

  public render(): JSX.Element {
    const time = new Date(this.props.timestamp);
    const date = moment(this.props.timestamp).format(("YYYY-MM-DDTHH:mm"));

    return (
      <Box
        display="flex"
        alignItems="center"
        m={this.props.m}
        mt={this.props.mt}
        mb={this.props.mb}
        ml={this.props.ml}
        mr={this.props.mr}
      >
        <Box mr="1.5rem">
          <MuiPickersUtilsProvider utils={MomentUtils} locale={this.state.locale}>
            <DatePicker
              disablePast={this.props.disablePast}
              disableFuture={this.props.disableFuture}
              autoOk={true}
              disableToolbar
              variant="inline"
              value={date}
              format="DD/MM/YYYY"
              onChange={(date: Nullable<Moment>): void => this.handleDateChange(date)}
              label={this.props.datePickerLabel}
              TextFieldComponent={this.renderDateInput}
              minDate={this.getMinOrMaxDate(this.props.minTimestamp)}
              maxDate={this.getMinOrMaxDate(this.props.maxTimestamp)}
            />
          </MuiPickersUtilsProvider>
        </Box>
        <SButton
          color="secondary"
          endIcon={ExpandMoreIcon}
          iconColor={SSvgIconColorProps.textPrimary}
          labelText={`${time?.getHours() ?? ""} : ${time?.getMinutes().toLocaleString("en-us", { minimumIntegerDigits: 2 }) ?? ""}`}
          onClick={(event: React.MouseEvent<HTMLButtonElement>): void => this.setState({ timePickerAnchorEl: event.currentTarget })}
          widthInRems={8}
          mr="1.5rem"
        />
        <TimePicker
          anchorEl={this.state.timePickerAnchorEl}
          close={(): void => this.setState({ timePickerAnchorEl: null })}
          setTimestamp={(timestamp: number): void => this.props.setTimestamp(timestamp)}
          timeStamp={time.getTime()}
        />
        {this.props.children}
      </Box>
    );
  }

  private getMinOrMaxDate(timestamp: Maybe<number>): Maybe<string> {
    if (timestamp !== undefined) {
      return moment(timestamp).format(("YYYY-MM-DDTHH:mm"));
    }
  }

  private handleDateChange(date: Nullable<Moment>): void {
    if (date) {
      const timestamp = date.unix() * 1000;
      this.props.setTimestamp(timestamp);
    }
  }
}

export default withTheme(DateTimePicker);
