import { Nullable } from "types/aliases";
import { MapLocation } from "./MapLink";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    H: any;
  }
}

export const DEFAULT_CENTER: {lat: number; lng: number} = { lat: 65.0, lng: 26.8 }; 
export const ZOOM_DEFAULT = 4.5;

export interface BaseProvider {
  init(): void;
  getDefaultLayers(): any; // eslint-disable-line @typescript-eslint/no-explicit-any
  addLayer(dataProvider: any): any; // eslint-disable-line @typescript-eslint/no-explicit-any
  getMap(): any; // eslint-disable-line @typescript-eslint/no-explicit-any
  disposeMap(): void;
  getUi(): any; // eslint-disable-line @typescript-eslint/no-explicit-any
  getClustering(): any;// eslint-disable-line @typescript-eslint/no-explicit-any
  getStyleObject(mapTheme: any): any; // eslint-disable-line @typescript-eslint/no-explicit-any 
  getSearchService(): any; // eslint-disable-line @typescript-eslint/no-explicit-any
  getInfoBubbleObject(): any; // eslint-disable-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMarker(type: "domMarker" | "marker", position: any, options: any): any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getIcon(type: "domIcon" | "icon", content: any): any;
  addListener(type: "dbltap" | "resize", uiCallback: () => void): void;
  resize(): void;
  getCenter(): MapLocation;
  setCenter(locationOrArea: MapLocation | MapLocation[]): void;
  getZoom(): number;
  setZoom(level: number): void;
  setLookAtData(data: MapLocation[]): void;
  isInCurrentView(locationOrArea: MapLocation | MapLocation[]): boolean;
}

export default class MapBase implements BaseProvider {
  private static instance: MapBase;

  private H: any = null; // eslint-disable-line @typescript-eslint/no-explicit-any
  private platform: any = null; // eslint-disable-line @typescript-eslint/no-explicit-any
  private map: any = null; // eslint-disable-line @typescript-eslint/no-explicit-any
  private defaultLayers: any = null; // eslint-disable-line @typescript-eslint/no-explicit-any
 
  public static getInstance(): MapBase {
    if (this.instance == null) {
      this.instance = new MapBase();
    }
    return this.instance;
  }

  public init(): void {
    this.H = window.H;
    this.platform = new this.H.service.Platform({
      apikey: process.env.REACT_APP_HERE_MAPS_API_KEY,
    });
    this.defaultLayers = this.platform.createDefaultLayers();

    const mapContainer = document.getElementById("here-status-map");

    /* defaultLayers includes traffic layers but the options to activate them in
     menu panel are hidden with CSS as they probably are not needed in this app */
    this.map = new this.H.Map(
      mapContainer,
      this.defaultLayers.vector.normal.map,
      {
        center: DEFAULT_CENTER,
        zoom: ZOOM_DEFAULT,
        pixelRatio: window.devicePixelRatio || 1,
        padding: { top: 50, left: 50, bottom: 50, right: 50 },
      },
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const behavior = new this.H.mapevents.Behavior(new this.H.mapevents.MapEvents(this.map));
    behavior.disable(this.H.mapevents.Behavior.Feature.DBL_TAP_ZOOM);
  }

  public getDefaultLayers(): any { // eslint-disable-line @typescript-eslint/no-explicit-any
    return this.defaultLayers;
  }
  
  public addLayer(dataProvider: any): void { // eslint-disable-line @typescript-eslint/no-explicit-any
    const layer = new this.H.map.layer.ObjectLayer(dataProvider);
    this.map.addLayer(layer);
  }

  public getMap(): any { // eslint-disable-line @typescript-eslint/no-explicit-any
    return this.map;
  }

  public disposeMap(): void {
    if (this.map) this.map.dispose();
  }
  
  public getUi(): any { // eslint-disable-line @typescript-eslint/no-explicit-any
    return this.H.ui;
  }
  
  public getClustering(): any {  // eslint-disable-line @typescript-eslint/no-explicit-any
    return this.H.clustering;
  }

  public getSearchService(): any {  // eslint-disable-line @typescript-eslint/no-explicit-any
    return this.platform.getSearchService();
  }

  public getStyleObject(mapTheme: any): any { // eslint-disable-line @typescript-eslint/no-explicit-any
    return new this.H.map.Style(mapTheme);
  }

  public getInfoBubbleObject(): any { // eslint-disable-line @typescript-eslint/no-explicit-any
    return new this.H.ui.InfoBubble({ lat: null, lng: null });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getMarker(type: "domMarker" | "marker", position: any, options?: any): any {
    if (type === "domMarker") {
      return new this.H.map.DomMarker(position, options);
    } else {
      return new this.H.map.Marker(position, options);
    }
  }
  
  public getIcon(type: "domIcon" | "icon", content: any): void {  // eslint-disable-line @typescript-eslint/no-explicit-any
    if (type === "domIcon") {
      return new this.H.map.DomIcon(content);
    } else {
      return new this.H.map.Icon(content);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public addListener(eventType: "dbltap" | "resize", uiCallback: (event?: any) => void): void {
    if (eventType === "dbltap") {
      this.map.addEventListener("dbltap", (event: any): void => uiCallback(event));  // eslint-disable-line @typescript-eslint/no-explicit-any
    } else {
      window.addEventListener("resize", () => this.handleResize(uiCallback));
    }
  }

  public resize(): void {
    this.map.getViewPort().resize();
  }

  public getCenter(): MapLocation { 
    return this.map.getCenter();
  }

  public setCenter(locationOrArea: MapLocation | MapLocation[]): void {
    if (Array.isArray(locationOrArea)) {
      const area = new this.H.geo.Rect.coverPoints(locationOrArea);
      const center = area.getCenter();
      this.map.setCenter(center);
    } else {
      this.map.setCenter(locationOrArea);
    }
  }

  public getZoom(): number {
    return this.getMap().getZoom();
  }

  public setZoom(level: number): void {
    this.map.setZoom(level, true);
  }

  public setLookAtData(data: MapLocation[]): void {
    const rect = new this.H.geo.Rect.coverPoints(data);
    this.map.getViewModel().setLookAtData({
      bounds: rect,
      zoom: this.getZoomForSetLookAtData(data), 
    }, true);
  }

  public isInCurrentView(locationOrArea: MapLocation | MapLocation[]): boolean {
    const mapBounds = this.map.getViewModel().getLookAtData().bounds;
    const boundingBox = mapBounds.getBoundingBox();
    
    if (Array.isArray(locationOrArea)) {
      const area = new this.H.geo.Rect.coverPoints(locationOrArea);
      return boundingBox.containsRect(area);
    } else {
      return boundingBox.containsLatLng(locationOrArea.lat, locationOrArea.lng);
    }
  }

  private handleResize(uiCallback: () => void): void {
    this.resize();
    uiCallback();
  }

  private getZoomForSetLookAtData(data: MapLocation[]): Nullable<number> { 
    // prevent automatic very close zooming for single marker and cluster with identical locations
    if (data.length === 1 || this.areLocationsInDataIdentical(data)) {
      return 12;
    } else {
    // use zoom calculated by calling setLookAtData with only bounds property
      return null;
    }
  }

  private areLocationsInDataIdentical(data: MapLocation[]): boolean {
    return data.every(d => d.lat === data[0].lat && d.lng === data[0].lng);
  }
}
