import React, { Component, Fragment, ReactNode } from "react";
import Localization from "data/localization-sensoan/Localization";
import { MeasurementJob } from "data/types/measurementJobTypes";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";
import Section from "components/layout/Section";
import MeasurementSetCharts from "components/charts/MeasurementSetCharts";
import MeasurementSetDataContainer from "data/measurement-data/MeasurementSetDataContainer";
import { Nullable } from "types/aliases";
import MeasurementJobSelector from "data/measurement-job-selector/MeasurementJobSelector";
import { MeasurementSetConfig } from "data/types/measurementSetTypes";
import MapLink, { IconStatus, MapMarkerData } from "data/map/MapLink";
import MeasurementDataTools from "data/measurement-data/MeasurementDataTools";
import JobDetails from "./JobsDetails";
import LoaderSensoan from "components/layout/LoaderSensoan";
import { getLocationsFromMapMarkers } from "data/utils/mapUtils";

interface Props {
}

interface State {
  measurementJobData: Nullable<MeasurementSetDataContainer>;
  dataInitialized: boolean;
  jobStarted: boolean;
  measSet: Nullable<MeasurementSetConfig>;
  mapData: Nullable<MapMarkerData>;
}

class JobsDataView extends Component<Props, State> {

  private jobSelector = MeasurementJobSelector.getInstance();

  public constructor(props: Props) {
    super(props);
    this.state = {
      measurementJobData: null,
      dataInitialized: false,
      jobStarted: true,
      measSet: null,
      mapData: null,
    };
  }

  private initData(job: MeasurementJob, measSet: MeasurementSetConfig, mapData: Nullable<MapMarkerData>): void {
    const startTs = job.startTs;
    const endTs = job.endTs ?? Date.now();
    const timeSpan = startTs ? endTs - startTs : endTs;

    if (startTs === undefined || startTs === null) {
      this.setState({ jobStarted: false, mapData });
    } else {
      this.setState({
        measurementJobData: new MeasurementSetDataContainer(measSet, endTs, timeSpan, () => this.onMeasurementSetDataReady()),
        dataInitialized: false,
        jobStarted: true,
        measSet,
        mapData,
      });
    }
  }

  public componentDidMount(): void {
    const job = this.jobSelector.getSelectedMeasurementJob() as MeasurementJob; // DataView will only render when selectedJob !== null
    const measSet = MeasurementSetRepository.getInstance().getMeasurementSet(job.setId) as MeasurementSetConfig;
    const mapData = MeasurementDataTools.getMapMarkerDataForMeasJob(measSet, job, () => IconStatus.selected);

    if (mapData !== null) {
      MapLink.getLinkToMap().replaceData([mapData]);
      MapLink.getLinkToMap().centerMap(getLocationsFromMapMarkers([mapData]), true);
    }
    this.initData(job, measSet, mapData);
  }

  public componentWillUnmount(): void {
    if (this.state.mapData !== null) {
      MapLink.getLinkToMap().removeLocation([this.state.mapData]);
    }

    if (!MapLink.getLinkToMap().isDefaultZoomAndCenter()) {
      MapLink.getLinkToMap().resetZoomAndCenter();
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (this.state.dataInitialized === prevState.dataInitialized
      && this.state.jobStarted === prevState.jobStarted
      && this.state.measSet === prevState.measSet
      && this.state.measurementJobData === prevState.measurementJobData) {
      // we didn't change our state so let's update data
      const job = this.jobSelector.getSelectedMeasurementJob() as MeasurementJob; // DataView will only render when selectedJob !== null
      const measSet = MeasurementSetRepository.getInstance().getMeasurementSet(job.setId) as MeasurementSetConfig;
      MeasurementDataTools.getMapMarkerDataForMeasJob(measSet, job, () => IconStatus.selected);
      return this.initData(job, measSet, this.state.mapData);
    } else {
      return;
    }
  }

  public onMeasurementSetDataReady(): void {
    console.log("Measurements loaded");
    this.setState({ dataInitialized: true });
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <Section
          title={Localization.getInstance().getDisplayText("MeasJobsDataView", "info")}
          titleTextStyle="h6"
          variant="foldable"
        >
          <JobDetails job={this.jobSelector.getSelectedMeasurementJob() as MeasurementJob}/>
        </Section>
        <Section
          title={Localization.getInstance().getDisplayText("Common", "measurements")}
          titleTextStyle="h6"
        >
          {this.state.jobStarted ?
            this.state.dataInitialized ?
              <MeasurementSetCharts
                selectedMeasurementSet={this.state.measSet}
                measurementSetData={this.state.measurementJobData}
              />
              : <LoaderSensoan />
            : Localization.getInstance().getDisplayText("MeasJobsListView", "notStarted")
          }
        </Section>
      </Fragment>
    );
  }
}

export default JobsDataView;
