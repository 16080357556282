import DeviceState from "data/device/DeviceState";
import { Maybe, Nullable } from "types/aliases";
import { isDefined } from "utils/types";
import { RuuviGWHWCfg, RuuviGWHWDeviceInfo, RuuviGWHWStateProperties, RuuviGWHWWhitelist, RuuviGWNodList } from "./RuuviGWHWStateProperties";

export class RuuviGWHWState extends DeviceState<RuuviGWHWStateProperties> {

  public get cfg(): Maybe<RuuviGWHWCfg> {
    return this.deltaExists("cfg") ? this.desired.cfg : this.reported.cfg;
  }

  public set cfg(cfg: Maybe<RuuviGWHWCfg>) {
    this.desired.cfg = cfg;
    this.changedValues.cfg = cfg;
  }

  public get displayName(): Nullable<string> {
    return this.deltaExists("displayName") ? this.desired.displayName : this.reported.displayName;
  }

  public set displayName(name: Nullable<string>) {
    this.desired.displayName = name;
    this.changedValues.displayName = name;
  }

  public get whitelist(): Maybe<RuuviGWHWWhitelist> {
    return this.deltaExists("wl") ? this.desired.wl : this.reported.wl;
  }

  public set whitelist(wl: Maybe<RuuviGWHWWhitelist>) {
    this.desired.wl = wl;
    this.changedValues.wl = wl;
  }

  public get dev(): Maybe<RuuviGWHWDeviceInfo> {
    return this.reported.dev;
  }

  // Override - inherited method does not support checking deltas in objects
  public deltaExists(keyInState: keyof RuuviGWHWStateProperties): boolean {
    if (keyInState === "cfg") {
      const { cfg: desiredCfg } = this.desired;
      const { cfg: reportedCfg } = this.reported;

      if (desiredCfg && reportedCfg) {
        return Object.keys(desiredCfg).some(keyInCfg => {
          if (keyInCfg === "nod" && Array.isArray(keyInCfg)) {
            /* checking length is enough to determine if nod values are different in this context */
            return (desiredCfg[keyInCfg] as RuuviGWNodList).length !== (reportedCfg[keyInCfg] as RuuviGWNodList).length;
          } else {
            return desiredCfg[keyInCfg] !== reportedCfg[keyInCfg];
          }
        });
      } else {
        return true;
      }
    }
    return super.deltaExists(keyInState);
  }

  public revert(): void {
    this.changedValues = {};
  }

  // helper method to filter out shadow state properties that are not needed on UI
  public static getSupportedCfgProperties(allCfgProperties: Partial<RuuviGWHWStateProperties>): Maybe<RuuviGWHWCfg> {
    if (allCfgProperties) {
      const { cfg } = allCfgProperties;

      if (cfg) {
        const { gpst, act, mvt, actwt, rscan, nod, mvres, acct } = cfg;
        return {
          ...(isDefined(gpst) && { gpst }),
          ...(isDefined(act) && { act }),
          ...(isDefined(mvt) && { mvt }),
          ...(isDefined(actwt) && { actwt }),
          ...(isDefined(rscan) && { rscan }),
          ...(isDefined(nod) && { nod }),
          ...(isDefined(mvres) && { mvres }),
          ...(isDefined(acct) && { acct }),
        };
      }
    }
  }

  // helper method to filter out shadow state properties that are not needed on UI
  public static getSupportedDevProperties(allCfgProperties: Partial<RuuviGWHWStateProperties>): Maybe<RuuviGWHWDeviceInfo> {
    if (allCfgProperties) {
      const { dev } = allCfgProperties;

      if (dev) {
        const { v } = dev;

        if (v) {
          const { nw, modV, appV, brdV } = v;
          return {
            v: {
              ...(isDefined(nw) && { nw }),
              ...(isDefined(modV) && { modV }),
              ...(isDefined(appV) && { appV }),
              ...(isDefined(brdV) && { brdV }),
            },
          };
        }
      }
    }
  }
}
