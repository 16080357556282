import ThemeSelector from "data/theme/ThemeSelector";
import { Maybe, Nullable } from "types/aliases";
import { InfoBubblePositioning } from "components/map/HereMap";
import MapBase from "./MapBase";
import { MapMarkerData } from "./MapLink";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const editedDarkTheme = require("../../assets/map/themes/dark_edited_v05.yaml");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const editedNormalTheme = require("../../assets/map/themes/normal_edited_v03.yaml");

export enum InfoBubbleState {
  open = "open",
  closed = "closed",
}

export interface UiProvider {
  init(): void;
  getInfoBubble(): any; // eslint-disable-line @typescript-eslint/no-explicit-any
  getInfoBubbleState(): Nullable<InfoBubbleState>;
  disposeInfoBubble(): void;
  openInfoBubble(data: Nullable<MapMarkerData[]>, infoBubblePositioning: Nullable<InfoBubblePositioning>): void;
  closeInfoBubble(): void;
  setMapTheme(): void;
  getMapLocale(): string;
  setMapLocale(locale: string): void;
}

export default class MapUi implements UiProvider {
  private static instance: MapUi;

  private ui: any = null; // eslint-disable-line @typescript-eslint/no-explicit-any
  private infoBubble: any = null; // eslint-disable-line @typescript-eslint/no-explicit-any

  public static getInstance(): MapUi {
    if (this.instance == null) {
      this.instance = new MapUi();
    }
    return this.instance;
  }

  public init(): void {
    this.ui = MapBase.getInstance().getUi().UI.createDefault(
      MapBase.getInstance().getMap(),
      MapBase.getInstance().getDefaultLayers(),
    );
    this.initializeInfoBubble();
    this.modifyUIControls();
    this.setMapTheme();
  }

  public getInfoBubble(): any { // eslint-disable-line @typescript-eslint/no-explicit-any
    return this.infoBubble;
  }

  public getInfoBubbleState(): Nullable<InfoBubbleState> {
    if (this.getInfoBubble()) {
      return this.getInfoBubble().getState();
    } else {
      return null;
    }
  }

  public disposeInfoBubble(): void {
    const infoBubble = this.getInfoBubble();

    if (infoBubble) {
      this.ui.removeBubble(infoBubble);
      infoBubble.dispose();
    }
  }

  public openInfoBubble(data: Nullable<MapMarkerData[]>, infoBubblePositioning: Nullable<InfoBubblePositioning>): void {
    const infoBubble = this.getInfoBubble();

    if (infoBubble) {
      if (data && data.length > 0) {
        infoBubble.setPosition(data[0].location()); // anchors bubble to marker
        const infoBubbleBodyDiv = document.querySelector(".H_ib_body");
        this.adjustInfoBubblePosition(infoBubblePositioning, infoBubbleBodyDiv); // adjusts bubble to a direction with most available space
        const infoBubbleContainer = document.getElementById("here-info-bubble");

        if (infoBubbleContainer !== null) {
          infoBubble.getContentElement().appendChild(infoBubbleContainer);
          infoBubble.open();
          infoBubbleContainer.style.display = "block";
        }
      }
    }
  }

  public closeInfoBubble(): void {
    const infoBubble = this.getInfoBubble();

    if (infoBubble) {
      infoBubble.close();
      const infoBubbleContainer = document.getElementById("here-info-bubble");

      if (infoBubbleContainer !== null) {
        infoBubbleContainer.style.display = "none";
      }
    }

  }

  public setMapTheme(): void {
    const { type } = ThemeSelector.getInstance().getSelectedTheme().palette;
    let mapTheme;

    if (type === "dark") {
      mapTheme = editedDarkTheme;
    } else if (type === "light"){
      mapTheme = editedNormalTheme;
    } else {
      console.error("Unknown selectedTheme in MapUiProvider.setMapTheme");
      mapTheme = editedDarkTheme;
    }
    const baseLayer = MapBase.getInstance().getMap().getBaseLayer();
    const provider = baseLayer.getProvider();
    provider.setStyle(MapBase.getInstance().getStyleObject(mapTheme));
    this.setThemeToUIElements();
  }

  public getMapLocale(): string {
    return editedNormalTheme.global.ux_language;
  }

  public setMapLocale(locale: string): void {
    editedNormalTheme.global.ux_language = locale;
    editedDarkTheme.global.ux_language = locale;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private adjustInfoBubblePosition(position: Nullable<InfoBubblePositioning>, element: any): void {
    switch (position) {
      case InfoBubblePositioning.topRight:
        element.setAttribute("style", "bottom: 20px");
        break;
      case InfoBubblePositioning.bottomRight:
        element.setAttribute("style", "top: -10px");
        break;
      case InfoBubblePositioning.bottomLeft:
        element.setAttribute("style", "right: 0; top: -10px");
        break;
      case InfoBubblePositioning.topLeft:
        element.setAttribute("style", "right: 0; bottom: 20px");
        break;
      default:
        console.error("null or unknown parameter position in MapUiProvider.adjustInfoBubblePosition");
        return;
    }
  }

  private initializeInfoBubble(): void {
    if (this.ui && this.ui.getBubbles().length === 0) {
      this.infoBubble = MapBase.getInstance().getInfoBubbleObject();
      this.infoBubble.setState("closed");
      this.ui.addBubble(this.infoBubble);
    }
  }

  private modifyUIControls(): void {
    this.ui.removeControl("scalebar");
    this.ui.getControl("zoom").setAlignment("right-bottom");
  }

  private setThemeToUIElements(): void {
    const zoomContainer = this.ui.getControl("zoom"); // div that holds zoom controls
    const zoomButtons = this.ui.getControl("zoom").getChildren(); // zoom in and out controls
    const settingsContainer = this.ui.getControl("mapsettings").getChildren()[0]; // div that holds map settings control
    this.removePreviousThemeClassesFromUI(zoomContainer, [...zoomButtons, settingsContainer]);
    this.addCurrentThemeClassesToUI(zoomContainer, [...zoomButtons, settingsContainer]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private removePreviousThemeClassesFromUI(zoomContainerEl: any, otherUIEls: any[]): void {
    const { type } = ThemeSelector.getInstance().getSelectedTheme().palette;
    const previousThemeType = this.getPreviousThemeType(type);
    zoomContainerEl.removeClass(`H_zoom_rectangle__${previousThemeType}`);
    const previousUiButtonClass = `H_ui_button__${previousThemeType}`;
    // eslint-disable-next-line
    otherUIEls.forEach((el: any) => el.removeClass(previousUiButtonClass));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private addCurrentThemeClassesToUI(zoomContainerEl: any, otherEls: any[]): void {
    const { type } = ThemeSelector.getInstance().getSelectedTheme().palette;
    zoomContainerEl.addClass(`H_zoom_rectangle__${type}`);
    const currentUiButtonClass = `H_ui_button__${type}`;
    // eslint-disable-next-line
    otherEls.forEach((el: any) => el.addClass(currentUiButtonClass));
  }

  private getPreviousThemeType(themeType: string): Maybe<string> {
    let themeIdentifier;

    if (themeType === "dark") {
      themeIdentifier = "light";
    } else if (themeType === "light"){
      themeIdentifier = "dark";
    } else {
      console.error("Unknown themeType in HereMap.getPreviousThemeIdentifier");
      return undefined;
    }
    return themeIdentifier;
  }
}

