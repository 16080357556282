/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import Data, { DataUtil } from "./Data";
import LatestData, { LatestDataObserver } from "./LatestData";
import LatestDataSubscriptionManager from "./LatestDataSubscriptionManager";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { DevicesMeasurementsLatestDocument } from "../../generated/gqlData";
import { Maybe } from "../../types/aliases";

export default class AWSLatestData<TData extends Data> extends LatestData<TData> {
  private data?: TData;

  public constructor(private readonly deviceId: string, private readonly type: string) {
    super();
  }

  public getId(): string {
    return this.deviceId;
  }

  public getData(): Maybe<TData> {
    return this.data;
  }

  public async fetch(): Promise<void> {
    try {
      if (this.deviceId != null) {
        const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DATA);
        const latestDataResponse = await appSyncClient.query(
          DevicesMeasurementsLatestDocument,
          {
            deviceId: this.deviceId,
          },
        );

        if (latestDataResponse.data.devicesMeasurementsLatest) {
          this.data = DataUtil.parseDataFragment(latestDataResponse.data.devicesMeasurementsLatest, this.type);
        }
      }
    } catch (error) {
      console.error("Error", error);
    }
  }

  public setData(data: TData): void {
    const dataWithDefinedValues = Object.fromEntries(
      Object.entries(data)
        .filter(([_, v]) => v != null)) as TData;
    this.data = { ...this.data, ...dataWithDefinedValues };
    this.notifyAction(observer => observer.onDataUpdate(this));
  }

  public async addObserver(observer: LatestDataObserver): Promise<void> {
    if (!this.data) {
      await this.fetch();
    }
    super.addObserver(observer);

    if (this.observerCount === 1) {
      LatestDataSubscriptionManager.instance.addListener(this);
    }
  }

  public removeObserver(observer: LatestDataObserver): void {
    super.removeObserver(observer);

    if (this.observerCount === 0) {
      LatestDataSubscriptionManager.instance.removeListener(this);
    }
  }
}
