import React, { Component, Fragment, ReactNode } from "react";
import { Box, MenuItem, TextField, Typography } from "@material-ui/core";
import { Nullable } from "types/aliases";
import Localization from "data/localization-sensoan/Localization";
import { MeasurementSetTreeItem } from "data/types/measurementSetTypes";
import { LocationInfo, MeasSetEditTarget, MeasSetLocationOption } from "types/sensoanUiTypes";
import ErrorMessage from "components/layout/ErrorMessage";
import SetsLocationSelector from "components/measurement-sets/create-new-view/sets-details-configuration/SetsLocationSelector";
import SSelect from "components/styled-components/SSelect";
import MeasSetGroupMenu2 from "components/inputs/meas-set-group-menu/MeasSetGroupMenu";
import MeasurementSetRepository from "data/data-storage/MeasurementSetRepository";

interface Props {
  clearSelectedGroup: () => void;
  deviceLocationMsgNeeded: boolean;
  disableParentGroupEditing: boolean;
  displayName: string;
  locationDeviceName: Nullable<string>;
  metadata: string;
  onHistoryLengthChange: (option: number) => void;
  onLocationOptionChange: (option: MeasSetLocationOption) => Promise<void>;
  onMetadataChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onNameChange: (text: string, target: MeasSetEditTarget) => void;
  onSelectMeasGroup: (event: React.MouseEvent<Element, MouseEvent>, measurementSetGroup: MeasurementSetTreeItem) => void;
  placeLocation: Nullable<LocationInfo>;
  selectedMeasGroupId: string;
  selectedLocationOption: Nullable<MeasSetLocationOption>;
  historyLength?: number;
}

interface State {}

class SetsDetailsConfiguration extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  private renderTimeSpanSelector(): ReactNode {
    const valueInfo = this.text("MeasurementSetConfiguration", "backwards");
    const localizedDay = this.text("MeasurementSetConfiguration", "day");
    const localizedMonth = this.text("MeasurementSetConfiguration", "month");

    return (
      <SSelect
        buttonWidthInRems={12}
        buttonText={this.props.historyLength !== undefined
          ? `${this.parseTimeSpanAndUnit(this.props.historyLength)} ${valueInfo}`
          : this.text("MeasurementSetConfigSelection", "select")}
        mr="1.5rem"
      >
        <MenuItem key="1h" onClick={(): void => this.handleTimeSpanChange(1, "hour")}>{`1h ${valueInfo}`}</MenuItem>
        <MenuItem key="6h" onClick={(): void => this.handleTimeSpanChange(6, "hour")}>{`6h ${valueInfo}`}</MenuItem>
        <MenuItem key="1d" onClick={(): void => this.handleTimeSpanChange(24, "hour")}>{`1${localizedDay} ${valueInfo}`}</MenuItem>
        <MenuItem key="2d" onClick={(): void => this.handleTimeSpanChange(2 * 24, "hour")}>{`2${localizedDay} ${valueInfo}`}</MenuItem>
        <MenuItem key="7d" onClick={(): void => this.handleTimeSpanChange(1 * 7 * 24, "hour")}>{`7${localizedDay} ${valueInfo}`}</MenuItem>
        <MenuItem key="1m" onClick={(): void => this.handleTimeSpanChange(1, "month")}>{`1${localizedMonth} ${valueInfo}`}</MenuItem>
        <MenuItem key="3m" onClick={(): void => this.handleTimeSpanChange(3, "month")}>{`3${localizedMonth} ${valueInfo}`}</MenuItem>
        <MenuItem key="6m" onClick={(): void => this.handleTimeSpanChange(6, "month")}>{`6${localizedMonth} ${valueInfo}`}</MenuItem>
      </SSelect>
    );
  }

  private renderParentGroupNames(): ReactNode {
    return (
      MeasurementSetRepository.getInstance().findParentGroupFromTree([this.props.selectedMeasGroupId])?.map((result: MeasurementSetTreeItem) => <Typography key={result.setId} variant="body1" color="textPrimary"> {result.displayName} </Typography>)
    );
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <Box display="flex" height="3rem" justifyContent="flex-start">
          <Box width="20%">
            <Typography variant="body1" color="textSecondary">
              {Localization.getInstance().getDisplayText("MeasurementSetConfigSelection", "nameOfSet")}
            </Typography>
          </Box>
          <Box>
            <TextField
              value={this.props.displayName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.props.onNameChange(event.target.value, "measurementSet")}
            />
          </Box>
          {this.props.displayName === "" && <ErrorMessage ml={4}/>}
        </Box>
        <Box display="flex" height="3rem" justifyContent="flex-start">
          <Box width="20%">
            <Typography variant="body1" color="textSecondary">
              {Localization.getInstance().getDisplayText("MeasurementSetConfigSelection", "additionalInfo")}
            </Typography>
          </Box>
          <Box width="25%">
            <TextField fullWidth value={this.props.metadata} onChange={this.props.onMetadataChange} />
          </Box>
        </Box>
        <Box display="flex" height="3rem" justifyContent="flex-start">
          <Box width="20%">
            <Typography variant="body1" color="textSecondary">
              {Localization.getInstance().getDisplayText("MeasurementSetConfigSelection", "timeSpan")}
            </Typography>
          </Box>
          <Box>
            {this.renderTimeSpanSelector()}
          </Box>
        </Box>
        <SetsLocationSelector
          locationDeviceName={this.props.locationDeviceName}
          notificationNeeded={this.props.deviceLocationMsgNeeded}
          onLocationOptionChange={this.props.onLocationOptionChange}
          placeLocation={this.props.placeLocation}
          selectedLocationOption={this.props.selectedLocationOption}
        />
        <Box display="flex" height="3rem" justifyContent="flex-start">
          <Box width="20%">
            <Typography variant="body1" color="textSecondary">
              {Localization.getInstance().getDisplayText("MeasurementSetParentSelector", "group")}
            </Typography>
          </Box>
          <Box>
            {this.props.disableParentGroupEditing
              ?
              this.renderParentGroupNames()

              :
              <MeasSetGroupMenu2
                onSelectTreeItem={this.props.onSelectMeasGroup}
                clearSelectedGroup={this.props.clearSelectedGroup}
                selectedGroupId={this.props.selectedMeasGroupId}
              />}
          </Box>
          {(this.props.selectedMeasGroupId === "") && <ErrorMessage ml={4} />}
        </Box>
      </Fragment>
    );
  }

  private handleTimeSpanChange(num: number, unit: "hour" | "month"): void {
    if (unit === "month") {
      // TODO: calculate exact time span in Wrapper if selected set's historyLength > 168
      // because monthsAsHours is not exact num * months backwards from given point in time
      const monthsAsHours = num * 30 * 24;
      this.props.onHistoryLengthChange(monthsAsHours);
    } else {
      this.props.onHistoryLengthChange(num);
    }
  }

  private parseTimeSpanAndUnit(timeSpanInHours: number): string {
    if (timeSpanInHours < 24) {
      return `${timeSpanInHours}h`;
    } else if (timeSpanInHours >= 24 && timeSpanInHours <= 168) {
      return `${timeSpanInHours / 24}${this.text("MeasurementSetConfiguration", "day")}`;
    } else {
      return `${(timeSpanInHours / 24 / 30).toFixed()}${this.text("MeasurementSetConfiguration", "month")}`;
    }
  }
}

export default SetsDetailsConfiguration;
