/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Theme, withTheme } from "@material-ui/core";
import React, { ReactElement } from "react";
import { Nullable } from "../../types/aliases";

type LoaderSizeName = "small" | "medium";
export type LoaderSize = LoaderSizeName | number;

const LOADER_SIZES: {[k in LoaderSizeName]: number} = {
  small: 1.3,
  medium: 3,
};

function getLoaderSize(size?: LoaderSize): number {
  if (size == null) {
    return LOADER_SIZES.medium;
  } else if (typeof size === "number") {
    return size;
  } else {
    return LOADER_SIZES[size];
  }
}

function circleThicknessFromSize(size: number): number {
  return Math.max(0.1, 0.1 * size);
}

export interface Props {
  show?: boolean;
  rotationSpeedInSeconds?: number;
  size?: LoaderSize;
  spinnerColor?: string;
  circleColor?: string;
  topBottomPadding?: string;
  leftRightPadding?: string;
  theme: Theme;
}

function Loader(props: Props): Nullable<ReactElement> {
  const {
    show,
    rotationSpeedInSeconds,
    size,
    spinnerColor,
    circleColor,
    topBottomPadding,
    leftRightPadding,
    theme,
  } = props;

  if (show != null && !show) {
    return null;
  }

  const sizeNumber = getLoaderSize(size);

  const rotationSpeed = rotationSpeedInSeconds || "1";
  const spinnerSize = `${sizeNumber}rem`;
  const colorS = spinnerColor || "#0069ff";
  const colorC = circleColor || theme.palette.iconColors.primary;
  const heightC = `${circleThicknessFromSize(sizeNumber)}rem`;
  const paddingTopBottom = topBottomPadding || "2rem";
  const paddingLeftRight = leftRightPadding || "0";
  const containerStyles = {
    padding: paddingTopBottom + " " + paddingLeftRight,
  };
  const loaderStyles = {
    animation: "spin " + rotationSpeed + "s linear infinite",
    border: heightC + " solid " + colorC,
    borderRadius: "50%",
    borderTop: heightC + " solid " + colorS,
    height: spinnerSize,
    margin: "0 auto",
    width: spinnerSize,
  };
  return (
    <div style={containerStyles}><div style={loaderStyles} /></div>
  );
}

export default withTheme(Loader);
