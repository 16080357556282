import React, { Component, ReactNode } from "react";
import { Box, Typography } from "@material-ui/core";
import { Maybe } from "types/aliases";
import Localization from "data/localization-sensoan/Localization";
import { MeasurementJob } from "data/types/measurementJobTypes";
import IoTDataRow from "components/layout/iot-data-row/IoTDataRow";
import HeaderRowLocationButton from "components/layout/iot-data-row/HeaderRowLocationButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import withDataJanitor from "components/hocs/DataJanitor";
import { DataRepositories } from "data/data-storage/DataRepositoryFactory";

const HeaderRowLocationButtonWithDeviceRepo = withDataJanitor(HeaderRowLocationButton, [
  DataRepositories.Device,
]);

interface Props {
  requestSorting: (sortKey: keyof MeasurementJob) => void;
  sortKey: keyof MeasurementJob;
  sortOrder: "default" | "reverse";
  toggleAllMapMarkers: () => void;
}

interface State {
}

class JobsListHeaderRow extends Component<Props, State> {

  private getSortIcon(label: keyof MeasurementJob): Maybe<JSX.Element> {
    if (label === this.props.sortKey) {
      return this.props.sortOrder === "default" ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />;
    } else {
      return undefined;
    }
  }

  // MapIcon & Name
  // Start
  // Stop
  // Measset button
  // Comment
  // Status
  // Details button
  public render(): ReactNode {
    const cursorPointerStyle: React.CSSProperties = { cursor: "pointer" };
    /* TODO: change boxes into buttons */
    return (
      <IoTDataRow dividerFadeEffect>
        <HeaderRowLocationButtonWithDeviceRepo
          toggleAllMapMarkers={this.props.toggleAllMapMarkers}
          inlineLoaderWithoutWaitingText
          loaderProps={{
            size: 1.75,
            leftRightPadding: "12px",
          }}
        />
        <Box width="14%" onClick={(): void => this.props.requestSorting("displayName")}>
          <Typography variant="body1" style={cursorPointerStyle}>
            {Localization.getInstance().getDisplayText("MeasJobsListView", "jobName")} {this.getSortIcon("displayName")}
          </Typography>
        </Box>
        <Box width="14%" onClick={(): void => this.props.requestSorting("startTs")}>
          <Typography variant="body1" style={cursorPointerStyle}>
            {Localization.getInstance().getDisplayText("MeasJobsListView", "start")} {this.getSortIcon("startTs")}
          </Typography>
        </Box>
        <Box width="14%" onClick={(): void => this.props.requestSorting("endTs")}>
          <Typography variant="body1" style={cursorPointerStyle}>
            {Localization.getInstance().getDisplayText("MeasJobsListView", "end")} {this.getSortIcon("endTs")}
          </Typography>
        </Box>
        <Box width="14%">
          <Typography variant="body1" >
            {Localization.getInstance().getDisplayText("MeasJobsListView", "measSet")}
          </Typography>
        </Box>
        <Box width="30%" onClick={(): void => this.props.requestSorting("responsible")}>
          <Typography variant="body1" style={cursorPointerStyle}>
            {Localization.getInstance().getDisplayText("MeasJobsListView", "responsible")} / {Localization.getInstance().getDisplayText("MeasJobsListView", "comments")} {this.getSortIcon("responsible")}
          </Typography>
        </Box>
        <Box width="10%">
          <Typography variant="body1" >
            {Localization.getInstance().getDisplayText("MeasJobsListView", "status")}
          </Typography>
        </Box>
      </IoTDataRow>
    );
  }
}

export default JobsListHeaderRow;
