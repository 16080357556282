import Localization from "data/localization-sensoan/Localization";
import { RuuviGWNodDictionary } from "types/sensoanUiTypes";

export const ruuviGWNodDictionary: RuuviGWNodDictionary = {
  gnss: {
    name: "gnss",
    displayName: (): string => Localization.getInstance().getDisplayText("DeviceSettings", "gnss"),
  },
  ncell: {
    name: "ncell",
    displayName: (): string => Localization.getInstance().getDisplayText("DeviceSettings", "ncell"),
  },
  mcell_loc: {
    name: "mcell_loc",
    displayName: (): string => Localization.getInstance().getDisplayText("DeviceSettings", "mcell_loc"),
  },
};
