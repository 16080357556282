import { Maybe, Nullable } from "types/aliases";
import { MeasurementSetConfig, MeasurementSetGroup, MeasurementSetTreeItem, MeasurementSetType, SetConfig } from "data/types/measurementSetTypes";
import SensoanBackend from "data/backend/SensoanBackend";
import MeasurementSetSelector from "data/measurement-set-selector/MeasurementSetSelector";
import DataRepository from "./DataRepository";
import AsyncCache from "data/utils/AsynCache";

export default class MeasurementSetRepository implements DataRepository {

  private static instance: MeasurementSetRepository;
  private readonly initQueue = new AsyncCache();

  private initialized = false;
  private tree: MeasurementSetTreeItem[] = [];

  public static getInstance(): MeasurementSetRepository {
    if (this.instance == null) {
      this.instance = new MeasurementSetRepository();
    }
    return this.instance;
  }

  public getName(): string {
    return "measurementSets";
  }

  public async addConfig(configDisplayName: string, parentIds: string[], config: SetConfig, metadata?: string): Promise<number> {

    const resp = await SensoanBackend.getInstance().addMeasurementSetConfig(
      configDisplayName,
      parentIds,
      config,
      metadata,
    );

    if (resp !== "") {
      const measConfig: MeasurementSetConfig = {
        setId: resp,
        parentIds,
        type: MeasurementSetType.CONFIG,
        displayName: configDisplayName,
        config: config,
        metadata: metadata ?? "", //backend will set metadata to empty string if it is not defined -> same logic here in repo
      };
      parentIds.map((parentId): void => {
        const groupList: MeasurementSetTreeItem[] = [];
        this.findMeasurementGroup(this.tree, parentId, groupList);

        if (groupList.length > 0 && groupList[0].type === MeasurementSetType.GROUP) {
          (groupList[0] as MeasurementSetGroup).childIds.push(measConfig.setId);
          groupList[0].childs?.push(measConfig);
        } else {
          console.error("Error in MeasurementSetRepository.addConfig: invalid parent group or no parent group found for parentId === ", parentId);
        }
      });
      MeasurementSetSelector.getInstance().setSelectedMeasurementSet(measConfig);
      return 0;
    } else {
      return -1;
    }
  }

  public async addGroup(groupDisplayName: string, parentIds: string[]): Promise<number> {
    const resp = await SensoanBackend.getInstance().addMeasurementSetGroup(
      groupDisplayName,
      parentIds,
    );

    if (resp !== "") {
      const measGroup: MeasurementSetGroup = {
        setId: resp,
        parentIds,
        type: MeasurementSetType.GROUP,
        displayName: groupDisplayName,
        childIds: [],
      };

      const measTreeItem: MeasurementSetTreeItem = {
        ...measGroup,
        childs: [],
      };

      const isGroupOnRootLevel = parentIds[0] === process.env.REACT_APP_MEASSET_ROOT_LEVEL_GROUP_ID as string;

      if (isGroupOnRootLevel) {
        this.tree.push(measTreeItem);
      } else {
        parentIds.map((parentId): void => {
          const groupList: MeasurementSetTreeItem[] = [];
          this.findMeasurementGroup(this.tree, parentId, groupList);

          if (groupList.length > 0 && groupList[0].type === MeasurementSetType.GROUP) {
            (groupList[0] as MeasurementSetGroup).childIds.push(measGroup.setId);
            groupList[0].childs?.push(measTreeItem);
          } else {
            console.error("Error in MeasurementSetRepository.addGroup: invalid parent group or no parent group found for parentId === ", parentId);
          }
        });
      }
      return 0;
    } else {
      return -1;
    }
  }

  public async edit(setId: string, configDisplayName: string, metadata: string, config: SetConfig): Promise<number> {
    const setToEdit = this.getMeasurementSet(setId);

    if (setToEdit !== null) {
      const resp = await SensoanBackend.getInstance().measurementSetsConfigEdit(
        setId,
        configDisplayName,
        metadata,
        config,
      );

      if (resp === "success") {
        const measConfig: MeasurementSetConfig = {
          setId,
          parentIds: setToEdit.parentIds,
          type: MeasurementSetType.CONFIG,
          displayName: configDisplayName,
          config,
          metadata: metadata ?? "", //backend will set metadata to empty string if it is not defined -> same logic here in repo
        };

        setToEdit.parentIds.map((parentId): void => {
          const groupList: MeasurementSetTreeItem[] = [];
          this.findMeasurementGroup(this.tree, parentId, groupList);

          if (groupList.length > 0 && groupList[0].type === MeasurementSetType.GROUP) {
            const updatedChilds = groupList[0].childs?.map(child => child.setId === setId ? measConfig : child);
            groupList[0].childs = updatedChilds;
          } else {
            console.error("Error in MeasurementSetRepository.edit: invalid parent group or no parent group found for parentId === ", parentId);
          }
        });
        MeasurementSetSelector.getInstance().setSelectedMeasurementSet(measConfig);
        return 0;
      } else {
        return -1;
      }
    } else {
      console.error("Error in MeasurementSetRepository / edit(): tried to edit a non-existing set");
      return -1;
    }
  }

  // TODO: combine with deleteGroup
  public async delete(setId: string): Promise<number> {
    const setToDelete = this.getMeasurementSet(setId);

    if (setToDelete === null) {
      console.error("Trying to delete a non-existing measurement set");
      return -1;
    }

    const resp = await SensoanBackend.getInstance().deleteMeasurementSet(setId);

    if (resp !== "") {

      setToDelete.parentIds.map((parentId): void => {
        const groupList: MeasurementSetTreeItem[] = [];
        this.findMeasurementGroup(this.tree, parentId, groupList);

        if (groupList.length > 0 && groupList[0].type === MeasurementSetType.GROUP && groupList[0].childs !== undefined) {
          const childIdIndexToDelete = (groupList[0] as MeasurementSetGroup).childIds.findIndex((childId) => {
            return childId === setToDelete.setId;
          });
          const childSetIdIndexToDelete = groupList[0].childs.findIndex((child) => {
            return child.setId === setToDelete.setId;
          });
          (groupList[0] as MeasurementSetGroup).childIds.splice(childIdIndexToDelete, 1);
          groupList[0].childs?.splice(childSetIdIndexToDelete, 1);
        } else {
          console.error("Error in MeasurementSetRepository.delete: invalid parent group or no parent group found for parentId === ", parentId);
        }
      });
      MeasurementSetSelector.getInstance().setSelectedMeasurementSet(null);
      return 0;
    }
    else {
      return -1;
    }
  }

  // TODO: combine with delete
  public async deleteGroup(setId: string): Promise<number> {
    const groupToDelete = this.getMeasurementGroup(setId);

    if (groupToDelete === null) {
      console.error("Trying to delete a non-existing measurement set");
      return -1;
    }

    const resp = await SensoanBackend.getInstance().deleteMeasurementSet(setId);

    if (resp !== "") {

      const isGroupOnRootLevel = groupToDelete.parentIds[0] === process.env.REACT_APP_MEASSET_ROOT_LEVEL_GROUP_ID as string;

      if (isGroupOnRootLevel) {
        this.tree = this.tree.filter(treeItem => treeItem.setId !== groupToDelete.setId);
      } else {
        groupToDelete.parentIds.map((parentId): void => {
          const groupList: MeasurementSetTreeItem[] = [];
          this.findMeasurementGroup(this.tree, parentId, groupList);

          if (groupList.length > 0 && groupList[0].type === MeasurementSetType.GROUP && groupList[0].childs !== undefined) {
            const childIdIndexToDelete = (groupList[0] as MeasurementSetGroup).childIds.findIndex((childId) => {
              return childId === groupToDelete.setId;
            });
            const childSetIdIndexToDelete = groupList[0].childs.findIndex((child) => {
              return child.setId === groupToDelete.setId;
            });
            (groupList[0] as MeasurementSetGroup).childIds.splice(childIdIndexToDelete, 1);
            (groupList[0] as MeasurementSetTreeItem).childs?.splice(childSetIdIndexToDelete as number, 1);
          } else {
            console.error("Error in MeasurementSetRepository.deleteGroup: invalid parent group or no parent group found for parentId === ", parentId);
          }
        });
      }
      return 0;
    } else {
      return -1;
    }
  }

  // TODO: combine with getMeasurementGroups
  public getMeasurementSets(startFolder?: MeasurementSetTreeItem): MeasurementSetConfig[] {
    const measurementSets: MeasurementSetTreeItem[] = [];

    if (startFolder) {
      this.parseMeasurementSets([startFolder], measurementSets);
    } else {
      this.parseMeasurementSets(this.tree, measurementSets);
    }
    return measurementSets as MeasurementSetConfig[];
  }

  // TODO: combine with getMeasurementSets
  public getMeasurementGroups(): MeasurementSetGroup[] {
    const measurementSets: MeasurementSetTreeItem[] = [];
    this.parseMeasurementGroups(this.tree, measurementSets);
    return measurementSets as MeasurementSetGroup[];
  }

  // TODO: combine with getMeasurementGroup
  public getMeasurementSet(setId: string): Nullable<MeasurementSetConfig> {
    const meassets = this.getMeasurementSets();
    const index = meassets.findIndex((set) => set.setId === setId);

    if (index > -1) {
      return meassets[index];
    } else {
      console.log("MeasurementSet not found");
      return null;
    }
  }

  // TODO: combine with getMeasurementSet
  public getMeasurementGroup(setId: string): Nullable<MeasurementSetGroup> {
    const meassets = this.getMeasurementGroups();
    const index = meassets.findIndex((set) => set.setId === setId);

    if (index > -1) {
      return meassets[index];
    } else {
      console.log("MeasurementSet not found");
      return null;
    }
  }

  // TODO: this should not be needed when getMeasurementGroup and getMeasurementSet are combined ?
  public findItemFromTree(setId: string, tree: MeasurementSetTreeItem[], searchResult: MeasurementSetTreeItem[]): void {
    tree.map((item: MeasurementSetTreeItem): void => {
      if (item.setId === setId) {
        searchResult.push(item);
      } else {
        if (item.type === MeasurementSetType.GROUP) {
          this.findItemFromTree(setId, item.childs as MeasurementSetTreeItem[], searchResult);
        }
      }
    });
  }

  // TODO: replace with getMeasurementGroup after it has been refactored
  public findParentGroupFromTree(parentGroupIds: Maybe<string[]>): MeasurementSetTreeItem[] {
    const parentSearchResult: MeasurementSetTreeItem[] = [];
    parentGroupIds?.forEach((id: string) => {
      return this.findItemFromTree(id, this.tree, parentSearchResult);
    });
    return parentSearchResult;
  }

  public getTree(): MeasurementSetTreeItem[] {
    return this.tree;
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  // TODO: Need to fetch latestData for devices that are used in
  // TODO: add PromiseWaitList
  public async init(): Promise<void> {
    const initRepo = async (): Promise<void> => {
      const treeJsonString = await SensoanBackend.getInstance().getMeasurementSetTree();
      this.tree = JSON.parse(treeJsonString);
      this.initialized = true;
    };
    return this.initQueue.get("initMeasSetsRepo", initRepo);
  }

  public clear(): void {
    this.tree = [];
    this.initQueue.clear();
    this.initialized = false;
  }

  private constructor() {
    /* empty constructor */
  }

  public passTreeGroups(item: MeasurementSetTreeItem): boolean {
    if (item.type === MeasurementSetType.GROUP) {
      return true;
    } else {
      return false;
    }
  }

  private findMeasurementGroup(tree: MeasurementSetTreeItem[], groupId: string, groupList: MeasurementSetTreeItem[]): void {
    tree.map((treeItem): void => {

      if (treeItem.type === MeasurementSetType.GROUP) {

        if (treeItem.setId === groupId) {
          groupList.push(treeItem);
        }
        treeItem.childs?.map((node: MeasurementSetTreeItem) => this.findMeasurementGroup([node], groupId, groupList));
      }
      return;
    });
  }

  // TODO: combine with parseMeasurementGroups
  private parseMeasurementSets(tree: MeasurementSetTreeItem[], measurementSets: MeasurementSetTreeItem[]): void {
    tree.map((treeItem): void => {
      if (treeItem.type === MeasurementSetType.GROUP) {
        treeItem.childs?.map((node: MeasurementSetTreeItem) => this.parseMeasurementSets([node], measurementSets));
      } else {
        measurementSets.push(treeItem);
      }
      return;
    });
  }

  // TODO: combine with parseMeasurementSets
  private parseMeasurementGroups(tree: MeasurementSetTreeItem[], measurementSets: MeasurementSetTreeItem[]): void {
    tree.map((treeItem): void => {

      if (treeItem.childs && treeItem.childs.length > 0) {

        treeItem.childs.map((node: MeasurementSetTreeItem) => this.parseMeasurementGroups([node], measurementSets));

      } else {
        treeItem.type === MeasurementSetType.GROUP && measurementSets.push(treeItem);
      }
      return;
    });
  }

}
