/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import LatestData from "../data/LatestData";
import Device, { DeviceObserver, DeviceParameters, StatePropertiesOf } from "./Device";
import DeviceState from "./DeviceState";
import ShadowSubscriptionManager from "./ShadowSubscriptionManager";
import Data from "../data/Data";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Nullable } from "../../types/aliases";
import AWSBackend, { narrowDownAttributeTypes } from "../backend/AWSBackend";
import { DevicesStatesGetDocument, DevicesUpdateDocument } from "../../generated/gqlDevice";
import { Attribute } from "./Attribute";
import DeviceGroup from "./DeviceGroup";
import { HasEntityRelations, RelationChange } from "../utils/EntityRelationCache";
import AWSThingGroup from "./AWSThingGroup";
import { PromiseSemaphore } from "../utils/PromiseSemaphore";
import LatestDeviceDataRepository from "data/data-storage/LatestDeviceDataRepository";

/**
 * Base-class for typed AWS Thing implementations. Do not create this directly.
 *
 * This class is no longer abstract since TypeScript does not allow for run-time type comparison against an
 * abstract class (since abstract things do not exist in TypeScript at run-time).
 */
export default class AWSThing<TState extends DeviceState> extends Device<TState> implements HasEntityRelations {
  public readonly entityType = AWSThing;
  private readonly groupSemaphore = new PromiseSemaphore((): Promise<void> => this.backend.linkDeviceGroupsForDevice(this));

  private readonly deviceId: string;
  private attributes?: Attribute[];
  protected state?: TState;
  private latestData?: LatestData;

  /*
   * DO NOT CALL DIRECTLY
   *
   * This constructor needs to be public so {@link EntityRelationCache} can use it for type checks.
   */
  public constructor(
      private readonly type: string,
      protected readonly backend: AWSBackend,
      params: DeviceParameters,
  ) {
    super();
    this.deviceId = params.deviceId;
    this.attributes = params.attributes;
  }

  public async getGroups(): Promise<DeviceGroup[]> {
    await this.groupSemaphore.guard();
    return this.backend.entityRelationCache.listFor(this, AWSThingGroup);
  }

  public getAttribute(key: string): Nullable<string> {
    if (this.attributes === undefined) {
      return null;
    }

    let value = null;
    this.attributes.forEach((attribute: Attribute) => {
      if (attribute.key === key) {
        value = attribute.value;
      }
    });
    return value;
  }

  public getAttributes(): Attribute[] {
    return this.attributes ?? [];
  }

  public getId(): string {
    return this.deviceId;
  }

  public getType(): string {
    return this.type;
  }

  public getState(): Nullable<TState> {
    return this.state ?? null;
  }

  // TODO: Consider moving this functionality inside DeviceState
  public addObserver(observer: DeviceObserver): void {
    super.addObserver(observer);
    ShadowSubscriptionManager.instance.addListener(this);
  }

  public removeObserver(observer: DeviceObserver): void {
    super.removeObserver(observer);
    ShadowSubscriptionManager.instance.removeListener(this);
  }

  public onRelationChange(change: RelationChange): void {
    if (change.ofType(AWSThingGroup)) {
      this.notifyAction(observer => observer.onDeviceGroupsChanged?.(this));
    }
  }

  public async init(): Promise<void> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
      const deviceStateResponse = await client.query(
        DevicesStatesGetDocument,
        {
          deviceId: this.deviceId,
        },
      );
      const { desired, reported, timestamp } = deviceStateResponse.data.devicesStatesGet ?? {};

      this.state = this.createState(timestamp ?? undefined, reported ? JSON.parse(reported) : undefined, desired ? JSON.parse(desired) : undefined);
    } catch (error) {
      console.error("Error", error);
    }
  }

  public setState(timestamp?: number, current?: Partial<StatePropertiesOf<TState>>, next?: Partial<StatePropertiesOf<TState>>): void {
    // TODO: Consider implementing a method that just updates the existing state
    this.state = this.createState(timestamp, current, next);
    this.notifyAction(observer => observer.onDeviceStateUpdated?.(this));
  }

  public async getLatestData(): Promise<Nullable<LatestData>> {
    try {
      if (!this.latestData) {
        const latestData = LatestDeviceDataRepository.getInstance().getLatestData(this.deviceId) ?? await LatestDeviceDataRepository.getInstance().fetchData(this.deviceId);

        if (latestData) {
          this.latestData = latestData;
        }
      }
      return this.latestData ?? null;
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  }

  public getLatestDataSync(): Nullable<Data> {
    return LatestDeviceDataRepository.getInstance().getData(this.deviceId);
  }

  // TODO: can user set null values for attr's value?
  public async updateAttributes(attributes: Required<Attribute>[]): Promise<void> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
      const response = await client.mutate(
        DevicesUpdateDocument,
        {
          deviceId: this.deviceId,
          attributes,
        },
      );
      this.attributes = narrowDownAttributeTypes(response.data?.devicesUpdate?.attr ?? []);
    } catch (error) {
      console.error("Error", error);
    }
  }
  // OVERRIDE THIS
  public createState(
      _timestamp?: number,
      _reported?: Partial<StatePropertiesOf<TState>>,
      _desired?: Partial<StatePropertiesOf<TState>>): TState {
    throw new Error("AWSThing MUST NOT be created directly");
  }

  // OVERRIDE THIS
  public getIcon(): string {
    throw new Error("AWSThing MUST NOT be created directly");
  }

  public static instanceOf(value: unknown): value is AWSThing<never> {
    return value instanceof AWSThing;
  }
}
