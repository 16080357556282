import React, { Component, ReactNode } from "react";
import { Box } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SSvgIcon, { SSvgIconColorProps } from "components/styled-components/SSvgIcon";
import SIconButton from "components/styled-components/SIconButton";

interface Props {
  toggleAllMapMarkers: () => void;
}

class HeaderRowLocationButton extends Component<Props> {

  public render(): ReactNode {
    return (
      <Box>
        <SIconButton onClick={(): void => this.props.toggleAllMapMarkers()} >
          <SSvgIcon
            color={SSvgIconColorProps.secondary}
            iconComponent={LocationOnIcon}
          />
        </SIconButton>
      </Box>
    );
  }
}

export default HeaderRowLocationButton;
