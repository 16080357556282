import React, { ReactNode, RefObject } from "react";
import { Box, List, TextField } from "@material-ui/core";
import SettingsListItem from "../settings-list-item";
import { getDeviceStateAsPiikkioHW, getDeviceStateAsRuuviGW, getDeviceStateAsSereneHW } from "data/utils/Utils";
import SettingsControls from "../settings-page-general/components/settings-controls";
import S3Tool from "data/s3/S3Tool";
import Auth from "@aws-amplify/auth";
import awsconfig from "aws-config";
import { v4 } from "uuid";
import { BackendActionStatus } from "types/sensoanUiTypes";
import Loader from "components/ui/loader";
import { Maybe } from "types/aliases";
import { RuuviGWHW } from "client/devices/RuuviGWHW/RuuviGWHW";
import OtaManager, { AWSIoTJobOta } from "data/ota/OtaManager";
import { DevicesOtaUpdateCreatePayload } from "generated/gqlDevice";
import { SereneHW } from "client/devices/SereneHW/SereneHW";
import { RuuviGWHWState } from "client/devices/RuuviGWHW/RuuviGWHWState";
import { SereneHWState } from "client/devices/SereneHW/SereneHWState";
import { PiikkioHW } from "client/devices/PiikkioHW/PiikkioHW";
import { PiikkioHWState } from "client/devices/PiikkioHW/PiikkioHWState";

type DeviceWithAWSIoTJobOta = RuuviGWHW | SereneHW | PiikkioHW;

interface Props {
  device: DeviceWithAWSIoTJobOta;
  closeSettings: () => void;
}

interface State {
  firmwareVersion: string;
  targetBoard: string;
  otaDescription: string;
  error?: boolean;
  loading?: boolean;
  jobOta?: AWSIoTJobOta;
}

class SettingsPageAWSIoTJobOta extends React.Component<Props, State> {
  private fileInputRef: RefObject<HTMLInputElement>;

  public constructor(props: Props) {
    super(props);
    this.state = {
      firmwareVersion: "",
      targetBoard: "",
      otaDescription: "",
    };
    this.fileInputRef = React.createRef();
  }

  private getFileFromInputRef(): Maybe<File> {
    return this.fileInputRef?.current?.files?.[0];
  }

  private isCreateButtonDisabled(): boolean {
    return this.getFileFromInputRef() === undefined || this.state.firmwareVersion === "";
  }

  private async createJobOta(): Promise<void> {
    const firmwareFile = this.getFileFromInputRef();

    if (firmwareFile) {
      this.setState({ loading: true });
      const credentials = await Auth.currentCredentials();
      const s3 = new S3Tool(credentials, awsconfig.aws_s3_nrf_fota_bucket as string);
      const jobId = v4();
      const s3OperationStatus = await s3.uploadFile(firmwareFile, jobId);

      if (s3OperationStatus === BackendActionStatus.SUCCESS) {
        const { size, name: filename } = firmwareFile;
        const { otaDescription: description, targetBoard, firmwareVersion: fwversion } = this.state;

        const payload: DevicesOtaUpdateCreatePayload = {
          description,
          deviceId: this.props.device.getId(),
          filename,
          fwversion, // this is set programmatically in reference app
          jobId,
          size,
          targetBoard,
        };

        const jobOta = await OtaManager.getInstance().createAWSIoTJobOta(payload);

        if (jobOta) {
          this.setState({ jobOta });
        } else {
          this.setState({ error: true });
        }
      } else {
        this.setState({ error: true });
      }
      this.setState({ loading: false });
    } else {
      console.error("Error in creating a job OTA: the OTA file was not found from the file input");
    }
  }

  private handleCancel = (): void => {
    this.props.device.getState()?.revert();
    this.props.closeSettings();
  };

  private getApplicationVersion = (state: RuuviGWHWState | SereneHWState | PiikkioHWState): Maybe<string> => {
    if (state instanceof RuuviGWHWState) {
      return getDeviceStateAsRuuviGW(state).dev?.v?.appV;
    } else if (state instanceof SereneHWState) {
      return getDeviceStateAsSereneHW(state).firmwareVersion ?? undefined;
    } else if (state instanceof PiikkioHWState) {
      return getDeviceStateAsPiikkioHW(state).firmwareVersion ?? undefined;
    }
  };

  private handleTargetBoardChange = (): void => {
    if (this.getFileFromInputRef()) {
      const state = this.props.device.getState();
      let targetBoard = "";

      if (state instanceof RuuviGWHWState) {
        targetBoard = state.dev?.v?.brdV ?? "";
      } 
      this.setState({ targetBoard });
    } else {
      console.error("Failed to set target board information while reading file input: no file was found in the file input");
    }
  };

  private renderOtaDeviceInformation(): ReactNode {
    const state = this.props.device.getState();

    if (state) {
      return (
        <SettingsListItem label="Application">
          {this.getApplicationVersion(state) ?? "N/A"}
        </SettingsListItem>
      );
    }
  }

  private renderCreateOtaDialog(): ReactNode {
    return (
      <List>
        {this.renderOtaDeviceInformation()}
        <SettingsListItem label="Firmware file">
          <input
            type="file"
            accept=".bin"
            // In React, <input type="file" /> is always an uncontrolled component because its value can only be set by a user, and not programmatically.
            // Therefore accessing the file is handled with a ref.
            ref={this.fileInputRef}
            // this is only needed for reading and displaying board version from shadow as target board when a file is selected
            onChange={(): void => this.handleTargetBoardChange()}
            style={{ fontFamily: "inherit", position: "relative", left: "1.6rem" }}
          />
        </SettingsListItem>
        {this.state.targetBoard !== "" &&
          <SettingsListItem label="Target board">
            <TextField
              disabled
              value={this.state.targetBoard}
              onChange={({ target }): void => this.setState({ targetBoard: target.value })}
            />
          </SettingsListItem>
        }
        <SettingsListItem label="Firmware version">
          <TextField
            value={this.state.firmwareVersion}
            onChange={({ target }): void => this.setState({ firmwareVersion: target.value })}
          />
        </SettingsListItem>
        <SettingsListItem label="OTA description">
          <TextField
            value={this.state.otaDescription}
            onChange={({ target }): void => this.setState({ otaDescription: target.value })}
          />
        </SettingsListItem>
        {this.state.jobOta &&
        <SettingsListItem label="New OTA item">
          {this.state.jobOta.description}
        </SettingsListItem>}
        {/* TODO: provide changesMade prop ? */}
        <SettingsControls
          submitButtonLabel="Create OTA job"
          cancelButtonLabel="Cancel"
          onSave={(): Promise<void> => this.createJobOta()}
          onCancel={this.handleCancel}
          submitButtonDisabled={this.isCreateButtonDisabled()}
        />
      </List>
    );
  }

  private renderErrorDialog(): ReactNode {
    return (
      <Box my="2rem">
        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" fontSize="0.8rem">
          Creating OTA job failed
        </Box>
        <SettingsControls
          submitButtonLabel="Ok"
          cancelButtonLabel="Cancel"
          onSave={(): void => this.setState({ error: false })}
          onCancel={this.handleCancel}
        />
      </Box>
    );
  }

  private renderSuccessDialog(): ReactNode {
    return (
      <Box my="2rem">
        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" fontSize="0.8rem">
          {`Creating OTA job ${this.state.jobOta?.description} succeeded`}
        </Box>
        <SettingsControls
          submitButtonLabel="Ok"
          cancelButtonLabel="Cancel"
          onSave={(): void => this.setState({ jobOta: undefined, firmwareVersion: "", otaDescription: "", targetBoard: "" })}
          onCancel={this.handleCancel}
        />
      </Box>
    );
  }

  private renderLoader(): ReactNode {
    return (
      <Box my="2rem" width="100%" display="flex" justifyContent="center" alignItems="center">
        <Loader/>
      </Box>
    );
  }

  public render(): ReactNode {
    if (this.state.loading) {
      return this.renderLoader();
    } else if (this.state.jobOta) {
      return this.renderSuccessDialog();
    } else if (this.state.error) {
      return this.renderErrorDialog();
    } else {
      return this.renderCreateOtaDialog();
    }
  }
}

export default SettingsPageAWSIoTJobOta;
