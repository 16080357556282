import React, { Component } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import AuthWrapper from "data/auth/AuthWrapper";
import Localization from "data/localization-sensoan/Localization";
import { Maybe } from "types/aliases";
import ConfirmationDialog from "components/ui/confirmation-dialog";
import AttributeForm from "components/user-view/components/attribute-form";
import PasswordForm from "./components/password-form";

interface Props {}

interface State {
  username: string;
  dialog?: Dialog;
}

interface Dialog {
  title: string;
  message: string;
  onAccept: () => void;
  onDecline?: () => void;
}

export default class UserView extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = {
      username: "",
    };
  }

  private triggerLogOutConfirmation = (): void => {
    this.setState({
      dialog: {
        title: this.text("Actions", "logOut"),
        message: this.text("UserView", "youAreAboutToLogOut"),
        onAccept: (): Promise<void> => this.logOut(),
        onDecline: (): void => this.resetDialog(),
      },
    });
  };

  public componentDidMount(): void {
    this.getUserDetails();
  }

  private async getUserDetails(): Promise<void> {
    try {
      const username = await AuthWrapper.getCurrentAuthenticatedUsername();
      this.setState({ username });
    } catch (error) {
      console.error("getUserDetails", error);
    }
  }

  private resetDialog(): void {
    this.setState({ dialog: undefined });
  }

  private async logOut(): Promise<void> {
    await AuthWrapper.logOut();
  }

  private renderDialog(): Maybe<JSX.Element> {
    if (this.state.dialog) {
      return (
        <ConfirmationDialog
          title={this.state.dialog.title}
          message={this.state.dialog.message}
          onConfirm={this.state.dialog.onAccept}
          onCancel={this.state.dialog.onDecline}
        />
      );
    }
  }

  private renderLogOutButton(): JSX.Element {
    return (
      <Grid container={true} justifyContent="center" spacing={2}>
        <Grid item={true}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>{this.text("UserView", "doYouWantToLogOut")}</Typography>
        </Grid>
        <Grid item={true} container={true} justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={this.triggerLogOutConfirmation}
          >
            {this.text("Actions", "logOut")}
          </Button>
        </Grid>
      </Grid>
    );
  }


  public render(): JSX.Element {
    return (
      <Grid container={true} justifyContent="center">
        <Grid item={true} container={true} xs={10} sm={8} md={6} spacing={6}>
          <Grid item={true} container={true} justifyContent="center">
            <Box paddingTop={2}><span><span style={{ fontWeight: "bold" }}>{this.text("Common", "username")}:</span> {this.state.username}</span></Box>
          </Grid>
          <Grid item={true} xs={12}>
            <AttributeForm />
          </Grid>
          <Grid item={true} xs={12}>
            <PasswordForm />
          </Grid>
          <Grid item={true} xs={12}>
            {this.renderLogOutButton()}
          </Grid>
          {this.renderDialog()}
        </Grid>
      </Grid>
    );
  }
}
