/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import { OtaUpdate, OtaUpdateState } from "./OtaTypes";
import { OtaUpdateListener } from "./OtaUpdateListener";
import {
  DeviceGroupsOtaUpdatesCancelDocument,
  DeviceGroupsOtaUpdatesStartDocument,
  DevicesOtaUpdateCreateDocument,
  DevicesOtaUpdateCreatePayload,
  DevicesOtaUpdatesCancelDocument,
  DevicesOtaUpdatesStartDocument,
  DeviceUpgradeFirmwareJobDocumentFieldsFragment,
  DeviceUpgradeFirmwareJobFieldsFragment,
  OtaUpdatesListDocument,
  OtaUpdatesStatesListDocument,
  OtaUpdatesStatesUpdateFeedDocument,
  OtaUpdateStateFieldsFragment,
} from "../../generated/gqlDevice";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Maybe, Nullable } from "../../types/aliases";
import { RuuviGWHW } from "client/devices/RuuviGWHW/RuuviGWHW";
import DeviceRepository from "data/data-storage/DeviceRepository";
import { ReceiverObserver } from "data/utils/ReceiverObserver";
import ReceiverManager from "data/utils/receivers/ReceiverManager";
import { SereneHW } from "client/devices/SereneHW/SereneHW";
import { PiikkioHW } from "client/devices/PiikkioHW/PiikkioHW";
import { MLDemoHW } from "client/devices/MLDemoHW/MLDemoHW";

export type AWSIoTJobOta = Omit<DeviceUpgradeFirmwareJobFieldsFragment, "__typename" | "document"> & {
  document: Omit<DeviceUpgradeFirmwareJobDocumentFieldsFragment, "__typename">;
};

export default class OtaManager implements ReceiverObserver {
  private static instance: OtaManager;

  private otaUpdates?: OtaUpdate[];
  private otaUpdateStates?: OtaUpdateState[];
  private subscriptions: ZenObservable.Subscription[] = [];
  private listeners: OtaUpdateListener[] = [];

  public static getInstance(): OtaManager {
    if (!OtaManager.instance) {
      OtaManager.instance = new OtaManager();
    }
    return this.instance;
  }

  public async init(): Promise<void> {
    ReceiverManager.instance.addObserver(this);
  }

  public uninit(): void {
    ReceiverManager.instance.removeObserver(this);
    this.unsubscribe();
  }

  public async getOtaUpdates(forceRefresh?: boolean): Promise<OtaUpdate[]> {
    if (!this.otaUpdates || forceRefresh) {
      return await this.fetchOtaUpdates();
    } else {
      return this.otaUpdates;
    }
  }

  public async getOtaUpdateStates(forceRefresh?: boolean): Promise<OtaUpdateState[]> {
    if (!this.otaUpdateStates || forceRefresh) {
      return await this.fetchOtaUpdateStates();
    } else {
      return this.otaUpdateStates;
    }
  }

  public async createAWSIoTJobOta(payload: DevicesOtaUpdateCreatePayload): Promise<Maybe<AWSIoTJobOta>> {

    const deviceType = DeviceRepository.getInstance().getDevice(payload.deviceId)?.getType();
    const deviceSupportsAWSIoTJobOta = deviceType === RuuviGWHW.type || deviceType === SereneHW.type || deviceType === PiikkioHW.type || deviceType === MLDemoHW.type;

    if (!deviceSupportsAWSIoTJobOta) {
      console.error(`createAWSIoTJobOta is not supported for device with deviceId: ${payload.deviceId}`);
    } else {
      try {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const { data } = await client.mutate(DevicesOtaUpdateCreateDocument, { payload });

        if (data?.devicesOtaUpdateCreate) {
          return OtaManager.parseDeviceUpgradeFirmwareJobFragment(data.devicesOtaUpdateCreate);
        } else {
          console.error("Invalid response content");
        }
      } catch (error) {
        console.error("Error", error);
      }
    }
  }

  public async triggerDeviceOtaUpdate(deviceId: string, otaId: string): Promise<void> {
    console.log(`triggerDeviceOtaUpdate ${deviceId}, ${otaId}`);

    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
      await client.mutate(DevicesOtaUpdatesStartDocument, { deviceId, otaId });
    } catch (error) {
      console.error("Error", error);
    }
  }

  public async triggerGroupOtaUpdate(groupId: string, otaId: string): Promise<void> {
    console.log(`triggerGroupOtaUpdate ${groupId}, ${otaId}`);

    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
      await client.mutate(DeviceGroupsOtaUpdatesStartDocument, { groupId, otaId });
    } catch (error) {
      console.error("Error", error);
    }
  }

  public async cancelDeviceOtaUpdate(deviceId: string): Promise<void> {
    console.log(`cancelDeviceOtaUpdate ${deviceId}`);

    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
      await client.mutate(DevicesOtaUpdatesCancelDocument, { deviceId });
    } catch (error) {
      console.error("Error", error);
    }
  }

  public async cancelGroupOtaUpdate(groupId: string): Promise<void> {
    console.log(`cancelGroupOtaUpdate ${groupId}`);

    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
      await client.mutate(DeviceGroupsOtaUpdatesCancelDocument, { groupId });
    } catch (error) {
      console.error("Error", error);
    }
  }

  public addListener(listener: OtaUpdateListener): void {
    const listenerIndex = this.listeners.indexOf(listener);

    if (listenerIndex !== -1) {
      this.removeListenerFromIndex(listenerIndex);
    }
    this.listeners.push(listener);
  }

  public removeListener(listener: OtaUpdateListener): void {
    const listenerIndex = this.listeners.indexOf(listener);

    if (listenerIndex !== -1) {
      this.removeListenerFromIndex(listenerIndex);
    }
  }

  private async fetchOtaUpdates(): Promise<OtaUpdate[]> {
    let nextToken: Nullable<string> = null;
    let otaUpdates: OtaUpdate[] = [];
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);

    try {
      do {
        const response = await client.query(OtaUpdatesListDocument, { nextToken });
        // cast is required or response is stuck in cyclic type inference loop
        nextToken = (response.data.otaUpdatesList?.nextToken ?? null) as Nullable<string>;
        otaUpdates = otaUpdates.concat(response.data.otaUpdatesList?.otaUpdates ?? []);
      } while (nextToken);
      this.otaUpdates = otaUpdates;
      return otaUpdates;
    } catch (error) {
      console.error("Error", error);
      return [];
    }
  }

  private async fetchOtaUpdateStates(): Promise<OtaUpdateState[]> {
    let nextToken: Nullable<string> = null;
    let otaUpdateStates: OtaUpdateState[] = [];
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);

    try {
      do {
        const response = await client.query(OtaUpdatesStatesListDocument, { nextToken });
        // cast is required or response is stuck in cyclic type inference loop
        nextToken = (response.data.otaUpdatesStatesList?.nextToken ?? null) as Nullable<string>;
        const convertedStates = response.data.otaUpdatesStatesList?.otaUpdateStates
          .map((fragment) => OtaManager.parseStateFragment(fragment));
        otaUpdateStates = otaUpdateStates.concat(convertedStates ?? []);
      } while (nextToken);
      this.otaUpdateStates = otaUpdateStates;
      return otaUpdateStates;
    } catch (error) {
      console.error("Error", error);
      return [];
    }
  }

  private removeListenerFromIndex(index: number): void {
    this.listeners.splice(index, 1);
  }

  public onReceiversChanged(receivers: string[]): void {
    this.unsubscribe();
    this.subscriptions.push(...receivers.map(receiver => this.subscribeWithIdentity(receiver)));
  }

  private unsubscribe(): void {
    this.subscriptions.forEach((s: ZenObservable.Subscription) => {
      s.unsubscribe();
    });
    this.subscriptions = [];
  }

  private subscribeWithIdentity(identity: string): ZenObservable.Subscription {
    const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
    return appSyncClient.subscribe(OtaUpdatesStatesUpdateFeedDocument, { receiver: identity })
      .subscribe({
        // TODO: Fix any type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: (error: any): void => {
          if (error.errorMessage === "AMQJS0008I Socket closed.") {
            console.log("Reconnecting socket");
            this.subscribeWithIdentity(identity);
          }
          console.error(error);
        },
        next: (update): void => {
          if (update.data?.otaUpdatesStatesUpdateFeed?.item) {
            const state = OtaManager.parseStateFragment(update.data.otaUpdatesStatesUpdateFeed.item);
            this.listeners.forEach((listener: OtaUpdateListener) => {
              listener.onOtaUpdateState(state);
            });
          }
        },
      });
  }

  private static parseStateFragment(fragment: OtaUpdateStateFieldsFragment): OtaUpdateState {
    const { timestamp, ...rest } = fragment;
    return {
      timestamp: typeof timestamp === "string" ? Number.parseInt(timestamp) : timestamp,
      ...rest,
    };
  }

  private static parseDeviceUpgradeFirmwareJobFragment(fragment: DeviceUpgradeFirmwareJobFieldsFragment): AWSIoTJobOta {
    const {
      __typename, // eslint-disable-line @typescript-eslint/no-unused-vars
      document,
      ...rest
    } = fragment;
    const {
      __typename: __documentTypename, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...restFromDocument
    } = document;
    return {
      ...rest,
      document: {
        ...restFromDocument,
      },
    };
  }

}
