import React, { Component, Fragment } from "react";
import { Box, InputAdornment, Switch, TextField, Theme, Typography, withTheme } from "@material-ui/core";
import { RuuviGWHWCfg, RuuviGWHWStateProperties, RuuviGWNodList, RuuviGWNodListValue } from "client/devices/RuuviGWHW/RuuviGWHWStateProperties";
import { validatePartialNumber } from "data/utils/inputUtils";
import { isDefined } from "utils/types";
import SettingsListItem from "../../settings-list-item";
import Localization from "data/localization-sensoan/Localization";
import RuuviGWNodSelector from "./RuuviGWNodSelector";
import { Concrete } from "types/sensoanUiTypes";

interface Props {
  deviceState: Partial<RuuviGWHWStateProperties>;
  setChangesMade: () => void;
  theme: Theme;
}

interface State {
  gpst: string; // gps timeout, seconds
  act: boolean; // active mode
  mvt: string; // passive mode data update without movement period, seconds
  actwt: string; // active mode data update period, seconds
  rscan: string; // ruuvi scan time, milliseconds
  nodList: RuuviGWNodList; // list of not requested data
  mvres: string; // time between movement triggered data updates
  acct: string; // accelerometer threshold for triggering movement
}

const DEFAULT_GPST_STRING = "60";
const DEFAULT_MVT_STRING = "3600";
const DEFAULT_ACTWT_STRING = "120";
const DEFAULT_RSCAN_STRING = "15000";
const DEFAULT_MVRES_STRING = "60";
const DEFAULT_ACCT_STRING = "120";
const DEFAULT_ACT = false;

type ActAndNodKey = Pick<RuuviGWHWCfg, "act" | "nod">;

/* Helper type for properties which might be undefined in device state but which will always be defined when edited on UI */
type WithDefinedActAndNodKey = Concrete<ActAndNodKey> & RuuviGWHWCfg;

class RuuviGWStateSettings extends Component<Props, State> {
  private text = Localization.getInstance().getDisplayText;

  public constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  private getCfgFromStateValues(): WithDefinedActAndNodKey {
    const { gpst, act, mvt, actwt, rscan, mvres, acct, nodList } = this.state;
    return {
      ...(gpst !== "" && { gpst: Number(gpst) }),
      ...(mvt !== "" && { mvt: Number(mvt) }),
      ...(actwt !== "" && { actwt: Number(actwt) }),
      ...(rscan !== "" && { rscan: Number(rscan) }),
      ...(mvres !== "" && { mvres: Number(mvres) }),
      ...(acct !== "" && { acct: Number(acct) }),
      act,
      nod: [...nodList],
    };
  }

  private handleNodValueChange(key: RuuviGWNodListValue, value: boolean): void {
    const newConfig = { ...this.getCfgFromStateValues() };
    const { nod } = newConfig;

    if (value) {
      if (!nod.includes(key)) {
        nod.push(key);
      } else {
        console.error(`Error in adding to the list of not requested data: the value '${key}' is already in list`);
        return;
      }
    } else {
      if (nod.includes(key)) {
        nod.splice(nod.indexOf(key), 1);
        this.setState({ nodList: this.state.nodList.filter(nodKey => nodKey !== key) });
      } else {
        console.error(`Error in removing from the list of not requested data: the value '${key}' was not in the list`);
        return;
      }
    }

    this.setState({ nodList: [...nod] });
    this.props.deviceState.cfg = newConfig;
    this.props.setChangesMade();
  }

  private handleGpstChange(value: string): void {
    if (validatePartialNumber(value)) {
      // TODO: move these three lines into generic function
      const newConfig: RuuviGWHWCfg = { ...this.getCfgFromStateValues() };
      newConfig.gpst = value !== "" ? Number(value) : null;
      this.props.deviceState.cfg = newConfig;
      this.setState({ gpst: value });
      this.props.setChangesMade();
    }
  }

  private handleActChange(value: boolean): void {
    const newCfg: Partial<RuuviGWHWCfg> = { ...this.getCfgFromStateValues(), act: value };
    this.props.deviceState.cfg = newCfg;
    this.setState({ act: value });
    this.props.setChangesMade();
  }

  private handleMvtChange(value: string): void {
    if (validatePartialNumber(value)) {
      // TODO: move these three lines into generic function
      const newConfig: RuuviGWHWCfg = { ...this.getCfgFromStateValues() };
      newConfig.mvt = value !== "" ? Number(value) : null;
      this.props.deviceState.cfg = newConfig;
      this.setState({ mvt: value });
      this.props.setChangesMade();
    }
  }

  private handleActwtChange(value: string): void {
    if (validatePartialNumber(value)) {
      // TODO: move these three lines into generic function
      const newConfig: RuuviGWHWCfg = { ...this.getCfgFromStateValues() };
      newConfig.actwt = value !== "" ? Number(value) : null;
      this.props.deviceState.cfg = newConfig;
      this.setState({ actwt: value });
      this.props.setChangesMade();
    }
  }

  private handleRscanChange(value: string): void {
    if (validatePartialNumber(value)) {
      // TODO: move these three lines into generic function
      const newConfig: RuuviGWHWCfg = { ...this.getCfgFromStateValues() };
      newConfig.rscan = value !== "" ? Number(value) : null;
      this.props.deviceState.cfg = newConfig;
      this.setState({ rscan: value });
      this.props.setChangesMade();
    }
  }

  private handleMvresChange(value: string): void {
    if (validatePartialNumber(value)) {
      // TODO: move these three lines into generic function
      const newConfig: RuuviGWHWCfg = { ...this.getCfgFromStateValues() };
      newConfig.mvres = value !== "" ? Number(value) : null;
      this.props.deviceState.cfg = newConfig;
      this.setState({ mvres: value });
      this.props.setChangesMade();
    }
  }

  private handleAcctChange(value: string): void {
    if (validatePartialNumber(value)) {
      // TODO: move these three lines into generic function
      const newConfig: RuuviGWHWCfg = { ...this.getCfgFromStateValues() };
      newConfig.acct = value !== "" ? Number(value) : null;
      this.props.deviceState.cfg = newConfig;
      this.setState({ acct: value });
      this.props.setChangesMade();
    }
  }

  /* TODO: default values should be removed from UI and instead device state handling process should be as follows:
   - read device state and set desired values to UI state (if desired values are missing, indicate it to the user and set empty values to state)
   - show user an indication if desired and reported values differ (after pressing "Apply") and show also the last reported value and its timestamp
  */
  private getInitialState(): Readonly<State> {
    const defaultConfig: State = {
      gpst: DEFAULT_GPST_STRING,
      mvt: DEFAULT_MVT_STRING,
      actwt: DEFAULT_ACTWT_STRING,
      rscan: DEFAULT_RSCAN_STRING,
      mvres: DEFAULT_MVRES_STRING,
      acct: DEFAULT_ACCT_STRING,
      act: DEFAULT_ACT,
      nodList: [],
    };

    const { cfg } = this.props.deviceState;

    if (cfg) {
      const { gpst, act, mvt, actwt, rscan, nod, mvres, acct } = cfg;
      const configWithPropsValues: State = {
        ...defaultConfig,
        ...(isDefined(gpst) && { gpst: gpst.toString() }),
        ...(isDefined(mvt) && { mvt: mvt.toString() }),
        ...(isDefined(actwt) && { actwt: actwt.toString() }),
        ...(isDefined(rscan) && { rscan: rscan.toString() }),
        ...(isDefined(mvres) && { mvres: mvres.toString() }),
        ...(isDefined(acct) && { acct: acct.toString() }),
        ...(isDefined(act) && { act }),
        ...(isDefined(nod) && { nodList: nod }),
      };
      return configWithPropsValues;
    } else {
      return defaultConfig;
    }
  }

  private renderEndAdornment(adornmentText = "s"): JSX.Element {
    return <InputAdornment position="end" disableTypography style={{ fontSize: "0.8rem" }}>{adornmentText}</InputAdornment>;
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <SettingsListItem label="Gps timeout">
          <TextField
            value={this.state.gpst ?? ""}
            onChange={({ target }): void => this.handleGpstChange(target.value)}
            InputProps={{ endAdornment: this.renderEndAdornment() }}
            style={{ maxWidth: "5rem" }}
          />
        </SettingsListItem>
        <SettingsListItem label="Active mode">
          <Switch
            edge="end"
            onChange={({ target }): void => this.handleActChange(target.checked)}
            checked={this.state.act}
            color="primary"
            size="small"
          />
        </SettingsListItem>
        <Typography align="left" color="textSecondary" style={{ fontSize: "0.8rem", padding: "0 1.5rem", margin: "4px 0" }}>Active mode settings</Typography>
        <Box border={`1px solid ${this.props.theme.palette.iconColors.primary}`} borderRadius="4px" py="0.5rem">
          <SettingsListItem label="Active wait time">
            <TextField
              value={this.state.actwt ?? ""}
              onChange={({ target }): void => this.handleActwtChange(target.value)}
              InputProps={{ endAdornment: this.renderEndAdornment() }}
              style={{ maxWidth: "5rem" }}
            />
          </SettingsListItem>
        </Box>
        <Typography align="left" color="textSecondary" style={{ fontSize: "0.8rem", padding: "0 1.5rem", margin: "4px 0" }}>Passive mode settings</Typography>
        <Box border={`1px solid ${this.props.theme.palette.iconColors.primary}`} borderRadius="4px" py="0.5rem">
          <SettingsListItem label="Movement timeout">
            <TextField
              value={this.state.mvt ?? ""}
              onChange={({ target }): void => this.handleMvtChange(target.value)}
              InputProps={{ endAdornment: this.renderEndAdornment() }}
              style={{ maxWidth: "5rem" }}
            />
          </SettingsListItem>
          <SettingsListItem label="Movement resolution">
            <TextField
              value={this.state.mvres ?? ""}
              onChange={({ target }): void => this.handleMvresChange(target.value)}
              InputProps={{ endAdornment: this.renderEndAdornment() }}
              style={{ maxWidth: "5rem" }}
            />
          </SettingsListItem>
          <SettingsListItem label="Accelerometer threshold">
            <TextField
              value={this.state.acct ?? ""}
              onChange={({ target }): void => this.handleAcctChange(target.value)}
              InputProps={{ endAdornment: this.renderEndAdornment("m/s²") }}
              style={{ maxWidth: "5rem" }}
            />
          </SettingsListItem>
        </Box>
        <SettingsListItem label="RuuviTag scan time">
          <TextField
            value={this.state.rscan ?? ""}
            onChange={({ target }): void => this.handleRscanChange(target.value)}
            InputProps={{ endAdornment: this.renderEndAdornment("ms") }}
            style={{ maxWidth: "5rem" }}
          />
        </SettingsListItem>
        <SettingsListItem label={this.text("DeviceSettings", "notRequestedList")}>
          <RuuviGWNodSelector
            nodGnss={this.state.nodList.includes("gnss")}
            nodNcell={this.state.nodList.includes("ncell")}
            nodMcellLoc={this.state.nodList.includes("mcell_loc")}
            handleChange={(key, value): void => this.handleNodValueChange(key, value)}
          />
        </SettingsListItem>
        <SettingsListItem label="Network mode">{this.props.deviceState?.dev?.v?.nw ?? "N/A"}</SettingsListItem>
        <SettingsListItem label="Modem">{this.props.deviceState?.dev?.v?.modV ?? "N/A"}</SettingsListItem>
        <SettingsListItem label="Application">{this.props.deviceState?.dev?.v?.appV ?? "N/A"}</SettingsListItem>
      </Fragment>
    );
  }
}

export default withTheme(RuuviGWStateSettings);
